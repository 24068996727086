import "core-js/fn/object/assign";
import "core-js/fn/promise";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";

import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import store from "./store";
import { BrowserRouter } from "react-router-dom";

// Adding TagManager only for prod env
if (process.env.REACT_APP_NODE_ENV === "prod") {
  const tagManagerArgs = {
    gtmId: "GTM-W3GC6RB",
  };
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <Provider store={store}>
  {/* <BrowserRouter> */}
    <App />
  {/* </BrowserRouter> */}
  </Provider>,
  document.getElementById("root")
);

registerServiceWorker();
