import React from "react";
import styled from "styled-components";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";

import { reporaDevice } from "../../components/global/commonStyles";
import { openMenu, closeMenu } from "../../actions/menuActions";

import Menu from "../../components/Menu/Menu";
import MenuToggle from "./components/MenuToggle";
import constants from "../../constants";

const ContentWrapper = styled.div`
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  -webkit-overflow-scrolling: touch;
  margin: 0 auto;

  & > * {
    -webkit-transform: translateZ(0px); 
  }

  ${(props) => (props.showMenu ? "overflow: hidden;" : null)}

  @media ${reporaDevice.tablet} {
    ${(props) =>
      props.marginTop
        ? `height: calc(100vh - ${props.marginTop}px);`
        : "height: calc(100vh - 68px);"}
  }
`;

const Content = styled.div`
  min-height: 100%;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;

  @media ${reporaDevice.desktop} {
    margin: 0px auto;
  }
`;

const ToggleWrapper = styled.div`
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 10;

  @media ${reporaDevice.tablet} {
    display: none;
  }
`;

class NavigationWrapper extends React.Component {
  state = {
    headerHeight: null,
  };

  handleToggleMenu = () => {
    if (this.props.menuIsOpen) {
      this.props.handleCloseMenu();
    } else {
      this.props.handleOpenMenu();
    }
  };

  handleHeaderSizeChange = (size) => {
    this.setState({
      headerHeight: size.height,
    });
  };

  render() {
    window.scrollTo(0, 0);
    let pathName = window.location.pathname || '';
    return (
      <BrowserRouter>
        <div>
          <Menu
            onSizeChange={this.handleHeaderSizeChange}
            history={this.props.history}
          />
          <ContentWrapper
            marginTop={this.state.headerHeight}
            id="contentWrapper"
            showMenu={this.props.menuIsOpen}
          >
            <React.Fragment>
              <Content id="contentLoader">{this.props.children}</Content>
              <ToggleWrapper onClick={this.handleToggleMenu}>
                {(!constants.REPORA_IFRAME_PATHS.includes(pathName)) &&
                <MenuToggle menuOpen={this.props.menuIsOpen} />
                 }
              </ToggleWrapper>
            </React.Fragment>
          </ContentWrapper>
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  menuIsOpen: state.menu.isOpen,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
  handleOpenMenu() {
    dispatch(openMenu());
  },
  handleCloseMenu() {
    dispatch(closeMenu());
  },
});

export const Unwrapped = NavigationWrapper;
export default connect(mapStateToProps, mapDispatchToProps)(NavigationWrapper);
