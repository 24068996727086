import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import {
  reporaDevice,
  reporaColor,
  reporaButtonStyles,
  ReporaButton,
} from "../../global/commonStyles";
import AutoComplete from "../../../scenes/AutoComplete/AutoComplete";

const Item = styled(NavLink)`
  display: flex;
  width: auto;
  font-size: 15px;
  font-family: "Nexa Bold";
  text-decoration: none;
  margin: 0 20px;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  color: ${reporaColor.warmGrey};

  &:hover,
  &.selected {
    color: ${reporaColor.black};
    border-bottom-color: ${reporaColor.brightSkyBlue};
  }
`;

const DropdownItem = styled.span`
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  font-size: 15px;
  font-family: "Nexa Bold";
  text-decoration: none;
  margin: 0 20px;
  position: relative;
  color: ${reporaColor.warmGrey};

  &:hover,
  &.selected {
    color: ${reporaColor.black};
    border-bottom: 4px solid ${reporaColor.brightSkyBlue};
  }

  @media ${reporaDevice.tablet} {
    &:hover,
    &.selected {
      ul {
        max-height: 400px;
      }
    }

    ul {
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      position: absolute;
      width: auto;
      top: 100%;
      left: -20px;
      height: auto;
      overflow: hidden;
      transition: max-height 0.5s;
      max-height: 0;
      background-color: ${reporaColor.grey91};
      z-index: 5;

      a {
        color: ${reporaColor.black};
      }
    }
  }
`;

const Title = styled.span`
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 60px;

  svg {
    margin: 3px 10px 0;
  }
`;

export const RedirectButton = styled(NavLink)`
  ${reporaButtonStyles};
  text-decoration: none;
  border: ${(props) =>
    props.showBorder ? `solid 1px ${reporaColor.black}` : ""};
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${reporaDevice.tablet} {
    flex-direction: row;
  }

  div {
    margin: auto auto 0;
  }

  div.react-autosuggest__container {
    width: 250px;
  }
`;

export const MenuItem = (props) => (
  <Item
    exact
    to={props.to}
    isActive={props.isActive}
    activeClassName="selected"
    onClick={props.onClick}
  >
    <Title>{props.title}</Title>
  </Item>
);

export const MenuDropdownItem = (props) => (
  <DropdownItem onClick={props.onClick}>
    <Title>
      {props.title}
      <FontAwesomeIcon icon={faChevronDown} />
    </Title>
    <ul>
      {Object.keys(props.subItems).map((key, index) => {
        const subItemName = props.subItems[key];
        return (
          <Item
            key={props.title + index}
            exact
            to={"/" + key}
            activeClassName="selected"
          >
            <Title>{subItemName}</Title>
          </Item>
        );
      })}
    </ul>
  </DropdownItem>
);

export const MenuSearchItem = (props) => (
  <FormWrapper>
    <AutoComplete {...props} />
    <ReporaButton onClick={props.handleSubmit} isIcon>
      <img src="/assets/global/search.svg" alt="" />
    </ReporaButton>
  </FormWrapper>
);
