import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { reporaColor, reporaDevice } from "../global/commonStyles";

const Wrapper = styled.section`
  background: white;
  width: 100%;
  position: sticky;
  bottom:0;
 z-index:1;
  `;

const Content = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 0 10px;
  height: 40px;
  align-items: center;

  >div {
    flex: 1;

    &:first-child {
      color: ${reporaColor.warmGrey};
      font-size: 14px;
      font-weight: bold;
      white-space: nowrap;
    }

    &:nth-of-type(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
  }

  a {
    color: black;
    font-size: 14px;
    font-weight: bold;
    margin: 0 20px;
    text-decoration: none;
    white-space: nowrap;
  }

  @media ${reporaDevice.xs} {
    flex-direction: column;
    align-items: center;
    height: auto;

    >div:first-child {
      margin: 20px 0;
    }

    >div:nth-of-type(2) {
      flex-direction: column;
    }

    a {
      margin: 0;
      margin-bottom: 20px;
    }
  }
`;

const Footer = React.memo(() => (
  <Wrapper>
    <Content>
      <div>© RepScore, Inc.</div>
      <div>
        <Link to="/ourcompany">Company</Link>
        <Link to="/privacy">Privacy and Terms</Link>
        <Link to="/contactus">Contact Us</Link>
      </div>
      <div></div>
    </Content>
  </Wrapper>
));

export default Footer;
