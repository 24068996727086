import React from "react";
import styled from "styled-components";
import { reporaColor, ReporaButton, reporaSize, Header2, Header4 } from "../../../components/global/commonStyles";
import CustomerHeroBgImage from "../assets/Repora_Customer_Hero_BGimage.jpeg";
import Hero from "../../../components/HeroUpdated/Hero";
import Footer from "../../../components/FooterUpdated/Footer";
import Data from "./Data";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PageSection = styled.section`
  width: 100%;
  max-width: 100%;
  height: auto !important;
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: ${props => props.bkgImage ? `url(${props.bkgImage})` : "none"};
  background-color: ${props => props.bkgColor || "none" };
  background-position: ${props => props.isFooter || props.arch ? "top" : "center center"};
	background-repeat: no-repeat;
	background-size: cover;
  padding: ${props => props.isHero ? "8% 30% 8% 10%" : "5% 15%"};
  color: ${props => props.txtColor || "inherit"};

  ${props => props.isFooter ? "margin-top: -6%;" : null}

  @media (max-width: ${reporaSize.medium}) {
		padding-left: ${props => props.isHero ? "15%" : "5%"};
    padding-right: ${props => props.isHero ? "15%" : "5%"};

    ${props => props.isFooter ? "margin-top: -8%;" : null}
	}

  @media (max-width: ${reporaSize.small}) {
    ${props => props.isFooter ? "margin-top: -15%;" : null}
	}
`;

const CommunityPart = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 70px;
`;

const CommunityHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  h4 {
    font-size: 18px;
  }
`;

const CommunityImage = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin: 3% 0;

  @media (max-width: ${reporaSize.small}) {
    margin: 6% 0;
  }

  img {
    height: 30px;
  }
`;

const CommunityText = styled.div`
  display: flex;
  flex-direction: row;
  width: 75%;
  align-items: center;

  @media (max-width: ${reporaSize.small}) {
    width: 100%;
  }

  h2 {
    font-size: 28px;
    text-align: center;

    @media (max-width: ${reporaSize.small}) {
      font-size: 22px;
    }
    
    span {
      color: ${reporaColor.aquaBlue}
    }
  }
`;

const CustomPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h2 {
    font-size: 35px;
    line-height: initial;
    margin-bottom: 3%;

    @media (max-width: ${reporaSize.small}) {
      font-size: 20px;
    }
  }
`;

const View = () => {

  return (
    <Wrapper>
      <PageSection bkgImage={CustomerHeroBgImage} isHero>
        <Hero textColor={reporaColor.white} data={Data.hero} alignLeft contentWidth="40%" />
      </PageSection>

      <PageSection>
        <CommunityPart>
          <CommunityHeader>
            <Header4>BECOME PART OF THE COMMUNITY</Header4>
          </CommunityHeader>
          <CommunityImage>
            <img src="/assets/featuresCustomers/row-of-icons.png" alt="" />
          </CommunityImage>
          <CommunityText>
            <Header2>
              By providing your feedback on <span>standardized attributes</span> and <span>comments</span>, you help your vendor, your relationship with them, and the overall customer community.
            </Header2>
          </CommunityText>
        </CommunityPart>
      </PageSection>

      <PageSection bkgImage="/assets/home/footer-bgm.png" isFooter>
        <CustomPanel>
          <Header2 textAlign="center" textColor={`${reporaColor.black}`}>Start Using Repora Today!</Header2>
          <ReporaButton href="/contactus" secondary>Contact Us</ReporaButton>
        </CustomPanel>
      </PageSection>

      <Footer />
    </Wrapper>
  );
};

export default View;
