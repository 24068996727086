import React from "react";
import styled from "styled-components";

import DefaultAvatarSvg from "../assets/default-avatar.svg";

const Picture = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

const UserProfilePicture = props => (
  <Picture
    src={props.picture || DefaultAvatarSvg}
    alt="profile"
    onError={event => {
      event.target.onerror = null;
      event.target.src = DefaultAvatarSvg;
    }} />
);

export default UserProfilePicture;