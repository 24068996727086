import React from "react";
import styled from "styled-components";

import { reporaDevice } from "../../../components/global/commonStyles";

import PersonCard from "./PersonCard";
import RatingsCard from "./RatingsCard";
import RateConfirmation from "./RateConfirmation";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;

  @media ${reporaDevice.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const SideWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  flex-shrink: 0;

  @media ${reporaDevice.tablet} {
    ${(props) =>
      props.left
        ? "width: 40%; min-width: 305px; padding-right: 20px;"
        : "width: 60%"}
  }

  @media ${reporaDevice.desktop} {
    ${(props) => (props.left ? "max-width: 305px;" : "max-width: 600px")}
  }
`;

const View = (props) => {
  if (props.teamRateResponseReturned) {
    return (
      <RateConfirmation
        responseMessage={props.teamRateResponse}
        teamData={props.teamData}
        handleRateDone={props.handleRateDone}
        companylogBlob={props.companylogBlob}
      />
    );
  }

  return (
    <Wrapper>
      <SideWrapper left>
        {props.teamData && (
          <PersonCard
            teamData={props.teamData}
            isFetching={props.teammDataFetching}
            companylogBlob={props.companylogBlob}
          />
        )}
      </SideWrapper>

      <SideWrapper right>
        {props.teamData && (
          <RatingsCard
            userData={props.userData}
            userdataExist={props.userdataExist}
            reviewRequestedBy={props.reviewRequestedBy}
            teamData={props.teamData}
            isFetchingRateTeam={props.isFetchingRateTeam}
            isFetching={props.teamDataFetching}
            handleSubmitRating={props.handleSubmitRating}
            customeAttributes={props.customeAttributes}
            customeAttributesFetching={props.customeAttributesFetching}
            attributesList={props.attributesList}
            spinner={props.spinner}
          />
        )}
      </SideWrapper>
    </Wrapper>
  );
};

export default View;
