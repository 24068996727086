import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Header3, Body4, reporaColor, reporaDevice, ReporaButton } from "../../../components/global/commonStyles";
import { HeaderWrapper, FormWrapper } from "../../SignUp/components/CommonStyles";
import DefaultAvatarSvg from "../assets/default-avatar.svg";

export const Wrapper = styled.div`
  max-width: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 80px auto 20px;
  position: relative;
  border-radius: 2px;
  background-color: gold;
  box-shadow: 0 0 8px 0 #00000033;
  padding: 10px;

  @media ${reporaDevice.tablet} {
    max-width: 502px;
    padding: 35px;
  }
`;

const Wrapper2 = styled.div`
  padding-top: 80px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: center;
  background: white;
`;

export const Card = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 8px 0 ${reporaColor.black08};
  border-radius: 2px;
  padding: 35px;
  max-width: 502px;

  @media ${reporaDevice.xs} {
    width: 100%;
  }
`;

const MessageWrapper = styled(FormWrapper)`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 10px;
  }
`;

const ImageWrapper = styled.div`
  max-width: 450px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;

  img{
    border-radius: 50%;
  }
  img:first-child {
    margin-right: 10px;
    width: 80px;
    height: 80px;
  }
`;

const RateComfirmation = props => {
  return (
    <Wrapper2>
      <Card>
        <HeaderWrapper>
          <Header3 textAlign="center">
            {props.responseMessage.statusCode === 200
              ? "Thank You!"
              : "Rating Up Error!"
            }
          </Header3>
        </HeaderWrapper>

        <MessageWrapper>
          {props.responseMessage.statusCode === 200 &&
            <ImageWrapper>
              <img
                src={props.repPhoto || "assets/global/userIcon.svg"}
                alt="profile"
                onError={event => {
                  event.target.onerror = null;
                  event.target.src = DefaultAvatarSvg;
                }} />
              {/* <img src="/assets/global/check-green.svg" alt="check icon" /> */}
            </ImageWrapper>
          }

          <Body4 textAlign="center" textColor={`${reporaColor.brightSkyBlue}`}>
            {props.responseMessage.statusCode === 200
              ? props.rateId ? `Your rating for ${props.repName} has been edited.` : "Your rating for " + props.repName + " was submitted."
              : props.responseMessage.message
            }
          </Body4>
          {props.responseMessage.statusCode === 200 
            ? props.rateId ?
            (<Body4 textAlign="center" textColor={`${reporaColor.warmGrey}`}>
            Your previous feedback was replaced. This will not count as a new/duplicate rating. 
            </Body4>):
            (props.userData ?
            (<Body4 textAlign="center" textColor={`${reporaColor.warmGrey}`}>
            By providing feedback to professional contacts you interact 
            with, you help improve them and your relationship with their 
            firm, you make the time you spend with them more productive,
            and you help the overall customer community. <br/><br/>
            <NavLink style={{color: `${reporaColor.brightSkyBlue}`}} target='_blank' to='/'>
            Learn
            </NavLink> how Repora can do the same for you. 
            </Body4>): 
            (<Body4 textAlign="center" textColor={`${reporaColor.warmGrey}`}>
             By providing feedback to professional contacts you interact 
            with, you help improve them and your relationship with their 
            firm, you make the time you spend with them more productive,
            and you help the overall customer community.<br/><br/>
            Learn how Repora can help do the same for you.
            </Body4>)) :
            null
          }
        </MessageWrapper>

        <ReporaButton onClick={props.handleRateDone}>{props.userData ? "Done" : "Learn More" }</ReporaButton>
      </Card>
    </Wrapper2>
  );
};

export default RateComfirmation;
