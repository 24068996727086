import React from "react";
import styled, {keyframes} from "styled-components";
import white from "./assets/spinner-white.svg";
import black from "./assets/spinner-black.svg";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100%{ transform: rotate(360deg); }
`;

const Image = styled.img`
  animation: ${spin} 2s infinite linear;
  border-radius: 50%;
`;

const Spinner = props => <Image className="spinner" src={props.color === "white" ? white : black} alt="loading indicator" />;

export default Spinner;
