const StatesCities = [
  { "code": 47, "groups": "corp, fin", "description": "Accounting" },
  { "code": 94, "groups": "man, tech, tran", "description": "Airlines/Aviation" },
  { "code": 120, "groups": "leg, org", "description": "Alternative Dispute Resolution" },
  { "code": 125, "groups": "hlth", "description": "Alternative Medicine" },
  { "code": 127, "groups": "art, med", "description": "Animation" },
  { "code": 19, "groups": "good", "description": "Apparel & Fashion" },
  { "code": 50, "groups": "cons", "description": "Architecture & Planning" },
  { "code": 111, "groups": "art, med, rec", "description": "Arts and Crafts" },
  { "code": 53, "groups": "man", "description": "Automotive" },
  { "code": 52, "groups": "gov, man", "description": "Aviation & Aerospace" },
  { "code": 41, "groups": "fin", "description": "Banking" },
  { "code": 12, "groups": "gov, hlth, tech", "description": "Biotechnology" },
  { "code": 36, "groups": "med, rec", "description": "Broadcast Media" },
  { "code": 49, "groups": "cons", "description": "Building Materials" },
  { "code": 138, "groups": "corp, man", "description": "Business Supplies and Equipment" },
  { "code": 129, "groups": "fin", "description": "Capital Markets" },
  { "code": 54, "groups": "man", "description": "Chemicals" },
  { "code": 90, "groups": "org, serv", "description": "Civic & Social Organization" },
  { "code": 51, "groups": "cons, gov", "description": "Civil Engineering" },
  { "code": 128, "groups": "cons, corp, fin", "description": "Commercial Real Estate" },
  { "code": 118, "groups": "tech", "description": "Computer & Network Security" },
  { "code": 109, "groups": "med, rec", "description": "Computer Games" },
  { "code": 3, "groups": "tech", "description": "Computer Hardware" },
  { "code": 5, "groups": "tech", "description": "Computer Networking" },
  { "code": 4, "groups": "tech", "description": "Computer Software" },
  { "code": 48, "groups": "cons", "description": "Construction" },
  { "code": 24, "groups": "good, man", "description": "Consumer Electronics" },
  { "code": 25, "groups": "good, man", "description": "Consumer Goods" },
  { "code": 91, "groups": "org, serv", "description": "Consumer Services" },
  { "code": 18, "groups": "good", "description": "Cosmetics" },
  { "code": 65, "groups": "agr", "description": "Dairy" },
  { "code": 1, "groups": "gov, tech", "description": "Defense & Space" },
  { "code": 99, "groups": "art, med", "description": "Design" },
  { "code": 69, "groups": "edu", "description": "Education Management" },
  { "code": 132, "groups": "edu, org", "description": "E-Learning" },
  { "code": 112, "groups": "good, man", "description": "Electrical/Electronic Manufacturing" },
  { "code": 28, "groups": "med, rec", "description": "Entertainment" },
  { "code": 86, "groups": "org, serv", "description": "Environmental Services" },
  { "code": 110, "groups": "corp, rec, serv", "description": "Events Services" },
  { "code": 76, "groups": "gov", "description": "Executive Office" },
  { "code": 122, "groups": "corp, serv", "description": "Facilities Services" },
  { "code": 63, "groups": "agr", "description": "Farming" },
  { "code": 43, "groups": "fin", "description": "Financial Services" },
  { "code": 38, "groups": "art, med, rec", "description": "Fine Art" },
  { "code": 66, "groups": "agr", "description": "Fishery" },
  { "code": 34, "groups": "rec, serv", "description": "Food & Beverages" },
  { "code": 23, "groups": "good, man, serv", "description": "Food Production" },
  { "code": 101, "groups": "org", "description": "Fund-Raising" },
  { "code": 26, "groups": "good, man", "description": "Furniture" },
  { "code": 29, "groups": "rec", "description": "Gambling & Casinos" },
  { "code": 145, "groups": "cons, man", "description": "Glass, Ceramics & Concrete" },
  { "code": 75, "groups": "gov", "description": "Government Administration" },
  { "code": 148, "groups": "gov", "description": "Government Relations" },
  { "code": 140, "groups": "art, med", "description": "Graphic Design" },
  { "code": 124, "groups": "hlth, rec", "description": "Health, Wellness and Fitness" },
  { "code": 68, "groups": "edu", "description": "Higher Education" },
  { "code": 14, "groups": "hlth", "description": "Hospital & Health Care" },
  { "code": 31, "groups": "rec, serv, tran", "description": "Hospitality" },
  { "code": 137, "groups": "corp", "description": "Human Resources" },
  { "code": 134, "groups": "corp, good, tran", "description": "Import and Export" },
  { "code": 88, "groups": "org, serv", "description": "Individual & Family Services" },
  { "code": 147, "groups": "cons, man", "description": "Industrial Automation" },
  { "code": 84, "groups": "med, serv", "description": "Information Services" },
  { "code": 96, "groups": "tech", "description": "Information Technology and Services" },
  { "code": 42, "groups": "fin", "description": "Insurance" },
  { "code": 74, "groups": "gov", "description": "International Affairs" },
  { "code": 141, "groups": "gov, org, tran", "description": "International Trade and Development" },
  { "code": 6, "groups": "tech", "description": "Internet" },
  { "code": 45, "groups": "fin", "description": "Investment Banking" },
  { "code": 46, "groups": "fin", "description": "Investment Management" },
  { "code": 73, "groups": "gov, leg", "description": "Judiciary" },
  { "code": 77, "groups": "gov, leg", "description": "Law Enforcement" },
  { "code": 9, "groups": "leg", "description": "Law Practice" },
  { "code": 10, "groups": "leg", "description": "Legal Services" },
  { "code": 72, "groups": "gov, leg", "description": "Legislative Office" },
  { "code": 30, "groups": "rec, serv, tran", "description": "Leisure, Travel & Tourism" },
  { "code": 85, "groups": "med, rec, serv", "description": "Libraries" },
  { "code": 116, "groups": "corp, tran", "description": "Logistics and Supply Chain" },
  { "code": 143, "groups": "good", "description": "Luxury Goods & Jewelry" },
  { "code": 55, "groups": "man", "description": "Machinery" },
  { "code": 11, "groups": "corp", "description": "Management Consulting" },
  { "code": 95, "groups": "tran", "description": "Maritime" },
  { "code": 97, "groups": "corp", "description": "Market Research" },
  { "code": 80, "groups": "corp, med", "description": "Marketing and Advertising" },
  { "code": 135, "groups": "cons, gov, man", "description": "Mechanical or Industrial Engineering" },
  { "code": 126, "groups": "med, rec", "description": "Media Production" },
  { "code": 17, "groups": "hlth", "description": "Medical Devices" },
  { "code": 13, "groups": "hlth", "description": "Medical Practice" },
  { "code": 139, "groups": "hlth", "description": "Mental Health Care" },
  { "code": 71, "groups": "gov", "description": "Military" },
  { "code": 56, "groups": "man", "description": "Mining & Metals" },
  { "code": 35, "groups": "art, med, rec", "description": "Motion Pictures and Film" },
  { "code": 37, "groups": "art, med, rec", "description": "Museums and Institutions" },
  { "code": 115, "groups": "art, rec", "description": "Music" },
  { "code": 114, "groups": "gov, man, tech", "description": "Nanotechnology" },
  { "code": 81, "groups": "med, rec", "description": "Newspapers" },
  { "code": 100, "groups": "org", "description": "Non-Profit Organization Management" },
  { "code": 57, "groups": "man", "description": "Oil & Energy" },
  { "code": 113, "groups": "med", "description": "Online Media" },
  { "code": 123, "groups": "corp", "description": "Outsourcing/Offshoring" },
  { "code": 87, "groups": "serv, tran", "description": "Package/Freight Delivery" },
  { "code": 146, "groups": "good, man", "description": "Packaging and Containers" },
  { "code": 61, "groups": "man", "description": "Paper & Forest Products" },
  { "code": 39, "groups": "art, med, rec", "description": "Performing Arts" },
  { "code": 15, "groups": "hlth, tech", "description": "Pharmaceuticals" },
  { "code": 131, "groups": "org", "description": "Philanthropy" },
  { "code": 136, "groups": "art, med, rec", "description": "Photography" },
  { "code": 117, "groups": "man", "description": "Plastics" },
  { "code": 107, "groups": "gov, org", "description": "Political Organization" },
  { "code": 67, "groups": "edu", "description": "Primary/Secondary Education" },
  { "code": 83, "groups": "med, rec", "description": "Printing" },
  { "code": 105, "groups": "corp", "description": "Professional Training & Coaching" },
  { "code": 102, "groups": "corp, org", "description": "Program Development" },
  { "code": 79, "groups": "gov", "description": "Public Policy" },
  { "code": 98, "groups": "corp", "description": "Public Relations and Communications" },
  { "code": 78, "groups": "gov", "description": "Public Safety" },
  { "code": 82, "groups": "med, rec", "description": "Publishing" },
  { "code": 62, "groups": "man", "description": "Railroad Manufacture" },
  { "code": 64, "groups": "agr", "description": "Ranching" },
  { "code": 44, "groups": "cons, fin, good", "description": "Real Estate" },
  { "code": 40, "groups": "rec, serv", "description": "Recreational Facilities and Services" },
  { "code": 89, "groups": "org, serv", "description": "Religious Institutions" },
  { "code": 144, "groups": "gov, man, org", "description": "Renewables & Environment" },
  { "code": 70, "groups": "edu, gov", "description": "Research" },
  { "code": 32, "groups": "rec, serv", "description": "Restaurants" },
  { "code": 27, "groups": "good, man", "description": "Retail" },
  { "code": 121, "groups": "corp, org, serv", "description": "Security and Investigations" },
  { "code": 7, "groups": "tech", "description": "Semiconductors" },
  { "code": 58, "groups": "man", "description": "Shipbuilding" },
  { "code": 20, "groups": "good, rec", "description": "Sporting Goods" },
  { "code": 33, "groups": "rec", "description": "Sports" },
  { "code": 104, "groups": "corp", "description": "Staffing and Recruiting" },
  { "code": 22, "groups": "good", "description": "Supermarkets" },
  { "code": 8, "groups": "gov, tech", "description": "Telecommunications" },
  { "code": 60, "groups": "man", "description": "Textiles" },
  { "code": 130, "groups": "gov, org", "description": "Think Tanks" },
  { "code": 21, "groups": "good", "description": "Tobacco" },
  { "code": 108, "groups": "corp, gov, serv", "description": "Translation and Localization" },
  { "code": 92, "groups": "tran", "description": "Transportation/Trucking/Railroad" },
  { "code": 59, "groups": "man", "description": "Utilities" },
  { "code": 106, "groups": "fin, tech", "description": "Venture Capital & Private Equity" },
  { "code": 16, "groups": "hlth", "description": "Veterinary" },
  { "code": 93, "groups": "tran", "description": "Warehousing" },
  { "code": 133, "groups": "good", "description": "Wholesale" },
  { "code": 142, "groups": "good, man, rec", "description": "Wine and Spirits" },
  { "code": 119, "groups": "tech", "description": "Wireless" },
  { "code": 103, "groups": "art, med, rec", "description": "Writing and Editing" }
];

export default StatesCities;
