import React from "react"
import styled from "styled-components"

import { reporaColor, Body4 } from "../global/commonStyles"

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > p {
    margin: -22px 5px 0;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url("/assets/global/${props => props.imgSource}.svg");
  height: 60px;
  width: 60px;
  background-size: contain;
  ${props => props.notEditable  ? null :`
  cursor: pointer;
  &:hover {
    background-image: url(/assets/global/${props => props.imgSource}-hover.svg);
  }
  `}
  ${props => (props.active ? `background-image: url("/assets/global/${props.imgSource}-hover.svg");` : null)}
`;

class LikeDislikeOption extends React.Component {
  state = {
    selected: null,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.onChange && typeof nextProps.selected === "boolean") {
      this.setState({ selected: nextProps.selected })
    }
  }

  handleSelection = value => {
    if (this.props.onChange) {
      this.props.onChange(value)
    }
    this.setState({ selected: value })
  }

  render() {
    if (this.props.static) {
       return (
        <Wrapper>
        <ButtonWrapper>
          <ImageWrapper imgSource="option-like" active={this.props.experince === true} notEditable = {true}  />
          <Body4 textColor={reporaColor.warmGrey}>Positive</Body4>
        </ButtonWrapper>

        <Body4 textColor={reporaColor.warmGrey}>or</Body4>

        <ButtonWrapper >
          <ImageWrapper imgSource="option-dislike" active={this.props.experince === false} notEditable = {true}  />
          <Body4 textColor={reporaColor.warmGrey}>Negative</Body4>
        </ButtonWrapper>
      </Wrapper>
       )
    }
    return (
      <Wrapper>
        <ButtonWrapper onClick={() => this.handleSelection(true)}>
          <ImageWrapper imgSource="option-like" active={this.state.selected} />
          <Body4 textColor={reporaColor.warmGrey}>Positive</Body4>
        </ButtonWrapper>

        <Body4 textColor={reporaColor.warmGrey}>or</Body4>

        <ButtonWrapper onClick={() => this.handleSelection(false)}>
          <ImageWrapper imgSource="option-dislike" active={this.state.selected === false} />
          <Body4 textColor={reporaColor.warmGrey}>Negative</Body4>
        </ButtonWrapper>
      </Wrapper>
    )
  }
}

export default LikeDislikeOption
