import React from "react";
import styled from "styled-components";

import { reporaDevice } from "../../../components/global/commonStyles";

import Spinner from "../../../components/Spinner/Spinner";

import PersonCard from "./PersonCard";
import RatingsCard from "./RatingsCard";
import RateConfirmation from "./RateConfirmation";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  padding: 0 15px;
  margin: 33px auto 0;

  @media ${reporaDevice.tablet} {
    align-items: center;
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin-top: 100px;

  img {
    width: 100px;
    height: 100px;
  }
`;

const View = (props) => {
  if (props.isFetching) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (props.rateNewRepResponseReturned) {
    return (
      <RateConfirmation
        responseMessage={props.rateNewRepResponse}
        errorMessage={props.errorMessage}
        handleRateDone={props.handleRateDone}
      />
    );
  }
  let ratingsKeys = "";
  if (props.ratingAttribute) {
    ratingsKeys = Object.keys(props.ratingAttribute);
    const ind = ratingsKeys.indexOf("General");
    ratingsKeys[ind] = "Other";
  }
  return (
    <Wrapper>
      <form onSubmit={props.handleFormSubmit}>
        <PersonCard
          userInfo={props.userInfo}
          currentCompany={props.currentCompany}
          companiesList={props.companiesList}
          companies={props.companies}
          handleFirstNameChange={props.handleFirstNameChange}
          handleLastNameChange={props.handleLastNameChange}
          handleEmailChange={props.handleEmailChange}
          handleCompanyChange={props.handleCompanyChange}
          handleAddNewCompany={props.handleAddNewCompany}
          addCompanyLoading={props.addCompanyLoading}
          companiesFetching={props.companiesFetching}
          handleSelectCompany={props.handleSelectCompany}
          handleSelectCompany1={props.handleSelectCompany1}
          functionSelected={props.functionSelected}
          handleFunctionsChange={props.handleFunctionsChange}
          errorMessage={props.errorMessage}
          ratingKeys={ratingsKeys}
          companySuggestions={props.companySuggestions}
          companySuggestionsFetching={props.companySuggestionsFetching}
          handleGetCompanySuggestions={props.handleGetCompanySuggestions}
        />
        <RatingsCard
          thumbsUp={props.thumbsUp}
          anonymous={props.anonymous}
          isFormValid={props.isFormValid}
          charactersLeft={props.commentCharLeft}
          userRatings={props.userRatings}
          detail={props.detail}
          handleRateChange={props.handleRateChange}
          handleExperienceSelection={props.handleExperienceSelection}
          handleCommentChange={props.handleCommentChange}
          handleAnonymousCheck={props.handleAnonymousCheck}
          functionSelected={props.functionSelected}
          errorMessage={props.errorMessage}
        />
      </form>
    </Wrapper>
  );
};

export default View;
