import {
  GET_REP_PROFILE_REQUEST,
  GET_REP_PROFILE_SUCCESS,
  GET_REP_PROFILE_FAILURE,
  GET_OPTIMISED_REP_PROFILE_REQUEST,
  GET_OPTIMISED_REP_PROFILE_SUCCESS,
  GET_OPTIMISED_REP_PROFILE_FAILURE,
  GET_REP_LIST_REQUEST,
  GET_REP_LIST_SUCCESS,
  GET_REP_LIST_FAILURE,
  RATE_REP_PROFILE_REQUEST,
  RATE_REP_PROFILE_SUCCESS,
  RATE_REP_PROFILE_FAILURE,
  RATE_REP_PROFILE_RESET,
  SET_REP_TRENDS_TIME_FILTER,
  SET_REP_TRENDS_METRIC,
  SET_REP_TRENDS_DATA,
  UPDATE_REP_PRODUCT_SPECIALITY_REQUESTED,
  UPDATE_REP_PRODUCT_SPECIALITY_SUCCEEDED,
  UPDATE_REP_PRODUCT_SPECIALITY_FAILED,
  DELETE_REP_EMAIL_REQUESTED,
  DELETE_REP_EMAIL_SUCCEEDED,
  DELETE_REP_EMAIL_FAILED,
  RATE_NEW_REP_PROFILE_REQUEST,
  RATE_NEW_REP_PROFILE_SUCCESS,
  RATE_NEW_REP_PROFILE_FAILURE,
  RATE_NEW_REP_PROFILE_GO_BACK,
  CLAIM_PROFILE_REQUEST,
  CLAIM_PROFILE_SUCCESS,
  CLAIM_PROFILE_FAILURE,
  CLAIM_PROFILE_RESET,
  DISPUTE_REP_REQUEST,
  DISPUTE_REP_SUCCESS,
  DISPUTE_REP_FAILURE,
  INVITATION_TEMPLATE_REQUEST,
  INVITATION_TEMPLATE_SUCCESS,
  INVITATION_SET_TEMPLATE_SUCCESS,
  INVITATION_TEMPLATE_FAILURE,
  SET_REP_SEARCH_STATE,
  GET_RESET_REP_SEARCH_STATE,
  REP_PROFILE_VIEW_REQUEST,
  REP_PROFILE_VIEW_SUCCESS,
  REP_PROFILE_VIEW_FAILURE,
  CONTACT_REP_EMAIL_REQUEST,
  CONTACT_REP_EMAIL_SUCCESS,
  CONTACT_REP_EMAIL_FAILURE,
  CONTACT_REP_EMAIL_RESET,
  CONTACT_COMPANY_EMAIL_RESET,
  CONTACT_COMPANY_EMAIL_REQUEST,
  CONTACT_COMPANY_EMAIL_SUCCESS,
  CONTACT_COMPANY_EMAIL_FAILURE,
  UPDATE_REP_FROM_LINKEDIN_REQUESTED,
  UPDATE_REP_FROM_LINKEDIN_SUCCEEDED,
  UPDATE_REP_FROM_LINKEDIN_FAILED,
  UPDATE_COMPANY_USER_INFO_REQUEST,
  UPDATE_COMPANY_USER_INFO_SUCCESS,
  UPDATE_COMPANY_USER_INFO_FAILURE,
  DELETE_COMPANY_USER_FAILURE,
  DELETE_COMPANY_USER_REQUEST,
  DELETE_COMPANY_USER_SUCCESS,
  ADD_PREVIOUS_COMPANY_REQUESTED,
  ADD_PREVIOUS_COMPANY_SUCCEEDED,
  ADD_PREVIOUS_COMPANY_FAILED,
  DISCONNECT_COMPANY_REQUESTED,
  DISCONNECT_COMPANY_SUCCEEDED,
  DISCONNECT_COMPANY_FAILED,
  UPDATE_REP_EMAIL_REQUESTED,
  UPDATE_REP_EMAIL_SUCCEEDED,
  UPDATE_REP_EMAIL_FAILED,
  CLEAR_ERRORS_REQUESTED,
  GET_REP_PROFILE_REQUESTED,
  GET_REP_PROFILE_SUCCEEDED,
  GET_REP_PROFILE_FAILED,
  GET_REP_SUGGESTIONS_REQUESTED,
  GET_REP_SUGGESTIONS_SUCCEEDED,
  GET_REP_SUGGESTIONS_FAILED,
  GET_COMPANY_REP_SUGGESTIONS_REQUESTED,
  GET_COMPANY_REP_SUGGESTIONS_SUCCEEDED,
  GET_COMPANY_REP_SUGGESTIONS_FAILED,
  ADD_CUSTOMER_REP_FEEDBACK_REQUESTED,
  ADD_CUSTOMER_REP_FEEDBACK_SUCCEEDED,
  ADD_CUSTOMER_REP_FEEDBACK_FAILED,
  UPDATE_CUSTOMER_REP_FEEDBACK_REQUESTED,
  UPDATE_CUSTOMER_REP_FEEDBACK_SUCCEEDED,
  UPDATE_CUSTOMER_REP_FEEDBACK_FAILED,
  DELETE_CUSTOMER_REP_FEEDBACK_REQUESTED,
  DELETE_CUSTOMER_REP_FEEDBACK_SUCCEEDED,
  DELETE_CUSTOMER_REP_FEEDBACK_FAILED,
  UPVOTE_CUSTOMER_REP_FEEDBACK_REQUESTED,
  UPVOTE_CUSTOMER_REP_FEEDBACK_SUCCEEDED,
  UPVOTE_CUSTOMER_REP_FEEDBACK_FAILED,
  UPDATE_STATE_CUSTOMER_REP_FEEDBACK_SUCCEEDED,
  GET_REP_OPT_PROFILE_SUCCESS,
  GET_REP_OPT_PROFILE_REQUEST,
  GET_REP_OPT_PROFILE_FAILURE,
  GET_IMPROVED_REP_FEEDBACK_REQUEST,
  GET_IMPROVED_REP_FEEDBACK_REQUEST_SUCCESS,
  GET_IMPROVED_REP_FEEDBACK_REQUEST_FAILURE,
} from "../actions/repActions";
import {
  GET_COMPANY_REPLIST_FAILURE,
  GET_COMPANY_REPLIST_REQUEST,
  GET_COMPANY_REPLIST_SUCCESS,
} from "../actions/companyActions";

const initialState = {
  repList: [],
  repProfile: {
    // payload: {
    //   customerFeedbackList: [],
    // },
  },
  rateResponse: {},
  claimResponse: {},
  errorMessage: {},
  rateNewRepResponse: {},
  isFetchingRepList: false,
  isFetchingProfileRep: true,
  isFetchingRateRep: false,
  rateResponseReady: false,
  claimResponseReady: false,
  isFetchingClaimRep: false,
  trendsTimeFilter: null,
  trendsMetric: null,
  filteredTrendsData: {
    labels: [],
    values: [],
  },
  updatingProductSpeciality: false,

  updatingEmail: false,

  repViewLoading: false,
  repViewSuccess: false,
  repViewError: "",
  repView: {},
  contactRepEmailLoading: false,
  contactRepEmailSuccess: false,
  contactRepEmailError: "",
  disputeRepRequest: {},
  invitationTemplat: {},
  contactCompanyEmailLoading: false,
  contactCompanyEmailSuccess: false,
  contactCompanyEmailError: "",
  updatingFromLinkedin: false,
  updateCompanyLoading: false,
  updateCompanySuccess: false,
  updateCompanyError: "",
  addPrevCompanyLoading: false,
  addPrevCompanySuccess: false,
  addPrevCompanyResponse: "",
  addPrevCompanyError: "",
  deletePrevCompanyLoading: false,
  deletePrevCompanySuccess: false,
  deletePrevCompanyResponse: "",
  deletePrevCompanyError: "",

  addPreviousCompanyFetching: false,
  addPreviousCompanyError: null,
  previousCompanies: [],

  updateRepEmailFetching: false,
  updateRepEmailError: null,

  deleteRepEmailFetching: false,
  deleteRepEmailSucceeded: null,
  deleteRepEmailError: null,

  disconnectCompanyFetching: false,
  disconnectCompanyError: null,

  repData: {},
  repDataFetching: false,
  repDataError: null,

  repSuggestionsFetching: false,
  repSuggestions: [],
  repSuggestionsError: "",
  repSearch: "",

  addCustomerRepFeedBackLoading: false,
  addCustomerRepFeedBackSuccess: false,
  addCustomerRepFeedBackResponse: "",
  addCustomerRepFeedBackError: "",

  updateCustomerRepFeedBackLoading: false,
  updateCustomerRepFeedBackSuccess: false,
  updateCustomerRepFeedBackResponse: "",
  updateCustomerRepFeedBackError: "",

  deleteCustomerRepFeedBackLoading: false,
  deleteCustomerRepFeedBackSuccess: false,
  deleteCustomerRepFeedBackResponse: "",
  deleteCustomerRepFeedBackError: "",

  upVoteCustomerRepFeedBackLoading: false,
  upVoteCustomerRepFeedBackSuccess: false,
  upVoteCustomerRepFeedBackResponse: "",
  upVoteCustomerRepFeedBackError: "",
  customerFeedBAckFetched: false,
  repImprovedFeedbackPayload: {},
  repImprovedFeedbackError: {},
  repImprovedFeedbackLoading: false,
  viewCompanyRepProfileFetched: false,
};

function updateDisputeRepRequest(state, payload) {
  const { feedbackId, ...nextData } = payload;
  let request = { ...state.disputeRepRequest[feedbackId], ...nextData };
  let newState = {};
  newState.disputeRepRequest = { ...state.disputeRepRequest };
  newState.disputeRepRequest[feedbackId] = request;
  return { ...state, ...newState };
}

const repReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REP_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetchingRepList: true,
        repList: [],
        errorMessage: false,
      });
    case GET_REP_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetchingRepList: false,
        repList: action.payload,
        errorMessage: false,
      });
    case GET_REP_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetchingRepList: false,
        repList: [],
        errorMessage: action.payload,
      });

    case GET_REP_PROFILE_REQUEST:
      return {
        ...state,
        isFetchingProfileRep: true,
        errorMessage: false,
      };

    case GET_REP_PROFILE_SUCCESS:
      return {
        ...state,
        isFetchingProfileRep: false,
        repProfile: action.payload,
        errorMessage: false,
      };
    case GET_REP_PROFILE_FAILURE:
      return {
        ...state,
        isFetchingProfileRep: false,
        repProfile: {},
        errorMessage: action.payload,
      };

    case GET_OPTIMISED_REP_PROFILE_REQUEST:
      return {
        ...state,
        customerFeedBAckFetched: true,
        repProfile: {},
        errorMessage: false,
      };
    case GET_OPTIMISED_REP_PROFILE_SUCCESS:
      // console.log("RepProfile --> ", state.repProfile);
      return {
        ...state,
        customerFeedBAckFetched: false,
        // repProfile: action.payload,
        repProfile: {
          ...action.payload,
          payload: {
            ...state.repProfile?.payload,
            ...action.payload.payload,
            id: action.payload.payload?.repId,
          },
        },
        errorMessage: false,
      };
    case GET_OPTIMISED_REP_PROFILE_FAILURE:
      return {
        ...state,
        customerFeedBAckFetched: false,
        repProfile: {},
        errorMessage: action.payload,
      };

    case RATE_REP_PROFILE_REQUEST:
      return Object.assign({}, state, {
        isFetchingRateRep: true,
        rateResponse: {},
        rateResponseReady: false,
        errorMessage: false,
      });
    case RATE_REP_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        isFetchingRateRep: false,
        rateResponse: action.payload,
        rateResponseReady: true,
        errorMessage: false,
      });
    case RATE_REP_PROFILE_FAILURE:
      return Object.assign({}, state, {
        isFetchingRateRep: false,
        rateResponse: action.payload,
        errorMessage: action.payload,
        rateResponseReady: true,
      });
    case RATE_REP_PROFILE_RESET:
      return Object.assign({}, state, {
        isFetchingRateRep: false,
        rateResponse: {},
        rateResponseReady: false,
        errorMessage: false,
      });

    case SET_REP_TRENDS_TIME_FILTER:
      return Object.assign({}, state, { trendsTimeFilter: action.payload });
    case SET_REP_TRENDS_METRIC:
      return Object.assign({}, state, { trendsMetric: action.payload });
    case SET_REP_TRENDS_DATA:
      return Object.assign({}, state, { filteredTrendsData: action.payload });

    case UPDATE_REP_PRODUCT_SPECIALITY_REQUESTED: {
      return {
        ...state,
        updatingProductSpeciality: true,
      };
    }
    case UPDATE_REP_PRODUCT_SPECIALITY_SUCCEEDED: {
      return {
        ...state,
        updatingProductSpeciality: false,
      };
    }
    case UPDATE_REP_PRODUCT_SPECIALITY_FAILED: {
      return {
        ...state,
        updatingProductSpeciality: false,
      };
    }

    case UPDATE_REP_FROM_LINKEDIN_REQUESTED: {
      return {
        ...state,
        updatingFromLinkedin: true,
      };
    }
    case UPDATE_REP_FROM_LINKEDIN_SUCCEEDED: {
      return {
        ...state,
        updatingFromLinkedin: false,
      };
    }
    case UPDATE_REP_FROM_LINKEDIN_FAILED: {
      return {
        ...state,
        updatingFromLinkedin: false,
        updatingFromLinkedinError: action.payload,
      };
    }

    case GET_COMPANY_REPLIST_REQUEST:
      return {
        ...state,
        isFetchingRepList: true,
      };
    case GET_COMPANY_REPLIST_SUCCESS:
      return {
        ...state,
        isFetchingRepList: false,
        repList: action.payload,
        errorMessageRepList: false,
      };
    case GET_COMPANY_REPLIST_FAILURE:
      return {
        ...state,
        isFetchingRepList: false,
        errorMessageRepList: action.payload,
      };

    case UPDATE_COMPANY_USER_INFO_REQUEST: {
      return {
        ...state,
        updateCompanyLoading: true,
      };
    }
    case UPDATE_COMPANY_USER_INFO_SUCCESS: {
      return {
        ...state,
        updateCompanyLoading: false,
        updateCompanySuccess: true,
      };
    }
    case UPDATE_COMPANY_USER_INFO_FAILURE: {
      return {
        ...state,
        updateCompanyLoading: false,
        updateCompanyError: action.payload,
      };
    }

    case ADD_PREVIOUS_COMPANY_REQUESTED: {
      return {
        ...state,
        addPreviousCompanyFetching: true,
      };
    }
    case ADD_PREVIOUS_COMPANY_SUCCEEDED: {
      return {
        ...state,
        addPreviousCompanyFetching: false,
        addPreviousCompanyError: null,
        previousCompanies: action.payload,
      };
    }
    case ADD_PREVIOUS_COMPANY_FAILED: {
      return {
        ...state,
        addPreviousCompanyFetching: false,
        addPreviousCompanyError: action.payload,
      };
    }

    case DELETE_COMPANY_USER_REQUEST: {
      return {
        ...state,
        deletePrevCompanyLoading: true,
      };
    }
    case DELETE_COMPANY_USER_SUCCESS: {
      return {
        ...state,
        deletePrevCompanyLoading: false,
        deletePrevCompanySuccess: true,
      };
    }
    case DELETE_COMPANY_USER_FAILURE: {
      return {
        ...state,
        deletePrevCompanyLoading: false,
        deletePrevCompanyError: action.payload,
      };
    }

    case UPDATE_REP_EMAIL_REQUESTED: {
      return {
        ...state,
        updateRepEmailFetching: true,
        updateRepEmailError: null,
      };
    }
    case UPDATE_REP_EMAIL_SUCCEEDED: {
      return {
        ...state,
        updateRepEmailFetching: false,
        updateRepEmailError: null,
      };
    }
    case UPDATE_REP_EMAIL_FAILED: {
      return {
        ...state,
        updateRepEmailFetching: false,
        updateRepEmailError: action.payload,
      };
    }

    case DELETE_REP_EMAIL_REQUESTED: {
      return {
        ...state,
        deleteRepEmailFetching: true,
        deleteRepEmailSucceeded: false,
        deleteRepEmailError: null,
      };
    }
    case DELETE_REP_EMAIL_SUCCEEDED: {
      return {
        ...state,
        deleteRepEmailFetching: false,
        deleteRepEmailSucceeded: true,
        deleteRepEmailError: null,
      };
    }
    case DELETE_REP_EMAIL_FAILED: {
      return {
        ...state,
        deleteRepEmailFetching: false,
        deleteRepEmailSucceeded: false,
        deleteRepEmailError: action.payload,
      };
    }

    case RATE_NEW_REP_PROFILE_REQUEST:
      return Object.assign({}, state, {
        isFetchingRateNewRep: true,
        rateNewRepResponse: {},
        rateNewRepResponseReady: false,
        errorMessage: false,
      });
    case RATE_NEW_REP_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        isFetchingRateNewRep: false,
        rateNewRepResponse: action.payload,
        rateNewRepResponseReady: true,
        errorMessage: false,
      });
    case RATE_NEW_REP_PROFILE_FAILURE:
      return Object.assign({}, state, {
        isFetchingRateNewRep: false,
        rateNewRepResponse: action.payload,
        rateNewRepResponseReady: true,
        errorMessage: action.payload,
      });
    case RATE_NEW_REP_PROFILE_GO_BACK:
      return Object.assign({}, state, {
        isFetchingRateNewRep: false,
        rateNewRepResponse: {},
        rateNewRepResponseReady: false,
        errorMessage: false,
      });

    case CLAIM_PROFILE_REQUEST:
      return Object.assign({}, state, {
        isFetchingClaimRep: true,
        claimResponse: {},
        claimResponseReady: false,
        errorMessage: false,
      });
    case CLAIM_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        isFetchingClaimRep: false,
        claimResponse: action.payload,
        claimResponseReady: true,
        errorMessage: false,
      });
    case CLAIM_PROFILE_FAILURE:
      return Object.assign({}, state, {
        isFetchingClaimRep: false,
        claimResponse: action.payload,
        claimResponseReady: true,
        errorMessage: action.payload,
      });
    case CLAIM_PROFILE_RESET:
      return {
        ...state,
        isFetchingClaimRep: false,
        claimResponse: {},
        claimResponseReady: false,
        errorMessage: false,
      };

    case DISPUTE_REP_REQUEST:
      return updateDisputeRepRequest(state, {
        ...action.payload,
        isFetchingDispute: true,
        disputeResponseReady: false,
      });
    case DISPUTE_REP_SUCCESS:
      return updateDisputeRepRequest(state, {
        ...action.payload,
        isFetchingDispute: false,
        disputeResponseReady: true,
      });
    case DISPUTE_REP_FAILURE:
      return updateDisputeRepRequest(state, {
        ...action.payload,
        isFetchingDispute: false,
        disputeResponseReady: true,
        errorMessage: action.payload.disputeResponse,
      });

    case SET_REP_SEARCH_STATE:
      return Object.assign({}, state, {
        repSearch: action.repSearch,
      });

    case INVITATION_TEMPLATE_REQUEST:
      return Object.assign({}, state, {
        invitationTemplateLoading: true,
        invitationTemplateSuccess: false,
        invitationTemplateError: false,
      });
    case INVITATION_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        invitationTemplateLoading: false,
        invitationTemplateSuccess: true,
        invitationTemplateError: false,
        invitationTemplateData: action.payload.invitationTemplate.payload,
      });
    case INVITATION_SET_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        invitationTemplateLoading: false,
        invitationTemplateSuccess: true,
        invitationTemplateError: false,
        invitationTemplateData: [action.payload.invitationTemplate.payload],
      });
    case INVITATION_TEMPLATE_FAILURE:
      return Object.assign({}, state, {
        invitationTemplateLoading: false,
        invitationTemplateSuccess: false,
        invitationTemplateError: action.payload,
      });

    case REP_PROFILE_VIEW_REQUEST:
      return Object.assign({}, state, {
        repViewLoading: true,
        repViewSuccess: false,
        repViewError: false,
      });
    case REP_PROFILE_VIEW_SUCCESS:
      return Object.assign({}, state, {
        repViewLoading: false,
        repViewSuccess: true,
        repViewError: false,
        repView: action.payload,
      });
    case REP_PROFILE_VIEW_FAILURE:
      return Object.assign({}, state, {
        repViewLoading: false,
        repViewSuccess: false,
        repViewError: action.payload,
      });

    case CONTACT_REP_EMAIL_RESET:
      return Object.assign({}, state, {
        contactRepEmailLoading: false,
        contactRepEmailSuccess: false,
        contactRepEmailError: "",
      });
    case CONTACT_REP_EMAIL_REQUEST:
      return Object.assign({}, state, {
        contactRepEmailLoading: true,
        contactRepEmailSuccess: false,
        contactRepEmailError: "",
      });
    case CONTACT_REP_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        contactRepEmailLoading: false,
        contactRepEmailSuccess: true,
        contactRepEmailError: "",
      });
    case CONTACT_REP_EMAIL_FAILURE:
      return Object.assign({}, state, {
        contactRepEmailLoading: false,
        contactRepEmailSuccess: false,
        contactRepEmailError: action.payload,
      });

    case CONTACT_COMPANY_EMAIL_RESET:
      return Object.assign({}, state, {
        contactCompanyEmailLoading: false,
        contactCompanyEmailSuccess: false,
        contactCompanyEmailError: "",
      });
    case CONTACT_COMPANY_EMAIL_REQUEST:
      return Object.assign({}, state, {
        contactCompanyEmailLoading: true,
        contactCompanyEmailSuccess: false,
        contactCompanyEmailError: "",
      });
    case CONTACT_COMPANY_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        contactCompanyEmailLoading: false,
        contactCompanyEmailSuccess: true,
        contactCompanyEmailError: "",
      });
    case CONTACT_COMPANY_EMAIL_FAILURE:
      return Object.assign({}, state, {
        contactCompanyEmailLoading: false,
        contactCompanyEmailSuccess: false,
        contactCompanyEmailError: action.payload,
      });

    case DISCONNECT_COMPANY_REQUESTED:
      return {
        ...state,
        disconnectCompanyFetching: true,
      };
    case DISCONNECT_COMPANY_SUCCEEDED:
      return {
        ...state,
        disconnectCompanyFetching: false,
      };
    case DISCONNECT_COMPANY_FAILED:
      return {
        ...state,
        disconnectCompanyFetching: false,
        disconnectCompanyError: action.payload,
      };

    case CLEAR_ERRORS_REQUESTED:
      return {
        ...state,
        disconnectCompanyError: null,
      };

    case GET_REP_PROFILE_REQUESTED:
      return {
        ...state,
        isFetchingProfileRep: true,
        repDataFetching: true,
      };
    case GET_REP_PROFILE_SUCCEEDED:
      return {
        ...state,
        repDataFetching: false,
        repDataError: null,
        repData: action.payload,
      };
    case GET_REP_PROFILE_FAILED:
      return {
        ...state,
        repDataFetching: false,
        repDataError: action.payload,
      };

    case GET_REP_SUGGESTIONS_REQUESTED:
    case GET_COMPANY_REP_SUGGESTIONS_REQUESTED:
      return {
        ...state,
        repSuggestionsFetching: true,
      };

    case GET_REP_SUGGESTIONS_SUCCEEDED:
    case GET_COMPANY_REP_SUGGESTIONS_SUCCEEDED:
      return {
        ...state,
        repSuggestionsFetching: false,
        repSuggestions: action.payload,
        repSuggestionsError: "",
      };
    case GET_REP_SUGGESTIONS_FAILED:
    case GET_COMPANY_REP_SUGGESTIONS_FAILED:
      return {
        ...state,
        repSuggestionsFetching: false,
        repSuggestionsError: action.payload,
      };
    /**************************/
    case ADD_CUSTOMER_REP_FEEDBACK_REQUESTED: {
      return {
        ...state,
        addCustomerRepFeedBackLoading: true,
      };
    }
    case ADD_CUSTOMER_REP_FEEDBACK_SUCCEEDED: {
      return {
        ...state,
        addCustomerRepFeedBackLoading: false,
        addCustomerRepFeedBackSuccess: true,
        addCustomerRepFeedBackResponse: action.payload,
      };
    }
    case ADD_CUSTOMER_REP_FEEDBACK_FAILED: {
      return {
        ...state,
        addCustomerRepFeedBackLoading: false,
        addCustomerRepFeedBackSuccess: false,
        addCustomerRepFeedBackError: action.payload,
      };
    }

    case UPDATE_CUSTOMER_REP_FEEDBACK_REQUESTED: {
      return {
        ...state,
        updateCustomerRepFeedBackLoading: true,
      };
    }
    case UPDATE_CUSTOMER_REP_FEEDBACK_SUCCEEDED: {
      return {
        ...state,
        updateCustomerRepFeedBackLoading: false,
        updateCustomerRepFeedBackSuccess: true,
        updateCustomerRepFeedBackResponse: action.payload,
      };
    }
    case UPDATE_CUSTOMER_REP_FEEDBACK_FAILED: {
      return {
        ...state,
        updateCustomerRepFeedBackLoading: false,
        updateCustomerRepFeedBackSuccess: false,
        updateCustomerRepFeedBackError: action.payload,
      };
    }
    case DELETE_CUSTOMER_REP_FEEDBACK_REQUESTED: {
      return {
        ...state,
        deleteCustomerRepFeedBackLoading: true,
      };
    }
    case DELETE_CUSTOMER_REP_FEEDBACK_SUCCEEDED: {
      return {
        ...state,
        deleteCustomerRepFeedBackLoading: false,
        deleteCustomerRepFeedBackSuccess: true,
        deleteCustomerRepFeedBackResponse: action.payload,
      };
    }
    case DELETE_CUSTOMER_REP_FEEDBACK_FAILED: {
      return {
        ...state,
        deleteCustomerRepFeedBackLoading: false,
        deleteCustomerRepFeedBackSuccess: false,
        deleteCustomerRepFeedBackError: action.payload,
      };
    }
    case UPVOTE_CUSTOMER_REP_FEEDBACK_REQUESTED: {
      return {
        ...state,
        upVoteCustomerRepFeedBackLoading: true,
      };
    }
    case UPDATE_STATE_CUSTOMER_REP_FEEDBACK_SUCCEEDED: {
      return {
        ...state,
        repProfile: {
          ...state.repProfile,
          payload: {
            ...state.repProfile.payload,
            customerFeedbackList: [...action.payload],
          },
        },
      };
    }

    case UPVOTE_CUSTOMER_REP_FEEDBACK_SUCCEEDED: {
      return {
        ...state,
        upVoteCustomerRepFeedBackLoading: false,
        upVoteCustomerRepFeedBackSuccess: true,
        upVoteCustomerRepFeedBackResponse: action.payload,
      };
    }

    case UPVOTE_CUSTOMER_REP_FEEDBACK_FAILED: {
      return {
        ...state,
        upVoteCustomerRepFeedBackLoading: false,
        upVoteCustomerRepFeedBackSuccess: false,
        upVoteCustomerRepFeedBackError: action.payload,
      };
    }

    case GET_REP_OPT_PROFILE_REQUEST:
      return {
        ...state,
        viewCompanyRepProfileFetched: false,
        isFetchingProfileRep: true,
        errorMessage: false,
      };

    case GET_REP_OPT_PROFILE_SUCCESS: {
      return {
        ...state,
        errorMessage: false,
        isFetchingProfileRep: false,
        viewCompanyRepProfileFetched: true,
        repProfile: {
          ...state.repProfile,
          payload: {
            ...action.payload.payload,
            customerFeedbackList: [
              ...action.payload.payload.customerFeedbackList,
            ],
          },
        },
      };
    }
    case GET_REP_OPT_PROFILE_FAILURE:
      return {
        ...state,
        viewCompanyRepProfileFetched: true,
      };
    case GET_IMPROVED_REP_FEEDBACK_REQUEST: {
      return {
        ...state,
        repImprovedFeedbackLoading: true,
      };
    }
    case GET_IMPROVED_REP_FEEDBACK_REQUEST_SUCCESS: {
      return {
        ...state,
        repImprovedFeedbackLoading: false,
        repImprovedFeedbackPayload: action.payload,
        // repProfile: {
        //   ...state.repProfile,
        //   payload: {
        //     ...state.repProfile.payload,
        //     customerFeedbackList: action.payload.payload,
        //   },
        // },
      };
    }
    case GET_IMPROVED_REP_FEEDBACK_REQUEST_FAILURE: {
      return {
        ...state,
        repImprovedFeedbackLoading: false,
        repImprovedFeedbackError: action.payload,
      };
    }

    /************************************/

    default:
      return state;
  }
};

export default repReducer;
