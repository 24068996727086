import axios from "axios";
import authService from "../../../services/AuthService";
import urls from "../../../../src/config"
const authdAxios = axios.create();

const ALLOWED_URLS = [
  `${urls.PASSPORT_API}/api/auth/verify-site`,
  `${urls.PASSPORT_API}/api/auth/sign-in`,
  `${urls.PASSPORT_API}/api/social-media/get-url`,
  `${urls.PASSPORT_API}/api/social-media/sign-in`,
  `${urls.LOCAL_ROOT_URL}/signup`,
  `${urls.PASSPORT_API}/api/auth/sign-up`,
  `${urls.PASSPORT_API}/api/social-media/create-user`,
  `${urls.PASSPORT_API}/api/social-media/get-user-information`,
  `${urls.NOTIFICATION_API}/api/notification/contact-us`,
  `${urls.PASSPORT_API}/api/auth/confirm-email`,
  `${urls.COMPANY_API}/api/company/invited-info`,
  `${urls.COMPANY_API}/api/company/add`,
  `${urls.PASSPORT_API}/api/auth/forgot-password`,
  `${urls.PASSPORT_API}/api/auth/confirm-code`,
  `${urls.PASSPORT_API}/api/auth/confirm`,
  `${urls.PASSPORT_API}/api/auth/company/sign-up`,
  `${urls.PASSPORT_API}/api/auth/sign-in-after-verification`,
  `${urls.CREATE_COMPANY_API}/auth/company/sign-up`,
];

// Add a request interceptor
authdAxios.interceptors.request.use(
  function (config) { // Do something before request is sent
    if (authService.isLoggedIn()) { // add token header 
      const token = authService.getToken();
      config.headers["Authorization"] = `Bearer ${token}`;
    } else if (!ALLOWED_URLS.includes(config.url)) { // log out, clear session & cancel request
      authService.logout();
      return false;
    }
    return config;
  },
  function (error) { // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
authdAxios.interceptors.response.use(
  function (response) { // Do something with response data
    if (!authService.isLoggedIn() && !ALLOWED_URLS.includes(response.config.url)) { // log out, clear session & cancel request
      authService.logout();
      return false;
    }
    return response;
  },
  function (error) { // Do something with response error
    throw error.response;
  }
);

export default authdAxios;
