import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { reporaColor } from "../../components/global/commonStyles";

const Wrapper = styled.div`
  height:100%;
  display: flex;
  justify-content: center;
  iframe{
    width:100%;
    border:none;
    ::-webkit-scrollbar {
        background: black !important;
    }
  }
`;

class CustomerReferral extends React.Component {
  state = {
  };

  render() {

    return (
      <Wrapper>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScTy0w2C7A5lxAu-_0UbkbipEUMHm-zBwXVKDKjqY8wLK7dWQ/viewform?embedded=true%22"></iframe>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
  
});

export const Unwrapped = CustomerReferral;
export default connect(mapStateToProps, mapDispatchToProps)(CustomerReferral);
