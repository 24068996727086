import { get } from "lodash";

import authdAxios from "../modules/Authentication/utils/authdAxios";
import constants from "../constants";
import config from "../config";

const NotificationService = () => {
  function submitContactUs(firstName, lastName, email, phoneNumber, message, isCompany) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.NOTIFICATION_API}/api/notification/contact-us`,
          {
            firstName,
            lastName,
            emailTo: email,
            phoneNumberTo: phoneNumber,
            message,
            company: isCompany
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data.payload);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    })
  }

  return {
    submitContactUs
  };
};

export default NotificationService();
