import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { reporaColor, reporaDevice, FormGroup } from "../../../components/global/commonStyles";
import Button from "../../../components/Buttons/Button";
import Loader from "../../../components/Loader/Loader";
import "./style.css"
const Wrapper = styled.section`
  padding: 15px;
`;

const FormWrapper = styled.form`
  position: relative;
  background: gold;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 0 8px 0 #00000033;
  max-width: 500px;
  width: 100%;
  margin-top: 80px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  padding: 35px 60px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  h1 {
    font-size: 28px;
    width: 100%;
    text-align: center;
  }

  @media ${reporaDevice.xs} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const CloseButton = styled.span`
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 20px;
  cursor: pointer;

  a {
    color: ${reporaColor.warmGrey};
  }
`;

export const Error = styled.div`
  margin-top: 20px;
  color: ${reporaColor.watermelon};
`;

class ConfirmationForm extends React.Component {
  state = {
    password: "",
    msg: ""
  }

  handleSubmit = event => {
    event.preventDefault();

    const { confirmCode, username, role } = this.props;
    const { password } = this.state;

    this.props.onSubmit({
      confirmCode: confirmCode,
      password: password,
      role: role,
      username: username
    });
  }

  handleResendSubmit = event => {
    event.preventDefault();
    const { confirmCode, username, role } = this.props;
    const { password } = this.state;
    this.props.resendConfirmation({
      confirmCode: confirmCode,
      password: password,
      role: role,
      username: username
    });
    this.setState({ msg: true })
  }


  handlePasswordChange = event => {
    this.setState({
      password: event.target.value
    });
  }

  render() {
    const { username, isSocial, loading, error } = this.props;
    const { password } = this.state;
    const isValid = isSocial ? true : (username && this.state.password);
    let formButton = <Button className="m-t-20" disabled={!isValid} onClick={this.handleSubmit}>Confirm</Button>
    let tempVar = error;
    if (error === "There was a connection error in the system") {
      tempVar = "Your verification link has expired. Please click below to request a new one."
      formButton = <Button className="m-t-20" disabled={!isValid} onClick={this.handleResendSubmit}>Resend Email</Button>
    }
    let tag = "";
    if(this.state.msg && loading){
      formButton = Loader({overlayed:true,color:'black', size: "medium"});
    }
    if (this.state.msg && !loading && error == "") {
      tempVar = "Please check your Email,We have sent a link.";
      tag = <>
        <div className="success-animation">
          <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
        </div>
        <h2>We have sent a verification link to your email please verify your mail</h2>
      </>
    } else {
      tag = <>
        <h1>Repora Confirm Account</h1>
        <div>
          <FormGroup>
            <label>Email</label>
            <input
              disabled
              name="username"
              value={username}
            />
          </FormGroup>

          {!isSocial &&
            <FormGroup>
              <label>Password</label>
              <input
                name="password"
                required
                type="password"
                value={password}
                onChange={this.handlePasswordChange}
              />
            </FormGroup>
          }

          {tempVar && <Error>{tempVar}</Error>}
        </div>
        {formButton}

      </>

    }
    // "This action could not be completed at this moment. Check with your System Administrator"
    return (
      <Wrapper>
        <FormWrapper>
          {loading && !this.state.msg && <Loader overlayed size="medium" />}

          <CloseButton>
            <Link to="/">
              <FontAwesomeIcon icon={faTimes} />
            </Link>
          </CloseButton>
          {tag}
           </FormWrapper>
      </Wrapper>
    );
  }
}

export default ConfirmationForm;
