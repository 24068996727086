import React from "react";
import { connect } from "react-redux";
import { withRouter, NavLink, Redirect } from "react-router-dom";
import styled from "styled-components";
import _ from "lodash";
import { isEqual } from "lodash";
import {
  reporaColor,
  reporaDevice,
  reporaSize,
} from "../../components/global/commonStyles";
import { getReps, setingRepSearchStates } from "../../actions/repActions";
import { getCompanies } from "../../actions/companyActions";
import { openMenu, closeMenu } from "../../actions/menuActions";
import { logout } from "../../actions/authActions";
import { openModalWithContent } from "../../actions/modalActions";
import {
  searchReps,
  resetSearchReps,
  getCompanyReps,
  clearSearchInfo,
  setReducerRepSearchFields,
} from "../../actions/searchActions";
import {
  MenuItem,
  MenuDropdownItem,
  RedirectButton,
} from "./components/MenuItem";
import UserDropDown from "./components/UserDropDown";
import constants from "../../constants";
import NoCompanyLogoPng from "../../assets/global/noCompanyLogo.png";
import HeaderSearchRep from "../../scenes/SearchRep/HeaderSearchRep";
import HeaderSearchRepUnclaimed from "../../scenes/SearchRepUnclaimed/HeaderSearchRepUnclaimed";
import UserProfilePicture from "./components/UserProfilePicture";

const ButtonHeader = styled(RedirectButton)`
  background-color: ${reporaColor.grey91};
  color: #040404;
  font-size: 16px;
  font-weight: bold;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  right: 100%;
  transition: right 0.5s;
  background-color: ${reporaColor.white};
  overflow: auto;
  z-index: 99;
  &.is-open {
    right: 0;
    z-index: 10;
  }

  @media ${reporaDevice.tablet} {
    width: 100%;
    height: 100%;
    position: relative;
    right: auto;
    min-height: ${(props) => (props.big ? "100px" : "68px")};
    overflow: visible;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
  }
`;

const MenuContent = styled.div`
  &.updated-header-menu-content {
    ${
      "" /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    }

    @media (max-width: ${reporaSize.small}) {
      padding-top: 100px;
    }
  }

  @media ${reporaDevice.tablet} {
    width: 100%;
    min-height: 100%;
    display: flex;
    max-width: 1440px;
    padding: 0 10px;
    margin: 0 auto;
    align-items: center;
  }

  @media ${reporaDevice.desktop} {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;

  a {
    line-height: 0;
    text-decoration: none;
  }

  @media ${reporaDevice.mobile} {
    margin: 0;
    padding: 15px 0;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  padding: 0 10px;

  &.updated-header-content-container {
    @media (max-width: ${reporaSize.small}) {
      margin: 0;
    }
  }

  @media ${reporaDevice.tablet} {
    align-items: center;
    flex-direction: row;
    padding: 0;
  }

  @media ${reporaDevice.mobile} {
    margin: 20px 0;

    /* form {
      flex-direction: column-reverse;
      align-items: center;

      input {
        margin-top: 5px;
      }
    } */
  }

  @media ${reporaDevice.xs} {
    form {
      width: 100%;
    }
  }
`;

const UserSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;

  > a:first-child {
    margin-right: 10px;
  }

  @media ${reporaDevice.mobile} {
    margin: 0;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const UserName = styled.div`
  margin: 0 15px;
`;

const CompanyLogoWrapper = styled.div`
  height: 40px;
  width: 40px;
  margin: 0 15px;
  // border-radius: 50%;
  border: 1px solid #dedede;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 40px;
  overflow: hidden;
  ${(props) =>
    props.imageUrl ? `background-image :url('${props.imageUrl}')` : ""};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const UserDropDownOptions = styled.ul`
  color: rgba(0, 0, 0, 0.5);

  a {
    text-decoration: none;
    color: #040404;
    margin: 0;
    padding: 10px 20px;
    display: block;
  }

  li {
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }

    ${
      "" /* &.logout a {
       color: rgb(105, 105, 105);
    } */
    }
    &.logout a {
      padding: 10px 20px;
      color: rgb(105, 105, 105);
    }

    &.logout2 {
      padding: 10px 20px;
      color: rgb(105, 105, 105);
    }
  }
`;

const UserSectionWrapper = styled.div`
  margin-top: 0;
`;

const LinksContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${reporaDevice.xs} {
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
  }
`;
const Dummy = styled.div`
  padding: 15px 30px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
`;
const LogoContainer = styled.div`
  width: 10%;
  margin-right: 2%;
  background-image: ${(props) =>
    props.bkgImage ? `url(${props.bkgImage})` : "none"};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 30px;

  @media (max-width: ${reporaSize.small}) {
    width: 100%;
    height: 40px;
  }

  a {
    display: block;
    height: 30px;
  }
`;

const UNCLAIMED_REPS_PATH = "/searchunclaimedaccount";
const LOGIN_PATH = "/login";

class Menu extends React.Component {
  state = {
    isCustomer: false,
    companyId: "",
    companyNameField: "",
    repId: "",
    repNameField: "",
    redirectLink: "",
    disableSearchButton: true,

    headerSize: null,
  };

  updateComponentStates(isAuthenticated, updateState) {
    const { user, handleGetSearch, handleSearchCompanyReps, history } =
      this.props;

    if (isAuthenticated) {
      if (history.location.pathname === UNCLAIMED_REPS_PATH) {
        this.props.handleGetSearch({ onlyUnclaimReps: true });
      } else {
        handleGetSearch();
      }
      if (user) {
        handleSearchCompanyReps(user.companyId);
      }
    }

    if (updateState) {
      this.setState({
        repNameField: this.props.searchParameters.nameField,
        selectedReps: this.props.searchParameters.repList,
        companyId: this.props.searchParameters.companyId,
        companyNameField: this.props.searchParameters.companyField,
      });
    }

    this.getHeaderSize();
  }

  componentDidMount() {
    this.updateComponentStates(
      this.props.isAuthenticated,
      this.props.searchParameters.nameField ||
        this.props.searchParameters.companyField
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.handleCloseMenu();
    }

    if (this.props.companylogBlob && !localStorage.getItem("CompanyLogo")) {
      setTimeout(() => {
        this.setState({});
      }, 1000);
    }

    this.updateComponentStates(
      this.props.isAuthenticated !== prevProps.isAuthenticated &&
        this.props.isAuthenticated,
      this.props.searchParameters.nameField !==
        prevProps.searchParameters.nameField ||
        this.props.searchParameters.companyField !==
          prevProps.searchParameters.companyField
    );
  }

  componentWillReceiveProps(nextProps) {
    const { isAuthenticated } = nextProps;
    if (isAuthenticated) {
      const { pathname } = this.props.location;
      const nextPathname = nextProps.location.pathname;
      // If it's coming to unclaimed from a different path, load only the unclaimed reps
      if (nextPathname === UNCLAIMED_REPS_PATH && nextPathname !== pathname) {
        this.props.handleGetSearch({ onlyUnclaimReps: true });
      } else if (
        nextPathname !== UNCLAIMED_REPS_PATH &&
        pathname !== LOGIN_PATH &&
        nextPathname !== pathname
      ) {
        this.props.handleGetSearch();
      }
    }
    this.setState({ redirectLink: "" });
  }

  getHeaderSize = () => {
    const header = document.querySelector("#header");

    if (header) {
      const size = header.getBoundingClientRect();

      if (
        !this.state.headerSize ||
        this.state.headerSize.height !== size.height
      ) {
        this.props.onSizeChange(size);

        this.setState({
          headerSize: size,
        });
      }
    }
  };

  handleRepNameChange = (newValue) => {
    const { companyNameField } = this.state;
    const emptyValue = _.isEmpty(newValue);
    this.setState({
      repId: "",
      repNameField: emptyValue ? "" : newValue,
      disableSearchButton: emptyValue && _.isEmpty(companyNameField),
    });
  };

  handleCompanyNameChange = (newValue) => {
    const { repNameField } = this.state;
    const emptyValue = _.isEmpty(newValue);
    this.setState({
      companyId: "",
      companyNameField: emptyValue ? "" : newValue,
      disableSearchButton: emptyValue && _.isEmpty(repNameField),
    });
  };

  headerHandler() {
    this.setState({ isCustomer: !this.state.isCustomer });
  }

  handleCloseMenu = () => {
    this.props.handleCloseMenu();
    console.log(
      // "this.props.handleCloseMenu()-->>>",
      this.props.handleCloseMenu()
    );
  };

  filterHelper(companylist, companyId) {
    return (
      companylist &&
      companylist.some((company) => company.companyId === companyId)
    );
  }

  handleCustomerSearch = (repList, companyId, nameField, companyName) => {
    this.props.handleSearchReps(repList, companyId, nameField, companyName);

    if (this.props.history.location.pathname !== UNCLAIMED_REPS_PATH) {
      this.props.history.push("/searchrep/results");
    }
  };

  handleSubmitSearch = () => {
    const { handleSearchReps, history } = this.props;
    const { companyNameField, repNameField } = this.state;
    let { repId, companyId } = this.state;
    let repsList = this.props.reps;
    let companiesList = this.props.companies;

    if (!companyId && !!companyNameField) {
      const company = companiesList.find(
        (company) =>
          company &&
          company.name.toLowerCase() === companyNameField.toLowerCase()
      );
      companyId = company ? company.id : "none";
    }

    if (!repId) {
      if (!!repNameField) {
        repsList = repsList.filter((rep) =>
          rep.name.toLowerCase().startsWith(repNameField.toLowerCase())
        );
      }
    } else {
      repsList = [repsList.find((rep) => rep.id === repId)];
    }

    if (!!companyId) {
      if (!!repId) {
        repsList = repsList.filter((rep) =>
          this.filterHelper(rep.companyList, companyId)
        );
      } else {
        repsList = repsList.filter((rep) => rep.companyId === companyId);
      }
    }
    repsList = repsList.map((rep) => rep.id);

    this.handleCloseMenu();
    if (history.location.pathname !== UNCLAIMED_REPS_PATH) {
      history.push("/searchrep/results");
    }

    handleSearchReps(repsList, companyId, repNameField, companyNameField);
  };

  handleSelectCompany = (company) => {
    if (company && company.id) {
      this.setState({
        companyId: company.id,
        companyNameField: company.name,
      });
    }
  };

  handleSelectRep = (rep) => {
    if (rep && rep.id) {
      this.setState({
        repId: rep.id,
        repNameField: rep.name,
      });
    }
  };

  handleLogOut = () => {
    const { handleLogout, history } = this.props;

    handleLogout(history);
    if (
      history.location.pathname == "/raterep" ||
      history.location.pathname == "/rateProgram" ||
      history.location.pathname == "/rateTeam"
    ) {
      history.push("/");
    }
    if (history.location.pathname == "/viewrepcustomer") {
      history.push("/");
    }
  };

  handleAdminLogOut = () => {
    const { handleLogout, history } = this.props;
    handleLogout(history);
    history.push("/admin");
  };

  isDevEnv = () =>
    process.env.REACT_APP_NODE_ENV === "development" ||
    process.env.REACT_APP_NODE_ENV === "local" ||
    process.env.REACT_APP_NODE_ENV === "stagging" ||
    process.env.REACT_APP_NODE_ENV === "test" ||
    process.env.REACT_APP_NODE_ENV === "prod";

  renderUpdatedPublicHeader = () => {
    return (
      <Wrapper
        id="header"
        innerRef={(elem) => (this.menuWrapper = elem)}
        className={`menu ${this.props.isOpen ? "is-open" : ""}`}
      >
        <MenuContent className="updated-header-menu-content">
          <LogoContainer bkgImage="/assets/global/logo.svg">
            {/* <a href="https://repora.wpenginepowered.com/" target="_blank" /> */}
            <NavLink to="/" onClick={this.handleCloseMenu} />
          </LogoContainer>

          <ContentContainer className="updated-header-content-container">
            <LinksContainer>
              <MenuItem
                to="/featurescompanies"
                title="Companies"
                alt="Companies button"
                onClick={this.handleCloseMenu}
              />
              <MenuItem
                to="/featurescustomers"
                title="Customers"
                alt="Customers button"
                onClick={this.handleCloseMenu}
              />
              <MenuItem
                to="/featuresreps"
                title="Representatives"
                alt="Representatives button"
                onClick={this.handleCloseMenu}
              />
              <MenuItem
                to="/pricing"
                title="Pricing"
                alt="Pricing button"
                onClick={this.handleCloseMenu}
              />
            </LinksContainer>
          </ContentContainer>

          {this.isDevEnv() && (
            <UserSection>
              <RedirectButton
                to="/login"
                small="true"
                secondary="true"
                onClick={this.handleCloseMenu}
              >
                Log In
              </RedirectButton>
              <RedirectButton
                to="/signup"
                small="true"
                onClick={this.handleCloseMenu}
              >
                Sign Up
              </RedirectButton>
            </UserSection>
          )}
        </MenuContent>
      </Wrapper>
    );
  };

  renderPublicHeader = () => {
    return (
      <Wrapper
        id="header"
        innerRef={(elem) => (this.menuWrapper = elem)}
        className={`menu ${this.props.isOpen ? "is-open" : ""}`}
      >
        <MenuContent>
          <LogoContainer bkgImage="/assets/global/logo.svg">
            <NavLink to="/" onClick={this.handleCloseMenu} />
          </LogoContainer>

          <ContentContainer>
            <LinksContainer>
              {this.isDevEnv() && (
                <>
                  <MenuItem
                    to="/"
                    title="Home"
                    alt="Home button"
                    onClick={this.handleCloseMenu}
                  />
                  <MenuDropdownItem
                    onClick={this.handleCloseMenu}
                    title="Features"
                    alt="Features button"
                    subItems={{
                      featurescompanies: "For Companies",
                      featurescustomers: "For Customers",
                      featuresreps: "For Reps",
                    }}
                  />
                  <MenuItem
                    to="/pricing"
                    title="Pricing"
                    alt="Pricing button"
                    onClick={this.handleCloseMenu}
                  />
                </>
              )}
            </LinksContainer>
          </ContentContainer>

          <UserSection>
            <RedirectButton
              to="/login"
              small="true"
              secondary="true"
              onClick={this.handleCloseMenu}
              showBorder={!this.isDevEnv()}
            >
              Log In
            </RedirectButton>
            {this.isDevEnv() && (
              <RedirectButton
                to="/signup"
                small="true"
                onClick={this.handleCloseMenu}
              >
                Sign Up
              </RedirectButton>
            )}
          </UserSection>
        </MenuContent>
      </Wrapper>
    );
  };

  renderPublicHeader2 = () => {
    return (
      <Wrapper
        id="header"
        innerRef={(elem) => (this.menuWrapper = elem)}
        className={`menu ${this.props.isOpen ? "is-open" : ""}`}
      >
        <MenuContent>
          <LogoContainer bkgImage="/assets/global/logo.svg">
            <NavLink to="/" onClick={this.handleCloseMenu} />
          </LogoContainer>
        </MenuContent>
      </Wrapper>
    );
  };

  renderAdminHeader = () => {
    const { user, isAuthenticated, location } = this.props;
    return (
      <Wrapper
        id="header"
        innerRef={(elem) => (this.menuWrapper = elem)}
        className={`menu ${this.props.isOpen ? "is-open" : ""}`}
      >
        <MenuContent>
          <LogoContainer bkgImage="/assets/global/logo.svg">
            {/* <NavLink to="/" onClick={this.handleCloseMenu} /> */}
          </LogoContainer>
          <ContentContainer className="justify-content-center" />
          {
            <UserSection>
              {/* <UserName>{user && user.firstName}</UserName> */}
              {/* <UserDropDown>
              <UserDropDownOptions>
                <li><NavLink exact to="/admin/pannel" onClick={this.handleCloseMenu}>Dashboard</NavLink></li>
                <li><NavLink exact to="/admin/dispute" onClick={this.handleCloseMenu}>Dispute</NavLink></li>
                <li className="logout" onClick={this.handleLogOut}>Logout</li>
                </UserDropDownOptions>
              </UserDropDown>
              */}

              {user && user.firstName ? (
                <UserDropDown>
                  <UserDropDownOptions>
                    {location?.pathname !== "/admin/dashboard" && (
                      <li>
                        <NavLink
                          exact
                          to="/admin/dashboard"
                          onClick={this.handleCloseMenu}
                        >
                          Dashboard
                        </NavLink>
                      </li>
                    )}
                    {/* <li><NavLink exact to="/admin/pannel" onClick={this.handleCloseMenu}>Dashboard</NavLink></li> */}
                    {/* <li><NavLink exact to="/admin/dispute" onClick={this.handleCloseMenu}>Dispute</NavLink></li> */}
                    <li className="logout" onClick={this.handleAdminLogOut}>
                      Logout
                    </li>
                  </UserDropDownOptions>
                </UserDropDown>
              ) : (
                ""
              )}
            </UserSection>
          }
        </MenuContent>
      </Wrapper>
    );
  };

  renderCustomerHeader = () => {
    const { user, location } = this.props;

    return (
      <Wrapper
        id="header"
        innerRef={(elem) => (this.menuWrapper = elem)}
        className={`menu ${this.props.isOpen ? "is-open" : ""}`}
      >
        <MenuContent>
          <LogoContainer bkgImage="/assets/global/logo.svg">
            <NavLink to="/" onClick={this.handleCloseMenu} />
          </LogoContainer>

          <ContentContainer className="justify-content-center">
            {!isEqual(location.pathname, "/searchrep") && <HeaderSearchRep />}
          </ContentContainer>

          <UserSection>
            {/* <UserProfilePicture picture={user.picture} /> */}
            {/* <UserName>{user.firstName || "..."}</UserName> */}
            <UserDropDown>
              <UserDropDownOptions>
                <li>
                  <NavLink
                    exact
                    to="/view-past-ratings"
                    onClick={() => this.props.handleCloseMenu()}
                  >
                    Past Ratings
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/accountcustomer"
                    onClick={() => this.props.handleCloseMenu()}
                  >
                    Account
                  </NavLink>
                </li>

                <li className="logout2" onClick={this.handleLogOut}>
                  Logout
                </li>
              </UserDropDownOptions>
            </UserDropDown>
          </UserSection>
        </MenuContent>
      </Wrapper>
    );
  };

  renderRepHeader = () => {
    const { user, location } = this.props;

    return (
      <Wrapper
        id="header"
        innerRef={(elem) => (this.menuWrapper = elem)}
        className={`menu ${this.props.isOpen ? "is-open" : ""}`}
      >
        <MenuContent>
          <LogoContainer bkgImage="/assets/global/logo.svg">
            <NavLink to="/" onClick={this.handleCloseMenu} />
          </LogoContainer>

          <ContentContainer className="justify-content-center">
            {!isEqual(location.pathname, "/searchrep") && <HeaderSearchRep />}
          </ContentContainer>

          <UserSection>
            {/* <UserProfilePicture picture={user.picture} /> */}
            {/* <UserName>{user.firstName || "..."}</UserName> */}
            <UserDropDown>
              <UserDropDownOptions>
                <li>
                  <NavLink
                    exact
                    to="/viewreppersonal"
                    onClick={this.handleCloseMenu}
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/searchunclaimedaccount"
                    onClick={this.handleClearSearchInfo}
                  >
                    Claim Accounts
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/repaccount"
                    onClick={this.handleCloseMenu}
                  >
                    Account
                  </NavLink>
                </li>
                <li className="logout" onClick={this.handleLogOut}>
                  Logout
                </li>
              </UserDropDownOptions>
            </UserDropDown>
          </UserSection>
        </MenuContent>
      </Wrapper>
    );
  };
  handleClearSearchInfo = () => {
    this.props.handleClearSearchInfo();
    this.props.handleresetSearchReps();
  };

  renderUnclaimedAccountsHeader = () => {
    const { user } = this.props;

    return (
      <Wrapper
        id="header"
        innerRef={(elem) => (this.menuWrapper = elem)}
        className={`menu ${this.props.isOpen ? "is-open" : ""}`}
        big
      >
        <MenuContent>
          <LogoContainer bkgImage="/assets/global/logo.svg">
            <NavLink to="/" onClick={this.handleCloseMenu} />
          </LogoContainer>

          {this.props.repResults.length > 0 && (
            <HeaderSearchRepUnclaimed header={true} />
          )}
          {this.props.repResults.length == 0 && <Dummy />}

          <UserSectionWrapper>
            <UserSection>
              {/* <UserProfilePicture picture={user.picture} /> */}
              {/* <UserName>{user.firstName || "..."}</UserName> */}
              <UserDropDown>
                <UserDropDownOptions>
                  <li>
                    <NavLink
                      to="/viewreppersonal"
                      onClick={this.handleCloseMenu}
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/searchunclaimedaccount"
                      onClick={this.handleClearSearchInfo}
                    >
                      Claim Accounts
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/repaccount" onClick={this.handleCloseMenu}>
                      Account
                    </NavLink>
                  </li>
                  <li className="logout" onClick={this.handleLogOut}>
                    Logout
                  </li>
                </UserDropDownOptions>
              </UserDropDown>
            </UserSection>
          </UserSectionWrapper>
        </MenuContent>
      </Wrapper>
    );
  };

  handleRepSelection = (rep) => {
    this.setState({
      redirectLink: rep.id,
    });
  };

  renderCompanyHeader = () => {
    const { user, companyInfoLogo } = this.props;
    const companyLogo = user.picture || NoCompanyLogoPng;
    const userRole = this.props.user.userRole;
    let Logo = localStorage.getItem("CompanyLogo");
    let imageURL = <CompanyLogoWrapper imageUrl={NoCompanyLogoPng} />;
    if (Logo) {
      imageURL = <CompanyLogoWrapper imageUrl={Logo} />;
    }
    return (
      <Wrapper
        id="header"
        innerRef={(elem) => (this.menuWrapper = elem)}
        className={`menu ${this.props.isOpen ? "is-open" : ""}`}
      >
        <MenuContent>
          <LogoContainer bkgImage="/assets/global/logo.svg">
            <NavLink to="/" onClick={this.handleCloseMenu} />
            {/* <NavLink target="_blank" to="/" onClick={this.handleCloseMenu} /> */}
          </LogoContainer>

          <ContentContainer>
            <LinksContainer>
              <MenuItem
                to="/dashboardcompany"
                title="Dashboard"
                alt="Dashboard button"
                onClick={this.handleCloseMenu}
              />
              <MenuItem
                to="/my-reps"
                isActive={() =>
                  ["/my-reps", "/viewrepcompany"].includes(
                    window.location.pathname
                  )
                }
                title="Reps"
                alt="My Reps button"
                onClick={this.handleCloseMenu}
              />
              {/* {userRole !== constants.USER_ROLES.REGULAR && ( */}
              <>
                <MenuItem
                  to="/programs"
                  isActive={() =>
                    ["/programs", "/viewprogram"].includes(
                      window.location.pathname
                    )
                  }
                  title="Programs"
                  alt="Programs button"
                  onClick={this.handleCloseMenu}
                />
                <MenuItem
                  to="/teams"
                  isActive={() =>
                    ["/teams", "/viewteam"].includes(window.location.pathname)
                  }
                  title="Teams"
                  alt="Teams button"
                  onClick={this.handleCloseMenu}
                />
              </>
              {/* )} */}
            </LinksContainer>

            <HeaderSearchRep companySearch />
          </ContentContainer>

          <UserSection>
            {/* {imageURL} */}
            <UserDropDown>
              <UserDropDownOptions>
                {userRole !== constants.USER_ROLES.REGULAR && (
                  <>
                    <li>
                      <NavLink to="/manage/reps" onClick={this.handleCloseMenu}>
                        {userRole === "ADMIN"
                          ? "Manage Reps/Users"
                          : "Manage Reps"}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/reportpreferences"
                        onClick={this.handleCloseMenu}
                      >
                        Reporting Preferences
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/accountcompany"
                        onClick={this.handleCloseMenu}
                      >
                        Account
                      </NavLink>
                    </li>
                  </>
                )}
                {userRole === constants.USER_ROLES.REGULAR && (
                  <li>
                    <NavLink
                      to="/accountusercompany"
                      onClick={this.handleCloseMenu}
                    >
                      Account
                    </NavLink>
                  </li>
                )}
                <li className="logout" onClick={this.handleLogOut}>
                  <NavLink to="/" onClick={this.handleLogOut}>
                    Logout
                  </NavLink>
                  {/* <a href="https://repora.wpenginepowered.com/">Logout</a> */}
                </li>
                {/* <li className="logout" onClick={this.handleLogOut}>
                  Logout
                </li> */}
              </UserDropDownOptions>
            </UserDropDown>
          </UserSection>
        </MenuContent>
      </Wrapper>
    );
  };

  renderSignupHeader = () => {
    return (
      <Wrapper
        id="header"
        innerRef={(elem) => (this.menuWrapper = elem)}
        className={`menu ${this.props.isOpen ? "is-open" : ""}`}
      >
        <MenuContent>
          <LogoContainer bkgImage="/assets/global/logo.svg">
            <NavLink to="/" onClick={this.handleCloseMenu} />
          </LogoContainer>
          <ContentContainer />
          <UserSection>
            <ButtonHeader to="/login" small="true" secondary="true">
              Log In
            </ButtonHeader>
          </UserSection>
        </MenuContent>
      </Wrapper>
    );
  };

  renderLoginHeader = () => {
    return (
      <Wrapper
        id="header"
        innerRef={(elem) => (this.menuWrapper = elem)}
        className={`menu ${this.props.isOpen ? "is-open" : ""}`}
      >
        <MenuContent>
          <LogoContainer bkgImage="/assets/global/logo.svg">
            <NavLink to="/" onClick={this.handleCloseMenu} />
          </LogoContainer>
          <ContentContainer />
          <UserSection>
            <ButtonHeader to="/signup" small="true">
              Sign Up
            </ButtonHeader>
          </UserSection>
        </MenuContent>
      </Wrapper>
    );
  };

  handleOpenRepscore = () => {
    window.open("/", "anotherWindow", "toolbar=no");
  };

  renderIframeCompanyHeader = () => {
    const { user, companyInfoLogo } = this.props;
    const companyLogo = user.picture || NoCompanyLogoPng;
    const userRole = this.props.user.userRole;
    let Logo = localStorage.getItem("CompanyLogo");
    let imageURL = <CompanyLogoWrapper imageUrl={NoCompanyLogoPng} />;
    if (Logo) {
      imageURL = <CompanyLogoWrapper imageUrl={Logo} />;
    }
    return (
      <Wrapper
        id="header"
        innerRef={(elem) => (this.menuWrapper = elem)}
        className={`menu ${this.props.isOpen ? "is-open" : ""}`}
      >
        <MenuContent>
          <LogoContainer
            onClick={() => {
              this.handleOpenRepscore();
            }}
            bkgImage="/assets/global/logo.svg"
          ></LogoContainer>

          <ContentContainer>
            <LinksContainer>
              <MenuItem
                to="/dashboardcompany"
                title="Dashboard"
                alt="Dashboard button"
                onClick={this.handleCloseMenu}
              />
              <MenuItem
                to="/my-reps"
                isActive={() =>
                  ["/my-reps", "/viewrepcompany"].includes(
                    window.location.pathname
                  )
                }
                title="Reps"
                alt="My Reps button"
                onClick={this.handleCloseMenu}
              />
              {/* {userRole !== constants.USER_ROLES.REGULAR && ( */}
              <>
                <MenuItem
                  to="/programs"
                  isActive={() =>
                    ["/programs", "/viewprogram"].includes(
                      window.location.pathname
                    )
                  }
                  title="Programs"
                  alt="Programs button"
                  onClick={this.handleCloseMenu}
                />
                <MenuItem
                  to="/teams"
                  isActive={() =>
                    ["/teams", "/viewteam"].includes(window.location.pathname)
                  }
                  title="Teams"
                  alt="Teams button"
                  onClick={this.handleCloseMenu}
                />
              </>
              {/* )} */}
            </LinksContainer>

            <HeaderSearchRep companySearch />
          </ContentContainer>

          {/* {!JSON.parse(isIframe) && <UserSection>
            {imageURL}
            <UserDropDown>
              <UserDropDownOptions>
               {userRole !== constants.USER_ROLES.REGULAR && (
                <>
                  <li>
                    <NavLink to="/manage/reps" onClick={this.handleCloseMenu}>
                      {userRole === "ADMIN" ? "Manage Reps/Users" : "Manage Reps"}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/reportpreferences"
                      onClick={this.handleCloseMenu}
                    >
                      Reporting Preferences
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/accountcompany"
                      onClick={this.handleCloseMenu}
                    >
                      Account
                    </NavLink>
                  </li>
                </> )}
                {userRole === constants.USER_ROLES.REGULAR && (
                  <li>
                    <NavLink
                      to="/accountusercompany"
                      onClick={this.handleCloseMenu}
                    >
                      Account
                    </NavLink>
                  </li>
                )}
              </UserDropDownOptions>
            </UserDropDown>
            
          </UserSection>} */}
        </MenuContent>
      </Wrapper>
    );
  };

  render() {
    const { redirectLink } = this.state;
    const { user, isAuthenticated, location } = this.props;

    if (redirectLink !== "") {
      this.handleCloseMenu();
      return <Redirect push={true} to={`/viewrepcompany?id=${redirectLink}`} />;
    }

    if (isAuthenticated) {
      if (location.pathname === "/howrepscore") {
        return <div></div>;
      }
      if (
        user &&
        (user.userRole === constants.USER_ROLES.COMPANY ||
          user.userRole === constants.USER_ROLES.REGULAR)
      ) {
        let iframe = window.localStorage.getItem("iframe");
        if (JSON.parse(iframe)) {
          return this.renderIframeCompanyHeader();
        }
        return this.renderCompanyHeader();
      }

      if (user && user.userRole === constants.USER_ROLES.CUSTOMER) {
        return this.renderCustomerHeader();
      }
      if (
        // location.pathname === "/admin" ||
        // location.pathname === "/admin/dashboard" ||
        // location.pathname === "/admin/dispute" ||
        // location.pathname === "/admin/pannel" ||
        // location.pathname === "/admin/userStats" ||
        // location.pathname === "/admin/reviews"
        user &&
        user.userRole === constants.USER_ROLES.ADMIN
      ) {
        return this.renderAdminHeader();
      }
      if (user) {
        if (
          location.pathname.includes("/searchunclaimedaccount") ||
          location.pathname.includes("/searchunclaimedaccount/results")
        ) {
          return this.renderUnclaimedAccountsHeader();
        } else {
          return this.renderRepHeader();
        }
      }
    }
    if (
      location.pathname === "/admin" ||
      location.pathname === "/admin/dispute" ||
      location.pathname === "/admin/pannel"
    ) {
      return this.renderAdminHeader();
    }
    if (
      location.pathname.includes("/facebook-data-deletion-instructions-url")
    ) {
      return this.renderPublicHeader2();
    }
    if (
      location.pathname === "/howrepscore" ||
      constants.REPORA_IFRAME_PATHS.includes(location.pathname)
    ) {
      return <div></div>;
    }
    return this.renderUpdatedPublicHeader();
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.userData,
  isOpen: state.menu.isOpen,
  isAuthenticated: state.auth.isAuthenticated,
  isSuper: state.auth.isSuper,
  logo: state.auth.logo,

  reps: state.rep.repList,
  fetchingReps: state.rep.isFetchingRepList,

  companies: state.company.companies,
  fetchingCompanies: state.company.isFetching,

  companyReps: state.search.companyReps,
  searchParameters: state.search.searchParams,
  searchedRepName: state.search.nameField,
  searchedCompany: state.search.companyField,
  companyInfo: state.company.dashboardCompanyInfo,
  companyInfoLogo: state.company.info,
  repResults: state.search.repResults,
  repResultsFetching: state.search.repResultsFetching,
  repResultsError: state.search.repResultsError,
  companylogBlob: state.company.companyLogo,
});

const mapDispatchToProps = (dispatch) => ({
  handleClearSearchInfo() {
    dispatch(clearSearchInfo());
  },
  handleOpenMenu() {
    dispatch(openMenu());
  },
  handleCloseMenu() {
    dispatch(closeMenu());
  },
  handleLogout(history) {
    dispatch(logout(history));
  },
  handleOpenModalWithContent(content) {
    dispatch(openModalWithContent(content));
  },
  handleGetSearch(repsParams = {}) {
    // dispatch(getReps(repsParams));
    // dispatch(getCompanies());
  },
  handleSearchReps(repList, companyId, nameField, companyName) {
    dispatch(
      searchReps(
        repList,
        companyId,
        nameField,
        companyName,
        false,
        this.user ? this.user.id : null
      )
    );
  },
  handleSearchCompanyReps(companyId) {
    // dispatch(getCompanyReps(companyId));
  },
  handleresetSearchReps() {
    dispatch(resetSearchReps());
  },
  handleSetRepSearchFields(nameField, companyField) {
    dispatch(setReducerRepSearchFields(nameField, companyField));
  },
  handlesetRepSearch(data) {
    dispatch(setingRepSearchStates(data));
  },
});

export const Unwrapped = Menu;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
