import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import { reporaColor } from "../global/commonStyles";

const Wrapper = styled.div`
  border-radius: calc(36px / 2);
  overflow: hidden;
  display: inline-flex;
  background: #F4F4F4;
  align-items: center;
  padding: 0 3px;
  flex-wrap: wrap;
  height: auto;
  justify-content: center;
  padding: 3px;
`;

const TabItem = styled(NavLink)`
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  color: ${reporaColor.brightSkyBlue};
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  flex: 1;

  &.selected {
    color: ${reporaColor.black};
    border-radius: 15px;
    border: solid 1px ${reporaColor.grey87};
    background-color: ${reporaColor.white};
  }
`;

const TabLinkMenu = props => {
  return (
    <Wrapper>
      {props.links.map(item =>
        <TabItem key={item.name} exact to={item.link} activeClassName="selected">{item.name}</TabItem>
      )}
    </Wrapper>
  );
};

TabLinkMenu.propTypes = {
  links: PropTypes.array
};

export default TabLinkMenu;
