import styled, { css } from "styled-components";
import { reporaColor, reporaDevice, FormGroup } from "../../../components/global/commonStyles";

export const Wrapper = styled.div`
  max-width: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: ${props => props.bkgImage || "none"};
  align-items: center;
  justify-content: center;
  margin: 80px auto 20px;
  position: relative;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);

  @media ${reporaDevice.tablet} {
    max-width: 502px;
  }
`;

export const CloseButton = styled.span`
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 20px;
  cursor: pointer;
  color: ${reporaColor.warmGrey};
`;

export const BackButton = styled.span`
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 20px;
  cursor: pointer;
  color: ${reporaColor.warmGrey};
`;

export const HeaderWrapper = styled.div`
  max-width: 450px;
  width: 100%;
  margin-top: 34px;
`;

export const InvalidSpan = styled.div`
 font-size: small;
  color: red; 
`;

export const SocialButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  a {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const SideLines = styled.div`
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  line-height: 0;
  padding: 0;
  margin: 38px 0 20px;
  width: 100%;

  span {
    padding: 0 15px;
    background: white;
  }
`;

export const FormWrapper = styled.div`
  max-width: 380px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props => props.maxWidth
    ? "max-width: 500px;"
    : null
  }

  >div {
    width: 100%;
  }

  ${props => props.small
    ? "width: 300px;"
    : null
  }

  @media ${reporaDevice.mobile} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;


export const PhoneGroup = styled(FormGroup)`
  input {
    padding: 0 10px 0 50px;
  }
`;

export const ButtonContainer = styled.div`
  margin-bottom: 10px;
  width: 100%;
`;

export const FormDisclaimer = styled.div`
  font-size: 12px;
  line-height: 28px;
  color: ${reporaColor.warmGrey};
  margin-top: 18px;
  margin-bottom: 43px;
`;

export const LinkedInButton = styled.a`
  display: flex;
  align-items: center;
  background-color: ${reporaColor.brightSkyBlue};
  color: ${reporaColor.white};
  width: auto;
  height: 40px;
  border-radius: 2px;
  margin-bottom: 45px;
  cursor: pointer;

  ${props => props.disabled && css`
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  `}

  @media ${reporaDevice.tablet} {
    width: 287px;
  }
`;

export const LinkedInButtonImageContainer = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid;
  height: 100%;
`;

export const LinkedInButtonText = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
`;