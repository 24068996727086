import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import Button from "../../../../components/Buttons/Button";
import {
  reporaColor,
  reporaDevice,
  TextArea,
} from "../../../../components/global/commonStyles";
import { copyToClipboard } from "../../../../components/global/utils";
import Loader from "../../../../components/Loader/Loader";
import Model from "../../../../components/Modal/Model";

const Wrapper = styled.div`
  background: white;
  border-radius: 2px;
  box-shadow: 0 0 17px 0 ${reporaColor.black08};
  margin-bottom: 15px;
  position: relative;
`;

const Form = styled.div`
  background: white;
  display: flex;
  padding: 25px;
  flex-direction: column;

  @media ${reporaDevice.gtmd} {
    background: white;
    display: flex;
    padding: 25px;
    flex-direction: row;
  }
`;

const LeftFormContent = styled.section`
  flex: 1;
  width: 65%;
`;
const TextWrapper = styled.div`
  color: black;
  input {
    font-size: 10px;
  }
`;
const InputContainer = styled.div`
  align-items: center;
  position: relative;

  .copyIcon{
    color: ${reporaColor.aquaBlue};
    cursor:pointer;
  }

  label {
    width: 120px;
    flex: 0 0 120px;
    font-size: 12px;
    font-weight: bold;
    color: ${reporaColor.warmGrey};
    margin-right: 15px;
  }

  input {
    font-family: Nexa Bold;
    height: 40px;
    border-radius: 4px;
    border: 1px solid ${reporaColor.grey91};
    width: 100%;
    padding: 0 10px;
    font-size: 14px;

    ${(props) =>
      props.invalid
        ? `
      box-shadow: 0 0 5px 1px ${reporaColor.watermelon};
    `
        : null};

    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  @media ${reporaDevice.xs} {
    flex-direction: column;

    label {
      flex: auto;
      align-self: flex-start;
    }

    > input:last-of-type {
      margin: 0;
      margin-top: 10px;
    }
  }

`;

const RightFormContent = styled.section`
  display: flex;
  margin-top: 25px;
  justify-content: flex-end;
  width: ${(props) => props.fromManagePKeys ? "100%" : "65%"};

  @media ${reporaDevice.gtmd} {
    margin: 0;
    justify-content: auto;
    margin-left: 25px;
  }
`;
const ButtonWrraper = styled.div`
  display: flex;
  justify-content: end;
`;

const ModelWrapper = styled.div`
  .content{
    margin: 10px 20px;
  }
  .buttons{
    display:flex;
    justify-content: center;
    margin: 30px 0px;
  }

`;

export default class EditForm extends React.Component {
  state = { isOpen: false, comments: this.props.ele.disputeActionDescription , newKey : null , saveNewKey : false 
    ,showWraning : false , isCKeyUpdating : false , confirmRemove : false  , newCompanyKey : "" };

  componentDidMount() {
    this.setState({ comments: this.props.ele?.adminNotes });
  }
  handleSubmit = (data) => {
    if(this.props.fromClaims){
      let obj = {
        claimAction: data === "ACCEPT" ? "APPROVED" : "REJECT",
        claimedRepId: this.props.ele.claimedRepId,
        claimingRepId: this.props.claimingRepId,
        notes: this.state.comments,
      };
      let statusD = 'PENDING';
      if(this.props.claimStatus === "Accepted"){
        statusD = 'APPROVED'
      }else if( this.props.claimStatus === "Rejected"){
        statusD = 'REJECT'
      }
      let status = { claimStatus : statusD}
    this.props.handleUpdateClaimStatus(obj , status)
    }else{
    let obj = {
      feedbackDisputeId: this.props.ele.id,
      disputeStatus:
        data === "ACCEPT" ? "DISPUTE_APPROVED" : "DISPUTE_REJECTED",
      disputeActionUserId: this.props.reviewerId.id,
      disputeActionDescription: this.state.comments,
    };
    this.props.handleUpdateFeedbackDispute(obj);
    }
  };
  adminComments = (data) => {
    this.setState({ comments: data.target.value });
  };

  generateNewKey = () => {
    const reservedCharacters = [' ', '&', '+'];
    const urlSpecialCharacters = ['?', '#', '/', '='];
    const controlCharacters = ['\n', '\r', '\t'];
  
    const validCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789^$%@!()}{[]-_';
    const validCharacterSet = new Set(validCharacters);
  
    let key = '';
  
    while (key.length < 256) {
      const randomCharacter = validCharacters[Math.floor(Math.random() * validCharacters.length)];
      
      if (!reservedCharacters.includes(randomCharacter) &&
          !urlSpecialCharacters.includes(randomCharacter) &&
          !controlCharacters.includes(randomCharacter) &&
          validCharacterSet.has(randomCharacter)) {
        key += randomCharacter;
      }
    }
  
    this.setState({ newKey : encodeURIComponent(key) })
  }

  handleCopyKey = ( type) => {
    if(type === "new"){
      return  copyToClipboard(document.getElementById("newKey"));
    }
    return  copyToClipboard(document.getElementById("oldKey"));
  }

  handleUpdateCompanyKey = () => {
    this.setState({ isCKeyUpdating : true })
  }

  handleSaveKey = ()=>{
    this.setState({ saveNewKey : true })
  }

  handleCancel =() => {
    this.setState({ showWraning : true })
  }

  confirmCancel = () => {
    return(
      <ModelWrapper>
       <div className="content">
       Are you sure you want to cancel and discard changes.
       </div>
       <div className="buttons">
       <Button
          size="small"
          color="red"
          className="m-r-15"
          onClick={() =>{ 
            this.setState({ showWraning : false , newCompanyKey : ""  });
            this.props.handleSetOpen();
          }}
        >
          Okay
        </Button>
       </div>
      </ModelWrapper>
    )
  }

  handleUpdatePartnerKey = () => {
    let obj = this.props.ele;
    obj['newPartnerApiKey'] = this.state.newKey;
    this.props.handleUpdatePartnerKey(obj)
  }

  confirmNewKey = () => {
    return(
      <ModelWrapper>
       <div className="content">
       Are you sure you want to change this key? The partner’s access to Repora will be immediately broken until they change the key on their side.
       </div>
       <div className="buttons">
       <Button
          size="small"
          color="white"
          className="m-r-15"
          onClick={() =>this.setState({ saveNewKey : false })}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="green"
          className="m-r-15"
          disabled = { !this.state.newKey || this.state.newKey.length < 256 }
          onClick={() =>{ this.handleUpdatePartnerKey()}}
        >
          Save
        </Button>
       </div>
      </ModelWrapper>
    )
  }

  confirmUpdate = () => {
    return(
        <ModelWrapper>
         <div className="content">
         Are you sure you want to change this key? The partner’s access to Repora will be immediately broken until they change the key on their side.
         </div>
         <div className="buttons">
         <Button
            size="small"
            color="white"
            className="m-r-15"
            onClick={() =>this.setState({ isCKeyUpdating : false })}
          >
            Cancel
          </Button>
          <Button
            size="small"
            color="green"
            className="m-r-15"
            onClick={() =>{
              let obj = {
                companyId : this.props.ele?.id,
                companyApiKey : this.state.newCompanyKey
              }
              this.props.handleUpdateCompanyKey(obj)
            }}
          >
            Update
          </Button>
         </div>
        </ModelWrapper>
      )
  }

  confirmRemove = () => {
    return(
      <ModelWrapper>
       <div className="content">
        {this.props.fromManagePKeys &&
        "Are you sure you want to remove partner"}
        {this.props.fromManageCKeys &&
        "Are you sure you want to remove company key"}
       </div>
       <div className="buttons">
       <Button
          size="small"
          color="white"
          className="m-r-15"
          onClick={() =>{ 
            this.setState({ confirmRemove : false });
          }}
        >
          Cancel
        </Button>
        <Button
            size="small"
            color="red"
            className="m-r-15"
            onClick={() =>{
              if(this.props.fromManagePKeys){
              this.props.handleRemovePartnerKey()
              }
              if(this.props.fromManageCKeys){
                this.props.handleRemoveCompanyKey()
              }
            }}
          >
            Remove
          </Button>
       </div>
      </ModelWrapper>
    )
  }



  render() {
    return (
      <Wrapper>
        <Model
        open={ this.state.saveNewKey }
        handleClose={() => this.setState({ saveNewKey : false })}
        title={"Warning"}
        dialogHeight={"260px"}
        dialogWidth = {"400px"}
        modelContent={
          this.confirmNewKey()
        }
        />
        <Model
          open={ this.state.showWraning }
          handleClose={() => this.setState({ showWraning : false })}
          title={"Warning"}
          dialogHeight={"200px"}
          dialogWidth = {"450px"}
          modelContent={
            this.confirmCancel()
          }
        />
        <Model
        open={ this.state.confirmRemove }
        handleClose={() => this.setState({ confirmRemove : false })}
        title={"Warning"}
        dialogHeight={"200px"}
        dialogWidth = {"400px"}
        modelContent={
          this.confirmRemove()
        }
        />
        <Model
          open={ this.state.isCKeyUpdating }
          handleClose={() => this.setState({ isCKeyUpdating : false })}
          title={"Warning"}
          dialogHeight={"260px"}
          dialogWidth = {"450px"}
          modelContent={
            this.confirmUpdate()
          }
        />
        <Form>
          <LeftFormContent />
          { this.props.fromManagePKeys &&
            <RightFormContent  fromManagePKeys id="RightFormContent">
            <LeftFormContent id="LeftFormContent">
            <div style={{ display: "flex",justifyContent: "flex-end"}}>
            <Button
                size="small"
                color="red"
                className="m-r-15"
                onClick={() => {
                   this.setState({ confirmRemove : true })
                }}
              >
                Remove Partner
              </Button>
            </div>
              <table style={{ width: "100%" }}>
                <tr style={{ width: "100%" }}>
                  <td style={{ padding: "1% 3% 1% 3%" }}>
                    <InputContainer>
                      <div>
                      <label>Current Key:</label>
                      <FontAwesomeIcon className="copyIcon" onClick={() => this.handleCopyKey("old")} icon={faCopy} />
                      </div>
                      <TextWrapper id="TextWrapper">
                        <TextArea
                          className="m-b-20"
                          maxLength="1000"
                          rows="4"
                          width="65% !important"
                          name="template"
                          id="oldKey"
                          // value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut e "
                          value={this.props.oldKey}
                          disabled={true}
                        />
                      </TextWrapper>
                    </InputContainer>
                  </td>
                  <td style={{ padding: "1% 3% 1% 3%" }}>
                  <InputContainer>
                    <div>
                    <label>New Key:</label>
                    <FontAwesomeIcon className="copyIcon" onClick={() => this.handleCopyKey("new")}  icon={faCopy} />
                    </div>
                    <TextWrapper id="TextWrapper">
                      <TextArea
                        className="m-b-20"
                        maxLength="1000"
                        rows="4"
                        width="65%"
                        name="template"
                        id = "newKey"
                        value={this.state.newKey}
                        onChange={this.adminComments}
                        disabled = {true}
                      />
                    </TextWrapper>
                  </InputContainer>
                </td>
                </tr>
              </table>
              <ButtonWrraper id="ButtonWrraper">
                <Button
                  size="small"
                  color="white"
                  className="m-r-15"
                  onClick={() => this.handleCancel()}
                >
                  Cancel{" "}
                </Button>
                <Button
                  size="small"
                  color="blue"
                  className="m-r-15"
                  onClick={() => this.generateNewKey()}
                >
                  Generate New Key{" "}
                </Button>
                { this.state.newKey && 
                <Button
                size="small"
                color="green"
                onClick={() => this.handleSaveKey()}
              >
                Save New Key{" "}
              </Button> }
              </ButtonWrraper>
            </LeftFormContent>
          </RightFormContent>}
          { this.props.fromManageCKeys &&
            <RightFormContent  fromManagePKeys id="RightFormContent">
            <LeftFormContent id="LeftFormContent">
            <div style={{ display: "flex",justifyContent: "flex-end"}}>
            <Button
                size="small"
                color="red"
                className="m-r-15"
                onClick={() => {
                  this.setState({ confirmRemove : true })
                }}
              >
                Remove
              </Button>
            </div>
              <table style={{ width: "100%" }}>
                <tr style={{ width: "100%" }}>
                  <td style={{ padding: "1% 3% 1% 3%" }}>
                    <InputContainer>
                      <div>
                      <label>Current Key:</label>
                      <FontAwesomeIcon className="copyIcon" onClick={() => this.handleCopyKey("old")} icon={faCopy} />
                      </div>
                      <TextWrapper id="TextWrapper">
                        <TextArea
                          className="m-b-20"
                          maxLength="1000"
                          rows="4"
                          width="65% !important"
                          name="template"
                          id="oldKey"
                          // value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut e "
                          value={this.props.oldKey}
                          disabled={true}
                        />
                      </TextWrapper>
                    </InputContainer>
                  </td>
                  <td style={{ padding: "1% 3% 1% 3%" }}>
                  <InputContainer>
                    <div>
                    <label>New Key:</label>
                    <FontAwesomeIcon className="copyIcon" onClick={() => this.handleCopyKey("new")}  icon={faCopy} />
                    </div>
                    <TextWrapper id="TextWrapper">
                      <TextArea
                        className="m-b-20"
                        maxLength="1000"
                        rows="4"
                        width="65%"
                        name="template"
                        id = "newKey"
                        value={this.state.newCompanyKey}
                        onChange={(e) => this.setState({ newCompanyKey : e.target.value }) }
                      />
                    </TextWrapper>
                  </InputContainer>
                </td>
                </tr>
              </table>
              <ButtonWrraper id="ButtonWrraper">
                <Button
                  size="small"
                  color="white"
                  className="m-r-15"
                  onClick={() => this.handleCancel()}
                >
                  Cancel{" "}
                </Button>
                <Button
                size="small"
                color="red"
                onClick={() => this.handleUpdateCompanyKey()}
                disabled = { !this.state.newCompanyKey || this.state.newCompanyKey.trim().length === 0  }
              >
                Update Key{" "}
              </Button>
              </ButtonWrraper>
            </LeftFormContent>
          </RightFormContent>}
          { !this.props.fromManagePKeys && !this.props.fromManageCKeys &&
          <RightFormContent id="RightFormContent">
            <LeftFormContent id="LeftFormContent">
              <table style={{ width: "100%" }}>
                <tr style={{ width: "100%" }}>
                  <td style={{ padding: "1% 3% 1% 3%" }}>
                    <InputContainer>
                      <label>{this.props?.labelComment} :</label>
                      <TextWrapper id="TextWrapper">
                        <TextArea
                          className="m-b-20"
                          maxLength="1000"
                          rows="4"
                          width="65%"
                          name="template"
                          // value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut e "
                          value={this.props.ele.disputing_comment || this.props?.userComment}
                          disabled={true}
                        />
                      </TextWrapper>
                    </InputContainer>
                  </td>
                  <td style={{ padding: "1% 3% 1% 3%" }}>
                    <InputContainer>
                      <label>Admin Notes:</label>
                      <TextWrapper id="TextWrapper">
                        <TextArea
                          className="m-b-20"
                          maxLength="1000"
                          rows="4"
                          width="65%"
                          name="template"
                          value={this.state.comments}
                          onChange={this.adminComments}
                        />
                      </TextWrapper>
                    </InputContainer>
                  </td>
                </tr>
              </table>
              <ButtonWrraper id="ButtonWrraper">
                <Button
                  size="small"
                  color="white"
                  className="m-r-15"
                  onClick={() => this.handleSubmit("REJECT")}
                  disabled = { this.props.fromClaims && this.props.claimStatus === 'Rejected'}
                >
                  Reject{" "}
                  {this.props.updateFeedbackDisputeRequested?.loading &&
                    this.props.updateFeedbackDisputeRequested.type ===
                      "DISPUTE_REJECTED" && <Loader size="small" />}
                </Button>
                <Button
                  size="small"
                  color="green"
                  onClick={() => this.handleSubmit("ACCEPT")}
                   disabled = { this.props.fromClaims && this.props.claimStatus === 'Accepted'}
                >
                  Accept{" "}
                  {this.props.updateFeedbackDisputeRequested?.loading &&
                    this.props.updateFeedbackDisputeRequested.type ===
                      "DISPUTE_APPROVED" && <Loader size="small" />}
                </Button>
              </ButtonWrraper>
            </LeftFormContent>
          </RightFormContent>}
        </Form>{" "}
      </Wrapper>
    );
  }
}
