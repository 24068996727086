import authdAxios from "../modules/Authentication/utils/authdAxios";
import config from "../config";
import constants from "../constants";
import { get } from "lodash";

const ManageRecipientsService = () => {
  function getRecipients(companyId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/get-info`,
          {
            companyId: companyId,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function addRecipient(companyId, email) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/add-recipients`,
          {
            companyId,
            email,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function deleteRecipient(companyId, email) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/delete-recipients`,
          {
            companyId,
            email,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getEmailAlertsConfig(companyId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/get-info`,
          {
            companyId: companyId,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function updateEmailAlerts(companyId, emailConfig, isSelected) {
    console.log("comapnyId-->>>", companyId);
    console.log("emailConfig-->>>", emailConfig);
    console.log("isSelected-->>>", isSelected);
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/email-alert`,
          {
            companyId,
            averageRatingThreshold: emailConfig.average,
            individualRatingThreshold: emailConfig.individual,
            isSelected,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
          }
        )
        .then((response) => {
          console.log("response email alert-->>", response);
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  return {
    getRecipients,
    addRecipient,
    deleteRecipient,
    updateEmailAlerts,
    getEmailAlertsConfig,
  };
};

export default ManageRecipientsService();
