import React from "react";
import styled from "styled-components";

import { reporaDevice, Header3, Header2, Body2 } from "../../../components/global/commonStyles";

import Spinner from "../../../components/Spinner/Spinner"

import FormCard from "./FormCard";
import RateConfirmation from "./RateConfirmation";
import PersonCard from "./PersonCard";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;

  @media ${reporaDevice.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const SideWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  flex-shrink: 0;

  >${Header3} {
  margin-left: 30px;
  }

  @media ${reporaDevice.tablet} {
  margin-top: 40px;
  ${props => props.left
    ? `width: 35%;
    padding-right: 10px;`
    : "width: 65%"
  }
  }
  @media ${reporaDevice.desktop} {
  ${props => props.left
    ? `width: 309px
    padding-right: 30px;`
    : "width: 60%"
  }
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin-top: 100px;

  img {
  width: 100px;
  height: 100px;
  }
`;

const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
`;

const View = props => {
  let unclaimedRepProfile = props.unclaimedRepProfile;
  if (props.isFetchingClaimRep) {
    return (
      <SpinnerWrapper><Spinner /></SpinnerWrapper>
    );
  }

  if (props.claimResponseReady) {
    return (
      <Wrapper>
        <RateConfirmation
          authUserPicture={props.authUser.picture}
          responseMessage={props.claimResponse}
          errorMessage={props.errorMessage}
          handleRateDone={props.claimResponseReady}
          tempRepId={props.tempRepId}
          handleOnFinishClaimRep={props.handleOnFinishClaimRep}
        />
      </Wrapper>
    );
  }

  if (props.errorMessage) {
    return (
      <Wrapper>
        error
    </Wrapper>
    );
  }

  if (props.isFetching) {
    return <SpinnerWrapper><Spinner /></SpinnerWrapper>
  } else if ((unclaimedRepProfile.payload === undefined || unclaimedRepProfile.payload === null) && unclaimedRepProfile.statusCode !== 200) {
    return (
      <ErrorWrapper>
        <Header2>Error!</Header2>
        <Body2>{unclaimedRepProfile.message || "No Info available for the given id"}</Body2>
      </ErrorWrapper>
    )
  } else {
    unclaimedRepProfile = unclaimedRepProfile.payload;
    return (
      <Wrapper>
        <SideWrapper left>
          <PersonCard
            repName={`${unclaimedRepProfile.firstName} ${unclaimedRepProfile.lastName}`}
            rating={unclaimedRepProfile.average}
            companyName={unclaimedRepProfile.currentCompany}
            companyAddress={unclaimedRepProfile.companyAddress}
          />
        </SideWrapper>
        <SideWrapper right>
          <FormCard
            isFormValid={props.isFormValid}
            charactersLeft={props.commentCharLeft}
            detail={props.detail}
            handleCommentChange={props.handleCommentChange}
            handleFormSubmit={props.handleFormSubmit}
            repName={props.repName}
            handleBack={props.handleBack}
          />
        </SideWrapper>
      </Wrapper>
    );
  }
};

export default View;
