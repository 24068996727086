import queryString from "query-string";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import styled from "styled-components";
import { addCompany } from "../../actions/accountActions";
import {
  getCompanyCustomeAttributeSample,
  getUserCompaniesSample,
} from "../../actions/companyActions";
import {
  getRepProfile,
  getRepProfileSample,
  rateRep,
  resetRateRep,
} from "../../actions/repActions";
import View from "./components/View";

import { isEmpty } from "lodash";
import { addUserCompany } from "../../actions/companyActions";
import {
  Body4,
  Header4,
  ReporaButton,
  reporaColor,
} from "../../components/global/commonStyles";
import { Card } from "./components/RateConfirmation";

const Wrapper3 = styled.div`
  padding-top: 80px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: center;
  background: white;
  height: 100%;
`;

class RateRep extends React.Component {
  state = {
    redirectLink: "",
    repId: null,
    rateId: null,
    currentCompany: null,
  };

  componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search);
    const repId = queryParams.id;
    const rateId = queryParams.rateid;
    const { handleResetRateRep } = this.props;
    handleResetRateRep();
    if (repId) {
      this.setState({
        repId: repId,
        rateId: rateId,
      });
      if (isEmpty(this.props.repProfile)) {
        if (this.props.userData) {
          if (rateId) {
            this.props.handleGetRepData(repId, true, this.props.userData.id);
          } else {
            this.props.handleGetRepProfile(repId, true, this.props.userData.id);
          }
        } else {
          this.props.handleGetRepProfile(repId, false, "");
        }
        this.props.handleGetCompanies();
      }
      if (this.props.previousCompanies.length === 0) {
        this.props.handleGetUserCompanies(repId);
      }
    }
    if (!isEmpty(this.props.repProfile.payload)) {
      this.props.getCustomeAttr({
        companyId: this.props.repProfile.payload.companyId,
        repType: this.props.repProfile.payload.repType,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.repData !== prevProps.repData &&
      this.props.repData &&
      this.props.repData.currentCompanyConnected
    ) {
      this.props.getCustomeAttr({
        companyId: this.props.repData.companyId,
        repType: this.props.repData.repType,
      });
    }
  }
  handleRateDone = () => {
    const { handleResetRateRep } = this.props;
    handleResetRateRep();
    if (this.props.auth.isAuthenticated) {
      this.setState({
        redirectLink: "/viewrepcustomer?id=" + this.props.repData.id,
      });
    } else {
      this.setState({ redirectLink: "/home" });
    }
  };

  render() {
    const queryParams = queryString.parse(this.props.location.search);
    const rateId = queryParams.rateid;
    if (this.state.redirectLink !== "") {
      return <Redirect push={true} to={this.state.redirectLink} />;
    }

    if (
      this.props.rateResponseReturned &&
      !this.props.rateResponse?.hasOwnProperty("statusCode")
    ) {
      return <Redirect push={true} to="/login" />;
    }

    if (this.props.repData === "Rep not found") {
      return (
        <>
          <Wrapper3>
            <Card style={{ height: "240px" }}>
              <Header4 textAlign="center">
                This person is no longer in Repora
              </Header4>
              <br />
              <Body4
                style={{
                  color: `${reporaColor.warmGrey}`,
                  textAlign: "center",
                }}
              >
                Learn how Repora can help you build high performance
                <br />
                customer facing teams
              </Body4>
              <ReporaButton
                style={{ marginTop: "20px", width: "120px", maxHeight: "35px" }}
                onClick={this.handleRateDone}
              >
                Learn More
              </ReporaButton>
            </Card>
          </Wrapper3>
        </>
      );
    }

    return (
      <View
        {...this.props}
        rateId={rateId}
        handleRateDone={this.handleRateDone}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  rateResponse: state.rep.rateResponse,
  rateResponseReturned: state.rep.rateResponseReady,

  companies: state.company.companies,

  userData: state.auth.userData,
  userCompaniesFetching: state.company.userCompaniesFetching,
  repDataError: state.rep.repDataError,
  repData: !isEmpty(state.rep.repProfile)
    ? state.rep.repProfile?.payload
    : state.rep.repData,
  repProfile: state.rep.repProfile,
  previousCompanies: state.company.previousCompanies,
  repDataFetching: state.rep.repDataFetching,
  isFetchingRateRep: state.rep.isFetchingRateRep,
  repCompanies:
    state.company.previousCompanies.length !== 0
      ? state.company.previousCompanies
      : state.company.userCompanies,
  currentCompany: state.currentCompany,
  repCompaniesFetching: state.company.userCompaniesFetching,
  auth: state.auth,
  addUserCompanyFetching: state.company.addUserCompanyFetching,
  customAttr: state.company.getcompanyCustomeAttributes,
});

const mapDispatchToProps = (dispatch) => ({
  handleAddCompany(company) {
    dispatch(addCompany(company.companyName, company.enrolled));
  },
  handleRateRep(rateData, userLogged) {
    dispatch(rateRep(rateData, userLogged));
  },
  handleResetRateRep() {
    dispatch(resetRateRep());
  },

  handleGetCompanies() {
    // dispatch(getCompanies());
  },
  handleGetRepProfile(repId, userLogged, userId) {
    dispatch(getRepProfileSample(repId, userLogged, userId));
  },
  handleGetRepData(repId, userLogged, userId) {
    dispatch(getRepProfile(repId, userLogged, userId));
  },
  handleGetUserCompanies(repId) {
    dispatch(getUserCompaniesSample(repId));
  },
  handleAddUserCompany(company, userLogged) {
    dispatch(addUserCompany(company, userLogged));
  },
  getCustomeAttr(obj) {
    dispatch(getCompanyCustomeAttributeSample(obj));
  },
});

export const Unwrapped = RateRep;
export default connect(mapStateToProps, mapDispatchToProps)(RateRep);
