import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { addCompanyKey, deleteCompanyKey, getCompanyKeys } from "../../../actions/adminActions";
import { getCompanySuggestions } from "../../../actions/companyActions";
import Button from "../../../components/Buttons/Button";
import Loader from "../../../components/Loader/Loader";
import Model from "../../../components/Modal/Model";
import { Body2, FormGroup, reporaColor } from "../../../components/global/commonStyles";
import InputSuggestions from "../../SearchRep/components/InputSuggestions";
import View from "./components/View";

const Wrapper = styled.div`
  margin: 3%;
  p {
    margin-bottom: 1%;
    margin-right: 10px;
  }
`;

const ModelWrapper =  styled.div`
padding: 0px 25px;
.buttons{
  display : flex;
  margin: 15px 0px;
  justify-content: center;
}
.content{
  >div{
    margin: 10px 0px;
  }
}
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  .copyIcon{
    color: ${reporaColor.aquaBlue};
    cursor:pointer;
  }

  label {
    font-size: 12px;
    font-weight: bold;
    color: ${reporaColor.black};
    margin-bottom: 5px;
    display: block;
  }

  &:last-child {
    margin-bottom: 0;
  }

  input {
    font-family: Nexa Bold;
    height: 40px;
    border-radius: 4px;
    border: 1px solid ${reporaColor.warmGrey};
    width: 100%;
    padding: 0 10px;
    font-size: 14px;

    ${props => props.invalid ? `
      box-shadow: 0 0 5px 1px ${reporaColor.watermelon};
    ` : null};

    &:focus, &:active {
      box-shadow: none;
    }
  }

  textarea{
    width: 100%;
    height: 90px;
    border: 1px solid ${reporaColor.warmGrey};
  }
`;

const AddKey = styled.div`
  display : flex;
  justify-content: space-between;
`; 

class ManageCompanyKeys extends React.Component {
  state = {
    companyKeysList: [],
    headers: [ "Company", "Company API Key"],
    fromManageCKeys:true,
    addCompanyKey : false,
    companyKey : "",

    selectedCompany:null,
    companySuggestions:[]
  };

  componentDidMount() {
    this.props.handleGetCompanyKeys()
  }

  componentDidUpdate(prevProps , prevState){
    if( this.props.companyKeys !== prevProps.companyKeys){
      this.setState({ companyKeysList : this.props.companyKeys })
    }

    const { companySuggestions  } = this.props;

    if( this.props.partnerKeys !== prevState.partnerKeys){
      this.setState({ partnerKeys : this.props.partnerKeys })
    }

    if (companySuggestions !== prevProps.companySuggestions) {
      this.setState({
        companySuggestions: companySuggestions.map((suggestion) => {
          const industry = suggestion.industry
            ? `${suggestion.industry} - `
            : "";
          const address =
            suggestion.physicalAddress &&
            suggestion.physicalAddress.state &&
            suggestion.physicalAddress.city
              ? `${suggestion.physicalAddress.state}, ${
                  suggestion.physicalAddress.city
                }`
              : "-";

          return {
            title: suggestion.name,
            subtitle: industry + address,
            picture: suggestion.logo,
            companyId: suggestion.id,
          };
        }),
      });
    }
  }

  handleStatusChange = (ele) => {
  };

  hanldleAddCompany = () => {
    this.setState({ addCompanyKey :  true })
  }

  handleGetCompanySuggestions = (inputValue) => {
    this.props.handleGetCompanySuggestions(inputValue ,this.props.userData.userRole);
  };

  handleSelectCompany = (selectedEl) => {
    this.setState({
      selectedCompany: selectedEl
    });
  };

  handleInputCompanyChange = (value) => {
    this.setState({
      selectedCompany: null
    });
  }

  isUrlEncoded = (url) => {
    try {
      var decodedUrl = decodeURIComponent(url);
      var reencodedUrl = encodeURIComponent(decodedUrl);
      return url === reencodedUrl;
    } catch (error) {
      return false; // An error occurred while decoding the URL
    }
  }

  handleAddCompanyKey = () => {
    const { companyKey , selectedCompany } = this.state;
    let obj = {
      companyId: selectedCompany?.companyId ,
      companyApiKey : companyKey,
    }
    // if(isUrlEncoded(companyKey)){
    //   obj['companyApiKey'] = companyKey
    // }else{
    //   obj['companyApiKey'] = encodeURIComponent(companyKey)
    // }
    this.props.handleAddCompanyKey(obj);
    this.setState({ addCompanyKey : false });
  }

  isValid = ()=>{
    if( this.state.companyKey && this.state.companyKey.length >= 256 && this.state.selectedCompany?.companyId ){
      return true
    }
    return false
  }



  confirmUpdate = () => {
    return(
        <ModelWrapper>
         <div className="content">
          <div>
            <InputContainer>
            <label>Company</label>
                <FormGroup className="searchInput">
                      <InputSuggestions
                        name="new-company-suggestions"
                        suggestions={this.state.companySuggestions}
                        placeholder=""
                        loading={this.props.fetchingCompanySuggestions}
                        handleSelectElement={this.handleSelectCompany}
                        selectedElement={this.state.selectedCompany}
                        handleInputChange={this.handleInputCompanyChange}
                        inputDelay={500}
                        handleGetSuggestions={this.handleGetCompanySuggestions}
                        inputHeight = "30px"
                        fromAdmin
                  />
              </FormGroup>
            </InputContainer>
          </div>
          <div>
            <InputContainer>
            <label >API KEY</label>
            <textarea id="PartnerKey" onChange={(e)=> this.setState({ companyKey : e.target.value }) } style={{height:"100px"}} value={this.state.companyKey}></textarea>
            </InputContainer>
          </div>
         </div>
         <div className="buttons">
         <Button
            size="small"
            color="white"
            className="m-r-15"
            onClick={() =>this.setState({ addCompanyKey : false })}
          >
            Cancel
          </Button>
          <Button
            size="small"
            color="green"
            className="m-r-15"
            onClick={() =>{
              this.handleAddCompanyKey()
            }}
          >
            Add
          </Button>
         </div>
        </ModelWrapper>
      )
    }


  render() {
    return (
      <>  
        <Model
          open={ this.state.addCompanyKey }
          handleClose={() => this.setState({ addCompanyKey : false })}
          title={"Add Company Key"}
          dialogHeight={"400px"}
          dialogWidth = {"450px"}
          modelContent={
            this.confirmUpdate()
          }
        />
        <div style={{margin:"3%"}}><NavLink className='Navlink' to = "/admin/dashboard">Back to Admin Panel</NavLink></div>
        <Wrapper>
          <AddKey>
                <Body2>Manage Company API Keys</Body2>
                <Button onClick={()=>this.hanldleAddCompany()} >Add Company Key</Button>
              </AddKey>
          {this.props.fetchingCompanyKeys ? (
            <Loader overlayed size="small" />
          ) : (
            <View 
            {...this.props}
            {...this.state}
            handleRemoveCompanyKey =  { this.props.handleRemoveCompanyKey }
            handleUpdateCompanyKey = { this.props.handleUpdateCompanyKey }
            />
          )}
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData : state.auth.userData,
  companySuggestions : state.company.companySuggestions,
  fetchingCompanySuggestions: state.company.companySuggestionsFetching,

  fetchingCompanyKeys : state.admin.fetchingCompanyKeys,
  companyKeys : state.admin.companyKeys,
  companyKeysErr : state.admin.companyKeysErr,

  isAddingCompanykey : state.admin.isAddingCompanykey,
  companyKeyAdded : state.admin.companyKeyAdded,
  errAddingCompanyKey : state.admin.errAddingCompanyKey
});

const mapDispatchToProps = (dispatch) => ({
  handleGetCompanySuggestions(companyName , userRole) {
    dispatch(getCompanySuggestions(companyName , userRole));
  },
  handleAddCompanyKey(obj){
    dispatch(addCompanyKey(obj))
  },
  handleGetCompanyKeys(){
    dispatch(getCompanyKeys())
  },
  handleRemoveCompanyKey(obj){
    dispatch(deleteCompanyKey(obj))
  },
  handleUpdateCompanyKey(obj){
    dispatch(addCompanyKey(obj))
  }
  // handleUpdateCompanyKey(obj){
  //   dispatch(updateCompanyKey(obj))
  // }
});
  
export const Unwrapped = ManageCompanyKeys;
export default connect(mapStateToProps, mapDispatchToProps)(ManageCompanyKeys);
