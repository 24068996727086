import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  addNewPartner,
  deletePartnerKey,
  getPartnerKeys,
} from "../../../actions/adminActions";
import { getCompanySuggestions } from "../../../actions/companyActions";
import Button from "../../../components/Buttons/Button";
import Loader from "../../../components/Loader/Loader";
import Model from "../../../components/Modal/Model";
import {
  Body2,
  FormGroup,
  reporaColor,
} from "../../../components/global/commonStyles";
import { copyToClipboard } from "../../../components/global/utils";
import InputSuggestions from "../../SearchRep/components/InputSuggestions";
import View from "./components/View";

const Wrapper = styled.div`
  margin: 3%;
  p {
    margin-bottom: 1%;
    margin-right: 10px;
  }
`;
const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  > div {
  }
`;

const AddKey = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModelWrapper = styled.div`
  padding: 0px 25px;
  .buttons {
    display: flex;
    margin: 15px 0px;
    justify-content: center;
  }
  .content {
    > div {
      margin: 10px 0px;
    }
  }
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  .copyIcon {
    color: ${reporaColor.aquaBlue};
    cursor: pointer;
  }

  label {
    font-size: 12px;
    font-weight: bold;
    color: ${reporaColor.black};
    margin-bottom: 5px;
    display: block;
  }

  &:last-child {
    margin-bottom: 0;
  }

  input {
    font-family: Nexa Bold;
    height: 40px;
    border-radius: 4px;
    border: 1px solid ${reporaColor.warmGrey};
    width: 100%;
    padding: 0 10px;
    font-size: 14px;

    ${(props) =>
      props.invalid
        ? `
      box-shadow: 0 0 5px 1px ${reporaColor.watermelon};
    `
        : null};

    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  textarea {
    width: 100%;
    height: 90px;
    border: 1px solid ${reporaColor.warmGrey};
  }
`;

class ManagePrivateKeys extends React.Component {
  state = {
    partnerKeys: [
      { partnerName: "Sales Force", company: "Tray.co", API_KEY: "asdfghjkl" },
    ],
    claimStatus: "Pending",
    headers: ["Partner", "Repora Company", "API Key"],
    fromManagePKeys: true,
    addPatner: false,

    partnerName: "",
    partnerKey: "",

    selectedCompany: null,
    companySuggestions: [],
  };

  componentDidMount() {
    this.props.handleGetPartnerKeys();
  }

  componentDidUpdate(prevProps, prevState) {
    const { companySuggestions } = this.props;

    if (this.props.partnerKeys !== prevState.partnerKeys) {
      this.setState({ partnerKeys: this.props.partnerKeys });
    }

    if (companySuggestions !== prevProps.companySuggestions) {
      this.setState({
        companySuggestions: companySuggestions.map((suggestion) => {
          const industry = suggestion.industry
            ? `${suggestion.industry} - `
            : "";
          const address =
            suggestion.physicalAddress &&
            suggestion.physicalAddress.state &&
            suggestion.physicalAddress.city
              ? `${suggestion.physicalAddress.state}, ${suggestion.physicalAddress.city}`
              : "-";

          return {
            title: suggestion.name,
            subtitle: industry + address,
            picture: suggestion.logo,
            companyId: suggestion.id,
          };
        }),
      });
    }
  }

  handleAddNewPartner = (data) => {
    if (data.type === "partnerName") {
      this.setState({ partnerName: data.value });
    }
    if (data.type === "partnerKey") {
      this.setState({ partnerKey: data.value });
    }
  };

  handleStatusChange = (ele) => {};

  hanldleAddPatner = () => {
    this.setState({ addPatner: true });
  };

  // generateNewKey = () => {
  //   const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@$^*()_~|}{[]\\:;<>,.';
  //   let result = '';
  //   for (let i = 0; i < 256; i++) {
  //     result += chars[Math.floor(Math.random() * chars.length)];
  //   }
  //   this.setState({ partnerKey : encodeURIComponent(result)})
  // }

  generateNewKey = () => {
    const reservedCharacters = [" ", "&", "+"];
    const urlSpecialCharacters = ["?", "#", "/", "="];
    const controlCharacters = ["\n", "\r", "\t"];

    const validCharacters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789^$%@!()}{[]-_";
    const validCharacterSet = new Set(validCharacters);

    let key = "";

    while (key.length < 256) {
      const randomCharacter =
        validCharacters[Math.floor(Math.random() * validCharacters.length)];

      if (
        !reservedCharacters.includes(randomCharacter) &&
        !urlSpecialCharacters.includes(randomCharacter) &&
        !controlCharacters.includes(randomCharacter) &&
        validCharacterSet.has(randomCharacter)
      ) {
        key += randomCharacter;
      }
    }

    this.setState({ partnerKey: encodeURIComponent(key) });
  };

  handleCopyKey = () => {
    return copyToClipboard(document.getElementById("PartnerKey"));
  };

  handleAddPartner = () => {
    const { selectedCompany, partnerKey, partnerName } = this.state;
    let obj = {
      companyId: selectedCompany?.companyId,
      partnerApiKey: partnerKey,
      partnerName: partnerName,
      reporaCompanyName: selectedCompany?.title,
    };
    this.props.handleAddNewPartnerKey(obj);
    this.setState({ addPatner: false });
  };

  isValid = () => {
    if (
      this.state.partnerName &&
      this.state.partnerName.length > 0 &&
      this.state.partnerKey !== "" &&
      this.state.selectedCompany?.companyId
    ) {
      return true;
    }
    return false;
  };

  handleGetCompanySuggestions = (inputValue) => {
    this.props.handleGetCompanySuggestions(
      inputValue,
      this.props.userData.userRole
    );
  };

  handleSelectCompany = (selectedEl) => {
    this.setState({
      selectedCompany: selectedEl,
    });
  };

  handleInputCompanyChange = (value) => {
    this.setState({
      selectedCompany: null,
    });
  };

  handleRemovePartnerKey = (obj) => {
    if (!isEmpty(obj)) {
      this.props.handleDeletePartnerKey(obj);
    }
  };

  confirmUpdate = () => {
    return (
      <ModelWrapper>
        <div className="content">
          <div>
            <InputContainer>
              <label>Partner Name</label>
              <input
                onChange={(e) => this.setState({ partnerName: e.target.value })}
              ></input>
            </InputContainer>
          </div>
          <div>
            <InputContainer>
              <label>Repora Company</label>
              <FormGroup className="searchInput">
                <InputSuggestions
                  name="new-company-suggestions"
                  suggestions={this.state.companySuggestions}
                  placeholder=""
                  loading={this.props.fetchingCompanySuggestions}
                  handleSelectElement={this.handleSelectCompany}
                  selectedElement={this.state.selectedCompany}
                  handleInputChange={this.handleInputCompanyChange}
                  inputDelay={500}
                  handleGetSuggestions={this.handleGetCompanySuggestions}
                  inputHeight="30px"
                  fromAdmin
                />
              </FormGroup>
            </InputContainer>
          </div>
          <div>
            <InputContainer>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label>API KEY</label>
                <FontAwesomeIcon
                  className="copyIcon"
                  onClick={() => this.handleCopyKey()}
                  icon={faCopy}
                />
              </div>
              <textarea
                id="PartnerKey"
                style={{ height: "100px" }}
                value={this.state.partnerKey}
                disabled={true}
              ></textarea>
            </InputContainer>
          </div>
          <Button size="small" onClick={() => this.generateNewKey()}>
            Generate Key
          </Button>
        </div>
        <div className="buttons">
          <Button
            size="small"
            color="white"
            className="m-r-15"
            onClick={() => this.setState({ addPatner: false, partnerKey: "" })}
          >
            Cancel
          </Button>
          <Button
            size="small"
            color="green"
            className="m-r-15"
            disabled={!this.isValid()}
            onClick={(e) => {
              this.handleAddPartner();
            }}
          >
            Add
          </Button>
        </div>
      </ModelWrapper>
    );
  };

  render() {
    return (
      <>
        <Model
          open={this.state.addPatner}
          handleClose={() =>
            this.setState({ addPatner: false, partnerKey: "" })
          }
          title={"Add Partner"}
          dialogHeight={"465px"}
          dialogWidth={"450px"}
          modelContent={this.confirmUpdate()}
        />
        <div style={{ margin: "3%" }}>
          <NavLink className="Navlink" to="/admin/dashboard">
            Back to Admin Panel
          </NavLink>
        </div>
        <Wrapper>
          <AddKey>
            <Body2>Manage Partner Keys</Body2>
            <Button onClick={() => this.hanldleAddPatner()}>Add Partner</Button>
          </AddKey>
          {this.props.fetchingPartnerKeys || this.props.deletingPartnerKey ? (
            <Loader overlayed size="small" />
          ) : (
            <View
              {...this.props}
              {...this.state}
              handleRemovePartnerKey={this.handleRemovePartnerKey}
              handleUpdatePartnerKey={this.props.handleUpdatePartnerKey}
            />
          )}
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  companySuggestions: state.company.companySuggestions,
  fetchingCompanySuggestions: state.company.companySuggestionsFetching,

  fetchingPartnerKeys: state.admin.fetchingPartnerKeys,
  partnerKeys: state.admin.partnerKeys,
  partnerKeysErr: state.admin.partnerKeysErr,

  deletingPartnerKey: state.admin.deletingPartnerKey,
});

const mapDispatchToProps = (dispatch) => ({
  handleGetCompanySuggestions(companyName, userRole) {
    dispatch(getCompanySuggestions(companyName, userRole));
  },
  handleAddNewPartnerKey(obj) {
    dispatch(addNewPartner(obj));
  },
  handleGetPartnerKeys() {
    dispatch(getPartnerKeys());
  },
  handleDeletePartnerKey(obj) {
    dispatch(deletePartnerKey(obj));
  },
  handleUpdatePartnerKey(obj) {
    dispatch(addNewPartner(obj));
  },
  // handleUpdatePartnerKeys(obj){
  //   dispatch(updatePartnerKey(obj))
  // }
});

export const Unwrapped = ManagePrivateKeys;
export default connect(mapStateToProps, mapDispatchToProps)(ManagePrivateKeys);
