import customerService from "../services/CustomerService";
import authService from "../services/AuthService"
import repService from "../services/RepService"

import { UPDATE_USER_INFO_REQUESTED } from "./authActions";

/* Action Constants */
export const GET_PAST_RATINGS_REQUEST = "GET_PAST_RATINGS_REQUEST";
export const GET_PAST_RATINGS_SUCCESS = "GET_PAST_RATINGS_SUCCESS";
export const GET_PAST_RATINGS_FAILURE = "GET_PAST_RATINGS_FAILURE";

export const UPVOTE_REQUEST = "UPVOTE_REQUEST";
export const UPVOTE_SUCCESS = "UPVOTE_SUCCESS";
export const UPVOTE_FAILURE = "UPVOTE_FAILURE";

export const UPDATE_PHONE_RESET = "UPDATE_PHONE_RESET";
export const UPDATE_EMAIL_RESET = "UPDATE_EMAIL_RESET";
export const UPDATE_EMAIL_REQUEST = "UPDATE_EMAIL_REQUEST";
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS";
export const UPDATE_EMAIL_FAILURE = "UPDATE_EMAIL_FAILURE";

export const UPDATE_PASSWORD_RESET = "UPDATE_PASSWORD_RESET";
export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";

export const UPDATE_COMPANY_RESET = "UPDATE_COMPANY_RESET";
export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";

export const CLEAR_UPVOTE_REQUESTED = "CLEAR_UPVOTE_REQUESTED";

export const UPDATE_PHONE_REQUESTED = "UPDATE_PHONE_REQUESTED";
export const UPDATE_PHONE_SUCCEEDED = "UPDATE_PHONE_SUCCEEDED";
export const UPDATE_PHONE_FAILED = "UPDATE_PHONE_FAILED";
export const RESET_PHONE_REQUESTED = "RESET_PHONE_REQUESTED";

export const UPDATE_COMPANY_REQUESTED = "UPDATE_COMPANY_REQUESTED";
export const UPDATE_COMPANY_SUCCEEDED = "UPDATE_COMPANY_SUCCEEDED";
export const UPDATE_COMPANY_FAILED = "UPDATE_COMPANY_FAILED";
export const RESET_COMPANY_REQUESTED = "RESET_COMPANY_REQUESTED";

export const UPDATE_PASSWORD_REQUESTED = "UPDATE_PASSWORD_REQUESTED";
export const UPDATE_PASSWORD_SUCCEEDED = "UPDATE_PASSWORD_SUCCEEDED";
export const UPDATE_PASSWORD_FAILED = "UPDATE_PASSWORD_FAILED";
export const RESET_PASSWORD_REQUESTED = "RESET_PASSWORD_REQUESTED";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const UPDATE_EMAIL_REQUESTED = "UPDATE_EMAIL_REQUESTED";
export const UPDATE_EMAIL_SUCCEEDED = "UPDATE_EMAIL_SUCCEEDED";
export const UPDATE_EMAIL_FAILED = "UPDATE_EMAIL_FAILED";
export const RESET_EMAIL_REQUESTED = "RESET_EMAIL_REQUESTED";
export const RESET_EMAIL_ERROR = "RESET_EMAIL_ERROR";

export const UPDATE_SECONDARY_EMAIL_REQUESTED = "UPDATE_SECONDARY_EMAIL_REQUESTED";
export const UPDATE_SECONDARY_EMAIL_SUCCEEDED = "UPDATE_SECONDARY_EMAIL_SUCCEEDED";
export const UPDATE_SECONDARY_EMAIL_FAILED = "UPDATE_SECONDARY_EMAIL_FAILED";
export const RESET_SECONDARY_EMAIL_REQUESTED = "RESET_SECONDARY_EMAIL_REQUESTED";
export const UPDATE_STATE_CUSTOMER_REP_FEEDBACK_SUCCEEDED = "UPDATE_STATE_CUSTOMER_REP_FEEDBACK_SUCCEEDED";

export const CHECK_EMAIL_REQUSTED = "CHECK_EMAIL_REQUSTED";
export const CHECK_EMAIL_SUCCESS = "CHECK_EMAIL_SUCCESS";
export const CHECK_EMAIL_FAILED = "CHECK_EMAIL_FAILED";

/* Action Creators */
const requestPastRatings = () => ({ type: GET_PAST_RATINGS_REQUEST, payload: null });
const receivePastRatings = data => ({ type: GET_PAST_RATINGS_SUCCESS, payload: data });
const receivePastRatingsError = error => ({ type: GET_PAST_RATINGS_FAILURE, payload: error });

const requestUpvote = (feedbackId) => ({ type: UPVOTE_REQUEST, payload: { feedbackId } });
const receiveUpvote = (feedbackId, data) => ({ type: UPVOTE_SUCCESS, payload: { feedbackId, data } });
const receiveUpvoteError = (feedbackId, error) => ({ type: UPVOTE_FAILURE, payload: { feedbackId, error } });

const resetUpdateEmail = () => ({ type: UPDATE_EMAIL_RESET });

const resetUpdatePassword = () => ({ type: UPDATE_PASSWORD_RESET });
const resetPasswordError = () => ({ type: RESET_PASSWORD_ERROR });

const resetUpdateCompany = () => ({ type: UPDATE_COMPANY_RESET });

const clearUpVoteRequested = () => ({ type: CLEAR_UPVOTE_REQUESTED });

const updatePhoneRequested = () => ({ type: UPDATE_PHONE_REQUESTED });
const updatePhoneSucceeded = data => ({ type: UPDATE_PHONE_SUCCEEDED, payload: data });
const updatePhoneFailed = error => ({ type: UPDATE_PHONE_FAILED, payload: error });
const resetPhoneRequested = () => ({ type: RESET_PHONE_REQUESTED });

const updateCompanyRequested = () => ({ type: UPDATE_COMPANY_REQUESTED });
const updateCompanySucceeded = data => ({ type: UPDATE_COMPANY_SUCCEEDED, payload: data });
const updateCompanyFailed = error => ({ type: UPDATE_COMPANY_FAILED, payload: error });

const updatePasswordRequested = () => ({ type: UPDATE_PASSWORD_REQUESTED });
const updatePasswordSucceeded = data => ({ type: UPDATE_PASSWORD_SUCCEEDED, payload: data });
const updatePasswordFailed = error => ({ type: UPDATE_PASSWORD_FAILED, payload: error });

const updateEmailRequested = () => ({ type: UPDATE_EMAIL_REQUESTED });
const updateEmailSucceeded = data => ({ type: UPDATE_EMAIL_SUCCEEDED, payload: data });
const updateEmailFailed = error => ({ type: UPDATE_EMAIL_FAILED, payload: error });
const resetEmailError = () => ({ type: RESET_EMAIL_ERROR });

const updateSecondaryEmailRequested = () => ({ type: UPDATE_SECONDARY_EMAIL_REQUESTED });
const updateSecondaryEmailSucceeded = data => ({ type: UPDATE_SECONDARY_EMAIL_SUCCEEDED, payload: data });
const updateSecondaryEmailFailed = error => ({ type: UPDATE_SECONDARY_EMAIL_FAILED, payload: error });
const resetSecondaryEmailRequested = () => ({ type: RESET_SECONDARY_EMAIL_REQUESTED });
const upVoteStateCustomerRepFeedBackSucceeded = data => ({ type: UPDATE_STATE_CUSTOMER_REP_FEEDBACK_SUCCEEDED, payload: data });

const checkEmailRequested = () => ({type:CHECK_EMAIL_REQUSTED, payload:null});
const checkEmailSucceeded = (data) => ({type:CHECK_EMAIL_SUCCESS ,payload:data});
const checkEmailFailed = (error) => ({type:CHECK_EMAIL_FAILED, payload :error})

export function getPastRatings(userId) {
  return dispatch => {
    dispatch(requestPastRatings());
    customerService.getPastRatings(userId)
      .then(res => {
        dispatch(receivePastRatings(res.payload));
      })
      .catch(err => {
        dispatch(receivePastRatingsError(err));
      });
  };
}

export function upvote(feedbackId, vote, id) {
  return (dispatch, getState) => {
    const { auth: { userData: { id: reviewerId } } } = getState();
    dispatch(requestUpvote(feedbackId))
    customerService
      .upvote({ feedbackId, reviewerId, vote })
      .then(res => {
        dispatch(receiveUpvote(feedbackId, res));
        // dispatch(upVoteStateCustomerRepFeedBackSucceeded(profile));
        dispatch(viewRepCustomerList(id));
      })
      .catch(err => {
        dispatch(receiveUpvoteError(feedbackId, err))
        // dispatch(upVoteStateCustomerRepFeedBackSucceeded(orginalState));
      })
  }
}



export function viewRepCustomerList(repID) {
  return (dispatch, getState) => {
    const state = getState();
    const auth = state.auth;
    const userData = auth && auth.userData;
    if (userData && userData.id) {
      repService.repProfile(repID, userData.id)
        .then(response => {
          dispatch(upVoteStateCustomerRepFeedBackSucceeded(response.payload.customerFeedbackList));
        })
        .catch(error => {
          // dispatch(receiveRepInfoError(error))
        });
    }
  }
}

export function updatePhone(id, email, phone, password) {
  return dispatch => {
    dispatch(updatePhoneRequested());

    customerService.updatePhone(id, email, phone, password)
      .then(response => {
        authService.updateUserInfo({ phoneNumber: phone });
        dispatch({ type: UPDATE_USER_INFO_REQUESTED });
        dispatch(updatePhoneSucceeded(response));
      })
      .catch(error => {
        dispatch(updatePhoneFailed(error));
      });
  };
}

export function resetPhone() {
  return dispatch => {
    dispatch(resetPhoneRequested());
  };
}

export function updateEmail(id, email, newEmail, password) {
  return dispatch => {
    dispatch(updateEmailRequested());

    customerService.updateEmail(id, email, newEmail, password)
      .then(response => {
        authService.updateUserInfo({ email: newEmail });
        dispatch({ type: UPDATE_USER_INFO_REQUESTED });
        dispatch(updateEmailSucceeded(response));
      })
      .catch(error => {
        dispatch(updateEmailFailed(error));
      });
  };
}

export function updateSecondaryEmail(userId, secondaryEmail) {
  return dispatch => {
    dispatch(updateSecondaryEmailRequested());

    customerService.updateSecondaryEmail(userId, secondaryEmail)
      .then(response => {
        authService.updateUserInfo({ secondaryEmail: secondaryEmail });
        dispatch({ type: UPDATE_USER_INFO_REQUESTED });
        dispatch(updateSecondaryEmailSucceeded(response));
      })
      .catch(error => {
        dispatch(updateSecondaryEmailFailed(error));
      });
  };
}

export function resetEmail() {
  return (dispatch) => {
    dispatch(resetUpdateEmail())
  }
}

export function resetSecondaryEmail() {
  return dispatch => {
    dispatch(resetSecondaryEmailRequested());
  }
}
export function resetEmailErrorCustomerAccount() {
  return dispatch => {
    dispatch(resetEmailError());
  }
}

export function updatePassword(oldPassword, newPassword) {
  return dispatch => {
    dispatch(updatePasswordRequested());

    customerService.updatePassword(oldPassword, newPassword)
      .then(response => {
        dispatch(updatePasswordSucceeded(response));
      })
      .catch(error => {
        dispatch(updatePasswordFailed(error));
      });
  };
}

export function resetPassword() {
  return (dispatch) => {
    dispatch(resetUpdatePassword());
  }
}

export function resetPasswordErrorCustomerAccount() {
  return (dispatch) => {
    dispatch(resetPasswordError());
  }
}

export function updateCompany(userId, companyName, jobTitle, country, zipCode, state, city) {
  return dispatch => {
    dispatch(updateCompanyRequested());

    customerService.updateCompany(userId, companyName, jobTitle, country, zipCode, state, city)
      .then(response => {
        authService.updateUserInfo({ jobTitle, country, zipCode, state, city });
        dispatch({ type: UPDATE_USER_INFO_REQUESTED });
        dispatch(updateCompanySucceeded(response));
      })
      .catch(error => {
        dispatch(updateCompanyFailed(error));
      });
  };
}

export function clearUpvote() {
  return dispatch => {
    dispatch(clearUpVoteRequested());
  }
}

export function resetCompany() {
  return (dispatch) => {
    dispatch(resetUpdateCompany())
  }
}

export function checkEmailExist(email) {
  return dispatch => {
    dispatch(checkEmailRequested());

    customerService.checkEmailExist(email)
      .then(response => {
        dispatch(checkEmailSucceeded(response.payload));
      })
      .catch(error => {
        dispatch(checkEmailFailed(error));
      });
  };
}
