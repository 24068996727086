import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPhoneInput from "react-phone-input-2";
import { faTimes, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
import { Header3, Header5, reporaColor, FormRow, FormGroup, reporaDevice } from "../../../components/global/commonStyles";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { Wrapper, CloseButton, BackButton, HeaderWrapper, FormWrapper, PhoneGroup, ButtonContainer } from "./CommonStyles";
import Button from "../../../components/Buttons/Button";
import config from "../../../config";

const CollapseRow = styled(FormRow)`
  .react-tel-input{
    width : auto !important;
  }
  @media ${reporaDevice.xs} {
    flex-direction: column;

    >div {
      margin: 0;
      width: 100%;
      margin-top: 14px;
    }
  }
`;

class Step3CustomerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    this.onRecaptchaValidate = this.onRecaptchaValidate.bind(this);
  }

  state = {
    initialState: {
      ...this.props.step3Data
    },
    phoneNumber : '',
    countryCode : '+1'
  }
  componentDidMount() {
    document.getElementById("contentLoader").scrollIntoView();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps , prevState){
    const { phoneNumber , countryCode } = this.state
    if(phoneNumber !== prevState.phoneNumber || countryCode !== prevState.countryCode){
      this.props.handleInputChange({ target: { name: "phoneNumber", value: this.state.countryCode + this.state.phoneNumber } });
    }
  }

  onRecaptchaValidate = () => {
    if(this.recaptchaRef && this.recaptchaRef.current) {
      const recaptchaValue = this.recaptchaRef.current.getValue();
      this.props.handleRecaptchaChange(recaptchaValue);
    }
  }

  render() {
    const reCaptchaSiteKey = config.RE_CAPTCHA_KEYS.siteKey || "";
    const {
      firstName,
      lastName,
      companyName,
      jobTitle,
      country,
      city,
      state,
      zipCode,
      phoneNumber,
      token
    } = this.props.step3Data;

    let isValid = false;
    if (
      firstName && lastName && jobTitle && companyName && city && zipCode &&
      firstName.trim() && lastName.trim() && jobTitle.trim() &&
      companyName.trim() && country.trim() && city.trim() && state.trim()
      && zipCode.trim() && phoneNumber && phoneNumber.length >= 12 && token) {
      isValid = true;
    }

    return (
      <Wrapper id="scroller">
        <HeaderWrapper>
          <BackButton onClick={this.props.handleBack}><FontAwesomeIcon icon={faArrowLeft} /></BackButton>
          <CloseButton onClick={this.props.handleClose}><FontAwesomeIcon icon={faTimes} /></CloseButton>

          <Header3 textAlign="center">Sign Up</Header3>
          <Header5 textAlign="center" textColor={reporaColor.brightSkyBlue}>Confirm your contact details, and complete any missing fields</Header5>
        </HeaderWrapper>
        <FormWrapper>
          <FormRow>
            <FormGroup>
              <label>First Name*</label>
              <input autoFocus onChange={this.props.handleInputChange} name="firstName" value={firstName || ""} disabled={this.state.initialState.firstName} />
            </FormGroup>

            <FormGroup>
              <label>Last Name*</label>
              <input onChange={this.props.handleInputChange} name="lastName" value={lastName || ""} disabled={this.state.initialState.lastName} />
            </FormGroup>
          </FormRow>

          <FormGroup>
            <label>Job Title*</label>
            <input onChange={this.props.handleInputChange} name="jobTitle" value={jobTitle || ""} disabled={this.state.initialState.jobTitle} />
          </FormGroup>

          <FormGroup>
            <label>Company*</label>
            <input onChange={this.props.handleInputChange} name="companyName" value={companyName || ""} />
          </FormGroup>

          <FormGroup>
            <label>Country*</label>
            <Dropdown customWidth="100%" options={this.props.countryList} value={country || ""} placeholder="Country" onClick={this.props.handleCountryChange} width="100%" left />
          </FormGroup>

          <FormGroup>
            <label>City*</label>
            <input onChange={this.props.handleInputChange} name="city" value={city || ""} />
          </FormGroup>

          <FormGroup>
            <label>State*</label>
            <Dropdown
              options={this.props.cityList}
              value={state || ""}
              placeholder="State"
              onClick={this.props.handleCityChange}
              customWidth="100%"
              left />
          </FormGroup>

          <CollapseRow>
            <FormGroup width="40%">
              <label>Zip Code*</label>
              <input onChange={this.props.handleInputChange} name="zipCode" value={zipCode || ""} />
            </FormGroup>
            <PhoneGroup width="60%">
              <label>Phone Number*</label>
              <div style = {{display: "flex"}}>
              <ReactPhoneInput
                name="phoneNumber"
                disableAreaCodes={true}
                inputStyle={{display:"none"}}
                buttonStyle={{ zIndex: 1 }}
                defaultCountry={"us"}
                value={ this.state.countryCode}
                onChange={value => {
                  this.setState({ countryCode : value })
                }} />
                <input type = "text" onChange = {(e)=>{
                  this.setState({ phoneNumber : e.target.value?.replace(/[^0-9]/g, '')}) 
                }}></input>
              </div>
            </PhoneGroup>
          </CollapseRow>
          <ButtonContainer>
            <ReCAPTCHA ref={this.recaptchaRef} sitekey={reCaptchaSiteKey} onChange={this.onRecaptchaValidate} />
          </ButtonContainer>
          <ButtonContainer className="m-b-45">
            <Button disabled={!isValid} onClick={this.props.handleStepSubmit} width="100%">
              Create Account
            </Button>
          </ButtonContainer>
        </FormWrapper>
      </Wrapper>
    );
  }
}

export default Step3CustomerDetails;
