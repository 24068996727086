import React from "react";
import styled from "styled-components";
import { reporaColor } from "../../components/global/commonStyles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  
  left: 10px;
  width:  ${props => props.customWidth};
  position: relative;
`;

const Bar = styled.div`
  height: ${props => props.small ? "6.4px" : "20px"};
  ${props => props.small
    ? props.isRadius && "border-radius: 5px;"
    : props.isRadius && "border-radius: 10px;"
  }
  width: ${props => props.customWidth || "100px"};
  border: 1px solid ${ props => {
    if (typeof props.customWidth === "undefined") {
      return `${reporaColor.grey87}`
    } else {
      return props.customColor || `${reporaColor.greenBlue}`
    }
  }};
  position: relative;
`;

const Filler = styled.div`
  background-color: ${props => props.customColor || `${reporaColor.greenBlue}`};
  width: ${props => props.customWidth || 0}%;
  height: 100%;
  display: inline-block;
  position: absolute;
`;

const Percentage = styled.div`
  display: inline-block;
  color: ${props => props.customColor};
  margin-right: 22px;
  width: 20px;  
  margin-left: 22px;
  font-size: ${props => props.size || "inherit"};
`;

const StepLimiter = styled.div`
  position: absolute;
  width: 3px;
  background: ${props => props.limiterBg || reporaColor.white};
  height: ${props => props.small
    ? "10px;"
    : "20px;"
  };
  top: -1px;
  left: ${props => props.pos}%;
`;

const ProgressBar = (props) => {
  let label = 0;
  let steps = [];

  if (props.label !== null && props.label !== 0) {
    label = props.percentageSign ? parseFloat(props.label).toFixed(0) + "%" : parseFloat(props.label).toFixed(1) || props.percentage;
  }
  else {
    label = "N/A";
  }

  for (var i = 0; i < props.steps - 1; i++) {
    const position = 100 / props.steps * (i + 1)
    steps.push(<StepLimiter key={"step" + i} pos={position} small={!!props.small} limiterBg={props.limiterBg}></StepLimiter>);
  }

  return (
    <Wrapper customWidth={props.customWrapperWidth}>
      <Bar small={!!props.small} isRadius={!!props.radius} customColor={props.customColor} customWidth={props.customWidth}>
        <Filler customWidth={props.percentage} customColor={props.customColor} />
        {steps}
      </Bar>
      {!props.noValue && <Percentage size={props.size} customColor={props.labelColor || props.customColor}>{label === "0.0" ? "-" : label}</Percentage>}
    </Wrapper>
  );
}

export default ProgressBar;
