import React from "react";
import styled from "styled-components";
import Step1 from "./Step1";
import Step2Customer from "./Step2Customer";
import Step2Rep from "./Step2Rep";
import Step2Company from "./Step2Company";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const View = (props) => {
  if (props.step) {
    if (props.step === 2) {
      switch (props.userRole) {
        case "CUSTOMER":
          return (
            <Wrapper>
              <Step2Customer
                isFetching={props.isFetching}
                clearErrorMessages={props.clearErrorMessages}
                loginErrorMessage={props.loginErrorMessage}
                handleBack={props.handleBack}
                handleClose={props.handleClose}
                handleGetSocialUrl={props.handleGetSocialUrl}
                handleInputChange={props.handleInputChange}
                handleStepSubmit={props.handleStepSubmit}
              />
            </Wrapper>
          );
        case "REP":
          return (
            <Wrapper>
              <Step2Rep
                isFetching={props.isFetching}
                company={props.companyPublicInfo}
                invitationCompanyId={
                  props.invitationCompanyId ||
                  props.companyPublicInfo?.companyId
                }
                invitationId={props.invitationId}
                handleBack={props.handleBack}
                handleClose={props.handleClose}
                handleGetSocialUrl={props.handleGetSocialUrl}
              />
            </Wrapper>
          );
        case "ADMIN":
          return (
            <Wrapper>
              <Step2Company
                isFetching={props.isFetching}
                clearErrorMessages={props.clearErrorMessages}
                loginErrorMessage={props.loginErrorMessage}
                handleBack={props.handleBack}
                handleClose={props.handleClose}
                handleInputChange={props.handleInputChange}
                handleStepSubmit={props.handleStepSubmit}
              />
            </Wrapper>
          );
        default:
          return null;
      }
    }

    return (
      <Wrapper>
        <Step1
          isFetching={props.isFetching}
          clearErrorMessages={props.clearErrorMessages}
          loginErrorMessage={props.loginErrorMessage}
          selectedValue={props.userRole}
          handleBack={props.handleBack}
          handleChange={props.handleRoleSelection}
          nextStepSubmit={props.handleStepSubmit}
        />
      </Wrapper>
    );
  }
};

export default View;
