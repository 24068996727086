import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { reporaColor, reporaDevice } from "../../components/global/commonStyles";
import MissionBackground from "./assets/mission.png";

import Footer from "../../components/FooterUpdated/Footer";

import questionBackground from "./assets/questionBackground.svg";

import user1 from "./assets/user1.png";
import user2 from "./assets/user2.png";
import user3 from "./assets/user3.png";

const CompanySection = styled.section`
  background: linear-gradient(297deg, ${reporaColor.greenBlue68}, ${reporaColor.aquaBlue84} 93%, ${reporaColor.brightSkyBlue85});
  height: 476px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 10px;

  h1 {
    font-size: 52px;
    font-weight: bold;
    font-style: normal;
    color: #ffffff;
  }

  p {
    color: white;
    margin-top: 40px;
  }
`;

const MissionSection = styled.section`
  background-image: url(${MissionBackground});
  background-size: cover;
  height: 615px;
  position: relative;
  padding: 0 10px;
  margin-bottom: 120px;

  div {
    height: 240px;
    max-width: 1170px;
    border-radius: 5px;
    box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    color: ${reporaColor.brightSkyBlue};
    padding: 70px 95px;
    font-size: 36px;
    margin: 0 20px;

    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    bottom: 0;
    bottom: -120px;
  }

  @media ${reporaDevice.sm} {
    div {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media ${reporaDevice.xs} {
    height: 300px;

    div {
      font-size: 16px;
      margin: 0 10px;
      padding: 10px;
      height: 100px;
      display: flex;
      align-items: center;
      bottom: -50px;
    }
  }
`;

const LeadershipSection = styled.section`
  display: flex;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 70px;
  margin-top: 160px;
  margin-bottom: -90px;

  >div {
    flex: 1;

    &:first-child {
      margin-right: 140px;

      h3 {
        font-size: 42px;
        font-weight: bold;
      }

      p {
        margin-top: 20px;
      }

      >div {
        height: 476px;
        border-radius: 14.5px;
        box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.08);
        background-color: white;
        padding: 40px 30px;
        margin-top: 75px;

        p {
          color: ${reporaColor.brightSkyBlue};
          text-align: center;
        }
      }
    }
  }

  @media ${reporaDevice.sm} {
    padding: 0 20px;

    >div:first-child {
      margin-right: 20px;
    }
  }

  @media ${reporaDevice.xs} {
    padding: 0 10px;
    margin-top: 0;
    margin-bottom: 0;
    flex-direction: column;

    >div:first-child {
      margin: 0;
    }

    >div:last-child {
      margin-top: 50px;
    }
  }
`;

const LeaderRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 35px;

  &:last-of-type {
    margin-bottom: 0;
  }

  img {
    width: 115px;
    height: 115px;
    margin-right: 55px;
  }

  p:first-child {
    color: black;
    font-size: 20px;
    font-weight: bold;
  }

  p:last-child {
    color: ${reporaColor.warmGrey};
    font-size: 14px;
    font-weight: bold;
  }

  @media ${reporaDevice.xs} {
    img {
      margin-right: 20px;
    }
  }
`;

const QuestionSection = styled.section`
  height: 476px;
  background-image: url("${questionBackground}");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  h2 {
    color: white;
    font-size: 42px;
    font-weight: bold;
    padding-top: 140px;
    text-align: center;
  }
`;

const ContactusButton = styled(Link)`
  color: black;
  height: 40px;
  width: 207px;
  border-radius: 2px;
  background-color: white;
  font-size: 18px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 31px;
`;

class OurCompany extends React.Component {

  render() {
    return (
      <section>
        <CompanySection>
          <h1>Our Company</h1>
          <p>Repora is empowering customers to provide insight into the customer-rep <br></br> experience in order to improve companies, and their sales reps.</p>
        </CompanySection>

        <MissionSection>
          <div>
            Repora’s mission is to maximize enterprise sales force efficiency, and keep the ultimate pulse of the customer.
          </div>
        </MissionSection>

        <LeadershipSection>
          <div>
            <h3>Leadership</h3>
            <p>At Repora, we"re committed to constant improvement. <br></br>We have a passion for transparency, and believe that qualitative data can lead to positive change.</p>

            <div>
              <p>We’re backed by some of today’s most <br></br> notable tech investors:</p>
            </div>
          </div>

          <div>
            <LeaderRow>
              <img src={user1} alt="leadership" />
              <div>
                <p>John Ghashghai</p>
                <p>Founder & CEO</p>
              </div>
            </LeaderRow>
            <LeaderRow>
              <img src={user2} alt="leadership" />
              <div>
                <p>James Yokota</p>
                <p>Senior Vice President, Engineering</p>
              </div>
            </LeaderRow>
            <LeaderRow>
              <img src={user3} alt="leadership" />
              <div>
                <p>Alex Kilpelainen</p>
                <p>Senior Vice President, Strategy</p>
              </div>
            </LeaderRow>
          </div>
        </LeadershipSection>

        <QuestionSection>
          <h2>Questions? We’re here to help.</h2>
          <ContactusButton to="/contactus">Contact Us</ContactusButton>
        </QuestionSection>

        <Footer />

      </section>
    );
  }
}

export default OurCompany;
