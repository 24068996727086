import React from "react";
import styled, { css } from "styled-components";
import RatingStars from "../../../components/RatingStars/RatingStars";
import { reporaDevice } from "../../../components/global/commonStyles";

const Wrapper = styled.div`
  background: white;
  box-shadow: 0 0 8px 0 #00000033;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  margin: 5px;

  p {
    margin-bottom: 20px;
  }

  font-size: 14px;
  width: calc(880px / 5);
  height: 90px;

  ${props => props.big && css`
    font-size: 18px;
    width: 300px;
    height: 150px;
  `}

  @media ${reporaDevice.xs} {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
  }
`;

const Card = props => (
  <Wrapper big={props.big}>
    <p>{props.title}</p>
    <RatingStars rating={5} static big={props.big}/>
  </Wrapper>
);

export default Card;