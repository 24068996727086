import React from "react";
import styled from "styled-components";
import DisputeForm from "./DisputeForm";
import { Link } from "react-router-dom";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faFlag,
  faReply,
  faTags
} from "@fortawesome/free-solid-svg-icons";
import {
  parseDate,
  formatDate,
  getJsonFromUrl,
} from "../../../components/global/utils.js";

import {
  reporaColor,
  reporaDevice,
  reporaButtonStyles,
  Body3,
  Body4,
  Body5,
  Body6,
  ReporaButton,
} from "../../../components/global/commonStyles";
import Model from "../../../components/Modal/Model";
import RatingStars from "../../../components/RatingStars/RatingStars";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import Button from "../../../components/Buttons/Button";
import constants from "../../../constants";

import NegativeIcon from "./assets/thumb_down.svg";
import PositiveIcon from "./assets/thumb_up.svg";

import UpvoteIcon from "./assets/upvotes-green.svg";
import UpvoteGreyIcon from "./assets/upvotes-grey.svg";
import { TimeFilter, Trends } from "../constants";
import RenderFeedbackResponse from "./RenderFeedbackResponse";
import OutsideClickHandler from "react-outside-click-handler";
import AddTagOptions from "../../ViewProgram/components/AddTagOptions";

const DropdownOptions = styled.ul`
  position: absolute;
  top: 50%;
  left: -35vw;
  width: 33vw;
  transform: translate(0, -50%);
  box-sizing: border-box;
  border-radius: 3px;
  margin: 0;
  padding: 10px;
  list-style-type: none;
  background-color: ${reporaColor.grey91};
  border-top: none;
  z-index: 1;
  overflow: auto;
  font-size: 12px;
  text-align: center;

  @media ${reporaDevice.mobile} {
    left: -62vw;
    width: 60vw;
  }
`;

const EmailButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: solid 1px ${reporaColor.grey91};
  background-color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  color: ${reporaColor.warmGrey};
  padding: 2px 15px;
`;
const Wrapper = styled.div`
  background: white;
  padding: 25px 20px 60px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  flex-wrap: wrap;
  @media ${reporaDevice.tablet} {
    flex-direction: row;
  }
`;

const MainWrapper = styled.div`
  margin-bottom: 10px;
  &:hover {
    box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.08);
  }
  @media ${reporaDevice.tablet} {
    flex-direction: row;
  }
`;

const NameWrapper = styled.div`
  display: flex;

  p {
    margin-right: 10px;
  }

  @media ${reporaDevice.tablet} {
    flex-direction: column;
    width: 35%;

    p {
      margin-right: 0;

      img {
        width: 15px;
        padding: 0px;
        margin: 0 5px 0 0;
        height: 14px;
        top: 2px;
        position: relative;
      }
    }
  }
`;

export const LikesWrapper = styled.div`
  display: flex;

  /* img {
    height: 100%;
  } */

  p {
    margin-left: 5px;
  }
`;

const DetailsWrapper = styled.div`
  width: 100%;

  @media ${reporaDevice.tablet} {
    width: 65%;
  }
`;

const DetailsHeader = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;

  p:nth-child(2) {
    cursor: pointer;
  }

  & > div {
    width: 100%;
  }

  p {
    &:last-child {
      margin-left: auto;
      justify-self: flex-end;
    }

    svg {
      margin-left: 5px;
    }
  }

  @media ${reporaDevice.desktop} {
    & > div {
      width: auto;
    }

    p {
      margin-left: 10px;

      &:last-child {
        margin-left: auto;
        justify-self: flex-end;
      }

      svg {
        margin-left: 5px;
      }
    }
  }
`;

const ExpandedRating = styled.ul`
  margin: 15px 0;

  li {
    display: flex;
    height: auto;
    overflow: hidden;
    transition: max-height 0.5s;
    max-height: 0;

    ${(props) => (props.isOpen ? "max-height: 100px;" : "max-height: 0")}

    p {
      margin-left: 10px;
    }
    .standard{
      text-transform: capitalize;
    }
  }
`;

const Tooltip = styled.section`
  width: 300px;
  padding: 15px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.15);
  color: black;
  opacity: 1;
  border-radius: 2px;
  background-color: #ffffff;
  border: 1px solid #dedede;

  position: absolute;
  top: -170px;

  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: white;
    border-width: 10px;
    margin-left: -10px;
  }
  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: #dedede;
    border-width: 11px;
    margin-left: -11px;
  }

  textarea {
    border: 1px solid #e8e8e8;
    background: #fafafa;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    font-size: 12px;
    width: 100%;
    resize: none;

    font-size: 12px;
    font-weight: bold;
    line-height: 1.58;
  }
`;

const UpvoteButton = styled.div`
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: solid 1px ${reporaColor.grey87};
  background: white;
  cursor: pointer;

  position: absolute;
  bottom: 24px;
  right: 20px;
`;

export const UpvoteRepButton = styled.div`
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: solid 1px ${reporaColor.grey87};
  cursor: pointer;
  
`;

const EditButtonContainer = styled.div`
  position: absolute;
  bottom: 24px;
  right: 20px;
`;

const DisputeButton = styled.a`
  ${reporaButtonStyles};
  border: solid 1px ${reporaColor.grey87};
  position: absolute;
  bottom: 24px;
  right: 20px;
  padding: 4px;
  font-size: 12px;
  color: ${reporaColor.warmGrey};
  width: 70px;
  height: 22px;
`;

const TagButton = styled.a`
  ${reporaButtonStyles};
  border: solid 1px ${reporaColor.grey87};
  position: absolute;
  bottom: 5px;
  left: 20px;
  padding: 4px;
  font-size: 12px;
  color: ${reporaColor.warmGrey};
  width: 70px;
  height: 22px;
`;

const UnderDispute = styled.a`
  /* border: solid 1px #DEDEDE; */
  position: absolute;
  bottom: 1%;
  right: 1%;
  /* padding: 4px; */
  /* font-size: 12px; */
  color: #9b9b9b;
  /* width: 70px; */
  height: 22px;
`;
const DisputeRejected = styled(UnderDispute)`
bottom: ${(props) => props.bottom || "24px"}
right: ${(props) => props.right || "25%"}
`;

const DisputeTooltipWrapper = styled.div`
  .manage-tooltip {
    color: ${reporaColor.white};
    background: ${reporaColor.black};
    opacity: 1;
    width: 250px;
    padding: 10px;
  }
`;

export const TooltipWrapper = styled.span`
  display: inline-flex;
`;

const YourRatingWrapper = styled.span`
  color: ${reporaColor.black};
`;

const Replay = styled.span`
  position: absolute;
  bottom: 23px;
  right: ${(props) => props.right || "100px"};
  color: #fff;
  font-size: 13px;
  padding: 2px;
  width: 27px;
  text-align: center;
  border-radius: 3px;
  border: solid 1px transparent;
  border: solid 1px #dedede;
  bottom: 24px;
  color: #9b9b9b;
  cursor: pointer;
`;

const Replay1 = styled.span`
  position: absolute;
  font-size: 13px;
  text-align: left;
  border-radius: 3px;
  border: solid 1px transparent;
  bottom: 5px;
  color: #9b9b9b;
  cursor: pointer;
  margin-left:28px;
`;

export const Tags = styled.div`
  height: 20px;
  width: 50vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Main = styled.div`
  width: 100%;
  background-color: rgb(250, 250, 250);
  margin-top: 10px;
  padding: 2%;
`;
const Section1 = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 10px >div >span >p {
    color: #9b9b9b;
    font-size: 14px;
  }
`;

const Section3 = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2%;
`;

const CancelDiv = styled.div`
  display: flex;
  > div {
    padding: 8px;
    color: #ff2156;
  }
  > a {
    background-color: #ff2156;
    color: #ffffff;
  }
`;
class FeedbackCard extends React.Component {
  /**
   * Enum to classify dispute types
   * @type {Object}
   */
  static DISPUTE_TYPE = Object.freeze({
    DISPUTE_RATING: 0,
    REPORT_ABUSE: 1,
  });

  state = {
    detailsOpen: false,
    name: "",
    charactersLeft: 1000,
    expanded: {},
    customerUpvote: false,
    notifyMe: false,
    tempData: "",

    editRespone: false,
    replayComment: "",
    cancelReplayComment: false,
    showTooltip: false,
    votedId: "",
    deleted: false,
    editTooltipShow: false,
    addTag:false
    // repFeedBack: this.props.data.repFeedBack,
  };

  onUpvoteClick = (event) => {
    let { customerUpvote } = this.state;
    const feedData = this.props.data;
    const {
      data: { feedbackId },
      handleUpvote,
      upvoteRequest: { isFetchingUpvote },
    } = this.props;
    const { data, rep, authUser } = this.props;
    let isvotedFlag = false;
    const Obj = {
      feedbackId: data.feedbackId,
      reviewerId: authUser.id,
      vote: !isvotedFlag,
    };
    if (!isFetchingUpvote) {
      this.setState({ customerUpvote });
      var queryParams = getJsonFromUrl(this.props.location.search);
      handleUpvote(feedbackId, !feedData.upVotedByCustomer, queryParams.id);
    }
  };

  handleDetailsDropdown = (newExpanded, index, event) => {
    this.setState({
      detailsOpen: !this.state.detailsOpen,
    });
  };

  handleDisputeDropdown = () => {
    const { isDisputeFormOpen, disputeFormId, toggleDisputeForm } = this.props;
    const nextFormId = isDisputeFormOpen ? undefined : disputeFormId;
    toggleDisputeForm(nextFormId);
  };

  handleSubmitDispute = (feedbackId) => {
    const { disputeType, data } = this.props;
    let ratingCategory = data.ratingCategory;
    let overAllRating = 0;
    ratingCategory.map((data) => {
      overAllRating = data.value + overAllRating;
    });
    const feedbackCreatedDate = parseDate(this.props.data.createdDate);
    //REP
    if (disputeType === FeedbackCard.DISPUTE_TYPE.DISPUTE_RATING) {
      const {
        repId,
        handleDisputeFeedback,
        reportAbuseData,
        disputed,
        handleAbuseFeedback,
        authUser,
      } = this.props;
      handleDisputeFeedback(repId, feedbackId, this.state.name, "REP");
      handleAbuseFeedback(feedbackId, {
        nameRep: authUser.firstName + " " + authUser.lastName,
        emailRep: authUser.email,
        // "nameReporter": data.name,
        nameReporter: authUser.firstName + " " + authUser.lastName,
        emailReporter: authUser.email,

        notifyMe: false,
        message: this.state.name,
        comment: data.comment,

        role: "Rep",
        customerName: data.name,
        repComapny: authUser.companyName,
        customerComapny: data.company,
        overall_rating: data.ratingCategory,
        customercomment: data.comment,
        overAllRating: overAllRating,
        reviewDate: formatDate(feedbackCreatedDate),
        reviewId: feedbackId,
        customRatingCategory: data.customRatingCategory,
      });
    } else if (disputeType === FeedbackCard.DISPUTE_TYPE.REPORT_ABUSE) {
      const {
        repId,
        disputeType,
        handleDisputeFeedback,
        reportAbuseData,
        disputed,
        authUser,
        handleDisputeRepFeedback,
      } = this.props;

      handleDisputeRepFeedback(
        data.repId,
        feedbackId,
        this.state.name,
        "COMPANY"
      );
      handleDisputeFeedback(feedbackId, {
        ...reportAbuseData,
        message: this.state.name,
        comment: data.comment,
        notifyMe: true,
        role: "Company",
        customerName: data.name,
        repComapny: authUser.companyName,
        customerComapny: data.company,
        overall_rating: data.ratingCategory,
        customercomment: data.commentcomment,
        overAllRating: overAllRating,
        reviewDate: formatDate(feedbackCreatedDate),
        reviewId: feedbackId,
        customRatingCategory: data.customRatingCategory,
      });
    }
  };

  handleInputChange = (event) => {
    const value = event.target;
    const field = event.target.getAttribute("name");
    if (field === this.props.feedbackId) {
      let charactersLeft = 1000 - value.value.length;
      this.setState({
        name: value.value.substring(0, 1000),
        charactersLeft: charactersLeft,
      });
    } else {
      this.setState({ [field]: value });
    }
  };

  toggleNotifyMe = (event) => {
    this.setState((prevState) => {
      return { notifyMe: !prevState.notifyMe };
    });
  };
  setTempData = () => {
    this.handleDisputeDropdown();
    this.setState({ tempData: "hide" });
  };

  handleReplay = (flag) => {
    this.setState({ editRespone: flag });
  };

  handleEditReplay = () => {
    this.setState((prevState) => ({
      replayComment: this.props.repFeedBack,
      editRespone: true,
    }));
    this.props.onEditResponseClick(this.props.feedbackId);
  };

  handlereplayChange = (event) => {
    this.setState({ replayComment: event.target.value });
  };

  handleShowTooltip = () => {
    if (!this.state.showTooltip) {
      this.setState({
        showTooltip: !this.state.showTooltip,
      });
      document
        .getElementById("contentWrapper")
        .addEventListener("scroll", this.hideTooltip, false);
      document
        .getElementById("contentWrapper")
        .addEventListener("click", this.hideTooltip, false);
    } else {
      this.handleDeleteResponse();
    }
  };

  hideTooltip = (event) => {
    if (event.target.id !== "ConfirmDeleteAttr") {
      document
        .getElementById("contentWrapper")
        .removeEventListener("scroll", this.hideTooltip);
      document
        .getElementById("contentWrapper")
        .removeEventListener("click", this.hideTooltip);
    } else {
      this.handleDeleteResponse();
    }

    this.setState({
      showTooltip: false,
    });
  };

  renderTooltip = () => {
    const message = `Are you sure you want to delete this response? `;
    return (
      <Tooltip
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        <textarea type="text" readOnly value={message} rows="3" />
        <ReporaButton
          id="ConfirmDeleteAttr"
          watermelon
          onClick={this.handleDeleteResponse}
          width="100%"
        >
          Confirm
        </ReporaButton>
      </Tooltip>
    );
  };

  handleSubmitResponse = () => {
    const Obj = {
      customerFeedbackId: this.props.data.feedbackId,
      reviewerId: this.props.data.repId,
      reviewerType: "REP",
      reviewedUserId: this.props.data.reviewerId || "", // empty in case of anonymous feedback
      reviewedUserType: "CUSTOMER",
      reviewDetail: this.state.replayComment,
    };
    this.props.handleCustomerFeedBack(Obj);

    this.props.onEditResponseClick(this.props.data.feedbackId);
  };

  handleUpdateResponse = () => {
    const Obj = {
      feedbackResponseId: this.props.data.feedbackResponseList[0].id, // id field from feedbackResponseList object
      reviewDetail: this.state.replayComment,
    };
    this.handleReplay(false);
    this.props.handleUpdateCustomerFeedBack(Obj);
    // this.setState({ repFeedBack: this.props.repFeedBack });
  };

  onUpvoteRepClick = () => {
    const { data, rep, authUser } = this.props;
    let _feedList = data.feedbackResponseList;
    let isvotedFlag = false;
    if (_feedList) {
      _feedList = _feedList[0];
      if (_feedList.upVoted && _feedList.upVoted.length > 0) {
        _feedList.upVoted.forEach((ele) => {
          if (ele.customerId == authUser.id) {
            isvotedFlag = true;
          }
        });
      }
    }
    const Obj = {
      feedbackId: data.feedbackResponseList[0].id, // id field from feedbackResponseList object
      reviewerId: authUser.id,
      vote: !isvotedFlag,
    };

    var queryParams = getJsonFromUrl(this.props.location.search);

    this.props.handleupVoteRepFeedCustomer(Obj, queryParams.id);
  };

  handleDeleteResponse = () => {
    document
      .getElementById("contentWrapper")
      .removeEventListener("scroll", this.hideTooltip);
    document
      .getElementById("contentWrapper")
      .removeEventListener("click", this.hideTooltip);
    this.props.handleDeleteCustomerFeedBack({
      id: this.props.data.feedbackResponseList[0].id,
    });
    this.setState({ deleted: true });
    this.props.onEditResponseClick(this.props.data.feedbackId);
  };

  renderDisputeCTA(disputeType) {
    const getTooltipForDisputeRejected = () => {
      if (
        this.props.currentCompanyConnected &&
        this.props.currentCompanyPrivate
      ) {
        return <span>{constants.DISPUTE_REJECTED_HELP.PRIVATE_COMPANY}</span>;
      } else {
        return <span>{constants.DISPUTE_REJECTED_HELP.PUBLIC_COMPANY}</span>;
      }
    };
    if (
      this.props.data.disputeStatus === "IN_DISPUTE" &&
      this.state.tempData === ""
    ) {
      return (
        <DisputeTooltipWrapper>
          <UnderDispute data-tip data-for="inDipute">
            Rating Under Dispute
          </UnderDispute>
          <ReactTooltip
            globalEventOff="click"
            id="inDipute"
            data-effect="solid"
            className="manage-tooltip"
            disable={constants.IS_MOBILE}
          >
            <span>{constants.IN_DISPUTE}</span>
          </ReactTooltip>
        </DisputeTooltipWrapper>
      );
    }
    switch (disputeType) {
      case FeedbackCard.DISPUTE_TYPE.DISPUTE_RATING:
        //  this.state.editRespone === true ? (
        return (
          <>
            {this.props.data.disputeStatus === "DISPUTE_REJECTED" && (
              <DisputeTooltipWrapper>
                <DisputeRejected
                  bottom={"24px"}
                  right={"140px"}
                  data-tip
                  data-for={"disputeRejected"}
                >
                  Dispute Not Accepted
                </DisputeRejected>
                <ReactTooltip
                  globalEventOff="click"
                  id="disputeRejected"
                  data-effect="solid"
                  className="manage-tooltip"
                  disable={constants.IS_MOBILE}
                >
                  {getTooltipForDisputeRejected()}
                </ReactTooltip>
              </DisputeTooltipWrapper>
            )}
            <div>
              {this.props.repFeedBack === "" && (
                <Replay
                  data-tip
                  data-for="replay"
                  onClick={() => this.handleReplay(true)}
                >
                  <FontAwesomeIcon icon={faReply} />
                  <ReactTooltip
                    globalEventOff="click"
                    id="replay"
                    data-effect="solid"
                    disable={constants.IS_MOBILE}
                  >
                    <span>Leave a response to this review...</span>
                  </ReactTooltip>
                </Replay>
              )}
              <DisputeButton
                data-tip
                data-for="disputeTip"
                onClick={this.handleDisputeDropdown}
                secondary
              >
                <span>Dispute...</span>
                <ReactTooltip
                  globalEventOff="click"
                  id="disputeTip"
                  data-effect="solid"
                  disable={constants.IS_MOBILE}
                >
                  <span>
                    You can dispute a<br />
                    rating that you
                    <br />
                    believe is malicious
                    <br />
                    or fraudulent
                  </span>
                </ReactTooltip>
              </DisputeButton>
            </div>
          </>
        );
      // )
      // : (
      //   this.responseWrapper(false)
      // );
      case FeedbackCard.DISPUTE_TYPE.REPORT_ABUSE:
        return (
          <>
            {this.props.data.disputeStatus === "DISPUTE_REJECTED" && (
              <DisputeTooltipWrapper>
                <DisputeRejected
                  bottom={"24px"}
                  right={"100px"}
                  data-tip
                  data-for={"disputeRejected"}
                >
                  Dispute Not Accepted
                </DisputeRejected>
                <ReactTooltip
                  globalEventOff="click"
                  id="disputeRejected"
                  data-effect="solid"
                  className="manage-tooltip"
                  disable={constants.IS_MOBILE}
                >
                  {getTooltipForDisputeRejected()}
                </ReactTooltip>
              </DisputeTooltipWrapper>
            )}
            <div>
              {this.props.repFeedBack === "" &&
                this.props.repId === this.props.authUser.id && (
                  <Replay
                    data-tip
                    data-for="replay"
                    onClick={() => this.handleReplay(true)}
                    right={"60px"}
                  >
                    <FontAwesomeIcon icon={faReply} />
                    <ReactTooltip
                      globalEventOff="click"
                      id="replay"
                      data-effect="solid"
                      disable={constants.IS_MOBILE}
                    >
                      <span>Leave a response to this review...</span>
                    </ReactTooltip>
                  </Replay>
                )}

              <DisputeButton
                data-tip
                data-for="abuseTip"
                onClick={this.handleDisputeDropdown}
                secondary
                style={{ width: 22 }}
              >
                <FontAwesomeIcon icon={faFlag} />
                <ReactTooltip
                  id="abuseTip"
                  data-effect="solid"
                  disable={constants.IS_MOBILE}
                  globalEventOff="click"
                >
                  <span>
                    You can dispute a<br />
                    rating that you
                    <br />
                    believe is malicious
                    <br />
                    or fraudulent
                  </span>
                </ReactTooltip>
              </DisputeButton>
            </div>
         </>
        );
      default:
        return null;
    }
  }

  renderDisputeForm(disputeType) {
    let label = "Tell us why this rating should be removed, or your desired resolution. FYI, until the dispute is resolved, the rating is only visible to you, your company (if you are connected to one), and the original customer. Repora will contact you within 24-48 hours of your dispute."
    if(this.props.authUser && (this.props.authUser.userRole === "ADMIN" || this.props.authUser.userRole === "REGULAR") ){
      label = "Tell us why this rating should be removed, or your desired resolution. FYI, until the dispute is resolved (and permanently if the dispute is accepted), this rating will not be included in any of your scores. Repora will contact you within 24-48 hours."
    }
    const sharedProps = {
      label: label,
      newLabel:"",
      feedbackId: this.props.feedbackId,
      onCancel: this.handleDisputeDropdown,
      onSubmit: () => this.handleSubmitDispute(this.props.feedbackId),
      disputeRequest: this.props.disputeRequest,
      onTextAreaChange: this.handleInputChange,
      charactersLeft: this.state.charactersLeft,
      comment: this.state.name,
    };
    switch (disputeType) {
      case FeedbackCard.DISPUTE_TYPE.DISPUTE_RATING:
        return (
          <DisputeForm
            title="Dispute Customer Rating"
            submitCTA="Submit Dispute"
            titleDone="Your Dispute was Submitted"
            onCloseDisputed={() => {
              this.props.onCloseDisputed(this.props.feedbackId);
            }}
            {...sharedProps}
          />
        );
      case FeedbackCard.DISPUTE_TYPE.REPORT_ABUSE:
        return (
          <DisputeForm
            title="Report Abuse"
            submitCTA="Report abuse"
            titleDone="Abuse report has been submitted"
            notifyMe={this.state.notifyMe}
            notifyMeCTA="Someone from Repora will be in touch very soon"
            onNotifyMeClick={this.toggleNotifyMe}
            temp={this.setTempData}
            onCloseDisputed={() => {
              this.props.onCloseDisputed(this.props.feedbackId);
              this.setTempData();
            }}
            // abuse="true"
            {...sharedProps}
          />
        );
      default:
        return null;
    }
  }

  renderFeedbackVoteCount(feedData) {
    const request = this.props.upvoteRequest;
    if (
      request &&
      request.upvoteResponseReady &&
      request.data &&
      request.data.payload === "Vote added"
    ) {
      return feedData.upVotes;
    } else {
      return feedData.upVotes;
    }
  }

  handleEditTooltip() {
    this.setState({ editTooltipShow: !this.state.editTooltipShow });
  }

  renderLikeEditButton() {
    const feedData = this.props.data;
    let norolechange = false;

    if (this.props.disputeFormId && !this.props.isDisputeFormOpen) {
      return this.renderDisputeCTA(
        this.props.authUser && this.props.authUser.userRole === "REP" ? 0 : 1
        // !this.state.editRespone ? 0 : this.props.disputeType
      );
    }
    const currentDate = moment(new Date());
    const createdDate = moment(feedData.createdDate);
    if (this.props.userRole == "CUSTOMER" && !this.props.disputeFormId) {
      if (feedData.reviewerId !== this.props.authUser.id) {
        return (
          <UpvoteButton
            onClick={this.onUpvoteClick}
            data-tip={
              feedData.upVotedByCustomer
                ? "Click to remove <br/> your upvote"
                : "Upvote if you agree <br/>or found this useful"
            }
            data-multiline="true"
            data-effect="solid"
          >
            <img
              src={feedData.upVotedByCustomer ? UpvoteIcon : UpvoteGreyIcon}
              alt="upvote"
            />
          </UpvoteButton>
        );
      } else if (currentDate.diff(createdDate, "hours") <= 24) {
        this.props.repProfile.payload.customerFeedbackList.forEach((fb) => {
          if (
            fb.feedbackId == feedData.feedbackId &&
            fb.repType == this.props.repProfile.payload.repType
          ) {
            norolechange = true;
          }
        });
        if (norolechange) {
          return (
            <EditButtonContainer>
              <Link
                to={`/raterep?id=${feedData.repId}&rateid=${
                  feedData.feedbackId
                }`}
                className="text-decoration-none"
              >
                <Button size="xsmall" color="white">
                  Edit
                </Button>
              </Link>
            </EditButtonContainer>
          );
        } else {
          return (
            <EditButtonContainer>
              <OutsideClickHandler
                onOutsideClick={
                  this.state.editTooltipShow
                    ? this.handleEditTooltip.bind(this)
                    : () => {}
                }
              >
                <EmailButton
                  onClick={this.handleEditTooltip.bind(this)}
                  data-multiline="true"
                  data-place="left"
                  data-position="fixed"
                >
                  Edit
                </EmailButton>
                {this.state.editTooltipShow && (
                  <DropdownOptions>
                    <li>
                      This person’s role has changed since you entered your
                      review. Please give them a new review rather than editing
                      this review.
                    </li>
                  </DropdownOptions>
                )}
              </OutsideClickHandler>
            </EditButtonContainer>
          );
        }
      }
    }
  }

  filterRatingDetailElement(rating, userRole) {
    switch (rating.type) {
      case "PRODUCT_KNOWLEDGE": {
        return true;
      }
      case "COMMUNICATION_SKILLS": {
        return true;
      }
      case "TRUSTWORTHINESS": {
        return true;
      }
      case "PROFESSIONALISM": {
        return userRole !== constants.USER_ROLES.CUSTOMER;
      }
      case "ORGANIZATION_SKILLS": {
        return userRole !== constants.USER_ROLES.CUSTOMER;
      }
      case "AVAILABILITY": {
        return userRole !== constants.USER_ROLES.CUSTOMER;
      }
      case "FOLLOW_THROUGH": {
        return userRole !== constants.USER_ROLES.CUSTOMER;
      }
      case "PUNCTUALITY": {
        return userRole !== constants.USER_ROLES.CUSTOMER;
      }
      default:
        return false;
    }
  }

  getFormattedDate(feedback) {
    const createdDate = parseDate(feedback.createdDate);

    if (createdDate) {
      if (feedback.anonymous) {
        return moment(createdDate).format("MM/YYYY");
      } else {
        return formatDate(createdDate);
      }
    } else {
      return "Invalid Date";
    }
  }

  renderExpandedRatingList = () => {
    const feedData = this.props.data;
    const userRole = this.props.userRole;
    const rateList = feedData.ratingCategory || [];
    let tempArr = [];
    feedData.ratingCategory.forEach((ele) => {
      tempArr.push(ele.type);
    });
    const sortOrder = tempArr;
    let filteredList = rateList.sort((a, b) =>
      sortOrder.indexOf(a.type) > sortOrder.indexOf(b.type) ? 1 : -1
    );
    if (this.props.authUser) {
      if (feedData.reviewerId !== this.props.authUser.id) {
        filteredList = filteredList.filter((rate) =>
          this.filterRatingDetailElement(rate, userRole)
        );
      }
    }

    const empFeedData = feedData.ratingCategory.map((rate) => {
      let rateFormatted = rate.type.replace(/_/g, " ").toLowerCase();

      return (
        <li key={rate.type + rate.value}>
          <RatingStars static rating={rate.value} />
          <Body5 className="standard" textColor={reporaColor.warmGrey}>{rateFormatted}</Body5>
        </li>
      );
    });

    let customefeedDaata = "";
    if (this.props.data.customRatingCategory) {
      customefeedDaata = feedData.customRatingCategory.map((rate) => {
        // let rateFormatted = rate.type.replace(/_/g, " ").toLowerCase();
        let rateFormatted = rate.type.replace("_"," ")
        return (
          <li key={rate.type + rate.value}>
            <RatingStars static rating={rate.value} />
            <Body5 textColor={reporaColor.warmGrey}>{rateFormatted}</Body5>
          </li>
        );
      });
    }

    return [...empFeedData, ...customefeedDaata];
  };

  handleAddTag=(t)=>{
    this.setState({addTag:t})
  }

  handleshowtags=()=>{
    return(
    <div>
      <TagButton
        data-tip
        data-for="Tag"
        onClick={(e)=>this.handleAddTag(true)}
        secondary
        style={{ width: 22 }}
      >
        <FontAwesomeIcon icon={faTags} />
        <ReactTooltip
          id="Tag"
          data-effect="solid"
          disable={constants.IS_MOBILE}
          globalEventOff="click"
        >
          <span>
            Add Tag
          </span>
        </ReactTooltip>
      </TagButton>
    </div>
    );
  }

  render() {
    const tags=this.props.data.tags
    const feedData = this.props.data;
    const {repId} =this.props
    const disputeFormId = this.props.disputeFormId;
    const date = this.getFormattedDate(feedData);
    let feedBackData = "";
    if (this.props.authUser == null && !this.props.publicRep) {
      feedBackData = (
        <TooltipWrapper
          data-tip="Customer name"
          data-tip-disable={constants.IS_MOBILE || !disputeFormId}
          data-effect="solid"
        >
          {feedData.name}
        </TooltipWrapper>
      );
    }
    let tagNames=[]
    if(tags && tags.length>0){
      tags.map((i)=>tagNames.push(i.tagName))
    }
    let finalString=tagNames.join(', ')

    return (
      <>
        <Model
        open={this.state.addTag}
        handleClose={() => this.handleAddTag(false)}
        title={"Select Tags"}
        dialogHeight={"400px"}
        dialogWidth={"400px"}
        modelContent={
          <AddTagOptions 
          cancel={() => this.handleAddTag(false)} 
          data={this.props.tagList} 
          tagNames={tagNames} 
          feedbackId={feedData.feedbackId}
          repId={repId}
          feedbackCategory= {"CUSTOMER_FEEDBACK"} />
        }
        scroll={"paper"}
        />
        <MainWrapper>
          <Wrapper id={`Wrapper-${this.props.feedbackId}`}>
            <NameWrapper>
              <Body3 textColor={reporaColor.warmGrey}>
                {feedData.verified === true && (
                  <TooltipWrapper
                    data-tip={
                      !!disputeFormId
                        ? "This customer's<br/>identity has been<br/>verified by signing up<br/>using a social account"
                        : "Verified Customer"
                    }
                    data-effect="solid"
                    data-multiline="true"
                  >
                    <img
                      className="verified"
                      src="/assets/global/verified.svg"
                      alt="verified"
                    />
                  </TooltipWrapper>
                )}
                {this.props.authUser ? (
                  <TooltipWrapper
                    data-tip="Customer name"
                    data-tip-disable={constants.IS_MOBILE || !disputeFormId}
                    data-effect="solid"
                  >
                    {feedData.reviewerId !== this.props.authUser.id ? (
                      feedData.name
                    ) : (
                      <YourRatingWrapper>Your Rating</YourRatingWrapper>
                    )}
                  </TooltipWrapper>
                ) : (
                  ""
                )}
                {feedBackData}
              </Body3>
              {feedData?.upVotes !== 0 &&
              <LikesWrapper>
                <TooltipWrapper
                  data-tip="Number of upvotes"
                  data-effect="solid"
                >
                  <img src={UpvoteIcon} alt="upvote" />
                  <Body4 textColor={reporaColor.greenBlue}>
                    {this.renderFeedbackVoteCount(feedData)}
                  </Body4>
                </TooltipWrapper>
              </LikesWrapper>}
            </NameWrapper>

            <DetailsWrapper>
              <DetailsHeader>
                <RatingStars static rating={feedData.average} />
                <Body5
                  onClick={this.handleDetailsDropdown}
                  textColor={reporaColor.warmGrey}
                  data-tip={
                    !disputeFormId
                      ? "Show Ratings"
                      : "View all rating<br/>categories"
                  }
                  data-effect="solid"
                  data-multiline="true"
                >
                  <span>Details</span>
                  <FontAwesomeIcon
                    icon={this.state.detailsOpen ? faChevronUp : faChevronDown}
                  />
                </Body5>
                <Body5 textColor={reporaColor.warmGrey}>{date}</Body5>
              </DetailsHeader>

              <ExpandedRating isOpen={this.state.detailsOpen}>
                {this.renderExpandedRatingList()}
              </ExpandedRating>

              {this.state.detailsOpen && (
                <span>
                  {feedData.experience ? (
                    <TooltipWrapper data-effect="solid" data-multiline="true">
                      <img
                        src={PositiveIcon}
                        alt="positive-icon"
                        align="left"
                      />
                      <Body3
                        className="m-l-5"
                        textColor={reporaColor.greenBlue}
                      >
                        Positive Experience
                      </Body3>
                    </TooltipWrapper>
                  ) : (
                    <TooltipWrapper data-effect="solid" data-multiline="true">
                      <img
                        src={NegativeIcon}
                        alt="negative-icon"
                        align="left"
                      />
                      <Body3
                        className="m-l-5"
                        textColor={reporaColor.watermelon}
                      >
                        Negative Experience
                      </Body3>
                    </TooltipWrapper>
                  )}
                </span>
              )}

              <Body5 textColor={reporaColor.warmGrey}>{feedData.comment}</Body5>
            </DetailsWrapper>

            {this.state.tempData !== "" ? (
              <UnderDispute>Rating Under Dispute</UnderDispute>
            ) : (
              <>{this.renderLikeEditButton()}</>
            )}
            {this.props.isDisputeFormOpen &&
              this.renderDisputeForm(this.props.disputeType)}

            <ReactTooltip effect="solid" disable={constants.IS_MOBILE} />
            { (this.props.feedbackViewType === "company") && (
               <>
                  {this.handleshowtags()}
                  {finalString &&
                  <Replay1 onClick={(e)=>this.handleAddTag(true)}>
                  <Body6  textColor={reporaColor.warmGrey}><Tags>{finalString}</Tags></Body6>
                  </Replay1>}
              </>)
            }
          </Wrapper>

          {/* {!this.state.deleted && (
          // this.state.editRespone && */}
          <RenderFeedbackResponse
            {...this.props}
            {...this.state}
            latestEditFeedback={this.props.latestEditFeedback}
            handleEditReplay={this.handleEditReplay}
            handleShowTooltip={this.handleShowTooltip}
            renderTooltip={this.renderTooltip}
            handleUpdateResponse={this.handleUpdateResponse}
            handleSubmitResponse={this.handleSubmitResponse}
            handlereplayChange={this.handlereplayChange}
            onUpvoteRepClick={this.onUpvoteRepClick}
            handleReplay = {this.handleReplay}
          />
          {/* )} */}
        </MainWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.userData,
});

export default connect(mapStateToProps)(FeedbackCard);
