import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { reporaColor } from "../global/commonStyles";
import OutsideClickHandler from "react-outside-click-handler";
import Button from "../Buttons/Button";
import Loader from "../Loader/Loader";

const Wrapper = styled.div`
  position: relative;

  input {
    padding: 0 10px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px ${reporaColor.grey87};
    background-color: ${reporaColor.white};

    font-size: 14px;
    color: ${reporaColor.black};
    font-family: Nexa Bold;

    width: 100%;

    ::placeholder {
      color: ${reporaColor.warmGrey};
    }
  }
`;

const SuggestionsContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #E8E8E8;
  margin-top: 5px;

  position: absolute;
  z-index: 1;
  min-width: 100%;
`;

const SuggestionList = styled.ul`
  max-height: ${props => props.maxSuggestions ? `${props.maxSuggestions * 40}px` : "auto"};
  overflow: auto;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 10px;
  cursor: pointer;
  color: ${reporaColor.warmGrey};

  &:hover {
    color: black;
  }
`;

const AddInput = styled.div`
  padding: 10px;
  background: white;
`;

const SuggestionItem = styled.li`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;

  &:hover {
    background: ${reporaColor.grey91};
  }

  background: ${props => props.selected && reporaColor.grey91};
`;

class InputSuggestion extends React.Component {
  state = {
    inputValue: "",
    suggestions: [],
    showSuggestions: false,
    selectedElement: "",
    selectedIndex: 0
  }

  constructor(props) {
    super(props);
    this.suggestionRefs = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { selectedElement, minChars, source } = this.props;

    if (this.props.selectedElement !== prevProps.selectedElement) {
      let filteredSource = [];

      if (selectedElement !== "" && selectedElement.length >= minChars && source) {
        filteredSource = source.filter(suggestion => suggestion.toLowerCase().includes(selectedElement.toLowerCase()));
      }

      this.setState({
        inputValue: selectedElement,
        suggestions: filteredSource,
        selectedElement
      });
    }
  }

  handleInputChange = event => {
    const { minChars, source } = this.props;
    const value = event.target.value;
    let filteredSource = [];

    if (value.trim() !== "" && value.length >= minChars && source) {
      filteredSource = source.filter(suggestion => suggestion.toLowerCase().includes(value.toLowerCase()));
    }

    this.setState({
      inputValue: value,
      suggestions: filteredSource
    });
  }

  handleOutsideClick = () => {
    const { suggestions, selectedElement, inputValue } = this.state;

    if (suggestions) {
      this.handleCloseSuggestions();
    }

    if (selectedElement !== inputValue) {
      this.setState({
        inputValue: selectedElement
      });
    }
  }

  handleCloseSuggestions = () => {
    this.setState({
      showSuggestions: false
    });
  }

  handleShowSuggestions = () => {
    this.setState({
      showSuggestions: true,
      selectedIndex: 0
    });
  }

  handleClearInput = () => {
    this.setState({
      inputValue: "",
      suggestions: []
    });

    if (this.props.handleSelectElement) {
      this.props.handleSelectElement("");
    }
  }

  handleAddElement = () => {
    const { inputValue } = this.state;

    this.setState({
      showSuggestions: false
    });

    if (this.props.handleAddElement) {
      this.props.handleAddElement(inputValue);
    }
  }

  handleSelectElement = suggestion => {
    this.setState({
      showSuggestions: false
    });

    if (this.props.handleSelectElement) {
      this.props.handleSelectElement(suggestion);
    }
  }

  handleKeyDown = e => {
    const { suggestions, selectedIndex } = this.state;

    if (suggestions && suggestions.length > 0) {
      if (e.keyCode === 40) { // key down
        this.setState({
          selectedIndex: (selectedIndex + 1) >= suggestions.length ? suggestions.length - 1 : (selectedIndex + 1)
        });
      }

      if (e.keyCode === 38) { // key up
        this.setState({
          selectedIndex: (selectedIndex - 1) < 0 ? 0 : (selectedIndex - 1)
        });
      }

      if (e.keyCode === 13 && suggestions[selectedIndex]) { // enter key
        this.setState({
          inputValue: suggestions[selectedIndex]
        }, () => {
          this.handleAddElement();
          const inputNode = this.inputRef.current;
          inputNode.blur();
        });
      }
    }
  }

  render() {
    const { maxSuggestions } = this.props;

    const node = this.suggestionRefs.current;
    if (node && node.children) {
      const selectedEl = node.children[0].childNodes[this.state.selectedIndex];

      if (selectedEl) {
        node.children[0].scrollTop = selectedEl.offsetTop;
      }
    }

    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <Wrapper>
          <input
            autoComplete="new-company"
            onFocus={this.handleShowSuggestions}
            value={this.state.inputValue}
            placeholder={this.props.inputPlaceholder}
            onChange={this.handleInputChange}
            onKeyDown={this.handleKeyDown}
            ref={this.inputRef}
          />

          {this.props.loading && <Loader overlayed size="small" />}

          {this.state.inputValue.length > 0 &&
            <CloseButton onClick={this.handleClearInput}>
              <FontAwesomeIcon icon={faTimes} />
            </CloseButton>
          }

          {this.state.showSuggestions && this.state.inputValue.trim().length > 0 &&
            <SuggestionsContainer>
              {this.state.suggestions.length > 0 &&
                <div ref={this.suggestionRefs}>
                  <SuggestionList maxSuggestions={maxSuggestions}>
                    {this.state.suggestions.map((suggestion, index) =>

                      <SuggestionItem
                        selected={index === this.state.selectedIndex}
                        key={index}
                        onClick={() => this.handleSelectElement(suggestion)}>
                        {suggestion}
                      </SuggestionItem>
                    )}
                  </SuggestionList>
                </div>
              }

              <AddInput>
                <Button
                  className="m-t-5"
                  size="small"
                  onClick={this.handleAddElement}>Add</Button>
              </AddInput>
            </SuggestionsContainer>
          }

        </Wrapper>
      </OutsideClickHandler >
    );
  }
}

InputSuggestion.propTypes = {
  disabled: PropTypes.bool,
  inputPlaceholder: PropTypes.string,
  source: PropTypes.array.isRequired,
  maxSuggestions: PropTypes.number,
  minChars: PropTypes.number,
  addPlaceholder: PropTypes.string,
  loading: PropTypes.bool,
  handleAddElement: PropTypes.func,
  selectedElement: PropTypes.string
};

InputSuggestion.defaultProps = {
  maxSuggestions: 5,
  minChars: 2,
  inputPlaceholder: "",
  addPlaceholder: "Add Element",
  loading: false
}

export default InputSuggestion;