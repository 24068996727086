import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { sortBy } from "lodash";

import { reporaColor, ReporaButton, FormGroup } from "../../components/global/commonStyles";
import { reporaDevice } from "../global/commonStyles";

let Wrapper = styled.div`
position: relative;
max-width: 100%;
background-color: ${reporaColor.white};
width: ${props => props.width || "auto"};
@media ${reporaDevice.desktop} {
  ${props => {
    try {
      if (props.specificWidth) {
        return `width:${props.specificWidth};`;
      } else {
        return "width:365px;";
      }
    } catch (e) {
      console.log(e);
    }

  }}
}
`;



const DropdownControl = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  cursor:  ${props => props.disable ? "default" : "pointer"};
  color: ${props => props.disable ? reporaColor.warmGrey : reporaColor.black};
  border: solid 1px ${reporaColor.grey91};
  min-width: ${props => props.width || "180px"};
  width: auto;
  height: 36px;
  max-width: 100%;
  border-radius: 5px;
  font-size: 14px;

`;

const DropdownContainer = styled.div`
  position: absolute;
  top: 40px;
  ${props => props.left
    ? "left: 0;"
    : "right: 0;"
  }
  box-sizing: border-box;
  min-width: 100%;
  white-space: nowrap;
  margin: 0;
  padding: 10px 20px;
  list-style-type: none;
  background-color: ${reporaColor.white};
  border: solid 1px ${reporaColor.grey91};
  border-radius: 5px;
  z-index: 1;
  height: auto;
  max-width: calc(100vw - 30px);
`;

const DropdownOptions = styled.ul`
  overflow: auto;
  max-height: calc(45px * 5);
  width:100%;
  ${props => props.optionWidth
    ? ""
    : `width: ${props.optionWidth};`
  }
   li {
    height: 45px;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 14px;
    justify-content: center;

    span {
      font-size: 10px;
      align-items: flex-end;
      color: ${reporaColor.warmGrey};
    }
  }

  a {
    margin-top: 5px;
  }
`;

class DropdownAddOption extends React.Component {
  state = {
    active: false,
    newOptionValue: ""
  };

  showDropdown = () => {
    if (this.props.options.length >= 0) {
      this.setState({ active: true });
      document.addEventListener("click", this.hideDropdown);
    }
  };

  hideDropdown = (event) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!(domNode && domNode.contains(event.target) && (event.target.tagName === "INPUT" || event.target.tagName === "BUTTON"))) {
      this.setState({ active: false, newOptionValue: "" });
      document.removeEventListener("click", this.hideDropdown);
    }
  };

  handleSelection = (item) => {
    if (this.props.onClick) {
      this.props.onClick(item);
    }
  }
  handleManageValue = (item, index) => {
    if (this.props.myVal) {
      this.props.onClick(item, index);
    } else {
      this.handleSelection(item)
    }
  }

  handleSelectedCompany =  (item) =>{
    if (this.props.onClick) {
      this.props.onClick(item);
    }
  }

  handleNewOptionChange = (event) => {
    const { isCompanyAdd } = this.props;
    let newCompany = null;
    if (isCompanyAdd) {
      newCompany = {
        industry: "",
        physicalAddress: {},
        name: event.target.value
      };
    } else {
      newCompany = event.target.value;
    }

    this.setState({ newOptionValue: newCompany });
  }

  handleAddNewOption = () => {
    const { isCompanyAdd } = this.props;

    if (isCompanyAdd) {
      if (!this.props.options.find(c => c.name === this.state.newOptionValue.name)) {
        this.props.options.push(this.state.newOptionValue);
        this.handleSelection(this.state.newOptionValue.name);
      }
    } else {
      if (!this.props.options.find(c => c === this.state.newOptionValue)) {
        this.props.options.push(this.state.newOptionValue);
        this.handleSelection(this.state.newOptionValue);
      }
    }
  };

  render() {



    return (
      <Wrapper active={this.state.active} width={this.props.wrapperWidth} specificWidth={this.props.specificWidth} >
        <DropdownControl onClick={this.showDropdown} width={this.props.width}>
          <div>{this.props.value || this.props.placeholder}</div>
          <FontAwesomeIcon icon={faChevronDown} />
        </DropdownControl>
        {this.state.active &&
          <DropdownContainer left={this.props.left}>
            <DropdownOptions style={{ "width": this.props.optionWidth }}>
              {this.props.isCompanyAdd ?
                sortBy(this.props.options, "name").map((item, index) => {
                  let address = item.physicalAddress ? `${item.physicalAddress.city || ""} ${item.physicalAddress.state || ""}` : "";
                  address = address.trim();
                  return (
                    <li key={item.name + index} onClick={() => { 
                      if(this.props?.repCompanyChangeNew){
                        this.handleSelectedCompany(item)
                      }else{
                        this.handleSelection(item.name)
                      }}}>
                      {item.name}
                      <span>{`${item.industry || ""} ${address ? "- " + address : ""}`}</span>
                    </li>
                  );
                }) :
                this.props.options.map((item, index) => {
                  return (
                    <li key={item + index} onClick={() => { this.handleManageValue(item, index) }}>
                      {item}
                    </li>
                  );
                })
              }
            </DropdownOptions>
            {this.props.disableInpt ? "" : <FormGroup>
              <input onChange={this.handleNewOptionChange} placeholder={this.props.addPlaceHolder} />
              {this.state.newOptionValue !== "" &&
                <ReporaButton className="m-t-10" small onClick={this.handleAddNewOption}>Add</ReporaButton>
              }
            </FormGroup>}
          </DropdownContainer>
        }
      </Wrapper>
    );
  }
}

export default DropdownAddOption;
