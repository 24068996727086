import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { reporaSize } from "../global/commonStyles";

const Wrapper = styled.section`
  background: white;
  width: 100%;
  // Use below code to make the footer sticky in the screen
  // position: sticky;
  // bottom:0;
  // z-index:1;
  padding: 1% 15%;

  @media (max-width: ${reporaSize.medium}) {
		padding-left: 5%;
    padding-right: 5%;
	}
`;

const Content = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1% 0;

  &.footer-content {
    border-top: 1px solid gray;
  }

  @media (max-width: ${reporaSize.small}) {
		flex-direction: column;
	}

  a {
    color: black;
    font-size: 14px;
    font-weight: bold;
    margin: 0 20px;
    text-decoration: none;
    white-space: nowrap;
  }
`;

const LinksContainer = styled.div`
  width: 33%;

  @media (max-width: ${reporaSize.small}) {
		width: 100%;
    display: flex;
    justify-content: center;
    margin: 3% 0;
	}
`

const LogoContainer = styled.div`
  width: 34%;
  background-image: ${props => props.bkgImage ? `url(${props.bkgImage})` : "none"};
  background-repeat: no-repeat;
  background-size: contain;
  height: 30px;

  @media (max-width: ${reporaSize.small}) {
		width: 100%;
    background-position: center;
    margin: 3% 0;
	}
`

const FooterContent = styled.div`
  width: 100%;
  color: gray;
  font-size: 10px;

  @media (max-width: ${reporaSize.small}) {
		display: flex;
    flex-direction: row;
    justify-content: center;
	}

  span {
    margin: 0 5px;
  }
`

const Footer = React.memo(() => (
  <Wrapper>
    <Content>
      <LogoContainer bkgImage="/assets/global/logo.svg"></LogoContainer>
      <LinksContainer>
        <Link to="/privacy">Privacy and Terms</Link>
      </LinksContainer>
      <LinksContainer>
        <Link to="/contactus">Contact Us</Link>
      </LinksContainer>
    </Content>
    <Content className="footer-content">
      <FooterContent>
        © 2023<span> | </span>Repora, Inc<span> | </span>All rights reserved
      </FooterContent>
      </Content>
  </Wrapper>
));

export default Footer;
