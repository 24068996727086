import React, { createRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../../config";

import {
  Header3,
  Header5,
  reporaColor,
  FormRow,
  FormGroup,
  reporaDevice,
} from "../../../components/global/commonStyles";
import {
  Wrapper,
  CloseButton,
  BackButton,
  HeaderWrapper,
  FormWrapper,
  ButtonContainer,
} from "./CommonStyles";
import Button from "../../../components/Buttons/Button";
import Dropdown from "../../../components/Dropdown/Dropdown";

function validateEmail(email) {
  return !!email.match(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ); // eslint-disable-line no-useless-escape
}

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;

 
  label {
    width: 120px;
    flex: 0 0 120px;
    font-size: 12px;
    font-weight: bold;
    color: ${reporaColor.warmGrey};
    margin-right: 15px;
  }

  @media ${reporaDevice.xs} {
    flex-direction: column;

    label {
      flex: auto;
      align-self: flex-start;
    }

    >div {
      width: 100%;
    }
  }
`;

const Step3RepDetails = (props) => {
  const recaptchaRef = createRef();
  const { firstName, lastName, email, companyName, token } = props.step3Data;
  let options = props.functions;
  options[2] = "Other";
  let functionsSelected = true;
  if (props.invitedLink) {
    functionsSelected = !props.validSocialData || !email || !email.trim() || !firstName || !lastName ||
      !validateEmail(email) || !token;
  } else {
    functionsSelected = !props.validSocialData || !email || !email.trim() || !firstName || !lastName ||
      !props.functionSelected || !validateEmail(email) || !token;
  }
  let _cmp = companyName
  if (_cmp == null) {
    _cmp = "";
  }

  const onRecaptchaValidate = () => {
    if(recaptchaRef && recaptchaRef.current) {
      const recaptchaValue = recaptchaRef.current.getValue();
      props.handleRecaptchaChange(recaptchaValue);
    }
  }

  const reCaptchaSiteKey = config.RE_CAPTCHA_KEYS.siteKey || "";

  return (
    <Wrapper>
      <HeaderWrapper>
        <BackButton onClick={props.handleBack}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </BackButton>
        <CloseButton onClick={props.handleClose}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>

        <Header3 textAlign="center">Sign Up</Header3>
        <Header5 textAlign="center" textColor={`${reporaColor.brightSkyBlue}`}>
          Confirm your contact details, and complete any missing fields
        </Header5>
      </HeaderWrapper>
      <FormWrapper>
        <FormRow>
          <FormGroup>
            <label>First Name*</label>
            <input readOnly name="firstName" value={firstName || ""} />
          </FormGroup>
          <FormGroup>
            <label>Last Name*</label>
            <input readOnly name="lastName" value={lastName || ""} />
          </FormGroup>
        </FormRow>
        <FormGroup>
          <label>Company</label>
          <input
            required
            onChange={props.handleInputChange}
            name="companyName"
            value={_cmp || ""}
          />
        </FormGroup>
        {/* <FormGroup>
          <label>Work Email*</label>
          <input
            required
            onChange={props.handleInputChange}
            name="email"
            value={email || ""}
          />
        </FormGroup> */}
       {!props.invitedLink ? (
          <FormGroup>
            <label>Role* </label>
            <DropdownContainer>
              <Dropdown
                key="sort"
                required
                isCompanyAdd={false}
                options={options}
                value={props.functionSelected}
                onClick={props.handleFunctionsChange}
                placeholder="Select"
                width="100%"
                disable={true}
                customWidth="100%"
                wrapperWidth="100%"
              />
            </DropdownContainer>
          </FormGroup>
        ) : (
          ""
        )}


        <ButtonContainer style={{marginTop : "10px"}}>
          <ReCAPTCHA ref={recaptchaRef} sitekey={reCaptchaSiteKey} onChange={onRecaptchaValidate} />
        </ButtonContainer>
        <ButtonContainer className="m-b-45">
          <Button
            disabled={
              functionsSelected
            }
            onClick={props.handleStepSubmit}
            width="100%"
          >
            Create Account
          </Button>
        </ButtonContainer>
      </FormWrapper>
    </Wrapper >
  );
};

export default Step3RepDetails;
