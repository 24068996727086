import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import View from "./components/View";
import { logout } from "../../actions/authActions";
import { forgotPassword, forgotPasswordClear, resetPassword } from "../../actions/accountActions";


class Forgot extends React.Component {
  render() {
    return (
      <View {...this.props} />
    );
  }
}

const mapStateToProps = state => ({
  forgotPasswordError: state.account.forgotPasswordError,
  fetchingForgotPassword: state.account.fetchingForgotPassword,
  forgotPasswordSent: state.account.forgotPasswordSent,

  fetchingResetPassword: state.account.fetchingResetPassword,
  resetPasswordError: state.account.resetPasswordError,
  resetPasswordSent: state.account.resetPasswordSent
});

const mapDispatchToProps = dispatch => ({
  handleForgotPassword(email) {
    dispatch(forgotPassword(email));
  },
  handleForgotPasswordClear() {
    dispatch(forgotPasswordClear());
  },
  handleResetPassword(email, code, password) {
    dispatch(resetPassword(email, code, password));
  },
  handleLogout(history) {
    dispatch(logout(history));
  },
});

export const Unwrapped = Forgot;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Forgot));