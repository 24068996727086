import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { startsWith } from "lodash";

import thumbsUpSvg from "./assets/thumbs_up.svg";
import thumbsDownSvg from "./assets/thumbs_down.svg";
import { reporaColor } from "../../components/global/commonStyles";

const Wrapper = styled.div`
  position: relative;
  max-width: ${props => props.customMaxWidth || "100%"};
  height: ${props => props.height || "40px"};
  width: ${props => props.customWidth || "280px"};
  background-color: ${props => props.grayBg ? reporaColor.lightGrey : reporaColor.white};
  font-size: ${props => props.customFontSize || "14px"};
  outline: none;
`;

const DropdownControl = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  cursor:  ${props => props.disable ? "default" : "pointer"};
  color: ${props => (props.disable || !props.modifiedPlaceholder) ? reporaColor.warmGrey : reporaColor.black};
  border: solid 1px ${reporaColor.grey91};
  height: ${props => props.height || "40px"};
  max-width: ${props => props.customMaxWidth || "100%"};
  border-radius: 4px;

  ${props => props.focused && css`
    outline: rgba(11, 201, 238, 0.84) auto 5px;
  `}

  >div {
    white-space: nowrap;
    padding-right: 10px;
    overflow: hidden;
  }
`;

const DropdownOptions = styled.ul`
  position: absolute;
  top: 45px;
  ${props => props.left
    ? "left: 0;"
    : "right: 0;"
  }
  box-sizing: border-box;
  width: ${props => props.dropdownWidth || "auto"};
  margin: 0;
  list-style-type: none;
  background-color: ${reporaColor.grey91};
  border-top: none;
  z-index: 2;
  min-width: ${props => props.customWidth || "280px"};
  max-height: calc(36px * 6);
  max-width: calc(100vw - 30px);
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 4px;

  li {
    height: 36px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:hover {
      background: rgba(0,0,0,0.1);
    }
  }
`;


class Dropdown extends React.Component {
  state = {
    active: false,
    scrollIndex: 0,
    preventToggle: false,
    inputBuffer: [],
    lastKeyTime: Date.now()
  };

  showDropdown = () => {
    if (this.props.options && this.props.options.length > 0 && !this.props.disabled) {
      this.setState({
        active: true,
        preventToggle: true
      });
    }
  };

  hideDropdown = () => {
    this.setState({ active: false });
  };

  toggleDropdown = () => {
    if (this.state.preventToggle) {
      this.setState({
        preventToggle: false
      });
    } else {
      this.setState(state => ({
        active: !state.active
      }));
    }
  }

  handleSelection = (item) => {
    if (this.props.onClick) {
      this.props.onClick(item);
    }
    this.setState({ active: false });
  }

  handleKeyPress = event => {
    const { lastKeyTime, inputBuffer } = this.state;
    const { keystrokeDelay } = this.props;

    const key = event.key;
    const currentTime = Date.now();
    let buffer = [];

    if ((currentTime - lastKeyTime) > keystrokeDelay) {
      buffer = [key];
    } else {
      buffer = [...inputBuffer, key];
    }

    this.setState({
      inputBuffer: buffer,
      lastKeyTime: currentTime
    });

    const scrollIndex = this.props.options.findIndex(option =>
      startsWith(option && option.toLowerCase(), buffer.join(""))
    );

    if (scrollIndex >= 0) {
      this.refs[scrollIndex].scrollIntoView({ block: "start", behavior: "smooth" });
    }
  };

  handleTriggerClick = () => {
    if (!this.props.disabled && this.props.options && this.props.options.length > 0) {
      this.toggleDropdown();
    }
  }
  render() {
  
    let valueLabel = this.props.placeholder || "";

    if (this.props.value) {
      valueLabel = (typeof (this.props.value === "object") && this.props.value.label) || this.props.value;
    }

    return (
      <Wrapper
        className={this.props.className}
        grayBg={this.props.grayBg}
        customWidth={this.props.customWidth}
        customMaxWidth={this.props.customMaxWidth}
        height={this.props.height}
        customFontSize={this.props.customFontSize}
        active={this.state.active}
        tabIndex={!this.props.options || !this.props.options.length || this.props.disabled ? "" : "0"}
        onKeyPress={this.handleKeyPress}
        onFocus={this.showDropdown}
        onBlur={this.hideDropdown}
        id={this.props.id}
      >
        <DropdownControl
          id={this.props.id}
          focused={this.state.active}
          modifiedPlaceholder={valueLabel !== this.props.placeholder}
          disable={!this.props.options || !this.props.options.length || this.props.disabled}
          onClick={this.handleTriggerClick}
          customWidth={this.props.customWidth}
          customMaxWidth={this.props.customMaxWidth}
          height={this.props.height}>
          <div className="d-flex align-items-center">
            {valueLabel === "Thumbs Up" && <img className="m-r-5" src={thumbsUpSvg} alt="thumbs-up" />}
            {valueLabel === "Thumbs Down" && <img className="m-r-5" src={thumbsDownSvg} alt="thumbs-down" />}
            <span>{valueLabel}</span>
          </div>
          <FontAwesomeIcon icon={this.state.active ? faChevronUp : faChevronDown} />
        </DropdownControl>

        {this.state.active && (this.props.options && this.props.options.length > 0)
          ? <DropdownOptions id={this.props.id} ref={this.dropdownList} onClick={this.onOutsideClick} left={this.props.left} dropdownWidth={this.props.dropdownWidth} customWidth={this.props.customWidth}>
            {this.props.options.map((item, index) => {
              if (item) {
                const label = item.label || item;
                return (
                  <li ref={index} key={label + index} onClick={() => { this.handleSelection(item) }}>
                    {label === "Thumbs Up" && <img className="m-r-5" src={thumbsUpSvg} alt="thumbs-up" />}
                    {label === "Thumbs Down" && <img className="m-r-5" src={thumbsDownSvg} alt="thumbs-down" />}
                    <span>{label}</span>
                  </li>
                );
              }
              return null;
            })}
          </DropdownOptions>
          : null
        }
      </Wrapper>
    );
  }
}

Dropdown.propTypes = {
  keystrokeDelay: PropTypes.number
};

Dropdown.defaultProps = {
  keystrokeDelay: 1000
}

export default Dropdown;
