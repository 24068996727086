import authdAxios from "../modules/Authentication/utils/authdAxios";
import config from "../config";
import constants from "../constants";
import { get } from "lodash";
import authService from "./AuthService";
import axios from "axios";

const ReportingService = () => {
  const getAttributesList = (payload) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/reps/get-rating-attributes`,
          payload,
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  };

  const getRatingAttr = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${config.CUSTOMER_API}/api/link/get-rating-attributes `,
          payload,
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  };

  const getProgramsList = (payload) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/company-feedback/get-program-list`,
          { companyId: payload },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  };
  const addProgram = (payload) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/company-feedback/add-program`,
          { ...payload },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  };
  const getProgram = (payload) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/company-feedback/get-program`,
          { id: payload },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  };

  const updateProgram = (payload) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/company-feedback/update-program`,
          { ...payload },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  };
  const deleteProgram = (payload) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/company-feedback/remove-program`,
          { id: payload },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  };

  const getTagList = (payload) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/company-feedback/get-tag-list`,
          { companyId: payload },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  };

  const addTag = (payload) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/company-feedback/add-tag`,
          { ...payload },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  };

  const deleteTag = (payload) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/company-feedback/remove-tag`,
          { id: payload },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  };

  const updateTag = (payload) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/company-feedback/update-tag`,
          { ...payload },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  };

  const getTeamsList = (payload) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/company-feedback/get-team-list`,
          { companyId: payload },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  };

  const AddTeam = (payload) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/company-feedback/add-team`,
          { ...payload },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  };

  const getTeam = (payload) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/company-feedback/get-team`,
          { id: payload },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  };

  const deleteTeam = (payload) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/company-feedback/remove-team`,
          { id: payload },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  };

  const updateTeam = (payload) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/company-feedback/update-team`,
          { ...payload },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  };

  const addTeam = (payload) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/company-feedback/add-team`,
          { ...payload },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  };

  return {
    getAttributesList,
    getRatingAttr,
    getProgramsList,
    addProgram,
    getProgram,
    getTeam,
    updateProgram,
    deleteProgram,
    getTagList,
    addTag,
    deleteTag,
    updateTag,
    getTeamsList,
    updateTeam,
    AddTeam,
    deleteTeam,
    addTeam,
  };
};

export default ReportingService();
