import React from "react";
import styled from "styled-components";
import Loader from "../../../components/Loader/Loader";
import { Header3, reporaColor, Body4 } from "../../../components/global/commonStyles";
import { Wrapper, HeaderWrapper, HeaderText, FormWrapper, Button } from "./CommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faTimes } from "@fortawesome/free-solid-svg-icons";

const ErrorMessage = styled.h3`
  color: ${reporaColor.watermelon};
  margin-top: -40px;
  margin-bottom: 40px;
  max-width: 380px;
  text-align: center;
`;

export const CloseButton = styled.span`
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 20px;
  cursor: pointer;
  color: ${reporaColor.warmGrey};
`;

class Step1 extends React.Component {

  handleButtonClick = (value) => {
    this.props.handleChange(value);
  }

  componentDidMount() {
    this.props.clearErrorMessages();
    document.getElementById("contentLoader").scrollIntoView();
    window.scrollTo(0, 0);
  }

  clearUserExpired(){
    setTimeout(() => window.localStorage.removeItem("user-expired"), 2000)
  }

  render() {
    return (
      <>
      <Wrapper>
        {this.props.isFetching && <Loader overlayed size="medium" />}
        {window.localStorage.getItem("user-expired") && <Body4 style = {{color : reporaColor.watermelon , marginTop:"-30px"}}>Your session has expired. Please login again.</Body4>}
        {window.localStorage.getItem("user-expired") && this.clearUserExpired()}
        <HeaderWrapper>
          <CloseButton onClick={this.props.handleBack}><FontAwesomeIcon icon={faTimes} /></CloseButton>
          <Header3 textAlign="center">Log In</Header3>
          <HeaderText styles={{ marginBottom: "44px" }} textAlign="center" textColor={`${reporaColor.brightSkyBlue}`}>Select your membership type</HeaderText>
        </HeaderWrapper>
        <FormWrapper>
          <Button onClick={() => this.handleButtonClick("CUSTOMER")}>
            <span>Customer</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>

          <Button onClick={() => this.handleButtonClick("REP")}>
            <span>Rep</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>

          <Button onClick={() => this.handleButtonClick("ADMIN")}>
            <span>Company</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </FormWrapper>

        {this.props.loginErrorMessage && <ErrorMessage>Error: {this.props.loginErrorMessage}</ErrorMessage>}
      </Wrapper>
      </>
    );
  }
};

export default Step1;
