import moment from "moment";
import { get } from "lodash";

export function formatPhoneNumber(number) {
  if (number) {
    const nn = number.replace("+", "");
    const p1 = nn.substring(0, 3);
    const p2 = nn.substring(3, 6);
    const p3 = nn.substring(6, nn.lenght);

    const part1 = p1 ? `${p1}` : "";
    const part2 = p2 ? `-${p2}` : "";
    const part3 = p3 ? `-${p3}` : "";

    return part1.concat(part2, part3);
  }

  return null;
}

export function sortingObjectList(data, sortingValue) {
  data.sort(function (a, b) {
    if (sortingValue === "name") {
      if (a[sortingValue] > b[sortingValue]) return 1;
      if (a[sortingValue] < b[sortingValue]) return -1;
      return 0;
    } else {
      return b[sortingValue] - a[sortingValue];
    }
  });
}

export function filterObjectList(data, filterValue, filterProp) {
  const filtered = data.filter((item) => {
    const value = get(item, filterProp);
    if (value) {
      return value.toLowerCase().includes(filterValue.toLowerCase());
    }
    return true;
  });

  return filtered;
}

export function validatePhone(phone) {
  let phoneno = /^\d{10}$/;
  if (phone.match(phoneno)) {
    return true;
  } else {
    return false;
  }
}

export function validateEmail(email) {
  if (!email) {
    return false;
  }
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
  return re.test(email);
}

export function getJsonFromUrl(query) {
  query = query.substr(1);
  var result = {};
  query.split("&").forEach(function (part) {
    if (!part) return;
    part = part.split("+").join(" "); // replace every + with space, regexp-free version
    var eq = part.indexOf("=");
    var key = eq > -1 ? part.substr(0, eq) : part;
    var val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : "";
    var from = key.indexOf("[");
    if (from === -1) result[decodeURIComponent(key)] = val;
    else {
      var to = key.indexOf("]", from);
      var index = decodeURIComponent(key.substring(from + 1, to));
      key = decodeURIComponent(key.substring(0, from));
      if (!result[key]) result[key] = [];
      if (!index) result[key].push(val);
      else result[key][index] = val;
    }
  });
  return result;
}

export function parseDate(text) {
  if (text) {
    const date = moment(text);
    if (date.isValid()) {
      return date.toDate();
    }
  }
  return null;
}

export function formatDate(date, defaultErrorFormat = "Invalid Date") {
  if (date) {
    return date.toLocaleDateString();
  }

  return defaultErrorFormat;
}

export function copyToClipboard(el, link) {
  // debugger;
  console.log("el->>>", el.innerText);
  if (link) {
    let linkLength = link.length;
    let index = el.value.indexOf(link);
    return el.setSelectionRange(index, index + linkLength, "forward");
  }

  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    // iOS
    const oldContentEditable = el.contentEditable;
    const oldReadOnly = el.readOnly;
    const range = document.createRange();

    el.contentEditable = true;
    el.readOnly = false;
    range.selectNodeContents(el);

    const s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);

    el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;

    document.execCommand("copy");
  } else {
    const oldDisabled = el.disabled;
    el.disabled = false;
    el.select();
    document.execCommand("copy");
    el.disabled = oldDisabled;
  }
}

export default {
  formatPhoneNumber,
  sortingObjectList,
  filterObjectList,
  validatePhone,
  validateEmail,
  getJsonFromUrl,
  parseDate,
  formatDate,
  copyToClipboard,
};
