import moment from "moment";
import React from "react";
import EditForm from "./EditForm";
import { Row } from "./ListItem";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const ClaimsList = (props) => {
    const [isOpen , setOpen] = useState(false);
    const {ele , i} = props;
    return (
        <>
            <div  key= {Math.random().toString()}>
            <Row>
            <div>{i.claimDate && moment(i.claimDate).format("MM-DD-YYYY")}</div>
            <div>{ele.claimedRepId}</div>
            <div>{ele.claimedRepName}</div>
            <div>
              <a
                style={{
                  textAlign: "center",
                  textDecoration: "underline",
                  color: "#0bc9f6",
                  fontWeight: "500",
                }}
                target="_blank"
                href={`/viewrepcustomer?id=${i.claimingRepId}`}
              >
              {i.claimingRepName}
              </a>
            </div>
            <span onClick={()=>setOpen(!isOpen)}>
              <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
            </span>
          </Row>
          {isOpen && (
            <EditForm
              {...props}
              labelComment = "Claim Comments"
              userComment = {i.claimReason}
              claimingRepId = {i.claimingRepId}
              handleUpdateClaimStatus={props.handleUpdateClaimStatus}
              fromClaims
            />
          )}
          </div>
        </>
    )

}

export default ClaimsList;