import authdAxios from "../modules/Authentication/utils/authdAxios";
import { get } from "lodash";
import config from "../config";
import constants from "../constants";

const ManageRepsService = () => {
  function getAssignedRoles(companyId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/manage-reps/assigned-roles`,
          {
            companyId
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function getReps(companyId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/manage-reps/get-all`,
          {
            companyId: companyId
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data.payload);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }


  function getRepsRole(companyId, role) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/manage-reps/get-all`,
          {
            companyId: companyId, repType: role
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data.payload);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function addRep(companyId, rep) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/manage-reps/invite`,
          rep,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function disconnectRep(repId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/manage-reps/disconnect`,
          {
            id: repId
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function deleteRep(repId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/manage-reps/delete`,
          {
            id: repId
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function resendInvite(repId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/manage-reps/re-invite`,
          {
            id: repId
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function editRep(rep) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/manage-reps/update`,
          rep,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function existRep(email) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/manage-reps/exist-email`,
          {
            email: email
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data.payload);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }


  function getFeedbackDispute(data) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.ADMIN_API}/api/admin/get-feedback-dispute-list`,
          // { "disputeStatus": "IN_DISPUTE" },
          data,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data.payload);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }



  function updateFeedbackDispute(data) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.ADMIN_API}/api/admin/update-feedback-dispute`,
          data,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data.payload);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  return {
    getAssignedRoles,
    getReps,
    getRepsRole,
    addRep,
    disconnectRep,
    deleteRep,
    resendInvite,
    editRep,
    existRep,
    getFeedbackDispute,
    updateFeedbackDispute
  };
}

export default ManageRepsService();