import React from "react";
import { Redirect } from "react-router";
import styled from "styled-components";
import NoCompanyLogoPng from "../../../../assets/global/noCompanyLogo.png";
import RatingStars from "../../../../components/RatingStars/RatingStars";
import {
  Body3,
  Body5,
  Body6,
  reporaColor,
  reporaDevice
} from "../../../../components/global/commonStyles";
import { formatDate, parseDate } from "../../../../components/global/utils";
import "../assets/dispute.css";
import NegativeIcon from "../assets/thumb_down.svg";
import PositiveIcon from "../assets/thumb_up.svg";
import ListItem from "./ListItem";

export const Wrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 20px auto 20px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 #00000033;
`;
export const Row = styled.div`
  background: white;
  display: flex;
  height: 60px;
  position: relative;
  border-bottom: 1px solid ${reporaColor.grey91};
  > div {
    flex: 1;
    font-size: 15px;
    padding-left: 0px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    background: ${reporaColor.grey98};
    user-select: none;
    &:last-child {
      border-right: none;
    }
    &:first-child {
      padding-left: 6px;
    }
  }

  &:last-child {
    border-bottom: none;
  }
  &:nth-child(even) {
  }
`;
const TooltipWrapper = styled.span`
  display: inline-flex;
`;
const ImageWrapper = styled.div`
  flex: 0 0 160px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 35px;

  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50px;
  }

  @media ${reporaDevice.xs} {
    margin: 0;
  }
`;

const LikeIconContainer = styled.div`
  color: ${reporaColor.greenBlue};
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
  }

  @media ${reporaDevice.xs} {
    margin-left: 0;
  }
`;

export default class View extends React.Component {
  state = {
    redirectLink: "",
    modalVals: { arr: [], feedbackResp: {} },
  };

  componentDidMount() {
    var modal = document.getElementById("myModal");
    var span = document.getElementsByClassName("close")[0];
    span.onclick = function () {
      modal.style.display = "none";
    };
    window.onclick = function (event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    };
  }

  redirect = () => {
    this.setState({
      redirectLink: "/viewrepcustomer?id=20019156-8548-4cd7-882f-bb8fc77b862e",
    });
  };
  modal = () => {
    return (
      <div id="myModal" className="modal">
        <div className="modal-content">
          <span className="close">&times;</span>
          <div id="content" style={{ display: "flex", width: "100%" }}>
            <div style={{ width: "35%", paddingLeft: "20px" }}>
              <ImageWrapper>
                <img src={NoCompanyLogoPng} alt="logo" />
              </ImageWrapper>
              <Body3 textColor={reporaColor.black}>
                {this.state.modalVals.feedbackResp.repName}
              </Body3>
              <Body6 textColor={reporaColor.black}>
                {this.state.modalVals.feedbackResp.companyName}
              </Body6>

              <LikeIconContainer>
                <img src={PositiveIcon} alt="like" />
                <p>{`${Math.round(
                  this.state.modalVals.feedbackResp.upVotes
                )}`}</p>
              </LikeIconContainer>
            </div>
            <div style={{ width: "50%" }}>
              <div style={{ display: "flex", padding: "0px 0px 2px 0px" }}>
                <RatingStars
                  static
                  rating={
                    this.state.modalVals.feedbackResp.average
                      ? Math.round(this.state.modalVals.feedbackResp.average)
                      : 0
                  }
                />
                <Body5 textColor={reporaColor.warmGrey}>
                  {"Overall Rating"}
                </Body5>
              </div>
              {this.state.modalVals.arr.map((element) => (
                <div style={{ display: "flex", padding: "0px 0px 2px 0px" }}>
                  <RatingStars static rating={element.value} />
                  <Body5 textColor={reporaColor.warmGrey}>{element.type}</Body5>
                </div>
              ))}

              <TooltipWrapper data-effect="solid" data-multiline="true">
                <img
                  src={
                    this.state.modalVals.feedbackResp.experience
                      ? PositiveIcon
                      : NegativeIcon
                  }
                  alt="positive-icon"
                  align="left"
                />
                <Body3
                  className="m-l-5"
                  textColor={
                    this.state.modalVals.feedbackResp.experience
                      ? reporaColor.greenBlue
                      : reporaColor.warning
                  }
                >
                  Positive Experience
                </Body3>
              </TooltipWrapper>
              <div style={{ display: "flex" }}>
                <Body5 textColor={reporaColor.warmGrey}>
                  {this.state.modalVals.feedbackResp.comment}
                </Body5>
              </div>
            </div>
            <div style={{ width: "15%" }}>
              <Body5 textColor={reporaColor.warmGrey}>
                {formatDate(
                  parseDate(this.state.modalVals.feedbackResp.createdDate)
                )}
              </Body5>
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleModalVal = (attributes) => {
    this.setState({ modalVals: attributes });
  };
  render() {
    if (this.state.redirectLink !== "") {
      return <Redirect push={true} to={this.state.redirectLink} />;
    }

    return (
      <Wrapper>
        {this.modal()}
        <Row>
          {this.props?.headers.map((i, index) => {
            return <div key={index}>{i}</div>;
          })}
          <span />
        </Row>
        {this.props.fromDispute &&
          this.props.feedbackDisputeSucceeded &&
          this.props.feedbackDisputeSucceeded.map((ele, index) => {
            return (
              <ListItem
                updateFeedbackDisputeRequested={
                  this.props.updateFeedbackDisputeRequested
                }
                ele={ele}
                key={index}
                reviewerId={this.props.reviewerId}
                redirect={this.redirect}
                handleModalVal={this.handleModalVal}
                handleUpdateFeedbackDispute={
                  this.props.handleUpdateFeedbackDispute
                }
                fromDispute
              />
            );
          })}
        {this.props.fromClaims &&
          this.props.claimsList &&
          this.props.claimsList.map((ele, index) => {
            return (
              <ListItem
                ele={ele}
                key={index}
                handleUpdateClaimStatus={this.props.handleUpdateClaimStatus}
                updateClaimStatusReq={this.props.updateClaimStatusReq}
                reviewerId={this.props.reviewerId}
                claimStatus={this.props.claimStatus}
                fromClaims
              />
            );
          })}
        {this.props.fromManagePKeys && this.props.partnerKeys && (
          <>
            <ListItem
              ele={this.props.partnerKeys}
              handleRemovePartnerKey={this.props.handleRemovePartnerKey}
              handleUpdatePartnerKey={this.props.handleUpdatePartnerKey}
              fromManagePKeys
            />
          </>
        )}
        {this.props.fromManageCKeys && this.props.companyKeys && (
          <>
            <ListItem
              ele={this.props.companyKeysList}
              handleRemoveCompanyKey={this.props.handleRemoveCompanyKey}
              handleUpdateCompanyKey={this.props.handleUpdateCompanyKey}
              fromManageCKeys
            />
          </>
        )}
      </Wrapper>
    );
  }
}
