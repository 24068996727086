import React from "react";
import styled from "styled-components";
import { XIcon, reporaColor } from "../global/commonStyles";
import { DialogTitle, Dialog, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

/** Creating Styles for the List Wrapper */
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: ${(props) => (props.visible ? "block" : "none")};
  z-index: 10;
`;

const Content = styled.section`
  position: fixed;
  background: white;
  width: 100%;
  max-width: 750px;
  height: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-height: 100vh;
  overflow: auto;
`;

// const Close = styled.a`
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   cursor: pointer;
// `;

const SytledDialog = styled(Dialog)`
  .MuiDialog-paper {
    height: ${(props) => props.dialogHeight || "auto"};
    width: ${(props) => props.dialogWidth || "auto"};
  }
`;

export const Model = (props) => {
  return (
    <SytledDialog
      onClose={props.handleClose}
      open={props.open}
      fullWidth
      maxWidth
      dialogHeight={props.dialogHeight}
      dialogWidth={props.dialogWidth}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
        }}
      >
        <DialogTitle style={{ margin: "auto", width: "90%" }}>
          <strong>{props.title}</strong>
        </DialogTitle>
        <IconButton onClick={props.handleClose}>
          <Close />
        </IconButton>
      </div>

      {props.modelContent}
    </SytledDialog>
  );
};

export default Model;
