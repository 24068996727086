import accountService from "../services/AccountService";
import authService from "../services/AuthService";
import config from "../config";

import { UPDATE_USER_INFO_REQUESTED } from "./authActions";

/* Action Constants */
export const RESET_REQUEST = "RESET_REQUEST";

export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";

export const SOCIAL_URL_REQUEST = "SOCIAL_URL_REQUEST";
export const SOCIAL_URL_SUCCESS = "SOCIAL_URL_SUCCESS";
export const SOCIAL_URL_FAILURE = "SOCIAL_URL_FAILURE";
export const SOCIAL_URL_ERROR_RESET = "SOCIAL_URL_ERROR_RESET";

export const SOCIAL_DATA_REQUEST = "SOCIAL_DATA_REQUEST";
export const SOCIAL_DATA_SUCCESS = "SOCIAL_DATA_SUCCESS";
export const SOCIAL_DATA_FAILURE = "SOCIAL_DATA_FAILURE";

export const ADD_COMPANY_REQUEST = "ADD_COMPANY_REQUEST";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAILURE = "ADD_COMPANY_FAILURE";

export const FORGOT_PASSWORD_REQUESTED = "FORGOT_PASSWORD_REQUESTED";
export const FORGOT_PASSWORD_SUCCEEDED = "FORGOT_PASSWORD_SUCCEEDED";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";
export const FORGOT_PASSWORD_CLEAR_REQUESTED = "FORGOT_PASSWORD_CLEAR_REQUESTED";

export const RESET_PASSWORD_REQUESTED = "RESET_PASSWORD_REQUESTED";
export const RESET_PASSWORD_SUCCEEDED = "RESET_PASSWORD_SUCCEEDED";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const VERIFY_ACCOUNT_REQUESTED = "VERIFY_ACCOUNT_REQUESTED";
export const VERIFY_ACCOUNT_SUCCEEDED = "VERIFY_ACCOUNT_SUCCEEDED";
export const VERIFY_ACCOUNT_FAILED = "VERIFY_ACCOUNT_FAILED";
export const VERIFY_ACCOUNT_ERROR_RESET = "VERIFY_ACCOUNT_ERROR_RESET";

export const UPDATE_FROM_LINKEDIN_REQUESTED = "UPDATE_FROM_LINKEDIN_REQUESTED";
export const UPDATE_FROM_LINKEDIN_SUCCEEDED = "UPDATE_FROM_LINKEDIN_SUCCEEDED";
export const UPDATE_FROM_LINKEDIN_FAILED = "UPDATE_FROM_LINKEDIN_FAILED";

export const SIGN_UP_COMPANY_REQUESTED = "SIGN_UP_COMPANY_REQUESTED";
export const SIGN_UP_COMPANY_SUCCEEDED = "SIGN_UP_COMPANY_SUCCEEDED";
export const SIGN_UP_COMPANY_FAILED = "SIGN_UP_COMPANY_FAILED";

const resetRequest = () => ({ type: RESET_REQUEST, payload: null });

const requestSignUp = () => ({ type: SIGN_UP_REQUEST, payload: null });
const receiveSignUp = (data) => ({ type: SIGN_UP_SUCCESS, payload: data });
const failedSignUp = (data) => ({ type: SIGN_UP_FAILURE, payload: data });

const requestSocialUrl = () => ({ type: SOCIAL_URL_REQUEST, payload: null });
const failedSocialUrl = (data) => ({ type: SOCIAL_URL_SUCCESS, payload: data });

const requestSocialData = () => ({ type: SOCIAL_DATA_REQUEST, payload: null });
const receiveSocialData = (data) => ({ type: SOCIAL_DATA_SUCCESS, payload: data });
const failedSocialData = (data) => ({ type: SOCIAL_DATA_FAILURE, payload: data });

const addCompanyRequested = () => ({ type: ADD_COMPANY_REQUEST, payload: null });
const addCompanySucceeded = data => ({ type: ADD_COMPANY_SUCCESS, payload: data });
const addCompanyFailed = error => ({ type: ADD_COMPANY_FAILURE, payload: error });

const forgotPasswordRequested = () => ({ type: FORGOT_PASSWORD_REQUESTED, payload: null });
const forgotPasswordSucceeded = data => ({ type: FORGOT_PASSWORD_SUCCEEDED, payload: data });
const forgotPasswordFailed = data => ({ type: FORGOT_PASSWORD_FAILED, payload: data });
const forgotPasswordClearRequested = () => ({ type: FORGOT_PASSWORD_CLEAR_REQUESTED, payload: null });

const resetPasswordRequested = () => ({ type: RESET_PASSWORD_REQUESTED, payload: null });
const resetPasswordSucceeded = data => ({ type: RESET_PASSWORD_SUCCEEDED, payload: data });
const resetPasswordFailed = data => ({ type: RESET_PASSWORD_FAILED, payload: data });

const verifyAccountRequested = () => ({ type: VERIFY_ACCOUNT_REQUESTED, payload: null });
const verifyAccountSucceeded = data => ({ type: VERIFY_ACCOUNT_SUCCEEDED, payload: data });
const verifyAccountFailed = data => ({ type: VERIFY_ACCOUNT_FAILED, payload: data });
const verifyAccountErrorReset = () => ({ type: VERIFY_ACCOUNT_ERROR_RESET, payload: null });

const updateFromLinkedinRequested = () => ({ type: UPDATE_FROM_LINKEDIN_REQUESTED, payload: null });
const updateFromLinkedinSucceeded = data => ({ type: UPDATE_FROM_LINKEDIN_SUCCEEDED, payload: data });
const updateFromLinkedinFailed = data => ({ type: UPDATE_FROM_LINKEDIN_FAILED, payload: data });

const signUpCompanyRequested = () => ({ type: SIGN_UP_COMPANY_REQUESTED, payload: null });
const signUpCompanySucceeded = data => ({ type: SIGN_UP_COMPANY_SUCCEEDED, payload: data });
const signUpCompanyFailed = data => ({ type: SIGN_UP_COMPANY_FAILED, payload: data });

/* Async Actions */
export function signUpUser(token, email, firstName, lastName, password, companyName, companyId, country, phoneNumber, zipCode, state, city, jobTitle, industry, userRole, invitationCompanyId) {
  return dispatch => {
    dispatch(requestSignUp());

    accountService.validateRecaptcha(token)
      .then(response => {
        if(response.success) {
          //$FlowFixMe
          accountService.signUp(email, firstName, lastName, password, companyName, companyId, country, phoneNumber, zipCode, state, city, jobTitle, industry, userRole, invitationCompanyId)
            .then(response => {
              dispatch(receiveSignUp(response));
            })
            .catch(error => {
              dispatch(failedSignUp(error));
            });
          } else {
            dispatch(failedSignUp("reCAPTCHA Validation Failed"));
          }
      })
      .catch(error => {
        dispatch(failedSignUp("reCAPTCHA Validation Failed"));
      });
  };
}

/* Async Actions */
export function addCompany(companyName, enrolled, industry, country, city, state, zipCode, phoneNumber, linkedinUrl, minimumRatings, territory) {
  return dispatch => {
    dispatch(addCompanyRequested());

    accountService.addCompany(companyName, enrolled, industry, country, city, state, zipCode, phoneNumber, linkedinUrl, minimumRatings, territory)
      .then(response => {
        dispatch(addCompanySucceeded(response));
      })
      .catch(error => {
        dispatch(addCompanyFailed(error));
      });
  };
}

/* Async Actions */
export function socialSignUp(socialData) {
  return dispatch => {
    dispatch(requestSignUp());

    accountService.validateRecaptcha(socialData.token)
      .then(response => {
        if(response.success) {
          accountService.socialSignUp(socialData)
            .then(response => {
              dispatch(receiveSignUp(response));
            })
            .catch(error => {
              dispatch(failedSignUp(error));
            });
        } else {
          dispatch(failedSignUp("reCAPTCHA Validation Failed"));
        }
      })
      .catch(error => {
        dispatch(failedSignUp("reCAPTCHA Validation Failed"));
      });
  };
}

export function getSocialData(socialCode, providerName, userRole) {
  return dispatch => {
    dispatch(requestSocialData());
    //$FlowFixMe
    const callback = `${config.LOCAL_ROOT_URL}/signup`;
    accountService.getSocialData(socialCode, providerName, userRole, callback)
      .then(res => {
        dispatch(receiveSocialData(res));
      })
      .catch(err => {
        dispatch(failedSocialData(err));
      });

  };
}

export function getSocialUrl(providerName, userRole, invitationCompanyId,invited) {
  return dispatch => {
    dispatch(requestSocialUrl());
    //$FlowFixMe
     const callbackUrl = `${config.LOCAL_ROOT_URL}/signup`;
     
    accountService.getSocialUrl(providerName, userRole, callbackUrl)
      .then(response => {
        const responseUrl = (response.payload && response.payload.url) || null;
        if (responseUrl) {
          let redirectSocialUrl = responseUrl;
          if (invitationCompanyId) {
            if (invited) {
              redirectSocialUrl=redirectSocialUrl.replace("&state=",`&state=companyUrl-${invitationCompanyId}_Invited-YES_`)
            }else{
              redirectSocialUrl = responseUrl.replace("&state=", `&state=companyUrl-${invitationCompanyId}_`);
            }
          }
           
          window.location = redirectSocialUrl;
        }
      })
      .catch(error => {
        dispatch(failedSocialUrl(error));
      });
  };
}

export function resetAccountReducer() {
  return dispatch => {
    dispatch(resetRequest());
  };
}

export function forgotPassword(email) {
  return dispatch => {
    dispatch(forgotPasswordRequested());

    accountService.forgotPassword(email)
      .then(res => {
        dispatch(forgotPasswordSucceeded(res));
      })
      .catch(err => {
        dispatch(forgotPasswordFailed(err));
      });
  };
}

export function forgotPasswordClear() {
  return dispatch => {
    dispatch(forgotPasswordClearRequested());
  };
}

export function resetPassword(email, code, password) {
  return dispatch => {
    dispatch(resetPasswordRequested());

    accountService.resetPassword(email, code, password)
      .then(response => {
        dispatch(resetPasswordSucceeded(response));
      })
      .catch(error => {
        dispatch(resetPasswordFailed(error));
      });
  };
}

export function verifyAccount(userId, code, role, provider, callback, accessToken, password) {
  return dispatch => {
    dispatch(verifyAccountRequested());

    accountService.verifyAccount(userId, code, role, provider, callback, accessToken, password)
      .then(response => {
        authService.updateUserInfo(response.payload);
        dispatch({ type: UPDATE_USER_INFO_REQUESTED });
        dispatch(verifyAccountSucceeded(response));
      })
      .catch(error => {
        dispatch(verifyAccountFailed(error));
      });
  };
}

export function verifyAccountErrorClean() {
  return dispatch => {
    dispatch(verifyAccountErrorReset());
  }
}

export function updateFromLinkedin(code, role, provider, callback) {
  return dispatch => {
    dispatch(updateFromLinkedinRequested());

    accountService.updateFromLinkedin(code, role, provider, callback)
      .then(response => {
        authService.updateUserInfo(response.payload);
        dispatch({ type: UPDATE_USER_INFO_REQUESTED });
        dispatch(updateFromLinkedinSucceeded(response));
      })
      .catch(error => {
        dispatch(updateFromLinkedinFailed(error));
      });
  };
}

export function signUpCompany(companyData) {
  return dispatch => {
    dispatch(signUpCompanyRequested());

    accountService.signUpCompany(companyData)
      .then(response => {
        dispatch(signUpCompanySucceeded(response));
      })
      .catch(error => {
        dispatch(signUpCompanyFailed(error));
      });
  }
}