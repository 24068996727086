import React from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { Body4, reporaSize } from "../../../components/global/commonStyles";

const Wrapper = styled.div`
    display: block;
    width: 95%;
    margin: 15px;
`;

const LogoContainer = styled.div`
  width: 100px;
  margin-right: 2%;
  background-image: ${props => props.bkgImage ? `url(${props.bkgImage})` : "none"};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 30px;

  @media (max-width: ${reporaSize.small}) {
    width: 100%;
    height: 40px;
  }

  a {
    display: block;
    height: 30px;
  }
`;

function UnVerified (props) {

    return(
        <Wrapper>
            <LogoContainer bkgImage="/assets/global/logo.svg">
                <NavLink to="/"  />
            </LogoContainer><br></br>
            <Body4>
            Customer feedback is powered by Repora. Hear directly from your customers what they think about your CSM’s, Programs or Teams. Learn how you are doing on on a set of well-researched attributes that make CSM’s successful or your own custom attributes, and benchmark yourself against the industry.
            </Body4><br></br>
            <Body4>
             <Link to="/" target="_blank">Learn More</Link>
            </Body4>
        </Wrapper>
    )


}

export default UnVerified;