const Data = {
  hero: {
    header: "Smarter Growth"
  },
  prioritiesHeader: "ADDRESSING TODAY’S PRIORITIES",
  efficientGrowth: {
    imgSrc: "/assets/featuresCompanies/repora-company-chart-growth-new.png",
    header: "Efficient Growth is More Important Than Ever",
    description: "During economic headwinds, efficient growth (NRR) becomes The most important metric for today’s CEOs outside of cash flow*",
    buttonTxt: ""
  },
  topInitiatives: {
    imgSrc: "/assets/featuresCompanies/repora-company-chart-initiatives.png",
    header: "Top GTM Initiatives",
    description: "We address 6 of the current top 7 CEO go-to-market initiatives*",
    buttonTxt: ""
  },
  prioritiesFooter: {
    text: "* Goinsight CEO Survey, August 2022",
    linkText: "View Survey",
    linkUrl: "https://www.gainsight.com/blog/5-lessons-saas-ceo-survey/"
  },
  customers: {
    head: {
      header: "Repora Customers",
      title: [
        "Wonder what it’s like to do business with them and they’re not afraid of the answer",
        "Take pride in transparency and accountability",
        "Are focused on measuring and improving customer experience and customer value creation"
      ]
    }
  },
  useCases: {
    header: "Repora Use Cases",
    columns: [
      {
        imgSrc: "/assets/featuresCompanies/illustrations-direct.svg",
        header: "Direct Customers",
        description: "Get feedback from customers who buy directly from and are supported by local employees.",
        headerPadding: "0 25px 0"
      },
      {
        imgSrc: "/assets/featuresCompanies/illustrations-indirect.svg",
        header: "Indirect Customers",
        description: "Get feedback on customers who bought from or did their implementation by a channel partner where you often have even less visibility.",
        headerPadding: "0 15px 0"
      },
      {
        imgSrc: "/assets/featuresCompanies/illustrations-offshore.svg",
        header: "Remote / Offshore Employees",
        description: "Get feedback from customers supported by employees you may not see as often."
      },
    ]
  }
}

export default Data;
