import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types"

import { reporaDevice, reporaColor, Body3, Body4, Body5 } from "../../../components/global/commonStyles";
import RatingStars from "../../../components/RatingStars/RatingStars";

const Wrapper = styled.div`
  background-color: ${reporaColor.white};
  display: flex;
  flex-shrink: 0;
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.08);
  padding: 15px 0 20px;
  flex-direction: column;
  align-items: center;

  @media ${reporaDevice.tablet} {
    padding: 30px 0 32px;
    flex-direction: row;
    align-items: flex-start;
  }

  p {
    white-space: wrap;
  }
`;

const ImageWrapper = styled.div`
  width: auto;
  margin: 40px 40px;

  @media ${reporaDevice.tablet} {
    margin: 0 3%;
  }

  @media ${reporaDevice.desktop} {
    margin: 0 7%;
  }

  img {
    width: 60px;
    height: 60px;
  }
`;

const InformationWrapper = styled.div`
  width: 100%;
  text-align: center;

  @media ${reporaDevice.tablet} {
    text-align: left;
  }
`;

const RatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;
  justify-content: center;

  h4 {
    margin-left: 10px;
  }

  @media ${reporaDevice.tablet} {
    justify-content: flex-start;
  }
`;

const PersonCard = props => {
  const { repName, rating, companyName, companyAddress } = props;
  return (
    <Wrapper>
      <ImageWrapper>
        <img src={"/assets/global/userIcon.svg"} alt="" />
      </ImageWrapper>

      <InformationWrapper>
        <Body3>{repName}</Body3>
        <RatingWrapper>
          <RatingStars static rating={rating} />
          <Body4>{rating.toFixed(1)}</Body4>
        </RatingWrapper>
        <Body5 textColor={reporaColor.warmGrey}>{companyName || "No current company found"}</Body5>
        <Body5 textColor={reporaColor.warmGrey}>{companyAddress || "No address found"}</Body5>
      </InformationWrapper>
    </Wrapper>
  );
};

PersonCard.defaultProps = {
  state: "",
  zipCode: "",
  city: "",
  address: ""
}

PersonCard.propTypes = {
  repName: PropTypes.string.isRequired

}

export default PersonCard;
