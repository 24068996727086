import * as React from "react";
import styled from "styled-components";
import Spinner from "../../../components/Spinner/Spinner";
import {
  Body4,
  Header4,
  NoBorderTextArea,
  ReporaButton,
  TextAreaWrapper,
  reporaColor,
} from "../../../components/global/commonStyles";

const DisputeWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  padding-top: 25px;
  border-top: solid 1px ${reporaColor.grey87};

  h4 {
    margin-bottom: 25px;
  }

  p {
    margin-bottom: 15px;
  }

  textarea {
    width: 100%;
    resize: none;
    height: 195px;
    border-radius: 5px;
    border-color: ${reporaColor.warmGrey};
    font-family: Nexa Bold;
    padding: 0 10px;
    font-size: 14px;
    line-height: 28px;
  }
`;

const DisputeActions = styled.div`
  display: flex;
  margin: 20px 0;
  margin-left: auto;
  max-width: 520px;

  a {
    width: 100%;
    &:first-child {
      flex-shrink: 3;
      margin-right: 20px;
      border: solid 1px;
    }
  }
`;
const LabeStyle = styled.label`
  position: relative;
  cursor: pointer;

  display: -webkit-flex;
  display: -ms-flexbox;

  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  color: #9b9b9b;
  font-size: 14px;
`;

// const CheckboxWrapper = styled.div`
//   display: inline-block;
//   margin-top: 20px;
// `;
const CharsLeftLabel = styled.label`
  position: relative;
  left: 5%;
  p {
    padding-top: 0px !important;
  }
`;

export default class DisputeForm extends React.Component {
  onSubmit = (event) => {
    event.preventDefault();
    if (!event.target.attributes.disabled) {
      this.props.onSubmit();
    }
  };

  renderSubmitButton() {
    const { disputeRequest, submitCTA, comment } = this.props;
    const canSubmitRequest =
      !disputeRequest.isFetchingDispute &&
      !disputeRequest.disputeResponseReady &&
      comment;
    return (
      <ReporaButton onClick={this.onSubmit} disabled={!canSubmitRequest}>
        {canSubmitRequest || disputeRequest.disputeResponseReady ? (
          submitCTA
        ) : comment ? (
          <span style={{ display: "inline-block", width: 35, height: 35 }}>
            <Spinner color="white" />
          </span>
        ) : (
          submitCTA
        )}
      </ReporaButton>
    );
  }

  renderCancelLabel() {
    const { disputeRequest } = this.props;
    return disputeRequest.disputeResponseReady ? "Close" : "Cancel";
  }

  renderDisputeForm() {
    const {
      title,
      label,
      onCancel,
      disputeRequest: { isFetchingDispute, disputeResponseReady },
      notifyMe,
      notifyMeCTA,
      onNotifyMeClick,
    } = this.props;
    return (
      <DisputeWrapper>
        <Header4>{title}</Header4>
        <Body4 textColor={`${reporaColor.brightSkyBlue}`}>{label}</Body4>
        <TextAreaWrapper>
          <NoBorderTextArea
            rows="4"
            onChange={this.props.onTextAreaChange}
            disabled={isFetchingDispute || disputeResponseReady}
            value={this.props.comment}
            name={this.props.feedbackId}
            maxLength="1000"
            placeholder="Write your response here..."
          />
          <CharsLeftLabel>
            <Body4 textColor={`${reporaColor.brightSkyBlue}`}>
              {this.props.charactersLeft} characters left
            </Body4>
          </CharsLeftLabel>
        </TextAreaWrapper>
        <LabeStyle>
          <label htmlFor="notifyMe">{notifyMeCTA}</label>
        </LabeStyle>
        <DisputeActions>
          <ReporaButton onClick={onCancel} secondary>
            {this.renderCancelLabel()}
          </ReporaButton>
          {this.renderSubmitButton()}
        </DisputeActions>
      </DisputeWrapper>
    );
  }

  renderDisputeResponse() {
    const { titleDone, onCancel, notifyMe, disputeRequest, temp, abuse } =
      this.props;
    const textColor = disputeRequest.errorMessage
      ? reporaColor.cinnabar
      : reporaColor.brightSkyBlue;
    const message = notifyMe
      ? "Thank you for your response. Our team will carefully review your dispute, and notify you of the result by email."
      : "Thank you for your response. Our team will carefully review your report, and take the appropriate action.";

    return (
      <DisputeWrapper>
        <Header4>{titleDone}</Header4>
        <Body4 textColor={textColor}>
          {disputeRequest.errorMessage || message}
        </Body4>
        {notifyMe ? (
          <ReporaButton onClick={onCancel}>Okay</ReporaButton>
        ) : this.props.abuse ? (
          <ReporaButton onClick={temp}>Okay</ReporaButton>
        ) : (
          <ReporaButton onClick={this.props.onCloseDisputed}>Okay</ReporaButton>
        )}
      </DisputeWrapper>
    );
  }

  render() {
    if (this.props.disputeRequest.disputeResponseReady) {
      return this.renderDisputeResponse();
    } else {
      return this.renderDisputeForm();
    }
  }
}
