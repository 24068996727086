import React from "react";
import styled from "styled-components";
import { Body3 } from "../../components/global/commonStyles";

const Wrapper = styled.div`
    width: 100%;
    display: grid;
    justify-content: center;
    padding: 5rem;
    background-color: #ffffff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-top: 20px;
`;

const WrapperSection = styled.div`
    display: flex;
    justify-content: center;
`;

const ServerMaintain = () => {
    return (
        <Wrapper>
            <Body3>We are currently upgrading Repora to make it even better. Please check back in a little while.</Body3><br/>
            <WrapperSection>
                <Body3>We apologize for the inconvenience.</Body3>
            </WrapperSection>
        </Wrapper>
    )
}

export default ServerMaintain;