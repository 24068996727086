import React from "react";
import styled from "styled-components";
import Footer from "../../components/FooterUpdated/Footer";
import { reporaColor, ReporaButton, Header4, Header2, reporaSize } from "../../components/global/commonStyles";

const Wrapper = styled.section`
  background-image: linear-gradient(
    332deg, 
    ${reporaColor.greenBlue68}, 
    ${reporaColor.brightSkyBlue85}
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100% - 113px);

  @media (max-width: ${reporaSize.medium}) {
    min-height: calc(100% - 89px);
  }

  @media (max-width: ${reporaSize.small}) {
    min-height: calc(100% - 161px);
  }
`;

const SectionWrapper = styled.section`
  padding: 0 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  > h1 {
    color: white;
    font-size: 52px;
    margin-top: 50px;
    margin-bottom: 40px;
  }

  > h3 {
    color: white;
    font-size: 20px;
  }

  > p {
    color: white;
    font-size: 16px;
    line-height: 1.5;
  }

  > a {
    text-decoration: none;
    margin-top: 30px;
    margin-bottom: 140px;
  }
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${reporaColor.white};
  margin: 0 25% 15%;
  padding: 5%;
  text-align: center;

  h4 {
    font-size: 16px;
    line-height: 26px;

    @media (max-width: ${reporaSize.small}) {
      font-size: 12px;
    }

    a {
      color: ${reporaColor.aquaBlue};
      text-decoration: none;
    }
  }

  h2 {
    font-size: 18px;
    margin: 10px 0;

    @media (max-width: ${reporaSize.small}) {
      font-size: 14px;
    }
  }
`;

class Pricing extends React.Component {
  isDevEnv = () =>
    process.env.REACT_APP_NODE_ENV === "development" ||
    process.env.REACT_APP_NODE_ENV === "local" ||
    process.env.REACT_APP_NODE_ENV === "stagging";

  render() {
    
    return (
      <>
        <Wrapper>
          <SectionWrapper>
            <h1>Pricing</h1>
          </SectionWrapper>

          <Container>
            {this.isDevEnv() ?
              <Header4>Repora is free for customers and reps who can begin <a href="/signup">signing up</a> and using Repora immediately. Companies need a Repora subscription to solicit and analyze feedback about their representatives.</Header4>
            :
              <Header4>Repora will be free for customers and reps. Companies will need a Repora subscription to solicit and analyze feedback about their representatives.</Header4>
            }
            {this.isDevEnv() ?
              <Header2>Still Have Questions?</Header2>
            :
              <Header2>Want to get started?</Header2>
            }
            <ReporaButton href="/contactus">Contact Us</ReporaButton>
          </Container>
        </Wrapper>
      <Footer />
      </>
    );
  }
}

export default Pricing;
