import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import TextArea from "../../../components/Inputs/TextArea";
import Loader from "../../../components/Loader/Loader";

import {
  Body4,
  reporaDevice,
  ReporaSubmitButton,
  reporaColor,
  FormRow,
  FormGroup,
  Body3,
} from "../../../components/global/commonStyles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 731px;
  margin: 0 auto;
  padding: 40px;
  background-color: ${reporaColor.white};
  border-radius: 12.5px;
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.08);

  .company-heading {
    margin-bottom: 10px;

    @media ${reporaDevice.xs} {
      text-align: center;
    }
  }

  @media ${reporaDevice.xs} {
    padding: 10px;
  }
`

const ContentWrapper = styled.div`
  display: flex;

  @media ${reporaDevice.tablet} {
    flex-direction: row;
  }

  @media ${reporaDevice.xs} {
    flex-direction: column;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  a {
    margin-top: 40px;
  }

  @media ${reporaDevice.tablet} {
    width: 57%;
  }
`;

const AddressWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;

  ul {
    display: flex;
    flex-direction: column;
  }

  li {
    display: flex;
    margin-bottom: 22px;
    color: ${reporaColor.warmGrey};

    a {
      color: ${reporaColor.warmGrey};
      text-decoration: none;
    }
  }

  svg {
    margin-right: 16px;
    margin-top: 5px;
    color: ${reporaColor.brightSkyBlue};
  }

  @media ${reporaDevice.tablet} {
    margin-top: 65px;
    width: 43%;
    padding-left: 60px;

    ul {
      width: 100%;
    }
  }
`;

const InputError = styled.div`
  font-size: 10px;
  color: ${reporaColor.watermelon};
  position: absolute;
  bottom: -15px;
  left: 0;
`;

class Form extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",

    firstNameError: "",
    lastNameError: "",
    emailError: ""
  };

  handleInputChange = event => {
    const value = event.target.value;
    const name = event.target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    const { firstName, lastName, email, phoneNumber, message } = this.state;

    const firstNameValid = this.validateEmpty("firstName", firstName);
    const lastNameValid = this.validateEmpty("lastName", lastName);
    const emailValid = this.validateEmpty("email", email) && this.validateEmail("email", email);

    if (firstNameValid && lastNameValid && emailValid) {
      this.props.handleSubmit(
        firstName,
        lastName,
        email,
        phoneNumber,
        message,
        this.props.isCompanyContact
      );
    }
  }

  validateEmpty = (name, value) => {
    if (value === "") {
      this.setState({
        [`${name}Error`]: "This field is required"
      });
      return false;
    }
    return true;
  }

  validateEmail = (name, value) => {
    const isValidEmail = !!value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); // eslint-disable-line no-useless-escape

    if (!isValidEmail) {
      this.setState({
        [`${name}Error`]: "Should be a valid email address"
      });
      return false;
    }
    return true;
  }

  render() {
    const { firstName, lastName, email, message, error } = this.state;
    const enableSubmit = firstName && lastName && email;

    return (
      <Wrapper>
        {this.props.fetching && <Loader overlayed size="medium" />}
        {
          this.props.isCompanyContact &&
          <Body3 className="company-heading" textColor={reporaColor.warmGrey}>
            Thank you for your interest in using Repora for your company. Please contact us and we will be in touch shortly with pricing, terms, etc.
          </Body3>
        }
        <ContentWrapper>
          <FormWrapper>
            <form onSubmit={this.handleSubmit}>
              {
                error &&
                <Body3 textColor={reporaColor.watermelon}>{error}</Body3>
              }
              <FormRow>
                <FormGroup>
                  <label>First Name</label>
                  <input required onChange={this.handleInputChange} name="firstName" type="text" />
                  <InputError>{this.state.firstNameError}</InputError>
                </FormGroup>
                <FormGroup>
                  <label>Last Name</label>
                  <input required onChange={this.handleInputChange} name="lastName" type="text" />
                  <InputError>{this.state.lastNameError}</InputError>
                </FormGroup>
              </FormRow>
              <FormGroup>
                <label>Email Address</label>
                <input required onChange={this.handleInputChange} name="email" type="email" />
                <InputError>{this.state.emailError}</InputError>
              </FormGroup>
              <FormGroup>
                <label>Phone Number (optional)</label>
                <input onChange={this.handleInputChange} name="phoneNumber" type="text" />
              </FormGroup>

              <TextArea
                className="m-t-20 m-b-40"
                maxLength="500"
                rows="4"
                onChange={this.handleInputChange}
                value={message}
                name="message"
                placeholder="Enter your message here (optional)..."
                counter />

              <ReporaSubmitButton disabled={!enableSubmit} full>Submit</ReporaSubmitButton>
            </form>
          </FormWrapper>

          <AddressWrapper>
            <ul>
              <li>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <Body4>650 Castro Street<br /> Suite 120-500<br />Mountain View, CA 94041</Body4>
              </li>
              <li>
                <FontAwesomeIcon icon={faPhone} />
                <Body4>(650) 469-3221‬</Body4>
              </li>
              
            </ul>
          </AddressWrapper>
          </ContentWrapper>
      </Wrapper>
    );
  }
};

export default Form;