import React from "react";
import styled from "styled-components";

import { ReporaButton, Header1, Body1 } from "../../components/global/commonStyles";

const Wrapper = styled.div`
  width:  100%;
  height: ${props => props.height || "673px"};
  display: flex;
  justify-content: center;
`;

const HeroContent = styled.div`
  width: 95%;
  max-width: 850px;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: ${props => props.textColor};
  margin-top: ${props => props.margin || "0px"};

  >* {
    max-width: 100%;
  }
  p {
    font-size:16px;
    width:${props => props.pWidth || "635px"};
    padding-right: 55px;
    padding-left: 55px;
    text-align: center;
    margin-bottom: ${props => props.pMarginBotton};
  }
  
  h1 {
    width:${props => props.h1Width || "576px"};
    font-size:52px;
    margin-bottom: ${props => props.h1MarginBotton || "35px"};
   }
`;

class Hero extends React.Component {
  render() {
    return (
      <Wrapper
        height={this.props.height}
      >
        <HeroContent
          h1Width={this.props.h1Width}
          h1MarginBotton={this.props.h1MarginBotton}
          pMarginBotton={this.props.pMarginBotton}
          pWidth={this.props.pWidth}
          margin={this.props.margin}
          textColor={this.props.textColor}>
          <Header1 textAlign="center">{this.props.data.header}</Header1>
          <Body1 textAlign="center">{this.props.data.description}{this.props.data.descriptionLine1}<br />{this.props.data.descriptionLine2}</Body1>
          {this.props.data.buttonTxt &&
            <ReporaButton href="/signup?state=role-ADMIN" >{this.props.data.buttonTxt}</ReporaButton>
          }
        </HeroContent>
      </Wrapper>
    )
  }
}



export const Unwrapped = Hero;
export default Hero;
