import React from "react";
import styled from "styled-components";
import { Body4 } from "../../../components/global/commonStyles";


const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding:5%;
`;


const Disconnected = props => {
  let splitErrorMsg = props.error.split("from")[1];
  return (
    <Wrapper>
      <Body4>
        We noticed your Repora account was disconnected from {splitErrorMsg}.
      </Body4>
      <br></br>
      <Body4>
        Since you have received customer feedback within Repora, that data is still accessible by you even though it is no longer accessible by {splitErrorMsg}. To continue using Repora but this time as a Rep (to view your customer feedback, request further feedback and curate your own reputation), please:
      </Body4>
      <br></br>
      <p id="section" style={{
        paddingLeft: "30px"
      }}>
        <Body4>
          1.   Sign up for a new Rep account in Repora by <a href="/signup" style={{
            textDecoration: "underline",
            color: "#0bc9f6"
          }}>clicking here</a> (you will need to use a different email address than your previous account at {splitErrorMsg}).
        </Body4>
        <Body4>
          2.   After logging in as the new Rep you created, use the “Claim Account” feature which will transfer the feedback from your old {splitErrorMsg} account. The “Claim Account” feature can be found in the dropdown menu at the top right of the screen.

        </Body4>
      </p>
      <br></br>
      <Body4>
        To use Repora as a Customer (to give feedback to others), please create a customer account by <a href="/signup" style={{
          textDecoration: "underline",
          color: "#0bc9f6"
        }}> clicking here</a>.
      </Body4>
      <Body4>
        If you have any questions, please don’t hesitate to contact us at<a style={{
          textDecoration: "underline",
          color: "#0bc9f6"
        }}> support@repora.co</a>.
      </Body4>
      <br></br>
      <Body4>
        Sincerely,
      </Body4>

      <Body4>
        The Repora Team
      </Body4>
    </Wrapper>
  );
};

export default Disconnected;
