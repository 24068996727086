import React from "react";
import styled from "styled-components";
import { withRouter, NavLink, Redirect } from "react-router-dom";
import logo from "../../assets/global/logo.svg";
// import Footer from "../../../components/Footer/Footer";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

// import { reporaDevice } from "../../components/global/commonStyles";
import { reporaColor,reporaDevice, Body5, Header4, Header5 } from "../../components/global/commonStyles";

const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  padding-top: 51px;
  margin: 0 auto;
  margin-top:10px;
  background-color: ${reporaColor.white};
  min-height: 87vh;
`;

const Content1 = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom:1%;
  padding: 0 10px;
  height: 40px;
  text-align: center;


  *{
      display: inline;
  }

  >div {
    flex: 1;

    &:first-child {
      color: ${reporaColor.warmGrey};
      font-size: 14px;
      font-weight: bold;
      white-space: nowrap;
    }
  }

  a {
    color: black;
    font-size: 14px;
    font-weight: bold;
    margin: 0 20px;
    text-decoration: none;
    white-space: nowrap;
  }

  @media ${reporaDevice.xs} {
    flex-direction: column;
    align-items: center;
    height: auto;

    >div:first-child {
      margin: 20px 0;
    }

    >div:nth-of-type(2) {
      flex-direction: column;
    }

    a {
      margin: 0;
      margin-bottom: 20px;
      margin-left: 20px;
    }
  }
`;


class DeletionInstructions extends React.Component{
  render() {

    return (
        <Wrapper>
            <div className="content">
            <h1>Facebook Data Deletion Instructions URL</h1><br></br>
            <p>Repora does not save your Facebook personal data on its server.</p><br></br>

            <p>However, according to Facebook policy, we have to provide User Data Deletion Callback URL or Data Deletion Instructions URL.</p><br></br>
            
            <p>If you want to delete your data from Repora app, you can remove your information by following these steps:</p><br></br>
            <ol>
                <li>&nbsp;&nbsp;&nbsp;&nbsp;1. Go to your Facebook Account’s Setting & Privacy. Click “Settings”</li><br></br>
                    
                <li>&nbsp;&nbsp;&nbsp;&nbsp;2. Look for “Apps and Websites” and you will see all of the apps and websites you linked with your Facebook.</li><br></br>
                    
                <li>&nbsp;&nbsp;&nbsp;&nbsp;3. Search and Click “Repora” in the search bar.</li><br></br>
                    
                <li>&nbsp;&nbsp;&nbsp;&nbsp;4. Scroll and click “Remove”.</li><br></br>
                    
                <li>&nbsp;&nbsp;&nbsp;&nbsp;5. Congratulations, you have successfully removed your app activities and data from the Repora App.</li><br></br>
                <br></br>
                <br></br>
                <br></br>
            </ol>
            </div>
            <Content1>
                <p>© RepScore, Inc.</p>
                <div>
                    {/* <Link to="/ourcompany">Company</Link> */}
                    <Link to="/privacy">Privacy and Terms</Link>
                    {/* <Link to="/contactus">Contact Us</Link> */}
                </div>
                <div></div>
            </Content1>
    
        </Wrapper>
        
    
    
    )
  }
}

export default DeletionInstructions;
