import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import queryString from "query-string";
import base64 from "base-64";

import {
  reporaDevice,
  reporaColor,
} from "../../../components/global/commonStyles";
import InputSuggestions from "./InputSuggestions";

import SearchSvg from "../assets/search.svg";
import constants from "../../../constants";

const FormWrapper = styled.form`
  display: flex;
  justify-content: center;
  width: 100%;

  > div {
    max-width: 330px;
    width: 100%;
    margin-right: 15px;
  }

  @media ${reporaDevice.xs} {
    flex-direction: column;
    align-items: center;

    > div {
      width: 100%;
      margin: 0;
      margin-top: 15px;
    }

    button {
      margin: 3%;
    }
  }
`;

const SubmitButton = styled.button`
  background: ${reporaColor.brightSkyBlue};
  display: flex;
  border-radius: 4px;

  padding: 0;
  width: 40px;
  height: 40px;
  border: 0;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

class SearchForm extends React.Component {
  state = {
    selectedRep: null,
    selectedCompany: null,

    repInputValue: "",
    companyInputValue: "",

    repSuggestions: [],
    companySuggestions: [],
  };

  componentDidUpdate(prevProps) {
    const { repSuggestions, companySuggestions } = this.props;

    if (repSuggestions !== prevProps.repSuggestions) {
      this.setState({
        repSuggestions: repSuggestions.map((suggestion) => ({
          title: suggestion.name,
          subtitle: suggestion.companyName || "-",
          picture: suggestion.image,
          repId: suggestion.id,
        })),
      });
    }

    if (companySuggestions !== prevProps.companySuggestions) {
      this.setState({
        companySuggestions: companySuggestions.map((suggestion) => {
          const industry = suggestion.industry
            ? `${suggestion.industry} - `
            : "";
          const address =
            suggestion.physicalAddress &&
            suggestion.physicalAddress.state &&
            suggestion.physicalAddress.city
              ? `${suggestion.physicalAddress.state}, ${
                  suggestion.physicalAddress.city
                }`
              : "-";

          return {
            title: suggestion.name,
            subtitle: industry + address,
            picture: suggestion.logo,
            companyId: suggestion.id,
          };
        }),
      });
    }
  }

  handleSelectRep = (selectedEl) => {
    this.setState({
      selectedRep: selectedEl,
      repInputValue: selectedEl ? selectedEl.title : "",
      companyInputValue: selectedEl ? selectedEl.subtitle : " ",
    });
  };

  handleSelectCompany = (selectedEl) => {
    this.setState({
      selectedCompany: selectedEl,
      companyInputValue: selectedEl ? selectedEl.title : "",
    });
  };

  handleInputRepChange = (value) => {
    this.setState({
      selectedRep: null,
      repInputValue: value,
    });
  };

  handleInputCompanyChange = (value) => {
    this.setState({
      selectedCompany: null,
      companyInputValue: value,
    });
  };

  handleSearch = (event) => {
    event.preventDefault();
    const {
      selectedRep,
      selectedCompany,
      repInputValue,
      companyInputValue,
    } = this.state;

    const { repName, companyName, repId, companyId } = {
      repName: repInputValue || undefined,
      companyName: companyInputValue.trim() || undefined,
      repId: selectedRep ? selectedRep.repId : undefined,
      companyId: selectedCompany ? selectedCompany.companyId : undefined,
    };

    const url = queryString.stringify({
      repName: repInputValue || undefined,
      companyName: companyInputValue.trim() || undefined,
      repId: selectedRep ? selectedRep.repId : undefined,
      companyId: selectedCompany ? selectedCompany.companyId : undefined,
    });

    const encodedUrl = base64.encode(url);
    this.props.history.push(`/searchrep/results/${encodedUrl}`);
    this.props.clearRepSelectionData();
    // if (
    //   this.props.user &&
    //   this.props.user.userRole === constants.USER_ROLES.COMPANY
    // ) {
    //   this.props.handleSearchCompanyReps(repName, repId, companyId);
    // } else {
    //   this.props.handleSearchReps(repName, repId, companyName, companyId);
    // }
  };

  handleGetRepSuggestions = (inputValue) => {
    const companyId =
      this.state.selectedCompany && this.state.selectedCompany.companyId;
    this.props.handleGetRepSuggestions(inputValue, companyId);
  };

  handleGetCompanySuggestions = (inputValue) => {
    const repId = this.state.selectedRep && this.state.selectedRep.repId;
    this.props.handleGetCompanySuggestions(inputValue, repId);
  };

  render() {
    const { repSuggestions, companySuggestions } = this.state;
    const { repSearch } = this.props;
    return (
      <React.Fragment>
        <FormWrapper>
          <InputSuggestions
            name="new-rep-suggestions"
            suggestions={repSuggestions}
            placeholder="Enter the name of a rep"
            loading={this.props.repSuggestionsFetching}
            handleSelectElement={this.handleSelectRep}
            selectedElement={this.state.selectedRep}
            handleInputChange={this.handleInputRepChange}
            handlesetRepSearch={this.props.handlesetRepSearch}
            inputDelay={this.props.inputDelay}
            autoFocus={true}
            handleGetSuggestions={this.handleGetRepSuggestions}
            user = {this.props?.user}
          />

          <InputSuggestions
            name="new-company-suggestions"
            suggestions={companySuggestions}
            placeholder="Company"
            loading={this.props.companySuggestionsFetching}
            handleSelectElement={this.handleSelectCompany}
            selectedElement={this.state.selectedCompany}
            handleInputChange={this.handleInputCompanyChange}
            inputDelay={this.props.inputDelay}
            handleGetSuggestions={this.handleGetCompanySuggestions}
          />

          <SubmitButton
            type="submit"
            onClick={this.handleSearch}
            disabled={
              this.props.fetchingCompanies ||
              this.props.fetchingReps ||
              (!this.state.repInputValue && !this.state.companyInputValue)
            }
          >
            <img src={SearchSvg} alt="search" />
          </SubmitButton>
        </FormWrapper>
      </React.Fragment>
    );
  }
}

SearchForm.propTypes = {
  repSuggestions: PropTypes.array,
  companySuggestions: PropTypes.array,

  repSuggestionsFetching: PropTypes.bool,
  fetchingCompanySuggestions: PropTypes.bool,

  repSuggestionsError: PropTypes.string,
};

SearchForm.defaultProps = {
  repSuggestions: [],
  companySuggestions: [],
};

export default withRouter(SearchForm);
