import React from "react";
import styled from "styled-components";
import { reporaColor } from "../global/commonStyles";

const Container = styled.div`
  position: relative;
  border-radius: 4px;
  padding: 10px;
  border: ${props => (props.focused ? `1px solid ${reporaColor.brightSkyBlue}` : "1px solid #E8E8E8")};
  box-shadow: ${props => (props.focused ? `0 0 2px ${reporaColor.brightSkyBlue}` : "none")};

  textarea {
    display: block;
    width: 100%;
    resize: none;
    font-size: 14px;
    font-family: Nexa Bold;
    border: none;
    outline: none;
  }
`;

const Message = styled.p`
  font-family: Nexa Bold;
  font-size: 10px;
  line-height: 22px;
  color: ${reporaColor.brightSkyBlue};
  user-select: none;
`;

class TextArea extends React.Component {
  state = {
    focused: false
  }

  constructor(props) {
    super(props);
    this.textareaRef = React.createRef();
  }

  handleContainerClick = () => {
    const node = this.textareaRef.current;
    node.focus();
  }

  handleTextareaClick = event => {
    event.stopPropagation();
  }

  render() {
    return (
      <Container
        focused={this.state.focused}
        className={this.props.className}
        onClick={this.handleContainerClick}>
        <textarea
          ref={this.textareaRef}
          onClick={this.handleTextareaClick}
          onFocus={() => this.setState({ focused: true })}
          onBlur={() => this.setState({ focused: false })}
          maxLength={this.props.maxLength}
          rows={this.props.rows}
          onChange={this.props.onChange}
          value={this.props.value}
          name={this.props.name}
          placeholder={this.props.placeholder} />
        {this.props.counter && this.props.maxLength && <Message>{`${this.props.maxLength - this.props.value.length} characters left`}</Message>}
      </Container>
    );
  }
}

export default TextArea;