import React from "react";
import styled from "styled-components";
import { reporaColor, ReporaButton, reporaSize, Header2, Body4 } from "../../../components/global/commonStyles";
import Hero from "../../../components/HeroUpdated/Hero";
import PanelColumns from "../../../components/PanelColumnsUpdated/PanelColumns";
import HomeHeroBgImage from "../assets/Repora_Home_Hero_BGimage.jpg";
import Footer from "../../../components/FooterUpdated/Footer";
import Data from "./Data";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PageSection = styled.section`
  width: 100%;
  max-width: 100%;
  height: auto !important;
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: ${props => props.bkgImage ? `url(${props.bkgImage})` : "none"};
  background-color: ${props => props.bkgColor || "none" };
  background-position: ${props => props.isFooter ? "top" : "center center"};
	background-repeat: no-repeat;
	background-size: cover;
  padding: ${props => props.isHero ? "8% 30%" : "5% 15%"};
  color: ${props => props.txtColor || "inherit"};

  ${props => props.isFooter ? "margin-top: -6%;" : null}

  @media (max-width: ${reporaSize.medium}) {
		padding-left: ${props => props.isHero ? "15%" : "5%"};
    padding-right: ${props => props.isHero ? "15%" : "5%"};

    ${props => props.isFooter ? "margin-top: -8%;" : null}
	}

  @media (max-width: ${reporaSize.small}) {
    ${props => props.isFooter ? "margin-top: -15%;" : null}
	}

  h2 {
    span {
      color: gold;
    }
  }
`;

const CustomPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h2 {
    font-size: 35px;
    line-height: initial;
    margin-bottom: 3%;

    @media (max-width: ${reporaSize.small}) {
      font-size: 20px;
    }
  }
`;

const SectionFooter = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 23%;
  padding: 15px;
  background-color: #0bc9f63b;
  border-radius: 5px;

  @media (max-width: ${reporaSize.medium}) {
		margin: 0 15%;
	}

  @media (max-width: ${reporaSize.small}) {
		margin: 0;
	}
`

const ColumnImg = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${reporaSize.medium}) {
		width: 40%;
	}

  @media (max-width: ${reporaSize.small}) {
		width: 60%;
	}

  img {
    max-width:  ${props => props.size==="small" ?"50px":"300px"};
    width: 100%;
  }
`;

const LegendContainer = styled.fieldset`
  border: 2px solid gray;
  border-radius: 5px;
  margin-bottom: 11%;

  @media (max-width: ${reporaSize.medium}) {
    margin-bottom: 13%;
  }

  @media (max-width: ${reporaSize.small}) {
    margin-bottom: 20%;
	}

  &.problem-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 2% 4%;
  }
`;

const LegendTitle = styled.legend`
  margin-left: auto;
  margin-right: auto;
  color: gold;
  padding: 0 10px;
`;

const LegendColumns = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: ${reporaSize.small}) {
    flex-direction: column;
  }
`
const LegendColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: ${reporaSize.small}) {
    width: 100%;
  }

  h2 {
    font-size: 18px;
    line-height: initial;

    @media (max-width: ${reporaSize.medium}) {
      font-size: 16px;
    }
  }
`

const LegendColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  @media (max-width: ${reporaSize.small}) {
    margin-bottom: 5%;
  }

  &.customerColumn {
    border-right: 2px solid gray;

    @media (max-width: ${reporaSize.small}) {
      border-right: none;
    }
  }
`

const LegendColumnItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0 5%;

  .columnArrow {
    top: -5%;
    position:relative;

    @media (max-width: ${reporaSize.small}) {
      top: -15px;
    }

    &:after {
      content: "";
      background-image: url(/assets/home/down-arrow.svg);
      height: 30px;
      width: 20px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
`

const LegendColumnItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 3%;
  padding: 4% 0;
  border-radius: 5px;
  border: 3px solid ${reporaColor.aquaBlue84};

  @media (max-width: ${reporaSize.medium}) {
    margin: 5%;
  }
  
  &.employeesColumnItem {
    border-color: ${reporaColor.greenBlue};
  }
`

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2% 0;

  h2 {
    font-size: 20px;
    line-height: initial;

    @media (max-width: ${reporaSize.medium}) {
      font-size: 16px;
    }
  }
`

const ProblemFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-right: ${props => props.hasBorder ? `1px solid ${reporaColor.aquaBlue84}` : "none"};
  width: ${props => `${100/(props.itemLength || 1)}%`};
  padding: 1% 0;

  h2 {
    font-size: 16px;
    line-height: initial;
    padding: 0 10%;

    @media (max-width: ${reporaSize.small}) {
      font-size: 12px;
    }
  }
`

const View = () => {
  return (
    <Wrapper>
      <PageSection bkgImage={HomeHeroBgImage} isHero>
        <Hero textColor={reporaColor.white} data={Data.hero} />
      </PageSection>

      <PageSection>
        <PanelColumns data={Data.welcome} />
        {Data.welcome.footer ? 
          <SectionFooter>
            <ColumnImg size={Data.welcome.size}>
              <img src={Data.welcome.footer.imgSrc} alt=""/>
            </ColumnImg>
            <Body4 textAlign="center">{Data.welcome.footer.title}</Body4>
          </SectionFooter>
        : null}
      </PageSection>

      <PageSection bkgColor={reporaColor.sectionBackGround} txtColor={reporaColor.white}>
        <PanelColumns data={Data.problem.head1} isheadingPadded={true} />
        <LegendContainer>
          <LegendTitle>{Data.problem.effect.title}</LegendTitle>
          <LegendColumns>
            <LegendColumn>
              <Header2 textAlign="center">{Data.problem.effect.customers.title}</Header2>
              {Data.problem.effect.customers.effects && Data.problem.effect.customers.effects.length > 0 ? 
                <LegendColumnsContainer className="customerColumn">
                  <LegendColumnItems>
                    {Data.problem.effect.customers.effects.map((item, index) => (
                      <React.Fragment key={index}>
                        <LegendColumnItem className="customerColumnItem">
                          <Header2 textAlign="center">{item}</Header2>
                        </LegendColumnItem>
                        {index != Data.problem.effect.customers.effects.length - 1 ? <div className="columnArrow"></div> : null}
                      </React.Fragment>
                    ))}
                  </LegendColumnItems>
                </LegendColumnsContainer>
              : null}
            </LegendColumn>
            <LegendColumn>
              <Header2 textAlign="center">{Data.problem.effect.employees.title}</Header2>
              {Data.problem.effect.employees.effects && Data.problem.effect.employees.effects.length > 0 ? 
                <LegendColumnsContainer className="employeesColumn">
                  <LegendColumnItems>
                    {Data.problem.effect.employees.effects.map((item, index) => (
                      <React.Fragment key={index}>
                        <LegendColumnItem className="employeesColumnItem">
                          <Header2 textAlign="center">{item}</Header2>
                        </LegendColumnItem>
                        {index != Data.problem.effect.employees.effects.length - 1 ? <div className="columnArrow"></div> : null}
                      </React.Fragment>
                    ))}
                  </LegendColumnItems>
                </LegendColumnsContainer>
              : null}
            </LegendColumn>
          </LegendColumns>
          <FooterContainer>
            <Header2 textAlign="center">These all result in <span>higher expenses</span> & <span>lower revenue</span></Header2>
          </FooterContainer>
        </LegendContainer>
        <PanelColumns data={Data.problem.head2} />
        {Data.problem.footer && Data.problem.footer.length > 0 ?
          <LegendContainer className="problem-footer">
              {Data.problem.footer.map((item, index) => (
                <ProblemFooter key={index} hasBorder={index != Data.problem.footer.length - 1} itemLength={Data.problem.footer.length}>
                  <Header2 textAlign="center">{item}</Header2>
                </ProblemFooter>
              ))}
          </LegendContainer>
        : null}
      </PageSection>

      <PageSection bkgImage="/assets/home/footer-bgm.png" isFooter>
        <CustomPanel>
          <Header2 textAlign="center" textColor={`${reporaColor.black}`}>Start Using Repora Today!</Header2>
          <ReporaButton href="/contactus" secondary>Contact Us</ReporaButton>
        </CustomPanel>
      </PageSection>

      <Footer />

    </Wrapper>
  );
};

export default View;
