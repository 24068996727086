
import React from "react";
import { connect } from "react-redux";
 import styled from "styled-components";


const Wrapper = styled.div` 
`;

class AdminPannel extends React.Component {
    state = {
        companiesList: []
    };

    componentDidMount() {

    }

    componentWillReceiveProps(newprops) {

    }


    render() {
        return (
            <Wrapper>
                AdminPannel
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({ 
});

const mapDispatchToProps = dispatch => ({ 
});

export const Unwrapped = AdminPannel;
export default connect(mapStateToProps, mapDispatchToProps)(AdminPannel);
