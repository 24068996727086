import {
  RESET_REQUEST,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SOCIAL_URL_REQUEST,
  SOCIAL_URL_SUCCESS,
  SOCIAL_URL_FAILURE,
  SOCIAL_DATA_REQUEST,
  SOCIAL_DATA_SUCCESS,
  SOCIAL_DATA_FAILURE,
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAILURE,

  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_SUCCEEDED,
  FORGOT_PASSWORD_FAILED,

  RESET_PASSWORD_REQUESTED,
  RESET_PASSWORD_SUCCEEDED,
  RESET_PASSWORD_FAILED,

  FORGOT_PASSWORD_CLEAR_REQUESTED,

  VERIFY_ACCOUNT_REQUESTED,
  VERIFY_ACCOUNT_SUCCEEDED,
  VERIFY_ACCOUNT_FAILED,

  UPDATE_FROM_LINKEDIN_REQUESTED,
  UPDATE_FROM_LINKEDIN_SUCCEEDED,
  UPDATE_FROM_LINKEDIN_FAILED,

  SIGN_UP_COMPANY_REQUESTED,
  SIGN_UP_COMPANY_SUCCEEDED,
  SIGN_UP_COMPANY_FAILED,
  VERIFY_ACCOUNT_ERROR_RESET
} from "../actions/accountActions";

const initialState = {
  responseSignUp: {},
  responseAddCompany: {},
  socialUrl: {},
  socialData: {},
  errorMessage: {},
  isFetching: false,
  isFetchingResponse: false,
  isSignUpFinish: false,
  fetchingForgotPassword: false,
  forgotPasswordError: "",
  forgotPasswordSent: false,
  fetchingResetPassword: false,
  resetPasswordError: "",
  resetPasswordSent: false,
  socialUrlError: "",

  verifyAccountSucceeded: false,
  updateFromLinkedinSucceeded: false
}

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_REQUEST:
      return ({
        ...state,
        ...initialState
      });
    case SIGN_UP_REQUEST:
      return ({
        ...state,
        isFetchingResponse: true,
        isSignUpFinish: false,
        responseSignUp: {}
      });
    case SIGN_UP_SUCCESS:
      window.localStorage.removeItem('inviteId');
      window.localStorage.removeItem('companyId');
      return ({
        ...state,
        isFetchingResponse: false,
        isSignUpFinish: true,
        responseSignUp: action.payload
      });
    case SIGN_UP_FAILURE:
      // window.localStorage.removeItem('inviteId');
      // window.localStorage.removeItem('companyId');
      return ({
        ...state,
        isFetchingResponse: false,
        isSignUpFinish: true,
        responseSignUp: action.payload,
        errorMessage: action.payload
      });

    case SIGN_UP_COMPANY_REQUESTED:
      return ({
        ...state,
        isFetchingResponse: true,
        isSignUpFinish: false,
        responseSignUp: {}
      });
    case SIGN_UP_COMPANY_SUCCEEDED:
      return ({
        ...state,
        isFetchingResponse: false,
        isSignUpFinish: true,
        responseSignUp: action.payload
      });
    case SIGN_UP_COMPANY_FAILED:
      return ({
        ...state,
        isFetchingResponse: false,
        isSignUpFinish: true,
        responseSignUp: action.payload,
        errorMessage: action.payload
      });

    case SOCIAL_URL_REQUEST:
      return Object.assign({}, state, { isFetching: true, socialUrl: {} });
    case SOCIAL_URL_SUCCESS:
      return Object.assign({}, state, { isFetching: false, socialUrl: action.payload });
    case SOCIAL_URL_FAILURE:
      return Object.assign({}, state, { isFetching: false, socialUrl: {}, errorMessage: action.payload });
    case SOCIAL_DATA_REQUEST:
      return Object.assign({}, state, { isFetching: true, socialData: {} });
    case SOCIAL_DATA_SUCCESS:
      return Object.assign({}, state, { isFetching: false, socialData: action.payload });
    case SOCIAL_DATA_FAILURE:
      return Object.assign({}, state, { isFetching: false, socialData: {}, errorMessage: action.payload });

    case ADD_COMPANY_REQUEST:
      return Object.assign({}, state, { isFetching: true, responseAddCompany: {} });
    case ADD_COMPANY_SUCCESS:
      return Object.assign({}, state, { isFetching: false, responseAddCompany: action.payload });
    case ADD_COMPANY_FAILURE:
      return Object.assign({}, state, { isFetching: false, responseAddCompany: {}, errorMessage: action.payload });

    case FORGOT_PASSWORD_REQUESTED:
      return ({
        ...state,
        fetchingForgotPassword: true,
        forgotPasswordSent: false
      });
    case FORGOT_PASSWORD_SUCCEEDED:
      return ({
        ...state,
        forgotPasswordError: "",
        fetchingForgotPassword: false,
        forgotPasswordSent: true
      });
    case FORGOT_PASSWORD_FAILED:
      return ({
        ...state,
        forgotPasswordError: action.payload,
        fetchingForgotPassword: false,
        forgotPasswordSent: false
      });

    case RESET_PASSWORD_REQUESTED:
      return ({
        ...state,
        fetchingResetPassword: true,
        resetPasswordSent: false
      })
    case RESET_PASSWORD_SUCCEEDED:
      return ({
        ...state,
        fetchingResetPassword: false,
        resetPasswordError: "",
        resetPasswordSent: true
      })
    case RESET_PASSWORD_FAILED:
      return ({
        ...state,
        fetchingResetPassword: false,
        resetPasswordError: action.payload,
        resetPasswordSent: false
      })

    case FORGOT_PASSWORD_CLEAR_REQUESTED:
      return ({
        ...state,
        forgotPasswordError: "",
        forgotPasswordSent: false
      });

    case VERIFY_ACCOUNT_REQUESTED:
      return ({
        ...state,
        verifyAccountSucceeded: false
      });
    case VERIFY_ACCOUNT_SUCCEEDED:
      return ({
        ...state,
        verifyAccountSucceeded: true
      });
    case VERIFY_ACCOUNT_FAILED:
      return ({
        ...state,
        verifyAccountSucceeded: false,
        socialUrlError: action.payload
      });

    case VERIFY_ACCOUNT_ERROR_RESET:
      return ({
        ...state,
        verifyAccountSucceeded: false,
        socialUrlError: ""
      });

    case UPDATE_FROM_LINKEDIN_REQUESTED:
      return ({
        ...state,
        updateFromLinkedinSucceeded: false
      });
    case UPDATE_FROM_LINKEDIN_SUCCEEDED:
      return ({
        ...state,
        updateFromLinkedinSucceeded: true
      });
    case UPDATE_FROM_LINKEDIN_FAILED:
      return ({
        ...state,
        updateFromLinkedinSucceeded: false
      });


    default:
      return state;
  }
};

export default accountReducer;
