import config from "../config";
import constants from "../constants";
import authService from "../services/AuthService";

import { resetRateRep } from "./repActions";

/* Action Constants */
export const LOG_IN_REQUESTED = "LOG_IN_REQUESTED";
export const LOG_IN_SUCCEEDED = "LOG_IN_SUCCEEDED";
export const LOG_IN_FAILED = "LOG_IN_FAILED";
export const CLEAR_LOG_IN_MESSAGE = "CLEAR_LOG_IN_MESSAGE";

export const LOG_IN_ADMIN_REQUESTED = "LOG_IN_REQUESTED";
export const LOG_IN_ADMIN_SUCCEEDED = "LOG_IN_ADMIN_SUCCEEDED";
export const LOG_IN_ADMIN_FAILED = "LOG_IN_ADMIN_FAILED";
export const CLEAR_LOG_IN_ADMIN_MESSAGE = "CLEAR_LOG_IN_ADMIN_MESSAGE";

export const SOCIAL_LOG_IN_REQUESTED = "SOCIAL_LOG_IN_REQUESTED";
export const SOCIAL_LOG_IN_SUCCEEDED = "SOCIAL_LOG_IN_SUCCEEDED";
export const SOCIAL_LOG_IN_FAILED = "SOCIAL_LOG_IN_FAILED";

export const SOCIAL_URL_REQUESTED = "SOCIAL_URL_REQUESTED";
export const SOCIAL_URL_SUCCEEDED = "SOCIAL_URL_SUCCEEDED";
export const SOCIAL_URL_FAILED = "SOCIAL_URL_FAILED";

export const LOGOUT_SUCCEEDED = "LOGOUT_SUCCEEDED";

export const COMPANY_UPDATE_PASSWORD_VERIFICATION_SUCCEEDED =
  "COMPANY_UPDATE_PASSWORD_VERIFICATION_SUCCEEDED";
export const COMPANY_UPDATE_PASSWORD_VERIFICATION_FAILED =
  "COMPANY_UPDATE_PASSWORD_VERIFICATION_FAILED";
export const ADMIN_INFO_PASSWORD_VERIFICATION_SUCCEEDED =
  "ADMIN_INFO_PASSWORD_VERIFICATION_SUCCEEDED";
export const ADMIN_INFO_PASSWORD_VERIFICATION_FAILED =
  "ADMIN_INFO_PASSWORD_VERIFICATION_FAILED";
export const PASSWORD_VERIFICATION_RESET = "PASSWORD_VERIFICATION_RESET";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_RESET = "CHANGE_PASSWORD_RESET";
export const CHANGE_PASSWORD_SUCCEEDED = "CHANGE_PASSWORD_SUCCEEDED";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const UPDATE_ADMIN_INFO_REQUEST = "UPDATE_ADMIN_INFO_REQUEST";
export const UPDATE_ADMIN_INFO_SUCCESS = "UPDATE_ADMIN_INFO_SUCCESS";
export const UPDATE_ADMIN_INFO_FAILURE = "UPDATE_ADMIN_INFO_FAILURE";
export const UPDATE_ADMIN_INFO_RESET = "UPDATE_ADMIN_INFO_RESET";

export const CONFIRM_EMAIL_REQUEST = "CONFIRM_EMAIL_REQUEST";
export const CONFIRM_EMAIL_RESET = "CONFIRM_EMAIL_RESET";
export const CONFIRM_EMAIL_SUCCEEDED = "CONFIRM_EMAIL_SUCCEEDED";
export const CONFIRM_EMAIL_FAILED = "CONFIRM_EMAIL_FAILED";

export const CLEAR_LOGIN_ERROR_MESSAGES = "CLEAR_LOGIN_ERROR_MESSAGES";

export const UPDATE_USER_INFO_REQUESTED = "UPDATE_USER_INFO_REQUESTED";

export const SIGNIN_AND_CONFIRM_ACCOUNT_REQUESTED =
  "SIGNIN_AND_CONFIRM_ACCOUNT_REQUESTED";
export const SIGNIN_AND_CONFIRM_ACCOUNT_SUCCEEDED =
  "SIGNIN_AND_CONFIRM_ACCOUNT_SUCCEEDED";
export const SIGNIN_AND_RECONFIRM_ACCOUNT_SUCCEEDED =
  "SIGNIN_AND_RECONFIRM_ACCOUNT_SUCCEEDED";
export const SIGNIN_AND_CONFIRM_ACCOUNT_FAILED =
  "SIGNIN_AND_CONFIRM_ACCOUNT_FAILED";

/* Action Creators */
const loginRequested = () => ({ type: LOG_IN_REQUESTED, payload: null });
const loginSucceeded = (data) => ({ type: LOG_IN_SUCCEEDED, payload: data });
const loginFailed = (data) => ({ type: LOG_IN_FAILED, payload: data });
const clearLoginMessage = (data) => ({
  type: CLEAR_LOG_IN_MESSAGE,
  payload: null,
});

const loginAdminRequested = () => ({
  type: LOG_IN_ADMIN_REQUESTED,
  payload: null,
});
const loginAdminSucceeded = (data) => ({
  type: LOG_IN_ADMIN_SUCCEEDED,
  payload: data,
});
const loginAdminFailed = (data) => ({
  type: LOG_IN_ADMIN_FAILED,
  payload: data,
});
// const clearLoginAdminMessage = (data) => ({
//   type: CLEAR_LOG_IN_ADMIN_MESSAGE,
//   payload: null,
// });

const logoutSucceeded = () => ({ type: LOGOUT_SUCCEEDED, payload: null });

const socialUrlRequested = () => ({
  type: SOCIAL_URL_REQUESTED,
  payload: null,
});
const socialUrlSucceeded = (data) => ({
  type: SOCIAL_URL_SUCCEEDED,
  payload: data,
});
const socialUrlFailed = (data) => ({ type: SOCIAL_URL_FAILED, payload: data });

const socialLoginRequested = () => ({
  type: SOCIAL_LOG_IN_REQUESTED,
  payload: null,
});
const socialLoginSucceeded = (data) => ({
  type: SOCIAL_LOG_IN_SUCCEEDED,
  payload: data,
});
const socialLoginFailed = (data) => ({
  type: SOCIAL_LOG_IN_FAILED,
  payload: data,
});

const companyUpdatePasswordVerificationSucceeded = () => ({
  type: COMPANY_UPDATE_PASSWORD_VERIFICATION_SUCCEEDED,
});
const companyUpdatePasswordVerificationFailed = (err) => ({
  type: COMPANY_UPDATE_PASSWORD_VERIFICATION_FAILED,
  payload: err,
});
const adminInfoPasswordVerificationSucceeded = () => ({
  type: ADMIN_INFO_PASSWORD_VERIFICATION_SUCCEEDED,
});
const adminInfoPasswordVerificationFailed = (err) => ({
  type: ADMIN_INFO_PASSWORD_VERIFICATION_FAILED,
  payload: err,
});
const passwordVerificationReset = () => ({ type: PASSWORD_VERIFICATION_RESET });

const changePasswordRequest = () => ({ type: CHANGE_PASSWORD_REQUEST });
const changePasswordReset = () => ({ type: CHANGE_PASSWORD_RESET });
const changePasswordSucceeded = () => ({ type: CHANGE_PASSWORD_SUCCEEDED });
const changePasswordFailed = (err) => ({
  type: CHANGE_PASSWORD_FAILED,
  payload: err,
});

const requestUpdateAdminInfo = () => ({ type: UPDATE_ADMIN_INFO_REQUEST });
const requestResetUpdateAdminInfo = () => ({ type: UPDATE_ADMIN_INFO_RESET });
const receiveUpdateAdminInfo = (data) => ({
  type: UPDATE_ADMIN_INFO_SUCCESS,
  payload: data,
});
const receiveUpdateAdminInfoError = (error) => ({
  type: UPDATE_ADMIN_INFO_FAILURE,
  payload: error,
});

const confirmEmailRequest = () => ({ type: CONFIRM_EMAIL_REQUEST });
const confirmEmailReset = () => ({ type: CONFIRM_EMAIL_RESET });
const confirmEmailSucceeded = () => ({ type: CONFIRM_EMAIL_SUCCEEDED });
const confirmEmailFailed = (err) => ({
  type: CONFIRM_EMAIL_FAILED,
  payload: err,
});

const requestClearLoginErrorMessages = () => ({
  type: CLEAR_LOGIN_ERROR_MESSAGES,
});

const signinAndConfirmAccountRequested = () => ({
  type: SIGNIN_AND_CONFIRM_ACCOUNT_REQUESTED,
  payload: null,
});
const signinAndConfirmAccountSucceeded = (data) => ({
  type: SIGNIN_AND_CONFIRM_ACCOUNT_SUCCEEDED,
  payload: data,
});
const signinAndReConfirmAccountSucceeded = (data) => ({
  type: SIGNIN_AND_RECONFIRM_ACCOUNT_SUCCEEDED,
  payload: data,
});

const signinAndConfirmAccountFailed = (error) => ({
  type: SIGNIN_AND_CONFIRM_ACCOUNT_FAILED,
  payload: error,
});

/* Async Actions */
export function login(email, password, role, history, redirectPath) {
  return (dispatch) => {
    dispatch(loginRequested());

    authService
      .login(email, password, role)
      .then((res) => {
        dispatch(loginSucceeded(res));
        dispatch(resetRateRep());
        history.push(redirectPath);
      })
      .catch((err) => {
        dispatch(loginFailed(err));
      });
  };
}

export function getIframeLogin(obj, history, redirectPath) {
  return (dispatch) => {
    dispatch(loginRequested());
    authService
      .iframeLogin(obj)
      .then((res) => {
        dispatch(loginSucceeded(res));
        dispatch(resetRateRep());
        history.push(redirectPath);
      })
      .catch((err) => {
        dispatch(loginFailed(err));
      });
  };
}

export function loginAdmin(email, password, role, history, redirectPath) {
  return (dispatch) => {
    dispatch(loginAdminRequested());

    authService
      .adminLogin(email, password, role)
      .then((res) => {
        dispatch(loginAdminSucceeded(res));
        // dispatch(resetRateRep());
        history.push(redirectPath);
      })
      .catch((err) => {
        dispatch(loginAdminFailed(err));
      });
  };
}
export function clearLoginMessages() {
  return (dispatch) => {
    dispatch(clearLoginMessage());
  };
}

export function socialLogin(
  code,
  role,
  provider,
  history,
  invitationCompanyId
) {
  return (dispatch) => {
    dispatch(socialLoginRequested());
    const callback = `${config.LOCAL_ROOT_URL}/login`;

    authService
      .socialLogin(code, role, provider, callback, invitationCompanyId)
      .then((response) => {
        dispatch(socialLoginSucceeded(response));

        if (
          response.loginData &&
          response.loginData.userResponse &&
          response.loginData.userResponse.newConnected
        ) {
          history.push("/invitation-succeeded");
        } else {
          history.push(`/${constants.ROLES_REDIRECTION[role]}`);
        }
      })
      .catch((error) => {
        dispatch(socialLoginFailed(error));
        history.push(history.location.pathname);
      });
  };
}

export function getSocialUrl(providerName, userRole, callbackPath, options) {
  console.log("providerName-->>>>", providerName);
  console.log("userRole-->>>>", userRole);
  console.log("callbackPath-->>>>", callbackPath);
  console.log("options-->>>>", options);
  return (dispatch) => {
    dispatch(socialUrlRequested());
    const callback = `${config.LOCAL_ROOT_URL}${callbackPath}`;
    console.log(
      "callback data-->>>>",
      `${config.LOCAL_ROOT_URL}${callbackPath}`
    );

    authService
      .getSocialUrl(providerName, userRole, callback)
      .then((response) => {
        const responseUrl = (response && response.url) || null;

        if (responseUrl) {
          if (options && options.invitationCompanyId) {
            window.location = responseUrl.replace(
              "&state=",
              `&state=invitedCompanyId-${options.invitationCompanyId}_`
            );
          } else if (options && options.updating) {
            window.location = responseUrl.replace(
              "&state=",
              `&state=updating-${options.updating}_`
            );
          } else {
            window.location = responseUrl;
          }
        }
        dispatch(socialUrlSucceeded(responseUrl));
      })
      .catch((error) => {
        dispatch(socialUrlFailed(error));
      });
  };
}

export function logout(history) {
  return (dispatch) => {
    authService.logout();
    dispatch(logoutSucceeded());
    //history.push("/login");
  };
}

export function getCompanyUpdatePasswordVerification(email, password) {
  return (dispatch) => {
    dispatch(resetPasswordVerification());

    authService
      .getPasswordVerification(email, password)
      .then((res) => {
        dispatch(companyUpdatePasswordVerificationSucceeded());
      })
      .catch((err) => {
        dispatch(companyUpdatePasswordVerificationFailed(err));
      });
  };
}

export function getAdminInfoPasswordVerification(email, password) {
  return (dispatch) => {
    dispatch(resetPasswordVerification());
    authService
      .getPasswordVerification(email, password)
      .then((res) => {
        dispatch(adminInfoPasswordVerificationSucceeded());
      })
      .catch((err) => {
        dispatch(adminInfoPasswordVerificationFailed(err));
      });
  };
}

export function resetPasswordVerification() {
  return (dispatch) => {
    dispatch(passwordVerificationReset());
  };
}

export function changePassword(oldPassword, newPassword, token) {
  return (dispatch) => {
    dispatch(changePasswordRequest());
    authService
      .changePassword(oldPassword, newPassword, token)
      .then((res) => {
        dispatch(changePasswordSucceeded());
      })
      .catch((err) => {
        dispatch(changePasswordFailed(err));
      });
  };
}

export function resetPasswordChange() {
  return (dispatch) => {
    dispatch(changePasswordReset());
  };
}

export function updateAdminInfo(info) {
  return (dispatch) => {
    dispatch(requestUpdateAdminInfo());
    authService
      .updateAdminInfo(info)
      .then((res) => {
        authService.updateUserInfo(res.payload);
        dispatch(receiveUpdateAdminInfo(res.payload));
      })
      .catch((err) => {
        dispatch(receiveUpdateAdminInfoError(err));
      });
  };
}

export function initRequestUpdateAdminInfo() {
  return (dispatch) => {
    dispatch(requestUpdateAdminInfo());
  };
}

export function resetAdminInfo() {
  return (dispatch) => {
    dispatch(requestResetUpdateAdminInfo());
  };
}

export function clearLoginErrorMessages() {
  return (dispatch) => {
    dispatch(requestClearLoginErrorMessages());
  };
}

export function confirmEmail(username, password, confirmCode, role) {
  return (dispatch) => {
    dispatch(confirmEmailRequest());
    authService
      .confirmEmail(username, password, confirmCode, role)
      .then((res) => {
        dispatch(confirmEmailSucceeded());
      })
      .catch((err) => {
        dispatch(confirmEmailFailed(err));
      });
  };
}

export function resetConfirmEmail() {
  return (dispatch) => {
    dispatch(confirmEmailReset());
  };
}
export function resendConfirmAccount({
  password,
  username,
  role,
  history,
  redirectPath,
  social,
}) {
  return (dispatch) => {
    dispatch(signinAndConfirmAccountRequested());

    authService
      .resendAndConfirmAccount({ username, role, password, social })
      .then((response) => {
        dispatch(signinAndReConfirmAccountSucceeded(response));
      })
      .catch((error) => {
        dispatch(signinAndConfirmAccountFailed(error));
      });
  };
}

export function signinAndConfirmAccount({
  confirmCode,
  password,
  role,
  username,
  history,
  redirectPath,
}) {
  return (dispatch) => {
    dispatch(signinAndConfirmAccountRequested());

    authService
      .signinAndConfirmAccount({ confirmCode, password, role, username })
      .then((response) => {
        dispatch(signinAndConfirmAccountSucceeded(response));
        dispatch(loginSucceeded(response));
        dispatch(resetRateRep());
        history.push(redirectPath);
      })
      .catch((error) => {
        dispatch(signinAndConfirmAccountFailed(error));
      });
  };
}
