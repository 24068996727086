import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import _ from "lodash"

import { getJsonFromUrl } from "../../components/global/utils";

import { upVoteRepFeedCustomer, viewComapnyRep, viewRep, viewRepLink } from "../../actions/repActions";
import { upvote, clearUpvote } from "../../actions/customerActions";

import CustomerRepView from "./components/CustomerRepView";
import { Trends, TimeFilter } from "./constants"
import { getPreviousCompanies } from "../../actions/companyActions";
import constants from "../../constants";

const CONTACT_REP = "CONTACT_REP";
const CONTACT_REP_COMPANY = "CONTACT_REP_COMPANY";
var Previous_Companies = [];

const getContactOptions = (profile, company) => {
  if (_.isEmpty(profile)) {
    return []
  }
  const { firstName } = profile.payload;
  try {
    let connected = [];
    if (Previous_Companies.length > 0) {
      connected = Previous_Companies[0].connected
    }
    if (profile.payload.unclaimed) {
      return ([{
        id: CONTACT_REP,
        label: `Message ${firstName}`
      }]);
    }
    else {
      let companyVal = false;
      // company.forEach(element => {
      //   if (element.companyId == profile.payload.companyId && element.connected) {
      //     companyVal = true;
      //   }
      // });
      if(profile.payload.currentCompanyConnected){
        companyVal = true
      }
      if (companyVal) {
        return ([{ id: CONTACT_REP, label: `Message ${firstName}` },
        { id: CONTACT_REP_COMPANY, label: `Message ${firstName}'s Company` }]);
      } else {
        return ([{
          id: CONTACT_REP,
          label: `Message ${firstName}`
        }]);
      }
    }

  }
  catch (e) {
    console.log(e)
  }
}

class ViewRepCustomer extends React.Component {
  state = {
    id: "",
    redirectLink: "",
    showMessageOptions: false
  }

  componentDidUpdate() {
    Previous_Companies = this.props.previousCompanies;
    const { repProfile, authUser } = this.props;
    const rep = repProfile && repProfile.payload;
    const companyId = authUser && authUser.companyId;
    const repCompanyId = rep && rep.companyId;
    if (authUser) {
      if ((authUser.userRole === constants.USER_ROLES.COMPANY) &&
        ((companyId && repCompanyId && companyId !== repCompanyId) || repCompanyId === null)) {
        // Company ROLE and the REP is not tethered to the company
        this.props.history.push("/my-reps");
      }
    }
  }

  componentDidMount(state) {
    const { handleClearUpvoteRequest, authUser } = this.props;
    var queryParams = getJsonFromUrl(this.props.location.search);
    if (!!queryParams.id) {
      if (authUser) {
        if (authUser.userRole === constants.USER_ROLES.CUSTOMER) {
          this.props.handleRepProfile(queryParams.id, Trends[0], TimeFilter[0]);
        }
        else if (authUser.userRole === constants.USER_ROLES.ADMIN) {
          this.props.handleRepProfile(queryParams.id, Trends[0], TimeFilter[0]);
        }
        else if (authUser.userRole === constants.USER_ROLES.REP) {
          this.props.handleRepProfile(queryParams.id, Trends[0], TimeFilter[0]);
        }
        else {
          this.props.handlecompanyRepProfile(queryParams.id, Trends[0], TimeFilter[0], authUser.companyId)
        }
      } else {
        this.props.handleRepProfileLink(queryParams.id, Trends[0], TimeFilter[0]);
      }
      this.setState({ id: queryParams.id });
    }
    this.props.handlePreviousCompanies(queryParams.id);
    handleClearUpvoteRequest();

  }

  handleRateRepRedirect = () => {
    this.setState({ redirectLink: "/raterep?id=" + this.state.id });
  }

  handleContactOptionClick = (event, opt) => {
    if (opt.id === CONTACT_REP) {
      this.setState({ redirectLink: "/contactrep?id=" + this.state.id })
    }
    if (opt.id === CONTACT_REP_COMPANY) {
      this.setState({ redirectLink: "/contactcompany?id=" + this.state.id })
    }
  }

  render() {

    const { repProfile, previousCompanies } = this.props;
    if (this.state.redirectLink !== "") {
      return <Redirect push={true} to={this.state.redirectLink} />
    }

    return (
      <CustomerRepView
        {...this.props}
        {...this.state}
        contactOptions={getContactOptions(repProfile, previousCompanies)}
        handleContactOptionClick={this.handleContactOptionClick}
        handleRateRepRedirect={this.handleRateRepRedirect}
      />
    );
  }
}

const mapStateToProps = state => ({
  previousCompanies: state.company.previousCompanies,
  repCompanies: state.company.userCompanies,
  repProfile: state.rep.repProfile,
  isFetching: state.rep.isFetchingProfileRep,
  errorMessage: state.rep.errorMessage,
  authUser: state.auth.userData,
  trendsSelectedTimeFilter: state.rep.trendsTimeFilter,
  trendsSelectedMetric: state.rep.trendsMetric,
  trendsData: state.rep.filteredTrendsData,
  upvoteRequest: state.customer.upvoteRequest,
  rep: state.rep
});

const mapDispatchToProps = dispatch => ({
  handleRepProfile(id, defaultMetric, defaultTimeFilter) {
    dispatch(viewRep(id, defaultMetric, defaultTimeFilter));
  },
  handleRepProfileLink(id, defaultMetric, defaultTimeFilter) {
    dispatch(viewRepLink(id, defaultMetric, defaultTimeFilter));
  },

  handlecompanyRepProfile(id, defaultMetric, defaultTimeFilter, companyId) {
    dispatch(viewComapnyRep(id, defaultMetric, defaultTimeFilter, companyId));
  },
  handlePreviousCompanies(id) {
    // dispatch(getPreviousCompanies(id));
  },
  handleUpvote(feedbackId, vote, id) {
    dispatch(upvote(feedbackId, vote, id))
  },
  handleClearUpvoteRequest() {
    dispatch(clearUpvote());
  },
  handleupVoteRepFeedCustomer(obj, id) {
    dispatch(upVoteRepFeedCustomer(obj, id));
  }



});

export const Unwrapped = ViewRepCustomer;
export default connect(mapStateToProps, mapDispatchToProps)(ViewRepCustomer);
