import { 
  CONTACT_US_REQUESTED,
  CONTACT_US_SUCCEEDED,
  CONTACT_US_FAILED,
  CLOSE_CONTACT_US_REQUESTED
} from "../actions/notificationActions";

const initialState = {
  contactUsFetching: false,
  contactUsSucceeded: false,
  contactUsError: ""
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_US_REQUESTED: {
      return ({
        ...state,
        contactUsFetching: true
      });
    }
    case CONTACT_US_SUCCEEDED: {
      return ({
        ...state,
        contactUsFetching: false,
        contactUsSucceeded: true,
        contactUsError: ""
      });
    }
    case CONTACT_US_FAILED: {
      return ({
        ...state,
        contactUsFetching: false,
        contactUsSucceeded: false,
        contactUsError: action.payload
      });
    }
    case CLOSE_CONTACT_US_REQUESTED: {
      return ({
        ...state,
        contactUsFetching: false,
        contactUsSucceeded: false,
        contactUsError: ""
      });
    }
    default:
      return state;
  }
};

export default notificationReducer;
