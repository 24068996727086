import { MODAL_OPENED, MODAL_CLOSED } from "../actions/modalActions";

const modalReducer = (state = {
  content: null
}, action) => {
  switch (action.type) {
    case MODAL_OPENED:
      return Object.assign({}, state, { content: action.payload });
    case MODAL_CLOSED:
      return Object.assign({}, state, { content: null });
    default:
      return state;
  }
};

export default modalReducer;
