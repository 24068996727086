import React from "react";
import styled from "styled-components";

import {
  reporaColor,
  Body5,
  Header4,
  Header5,
  Header3,
} from "../../components/global/commonStyles";
import TabLinkMenu from "../../components/TabLinkMenu/TabLinkMenu";
import Footer from "../../components/FooterUpdated/Footer";

const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  padding-top: 51px;
  margin: 0 auto;
  position: relative;
  background-color: ${reporaColor.white};
`;

const Content = styled.div`
  width: 100%;
  max-width: 643px;
  margin: 0 auto;

  p {
    color: ${reporaColor.warmGrey};
    margin: 20px 0;
  }
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 41px;
`;

class PrivacyPolicy extends React.Component {
  render() {
    const menuLinks = [
      {
        name: "Privacy Policy",
        link: "/privacy",
      },
      {
        name: "Terms of Service",
        link: "/terms",
      },
    ];

    return (
      <Wrapper>
        <TabContainer>
          <TabLinkMenu links={menuLinks} />
        </TabContainer>

        <Content>
          <Header4>REPORA PRIVACY POLICY</Header4>
          <Header5>Last Updated: 9/1/23</Header5>
          <Body5>
            This Privacy Policy explains how Repora, Inc. (“Repora,” “we,” or
            “us”) collects, uses, and discloses information about you. This
            Privacy Policy applies when you use our websites, mobile
            applications, and other online products and services that link to
            this Privacy Policy (collectively, our “Services”), shop in our
            stores, contact our customer service team, engage with us on social
            media, or otherwise interact with us.
          </Body5>
          <Body5>
            We may change this Privacy Policy from time to time. If we make
            changes, we will notify you by revising the date at the top of this
            policy and, in some cases, we may provide you with additional notice
            (such as adding a statement to our website or sending you a
            notification). We encourage you to review this Privacy Policy
            regularly to stay informed about our information practices and the
            choices available to you.
          </Body5>
          <Header4>CONTENTS</Header4>
          <Body5 style={{ color: "rgb(11, 201, 246)" }}>
            Collection of Information<br></br>
            Use of Information<br></br>
            Sharing of Information<br></br>
            Analytics<br></br>
            Transfer of Information to the United States and Other Countries
            <br></br>
            Your Choices<br></br>
            Your California Privacy Rights<br></br>
            Contact Us<br></br>
          </Body5>
          <Header3>COLLECTION OF INFORMATION</Header3>
          <Header5>Information You Provide to Us</Header5>
          <Body5>
            We collect information you provide directly to us. For example, you
            share information directly with us when you create an account, fill
            out a form, submit or post content through our Services, make a
            purchase, communicate with us via third-party platforms, participate
            in a contest or promotion, request customer support, or otherwise
            communicate with us. The types of personal information we may
            collect include your name, email address, postal address, phone
            number, any other information you choose to provide, and with
            respect to Corporate Users (as defined in the Terms of Service),
            credit card and other payment information.
          </Body5>
          <Header4>
            Information We Collect Automatically When You Interact with Us
          </Header4>
          <Body5>
            When you access or use our Services or otherwise transact business
            with us, we automatically collect certain information, including:
          </Body5>
          <br></br>
          <ul style={{ listStyle: "disc" }}>
            <li>
              <span id="Header">Activity Information: </span>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                We collect information about your activity on our Services, such
                as information collected in connection with the reviews other
                individuals will leave, usage and browsing activity.
              </span>
            </li>
            <li>
              <span id="Header"> Transactional Information:</span>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                When a Corporate User makes a purchase, we collect information
                about the transaction, such as item purchased, purchase price,
                and the date and location of the transaction.
              </span>
            </li>
            <li>
              <span id="Header"> Device and Usage Information:</span>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                We collect information about how you access our Services,
                including data about the device and network you use, such as
                your hardware model, operating system version, mobile network,
                IP address, unique device identifiers, browser type, and app
                version. We also collect information about your activity on our
                Services, such as access times, pages viewed, links clicked, and
                the page you visited before navigating to our Services.
              </span>
            </li>
            <li>
              <span id="Header">Location Information:</span>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                In accordance with your device permissions, we may collect
                information about your location. You may stop the collection of
                precise location information at any time (see the Your Choices
                section below for details).
              </span>
            </li>
            <li>
              <span id="Header">
                Information Collected by Cookies and Similar Tracking
                Technologies:
              </span>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                We (and our service providers) use tracking technologies, such
                as cookies and web beacons, to collect information about you.
                Cookies are small data files stored on your hard drive or in
                device memory that help us improve our Services and your
                experience, see which areas and features of our Services are
                popular, and count visits. Web beacons (also known as “pixel
                tags” or “clear GIFs”) are electronic images that we use on our
                Services and in our emails to help deliver cookies, count
                visits, and understand usage and campaign effectiveness. For
                more information about cookies and how to disable them, see the
                Your Choices section below.
              </span>
            </li>
            <br></br>
          </ul>
          <Header4>Information We Collect from Other Sources</Header4>
          <Body5>
            If you log into Repora through a third-party platform authentication
            protocol (such as Facebook, Google or LinkedIn), we will have access
            to certain basic contact information from that platform, such as
            your name and profile picture, in accordance with the authorization
            procedures determined by such platform. This information is strictly
            used to authenticate your access to Repora, and to present a
            friendly interface. We do not sell your information to any 3rd
            party. If you have chosen to login to Repora using a social network,
            you may at any time create your own Repora login (email and
            password) and stop using the social network login method. Doing so
            stops the sharing of information. In addition, you may at any time
            request all data Repora had previously received from the social
            network to be deleted from our systems by emailing us at
            support@repora.co.
          </Body5>
          <Header4>Information We Derive</Header4>
          <Body5>
            We may derive information or draw inferences about you based on the
            information we collect. For example, we may make inferences about
            your location based on your IP address or infer that you are looking
            to purchase certain products based on your browsing behavior and
            past purchases.
          </Body5>
          <Header4>USE OF INFORMATION</Header4>
          <Body5>
            We use the information we collect to administer your account. We
            also use the information we collect to:
          </Body5>
          <br></br>
          <ul style={{ listStyle: "disc", color: "rgb(155, 155, 155)" }}>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                Provide, maintain, and improve our products and services;
              </span>
            </li>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                Process transactions and send you related information, including
                confirmations, receipts, invoices, customer experience surveys,
                and recall notices;
              </span>
            </li>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                Personalize and improve your experience on our Services;
              </span>
            </li>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                Send you technical notices, security alerts, and support and
                administrative messages;
              </span>
            </li>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                Respond to your comments and questions and provide customer
                service;
              </span>
            </li>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                Communicate with you about products, services, and events
                offered by Repora and provide news and information that we think
                will interest you (see the Your Choices section below for
                information about how to opt out of these communications at any
                time);
              </span>
            </li>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                Monitor and analyze trends, usage, and activities in connection
                with our Services;
              </span>
            </li>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                Detect, investigate, and prevent security incidents and other
                malicious, deceptive, fraudulent, or illegal activity and
                protect the rights and property of Repora and others;
              </span>
            </li>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                Debug to identify and repair errors in our Services;
              </span>
            </li>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                Comply with our legal and financial obligations; and
              </span>
            </li>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                Carry out any other purpose described to you at the time the
                information was collected.
              </span>
            </li>
            <br></br>
          </ul>
          <Header4>SHARING OF INFORMATION</Header4>
          <Body5>
            We share personal information in the following circumstances or as
            otherwise described in this policy:
          </Body5>
          <ul style={{ listStyle: "disc", color: "rgb(155, 155, 155)" }}>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                We share personal information with vendors, service providers,
                and consultants that need access to personal information in
                order to perform services for us, such as companies that assist
                us with web hosting, shipping and delivery, payment processing,
                fraud prevention, customer service, and marketing and
                advertising.
              </span>
            </li>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                If you submit a product review or post content in another public
                area of our Services, we share this information publicly on our
                Services.
              </span>
            </li>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                We may disclose personal information if we believe that
                disclosure is in accordance with, or required by, any applicable
                law or legal process, including lawful requests by public
                authorities to meet national security or law enforcement
                requirements.
              </span>
            </li>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                We may share personal information if we believe that your
                actions are inconsistent with our user agreements or policies,
                if we believe that you have violated the law, or if we believe
                it is necessary to protect the rights, property, and safety of
                Repora, our users, the public, or others.
              </span>
            </li>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                We share personal information with our lawyers and other
                professional advisors where necessary to obtain advice or
                otherwise protect and manage our business interests.
              </span>
            </li>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                We may share personal information in connection with, or during
                negotiations concerning, any merger, sale of company assets,
                financing, or acquisition of all or a portion of our business by
                another company.
              </span>
            </li>
            <li>
              <span style={{ fontSize: "13px", color: "rgb(155, 155, 155)" }}>
                We share personal information with your consent or at your
                direction.
              </span>
            </li>
          </ul>
          <Body5>
            We also share aggregated or de-identified information that cannot
            reasonably be used to identify you
          </Body5>
          <Header4>ANALYTICS </Header4>
          <Body5>
            We allow others to provide analytics services on our behalf across
            the web and in mobile apps. These entities may use cookies, web
            beacons, device identifiers, and other technologies to collect
            information about your use of our Services and other websites and
            applications, including your IP address, web browser, mobile network
            information, pages viewed, time spent on pages or in mobile apps,
            links clicked, and conversion information. This information may be
            used by Repora and others to, among other things, analyze and track
            data, determine the popularity of certain content, and better
            understand your online activity.
          </Body5>
          <Header4>
            TRANSFER OF INFORMATION TO THE UNITED STATES AND OTHER COUNTRIES
          </Header4>
          <Body5>
            Repora is headquartered in the United States, and we have operations
            and service providers in the United States and other countries.
            Therefore, we and our service providers may transfer your personal
            information to, or store or access it in, jurisdictions that may not
            provide levels of data protection that are equivalent to those of
            your home jurisdiction. We will take steps to ensure that your
            personal information receives an adequate level of protection in the
            jurisdictions in which we process it.
          </Body5>
          <Header4>YOUR CHOICES</Header4>
          <Header5>Account Information</Header5>
          <Body5>
            You may update and correct certain account information at any time
            by logging into your account.
          </Body5>
          <Header5>Cookies</Header5>
          <Body5>
            Most web browsers are set to accept cookies by default. If you
            prefer, you can usually adjust your browser settings to remove or
            reject browser cookies. Please note that removing or rejecting
            cookies could affect the availability and functionality of our
            Services.
          </Body5>
          <Header5>Communications Preferences</Header5>
          <Body5>
            You may opt out of receiving promotional emails from Repora by
            following the instructions in those communications. If you opt out,
            we may still send you non-promotional emails, such as those about
            your account or our ongoing business relations.
          </Body5>
          <Header4>CONTACT US</Header4>
          <Body5>
            If you have any questions about this Privacy Policy, please contact
            us at support@repora.co.
          </Body5>
          <br></br>
        </Content>
        <Footer />
      </Wrapper>
    );
  }
}

export default PrivacyPolicy;
