import React from "react";
import { connect } from "react-redux";
import styled ,{ css } from "styled-components";
import { Body1, Body2, Body3, Body4, Body5, Header4, reporaColor, reporaDevice ,FormGroup } from "../../../components/global/commonStyles";
import RatingStars from "../../../components/RatingStars/RatingStars";
import DynamicRating from "../../../components/Dynamic/DynamicRating";
import SearchIcon from "../../Admin/manageDispute/assets/search.svg";
import LeftArrow from "../../Admin/manageDispute/assets/left-arrow.svg";
import RightArrow from "../../Admin/manageDispute/assets/right-arrow.svg";
import LikeDislikeOption from "../../../components/LikeDislikeOption/LikeDislikeOption";
import { NavLink } from "react-router-dom";
import InputSuggestions from "../../SearchRep/components/InputSuggestions";
import { getCompanySuggestions  } from "../../../actions/companyActions";
import { handleGetCompanyFeedbacks , getCustomerSuggestions , getRepFeedbacks} from "../../../actions/adminActions";
import { getRepSuggestions  } from "../../../actions/repActions";
import PropTypes from "prop-types";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import { isEmpty } from "lodash";
import RepService from "../../../services/RepService";


export const Wrapper = styled.div`
  margin: 3%;
  .Navlink{
    alignItems: end;
    display: flex;
  }
  @media screen and (max-width:768px){
    margin-top:70px;
  }
`;
const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 40px;
  .headerDropDown{
    width:170px !important;
  }
  input{
    padding: 4px;
  }
`;

const WrapperSection = styled.div`
  border-bottom: 1px solid lightgrey;
  display: flex; 
  .searchInput{
    display: flex;
    align-items: center;
    justify-content:space-around;
    max-width: 264px;
    margin : 10px 0px;
  }
  @media screen and (max-width:800px){
    display: block;
    div{
    justify-content:none;
    }
  }
`;

const ResultWrapper = styled.div`
  margin : 15px 10px;
`;

const FeedBackCard = styled.div`
  display: inline-flex;
  width: 100%;
  padding: 20px 10px;

  @media screen and (max-width:641px){
    display: block;
  }
`;

const CustomerCard = styled.div`
  margin-right: 30px;
`;

const RateRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;

  border-top: 1px solid ${reporaColor.grey91};
  width: 350px;

  ${(props) =>
    props.last &&
    css`
      border-bottom: 1px solid ${reporaColor.grey91};
    `}

  @media ${reporaDevice.xs} {
    flex-direction: column;
    align-items: center;
  }
`;

const RatingCard = styled.div`
  max-width: 350px;
`;

const CommentSection = styled.div`
  margin: 25px 50px;
  @media screen and (max-width:810px){
    margin: 0px;
  }
`;

const FeedBackData = styled.div`
  display: flex;
  @media screen and (max-width:810px){
    display:block;
  }
`;

const PNButtons =styled.div`
  margin-left: 10px;
  justify-content: flex-end;
  display: flex;
  img:hover {
    background-color: ${reporaColor.grey87};
    cursor: pointer
  }

  .arrow{
    width:30px
    margin: 5px;
  }
`;

const FormWrapper = styled.form`
  display: flex;
  justify-content: center;
  width: 100%;

  > div {
    max-width: 330px;
    width: 100%;
    margin-right: 15px;
  }

  @media ${reporaDevice.xs} {
    flex-direction: column;
    align-items: center;

    > div {
      width: 100%;
      margin: 0;
      margin-top: 15px;
    }

    button {
      margin: 3%;
    }
  }
`;



class CustomerReviews extends React.Component {
  state = {
    selectedCompany:null,
    companySuggestions:[],

    selectedCustomer:null,
    customerSuggestions:[],

    selectedRep:null,
    repSuggestions:[],

    feedbackIndex : 0,
    companyFeedBacks:[],
  }

  componentDidMount(){
    // this.props.GetCompanyFeedbacks({})
  }

  componentDidUpdate(prevProps , prevState) {
    const { companySuggestions ,companyFeedBacks , customerSuggestions , repSuggestions } = this.props;
    const  {selectedCompany ,selectedCustomer } = this.state;

    if (companySuggestions !== prevProps.companySuggestions) {
      this.setState({
        companySuggestions: companySuggestions.map((suggestion) => {
          const industry = suggestion.industry
            ? `${suggestion.industry} - `
            : "";
          const address =
            suggestion.physicalAddress &&
            suggestion.physicalAddress.state &&
            suggestion.physicalAddress.city
              ? `${suggestion.physicalAddress.state}, ${
                  suggestion.physicalAddress.city
                }`
              : "-";

          return {
            title: suggestion.name,
            subtitle: industry + address,
            picture: suggestion.logo,
            companyId: suggestion.id,
          };
        }),
      });
    }

    if (customerSuggestions !== prevProps.customerSuggestions) {
      this.setState({
        customerSuggestions: customerSuggestions.map((suggestion) => {
          return {
            title: suggestion.fullName,
            subtitle: suggestion.assignedRole ,
            picture: suggestion.logo,
            customerId: suggestion.id,
          };
        }),
      });
    }

    if(companyFeedBacks !== prevProps.companyFeedBacks){
      let sortedFeedbacks = companyFeedBacks.sort((a , b) => {
        return moment(b.createdDate).diff(moment(a.createdDate));
      })
      if(!isEmpty(this.state.selectedRep)){
        let feedBackList = []
          if(!isEmpty(selectedCustomer) && !isEmpty(selectedCompany)){
            feedBackList = sortedFeedbacks.filter((i)=>{
              if(i.company === selectedCompany?.companyId && i.reviewerId === selectedCustomer?.customerId){
                return i
              }
            })
            this.setState({ companyFeedBacks : feedBackList , feedbackIndex : 0 })
          }
          else if(!isEmpty(selectedCustomer) || !isEmpty(selectedCompany)){
            feedBackList = sortedFeedbacks.filter((i)=>{
              if(i.company === selectedCompany?.companyId || i.reviewerId === selectedCustomer?.customerId){
                return i
              }
            })
            this.setState({ companyFeedBacks : feedBackList , feedbackIndex : 0 })
          }
          else{
              this.setState({ companyFeedBacks : sortedFeedbacks, feedbackIndex : 0 })
          }
      }else{
        this.setState({ feedbackIndex : 0 , companyFeedBacks : sortedFeedbacks })
      }
    }

    if(this.state.companyFeedBacks !== prevState.companyFeedBacks){
      this.setState({ feedbackIndex : 0})
    }

    if (repSuggestions !== prevProps.repSuggestions) {
      this.setState({
        repSuggestions: repSuggestions.map((suggestion) => ({
          title: suggestion.name,
          subtitle: suggestion.companyName || "-",
          picture: suggestion.image,
          repId: suggestion.id,
        })),
      });
    }
  }

  handleInputCompanyChange = (value) => {
    this.setState({
      selectedCompany: null
    });
  }

  handleInputCustomerChange = (value) => {
    this.setState({
      selectedCustomer: null
    });
  }

  handleInputRepChange = (value) => {
    this.setState({
      selectedRep: null
    });
  }

  handleSelectCompany = (selectedEl) => {
    this.setState({
      selectedCompany: selectedEl
    });
  };
  
  handleSelectRep = (selectedEl) => {
    this.setState({
      selectedRep: selectedEl
    });
  };


  handleSelectCustomer = (selectedEl) => {
    this.setState({
      selectedCustomer: selectedEl
    });
  };

  handleGetCompanySuggestions = (inputValue) => {
    this.props.handleGetCompanySuggestions(inputValue ,this.props.userData.userRole);
  };

  handleGetCustomerSuggestions = (inputValue) => {
    this.props.handleGetCustomerSuggestions(inputValue ,this.props.userData.userRole);
  };

  handleGetRepSuggestions = (inputValue) => {
    this.props.handleGetRepSuggestions(inputValue ,this.props.userData.userRole);
  };

  handleGetCompanyFeedBacks = () => {
    if(!isEmpty(this.state.selectedRep)){
      this.props.handleGetRepFeedbacks(this.state.selectedRep?.repId)
    }else{
      if(!isEmpty(this.state.selectedCompany) && !isEmpty(this.state.selectedCustomer)){
        this.props.GetCompanyFeedbacks({ id:this.state.selectedCompany?.companyId , reviewerId : this.state.selectedCustomer?.customerId})
      }
      else if(this.state.selectedCompany?.companyId ){
      this.props.GetCompanyFeedbacks({id:this.state.selectedCompany?.companyId})
      }
      else if(this.state.selectedCustomer?.customerId){
        this.props.GetCompanyFeedbacks({ id:"" , reviewerId : this.state.selectedCustomer?.customerId })
      }
    }
  }

  handleArrowClick = (e) => { 
    const { feedbackIndex , companyFeedBacks } = this.state
    if(e.target.name === "increment"){
      if(feedbackIndex+1 < companyFeedBacks.length){
        return this.setState({ feedbackIndex : feedbackIndex + 1 })
      }
    }else if(e.target.name === "decrement"){
      if(feedbackIndex - 1 >= 0){
        return this.setState({ feedbackIndex : feedbackIndex - 1 })}}
  }

  render() {
    const { selectedCompany , selectedCustomer, selectedRep , repSuggestions , companyFeedBacks , companySuggestions , customerSuggestions , feedbackIndex} = this.state;
    const { fetchingCompanyFeedbacks} = this.props;
    return (
      <Wrapper>
        <NavLink className='Navlink' to = "/admin/dashboard">Back to Admin Panel</NavLink><br/>
        <Header4>View Customer Reviews</Header4>
          <WrapperSection>
            <FormGroup className="searchInput">
              <Body5>Company : </Body5>
                <InputSuggestions
                  name="new-company-suggestions"
                  suggestions={companySuggestions}
                  placeholder="Company"
                  loading={this.props.fetchingCompanySuggestions}
                  handleSelectElement={this.handleSelectCompany}
                  selectedElement={this.state.selectedCompany}
                  handleInputChange={this.handleInputCompanyChange}
                  inputDelay={500}
                  handleGetSuggestions={this.handleGetCompanySuggestions}
                  maxWidth = "300px"
                  inputHeight = "30px"
                  suggestionWidth = "215px"
                  fromAdmin
                />
            </FormGroup>
            <FormGroup className="searchInput">
              <Body5>Customer : </Body5>
                <InputSuggestions
                  name="new-company-suggestions"
                  suggestions={customerSuggestions}
                  placeholder="Customer"
                  loading={this.props.fetchingCustomerSuggestions}
                  handleSelectElement={this.handleSelectCustomer}
                  selectedElement={this.state.selectedCustomer}
                  handleInputChange={this.handleInputCustomerChange}
                  inputDelay={500}
                  handleGetSuggestions={this.handleGetCustomerSuggestions}
                  maxWidth = "300px"
                  inputHeight = "30px"
                  suggestionWidth = "215px"
                  fromAdmin
                />
            </FormGroup>
            <FormGroup style={{maxWidth: "335px"}} className="searchInput">
              <Body5>Rep : </Body5>
                <InputSuggestions
                  name="new-rep-suggestions"
                  suggestions={repSuggestions}
                  placeholder="Rep"
                  loading={this.props.repSuggestionsFetching}
                  handleSelectElement={this.handleSelectRep}
                  selectedElement={this.state.selectedRep}
                  handleInputChange={this.handleInputRepChange}
                  inputDelay={500}
                  handleGetSuggestions={this.handleGetRepSuggestions}
                  maxWidth = "300px"
                  inputHeight = "30px"
                  suggestionWidth = "215px"
                  fromAdmin
                />
                <img style={{backgroundColor:`${reporaColor.aquaBlue}`, padding:"5px",cursor:"pointer"}} 
                onClick={this.handleGetCompanyFeedBacks} src={SearchIcon} alt="Search Icon"/>
            </FormGroup>
          </WrapperSection>
          {fetchingCompanyFeedbacks && <Loader overlayed size="medium" />}
          {companyFeedBacks && companyFeedBacks?.length !== 0 &&
          <div>
          <ResultWrapper>
            <div style={{display:"flex" , alignItems: "center"}}>
            <Body3>Result ({feedbackIndex+1} of {companyFeedBacks.length})</Body3>
            {companyFeedBacks && companyFeedBacks?.length > 1 &&
            <PNButtons>
              <img className="arrow" name="decrement" onClick={(e)=> this.handleArrowClick(e) }src={LeftArrow} alt="leftarrow"/>
              <img  className="arrow" name="increment" onClick={(e)=> this.handleArrowClick(e)} src={RightArrow} alt="rightarrow"/>
            </PNButtons>}
            </div>
              <FeedBackCard>
                  <CustomerCard>
                    <Body4>Customer : {companyFeedBacks[feedbackIndex]?.customerName || companyFeedBacks[feedbackIndex]?.name || 'Anonymous'}</Body4>
                  </CustomerCard>
                  <CustomerCard>
                    <Body4>Customer Email : {companyFeedBacks[feedbackIndex]?.customerEmail}</Body4>
                  </CustomerCard>
                  <CustomerCard>
                    {!selectedRep &&
                    <Body4>Rep : {companyFeedBacks[feedbackIndex]?.repName} {companyFeedBacks[feedbackIndex]?.tempRep && "Temp"}</Body4>}
                  </CustomerCard>
                  <CustomerCard>
                    <Body4>Date : {companyFeedBacks[feedbackIndex]?.createdDate &&  moment(companyFeedBacks[feedbackIndex].createdDate).format("MM/DD/YYYY")}</Body4>
                  </CustomerCard>
              </FeedBackCard>
          </ResultWrapper>
          <FeedBackData>
            <RatingCard>
            { companyFeedBacks && companyFeedBacks[feedbackIndex]?.ratingCategory.map((rate) => {
                const ratingAttr = DynamicRating(rate.type);
                return (
                  <RateRow  key={ratingAttr.key}>
                    <Body4>{ratingAttr.value}</Body4>
                    <RatingStars
                      name={ratingAttr.key}
                      rating={rate.value}
                      disabled = {true}
                      static = {true}
                    />
                  </RateRow>
                );
              })
            }
            <RateRow last>
              <Body4>How was your overall experience?</Body4>
              <LikeDislikeOption
                name="experience"
                experince= {companyFeedBacks[feedbackIndex]?.experience}
                disabled={true}
                static = {true}
              />
            </RateRow>
            { companyFeedBacks && companyFeedBacks[feedbackIndex]?.customRatingCategory?.map((rate) => {
                const ratingAttr = DynamicRating(rate.type);
                return (
                  <RateRow  key={ratingAttr.key}>
                    <Body4>{rate.type}</Body4>
                    <RatingStars
                      name={ratingAttr.key}
                      rating={rate.value}
                      disabled = {true}
                      static = {true}
                    />
                  </RateRow>
                );
              })
            }
            </RatingCard>
            <CommentSection>
            <Body5>Customer Comment</Body5>
            <textarea disabled rows="10" cols="50" type="text" value={companyFeedBacks[feedbackIndex]?.comment} />
          </CommentSection>
          </FeedBackData>
          </div>}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  userData : state.auth.userData,
  companySuggestions : state.company.companySuggestions,
  fetchingCompanySuggestions: state.company.companySuggestionsFetching,
  companyFeedBacks : state.admin.companyFeedBacks,
  fetchingCompanyFeedbacks : state.admin.fetchingCompanyFeedbacks,

  repSuggestions: state.rep.repSuggestions,
  repSuggestionsFetching: state.rep.repSuggestionsFetching,
  repSuggestionsError: state.rep.repSuggestionsError,

  customerSuggestions : state.admin.customerSuggestions,
  fetchingCustomerSuggestions: state.admin.fetchingCustomerSuggestions,

  companyFeedbackError : state.admin.companyFeedbackError,
  userData: state.auth.userData
});

const mapDispatchToProps = (dispatch) => ({
  handleGetCompanySuggestions(companyName , userRole) {
    dispatch(getCompanySuggestions(companyName , userRole));
  },
  handleGetCustomerSuggestions(customerName , userRole) {
    dispatch(getCustomerSuggestions(customerName , userRole));
  },
  handleGetRepSuggestions(repName) {
    dispatch(getRepSuggestions(repName));
  },
  handleGetRepFeedbacks(repId){
    dispatch(getRepFeedbacks(repId));
  },
  GetCompanyFeedbacks(id){
    dispatch(handleGetCompanyFeedbacks(id))
  }
});

CustomerReviews.propTypes = {
  companySuggestions: PropTypes.array,
  fetchingCompanySuggestions: PropTypes.bool,
};

CustomerReviews.defaultProps = {
  companySuggestions: [],
  customerSuggestions: [],
  repSuggestions: []
};

export const Unwrapped = CustomerReviews;
export default connect(mapStateToProps, mapDispatchToProps)(CustomerReviews);