import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import queryString from "query-string";
import base64 from "base-64";

import { reporaDevice } from "../../../components/global/commonStyles";
import InputSuggestions from "./InputSuggestions";

const FormWrapper = styled.form`
  display: flex;
  justify-content: center;
  width: 100%;

  > div {
    max-width: 330px;
    width: 100%;
  }

  @media ${reporaDevice.xs} {
    flex-direction: column;
    align-items: center;

    > div {
      width: 100%;
      margin: 0;
      margin-top: 15px;
    }
  }
`;

class CompanyRepSearchForm extends React.Component {
  state = {
    selectedRep: null,
    repInputValue: this.props.repSearch.repSearch,
    repSuggestions: [],
  };

  componentDidUpdate(prevProps) {
    const { repSuggestions } = this.props;

    if (repSuggestions !== prevProps.repSuggestions) {
      this.setState({
        repSuggestions: repSuggestions.map((suggestion) => ({
          title: suggestion.name,
          subtitle: suggestion.companyName || "-",
          picture: suggestion.image || suggestion.picture,
          repId: suggestion.id,
        })),
      });
    }
  }

  handleSelectRep = (selectedEl) => {
    this.setState(
      {
        selectedRep: selectedEl,
        repInputValue: selectedEl ? selectedEl.title : "",
      },
      () => this.handleSearch()
    );
    // const _selectedEl = selectedEl
    //   ? this.props.handlesetRepSearch(selectedEl.title)
    //   : "";
  };

  handleInputRepChange = (value) => {
    this.props.handlesetRepSearch(value);
    this.setState({
      selectedRep: null,
      repInputValue: this.props.repSearch,
    });
  };

  handleSearch = (event) => {
    if (event) {
      event.preventDefault();
    }

    const { selectedRep, repInputValue } = this.state;
    const companyId = this.props.user.companyId;

    if (selectedRep || repInputValue) {
      const url = queryString.stringify({
        repName: repInputValue || undefined,
        repId: selectedRep ? selectedRep.repId : undefined,
        companyName: undefined,
        companyId: companyId,
      });

      const encodedUrl = base64.encode(url);
      this.props.history.push(`/searchrep/results/${encodedUrl}`);
      this.props.clearRepSelectionData();
    }
  };

  handleGetRepSuggestions = (inputValue) => {
    const companyId = this.props.user.companyId;
    this.props.handleGetCompanyRepSuggestions(inputValue, companyId);
  };

  render() {
    const { user, repSearch } = this.props;
    const { repSuggestions } = this.state;
    return (
      <FormWrapper onSubmit={this.handleSearch}>
        <InputSuggestions
          name="new-rep-suggestions"
          suggestions={repSuggestions}
          placeholder={`Search reps`}
          loading={this.props.repSuggestionsFetching}
          handleSelectElement={this.handleSelectRep}
          selectedElement={this.state.selectedRep}
          repSearchVal={repSearch}
          handleInputChange={this.handleInputRepChange}
          inputDelay={this.props.inputDelay}
          handleGetSuggestions={this.handleGetRepSuggestions}
          companySearch={true}
          handlesetRepSearch={this.props.handlesetRepSearch}
          searchIcon
        />
      </FormWrapper>
    );
  }
}

CompanyRepSearchForm.propTypes = {
  repSuggestions: PropTypes.array,
  repSuggestionsFetching: PropTypes.bool,
  repSuggestionsError: PropTypes.string,
};

CompanyRepSearchForm.defaultProps = {
  repSuggestions: [],
};

export default withRouter(CompanyRepSearchForm);
