import React from "react";
import styled, { css } from "styled-components";
import { reporaColor } from "../../components/global/commonStyles";
import PropTypes from "prop-types";
 

const StyledButton = styled.button`
  background-color: ${reporaColor.brightSkyBlue};
  color: ${reporaColor.white};
  font-size:  ${props => props.fontSize || "18px"};
  width: ${props => props.width || "auto"};
  min-width: ${props => props.minwidth || "100px"};
  height:${props => props.height || "40px"};
  font-family: "Nexa Bold";
  text-align: center;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border: solid 1px transparent;
  text-decoration:none;
  padding: 0 15px;

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  ${props => props.size === "xsmall" && css`
    height: 22px;
    font-size: 12px;
    min-width: auto;
  `}

  ${props => props.size === "small" && css`
    height: 36px;
    font-size: 14px;
  `}

  ${props => props.size === "large" && css`
    max-width: 287px;
    width: 100%;
  `}

  ${props => props.color === "white" && css`
    background-color: ${reporaColor.white};
    color: ${reporaColor.warmGrey};
    border: solid 1px ${reporaColor.grey87};
  `}

  ${props => props.color === "green" && css`
    background-color: ${reporaColor.greenBlue};
    color: ${reporaColor.white};
    border: solid 1px ${reporaColor.greenBlue};
  `}

  ${props => props.color === "red" && css`
    background-color: ${reporaColor.watermelon};
    color: ${reporaColor.white};
    border: solid 1px ${reporaColor.watermelon};
  `}

  ${props => props.color === "monocromatic" && css`
    background-color: ${reporaColor.white};
    color: ${reporaColor.black};
  `}
`;

class Button extends React.Component {
  render() {
    return (
      <StyledButton
        width={this.props.width}
         type={this.props.type || "button"}
        className={this.props.className}
        size={this.props.size}
        height={this.props.height}
        color={this.props.color}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        minwidth={this.props.minwidth}
        onFocus={this.props.onFocus}
        fontSize={this.props.fontSize}
        onBlur={this.props.onBlur} >
        {this.props.children}
      </StyledButton>
    );
  }
}

Button.propTypes = {
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(["xsmall", "small", "large"]),
  color: PropTypes.oneOf(["green", "white", "red", "monocromatic"]),
  width: PropTypes.string
};

export default Button;
