import { isEmpty, isEqual } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import {
  getTeamData,
  handleSubmitRating,
  resetRateTeam,
} from "../../actions/companyActions";
import { requestAttrList } from "../../actions/reportingPreferencesAction";
import View from "./components/View";

class RateTeam extends React.Component {
  state = {
    redirectLink: "",
    teamData: null,
    reviewRequestedBy: "",
  };

  componentDidMount() {
    const { handleResetRateTeam, location } = this.props;
    let data = location.search.split("/");

    const teamId = data[0].split("=")[1];
    const reviewRequestedById = data[1].split("=")[1];
    this.setState({ reviewRequestedBy: reviewRequestedById });
    this.props.handlegetteamdata(teamId);
    handleResetRateTeam();
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(this.props.teamData, prevProps.teamData) &&
      this.props.teamData.company
    ) {
      this.setState({ teamData: this.props.teamData });
      if (!isEmpty(this.props.teamData)) {
        this.props.handleGetCustomeAttr({
          companyId: this.props.teamData.company.id,
          companyFeedbackCategory: "TEAM",
        });
      }
    }
  }

  handleRateDone = () => {
    const { handleResetRateTeam } = this.props;
    handleResetRateTeam();
    this.setState({ redirectLink: "/home" });
  };

  render() {
    if (this.state.redirectLink !== "") {
      return <Redirect push={true} to={this.state.redirectLink} />;
    }
    let userdataExist = false;
    if (this.props.userData) {
      userdataExist = true;
    }
    return (
      <View
        {...this.props}
        teamData={this.state.teamData}
        handleRateDone={this.handleRateDone}
        reviewRequestedBy={this.state.reviewRequestedBy}
        userdataExist={userdataExist}
        userData={this.props.userData}
        spinner={this.props.isAtributesLoading}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  teamRateResponse: state.company.rateResponse,
  teamRateResponseReturned: state.company.rateResponseReady,
  teamDataFetching: state.company.fetchingTeamData,
  isFetchingRateTeam: state.company.isFetchingRateTeam,
  teamData: state.company.teamData,
  attributesList: state.reportingPreferences.attributesList,
  customeAttributes: state.company.getcompanyCustomeAttributes,
  customeAttributesFetching: state.company.getCompanyCustomeAttributesFetching,
  companylogBlob: state.company.ratingPageCompanyLogo,
  isAtributesLoading: state.reportingPreferences.isAtributesLoading,
});

const mapDispatchToProps = (dispatch) => ({
  handleResetRateTeam() {
    dispatch(resetRateTeam());
  },
  handlegetteamdata(Id) {
    dispatch(getTeamData(Id));
  },
  handleSubmitRating(obj) {
    dispatch(handleSubmitRating(obj));
  },
  handleGetCustomeAttr(id) {
    dispatch(requestAttrList(id));
  },
});

export const Unwrapped = RateTeam;
export default connect(mapStateToProps, mapDispatchToProps)(RateTeam);
