import constants from "../../constants";

export default {
  VIEW_REP_PERSONAL: {
    path: "viewreppersonal_2",
    loader: () => import("../../scenes/ViewRep/ViewRepPersonal"),
    invalidRoles: [constants.USER_ROLES.CUSTOMER],
  },
  VIEW_REP_PERSONAL_2: {
    path: "viewreppersonal",
    loader: () => import("../../scenes/ViewRep/ViewRepPersonal_Improved"),
    invalidRoles: [constants.USER_ROLES.CUSTOMER],
  },
  VIEW_REP_CUSTOMER: {
    path: "viewrepcustomer",
    loader: () => import("../../scenes/ViewRep/ViewRepCustomer"),
    invalidRoles: [],
  },
  VIEW_REP_COMPANY: {
    path: "viewrepcompany",
    loader: () => import("../../scenes/ViewRep/ViewRepCompany"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER],
  },
  VIEW_COMPANY_PROGRAM: {
    path: "viewprogram",
    loader: () => import("../../scenes/ViewProgram/ViewCompanyProgram"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER],
  },
  VIEW_COMPANY_TEAM: {
    path: "viewteam",
    loader: () => import("../../scenes/ViewTeam/ViewCompanyTeam"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER],
  },
  RATE_REP_CUSTOMER: {
    path: "raterep",
    loader: () => import("../../scenes/RateRep/RateRep"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.COMPANY],
  },
  RATE_PROGRAM_CUSTOMER: {
    path: "rateProgram",
    loader: () => import("../../scenes/RateProgram/RateProgram"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.COMPANY],
  },
  ADMIN_Dispute: {
    path: "admin/dispute",
    loader: () => import("../../scenes/Admin/manageDispute/dispute"),
    invalidRoles: [
      constants.USER_ROLES.REP,
      constants.USER_ROLES.COMPANY,
      constants.USER_ROLES.CUSTOMER,
    ],
  },
  ADMIN_STATS: {
    path: "admin/userStats",
    loader: () => import("../../scenes/Admin/manageDispute/Admin_UserStats"),
    invalidRoles: [
      constants.USER_ROLES.REP,
      constants.USER_ROLES.COMPANY,
      constants.USER_ROLES.CUSTOMER,
    ],
  },
  ADMIN_CUSTOMER_REVIEWS: {
    path: "admin/reviews",
    loader: () => import("../../scenes/Admin/manageDispute/CustomerReviews"),
    invalidRoles: [
      constants.USER_ROLES.REP,
      constants.USER_ROLES.COMPANY,
      constants.USER_ROLES.CUSTOMER,
    ],
  },
  ADMIN_CUSTOMER_CLAIMS: {
    path: "admin/claims",
    loader: () => import("../../scenes/Admin/manageDispute/ManageClaims"),
    invalidRoles: [
      constants.USER_ROLES.REP,
      constants.USER_ROLES.COMPANY,
      constants.USER_ROLES.CUSTOMER,
    ],
  },
  ADMIN_Dashboard: {
    path: "admin/dashboard",
    loader: () => import("../../scenes/Admin/dashboard/pannel"),
    invalidRoles: [
      constants.USER_ROLES.REP,
      constants.USER_ROLES.COMPANY,
      constants.USER_ROLES.CUSTOMER,
    ],
  },
  CONTACT_COMPANY: {
    path: "contactcompany",
    loader: () => import("../../scenes/ContactCompany/ContactCompany"),
    invalidRoles: [],
  },
  CONTACT_REP: {
    path: "contactrep",
    loader: () => import("../../scenes/ContactRep/ContactRep"),
    invalidRoles: [],
  },
  SEARCH_REP_CUSTOMER: {
    path: "searchrep",
    loader: () => import("../../scenes/SearchRep/SearchRep"),
    invalidRoles: [],
    strict: true,
  },
  SEARCH_REP_RESULTS: {
    path: "searchrep/results/:query",
    exact: false,
    loader: () => import("../../scenes/SearchRep/SearchRepResults"),
    invalidRoles: [],
  },
  SEARCH_PAST_RATINGS_CUSTOMER: {
    path: "view-past-ratings",
    loader: () => import("../../scenes/PastRatings/PastRatings"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.COMPANY],
  },
  SEARCH_PAST_RATINGS_CUSTOMER_WITHID: {
    path: "view-past-ratings/:id",
    loader: () => import("../../scenes/PastRatings/PastRatings"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.COMPANY],
  },
  VIEW_MANAGE: {
    path: "manage",
    loader: () => import("../../scenes/Manage/ManageCompany"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER],
  },
  VIEW_MANAGE_USERS: {
    path: "manage/users",
    loader: () => import("../../scenes/Manage/ManageCompany"),
    invalidRoles: [
      constants.USER_ROLES.REP,
      constants.USER_ROLES.REGULAR,
      constants.USER_ROLES.CUSTOMER,
    ],
  },
  VIEW_MANAGE_EMAILS: {
    path: "manage/emails",
    loader: () => import("../../scenes/Manage/ManageCompany"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER],
  },
  VIEW_MANAGE_REPS: {
    path: "manage/reps",
    loader: () => import("../../scenes/Manage/ManageCompany"),
    invalidRoles: [
      constants.USER_ROLES.REP,
      constants.USER_ROLES.CUSTOMER,
      constants.USER_ROLES.REGULAR,
    ],
  },
  VIEW_ACCOUNT_COMPANY: {
    path: "accountcompany",
    loader: () => import("../../scenes/AccountCompany/AccountCompany"),
    invalidRoles: [
      constants.USER_ROLES.REP,
      constants.USER_ROLES.REGULAR,
      constants.USER_ROLES.CUSTOMER,
    ],
  },

  VIEW_ACCOUNT_COMPANY_USER: {
    path: "accountusercompany",
    loader: () => import("../../scenes/ManageCompanyUser/ManageCompanyUser"),
    invalidRoles: [constants.USER_ROLES.COMPANY, constants.USER_ROLES.CUSTOMER],
  },

  REP_ACCOUNT: {
    path: "repaccount",
    exact: false,
    loader: () => import("../../scenes/RepAccount/RepAccount"),
    invalidRoles: [constants.USER_ROLES.COMPANY, constants.USER_ROLES.CUSTOMER],
  },

  CUSTOMER_ACCOUNT: {
    path: "accountcustomer",
    loader: () => import("../../scenes/AccountCustomer/AccountCustomer"),
    invalidRoles: [constants.USER_ROLES.COMPANY, constants.USER_ROLES.REP],
  },

  VIEW_COMPANY_REPLIST: {
    path: "my-reps",
    loader: () => import("../../scenes/RepList/RepList"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER],
  },
  VIEW_COMPANY_REPLIST_SA: {
    path: "repora-myreps-sa",
    loader: () => import("../../scenes/ReporaIframes/Reps/RepList"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER],
  },
  VIEW_COMPANY_REP_SA: {
    path: "repora-viewrep-sa",
    loader: () => import("../../scenes/ReporaIframes/ViewRep/ViewRepCompany"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER],
  },
  VIEW_COMPANY_PROGRAMS: {
    path: "programs",
    loader: () => import("../../scenes/ProgramList/ProgramList"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER],
    // invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER, constants.USER_ROLES.REGULAR],
  },
  VIEW_COMPANY_TEAMS: {
    path: "teams",
    loader: () => import("../../scenes/TeamList/TeamList"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER],
    // invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER, constants.USER_ROLES.REGULAR],
  },
  SEARCH_REP_UNCLAIMED: {
    path: "searchunclaimedaccount",
    loader: () =>
      import("../../scenes/SearchRepUnclaimed/components/SearchEmpty"),
    invalidRoles: [constants.USER_ROLES.COMPANY, constants.USER_ROLES.CUSTOMER],
    strict: true,
  },
  SEARCH_REP_UNCLAIMED_RESULTS: {
    path: "searchunclaimedaccount/results/:query",
    exact: false,
    loader: () =>
      import("../../scenes/SearchRepUnclaimed/SearchRepUnclaimedResults"),
    invalidRoles: [constants.USER_ROLES.COMPANY, constants.USER_ROLES.CUSTOMER],
  },

  CLAIM_REP: {
    path: "claimaccount",
    loader: () => import("../../scenes/ClaimAccount/ClaimAccount"),
    invalidRoles: [constants.USER_ROLES.COMPANY, constants.USER_ROLES.CUSTOMER],
  },
  REPORTING_PREFERENCES_COMPANY: {
    path: "reportpreferences",
    loader: () =>
      import("../../scenes/ReportingPreferences/ReportingPreferences"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER],
  },
  REPORTING_PREFERENCES_MANAGE_EMAILS: {
    path: "reportpreferences/emails",
    loader: () =>
      import("../../scenes/ReportingPreferences/ReportingPreferences"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER],
  },

  REPORTING_PREFERENCES: {
    path: "reportpreferences/preferance",
    loader: () =>
      import("../../scenes/ReportingPreferences/ReportingPreferences"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER],
  },
  REPORTING_PREFERENCES_CUSTOME_ATTRIBUTE: {
    path: "reportpreferences/customeAttr",
    loader: () =>
      import("../../scenes/ReportingPreferences/ReportingPreferences"),
    invalidRoles: [
      constants.USER_ROLES.REP,
      constants.USER_ROLES.CUSTOMER,
      constants.USER_ROLES.REGULAR,
    ],
  },

  REPORTING_PREFERENCES_CRM: {
    path: "reportpreferences/crm",
    loader: () =>
      import("../../scenes/ReportingPreferences/ReportingPreferences"),
    invalidRoles: [
      constants.USER_ROLES.REP,
      constants.USER_ROLES.CUSTOMER,
      constants.USER_ROLES.REGULAR,
    ],
  },
  REPORTING_PREFERENCES_PROGRAMS: {
    path: "reportpreferences/programs",
    loader: () =>
      import("../../scenes/ReportingPreferences/ReportingPreferences"),
    invalidRoles: [
      constants.USER_ROLES.REP,
      constants.USER_ROLES.CUSTOMER,
      constants.USER_ROLES.REGULAR,
    ],
  },
  REPORTING_PREFERENCES_TAGS: {
    path: "reportpreferences/tags",
    loader: () =>
      import("../../scenes/ReportingPreferences/ReportingPreferences"),
    invalidRoles: [
      constants.USER_ROLES.REP,
      constants.USER_ROLES.CUSTOMER,
      constants.USER_ROLES.REGULAR,
    ],
  },
  RATE_TEAM_CUSTOMER: {
    path: "rateTeam",
    loader: () => import("../../scenes/RateTeam/RateTeam"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.COMPANY],
  },
  REPORTING_PREFERENCES_TEAMS: {
    path: "reportpreferences/teams",
    loader: () =>
      import("../../scenes/ReportingPreferences/ReportingPreferences"),
    invalidRoles: [
      constants.USER_ROLES.REP,
      constants.USER_ROLES.CUSTOMER,
      constants.USER_ROLES.REGULAR,
    ],
  },
  REPORTING_PREFERENCES_DASHBOARD_PREFERENCES: {
    path: "reportpreferences/dashboardPreferences",
    loader: () =>
      import("../../scenes/ReportingPreferences/ReportingPreferences"),
    invalidRoles: [
      constants.USER_ROLES.REP,
      constants.USER_ROLES.CUSTOMER,
      constants.USER_ROLES.REGULAR,
    ],
  },
  COMPANY_DASHBOARD: {
    path: "dashboardcompany",
    loader: () => import("../../scenes/Dashboard2Company/DashboardCompany"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER],
  },
  COMPANY_DASHBOARD_SA: {
    path: "repora-viewcompany-sa",
    loader: () => import("../../scenes/Dashboard2Company/DashboardCompany"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER],
  },
  COMPANY_2DASHBOARD: {
    path: "dashboardcompany2",
    loader: () => import("../../scenes/DashboardCompany/DashboardCompany"),
    invalidRoles: [constants.USER_ROLES.REP, constants.USER_ROLES.CUSTOMER],
  },
};
