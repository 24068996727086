import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setingRepSearchStates } from "../../actions/repActions";

class ScrollToTopRouter extends React.Component {
    componentDidUpdate(prevProps) {
        if (
            this.props.location.pathname !== prevProps.location.pathname
        ) {
            document.getElementById("contentLoader").scrollIntoView();
            window.scrollTo(0, 0);
            let location = this.props.location.pathname;
            if (location.split("/searchrep/results/").length != 2) {
                this.props.handlesetRepSearch("");
            }
        }
    }

    render() {
        return null;
    }
}


const mapStateToProps = state => ({
    user: state.auth.userData,
    isOpen: state.menu.isOpen,

});

const mapDispatchToProps = dispatch => ({

    handlesetRepSearch(data) {
        dispatch(setingRepSearchStates(data))
    }
});

export const Unwrapped = ScrollToTopRouter;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScrollToTopRouter));

 