import React from "react";
import { isEmpty } from "lodash";
import Button from "../../../components/Buttons/Button";
import Loader from "../../../components/Loader/Loader";
import { Header3, FormGroup } from "../../../components/global/commonStyles";
import {
  Wrapper,
  HeaderWrapper,
  FormWrapper,
  ButtonContainer,
} from "./CommonStyles";


export default class View extends React.Component {
  state = {
    email: "",
    password: "",
  };

  componentDidMount() {
  }

  handleFormValidation = (event) => {
    const inputName = event.target.getAttribute("name");
    const inputValue = event.target.value;
    this.setState({ [inputName]: inputValue });
  };

  handleStepSubmit = (e) => {
    e.preventDefault();
    this.props.handleLogin(
      this.state.email,
      this.state.password,
      "REPORA_ADMIN",
      this.props.history,
      "admin/dashboard"
    );
    // this.props.history.push("/admin/dispute");
  };

  render() {
    const { email, password } = this.state;
    const isValid = !isEmpty(email) && !isEmpty(password);

    return (
      <section>
        <Wrapper>
          {this.props.isFetching && <Loader overlayed size="medium" />}
          <HeaderWrapper>
            <Header3 textAlign="center">Log In</Header3>
          </HeaderWrapper>

          <FormWrapper>
            <FormGroup>
              <label>Email Address</label>
              <input
                name="email"
                required
                value={this.state.email}
                autoFocus
                onChange={this.handleFormValidation}
              />
            </FormGroup>

            <FormGroup>
              <label>Password</label>
              <input
                name="password"
                required
                type="password"
                value={this.state.password}
                onChange={this.handleFormValidation}
              />
            </FormGroup>
            <ButtonContainer>
              <Button
                width="100%"
                type="Submit"
                onClick={this.handleStepSubmit}
              >
                Log In
              </Button>
            </ButtonContainer>
          </FormWrapper>
        </Wrapper>
      </section>
    );
  }
}
