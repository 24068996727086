import {
  ADD_COMPANY_USER_REQUESTED,
  ADD_COMPANY_USER_SUCCEEDED,
  ADD_COMPANY_USER_FAILED,

  EDIT_USER_REQUESTED,
  EDIT_USER_SUCCEEDED,
  EDIT_USER_FAILED,
  CLEAN_COMPANY_USER,

  GET_COMPANY_USERS_REQUESTED,
  GET_COMPANY_USERS_SUCCEEDED,
  GET_COMPANY_USERS_FAILED,

  DELETE_USER_REQUESTED,
  DELETE_USER_SUCCEEDED,
  DELETE_USER_FAILED,

  RESET_PASSWORD_COMPANY_USER_REQUESTED,
  RESET_PASSWORD_COMPANY_USER_SUCCEEDED,
  RESET_PASSWORD_COMPANY_USER_FAILED,

  SHOW_USER_MANAGEMENT_MESSAGE,
  CLOSE_USER_MANAGEMENT_MESSAGE,

  CLEAR_ADD_USER_ERROR_MESSAGES,
  CLEAR_DELETE_USER_ERROR_MESSAGES,
  CLEAR_RESET_PASSWORD_USER_ERROR_MESSAGES,


  USER_REVIEWS_EXIST_REQUESTED,
  USER_REVIEWS_EXIST_SUCCEEDED,
  USER_REVIEWS_EXIST_FAILED,
} from "../actions/manageUserActions";

const initialState = {
  users: [],
  lastUser: null,
  lastEmail: null,

  getUsersFetching: false,
  getUsersError: null,

  addUserFetching: false,
  addUserError: null,

  editUserFetching: false,
  editUserError: null,

  deleteUserFetching: false,
  deleteUserError: null,

  showMessage: false,
  messageType: null,
  messageData: null,

  resetPasswordCompanyUserError: null,
  resetPasswordCompanyUserFetching: false,

  userReviewsExistFetching: false,
  userReviewsExist: [],
  userReviewsExistError: null,

};

const manageUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_USERS_REQUESTED:
      return {
        ...state,
        getUsersFetching: true
      };
    case GET_COMPANY_USERS_SUCCEEDED:
      return {
        ...state,
        getUsersFetching: false,
        users: action.payload
      };
    case GET_COMPANY_USERS_FAILED:
      return {
        ...state,
        getUsersFetching: false,
        getUsersError: action.payload
      };

    case CLEAR_ADD_USER_ERROR_MESSAGES:
      return ({
        ...state,
        addUserError: ""
      });

    case CLEAR_DELETE_USER_ERROR_MESSAGES:
      return ({
        ...state,
        deleteUserError: ""
      });

    case CLEAR_RESET_PASSWORD_USER_ERROR_MESSAGES:
      return ({
        ...state,
        resetPasswordCompanyUserError: ""
      });

    case ADD_COMPANY_USER_REQUESTED:
      return {
        ...state,
        addUserFetching: true
      };

    case ADD_COMPANY_USER_SUCCEEDED:
      return {
        ...state,
        addUserFetching: false,
        addUserError: null
      };

    case ADD_COMPANY_USER_FAILED:
      return {
        ...state,
        addUserFetching: false,
        addUserError: action.payload
      };

    case EDIT_USER_REQUESTED:
      return {
        ...state,
        editUserFetching: true,
        lastUser: null
      };

    case EDIT_USER_SUCCEEDED:
      return {
        ...state,
        editUserFetching: false,
        editUserError: null,
        lastUser: action.payload
      };

    case EDIT_USER_FAILED:
      return {
        ...state,
        editUserFetching: false,
        editUserError: action.payload
      };

    case CLEAN_COMPANY_USER:
      return {
        ...state,
        lastUser: null
      };

    case DELETE_USER_REQUESTED:
      return {
        ...state,
        deleteUserFetching: true
      };
    case DELETE_USER_SUCCEEDED:
      return {
        ...state,
        deleteUserFetching: false,
        deleteUserError: null
      };
    case DELETE_USER_FAILED:
      return {
        ...state,
        deleteUserFetching: false,
        deleteUserError: action.payload
      };
    case SHOW_USER_MANAGEMENT_MESSAGE:
      return {
        ...state,
        showMessage: true,
        messageType: action.payload.type,
        messageData: action.payload.data
      };

    case RESET_PASSWORD_COMPANY_USER_REQUESTED:
      return ({
        ...state,
        resetPasswordCompanyUserFetching: true,
        resetPasswordCompanyUserError: null,
        lastEmail: null

      });

    case RESET_PASSWORD_COMPANY_USER_SUCCEEDED:
      return ({
        ...state,
        resetPasswordCompanyUserFetching: false,
        resetPasswordCompanyUserError: null,
        lastEmail: action.email

      });

    case RESET_PASSWORD_COMPANY_USER_FAILED:
      return ({
        ...state,
        resetPasswordCompanyUserFetching: false,
        resetPasswordCompanyUserError: action.payload,
        lastEmail: action.email
      });

    case CLOSE_USER_MANAGEMENT_MESSAGE:
      return {
        ...state,
        showMessage: false,
        messageType: null,
        messageData: null
      };



    case USER_REVIEWS_EXIST_REQUESTED:
      return {
        ...state,
        userReviewsExistFetching: true,
         userReviewsExistError: null,
      };
    case USER_REVIEWS_EXIST_SUCCEEDED:

      return {
        ...state,
        userReviewsExistFetching: false,
        // userReviewsExist: [...state.userReviewsExist, action.payload.payload],
        userReviewsExist: [action.payload.payload],
         userReviewsExistError: null,
      };
    case USER_REVIEWS_EXIST_FAILED:
      return {
        ...state,
        userReviewsExistFetching: false,
        userReviewsExist: {},
        userReviewsExistError: action.payload,
      };
    default:
      return state;
  }
}

export default manageUserReducer;
