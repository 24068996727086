import {
  GET_COMPANY_RECIPIENTS_REQUESTED,
  GET_COMPANY_RECIPIENTS_SUCCEEDED,
  GET_COMPANY_RECIPIENTS_FAILED,

  ADD_COMPANY_RECIPIENT_REQUESTED,
  ADD_COMPANY_RECIPIENT_SUCCEEDED,
  ADD_COMPANY_RECIPIENT_FAILED,

  DELETE_COMPANY_RECIPIENT_REQUESTED,
  DELETE_COMPANY_RECIPIENT_SUCCEEDED,
  DELETE_COMPANY_RECIPIENT_FAILED,

  GET_EMAIL_ALERTS_CONFIG_REQUESTED,
  GET_EMAIL_ALERTS_CONFIG_SUCCEEDED,
  GET_EMAIL_ALERTS_CONFIG_FAILED,
} from "../actions/manageEmailAlertsActions";

const initialState = {
  recipients: [],

  getRecipientsFetching: false,
  getRecipientsError: null,

  addRecipientFetching: false,
  addRecipientError: null,

  deleteRecipientFetching: false,
  deleteRecipientError: null,

  emailAlertsConfig: {},
  emailAlertsConfigFetching: false
};

const manageEmailAlertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_RECIPIENTS_REQUESTED:
      return ({
        ...state,
        getRecipientsFetching: true
      });
    case GET_COMPANY_RECIPIENTS_SUCCEEDED:
      return ({
        ...state,
        getRecipientsFetching: false,
        recipients: action.payload.recipients || []
      });
    case GET_COMPANY_RECIPIENTS_FAILED:
      return ({
        ...state,
        getRecipientsFetching: false,
        getRecipientsError: action.payload
      });

    case ADD_COMPANY_RECIPIENT_REQUESTED:
      return ({
        ...state,
        addRecipientFetching: true
      });
    case ADD_COMPANY_RECIPIENT_SUCCEEDED:
      return ({
        ...state,
        addRecipientFetching: false,
        addRecipientError: null,
        recipients: [...state.recipients, action.payload]
      });
    case ADD_COMPANY_RECIPIENT_FAILED:
      return ({
        ...state,
        addRecipientFetching: false,
        addRecipientError: action.payload
      });

    case DELETE_COMPANY_RECIPIENT_REQUESTED:
      return ({
        ...state,
        deleteRecipientFetching: true
      });
    case DELETE_COMPANY_RECIPIENT_SUCCEEDED:
      return ({
        ...state,
        deleteRecipientFetching: false,
        deleteRecipientError: null,
        recipients: state.recipients.filter(email => action.payload !== email)
      });
    case DELETE_COMPANY_RECIPIENT_FAILED:
      return ({
        ...state,
        deleteRecipientFetching: false,
        deleteRecipientError: action.payload
      });

    case GET_EMAIL_ALERTS_CONFIG_REQUESTED:
      return ({
        ...state,
        emailAlertsConfigFetching: true
      });
    case GET_EMAIL_ALERTS_CONFIG_SUCCEEDED:
      return ({
        ...state,
        emailAlertsConfigFetching: false,
        emailAlertsConfigError: null,
        emailAlertsConfig: {
          average: action.payload.averageRatingThreshold,
          individual: action.payload.individualRatingThreshold
        }
      });
    case GET_EMAIL_ALERTS_CONFIG_FAILED:
      return ({
        ...state,
        emailAlertsConfigFetching: false,
        emailAlertsConfigError: action.payload
      });

    default:
      return state;
  }
}

export default manageEmailAlertsReducer;