import React from "react";
import styled from "styled-components";

import { ReporaButton, Header1, Body1, reporaSize } from "../../components/global/commonStyles";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: ${props => props.contentWidth || "100%"};
  @media (max-width: ${reporaSize.small}) {
    width:  100%;
  }
`;

const HeroContent = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: ${props => props.textColor};

  p {
    font-size:15px;
    line-height: inherit;
    text-align: center;
    line-height: inherit;
    padding: 2% 0;
    margin-bottom: 2%;

    width: ${props => props.subtitleWidth || "100%"};
    ${props => props.alignLeft ? "text-align: left;" : null};
    ${props => props.alignLeft ? "align-self: start;" : null};
    @media (max-width: ${reporaSize.small}) {
      text-align: center;
    }

    @media (max-width: ${reporaSize.small}) {
      font-size: 9px;
    }
  }
  
  h1 {
    width: 100%;
    font-size: 40px;
    line-height: inherit;

    ${props => props.alignLeft ? "text-align: left;" : null}
    @media (max-width: ${reporaSize.small}) {
      text-align: center;
    }

    @media (max-width: ${reporaSize.small}) {
      font-size: 13px;
    }
   }

   a {
    ${props => props.alignLeft ? "align-self: start;" : null}
    @media (max-width: ${reporaSize.small}) {
      align-self: center;
    }
   }
`;

class Hero extends React.Component {
  render() {
    return (
      <Wrapper alignLeft={this.props.alignLeft} contentWidth={this.props.contentWidth}>
        <HeroContent textColor={this.props.textColor} alignLeft={this.props.alignLeft} subtitleWidth={this.props.subtitleWidth}>
          <Header1 textAlign="center">{this.props.data.header}</Header1>
          <Body1 textAlign="center">
            {this.props.data.description}{this.props.data.descriptionLine1}
            <br />
            {this.props.data.descriptionLine2}
          </Body1>
          {this.props.data.buttonTxt &&
            <ReporaButton href={this.props.data.buttonLink || "/signup?state=role-ADMIN"} >{this.props.data.buttonTxt}</ReporaButton>
          }
        </HeroContent>
      </Wrapper>
    )
  }
}



export const Unwrapped = Hero;
export default Hero;
