import { injectGlobal } from "styled-components";
import styled, { css } from "styled-components";

/**
 * Importing Font Faces
 */
injectGlobal`
  @font-face {
      font-family: "Nexa Light";
      src: url("/fonts/nexa-light-webfont.eot");
      src: url("/fonts/nexa-light-webfont.eot?#iefix") format("embedded-opentype"),
           url("/fonts/nexa-light-webfont.woff") format("woff"),
           url("/fonts/nexa-light-webfont.ttf") format("truetype"),
           url("/fonts/nexa-light-webfont.svg#nexa-light") format("svg");
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: "Nexa Bold";
      src: url("/fonts/nexa-bold-webfont.eot");
      src: url("/fonts/nexa-bold-webfont.eot?#iefix") format("embedded-opentype"),
           url("/fonts/nexa-bold-webfont.woff") format("woff"),
           url("/fonts/nexa-bold-webfont.ttf") format("truetype"),
           url("/fonts/nexa-bold-webfont.svg#nexa-bold") format("svg");
      font-weight: bold;
      font-style: normal;
  }

  body {
    font-family: "Nexa Bold", sans-serif;
  }
`;

/**
 * Defining media Queries
 */
export const reporaSize = {
  tablet: "768px",
  desktop: "1024px",

  small: "600px",
  medium: "960px",
  large: "1280px",
  xlarge: "1920px",
};

export const reporaDevice = {
  tablet: `(min-width: ${reporaSize.tablet})`,
  desktop: `(min-width: ${reporaSize.desktop})`,
  mobile: `(max-width: ${reporaSize.tablet})`,

  xs: "only screen and (max-width: 599px)",
  gtxs: "only screen and (min-width: 600px)",

  sm: "only screen and (min-width: 600px) and (max-width: 959px)",
  stsm: "only screen and (max-width: 559px)",
  gtsm: "only screen and (min-width: 960px)",

  md: "only screen and (min-width: 960px) and (max-width: 1279px)",
  stmd: "only screen and (max-width: 959px)",
  gtmd: "only screen and (min-width: 1280px)",

  lg: "only screen and (min-width: 1280px) and (max-width: 1919px)",
  stlg: "only screen and (max-width: 1279px)",
  gtlg: "only screen and (min-width: 1920px)",

  xl: "only screen and (min-width: 1920px)",
};

/**
 * Defining color pallete
 */
export const reporaColor = {
  white: "#FFF",
  black: "#000",
  watermelon: "#FF3F63",
  lightGrey: "#f9f9f9",
  warmGrey: "#9B9B9B",
  grey87: "#DEDEDE",
  grey91: "#E8E8E8",
  grey98: "#FAFAFA",
  greenBlue: "#05CE7C",
  marigold: "#FCC203",
  brightSkyBlue: "#0BC9F6",
  aquaBlue: "rgba(11, 201, 238)",
  greenBlue68: "rgba(5, 206, 124, 0.68)",
  aquaBlue84: "rgba(11, 201, 238, 0.84)",
  brightSkyBlue85: "rgba(11, 201, 246, 0.85)",
  black08: "rgba(0, 0, 0, 0.08)",
  black02: "rgba(0, 0, 0, 0.2)",
  pacificBlue: "#008BCC",
  denim: "#0F6EC1",
  cinnabar: "#E5481B",
  warning: "#F32013",
  warningLite: "#FF2156",
  sectionBackGround: "#19222C",
};

/**
 * Defining Font styles
 */
export const PrimaryFont = css`
  font-family: Nexa Bold;
  color: ${(props) => props.textColor};
  text-align: ${(props) => props.textAlign};
`;

export const Title = styled.h4`
  ${PrimaryFont} font-size: 16px;
  letter-spacing: 0.6px;
`;
export const Title1 = styled.h4`
  ${PrimaryFont} font-size: 36px;
  color: ${reporaColor.black};
  letter-spacing: normal;
`;
export const Header1 = styled.h1`
  ${PrimaryFont} font-size: 48px;
  line-height: 52px;

  @media ${reporaDevice.tablet} {
    font-size: 60px;
    line-height: 65px;
  }
`;

export const Header2 = styled.h2`
  ${PrimaryFont} font-size: 28px;
  line-height: 34px;

  @media ${reporaDevice.tablet} {
    font-size: 42px;
    line-height: 50px;
  }
`;

export const Header3 = styled.h3`
  ${PrimaryFont} font-size: 22px;

  @media ${reporaDevice.tablet} {
    font-size: 28px;
  }
`;

export const Header4 = styled.h4`
  ${PrimaryFont} font-size: 18px;
  // text-transform: capitalize;
  @media ${reporaDevice.tablet} {
    font-size: 24px;
  }
`;

export const Header5 = styled.h5`
  ${PrimaryFont} font-size: 14px;
  margin: 21px 0;
`;

export const Body1 = styled.p`
  ${PrimaryFont} font-size: 20px;
  line-height: 28px;
`;

export const Body2 = styled.p`
  ${PrimaryFont} font-size: 18px;
  line-height: 26px;
`;

export const Body3 = styled.p`
  ${PrimaryFont} font-size: 16px;
  line-height: 24px;
`;

export const Body4 = styled.p`
  ${PrimaryFont} font-size: 14px;
  line-height: 22px;
`;

export const Body5 = styled.p`
  ${PrimaryFont} font-size: 12px;
  line-height: 20px;
`;

export const Body6 = styled.p`
  ${PrimaryFont} font-size: 10px;
  line-height: 18px;
`;
/*
 * Defining Button styles
 */
export const reporaButtonStyles = css`
  background-color: ${reporaColor.brightSkyBlue};
  color: ${reporaColor.white};
  font-size: ${(props) =>
    props.xsmall ? "12px" : props.small ? "14px" : "16px"};
  width: ${(props) =>
    props.small
      ? "100px"
      : props.large
      ? "287px"
      : props.xsmall
      ? "60px"
      : "205px"};
  height: ${(props) => (props.small ? "36px" : props.xsmall ? "25px" : "45px")};
  margin-bottom: ${(props) => props.marginBottom};
  font-family: "Nexa Bold";
  text-align: center;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border: solid 1px transparent;
  text-decoration: none;

  &:hover {
    border: solid 1px ${reporaColor.brightSkyBlue85};
  }

  &[disabled] {
    opacity: 0.5;
    border: solid 1px ${reporaColor.warmGrey};
    cursor: default;
  }

  ${(props) =>
    props.secondary
      ? `
    background-color: ${reporaColor.white};
    color: ${reporaColor.black};

    &:hover {
      border: solid 1px ${reporaColor.warmGrey};
    }
  `
      : null}

  ${(props) =>
    props.facebook
      ? `
    background-color: ${reporaColor.denim};
    color: ${reporaColor.white};

    &:hover {
      border: solid 1px ${reporaColor.denim};
    }
  `
      : null}

  ${(props) =>
    props.google
      ? `
    background-color: ${reporaColor.cinnabar};
    color: ${reporaColor.white};

    &:hover {
      border: solid 1px ${reporaColor.cinnabar};
    }
  `
      : null}

  ${(props) =>
    props.linked
      ? `
    background-color: ${reporaColor.pacificBlue};
    color: ${reporaColor.white};

    &:hover {
      border: solid 1px ${reporaColor.pacificBlue};
    }
  `
      : null}

    ${(props) =>
    props.greenBlue
      ? `
    background-color: ${reporaColor.greenBlue};
    color: ${reporaColor.white};
    border: solid 1px ${reporaColor.greenBlue};
  `
      : null}

  ${(props) =>
    props.white
      ? `
    background-color: ${reporaColor.white};
    color: ${reporaColor.warmGrey};
    border: solid 1px ${reporaColor.grey87};
  `
      : null}

  ${(props) =>
    props.watermelon
      ? `
    background-color: ${reporaColor.watermelon};
    color: ${reporaColor.white};
    border: solid 1px ${reporaColor.watermelon};
  `
      : null}


  ${(props) =>
    props.full
      ? `
    width: 100%;
  `
      : null}

  ${(props) =>
    props.autoWidth
      ? `
    width: auto;
    padding: 0 20px;
  `
      : null}

  ${(props) =>
    props.withIcon
      ? `
    display: flex;
    justify-content: space-between;
    padding-right: 20px;

    img {
      padding: 10px;
      border-right: 1px solid;
    }
  `
      : null}

  ${(props) =>
    props.isIcon
      ? `
    width: 36px;
    height: 36px;
  `
      : null}
      ${(props) =>
    props.modal
      ? `
        background-color: ${reporaColor.lightGrey};
        color: ${reporaColor.black};
      `
      : null}
  width: ${(props) => props.width};
  margin-top: ${(props) => props.marginTop};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
`;

export const ReporaButton = styled.a`
  ${reporaButtonStyles};
`;

export const ReporaSubmitButton = styled.button`
  ${reporaButtonStyles};
`;

/**
 * Defining Form styles
 */
export const FormRow = styled.div`
  display: flex;
  width: 100%;

  & > div {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const FormRow2 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.isEditMode ? "column" : "row")};
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px;
  align-items: center;

  @media ${reporaDevice.desktop} {
    flex-direction: row;
  }

  div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex: ${(props) => (props.isEditMode ? "0" : "1")}
      ${(props) => (props.isEditMode ? "0" : "381px")};

    @media ${reporaDevice.desktop} {
      flex: 1 1 ${(props) => (props.isEditMode ? "195px" : "381px")};
    }
  }

  label {
    font-size: 12px;
    line-height: 2.33;
    color: ${reporaColor.warmGrey};
    flex: 1 1 ${(props) => (props.isEditMode ? "0px" : "85px")};

    @media ${reporaDevice.desktop} {
      flex: 1 1 ${(props) => (props.isEditMode ? "0px" : "85px")};
    }
  }

  input {
    font-family: Nexa Bold;
    padding: 0 10px;
    font-size: 14px;
    line-height: 28px;
    height: 40px;
    border: 1px solid ${reporaColor.grey91};
    flex: 1 1 ${(props) => (props.isEditMode ? "40px" : "381px")};
    border-radius: 4px;

    &:focus {
      outline: none !important;
      border: 1px solid ${reporaColor.brightSkyBlue};
      box-shadow: 0 0 2px ${reporaColor.brightSkyBlue};
    }

    @media ${reporaDevice.desktop} {
      flex: 1 1 ${(props) => (props.isEditMode ? "195px" : "381px")};
    }
  }

  a {
    color: ${reporaColor.brightSkyBlue};
    margin: 5px;
    font-size: 12px;
    text-decoration: underline;
    margin-top: 40px;

    @media ${reporaDevice.tablet} {
      margin-top: 0px;
    }
  }

  img {
    flex: 1 1 14px;
  }
`;

export const FormGroup = styled.div`
  margin-top: ${(props) => props.marginTop || "14px"};
  width: ${(props) => props.width || "100%"};
  position: relative;

  label {
    font-size: 12px;
    line-height: 2.33;
    color: ${reporaColor.warmGrey};
  }

  input {
    font-family: Nexa Bold;
    padding: 0 10px;
    font-size: 16px;
    line-height: 28px;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    box-shadow: ${(props) =>
      props.error ? `0 0 4px 0 ${reporaColor.watermelon}` : ""};
    border: ${(props) =>
      props.error ? "1px solid red" : `1px solid ${reporaColor.grey91}`};

    &:focus {
      outline: none !important;
      border: 1px solid ${reporaColor.brightSkyBlue};
      box-shadow: 0 0 2px ${reporaColor.brightSkyBlue};
    }

    &[type="password"] {
      font-size: 20px;
    }
  }

  textarea {
    resize: none;
    font-family: Nexa Bold;
    padding: 20px;
    font-size: 14px;
    line-height: 28px;
    width: 100%;
    height: 150px;
    border-radius: 4px;
    box-shadow: ${(props) =>
      props.error ? `0 0 4px 0 ${reporaColor.watermelon}` : ""};
    border: ${(props) =>
      props.error ? "1px solid red" : `1px solid ${reporaColor.grey91}`};
  }

  .formErrorInput {
    position: absolute;
    color: ${reporaColor.watermelon};
    width: 100%;
    font-size: 10px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
  }
`;
export const NoBorderTextArea = styled.textarea`
  border: none !important;
  box-shadow: none !important;
  height: 100% !important;
  width: 100%;
  margin: 0 !important;
  padding: 5% !important;
  resize: none;
  font-size: 14px;
  line-height: 25px;
  font-family: Nexa Bold;
  ::placeholder {
    color: ${reporaColor.warmGrey};
  }
  &:focus {
    outline: none !important;
    border: 1px none;
    box-shadow: none;
  }
`;

export const TextAreaWrapper = styled.div`
  border: 1px solid ${reporaColor.grey91};
  position: relative;
  width: 100% !important;
  border-radius: 4px;
  &:hover {
    outline: none !important;
    border: 1px solid ${reporaColor.brightSkyBlue};
    box-shadow: 0 0 2px ${reporaColor.brightSkyBlue};
  }
`;

export const TextArea = styled.textarea`
  font-family: Nexa Bold;
  padding: 0 10px;
  font-size: 14px;
  line-height: 28px;
  width: 100%;
  border: 1px solid ${reporaColor.grey91};
  resize: none;
  border-radius: 4px;
  ${(props) =>
    props.disabledColor
      ? `
  background-color: ${props.disabledColor};
`
      : null}
  &:focus {
    outline: none !important;
    border: 1px solid ${reporaColor.brightSkyBlue};
    box-shadow: 0 0 2px ${reporaColor.brightSkyBlue};
  }
`;

export const RadioComponent = styled.div`
  width: 100%;

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${reporaColor.black};
    border-radius: 5px;
    height: 100%;
    width: 100%;
    padding-left: 20px;

    span {
      position: relative;
    }
  }

  [type="radio"]:checked + label > span:before,
  [type="radio"]:not(:checked) + label > span:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -20px;
    width: 16px;
    height: 16px;
    border: 2px solid ${reporaColor.warmGrey};
    border-radius: 100%;
    transform: translateY(-50%);
  }

  [type="radio"]:checked + label > span:after,
  [type="radio"]:not(:checked) + label > span:after {
    content: "";
    width: 10px;
    height: 10px;
    background: ${reporaColor.brightSkyBlue};
    position: absolute;
    top: calc(50% - 5px);
    left: -17px;
    border-radius: 100%;
    transition: all 0.2s ease;
    transform: scale(0);
    transform: translateY(-50%);
    opacity: 0;
  }

  [type="radio"]:checked + label {
    border-color: ${reporaColor.warmGrey};
    & > span:after {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const CheckComponent = styled.div`
  width: ${(props) => props.width || "100%"};
  position: relative;

  input {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0;
      color: ${(props) => props.textColor || reporaColor.black};
      font-size: ${(props) => props.textSize || "14px"};
    }

    & + label:before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      flex: 0 0 20px;
      background: ${(props) => props.boxColor || reporaColor.brightSkyBlue};
    }

    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    &:disabled + label {
      color: ${reporaColor.warmGrey};
      cursor: auto;
    }

    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    &:not(:checked) + label:before {
      background: #ddd;
    }

    &:checked + label:after,
    label:after {
      content: "";
      position: absolute;
      left: 5px;
      top: calc(50% - 5px);
      width: 10px;
      height: 10px;
      background: url("/assets/global/check-white.svg");
      background-size: cover;
    }
  }
`;

export const InputTooltip = styled.span`
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  width: 170px;
  background-color: ${(props) =>
    props.isValid ? reporaColor.greenBlue : reporaColor.brightSkyBlue};
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 5px 0;
  position: absolute;
  z-index: ${(props) => (props.isVisible ? "1" : "-2")};
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 20px;
  padding: 10px;
  margin: 0;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: ${(props) =>
        props.isValid
          ? `${reporaColor.greenBlue}`
          : `${reporaColor.brightSkyBlue}`}
      transparent transparent transparent;
  }

  @media ${reporaDevice.gtsm} {
    bottom: 20px;
    left: 100%;
    margin-left: 20px;
    transform: translateY(50%);
    transition: opacity 0.3s;

    &:after {
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      border-color: transparent
        ${(props) =>
          props.isValid
            ? `${reporaColor.greenBlue}`
            : `${reporaColor.brightSkyBlue}`}
        transparent transparent;
    }

    ${(props) =>
      props.position === "left" &&
      css`
        left: -190px;
        margin: 0;

        &:after {
          top: 50%;
          left: 100%;
          transform: translateY(-50%) rotate(180deg);
        }
      `}
  }
`;

/**
 * Other commonStyles
 */
export const XIcon = styled.span`
  position: relative;
  display: inline-block;
  width: ${(props) => (props.size ? props.size : "22px")};
  height: ${(props) => (props.size ? props.size : "22px")};
  overflow: hidden;
  margin: 5px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 40%;
    left: 0;
    background: ${(props) => props.color};
    height: 3px;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;
export const H1 = styled.h1`
  font-family: "Nexa Bold";
  font-size: 52px;
`;
