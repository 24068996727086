import reportingServices from "../services/reportingService";

export const GET_ATTRIBUTES_LIST = "GET_ATTRIBUTES_LIST";
export const GET_ATTRIBUTES_LIST_SUCCESS = "GET_ATTRIBUTES_LIST_SUCCESS";

export const LOADER_ACTIVATE = "LOADER_ACTIVATE";

export const GET_ATTRIBUTES_LIST_ERROR = "GET_ATTRIBUTES_LIST_ERROR";
export const GET_PROGRAMS_LIST = "GET_PROGRAMS_LIST";
export const GET_PROGRAMS_LIST_SUCCESS = "GET_PROGRAMS_LIST_SUCCESS";
export const GET_PROGRAMS_LIST_ERROR = "GET_PROGRAMS_LIST_ERROR";
export const ADD_PROGRAM = "ADD_PROGRAM";
export const ADD_PROGRAM_SUCCESS = "ADD_PROGRAM_SUCCESS";
export const ADD_PROGRAM_ERROR = "ADD_PROGRAM_ERROR";
export const GET_PROGRAM = "GET_PROGRAM";
export const GET_PROGRAM_SUCCESS = "GET_PROGRAM_SUCCESS";
export const GET_PROGRAM_ERROR = "GET_PROGRAM_ERROR";
export const UPDATE_PROGRAM = "UPDATE_PROGRAM";
export const UPDATE_PROGRAM_SUCCESS = "UPDATE_PROGRAM_SUCCESS";
export const UPDATE_PROGRAM_ERROR = "UPDATE_PROGRAM_ERROR";

export const DELETE_PROGRAM = "DELETE_PROGRAM";
export const DELETE_PROGRAM_SUCCESS = "DELETE_PROGRAM_SUCCESS";
export const DELETE_PROGRAM_ERROR = "DELETE_PROGRAM_ERROR";

export const GET_TAG_LIST = "GET_TAG_LIST";
export const GET_TAG_LIST_SUCCESS = "GET_TAG_LIST_SUCCESS";
export const GET_TAG_LIST_ERROR = "GET_TAG_LIST_ERROR";

export const ADD_TAG = "ADD_TAG";
export const ADD_TAG_SUCCESS = "ADD_TAG_SUCCESS";
export const ADD_TAG_ERROR = "ADD_TAG_ERROR";

export const DELETE_TAG = "DELETE_TAG";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_ERROR = "DELETE_TAG_ERROR";

export const UPDATE_TAG = "UPDATE_TAG";
export const UPDATE_TAG_SUCCESS = "UPDATE_TAG_SUCCESS";
export const UPDATE_TAG_ERROR = "UPDATE_TAG_ERROR";

export const GET_TEAM_LIST = "GET_TEAM_LIST";
export const GET_TEAM_LIST_SUCCESS = "GET_TEAM_LIST_SUCCESS";
export const GET_TEAM_LIST_ERROR = "GET_TEAM_LIST_ERROR";

export const ADD_TEAM = "ADD_TEAM";
export const ADD_TEAM_SUCCESS = "ADD_TEAM_SUCCESS";
export const ADD_TEAM_ERROR = "ADD_TEAM_ERROR";

export const GET_TEAM = "GET_TEAM";
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";
export const GET_TEAM_ERROR = "GET_TEAM_ERROR";

export const DELETE_TEAM = "DELETE_TEAM";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_ERROR = "DELETE_TEAM_ERROR";

export const UPDATE_TEAM = "UPDATE_TEAM";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_ERROR = "UPDATE_TEAM_ERROR";

const getProgramsList = (payload) => {
  return {
    type: GET_PROGRAMS_LIST,
    payload,
  };
};

const getProgramsListSuccess = (payload) => {
  return {
    type: GET_PROGRAMS_LIST_SUCCESS,
    payload,
  };
};
const getProgramsListError = (payload) => {
  return {
    type: GET_PROGRAMS_LIST_ERROR,
    payload,
  };
};

const addProgramAction = (payload) => {
  return {
    type: ADD_PROGRAM,
    payload,
  };
};
const addProgramSuccessAction = (payload) => {
  return {
    type: ADD_PROGRAM_SUCCESS,
    payload,
  };
};
const addProgramErrorAction = (payload) => {
  return {
    type: ADD_PROGRAM_ERROR,
    payload,
  };
};

const getProgramAction = (payload) => {
  return {
    type: GET_PROGRAM,
    payload,
  };
};
const getProgramSuccess = (payload) => {
  return {
    type: GET_PROGRAM_SUCCESS,
    payload,
  };
};
const getProgramError = (payload) => {
  return {
    type: GET_PROGRAM_ERROR,
    payload,
  };
};

const updateProgramAction = (payload) => {
  return {
    type: UPDATE_PROGRAM,
    payload,
  };
};
const updateProgramSuccess = (payload) => {
  return {
    type: UPDATE_PROGRAM_SUCCESS,
    payload,
  };
};
const updateProgramError = (payload) => {
  return {
    type: UPDATE_PROGRAM_ERROR,
    payload,
  };
};

const deleteProgram = (payload) => {
  return {
    type: DELETE_PROGRAM,
    payload,
  };
};
const deleteProgramSuccess = (payload) => {
  return {
    type: DELETE_PROGRAM_SUCCESS,
    payload,
  };
};
const deleteProgramError = (payload) => {
  return {
    type: DELETE_PROGRAM_ERROR,
    payload,
  };
};

const getAttributesList = (payload) => {
  return {
    type: GET_ATTRIBUTES_LIST,
    payload,
  };
};
const getAttributesListSuccess = (payload) => {
  return {
    type: GET_ATTRIBUTES_LIST_SUCCESS,
    payload,
  };
};

const UpdateLoaderState = (data) => ({
  type: LOADER_ACTIVATE,
  payload: data,
});

const getAttributesListError = (payload) => {
  return {
    type: GET_ATTRIBUTES_LIST_ERROR,
    payload,
  };
};

const getTagList = (payload) => {
  return {
    type: GET_TAG_LIST,
    payload,
  };
};

const getTagListSuccess = (payload) => {
  return {
    type: GET_TAG_LIST_SUCCESS,
    payload,
  };
};
const getTagListError = (payload) => {
  return {
    type: GET_TAG_LIST_ERROR,
    payload,
  };
};

const requestAddTag = (payload) => {
  return {
    type: ADD_TAG,
    payload,
  };
};
const addTagSuccess = (payload) => {
  return {
    type: ADD_TAG_SUCCESS,
    payload,
  };
};
const addTagError = (payload) => {
  return {
    type: ADD_TAG_ERROR,
    payload,
  };
};

const deleteTag = (payload) => {
  return {
    type: DELETE_TAG,
    payload,
  };
};
const deleteTagSuccess = (payload) => {
  return {
    type: DELETE_TAG_SUCCESS,
    payload,
  };
};
const deleteTagError = (payload) => {
  return {
    type: DELETE_TAG_ERROR,
    payload,
  };
};
const updateTag = (payload) => {
  return {
    type: UPDATE_TAG,
    payload,
  };
};
const updateTagSuccess = (payload) => {
  return {
    type: UPDATE_TAG_SUCCESS,
    payload,
  };
};
const updateTagError = (payload) => {
  return {
    type: UPDATE_TAG_ERROR,
    payload,
  };
};

const getTeamList = (payload) => {
  return {
    type: GET_TEAM_LIST,
    payload,
  };
};

const getTeamListSuccess = (payload) => {
  return {
    type: GET_TEAM_LIST_SUCCESS,
    payload,
  };
};
const getTeamListError = (payload) => {
  return {
    type: GET_TEAM_LIST_ERROR,
    payload,
  };
};

const addTeamAction = (payload) => {
  return {
    type: ADD_TEAM,
    payload,
  };
};
const addTeamSuccessAction = (payload) => {
  return {
    type: ADD_TEAM_SUCCESS,
    payload,
  };
};
const addTeamErrorAction = (payload) => {
  return {
    type: ADD_TEAM_ERROR,
    payload,
  };
};

const getTeamAction = (payload) => {
  return {
    type: GET_TEAM,
    payload,
  };
};
const getTeamSuccess = (payload) => {
  return {
    type: GET_TEAM_SUCCESS,
    payload,
  };
};
const getTeamError = (payload) => {
  return {
    type: GET_TEAM_ERROR,
    payload,
  };
};

const deleteTeam = (payload) => {
  return {
    type: DELETE_TEAM,
    payload,
  };
};
const deleteTeamSuccess = (payload) => {
  return {
    type: DELETE_TEAM_SUCCESS,
    payload,
  };
};
const deleteTeamError = (payload) => {
  return {
    type: DELETE_TEAM_ERROR,
    payload,
  };
};

const updateTeamAction = (payload) => {
  return {
    type: UPDATE_TEAM,
    payload,
  };
};
const updateTeamSuccess = (payload) => {
  return {
    type: UPDATE_TEAM_SUCCESS,
    payload,
  };
};
const updateTeamError = (payload) => {
  return {
    type: UPDATE_TEAM_ERROR,
    payload,
  };
};

export const requestProgramsList = (payload) => {
  return (dispatch) => {
    dispatch(getProgramsList(payload));
    reportingServices
      .getProgramsList(payload)
      .then((response) => {
        dispatch(getProgramsListSuccess(response));
      })
      .catch((error) => dispatch(getProgramsListError(error)));
  };
};

export const requestAddProgram = (payload) => {
  return (dispatch) => {
    dispatch(addProgramAction(payload));
    reportingServices
      .addProgram(payload)
      .then((response) => {
        dispatch(addProgramSuccessAction(response));
        dispatch(requestProgramsList(payload.companyId));
      })
      .catch((error) => dispatch(addProgramErrorAction(error)));
  };
};
export const requestGetProgram = (payload) => {
  return (dispatch) => {
    dispatch(getProgramAction(payload));
    reportingServices
      .getProgram(payload)
      .then((response) => {
        dispatch(getProgramSuccess(response));
      })
      .catch((error) => dispatch(getProgramError(error)));
  };
};
export const requestUpdateProgram = (payload) => {
  return (dispatch) => {
    dispatch(updateProgramAction(payload));
    reportingServices
      .updateProgram(payload)
      .then((response) => {
        dispatch(updateProgramSuccess(response));
        dispatch(requestProgramsList(payload.companyId));
      })
      .catch((error) => dispatch(updateProgramError(error)));
  };
};
export const requestDeleteProgram = (payload) => {
  return (dispatch) => {
    dispatch(deleteProgram(payload));
    reportingServices
      .deleteProgram(payload.programId)
      .then((response) => {
        dispatch(deleteProgramSuccess(response));
        if (response.statusCode === 200) {
          dispatch(requestProgramsList(payload.companyId));
        }
      })
      .catch((error) => dispatch(deleteProgramError(error)));
  };
};

export const requestAttributesList = (payload) => {
  return (dispatch) => {
    dispatch(getAttributesList());
    reportingServices
      .getAttributesList(payload)
      .then((response) => {
        dispatch(getAttributesListSuccess(response));
      })
      .catch((error) => {
        dispatch(getAttributesListError(error));
      });
  };
};

export const requestAttrList = (payload, state) => {
  return (dispatch) => {
    dispatch(getAttributesList());
    dispatch(UpdateLoaderState(state));
    reportingServices
      .getRatingAttr(payload)
      .then((response) => {
        // console.log("response1-->>>", response);

        dispatch(getAttributesListSuccess(response));
      })
      .catch((error) => {
        dispatch(getAttributesListError(error));
      });
  };
};

export const requestTagList = (payload) => {
  return (dispatch) => {
    dispatch(getTagList(payload));
    reportingServices
      .getTagList(payload)
      .then((response) => {
        dispatch(getTagListSuccess(response));
      })
      .catch((error) => dispatch(getTagListError(error)));
  };
};

export const addTag = (payload) => {
  return (dispatch) => {
    dispatch(requestAddTag(payload));
    reportingServices
      .addTag(payload)
      .then((response) => {
        dispatch(addTagSuccess(response));
        if (response.statusCode == 200 && response.payload == "Success") {
          dispatch(requestTagList(payload.companyId));
        }
      })
      .catch((error) => dispatch(addTagError(error)));
  };
};

export const requestDeleteTag = (payload) => {
  return (dispatch) => {
    dispatch(deleteTag(payload.tagId));
    reportingServices
      .deleteTag(payload.tagId)
      .then((response) => {
        dispatch(deleteTagSuccess(response));
        dispatch(requestTagList(payload.companyId));
      })
      .catch((error) => dispatch(deleteTagError(error)));
  };
};

export const requestUpdateTag = (payload) => {
  return (dispatch) => {
    dispatch(updateTag(payload));
    reportingServices
      .updateTag(payload)
      .then((response) => {
        dispatch(updateTagSuccess(response));
        dispatch(requestTagList(payload.companyId));
      })
      .catch((error) => dispatch(updateTagError(error)));
  };
};

export const requestTeamList = (payload) => {
  return (dispatch) => {
    dispatch(getTeamList(payload));
    reportingServices
      .getTeamsList(payload)
      .then((response) => {
        dispatch(getTeamListSuccess(response));
      })
      .catch((error) => dispatch(getTeamListError(error)));
  };
};

export const requestAddTeam = (payload) => {
  return (dispatch) => {
    dispatch(addTeamAction(payload));
    reportingServices
      .addTeam(payload)
      .then((response) => {
        dispatch(addTeamSuccessAction(response));
        dispatch(requestTeamList(payload.companyId));
      })
      .catch((error) => dispatch(addTeamErrorAction(error)));
  };
};

export const requestGetTeam = (payload) => {
  return (dispatch) => {
    dispatch(getTeamAction(payload));
    reportingServices
      .getTeam(payload)
      .then((response) => {
        dispatch(getTeamSuccess(response));
      })
      .catch((error) => dispatch(getTeamError(error)));
  };
};

export const requestDeleteTeam = (payload) => {
  return (dispatch) => {
    dispatch(deleteTeam(payload));
    reportingServices
      .deleteTeam(payload.teamId)
      .then((response) => {
        dispatch(deleteTeamSuccess(response));
        if (response.statusCode === 200) {
          dispatch(requestTeamList(payload.companyId));
        }
      })
      .catch((error) => dispatch(deleteTeamError(error)));
  };
};

export const requestUpdateTeam = (payload) => {
  return (dispatch) => {
    dispatch(updateTeamAction(payload));
    reportingServices
      .updateTeam(payload)
      .then((response) => {
        dispatch(updateTeamSuccess(response));
        dispatch(requestTeamList(payload.companyId));
      })
      .catch((error) => dispatch(updateTeamError(error)));
  };
};
