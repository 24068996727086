import React from "react";
import { connect } from "react-redux";

import {
  getRepSuggestions,
  getCompanyRepSuggestions,
  setingRepSearchStates,
} from "../../actions/repActions";
import { getCompanySuggestions } from "../../actions/companyActions";
import SearchForm from "./components/SearchForm";
import CompanyRepSearchForm from "./components/CompanyRepSearchForm";
import {
  requestClearRepSelectionData,
  searchCompanyReps,
  searchReps,
} from "../../actions/searchActions";

class HeaderSearchRep extends React.Component {
  render() {
    if (this.props.companySearch) {
      return (
        <CompanyRepSearchForm
          user={this.props.user}
          repSuggestions={this.props.repSuggestions}
          repSuggestionsFetching={this.props.repSuggestionsFetching}
          repSuggestionsError={this.props.repSuggestionsError}
          handleGetCompanyRepSuggestions={this.props.handleGetCompanyRepSuggestions}
          handlesetRepSearch={this.props.handlesetRepSearch}
          inputDelay={500}
          repSearch={this.props.repSearch}
          {...this.props}
        />
      );
    }
    if (this.props.user.userRole === "REP") {
      return <div />;
    }

    return (
      <SearchForm
        repSuggestions={this.props.repSuggestions}
        companySuggestions={this.props.companySuggestions}
        repSuggestionsFetching={this.props.repSuggestionsFetching}
        companySuggestionsFetching={this.props.companySuggestionsFetching}
        repSuggestionsError={this.props.repSuggestionsError}
        handleGetRepSuggestions={this.props.handleGetRepSuggestions}
        handleGetCompanySuggestions={this.props.handleGetCompanySuggestions}
        handlesetRepSearch={this.props.handlesetRepSearch}
        inputDelay={500}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.userData,

  repSuggestions: state.rep.repSuggestions,
  companySuggestions: state.company.companySuggestions,

  repSuggestionsFetching: state.rep.repSuggestionsFetching,
  companySuggestionsFetching: state.company.companySuggestionsFetching,

  repSuggestionsError: state.rep.repSuggestionsError,
  companySuggestionsError: state.company.companySuggestionsError,
  repSearch: state.rep.repSearch,
});

const mapDispatchToProps = (dispatch) => ({
  handleGetRepSuggestions(repName, companyId) {
    dispatch(getRepSuggestions(repName, companyId));
  },
  handleGetCompanySuggestions(companyName, repId) {
    dispatch(getCompanySuggestions(companyName, repId));
  },
  handleGetCompanyRepSuggestions(repName, companyId) {
    dispatch(getCompanyRepSuggestions(repName, companyId));
  },
  handlesetRepSearch(data) {
    dispatch(setingRepSearchStates(data));
  },
  handleSearchReps(repName, repId, companyName, companyId) {
    dispatch(
      searchReps(
        repName,
        repId,
        companyName,
        companyId,
        false,
        this.user ? this.user.id : null
      )
    );
  },
  handleSearchCompanyReps(repName, repId, companyId) {
    dispatch(
      searchCompanyReps(
        repName,
        repId,
        companyId,
        this.user ? this.user.id : null
      )
    );
  },
  clearRepSelectionData(data) {
    dispatch(requestClearRepSelectionData(data));
  },
});

export const Unwrapped = HeaderSearchRep;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderSearchRep);
