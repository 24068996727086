import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { Header3, Header5, Body5, reporaColor, FormRow, FormGroup, InputTooltip } from "../../../components/global/commonStyles";
import { Wrapper, BackButton, CloseButton, HeaderWrapper, FormWrapper, ButtonContainer ,InvalidSpan} from "./CommonStyles";
import Button from "../../../components/Buttons/Button";

const RestrictionItem = styled.li`
  display: flex;
  align-items: center;

  img {
    opacity: ${props => props.isValid ? "1" : "0"};
    margin-right: 5px;
    width: 10px;
  }
`;

class Step2Customer extends React.Component {
  state = {
    validStep: false,
    reemailValid: false,
    varifiedEmail:false,
    passwordValid: false,
    repasswordValid: false,
    reemailTooltip: false,
    passwordTooltip: false,
    repasswordTooltip: false,
    email: "",
    reemail: "",
    password: "",
    repassword: "",
    companyMsg:"",
    passwordRules: [
      { description: "8 characters", valid: false, regex: /^(?=.*\d).{8,}$/ },
      { description: "1 capital letter", valid: false, regex: /[A-Z]/g },
      { description: "1 lowercase letter", valid: false, regex: /[a-z]/g },
      { description: "1 number", valid: false, regex: /[0-9]/g },
      { description: "1 special character", valid: false, regex: /[^\w]/ }
    ]
  };

  componentWillReceiveProps(nextProps) {
    const { reemailValid, emailValid, repasswordValid, passwordValid } = this.state;
    const { step3Data: { firstName, lastName } } = nextProps;
    const validStep = emailValid  && repasswordValid && passwordValid && reemailValid && firstName && !_.isEmpty(firstName.trim()) && lastName && !_.isEmpty(lastName.trim());
    this.setState({ validStep });
  }

  handleFormValidation = event => {
    const inputName = event.target.name;
    let inputValue = event.target.value;

    if (inputName === "password") {
      inputValue = event.target && event.target.value && event.target.value.trim()
    }

    this.setState({ [inputName]: inputValue });

    switch (inputName) {
      case "email":
        this.validateEmail(inputValue);
        break;
      case "reemail":
        this.validateDoubleInput(inputValue, "email", this.state.repasswordValid);
        break;
      case "password":
        this.validatePassword(inputValue);
        break;
      case "repassword":
        this.validateDoubleInput(inputValue, "password", this.state.reemailValid);
        break;
      default:
        break;
    }
  };

  validateEmail = (value) => {
    const { repasswordValid, passwordValid, reemail } = this.state
    const { step3Data: { firstName, lastName } } = this.props
    const emailValid = !!value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); // eslint-disable-line no-useless-escape
    const reemailValid = value === reemail;
    const validStep = emailValid && repasswordValid && passwordValid && reemailValid && firstName && !_.isEmpty(firstName.trim()) && lastName && !_.isEmpty(lastName.trim());
    const publicPortal = ["gmail", "yahoo", "outlook", "hotmail", "aol"];
    if (emailValid){
     let spltVal= value.split("@")[1];
      if (!publicPortal.includes(spltVal.split(".")[0].toLowerCase())) {
        this.setState({ varifiedEmail: true,companyMsg:"" });
      } else {
        this.setState({ varifiedEmail: false,companyMsg:"Please use your company email address" });
      };
    }
    
    this.setState({
      validStep,
      reemailValid,
      emailValid,
    }, () => this.props.handleInputChange({ target: { name: "email", value: value } }));
  }

  validatePassword = (value) => {
    const { passwordRules, emailValid, repassword, reemailValid } = this.state;
    const { step3Data: { firstName, lastName } } = this.props
    let passwordRulesTemp = passwordRules.slice();

    passwordRulesTemp.forEach((element) => {
      element.valid = !!value.match(element.regex);
    });

    // HACK - REFACTOR PASSWORD RULES LOGIC
    if (!!value.match(/[^$*.[\]{}()?\-"!@#%&/\,><':;|_~`\s\w]/)) { // eslint-disable-line no-useless-escape
      passwordRulesTemp[4].valid = false;
    }

    const passwordValid = _.every(passwordRulesTemp, el => el.valid);
    const repasswordValid = value === repassword;
    const validStep = passwordValid && repasswordValid && emailValid && reemailValid && firstName && !_.isEmpty(firstName.trim()) && lastName && !_.isEmpty(lastName.trim());

    this.setState({
      validStep,
      repasswordValid,
      passwordValid,
      passwordRules: passwordRulesTemp,
    }, () => this.props.handleInputChange({ target: { name: "password", value: value } }));
  }

  validateDoubleInput = (value, keyCompare, otherDoubleInputValid) => {
    const { step3Data: { firstName, lastName } } = this.props
    const doubleInputValid = (value === this.state[keyCompare]);
    const validStep = doubleInputValid && this.state.varifiedEmail && otherDoubleInputValid && this.state.passwordValid && this.state.emailValid && firstName && !_.isEmpty(firstName.trim()) && lastName && !_.isEmpty(lastName.trim());

    this.setState({
      validStep,
      ["re" + keyCompare + "Valid"]: doubleInputValid
    });
  }

  handleInputBlur = (event) => {
    const inputName = event.target.getAttribute("name");
    switch (inputName) {
      case "email":
        this.setState({ emailTooltip: false });
        break;
      case "reemail":
        this.setState({ reemailTooltip: false });
        break;
      case "password":
        this.setState({ passwordTooltip: false });
        break;
      case "repassword":
        this.setState({ repasswordTooltip: false });
        break;
      default: break;
    }
  }

  handleInputFocus = (event) => {
    const inputName = event.target.getAttribute("name");
    switch (inputName) {
      case "email":
        this.setState({ emailTooltip: true });
        break;
      case "reemail":
        this.setState({ reemailTooltip: true });
        break;
      case "password":
        this.setState({ passwordTooltip: true });
        break;
      case "repassword":
        this.setState({ repasswordTooltip: true });
        break;
      default: break;
    }
  }

  handleStepSubmit = () => {
    if (this.state.validStep && this.state.varifiedEmail) {
      this.props.handleStepSubmit();
    }
  }

  render() {
    return (
      <Wrapper>
        <HeaderWrapper>
          <BackButton onClick={this.props.handleBack}><FontAwesomeIcon icon={faArrowLeft} /></BackButton>
          <CloseButton onClick={this.props.handleClose}><FontAwesomeIcon icon={faTimes} /></CloseButton>

          <Header3 textAlign="center">Sign Up</Header3>
          <Header5 textAlign="center" textColor={`${reporaColor.brightSkyBlue}`}>Enter the information for your account Administrator</Header5>
        </HeaderWrapper>

        <FormWrapper>
          <FormRow>
            <FormGroup>
              <label>First Name</label>
              <input onChange={this.props.handleInputChange} name="firstName" value={this.props.step3Data.firstName || ""} disabled={this.props.validSocialData} />
            </FormGroup>
            <FormGroup>
              <label>Last Name</label>
              <input onChange={this.props.handleInputChange} name="lastName" value={this.props.step3Data.lastName || ""} disabled={this.props.validSocialData} />
            </FormGroup>
          </FormRow>

          <FormGroup>
            <label>Email Address</label>
            <input name="email"
              value={this.state.email}
              onChange={this.handleFormValidation}
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur} />
              <InvalidSpan>
              {this.state.companyMsg}
              </InvalidSpan>
            <InputTooltip
              isValid={this.state.emailValid}
              isVisible={this.state.emailTooltip}>
              <Body5 textAlign="left">
                {this.state.emailValid
                  ? "Valid Email Format"
                  : "Invalid Email Format"
                }
              </Body5>
            </InputTooltip>
          </FormGroup>

          <FormGroup>
            <label>Re-Enter Email Address</label>
            <input name="reemail"
              value={this.state.reemail}
              onChange={this.handleFormValidation}
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur} />
            <InputTooltip
              isValid={this.state.reemailValid}
              isVisible={this.state.reemailTooltip}>
              <Body5 textAlign="left">
                {this.state.reemailValid
                  ? "Email is Matching"
                  : "Email Doesn't Match"
                }
              </Body5>
            </InputTooltip>
          </FormGroup>

          <FormGroup>
            <label>Password</label>
            <input
              name="password"
              type="password"
              value={this.state.password}
              onChange={this.handleFormValidation}
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur} />
            <InputTooltip
              isValid={this.state.passwordValid}
              isVisible={this.state.passwordTooltip}>
              <Body5 textAlign="left">Must contain atleast</Body5>
              <ul >
                {this.state.passwordRules.map((item, index) => {
                  return (
                    <RestrictionItem key={"passwordRule" + index} isValid={item.valid}>
                      <img src="assets/global/check-white.svg" alt="" />
                      <Body5 textAlign="left">
                        {item.description}
                      </Body5>
                    </RestrictionItem>
                  );
                })}
              </ul>
            </InputTooltip>
          </FormGroup>

          <FormGroup>
            <label>Re-Enter Password</label>
            <input
              name="repassword"
              type="password"
              value={this.state.repassword}
              onChange={this.handleFormValidation}
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur} />
            <InputTooltip
              isValid={this.state.repasswordValid}
              isVisible={this.state.repasswordTooltip}>
              <Body5 textAlign="left">
                {this.state.repasswordValid
                  ? "Password is Matching"
                  : "Password Doesn't Match"
                }
              </Body5>
            </InputTooltip>
          </FormGroup>
          <ButtonContainer className="m-b-45">
            <Button
              disabled={!this.state.validStep}
              onClick={this.handleStepSubmit}
              width="100%">
              Create Account
            </Button>
          </ButtonContainer>
        </FormWrapper>
      </Wrapper>
    );
  }
};

export default Step2Customer;
