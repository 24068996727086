const DynamicRating = (data) => {
  try {
    let tempOrginalData = data;
    let lowerValue = data.toLowerCase().split("_");
    let tempArr = [];
    if (lowerValue.length > 1) {
      lowerValue.forEach(function(data, ele) {
        const char = data.charAt(0);
        tempArr.push(char.toUpperCase() + data.slice(1));
      });
      const finalVal = tempArr.join(" ");
      const nospacefinalVal = tempArr.join("");
      let loweredVal = finalVal;
      const finalLowerVal =
        loweredVal.charAt(0).toLowerCase() + loweredVal.slice(1);
      const finalNoSpaceLowerVal =
        nospacefinalVal.charAt(0).toLowerCase() + nospacefinalVal.slice(1);
      return {
        key: tempOrginalData,
        value: finalVal,
        lowerFirstVal: finalLowerVal,
        noSpace: finalNoSpaceLowerVal,
      };
    } else {
      const finalVal =
        lowerValue[0].charAt(0).toUpperCase() + lowerValue[0].slice(1);
      const lowerVal =
        lowerValue[0].charAt(0).toLowerCase() + lowerValue[0].slice(1);
      return {
        key: tempOrginalData,
        value: finalVal,
        lowerFirstVal: lowerVal,
        noSpace: lowerVal,
      };
    }
  } catch (e) {
    console.log(e);
  }
};

export const SplitKeySpace = (data) => {
  try {
    if (data) {
      let temp = data;
      let count = 0;

      data.split("").forEach((e, i) => {
        if (e == e.toUpperCase()) {
          temp = replaceChar(temp, "_" + e, i + count);
          count++;
        }
      });
      return temp;
    }
  } catch (e) {
    console.log(e);
  }
};

function replaceChar(origString, replaceChar, index) {
  let firstPart = origString.substr(0, index);
  let lastPart = origString.substr(index + 1);

  let newString = firstPart + replaceChar + lastPart;
  return newString;
}

export const DynamicCapital = (data) => {
  try {
    if (data) {
      let tempOrginalData = data;
      let lowerValue = data.toLowerCase().split(" ");
      let tempArr = [];
      if (lowerValue.length > 1) {
        lowerValue.forEach(function(data, ele) {
          const char = data.charAt(0);
          tempArr.push(char.toUpperCase() + data.slice(1));
        });
        const finalVal = tempArr.join(" ");
        const nospacefinalVal = tempArr.join("");
        let loweredVal = finalVal;
        const finalLowerVal =
          loweredVal.charAt(0).toLowerCase() + loweredVal.slice(1);
        const finalNoSpaceLowerVal =
          nospacefinalVal.charAt(0).toLowerCase() + nospacefinalVal.slice(1);
        return {
          key: tempOrginalData,
          value: finalVal,
          lowerFirstVal: finalLowerVal,
          noSpace: finalNoSpaceLowerVal,
          underScoreUpperCase: tempArr.join("_").toUpperCase(),
          data: data,
        };
      } else {
        const finalVal =
          lowerValue[0].charAt(0).toUpperCase() + lowerValue[0].slice(1);
        const lowerVal =
          lowerValue[0].charAt(0).toLowerCase() + lowerValue[0].slice(1);
        return {
          key: tempOrginalData,
          value: finalVal,
          lowerFirstVal: lowerVal,
          noSpace: lowerVal,
          underScoreUpperCase: lowerValue.join("").toUpperCase(),
          data: data,
        };
      }
    }
  } catch (e) {}
};
export default DynamicRating;
