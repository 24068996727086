import authdAxios from "../modules/Authentication/utils/authdAxios";
import config from "../config"
import constants from "../constants";
import { get } from "lodash";

const CustomerService = () => {

  function getPastRatings(userId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/reps/view-past-rating`,
          {
            id: userId
          },
          {
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(get(error, "response.data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    })
  }

  function upvote(payload) {
    const {
      feedbackId,
      reviewerId,
      vote
    } = payload
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/reps/up-vote`,
          {
            feedbackId,
            reviewerId,
            vote
          },
          {
            headers: {
              "content-type": "application/json"
            },
          }
        )
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.data.payload);
        })
    })
  }

  function updatePhone(userId, email, phoneNumber, password) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/users/customer/phone`,
          {
            userId,
            email,
            phoneNumber,
            password
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT))
        });
    });
  }

  function updateEmail(userId, email, newEmail, password) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/users/customer/email`,
          {
            userId,
            email,
            newEmail,
            password
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT))
        });
    })
  }

  function updateSecondaryEmail(userId, secondaryEmail) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/users/rep/secondary-email`,
          {
            userId,
            secondaryEmail
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT))
        });
    })
  }

  function updatePassword(oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/auth/change-password`,
          {
            oldPassword,
            newPassword,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT))
        });
    })
  }

  function updateCompany(userId, companyName, jobTitle, country, zipCode, state, city) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company-users/update-company-user`,
          {
            userId,
            companyName,
            jobTitle,
            country,
            zipCode,
            state,
            city
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT))
        });
    });
  }

  function checkEmailExist(email) {
    let obj = {email:email}
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/reps/check-email`,
          {
            tempRep:obj
          },
          {
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.data);
        });
    })
  }

  return {
    getPastRatings,
    upvote,
    updatePhone,
    updateEmail,
    updateSecondaryEmail,
    updatePassword,
    updateCompany,
    checkEmailExist
  };
};

export default CustomerService();
