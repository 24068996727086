import moment from "moment";
import React from "react";
import EditForm from "./EditForm";
import { Row } from "./ListItem";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const CompanyApilist = (props) => {
    const [isOpen , setOpen] = useState(false);
    const {ele , i} = props;

    const handleSetOpen = () => {
      setOpen(false)
    }

    const handleRemoveCompanyKey = () => {
      props.handleRemoveCompanyKey({ companyId : props.ele?.id })
    }

    return (
        <>
            <div  key= {Math.random().toString()}>
            <Row>
            <div>{ele.name}</div>
            <div className="keyDiv">{ele?.companyApiKey}</div>
            <span onClick={()=>setOpen(!isOpen)} style={{ paddingLeft: "5px" }}>
              <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
            </span>
          </Row>
          {isOpen && (
            <EditForm
              {...props}
              oldKey = { ele?.companyApiKey }
              handleRemoveCompanyKey = {handleRemoveCompanyKey}
              handleUpdateCompanyKey = { props.handleUpdateCompanyKey}
              handleSetOpen = {handleSetOpen}
              fromManageCKeys
            />
          )}
          </div>
        </>
    )

}

export default CompanyApilist;