import React from "react";
import styled from "styled-components";

import {
  reporaDevice,
  reporaColor,
  ReporaSubmitButton,
  Body2,
  Body3,
  Body4,
  Body5,
  CheckComponent,
  NoBorderTextArea,
  TextAreaWrapper,
} from "../../../components/global/commonStyles";

import RatingStars from "../../../components/RatingStars/RatingStars";
import LikeDislikeOption from "../../../components/LikeDislikeOption/LikeDislikeOption";
import HelpTooltip from "../../../components/HelpTooltip/HelpTooltip";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  flex-shrink: 0;
  padding: 30px 10px;
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.08);
  width: 100%;

  @media ${reporaDevice.tablet} {
    padding: 30px 15px;
  }

  @media ${reporaDevice.desktop} {
    padding: 30px 50px;
    max-width: 630px;
  }
`;

const RateRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
  align-items: center;
  border-top: solid 1px ${reporaColor.grey91} p {
    text-transform: capitalize;
  }
  p {
    text-transform: capitalize;
  }

  ${(props) =>
    props.last
      ? `border-bottom: solid 1px ${reporaColor.grey91}
        p {
          text-transform: none;
        }
    `
      : null};
`;

const RatingLabels = styled.div`
  display: flex;
  align-self: flex-end;
  width: 145px;
  justify-content: space-between;
  margin-top: 20px;
`;

const CommentLabel = styled.div`
  margin: 20px 0;
`;

const CharsLeftLabel = styled.label`
  position: relative;
  left: 5%;
  p {
    margin-top: 0px !important;
  }
`;

const AnonymousWrapper = styled.div`
  margin: 30px 0 40px;
`;

const RatingsCard = (props) => {
  if (props.userRatings.length === 0 || props.errorMessage !== "") {
    return "";
  }
  return (
    <Wrapper>
      <Body3 textColor={`${reporaColor.brightSkyBlue}`}>
        Rate the skill level of this rep, in the following areas:
      </Body3>
      <RatingLabels>
        <Body5 textColor={`${reporaColor.warmGrey}`}>Poor</Body5>
        <Body5 textColor={`${reporaColor.warmGrey}`}>Average</Body5>
        <Body5 textColor={`${reporaColor.warmGrey}`}>Great</Body5>
      </RatingLabels>
      {props.userRatings.map((item, index) => {
        let catFormatted = item.category.replace(/_/g, " ").toLocaleLowerCase();
        const rating = item.value && parseInt(item.value);

        return (
          <RateRow key={item + index}>
            <Body2>{catFormatted}</Body2>
            <RatingStars
              name={item.category}
              rating={rating}
              big={true}
              onChange={props.handleRateChange}
            />
          </RateRow>
        );
      })}
      <RateRow last>
        <Body2>How was your overall experience?</Body2>
        <LikeDislikeOption
          name="experience"
          selected={props.thumbsUp}
          onChange={props.handleExperienceSelection}
        />
      </RateRow>
      <CommentLabel>
        <Body3 textColor={`${reporaColor.brightSkyBlue}`}>
          Write a comment (optional):
        </Body3>
      </CommentLabel>
      <TextAreaWrapper>
        <NoBorderTextArea
          cols="30"
          rows="5"
          onChange={props.handleCommentChange}
          value={props.detail}
        />
        <CharsLeftLabel>
          <Body4 textColor={`${reporaColor.brightSkyBlue}`}>
            {props.charactersLeft} characters left
          </Body4>
        </CharsLeftLabel>
      </TextAreaWrapper>
      <AnonymousWrapper>
        <CheckComponent textSize="14px" textColor={`${reporaColor.warmGrey}`}>
          <input
            id="anonymousRating"
            type="checkbox"
            value="value1"
            checked={props.anonymous}
            onChange={props.handleAnonymousCheck}
          />
          <label htmlFor="anonymousRating">
            Make my rating anonymous
            <HelpTooltip
              data={
                "A public rating will show your first name and last initial to fellow customers," +
                "while reps and companies will see your full name. Anonymous ratings will hide your name," +
                "will not be posted immediately, and the rating date will only show the month."
              }
            />
          </label>
        </CheckComponent>
      </AnonymousWrapper>
      <ReporaSubmitButton large disabled={!props.isFormValid} type="submit">
        Submit Rating
      </ReporaSubmitButton>
    </Wrapper>
  );
};

export default RatingsCard;
