import { constant } from "lodash";
import React from "react";
import styled from "styled-components";
import DropdownAddOption from "../../../components/Dropdown/DropdownAddOption";

import {
  reporaDevice,
  reporaColor,
  Header3,
  Body4,
  FormGroup,
  FormRow,
} from "../../../components/global/commonStyles";
import InputSuggestion from "../../../components/InputSuggestion/InputSuggestion";
import InputSuggestions from "../../SearchRep/components/InputSuggestions";
import constants from "../../../constants";

const InvalidSpan = styled.div`
  font-size: small;
  color: red;
`;

const Wrapper = styled.div`
  background-color: ${reporaColor.white};
  display: flex;
  flex-shrink: 0;
  padding: 15px 10px 20px;
  flex-direction: column;
  margin-bottom: 30px;
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.08);

  ${Body4} {
    margin: 30px 0 20px;
  }

  @media ${reporaDevice.tablet} {
    padding: 30px 15px;
  }

  @media ${reporaDevice.desktop} {
    padding: 30px 50px;
    max-width: 630px;
  }
  ul,
  il {
    background: #fff;
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  width: 80px !important;
  right: auto;
  label {
    width: 120px;
    flex: 0 0 120px;
    font-size: 12px;
    font-weight: bold;
    color: ${reporaColor.warmGrey};
    margin-right: 15px;
  }

  @media ${reporaDevice.xs} {
    flex-direction: column;

    label {
      flex: auto;
      align-self: flex-start;
    }

    > div {
      width: 100%;
    }
  }
`;

class PersonCard extends React.Component {
  state = {
    selectedCompany : null,
    companyInputValue: '',
    companySuggestions : []
    
  }

  componentDidUpdate (prevProps){
    const {companySuggestions } = this.props;
    if (companySuggestions !== prevProps.companySuggestions) {
      this.setState({
        companySuggestions: companySuggestions.map((suggestion) => {
          const industry = suggestion.industry
            ? `${suggestion.industry} - `
            : "";
          const address =
            suggestion.physicalAddress &&
            suggestion.physicalAddress.state &&
            suggestion.physicalAddress.city
              ? `${suggestion.physicalAddress.state}, ${
                  suggestion.physicalAddress.city
                }`
              : "-";

          return {
            title: suggestion.name,
            subtitle: industry + address,
            picture: suggestion.logo,
            companyId: suggestion.id,
          };
        }),
      });
    }
  }

  handleAddCompany = (companyName) => {
    this.props.handleAddNewCompany(companyName);
  };

  handleSelectCompany = (companyName) => {
    this.props.handleSelectCompany(companyName);
  };


  //handleSelectCompany1 geting data through get rep suggetion api instead of get all and setting it into state 
  
  handleSelectCompany1 = (selectedEl) => {
    this.setState({
      selectedCompany: selectedEl,
      companyInputValue: selectedEl ? selectedEl.title : "",
    });
    this.props.handleSelectCompany1(selectedEl);
  };

  handleInputCompanyChange = (value) => {
    this.setState({
      selectedCompany: null,
      companyInputValue: value,
    });
    this.props.handleSelectCompany1(value)
  };

  handleGetCompanySuggestions = (inputValue) => {
    this.props.handleGetCompanySuggestions(inputValue);
  };

  render() {
    const { firstName, lastName, email } = this.props.userInfo;
    const { errorMessage, ratingKeys } = this.props;
    const {companySuggestions} = this.state;
    return (
      <Wrapper>
        <Header3>Add a Rep</Header3>
        <Body4 textColor={reporaColor.brightSkyBlue}>
          The rep you were looking for is not in our system, but you can still
          enter a rating. Enter your rep’s information below to get started:
        </Body4>

        <FormRow>
          <FormGroup>
            <label>First Name *</label>
            <input
              name="firstName"
              type="text"
              value={firstName}
              disabled={this.props.isDisabled}
              onChange={this.props.handleFirstNameChange}
            />
          </FormGroup>
          <FormGroup>
            <label>Last Name *</label>
            <input
              name="lastName"
              value={lastName}
              disabled={this.props.isDisabled}
              onChange={this.props.handleLastNameChange}
            />
          </FormGroup>
        </FormRow>

        <FormGroup>
          <label>Company</label>
          {/* for getting companys through get all api */}
          {/* <InputSuggestion
            inputPlaceholder="Company name"
            addPlaceholder="Add a Company"
            selectedElement={this.props.currentCompany}
            loading={
              this.props.addCompanyLoading || this.props.companiesFetching
            }
            source={this.props.companies.map((company) => company.name)}
            handleAddElement={this.handleAddCompany}
            handleSelectElement={this.handleSelectCompany}
          /> */}

           {/* for getting companies through get company suggestion api */}
           <InputSuggestions
            name="new-company-suggestions"
            suggestions={companySuggestions}
            placeholder="Company"
            loading={this.props.companySuggestionsFetching}
            handleSelectElement={this.handleSelectCompany1}
            selectedElement={this.state.selectedCompany}
            handleInputChange={this.handleInputCompanyChange}
            inputDelay={500}
            handleGetSuggestions={this.handleGetCompanySuggestions}
          />
        </FormGroup>

        <FormGroup>
          <label>Email Address* </label>
          <input
            name="email"
            type="email"
            value={email}
            disabled={this.props.isDisabled}
            onChange={this.props.handleEmailChange}
          />

          <InvalidSpan>{errorMessage}</InvalidSpan>
        </FormGroup>

        <FormGroup>
          <label>Type of Rep *</label>
          <DropdownContainer>
            <DropdownAddOption
              key="sort"
              required
              isCompanyAdd={false}
              options={ratingKeys}
              disableInpt={true}
              left="0"
              value={this.props.functionSelected}
              // left="0"
              width="300px"
              optionWidth="280px"
              placeholder="Select"
              onClick={this.props.handleFunctionsChange}
            />
          </DropdownContainer>
        </FormGroup>
      </Wrapper>
    );
  }
}

export default PersonCard;
