import notificationService from "../services/NotificationService";

/* Action Constants */
export const CONTACT_US_REQUESTED = "CONTACT_US_REQUESTED";
export const CONTACT_US_SUCCEEDED = "CONTACT_US_SUCCEEDED";
export const CONTACT_US_FAILED = "CONTACT_US_FAILED";

export const CLOSE_CONTACT_US_REQUESTED = "CLOSE_CONTACT_US_REQUESTED";

/* Action Creators */
const contactUsRequested = () => ({ type: CONTACT_US_REQUESTED });
const contactUsSucceeded = data => ({ type: CONTACT_US_SUCCEEDED, payload: data });
const contactUsFailed = error => ({ type: CONTACT_US_FAILED, payload: error });

const closeContactUsRequested = () => ({ type: CLOSE_CONTACT_US_REQUESTED });

/* Async Actions */
export function submitContactUs(firstName, lastName, email, phoneNumber, message, isCompany) {
  return dispatch => {
    dispatch(contactUsRequested());

    notificationService.submitContactUs(firstName, lastName, email, phoneNumber, message, isCompany)
      .then(response => {
        dispatch(contactUsSucceeded(response));
      })
      .catch(error => {
        dispatch(contactUsFailed(error));
      });
  };
}

export function closeContactUs() {
  return dispatch => {
    dispatch(closeContactUsRequested());
  };
}