import React from "react";
import styled from "styled-components";

import { reporaColor, Body4, Body5 } from "../../../components/global/commonStyles";

const Wrapper = styled.div`
  background: white;
  padding: 25px 20px 30px;
  margin-top: 10px;
`;

const CompanyName = styled.div`
  display: flex;
  justify-content: space-between;
`;


const ExperienceCard = props => {
  return (
    <Wrapper>
      <CompanyName>
        <Body4>{props.companyName}</Body4>
        <Body5 textColor={reporaColor.warmGrey}>{props.date || ""}</Body5>
      </CompanyName>
      <Body5 textColor={reporaColor.warmGrey}>{props.location || ""}</Body5>
      <Body5 textColor={reporaColor.brightSkyBlue}>{props.experience || ""}</Body5>
    </Wrapper>
  );
};

export default ExperienceCard;
