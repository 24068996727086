import React from "react";
import styled from "styled-components";
import { Header3, Body4, reporaColor, ReporaButton } from "../../../components/global/commonStyles";
import { Wrapper, HeaderWrapper, FormWrapper } from "./CommonStyles";
import CompanySVG from "../assets/default_company_logo.svg";

const MessageWrapper = styled(FormWrapper)`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageWrapper = styled.div`
  max-width: 450px;
  width: 100%;
  margin-bottom:30px;
  display: flex;
  justify-content: center;
  margin: 50px 0;

  img:first-child {
    margin-right: 10px;
  }
`;

const UserImage = styled.img`
  border-radius: 50%;
`;

const StepConfirmation = props => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <Header3 textAlign="center">
          {props.responseMessage && props.responseMessage.statusCode === 200
            ? "Almost there!"
            : <div><p>Sign Up Error!</p><h6>{
              (typeof props.responseMessage) === "string" ? props.responseMessage : ""
            }</h6></div>
          }
        </Header3>
      </HeaderWrapper>
      <MessageWrapper>
        {props.responseMessage && props.responseMessage.statusCode === 200
          ? <ImageWrapper>
            {props.picture ?
              <UserImage src={props.picture} />
              :
              props.userRole === "ADMIN" && <img src={CompanySVG} alt="company-logo" />
            }
            <img src="/assets/global/check-green.svg" alt="" />
          </ImageWrapper>
          : null
        }
        <Body4 textAlign="center" textColor={`${reporaColor.brightSkyBlue}`}>
          {props.responseMessage && props.responseMessage.statusCode === 200
            ? props.customMessage
            : props.responseMessage.payload
          }
        </Body4>
        &nbsp;
        {props.customMessage2 && props.responseMessage.statusCode === 200
          ? <Body4 textAlign="center" textColor={`${reporaColor.brightSkyBlue}`}>{props.customMessage2}</Body4>
          : null
        }
      </MessageWrapper>
      <ReporaButton className="m-b-45" onClick={() => props.handleSignUpFinish(props.responseMessage && props.responseMessage.statusCode)}>Okay</ReporaButton>
    </Wrapper>
  );
};

export default StepConfirmation;
