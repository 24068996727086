import React from "react";
import styled from "styled-components";
import { reporaColor, Body3 } from "../../../components/global/commonStyles";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: white;
  margin-top: 5px;
  padding: 15px 20px;
  flex-shrink: 0;
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.08);
   margin-bottom: 5px;
`;

const ExperienceCard = props => {
  let thumbsUpAverage = parseFloat(Math.round(props.thumbsUpAverage)).toFixed(1);

  return (
    <Wrapper>
      <Body3 textColor={`${reporaColor.warmGrey}`}>Positive Experience </Body3>
      <ProgressBar size="12px" percentage={thumbsUpAverage} label={thumbsUpAverage} labelColor={reporaColor.warmGrey} percentageSign />
    </Wrapper>
  );
};

export default ExperienceCard;
