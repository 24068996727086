import React from "react"
import styled from "styled-components"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { reporaColor } from "../../../components/global/commonStyles"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 731px;
  margin: 0 auto;
  padding-top: 150px;
  padding-bottom: 150px;
  background-color: ${reporaColor.white};
  border-radius: 12.5px;
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.08);
`;

export const CloseButton = styled.span`
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 20px;
  cursor: pointer;
  color: ${reporaColor.warmGrey};
`;

export const Message = styled.p`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: ${props => (props.primary ? reporaColor.brightSkyBlue : reporaColor.warmGrey)};
`;

const Confirmation = props => {
  return (
    <Wrapper>
      <CloseButton onClick={() => props.handleClose()}><FontAwesomeIcon icon={faTimes} /></CloseButton>
      <img className="m-b-25" src="/assets/global/check-green.svg" alt="check-icon" />
      <h1 className="m-b-30">Thank You!</h1>
      <Message className="m-b-30" primary>Your message has been sent</Message>
      <Message>We will reach out to you directly <br></br> in regard to your message.</Message>
    </Wrapper>
  );
}

export default Confirmation
