import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import LazyLoader from "./LazyLoader";

const PrivateRoute = ({ componentLoader, pathName, isFetching, isAuthenticated, requestedLogout, isValidPathForUserRole, location, ...rest }) => {
  let componentToRender;

  if (isFetching) {
    componentToRender = () => null;
  } else if (!isAuthenticated) {
    if (requestedLogout) {
      componentToRender = () => <Redirect to={{ pathname: "/login" }} />;
    } else {
      // ONLY IF THE SESSION HAS EXPIRED AFTER A NEW LOGIN WE NEED TO REDIRECT THE USER TO THE LAST PAGE
      const currentPath = location.pathname + location.search + location.hash;
      componentToRender = () => <Redirect to={{ pathname: "/login", state: { from: currentPath } }} />;
    }
  } else if (!isValidPathForUserRole) {
    componentToRender = () => <Redirect to={{ pathname: "/login" }} />;
  } else {
    const LazyComponent = LazyLoader(pathName, componentLoader);
    componentToRender = props => <LazyComponent {...props} />;
  }

  return (
    <Route
      {...rest}
      render={componentToRender}
    />
  );
};

PrivateRoute.propTypes = {
  componentLoader: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isValidPathForUserRole: PropTypes.bool.isRequired
};

export default PrivateRoute;
