import React from "react";
import styled from "styled-components";

import { reporaDevice, reporaColor, Title, Header2, Header3, Body4 } from "../../components/global/commonStyles";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5% 0;
`;

const PanelContent =styled.div`
  width: 95%;
  max-width: 850px;

  h4 {
    margin: 50px 0;
  }
`;

const Columns = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  max-width: 1300px;
  align-items: flex-start;
  justify-content: center;

`;

const Column = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px 30px;
  width: 100%;
  max-width: 400px;

  @media ${reporaDevice.tablet} {
    width: calc(100% / 3);
    padding: 0 3%;
  }
  h3 {
    margin: 30px 0;
  }
`;

const ColumnImg = styled.div`
  width: 100%;
  height: ${props => props.size==="small" ?"50px":"100px"};
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width:  ${props => props.size==="small" ?"50px":"300px"};
    width: 100%;
  }

  @media ${reporaDevice.tablet} {
    padding:  ${props => props.size==="small" ?"25% 0 0":"30% 0 5%"};
  }

`;

class PanelColumns extends React.Component {
  render() {
    return (
      <Wrapper>
        <PanelContent>
          <Header2 textAlign="center">{this.props.data.header}</Header2>
          <Title textAlign="center" textColor={`${reporaColor.warmGrey}`}>{this.props.data.title}</Title>
        </PanelContent>
        <Columns>
          {Object.keys(this.props.data.columns).map((key, index) => {
            const columnData = this.props.data.columns[key];
            return (
              <Column key={key + index}>
                <ColumnImg size={this.props.data.size}>
                  <img src={columnData.imgSrc} alt=""/>
                </ColumnImg>
                <Header3 textAlign="center">{columnData.header}</Header3>
                <Body4 textAlign="center">{columnData.description}</Body4>
              </Column>
            );
          })}
        </Columns>
      </Wrapper>
    )
  }
}



export const Unwrapped = PanelColumns;
export default PanelColumns;
