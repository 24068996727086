
import React from "react"; 
import styled from "styled-components";
import { reporaColor } from "../../../components/global/commonStyles";
 

export const Wrapper = styled.div` 
  width: 100%; 
  align-items: center;
  justify-content: center;
  margin: 20px auto 20px;
   border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 #00000033;
 
`;
export const Row = styled.div`
  background: white;
  display: flex;
  height: 60px;
  border-bottom: 1px solid ${reporaColor.grey91};
  >div {
    flex: 1;
    font-size: 15px;
    padding-left: 20px;
    padding-right: 10px;
    display: flex;
    align-items: center;
     background: ${reporaColor.grey98};
    user-select: none;
    &:last-child {
      border-right: none;
    }
  }

  >span {
    flex: 1; 
    padding-left: 15px;
    padding-right: 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
     user-select: none;
    &:last-child {
      border-right: none;
    }
  }

  &:last-child {
    border-bottom: none;
  }
  &:nth-child(even) {
   }
`;



export default class View extends React.Component {
    state = {

    };

    componentDidMount() {
    }



    render() {

        return (
            <Wrapper>
                <Row>
                    <div>
                        Dispute Id
                    </div>
                    <div>
                        Date
                    </div>
                    <div>
                        Review Id
                    </div>
                    <div>
                        Review Date
                    </div>
                    <div>
                        Customer
                    </div>
                    <div>
                        Rep
                    </div>

                    <div>
                        Company
                    </div>
                    <div>
                        Disputed By
                    </div>

                </Row>
                <Row>
                    <span>
                        123
                    </span>
                    <span>
                        12/5/21
                    </span>
                    <span>
                        45678
                    </span>
                    <span>
                        12/5/21
                    </span>
                    <span>
                        John Smith
                    </span>
                    <span>
                        John Ghashghai
                    </span>

                    <span>
                        Cypress
                    </span>
                    <span>
                        Company
                    </span>
                </Row>
                <Row>
                    <span>
                        123
                    </span>
                    <span>
                        12/5/21
                    </span>
                    <span>
                        45678
                    </span>
                    <span>
                        12/5/21
                    </span>
                    <span>
                        John Smith
                    </span>
                    <span>
                        John Ghashghai
                    </span>

                    <span>
                        Cypress
                    </span>
                    <span>
                        Company
                    </span>
                </Row>
            </Wrapper>
        );
    }

};


