import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { Header3, Header5, Body5, reporaColor, ReporaButton, FormGroup, InputTooltip } from "../../../components/global/commonStyles";
import { Wrapper, BackButton, CloseButton, HeaderWrapper, FormWrapper, ButtonContainer, SideLines, FormDisclaimer } from "./CommonStyles";

const SocialButtons = styled(FormWrapper)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: relative;

  a {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const RestrictionItem = styled.li`
  display: flex;
  align-items: center;

  img {
    opacity: ${props => props.isValid ? "1" : "0"};
    margin-right: 5px;
    width: 10px;
  }
`;

const TermsLink = styled.a`
  color: ${reporaColor.warmGrey};
`;

class Step2Customer extends React.Component {
  state = {
    validStep: false,
    emailValid: false,
    passwordValid: false,
    repasswordValid: false,
    emailTooltip: false,
    passwordTooltip: false,
    repasswordTooltip: false,
    email: "",
    password: "",
    repassword: "",
    passwordRules: [
      { description: "8 characters", valid: false, regex: /^(?=.*\d).{8,}$/ },
      { description: "1 capital letter", valid: false, regex: /[A-Z]/g },
      { description: "1 lowercase letter", valid: false, regex: /[a-z]/g },
      { description: "1 number", valid: false, regex: /[0-9]/g },
      { description: "1 special character", valid: false, regex: /[^\w\s]/ }
    ]
  };
  componentDidMount() {
    document.getElementById("contentLoader").scrollIntoView();
    window.scrollTo(0, 0);
  }
  handleFormValidation = event => {
    const inputName = event.target.getAttribute("name");
    let inputValue = event.target.value;

    if (inputName === "password") {
      inputValue = event.target && event.target.value && event.target.value.trim();
    }

    this.setState({ [inputName]: inputValue });

    switch (inputName) {
      case "email":
        this.validateEmail(inputValue);
        this.props.handleInputChange(event);
        break;
      case "password":
        this.validatePassword(inputValue);
        this.props.handleInputChange(event);
        break;
      case "repassword":
        this.validateRepassword(inputValue);
        break;
      default:
        break;
    }
  }

  validateEmail = (value) => {
    const { repasswordValid, passwordValid } = this.state
    const emailValid = !!value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); // eslint-disable-line no-useless-escape
    const validstep = (emailValid && repasswordValid && passwordValid);

    this.setState({
      emailValid: emailValid,
      validStep: validstep
    });
  }

  validatePassword = (value) => {
    const { passwordRules, emailValid, repassword } = this.state
    let passwordRulesTemp = passwordRules.slice();

    passwordRulesTemp.forEach((element) => {
      element.valid = !!value.match(element.regex);
    });

    // HACK - REFACTOR PASSWORD RULES LOGIC
    if (!!value.match(/[^$*.[\]{}()?\-"!@#%&/\,><':;|_~`\s\w]/)) { // eslint-disable-line no-useless-escape
      passwordRulesTemp[4].valid = false;
    }

    const repasswordValid = value === repassword;
    const passValid = _.every(passwordRulesTemp, el => el.valid);
    const validStep = (passValid && repasswordValid && emailValid);

    this.setState({
      passwordValid: passValid,
      passwordRules: passwordRulesTemp,
      validStep: validStep,
      repasswordValid
    });
  }

  validateRepassword = (value) => {
    const { password, passwordValid, emailValid } = this.state
    const repassValid = (value === password);
    const validStep = (repassValid && passwordValid && emailValid);

    this.setState({
      repasswordValid: repassValid,
      validStep: validStep
    });
  }

  handleInputBlur = (event) => {
    const inputName = event.target.getAttribute("name");
    switch (inputName) {
      case "email":
        this.setState({ emailTooltip: false });
        break;
      case "password":
        this.setState({ passwordTooltip: false });
        break;
      case "repassword":
        this.setState({ repasswordTooltip: false });
        break;
      default: break;
    }
  }

  handleInputFocus = (event) => {
    const inputName = event.target.getAttribute("name");
    switch (inputName) {
      case "email":
        this.setState({ emailTooltip: true });
        break;
      case "password":
        this.setState({ passwordTooltip: true });
        break;
      case "repassword":
        this.setState({ repasswordTooltip: true });
        break;
      default: break;
    }
  }

  handleStepSubmit = () => {
    if (this.state.validStep) {
      this.props.handleStepSubmit();
    }
  }

  render() {
    return (
      <Wrapper>
        <BackButton onClick={this.props.handleBack}><FontAwesomeIcon icon={faArrowLeft} /></BackButton>
        <CloseButton onClick={this.props.handleClose}><FontAwesomeIcon icon={faTimes} /></CloseButton>
        <HeaderWrapper>
          <Header3 textAlign="center">Sign Up</Header3>
          <Header5 textAlign="center" textColor={`${reporaColor.brightSkyBlue}`}>Sign up using your favorite social network</Header5>
          {this.props.errorMessage
            ? <Header5 textAlign="center" textColor={`${reporaColor.watermelon}`}>{this.props.errorMessage}, try again.</Header5>
            : null
          }
        </HeaderWrapper>
        <SocialButtons>
          <ReporaButton onClick={() => this.props.handleGetSocialUrl("LINKEDIN")} linked>Linked In</ReporaButton>
          <ReporaButton onClick={() => this.props.handleGetSocialUrl("GOOGLE")} google>Google</ReporaButton>
          <ReporaButton onClick={() => this.props.handleGetSocialUrl("FACEBOOK")} facebook>Facebook</ReporaButton>
        </SocialButtons>
        <FormWrapper>
          <SideLines><span>Or Sign Up using email</span></SideLines>
          <FormGroup>
            <label>Email Address</label>
            <input name="email"
              value={this.state.email}
              onChange={this.handleFormValidation}
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur} />
            <InputTooltip
              isValid={this.state.emailValid}
              isVisible={this.state.emailTooltip}>
              <Body5 textAlign="left">
                {this.state.emailValid
                  ? "Valid Email Format"
                  : "Invalid Email Format"
                }
              </Body5>
            </InputTooltip>
          </FormGroup>
          <FormGroup>
            <label>Password</label>
            <input
              name="password"
              type="password"
              value={this.state.password}
              onChange={this.handleFormValidation}
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur} />
            <InputTooltip
              isValid={this.state.passwordValid}
              isVisible={this.state.passwordTooltip}>
              <Body5 textAlign="left">Must contain atleast</Body5>
              <ul >
                {this.state.passwordRules.map((item, index) => {
                  return (
                    <RestrictionItem key={"passwordRule" + index} isValid={item.valid}>
                      <img src="assets/global/check-white.svg" alt="" />
                      <Body5 textAlign="left">
                        {item.description}
                      </Body5>
                    </RestrictionItem>
                  );
                })}
              </ul>
            </InputTooltip>
          </FormGroup>
          <FormGroup>
            <label>Re-Enter Password</label>
            <input
              name="repassword"
              type="password"
              value={this.state.repassword}
              onChange={this.handleFormValidation}
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur} />
            <InputTooltip
              isValid={this.state.repasswordValid}
              isVisible={this.state.repasswordTooltip}>
              <Body5 textAlign="left">
                {this.state.repasswordValid
                  ? "Password is Matching"
                  : "Password Doesn't Match"
                }
              </Body5>
            </InputTooltip>
          </FormGroup>
          <ButtonContainer>
            <ReporaButton
              className="m-t-10"
              disabled={!this.state.validStep}
              onClick={this.handleStepSubmit}
              full>
              Continue
            </ReporaButton>
          </ButtonContainer>
          <FormDisclaimer> By signing up, you agree to the Repora <TermsLink href={"/terms"}>Terms of Service</TermsLink></FormDisclaimer>
        </FormWrapper>
      </Wrapper>
    );
  }
};

export default Step2Customer;
