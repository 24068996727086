import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Loader from "../../../components/Loader/Loader";
import Button from "../../../components/Buttons/Button";
import { Header3, Header5, reporaColor, ReporaButton, FormGroup, reporaDevice } from "../../../components/global/commonStyles";
import {
  Wrapper, BackButton, CloseButton,
  HeaderWrapper, FormWrapper, ButtonContainer,
  SideLines, ForgotLink, SignupLink, CreateContainer, FormError
} from "./CommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const SocialButtons = styled(FormWrapper)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
  
  a {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media ${reporaDevice.xs} {
    flex-direction: column;

    a {
      margin: 0;

      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
    }
  }
`;

class Step2Customer extends React.Component {
  state = {
    validStep: false,
    repasswordValid: false,
    repasswordTooltip: false,
    email: "",
    password: "",
    passwordRules: [
      { description: "8 characters", valid: false, regex: /^(?=.*\d).{8,}$/ },
      { description: "1 capital letter", valid: false, regex: /[A-Z]/g },
      { description: "1 lowercase letter", valid: false, regex: /[a-z]/g },
      { description: "1 number", valid: false, regex: /[0-9]/g },
      { description: "1 special character", valid: false, regex: /[^\w]/ }
    ]
  };

  componentDidMount() {
    document.getElementById("contentLoader").scrollIntoView();
    window.scrollTo(0, 0);
    this.props.clearErrorMessages();
    document.getElementsByName("email")[0].focus = true

  }

  handleFormValidation = (event) => {
    const inputName = event.target.getAttribute("name");
    const inputValue = event.target.value
    const { password, email } = this.state;
    this.setState({ [inputName]: inputValue });

    switch (inputName) {
      case "email":
        this.setState({ validStep: (!!password && !!inputValue) });
        this.props.handleInputChange(event);
        break;
      case "password":
        this.setState({ validStep: (!!inputValue && !!email) });
        this.props.handleInputChange(event);
        break;
      case "repassword":
        this.validateRepassword(inputValue);
        break;
      default:
        break;
    }
  }

  handleInputBlur = (event) => {
    const inputName = event.target.getAttribute("name");
    switch (inputName) {
      case "password":
        this.setState({ passwordTooltip: false });
        break;
      case "repassword":
        this.setState({ repasswordTooltip: false });
        break;
      default: break;
    }
  }

  handleInputFocus = (event) => {
    const inputName = event.target.getAttribute("name");
    switch (inputName) {
      case "password":
        this.setState({ passwordTooltip: true });
        break;
      case "repassword":
        this.setState({ repasswordTooltip: true });
        break;
      default: break;
    }
  }

  handleStepSubmit = (e) => {
    e.preventDefault();
    if (this.state.validStep) {
      this.props.handleStepSubmit();
    }
  }

  render() {
    const { validStep } = this.state;

    return (
      <section>
        <Wrapper>
          {this.props.isFetching && <Loader overlayed size="medium" />}

          <BackButton onClick={this.props.handleBack}><FontAwesomeIcon icon={faArrowLeft} /></BackButton>
          <CloseButton onClick={this.props.handleClose}><FontAwesomeIcon icon={faTimes} /></CloseButton>

          <HeaderWrapper>
            <Header3 textAlign="center">Log In</Header3>
            <Header5 textAlign="center" textColor={`${reporaColor.warmGrey}`}>Using your social account:</Header5>
          </HeaderWrapper>

          <SocialButtons>
            <ReporaButton onClick={() => this.props.handleGetSocialUrl("LINKEDIN")} linked>Linked In</ReporaButton>
            <ReporaButton onClick={() => this.props.handleGetSocialUrl("GOOGLE")} google>Google</ReporaButton>
            <ReporaButton onClick={() => this.props.handleGetSocialUrl("FACEBOOK")} facebook>Facebook</ReporaButton>
          </SocialButtons>

          <FormWrapper>
            <SideLines><span>Or Log In using:</span></SideLines>

            <FormGroup>
              <label>Email Address</label>
              <input
                name="email"
                value={this.state.email}
                autoFocus
                onChange={this.handleFormValidation} />
            </FormGroup>

            <FormGroup>
              <label>Password</label>
              <input
                name="password"
                type="password"
                value={this.state.password}
                onChange={this.handleFormValidation}
                onFocus={this.handleInputFocus}
                onBlur={this.handleInputBlur} />
            </FormGroup>

            <ForgotLink><Link to="/reset-password">Forgot?</Link></ForgotLink>

            {this.props.loginErrorMessage && <FormError>{this.props.loginErrorMessage}</FormError>}

            <FormGroup>
              <ButtonContainer>
                <Button width="100%" type={"Submit"} disabled={!validStep} onClick={this.handleStepSubmit}>Log In</Button>
              </ButtonContainer>
            </FormGroup>

          </FormWrapper>
        </Wrapper>

        <CreateContainer>
          <span>Don't have an account? <SignupLink><Link to="/signup">Sign Up</Link></SignupLink></span>
        </CreateContainer>
      </section>
    );
  }
};

export default Step2Customer;
