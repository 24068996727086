import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import * as preferencesActions from "../../../actions/companyActions";
import Button from "../../../components/Buttons/Button";
import Loader from "../../../components/Loader/Loader";
import { reporaColor } from "../../../components/global/commonStyles";

export const CheckboxOptions = styled.div`
  margin: 10px 0px;
  background-color: ${reporaColor.lightGrey};
  padding: 10px;
  margin-left: 20px;
  height: 200px;
  overflow-y: scroll;
`;

const AddTagOptions = (props) => {
  const dispatch = useDispatch();
  const [selectedTags, setselectedTags] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [tagList2, setTagList2] = useState(props.tagNames);

  useEffect(() => {
    let tagList1 = [];
    if (props.tagList && JSON.stringify(props.tagList) != {}) {
      props.tagList.forEach((i) => {
        tagList1.push(i.tagName);
      });
    }
    tagList1.sort((a, b) => a.localeCompare(b));
    setTagList(tagList1);
    if (props.tagNames.length > 0) {
      let existedIds = [];
      props.tagNames.forEach((i) => {
        let data = props.tagList.filter((ii) => ii.tagName == i);
        if (data.length > 0) {
          existedIds.push(data[0].id);
        }
        setselectedTags(existedIds);
      });
    }
  }, [props.tagList, props.tagNames]);

  const handleAddFeedbackTags = (e) => {
    dispatch(
      preferencesActions.handleAddSelectedTag(
        props.feedbackId,
        [...selectedTags],
        props.feedbackCategory,
        props.companyId,
        props.repId,
        props.programId
      )
    );
    props.cancel();
  };

  const handleAddSelectedTag = (e, attr) => {
    const itemalreadychecked = tagList2.filter((i) => i === attr);
    const idalreadyexist = props.tagList.find((i) => i.tagName == attr);
    if (itemalreadychecked.length > 0) {
      let t3 = tagList2.filter((i) => i !== attr);
      let t4 = selectedTags.filter((i) => i !== idalreadyexist.id);
      setTagList2(t3);
      setselectedTags(t4);
    } else {
      if (selectedTags.indexOf(idalreadyexist.id) === -1) {
        setselectedTags([...selectedTags, idalreadyexist.id]);
        setTagList2([...tagList2, idalreadyexist.tagName]);
      } else {
        let filterattr = selectedTags.filter((i) => i !== idalreadyexist.id);
        setselectedTags([...filterattr]);
      }
    }
  };

  const handleCheckedItems = (attr) => {
    if (props.tagNames.length > 0) {
      let checked = tagList2.filter((i) => i === attr);
      return checked.length > 0;
    }
  };

  return (
    <Container>
      {props.isTagsLoading ? (
        <Loader overlayed />
      ) : (
        <div>
          <CheckboxOptions>
            <AttributsWrapper>
              {tagList.map((attr) => {
                return (
                  <InputWrapper key={attr}>
                    <input
                      type="checkbox"
                      key={`input-${attr}`}
                      checked={handleCheckedItems(attr)}
                      onClick={(e) => {
                        handleAddSelectedTag(e, attr);
                      }}
                    />
                    {attr}
                  </InputWrapper>
                );
              })}
            </AttributsWrapper>
          </CheckboxOptions>
          <BottomButtonBlock>
            <Button
              className="m-t-5"
              size="small"
              onClick={() => props.cancel()}
              height={"30px"}
              color="white"
            >
              Cancel
            </Button>
            <div style={{ width: "10px" }}>
              {/* For padding between buttons */}
            </div>
            <Button
              className="m-t-5"
              size="small"
              height={"30px"}
              onClick={() => {
                handleAddFeedbackTags();
              }}
              color="green"
            >
              Save
            </Button>
          </BottomButtonBlock>
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  companyId: state.auth.userData.companyId,
  attributesList: state.reportingPreferences.attributesList,
  isAtributesLoading: state.reportingPreferences.isAtributesLoading,
  programAdded: state.reportingPreferences.programAdded,
  isProgramAdding: state.reportingPreferences.isProgramAdding,
  tagList: state.reportingPreferences.tagList,
  isTagsLoading: state.reportingPreferences.isTagsLoading,
  isTagAttached: state.company.isTagAttached,
});
export default connect(mapStateToProps, null)(AddTagOptions);

export const InputWrapper = styled.div`
  color: ${(props) => (props.exists ? "lightgray" : "default")};
  margin-bottom: 5px;
`;

export const BottomButtonBlock = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
`;

export const AttributsWrapper = styled.div`
  display: block;
  width: 100%;
`;

export const SelectionBlock = styled.div`
  height: 300px;
  width: 35%;
  margin-left: 10px;
  max-height: 300px;
  overflow: auto;
  border: 1px solid;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
`;
export const SearchWrapper = styled.div`
  width: ${(props) => props.width || "auto"};
  margin-left: 10px;
  input {
    width: 100%;
  }
`;

export const InstructionWrapper = styled(SearchWrapper)`
  // margin-top: 20px;
  width: 30%;
  .react-autosuggest__input {
    height: 300px;
  }
`;

export const Container = styled.div`
  height: 400px;
  width: 80%;
`;
