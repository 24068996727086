import { MENU_OPENED, MENU_CLOSED } from "../actions/menuActions";

const initialState = {
  isOpen: false
}

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case MENU_OPENED:
      return Object.assign({}, state, { isOpen: true });
    case MENU_CLOSED:
      return Object.assign({}, state, { isOpen: false });
    default:
      return state;
  }
};

export default menuReducer;
