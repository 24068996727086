import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { reporaColor, ReporaButton, FormGroup, InputTooltip, Body5 } from "../../../components/global/commonStyles";
import Loader from "../../../components/Loader/Loader";

const Wrapper = styled.div`
  max-width: 502px;
  width: 100%;
  border-radius: 2px;
  background: white;
  box-shadow: 0 0 8px 0 ${reporaColor.black02};
  margin: 0 auto;
  margin-top: 60px;
  padding: 35px 55px;
  position: relative;
  z-index: 1;
`;

const Header = styled.h1`
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  margin-bottom: 50px;
`;

const ErrorMessage = styled.div`
  margin-top: 30px;
  color: ${reporaColor.watermelon};
`;

const RestrictionItem = styled.li`
  display: flex;
  align-items: center;

  img {
    opacity: ${props => props.isValid ? 1 : 0};
    margin-right: 5px;
    width: 10px;
  }
`;

const SubHeader = styled.p`
  color: ${reporaColor.brightSkyBlue};
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  margin-top: 25px;
  margin-bottom: 20px;
`;

class SendForgotEmail extends React.Component {
  state = {
    password: "",
    repassword: "",

    passwordValid: false,
    repasswordValid: false,

    passwordTooltip: false,
    repasswordTooltip: false,

    passwordRules: [
      { description: "8 characters", valid: false, regex: /^(?=.*\d).{8,}$/ },
      { description: "1 capital letter", valid: false, regex: /[A-Z]/g },
      { description: "1 lowercase letter", valid: false, regex: /[a-z]/g },
      { description: "1 number", valid: false, regex: /[0-9]/g },
      { description: "1 special character", valid: false, regex: /[^\w\s]/ }
    ]
  }

  componentDidMount() {
    this.props.handleForgotPasswordClear();
  }

  handleInputChange = event => {
    let value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    const name = event.target.name;

    if (name === "password" || name === "repassword") {
      value = event.target.value.trim()
    }

    this.setState({
      [name]: value
    });

    if (name === "password") {
      this.validatePassword(value);
    }

    if (name === "repassword") {
      this.validateRepassword(value);
    }
  }

  handleSubmitButton = () => {
    const { password, repassword } = this.state;
    const { email, code } = this.props;

    if (this.validatePassword(password) && this.validateRepassword(repassword)) {
      this.props.handleResetPassword(email, code, password);
    }
  }

  handleNewRequestButton = () => {
    this.props.handleForgotPasswordClear();
  }
  handleForgotSubmitButton = () => {
    this.props.handleForgotPassword(this.props.email);
  }

  validatePassword = value => {
    let passwordRulesTemp = this.state.passwordRules.slice();
    let passwordValid = true;
    const repasswordValid = this.state.repassword === value;

    passwordRulesTemp.forEach(element => {
      element.valid = !!value.match(element.regex);
      passwordValid = passwordValid && !!value.match(element.regex);
    });

    this.setState({
      passwordValid: passwordValid,
      passwordRules: passwordRulesTemp,
      repasswordValid: repasswordValid
    });

    return passwordValid;
  }

  validateRepassword = repassword => {
    const repasswordValid = repassword === this.state.password;

    this.setState({
      repasswordValid: repasswordValid
    });

    return repasswordValid;
  }

  handleInputBlur = event => {
    const inputName = event.target.getAttribute("name");
    switch (inputName) {
      case "password":
        this.setState({ passwordTooltip: false });
        break;
      case "repassword":
        this.setState({ repasswordTooltip: false });
        break;
      default: break;
    }
  }

  handleInputFocus = event => {
    const inputName = event.target.getAttribute("name");
    switch (inputName) {
      case "password":
        this.setState({ passwordTooltip: true });
        break;
      case "repassword":
        this.setState({ repasswordTooltip: true });
        break;
      default: break;
    }
  }

  handleLogOut = () => {
    const { handleLogout, history } = this.props;
    handleLogout(history);
  }


  render() {
    const { password, repassword, passwordValid, repasswordValid } = this.state;
    const { resetPasswordError, fetchingResetPassword, resetPasswordSent, email, forgotPasswordSent } = this.props;

    if (resetPasswordSent) {
      return (
        <Wrapper>
          <div>
            <Header>Reset Your Password</Header>
          </div>
          <div>
            <SubHeader>Your password has been reset.</SubHeader>
            <NavLink to="/login" onClick={this.handleLogOut}>Login</NavLink>
          </div>
        </Wrapper>
      );
    }
    if (forgotPasswordSent) {
      return (
        <Wrapper>
          <div>
            <Header>Reset Your Password</Header>
            <SubHeader>We have emailed you at {email}</SubHeader>
          </div>
          <div>
            <p>If you have not received an email within 5 minutes, you may have used a different email address to sign up for RepScore.</p>
            <ReporaButton white className="m-t-30" full onClick={this.handleNewRequestButton}>Try Another Email Address</ReporaButton>
          </div>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        {fetchingResetPassword && <Loader overlayed size="medium" />}
        <div>
          <Header>Reset Your Password</Header>
        </div>
        <div>
          <FormGroup marginTop="none">
            <label>Email</label>
            <input
              name="email"
              type="text"
              value={email}
              disabled={true}
            />
          </FormGroup>
          <FormGroup marginTop="none">
            <label>Enter New Password</label>
            <input
              name="password"
              type="password"
              value={password}
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur}
              onChange={this.handleInputChange} />

            <InputTooltip
              isValid={this.state.passwordValid}
              isVisible={this.state.passwordTooltip}>
              <Body5 textAlign="left">Must contain at least</Body5>
              <ul >
                {this.state.passwordRules.map((item, index) => {
                  return (
                    <RestrictionItem key={"passwordRule" + index} isValid={item.valid}>
                      <img src="assets/global/check-white.svg" alt="" />
                      <Body5 textAlign="left">
                        {item.description}
                      </Body5>
                    </RestrictionItem>
                  );
                })}
              </ul>
            </InputTooltip>
          </FormGroup>

          <FormGroup>
            <label>Re-Enter New Password</label>
            <input
              name="repassword"
              type="password"
              value={repassword}
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur} />

            <InputTooltip
              isValid={this.state.repasswordValid}
              isVisible={this.state.repasswordTooltip}>
              <Body5 textAlign="left">
                {this.state.repasswordValid
                  ? "Password is Matching"
                  : "Password Doesn't Match"
                }
              </Body5>
            </InputTooltip>
          </FormGroup>
          {resetPasswordError && <ErrorMessage>{resetPasswordError}</ErrorMessage>}
          {resetPasswordError === "This link has expired. Request a new link to reset password" ? <ReporaButton
            disabled={!passwordValid || !repasswordValid}
            className="m-t-30"
            full
            onClick={this.handleForgotSubmitButton}>
            Resend Email
          </ReporaButton> : <ReporaButton
            disabled={!passwordValid || !repasswordValid}
            className="m-t-30"
            full
            onClick={this.handleSubmitButton}>
            Set Password
          </ReporaButton>}
        </div>
      </Wrapper>
    );
  }
}

export default SendForgotEmail;
