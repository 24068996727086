import {
  GET_REPSEARCH_INFO_REQUEST,
  GET_REPSEARCH_INFO_SUCCESS,
  GET_REPSEARCH_INFO_FAILURE,
  SET_REPSEARCH_PARAMS,
  GET_COMPANY_REPS_REQUESTED,
  GET_COMPANY_REPS_SUCCEEDED,
  GET_COMPANY_REPS_FAILED,
  CLEAR_SEARCH_INFO_REQUESTED,
  SEARCH_REPS_REQUESTED,
  SEARCH_REPS_SUCCEEDED,
  SEARCH_REPS_FAILED,
  SEARCH_COMPANY_REPS_REQUESTED,
  SEARCH_COMPANY_REPS_SUCCEEDED,
  SEARCH_COMPANY_REPS_FAILED,
  CLEAR_REP_SELECTION_DATA,
} from "../actions/searchActions";

const initialState = {
  searchParams: {
    nameField: "",
    companyField: "",
    repList: [],
    companyId: "",
  },
  customers: [],
  companies: [],
  repSearch: [],
  isFetching: false,
  isFetchingResults: false,
  companyReps: [],
  companyRepsLoading: false,
  companyRepsSuccess: false,
  companyRepsError: false,

  repResults: [],
  repResultsFetching: false,
  repResultsError: "",
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPSEARCH_INFO_REQUEST:
      return {
        ...state,
        isFetchingResults: true,
        repSearch: [],
      };

    case GET_REPSEARCH_INFO_SUCCESS:
      return {
        ...state,
        isFetchingResults: false,
        repSearch: action.payload,
      };

    case GET_REPSEARCH_INFO_FAILURE:
      return {
        ...state,
        isFetchingResults: false,
        repSearch: [],
        errorMessage: action.payload,
      };

    case SET_REPSEARCH_PARAMS:
      return {
        ...state,
        searchParams: action.payload,
      };

    case GET_COMPANY_REPS_REQUESTED:
      return {
        ...state,
        companyRepsLoading: true,
        companyReps: [],
        companyRepsSuccess: false,
        companyRepsError: false,
      };

    case GET_COMPANY_REPS_SUCCEEDED:
      return {
        ...state,
        companyRepsLoading: false,
        companyReps: action.payload,
        companyRepsSuccess: true,
        companyRepsError: false,
      };

    case GET_COMPANY_REPS_FAILED:
      return {
        ...state,
        companyRepsLoading: false,
        companyReps: [],
        companyRepsSuccess: false,
        companyRepsError: action.payload,
      };

    case CLEAR_SEARCH_INFO_REQUESTED:
      return {
        ...state,
        isFetchingResults: false,
        repSearch: [],
        searchParams: {
          nameField: "",
          companyField: "",
          repList: [],
          companyId: "",
        },
      };
    case CLEAR_REP_SELECTION_DATA:
      return {
        ...state,
        repResults: [],
      };

    case SEARCH_REPS_REQUESTED:
    case SEARCH_COMPANY_REPS_REQUESTED:
      return {
        ...state,
        repResultsFetching: true,
        repResults: [],
      };
    case SEARCH_REPS_SUCCEEDED:
    case SEARCH_COMPANY_REPS_SUCCEEDED:
      return {
        ...state,
        repResultsFetching: false,
        repResults: action.payload,
      };
    case SEARCH_REPS_FAILED:
    case SEARCH_COMPANY_REPS_FAILED:
      return {
        ...state,
        repResultsFetching: false,
        repResultsError: action.payload,
      };

    default:
      return state;
  }
};

export default searchReducer;
