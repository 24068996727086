import Axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  updateFeedbackDispute
} from "../../../actions/manageRepsActions";
import Button from "../../../components/Buttons/Button";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Loader from "../../../components/Loader/Loader";
import {
  Body2,
  FormGroup,
  FormRow,
  ReporaButton,
  reporaColor
} from "../../../components/global/commonStyles";
import CountriesStates from "../../../data/CountriesStates";
import IndustriesList from "../../../data/linkedInIndustries";
import urls from '../../../config';

const Wrapper = styled.div`
  margin: 3%;
  p {
    margin-bottom: 1%;
    margin-right: 10px;
  }
`;
const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  > div {
  }
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
  position: relative;
  width: 180;
  .copyIcon {
    color: ${reporaColor.aquaBlue};
    cursor: pointer;
  }

  label {
    font-size: 12px;
    font-weight: bold;
    color: ${reporaColor.black};
    margin-bottom: 5px;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
  }

  &:last-child {
    margin-bottom: 0;
  }

  input {
    font-family: Nexa Bold;
    height: 40px;
    border-radius: 4px;
    border: 1px solid ${reporaColor.warmGrey};
    width: 100%;
    padding: 0 10px;
    font-size: 14px;

    ${(props) =>
      props.invalid
        ? `
      box-shadow: 0 0 5px 1px ${reporaColor.watermelon};
    `
        : null};

    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  textarea {
    width: 100%;
    height: 90px;
    border: 1px solid ${reporaColor.warmGrey};
  }
`;
const getRegions = (country) => {
  country = CountriesStates.find((c) => c.countryName === country);
  return country ? country.regions.map((r) => r.name) : [];
};
class CreateCompany extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companiesList: [],
      company: {
        name: "",
        industry: "",
        linkedinUrl: "",
        domain: "",
        minimumRatings: 1,
        enrolled: true,
        is_private: false,
        physicalAddress: {
          country: "",
          city: "",
          state: "",
          zipCode: "",
          phoneNumber: '',
        },
      },
      administrator: {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        companyName: "",
        country: "",
        phoneNumber: "",
        zipCode: "",
        state: "",
        city: "",
        jobTitle: "",
      },
      isValid: true,
      isLoading: false,
    };
  }

  flattenObject(ob) {
    var toReturn = {};

    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if (typeof ob[i] == "object" && ob[i] !== null) {
        var flatObject = this.flattenObject(ob[i]);
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    console.log({ toReturn });
    return toReturn;
  }

  handleCompanyChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let company;
    if (
      name === "country" ||
      name === "state" ||
      name === "city" ||
      name === "zipCode" ||
      name === "phoneNumber" ||
      name === "territory"
    ) {
      if (name === "country") {
        let physicalAddress = {
          ...this.state.company.physicalAddress,
          [name]: value,
          ["state"]: "",
          ["city"]: "",
          ["zipCode"]: "",
        };
        company = { ...this.state.company, physicalAddress: physicalAddress };
      } else {
        let physicalAddress = {
          ...this.state.company.physicalAddress,
          [name]: value,
        };
        company = { ...this.state.company, physicalAddress: physicalAddress };
      }
    } else {
      if (name === "is_private") {
        company = { ...this.state.company, [name]: e.target.checked };
      } else {
        company = { ...this.state.company, [name]: value };
      }
    }

    this.setState({ company });
  };

  handleAdministratorChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let administrator;
    if (name === "country") {
      administrator = {
        ...this.state.administrator,
        [name]: value,
        ["state"]: "",
        ["city"]: "",
        ["zipCode"]: "",
      };
    } else {
      administrator = { ...this.state.administrator, [name]: value };
    }
    this.setState({ administrator });
    console.log(
      this.hasEmptyFields(administrator),
      this.hasEmptyFields(this.state.company)
    );
  };

  hasEmptyFields(obj) {
    let variable = Object.values(obj)?.some((el) => el === "");

    if (!variable) return false; // No empty fields found
    else return true;
  }

  handleSubmitForm = async () => {
    try {
      this.setState({ isLoading: true });
      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": window.location.origin,
      };

      let reqOptions = {
        url: `${urls.CREATE_COMPANY_API}/auth/company/sign-up`,
        method: "POST",
        headers: headersList,
        data: {
          company: {
            ...this.state.company,
            physicalAddress:{
              ...this.state.company.physicalAddress,
              phoneNumber: "+"+this.state.company.physicalAddress.phoneNumber
            }
          },
          administrator: {
            ...this.state.administrator,
            industry: this.state.company.industry,
            companyName: this.state.company.name,
            companyId: "",
            phoneNumber: "+"+this.state.administrator.phoneNumber,
            userRole: "ADMIN",
          },
        },
      };

      const {data} = await Axios.request(reqOptions);
      this.setState({ isLoading: false });
      if(data.statusCode === 200 || data.statusCode === 201){
        this.handleCancelEdit();
      }
    } catch (e) {
      this.setState({ isLoading: false });
      console.log(e);
    }
  };

  handleCancelEdit = () => {
    let company = {
      name: "",
      industry: "",
      linkedinUrl: "",
      domain: "",
      minimumRatings: 1,
      enrolled: true,
      is_private: false,
      physicalAddress: {
        country: "",
        city: "",
        state: "",
        zipCode: "",
        phoneNumber: "",
      },
    };
    let administrator = {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      country: "",
      phoneNumber: "",
      zipCode: "",
      state: "",
      city: "",
      jobTitle: "",
    };
    this.setState({ company });
    this.setState({ administrator });
  };

  render() {
    const { company, administrator } = this.state;
    return (
      <Wrapper>
        <div style={{ display: "flex", gap: 70 }}>
          <NavLink className="Navlink" to="/admin/dashboard">
            Back to Admin Panel
          </NavLink>
          <Body2>Create Company</Body2>
        </div>

        {this.state.isLoading ? (
          <Loader overlayed size="small" />
        ) : (
          <div
            style={{
              width: "80%",
              marginInline: "auto",
              padding: "30px 20px 20px 20px",
              background: "#fff",
              borderRadius: "12px",
            }}
            className="content"
          >
            <Body2>Company Info</Body2>
            <div>
              <FormRow>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>Company Name</label>
                    <input
                      style={{ borderColor: "#E8E8E8" }}
                      name="name"
                      type="text"
                      value={company.name}
                      onChange={(e) => this.handleCompanyChange(e)}
                    ></input>
                  </InputContainer>
                </FormGroup>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>Phone</label>
                    <input
                      style={{ borderColor: "#E8E8E8" }}
                      name="phoneNumber"
                      type="number"
                      value={company.physicalAddress.phoneNumber}
                      onChange={(e) => this.handleCompanyChange(e)}
                    ></input>
                  </InputContainer>
                </FormGroup>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>LinkedIn Co URL</label>
                    <input
                      style={{ borderColor: "#E8E8E8" }}
                      name="linkedinUrl"
                      type="text"
                      value={company.linkedinUrl}
                      onChange={(e) => this.handleCompanyChange(e)}
                    ></input>
                  </InputContainer>
                </FormGroup>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>Domain (e.g. acme.com)</label>
                    <input
                      style={{ borderColor: "#E8E8E8" }}
                      name="domain"
                      type="text"
                      value={company.domain}
                      onChange={(e) => this.handleCompanyChange(e)}
                    ></input>
                  </InputContainer>
                </FormGroup>
              </FormRow>
              <FormRow style={{ justifyContent: "start" }}>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>Min Ratings</label>
                    <input
                      style={{ borderColor: "#E8E8E8" }}
                      name="minimumRatings"
                      type="number"
                      min={1}
                      max={5}
                      value={company.minimumRatings}
                      onChange={(e) => this.handleCompanyChange(e)}
                    ></input>
                  </InputContainer>
                </FormGroup>
                <FormGroup width="25%">
                  <div>
                    <InputContainer style={{ width: "max-content" }}>
                      <label>Private</label>
                      <input
                        name="is_private"
                        type="checkbox"
                        style={{
                          marginTop: "-0.5px",
                          width: "25px",
                          borderColor: "#E8E8E8",
                        }}
                        checked={company.is_private}
                        onChange={(e) => this.handleCompanyChange(e)}
                      ></input>
                    </InputContainer>
                  </div>
                </FormGroup>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>Industry</label>
                    <Dropdown
                      options={IndustriesList.map((i) => i.description)}
                      value={company.industry}
                      placeholder="Industry"
                      onClick={(e) =>
                        this.handleCompanyChange({
                          target: { name: "industry", value: e },
                        })
                      }
                      disabled={false}
                      customWidth="100%"
                      left
                    />
                  </InputContainer>
                </FormGroup>
                <div></div>
              </FormRow>
              <FormRow>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>Country</label>
                    <Dropdown
                      options={CountriesStates.map((e) => e.countryName)}
                      value={company.physicalAddress.country}
                      placeholder="Country"
                      onClick={(e) => {
                        console.log("Country --> ", e);
                        this.handleCompanyChange({
                          target: { name: "country", value: e },
                        });
                      }}
                      disabled={false}
                      customWidth="100%"
                      left
                    />
                  </InputContainer>
                </FormGroup>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>State</label>
                    <Dropdown
                      options={getRegions(company.physicalAddress.country)}
                      value={company.physicalAddress.state}
                      placeholder="State"
                      onClick={(e) =>
                        this.handleCompanyChange({
                          target: { name: "state", value: e },
                        })
                      }
                      disabled={!company.physicalAddress.country}
                      customWidth="100%"
                      left
                    />
                  </InputContainer>
                </FormGroup>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>City</label>
                    <input
                      style={{ borderColor: "#E8E8E8" }}
                      name="city"
                      type="text"
                      value={company.physicalAddress.city}
                      onChange={(e) => this.handleCompanyChange(e)}
                    ></input>
                  </InputContainer>
                </FormGroup>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>Zip</label>
                    <input
                      style={{ borderColor: "#E8E8E8" }}
                      name="zipCode"
                      type="text"
                      value={company.physicalAddress.zipCode}
                      onChange={(e) => this.handleCompanyChange(e)}
                    ></input>
                  </InputContainer>
                </FormGroup>
              </FormRow>
              <Body2 style={{ marginTop: "25px" }}>Administrator Info</Body2>
              <FormRow>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>First Name</label>
                    <input
                      style={{ borderColor: "#E8E8E8" }}
                      name="firstName"
                      type="text"
                      value={administrator.firstName}
                      onChange={(e) => this.handleAdministratorChange(e)}
                    ></input>
                  </InputContainer>
                </FormGroup>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>Last Name</label>
                    <input
                      style={{ borderColor: "#E8E8E8" }}
                      name="lastName"
                      type="text"
                      value={administrator.lastName}
                      onChange={(e) => this.handleAdministratorChange(e)}
                    ></input>
                  </InputContainer>
                </FormGroup>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>Job Title</label>
                    <input
                      style={{ borderColor: "#E8E8E8" }}
                      name="jobTitle"
                      type="text"
                      value={administrator.jobTitle}
                      onChange={(e) => this.handleAdministratorChange(e)}
                    ></input>
                  </InputContainer>
                </FormGroup>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>Email</label>
                    <input
                      style={{ borderColor: "#E8E8E8" }}
                      name="email"
                      type="text"
                      value={administrator.email}
                      onChange={(e) => this.handleAdministratorChange(e)}
                    ></input>
                  </InputContainer>
                </FormGroup>
              </FormRow>
              <FormRow>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>Country</label>
                    <Dropdown
                      options={CountriesStates.map((e) => e.countryName)}
                      value={administrator.country}
                      placeholder="Country"
                      onClick={(e) => {
                        this.handleAdministratorChange({
                          target: { name: "country", value: e },
                        });
                      }}
                      disabled={false}
                      customWidth="100%"
                      left
                    />
                  </InputContainer>
                </FormGroup>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>State</label>
                    <Dropdown
                      options={getRegions(company.physicalAddress.country)}
                      value={administrator.state}
                      placeholder="State"
                      onClick={(e) =>
                        this.handleAdministratorChange({
                          target: { name: "state", value: e },
                        })
                      }
                      disabled={!administrator.country}
                      customWidth="100%"
                      left
                    />
                  </InputContainer>
                </FormGroup>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>City</label>
                    <input
                      style={{ borderColor: "#E8E8E8" }}
                      name="city"
                      type="text"
                      value={administrator.city}
                      onChange={(e) => this.handleAdministratorChange(e)}
                    ></input>
                  </InputContainer>
                </FormGroup>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>Zip</label>
                    <input
                      style={{ borderColor: "#E8E8E8" }}
                      name="zipCode"
                      type="text"
                      value={administrator.zipCode}
                      onChange={(e) => this.handleAdministratorChange(e)}
                    ></input>
                  </InputContainer>
                </FormGroup>
              </FormRow>
              <FormRow>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>Phone</label>
                    <input
                      style={{ borderColor: "#E8E8E8" }}
                      name="phoneNumber"
                      type="number"
                      value={administrator.phoneNumber}
                      onChange={(e) => this.handleAdministratorChange(e)}
                    ></input>
                  </InputContainer>
                </FormGroup>
                <FormGroup width="25%">
                  <InputContainer>
                    <label>Temporary Password</label>
                    <input
                      style={{ borderColor: "#E8E8E8" }}
                      name="password"
                      type="password"
                      value={administrator.password}
                      onChange={(e) => this.handleAdministratorChange(e)}
                    ></input>
                  </InputContainer>
                </FormGroup>
              </FormRow>
            </div>
            <FormRow
              style={{
                width: "100%",
                justifyContent: "center",
                gap: "1rem",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <ReporaButton
                style={{ border: "1px solid #0BC9F6" }}
                onClick={this.handleCancelEdit}
                secondary
                small
              >
                Cancel
              </ReporaButton>
              <Button
                disabled={
                  this.hasEmptyFields(this.flattenObject(administrator)) &&
                  this.hasEmptyFields(this.flattenObject(company))
                }
                size="small"
                onClick={() => this.handleSubmitForm()}
              >
                Save
              </Button>
            </FormRow>
          </div>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  feedbackDisputeSucceeded: state.manageRep.feedbackDisputeSucceeded,
  feedbackDisputeRequested: state.manageRep.feedbackDisputeRequested,
  feedbackDisputeFailed: state.manageRep.feedbackDisputeFailed,
  updateFeedbackDisputeRequested:
    state.manageRep.updateFeedbackDisputeRequested,
  reviewerId: state.auth.userData,
});
const mapDispatchToProps = (dispatch) => ({
  handleSubmitCompanyData(data) {
    dispatch(updateFeedbackDispute(data));
  },
});

export const Unwrapped = CreateCompany;
export default connect(mapStateToProps, mapDispatchToProps)(CreateCompany);
