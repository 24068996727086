import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Star = styled.div`
  background-image: url(/assets/global/rating-star-grey.svg);
  background-repeat: no-repeat;

  ${props => props.big
    ? ` height: 24px;
        width: 30px;
        background-size: 24px 24px;
        background-position: 3px 0px;`
    : ` height: 16px;
        width: 20px;
        background-size: 16px 16px;
        background-position: 2px 0px;`
  }

  &:hover {
    ${props => props.static
    ? "cursor: inherit;"
    : "cursor: default;"
  }
    
  }

  &.is-disabled:hover {
    cursor: default;
  }

  ${props => props.selected
    ? "background-image: url('/assets/global/rating-star.svg');"
    : null
  }
`;

class RatingStars extends React.Component {
  state = {
    rating: 0,
    temp_rating: 0
  }

  componentDidUpdate(prevProps) {
    if (this.props.rating !== prevProps.rating) {
      this.setState({ rating: this.props.rating, temp_rating: this.props.rating });
    }
  }

  componentDidMount() {
    this.setState({ rating: this.props.rating, temp_rating: this.props.rating });
  }

  rate = (event) => {
    let rating = event.target.dataset.index;

    this.setState({
      rating: rating,
      temp_rating: rating
    });

    this.props.onChange(this.props.name, rating);
  };

  star_over = (event) => {
    let rating = event.target.dataset.index;
    let tempRating = this.state.rating;

    this.setState({
      rating: rating,
      temp_rating: tempRating
    });
  };

  star_out = () => {
    this.setState({ rating: this.state.temp_rating });
  };

  render() {
    let stars = [];

    for (let index = 1; index < 6; index++) {
      let selected = this.state.rating >= index && this.state.rating != null;
      let isBig = true && this.props.big;

      if (this.props.static) {
        selected = Math.round(this.props.rating) >= index && this.props.rating != null;
        stars.push(
          <Star key={"star" + index} selected={selected} big={isBig} static />
        );
      } else {
        stars.push(
          <Star
            key={"star" + index}
            name={this.props.name}
            onClick={this.rate}
            onMouseOver={this.star_over}
            onMouseOut={this.star_out}
            selected={selected}
            big={isBig}
            data-index={index} />
        );
      }
    }

    return (
      <Wrapper className={this.props.className}>
        {stars}
      </Wrapper>
    );
  }
}

RatingStars.propTypes = {
  name: PropTypes.string,
  rating: PropTypes.number,
  big: PropTypes.bool,
  static: PropTypes.bool
};

export default RatingStars;
