import SearchService from "../services/SearchService";

/* Action Constants */
export const GET_REPSEARCH_INFO_REQUEST = "GET_REPSEARCH_INFO_REQUEST";
export const GET_REPSEARCH_INFO_SUCCESS = "GET_REPSEARCH_INFO_SUCCESS";
export const GET_REPSEARCH_INFO_FAILURE = "GET_REPSEARCH_INFO_FAILURE";
export const SET_REPSEARCH_PARAMS = "SET_REPSEARCH_PARAMS";

export const GET_COMPANY_REPS_REQUESTED = "GET_COMPANY_REPS_REQUESTED";
export const GET_COMPANY_REPS_SUCCEEDED = "GET_COMPANY_REPS_SUCCEEDED";
export const GET_COMPANY_REPS_FAILED = "GET_COMPANY_REPS_FAILED";

export const CLEAR_SEARCH_INFO_REQUESTED = "CLEAR_SEARCH_INFO_REQUESTED";

export const SEARCH_REPS_REQUESTED = "SEARCH_REPS_REQUESTED";
export const SEARCH_REPS_SUCCEEDED = "SEARCH_REPS_SUCCEEDED";
export const SEARCH_REPS_FAILED = "SEARCH_REPS_FAILED";

export const SEARCH_COMPANY_REPS_REQUESTED = "SEARCH_COMPANY_REPS_REQUESTED";
export const SEARCH_COMPANY_REPS_SUCCEEDED = "SEARCH_COMPANY_REPS_SUCCEEDED";
export const SEARCH_COMPANY_REPS_FAILED = "SEARCH_COMPANY_REPS_FAILED";
export const CLEAR_REP_SELECTION_DATA = "CLEAR_REP_SELECTION_DATA";

/* Action Creators */
const setRepSearchFields = (fields) => ({
  type: SET_REPSEARCH_PARAMS,
  payload: fields,
});
const clearSearchInfoRequested = () => ({ type: CLEAR_SEARCH_INFO_REQUESTED });

const getCompanyRepsRequested = () => ({ type: GET_COMPANY_REPS_REQUESTED });
const getCompanyRepsSucceeded = (data) => ({
  type: GET_COMPANY_REPS_SUCCEEDED,
  payload: data,
});
const getCompanyRepsFailed = (error) => ({
  type: GET_COMPANY_REPS_FAILED,
  payload: error,
});

const searchRepsRequested = () => ({ type: SEARCH_REPS_REQUESTED });
const searchRepsSucceeded = (data) => ({
  type: SEARCH_REPS_SUCCEEDED,
  payload: data,
});
const searchRepsFailed = (error) => ({
  type: SEARCH_REPS_FAILED,
  payload: error,
});

const searchCompanyRepsRequested = () => ({
  type: SEARCH_COMPANY_REPS_REQUESTED,
});
const searchCompanyRepsSucceeded = (data) => ({
  type: SEARCH_COMPANY_REPS_SUCCEEDED,
  payload: data,
});
const searchCompanyRepsFailed = (error) => ({
  type: SEARCH_COMPANY_REPS_FAILED,
  payload: error,
});
const clearRepSelectionDataAction = (data) => ({
  type: CLEAR_REP_SELECTION_DATA,
  payload: data,
});

export function searchReps(
  repName,
  repId,
  companyName,
  companyId,
  unclaimed,
  sessionUserId
) {
  return (dispatch) => {
    dispatch(searchRepsRequested());

    SearchService.searchReps(
      repName,
      repId,
      companyName,
      companyId,
      unclaimed,
      sessionUserId
    )
      .then((response) => {
        dispatch(searchRepsSucceeded(response));
      })
      .catch((error) => {
        dispatch(searchRepsFailed(error));
      });
  };
}

export const requestClearRepSelectionData = () => {
  return (dispatch) => {
    dispatch(clearRepSelectionDataAction());
  };
};

export function resetSearchReps() {
  return (dispatch) => {
    dispatch(searchRepsSucceeded([]));
  };
}
export function searchCompanyReps(repName, repId, companyId, sessionUserId) {
  return (dispatch) => {
    dispatch(searchCompanyRepsRequested());

    SearchService.searchCompanyReps(repName, repId, companyId, sessionUserId)
      .then((response) => {
        dispatch(searchCompanyRepsSucceeded(response));
      })
      .catch((error) => {
        dispatch(searchCompanyRepsFailed(error));
      });
  };
}

export function setReducerRepSearchFields(nameField, companyField) {
  return (dispatch) => {
    dispatch(
      setRepSearchFields({
        nameField,
        companyField,
      })
    );
  };
}

export function getCompanyReps(companyId) {
  return (dispatch) => {
    dispatch(getCompanyRepsRequested());

    SearchService.getCompanyReps(companyId)
      .then((response) => {
        dispatch(getCompanyRepsSucceeded(response));
      })
      .catch((error) => {
        dispatch(getCompanyRepsFailed(error));
      });
  };
}

export function clearSearchInfo() {
  return (dispatch) => {
    dispatch(clearSearchInfoRequested());
  };
}
