import {
  GET_PAST_RATINGS_REQUEST,
  GET_PAST_RATINGS_SUCCESS,
  GET_PAST_RATINGS_FAILURE,
  UPVOTE_REQUEST,
  UPVOTE_SUCCESS,
  UPVOTE_FAILURE,

  UPDATE_PHONE_REQUESTED,
  UPDATE_PHONE_SUCCEEDED,
  UPDATE_PHONE_FAILED,
  RESET_PHONE_REQUESTED,

  UPDATE_COMPANY_REQUESTED,
  UPDATE_COMPANY_SUCCEEDED,
  UPDATE_COMPANY_FAILED,
  RESET_COMPANY_REQUESTED,

  UPDATE_EMAIL_REQUESTED,
  UPDATE_EMAIL_SUCCEEDED,
  UPDATE_EMAIL_FAILED,
  RESET_EMAIL_REQUESTED,
  RESET_EMAIL_ERROR,

  UPDATE_SECONDARY_EMAIL_REQUESTED,
  UPDATE_SECONDARY_EMAIL_SUCCEEDED,
  UPDATE_SECONDARY_EMAIL_FAILED,
  RESET_SECONDARY_EMAIL_REQUESTED,

  UPDATE_PASSWORD_REQUESTED,
  UPDATE_PASSWORD_SUCCEEDED,
  UPDATE_PASSWORD_FAILED,
  RESET_PASSWORD_REQUESTED,
  RESET_PASSWORD_ERROR,

  CLEAR_UPVOTE_REQUESTED,

  CHECK_EMAIL_REQUSTED,
  CHECK_EMAIL_SUCCESS,
  CHECK_EMAIL_FAILED,
} from "../actions/customerActions";

function updateUpvoteRequest(state, payload) {
  const { feedbackId, ...nextData } = payload
  let request = { ...state.upvoteRequest[feedbackId], ...nextData }
  let newState = {}
  newState.upvoteRequest = { ...state.upvoteRequest }
  newState.upvoteRequest[feedbackId] = request
  return { ...state, ...newState }
}

const defaultState = {
  pastRatings: {},
  errorMessage: "{}",
  isFetching: false,
  upvoteRequest: {},

  updatePhoneFetching: false,
  updatePhoneError: "",

  updateCompanyFetching: false,
  updateCompanyError: "",

  updatePasswordFetching: false,
  updatePasswordSucceeded: null,
  updatePasswordError: "",

  updateEmailFetching: false,
  updateEmailSucceeded: null,
  updateEmailError: "",

  isCheckingEmail: false,
  isUniqueEmail: null,
  checkingEmailError:null,
}

const customerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_PAST_RATINGS_REQUEST:
      return Object.assign({}, state, { isFetching: true, pastRatings: {}, errorMessage: false });
    case GET_PAST_RATINGS_SUCCESS:
      return Object.assign({}, state, { isFetching: false, pastRatings: action.payload, errorMessage: false });
    case GET_PAST_RATINGS_FAILURE:
      return Object.assign({}, state, { isFetching: false, pastRatings: {}, errorMessage: action.payload });


    case CLEAR_UPVOTE_REQUESTED:
      return ({
        ...state,
        upvoteRequest: {},
        upvoteResponseReady: false
      })
    case UPVOTE_REQUEST:
      return updateUpvoteRequest(state, {
        ...action.payload,
        isFetchingUpvote: true,
        upvoteResponseReady: false
      })
    case UPVOTE_SUCCESS:
      return updateUpvoteRequest(state, {
        ...action.payload,
        isFetchingUpvote: false,
        upvoteResponseReady: true
      })
    case UPVOTE_FAILURE:
      return updateUpvoteRequest(state, {
        ...action.payload,
        isFetchingUpvote: false,
        upvoteResponseReady: true,
        errorMessage: action.payload.error
      })




    case UPDATE_PHONE_REQUESTED:
      return ({
        ...state,
        updatePhoneFetching: true,
        updatePhoneError: ""
      });
    case UPDATE_PHONE_SUCCEEDED:
      return ({
        ...state,
        updatePhoneFetching: false,
        updatePhoneError: ""
      });
    case UPDATE_PHONE_FAILED:
      return ({
        ...state,
        updatePhoneFetching: false,
        updatePhoneError: action.payload
      });
    case RESET_PHONE_REQUESTED:
      return ({
        ...state,
        updatePhoneFetching: false,
        updatePhoneError: ""
      });

    case UPDATE_COMPANY_REQUESTED:
      return ({
        ...state,
        updateCompanyFetching: true,
        updateCompanyError: ""
      });
    case UPDATE_COMPANY_SUCCEEDED:
      return ({
        ...state,
        updateCompanyFetching: false,
        updateCompanyError: ""
      });
    case UPDATE_COMPANY_FAILED:
      return ({
        ...state,
        updateCompanyFetching: false,
        updateCompanyError: action.payload
      });
    case RESET_COMPANY_REQUESTED:
      return ({
        ...state,
        updateCompanyFetching: false,
        updateCompanyError: ""
      });

    case UPDATE_PASSWORD_REQUESTED:
      return ({
        ...state,
        updatePasswordFetching: true,
        updatePasswordSucceeded: null
      });
    case UPDATE_PASSWORD_SUCCEEDED:
      return ({
        ...state,
        updatePasswordFetching: false,
        updatePasswordError: "",
        updatePasswordSucceeded: true
      });
    case UPDATE_PASSWORD_FAILED:
      return ({
        ...state,
        updatePasswordFetching: false,
        updatePasswordError: action.payload,
        updatePasswordSucceeded: false,
      });
    case RESET_PASSWORD_REQUESTED:
      return ({
        ...state,
        updatePasswordFetching: false,
        updatePasswordError: "",
        updatePasswordSucceeded: null
      });
    case RESET_PASSWORD_ERROR:
      return ({
        ...state,
        updatePasswordFetching: false,
        updatePasswordError: "",
        updatePasswordSucceeded: null
      });

    case UPDATE_EMAIL_REQUESTED:
      return ({
        ...state,
        updateEmailFetching: true,
        updateEmailSucceeded: false,
        updateEmailError: ""
      })
    case UPDATE_EMAIL_SUCCEEDED:
      return ({
        ...state,
        updateEmailFetching: false,
        updateEmailSucceeded: true,
        updateEmailError: ""
      })
    case UPDATE_EMAIL_FAILED:
      return ({
        ...state,
        updateEmailFetching: false,
        updateEmailSucceeded: false,
        updateEmailError: action.payload
      })
    case RESET_EMAIL_REQUESTED:
      return ({
        ...state,
        updateEmailFetching: false,
        updateEmailSucceeded: false,
        updateEmailError: ""
      })
    case RESET_EMAIL_ERROR:
      return ({
        ...state,
        updateEmailFetching: false,
        updateEmailSucceeded: false,
        updateEmailError: ""
      })

    case UPDATE_SECONDARY_EMAIL_REQUESTED:
      return ({
        ...state,
        updateSecondaryEmailFetching: true,
        updateSecondaryEmailSucceeded: false,
        updateSecondaryEmailError: ""
      });
    case UPDATE_SECONDARY_EMAIL_SUCCEEDED:
      return ({
        ...state,
        updateSecondaryEmailFetching: false,
        updateSecondaryEmailSucceeded: true,
        updateSecondaryEmailError: ""
      });
    case UPDATE_SECONDARY_EMAIL_FAILED:
      return ({
        ...state,
        updateSecondaryEmailFetching: false,
        updateSecondaryEmailSucceeded: false,
        updateSecondaryEmailError: action.payload
      });
    case RESET_SECONDARY_EMAIL_REQUESTED:
      return ({
        ...state,
        updateSecondaryEmailFetching: false,
        updateSecondaryEmailSucceeded: null,
        updateSecondaryEmailError: ""
      });
    case CHECK_EMAIL_REQUSTED:
      return ({
          ...state,
          isCheckingEmail: true,
      })
    case CHECK_EMAIL_SUCCESS:
      return ({
          ...state,
          isCheckingEmail: false,
          isUniqueEmail: action.payload,
          checkingEmailError: null,
      })
    case CHECK_EMAIL_FAILED:
      return ({
          ...state,
          isCheckingEmail: false,
          isUniqueEmail: null,
          checkingEmailError: action.payload,
      });

    default:
      return state;
  }
};

export default customerReducer;
