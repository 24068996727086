import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  getFeedbackDispute,
  updateFeedbackDispute,
} from "../../../actions/manageRepsActions";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { Body2, Body4 } from "../../../components/global/commonStyles";
import Loader from "../../../components/Loader/Loader";
import View from "./components/View";

const Wrapper = styled.div`
  margin: 3%;
  p {
    margin-bottom: 1%;
    margin-right: 10px;
  }
`;
const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  > div {
  }
`;

class Dispute extends React.Component {
  state = {
    companiesList: [],
    disputes: "Unprocessed",
    headers: ["Dispute ID", "Date", "Review ID", "Review Date", "Customer", "Rep", "Company", "Disputed By"],
    fromDispute:true
  };

  componentDidMount() {
    this.props.handleFeedbackDisputList({ disputeStatus: "IN_DISPUTE" });
  }

  componentWillReceiveProps(newprops) {}
  handleFilterAverageChange = (ele) => {
    if (ele === "Accepted") {
      this.props.handleFeedbackDisputList({
        disputeStatus: "DISPUTE_APPROVED",
      });
    } else if (ele === "Rejected") {
      this.props.handleFeedbackDisputList({
        disputeStatus: "DISPUTE_REJECTED",
      });
    } else {
      this.props.handleFeedbackDisputList({ disputeStatus: "IN_DISPUTE" });
    }

    this.setState({ disputes: ele });
    console.log(ele);
  };
  render() {
    return (
      <Wrapper>
        <NavLink className='Navlink' to = "/admin/dashboard">Back to Admin Panel</NavLink><br/><br/>
        <Body2>Manage Disputes</Body2>
        <WrapperHeader>
          <Body4> Disputes being shown: </Body4>

          <Dropdown
            className="m-b-15"
            options={["Unprocessed", "Accepted", "Rejected"]}
            value={this.state.disputes}
            onClick={this.handleFilterAverageChange}
          />
        </WrapperHeader>
        {this.props.feedbackDisputeRequested ? (
          <Loader overlayed size="small" />
        ) : (
          <View {...this.props} {...this.state} />
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  feedbackDisputeSucceeded: state.manageRep.feedbackDisputeSucceeded,
  feedbackDisputeRequested: state.manageRep.feedbackDisputeRequested,
  feedbackDisputeFailed: state.manageRep.feedbackDisputeFailed,
  updateFeedbackDisputeRequested:
    state.manageRep.updateFeedbackDisputeRequested,
  reviewerId: state.auth.userData,
});
const mapDispatchToProps = (dispatch) => ({
  handleFeedbackDisputList(data) {
    console.log("Get feedback dispute : ", data);
    dispatch(getFeedbackDispute(data));
  },
  handleUpdateFeedbackDispute(data) {
    dispatch(updateFeedbackDispute(data));
  },
});

export const Unwrapped = Dispute;
export default connect(mapStateToProps, mapDispatchToProps)(Dispute);
