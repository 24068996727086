import { combineReducers } from "redux";
import accountReducer from "./accountReducer";
import menuReducer from "./menuReducer";
import authReducer from "./authReducer";
import modalReducer from "./modalReducer";
import searchReducer from "./searchReducer";
import manageUserReducer from "./manageUserReducer";
import manageEmailAlertsReducer from "./manageEmailAlertsReducer";
import repReducer from "./repReducer";
import companyReducer from "./companyReducer";
import customerReducer from "./customerReducer";
import notificationReducer from "./notificationReducer";
import manageRepsReducer from "./manageRepsReducer";
import adminReducer from "./adminReducer";
import reportingReducer from "./reportingPreferencesReducer";

const rootReducer = combineReducers({
  admin: adminReducer,
  account: accountReducer,
  menu: menuReducer,
  auth: authReducer,
  modal: modalReducer,
  search: searchReducer,
  user: manageUserReducer,
  emailAlerts: manageEmailAlertsReducer,
  rep: repReducer,
  company: companyReducer,
  customer: customerReducer,
  notification: notificationReducer,
  manageRep: manageRepsReducer,
  reportingPreferences: reportingReducer,
});

export default rootReducer;
