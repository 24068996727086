import React from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

import DefaultAvatarSvg from "../../../assets/global/default-avatar.svg";
import RatingStars from "../../../components/RatingStars/RatingStars";
import {
  Body5,
  Header4,
  reporaColor,
  reporaDevice,
} from "../../../components/global/commonStyles";
import constants from "../../../constants";

const Wrapper = styled.div`
  background-color: ${reporaColor.white};
  display: flex;
  flex-shrink: 0;
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.08);
  padding: 15px 0 20px;
  flex-direction: column;
  align-items: center;

  @media ${reporaDevice.desktop} {
    padding: 30px 0 40px;
    flex-direction: row;
    align-items: center;
  }

  p {
    white-space: wrap;
  }
`;

const ImageWrapper = styled.div`
  width: 160px;
  padding: 0 40px;

  @media ${reporaDevice.mobile} {
    display: flex;
    justify-content: center;
    padding: 0;
  }

  @media ${reporaDevice.tablet} {
    padding: 0 5%;
  }

  @media ${reporaDevice.desktop} {
    padding: 0 9%;
  }

  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
`;

const InformationWrapper = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${reporaDevice.desktop} {
    align-items: normal !important;
    text-align: left !important;
    margin-left: 7%;
  }
`;

const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  p:nth-child(2) {
    margin-left: 3px;
  }
`;

const RatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0;
  justify-content: center;

  h4 {
    margin-left: 10px;
  }

  @media ${reporaDevice.tablet} {
    justify-content: flex-start;
  }
`;

const TooltipWrapper = styled.span`
  display: inline-flex;
`;

const PersonCard = (props) => {
  let currentCompAddress = "";
  let territory = "";
  // let currentCompanyConnected = props.currentCompanyConnected;
  // let currentCompanyPrivate = props.currentCompanyPrivate;
  if (props.companyAddress) {
    let city = props.companyAddress.city
      ? `${props.companyAddress.city}, `
      : "";
    let state = props.companyAddress.state
      ? `${props.companyAddress.state}, `
      : "";
    let zipCode = props.companyAddress.zipCode
      ? props.companyAddress.zipCode
      : "";
    territory = props.companyAddress.territory
      ? `${props.companyAddress.territory}`
      : "";
    currentCompAddress = city + state + zipCode;
  }
  let Rate = 0;
  if (props.rate == 0) {
    Rate = "";
  } else {
    if (props.rate) {
      Rate = props.rate?.toFixed(1);
    }
  }
  let hiderating = true;
  if (
    props.currentCompanyConnected == true &&
    props.currentCompanyPrivate == true
  ) {
    hiderating = false;
  }
  return (
    <Wrapper>
      <ImageWrapper>
        <img
          src={props.image || "/assets/global/userIcon.svg"}
          alt="profile"
          onError={(event) => {
            event.target.onerror = null;
            event.target.src = DefaultAvatarSvg;
          }}
        />
      </ImageWrapper>
      <InformationWrapper>
        <Header4>{props.name.replace("null", "")}</Header4>
        {(() => {
          if (hiderating) {
            return (
              <RatingWrapper>
                <RatingStars static big={true} rating={props.rate} />
                <Header4>
                  <TooltipWrapper data-tip="Overall Rating">
                    {Rate}
                  </TooltipWrapper>
                </Header4>
              </RatingWrapper>
            );
          }
        })()}
        <CompanyWrapper>
          {props.currentCompany !== "Not Available" && (
            <Body5>
              <TooltipWrapper data-tip="Current Company">
                {props.currentCompany || ""}
              </TooltipWrapper>{" "}
            </Body5>
          )}{" "}
          {/* current company */}
        </CompanyWrapper>
        {/* <Body5 textColor={`${reporaColor.warmGrey}`}>{currentCompAddress || ""}</Body5>  */}
        {/* current company location */}
        {/* <Body5 textColor={`${reporaColor.warmGrey}`}>{territory}</Body5> */}
        {/* company territory */}
        {props.unclaimed === false && (
          <React.Fragment>
            {/* {
              props.unclaimed === false && props.previousCompany &&
              <Body5 textColor={reporaColor.warmGrey}>{props.previousCompany ? `Previous: ${props.previousCompany}` : ""}</Body5>

            } */}
            {props.unclaimed === false && props.productSpeciality && (
              <Body5 textColor={reporaColor.brightSkyBlue}>
                <TooltipWrapper data-tip="Product Specialty">
                  {props.unclaimed ? "" : props.productSpeciality || ""}
                </TooltipWrapper>
              </Body5>
            )}
          </React.Fragment>
        )}
      </InformationWrapper>
      <ReactTooltip effect="solid" disable={constants.IS_MOBILE} />
    </Wrapper>
  );
};

export default PersonCard;
