import React from "react";
import styled from "styled-components";

import { reporaColor, reporaSize, Title, Header2, Header3, Body4 } from "../../components/global/commonStyles";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const PanelContent =styled.div`
  width: 100%;
  padding: ${props => props.isheadingPadded ? "0 20%" : "0"}

  ${props => !props.noHeadingMargin ? "margin-bottom: 5%;" : null}

  @media (max-width: ${reporaSize.small}) {
    margin-bottom: 10%;
  }

  .multi-title {
    padding: 2%;
    position: relative;

    @media (max-width: ${reporaSize.small}) {
      padding: 4%;
    }

    .separator {
      &:after {
        content: "";
        height: 3px;
        width: 50px;
        background-color: ${reporaColor.aquaBlue};
        position: absolute;
        right: 50%;
        left: 50%;
        bottom: 0;

        @media (max-width: ${reporaSize.small}) {
          right: 42%;
          left: 42%;
        }
      }
    }
  }

  h2 {
    font-size: 35px;
    line-height: initial;
    margin-bottom: 1%;

    @media (max-width: ${reporaSize.small}) {
      font-size: 20px;
    }
  }

  h4 {
    letter-spacing: initial;

    @media (max-width: ${reporaSize.small}) {
      font-size: 12px;
    }
  }
`;

const Columns = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

`;

const Column = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 1% 30px;
  padding: 0 20px 20px;
  background-color: ${props => props.boxcolor || "none"};
  border-radius: 10px;

  ${props => props.itemsCount < 4 ? `width: calc(${100 - props.itemsCount * 2}% / ${props.itemsCount});` : `width: calc(92% / 4);`};

  @media (max-width: ${reporaSize.small}) {
		width: calc(96% / 2);
	}

  h3 {
    margin-top: 5%;
    word-break: break-word;
    
    @media (min-width: ${reporaSize.medium}) {
      ${props => props.headerSize ? `font-size: ${props.headerSize}` : ``};
      ${props => props.headerPadding ? `padding: ${props.headerPadding}` : ``};
    }
  }

  p {
    margin-top: 5%;

    @media (max-width: ${reporaSize.small}) {
      font-size: 10px;
      line-height: initial;
    }
  }
`;

const ColumnImg = styled.div`
  width: 100%;
  height: ${props => props.size==="small" ?"50px":"100px"};
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width:  ${props => props.size==="small" ?"50px":"100px"};
    width: 100%;
  }
`;

class PanelColumns extends React.Component {
  render() {
    return (
      <Wrapper>
        <PanelContent isheadingPadded={this.props.isheadingPadded} noHeadingMargin={this.props.noHeadingMargin}>
          <Header2 textAlign="center">{this.props.data.header}</Header2>
          {typeof this.props.data.title === "object" && this.props.data.title.length > 0 ? 
            this.props.data.title.map((item, index) =>
              <div className="multi-title" key={index}>
                <Title textAlign="center">{item}</Title>
                {index < this.props.data.title.length - 1 ? <div className="separator" /> : null}
              </div>
            )
          :
            <Title textAlign="center">{this.props.data.title}</Title>
          }
        </PanelContent>
        {this.props.data.columns ? 
          <Columns>
            {Object.keys(this.props.data.columns).map((key, index) => {
              const columnData = this.props.data.columns[key];
              return (
                <Column
                  key={key + index}
                  itemsCount={this.props.data.columns.length}
                  boxcolor={this.props.boxcolor}
                  headerSize={this.props.columnHeaderSize}
                  headerPadding={columnData.headerPadding}
                >
                  <ColumnImg size={this.props.data.size}>
                    <img src={columnData.imgSrc} alt=""/>
                  </ColumnImg>
                  {columnData.header ? 
                    <Header3 textAlign="center">{columnData.header}</Header3>
                  : null}
                  <Body4 textAlign="center">{columnData.description}</Body4>
                </Column>
              );
            })}
          </Columns>
        : null}
      </Wrapper>
    )
  }
}



export const Unwrapped = PanelColumns;
export default PanelColumns;
