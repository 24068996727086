import adminService from "../services/adminService";
import RepService from "../services/RepService";

export const GET_ANALYTICS_COUNT = "GET_ANALYTICS_COUNT";
export const GET_ANALYTICS_COUNT_SUCCESS = "GET_ANALYTICS_COUNT_SUCCESS";
export const GET_ANALYTICS_COUNT_ERROR = "GET_ANALYTICS_COUNT_ERROR";
export const GET_ANALYTICS_REVIEW_COUNT = "GET_ANALYTICS_REVIEW_COUNT";
export const GET_ANALYTICS_REVIEW_COUNT_SUCCESS =
  "GET_ANALYTICS_REVIEW_COUNT_SUCCESS";
export const GET_ANALYTICS_REVIEW_COUNT_ERROR =
  "GET_ANALYTICS_REVIEW_COUNT_ERROR";

export const GET_COMPANY_FEEDBACKS_SUCCESS = "GET_COMPANY_FEEDBACKS_SUCCESS";
export const GET_COMPANY_FEEDBACKS_REQUESTED = "GET_COMPANY_FEEDBACKS_REQUESTED";
export const GET_COMPANY_FEEDBACKS_FAILED = "GET_COMPANY_FEEDBACKS_FAILED";

export const GET_CLAIM_LIST_REQUESTED = "GET_CLAIM_LIST_REQUESTED";
export const GET_CLAIM_LIST_SUCCESS = "GET_CLAIM_LIST_SUCCESS";
export const GET_CLAIM_LIST_FAILED = "GET_CLAIM_LIST_FAILED";

export const UPDATE_CLAIM_STATUS_REQUESTED = "UPDATE_CLAIM_STATUS_REQUESTED";
export const UPDATE_CLAIM_STATUS_SUCEESS = "UPDATE_CLAIM_STATUS_SUCEESS";
export const UPDATE_CLAIM_STATUS_FALILED = "UPDATE_CLAIM_STATUS_FALILED";

export const GET_CUSTOMER_SUGGESTIONS_REQUESTED = "GET_CUSTOMER_SUGGESTIONS_REQUESTED";
export const GET_CUSTOMER_SUGGESTIONS_SUCCEEDED = "GET_CUSTOMER_SUGGESTIONS_SUCCEEDED";
export const GET_CUSTOMER_SUGGESTIONS_FAILED = "GET_CUSTOMER_SUGGESTIONS_FAILED";

export const GET_UNCLAIMED_REPS_REQUESTED = "GET_UNCLAIMED_REPS_REQUESTED";
export const GET_UNCLAIMED_REPS_SUCCEEDED = "GET_UNCLAIMED_REPS_SUCCEEDED";
export const GET_UNCLAIMED_REPS_FAILED = "GET_UNCLAIMED_REPS_FAILED";

export const GET_REPS_ACCOUNTS_REQUESTED = "GET_REPS_ACCOUNTS_REQUESTED";
export const GET_REPS_ACCOUNTS_SUCCEEDED = "GET_REPS_ACCOUNTS_SUCCEEDED";
export const GET_REPS_ACCOUNTS_FAILED = "GET_REPS_ACCOUNTS_FAILED";

export const GET_PARTNER_KEYS_REQUESTED = "GET_PARTNER_KEYS_REQUESTED";
export const GET_PARTNER_KEYS_SUCCEEDED = "GET_PARTNER_KEYS_SUCCEEDED";
export const GET_PARTNER_KEYS_FAILED = "GET_PARTNER_KEYS_FAILED";

export const GET_COMPANY_KEYS_REQUESTED = "GET_COMPANY_KEYS_REQUESTED";
export const GET_COMPANY_KEYS_SUCCEEDED = "GET_COMPANY_KEYS_SUCCEEDED";
export const GET_COMPANY_KEYS_FAILED = "GET_COMPANY_KEYS_FAILED";

export const ADD_PARTNER_REQUESTED = "ADD_PARTNER_REQUESTED";
export const ADD_PARTNER_SUCCEEDED = "ADD_PARTNER_SUCCEEDED";
export const ADD_PARTNER_FAILED = "ADD_PARTNER_FAILED";

export const ADD_COMPANY_KEY_REQUESTED = "ADD_COMPANY_KEY_REQUESTED";
export const ADD_COMPANY_KEY_SUCCEEDED = "ADD_COMPANY_KEY_SUCCEEDED";
export const ADD_COMPANY_KEY_FAILED = "ADD_COMPANY_KEY_FAILED";

export const DELETE_PARTNER_KEY_REQUESTED = "DELETE_PARTNER_KEY_REQUESTED";
export const DELETE_PARTNER_KEY_SUCCEEDED = "DELETE_PARTNER_KEY_SUCCEEDED";
export const DELETE_PARTNER_KEY_FAILED = "DELETE_PARTNER_KEY_FAILED";

export const DELETE_COMPANY_KEY_REQUESTED = "DELETE_COMPANY_KEY_REQUESTED";
export const DELETE_COMPANY_KEY_SUCCEEDED = "DELETE_COMPANY_KEY_SUCCEEDED";
export const DELETE_COMPANY_KEY_FAILED = "DELETE_COMPANY_KEY_FAILED";

export const UPDATE_PARTNER_KEY_REQUESTED = "UPDATE_PARTNER_KEY_REQUESTED";
export const UPDATE_PARTNER_KEY_SUCCEEDED = "UPDATE_PARTNER_KEY_SUCCEEDED";
export const UPDATE_PARTNER_KEY_FAILED = "UPDATE_PARTNER_KEY_FAILED";

export const UPDATE_COMPANY_KEY_REQUESTED = "UPDATE_COMPANY_KEY_REQUESTED";
export const UPDATE_COMPANY_KEY_SUCCEEDED = "UPDATE_COMPANY_KEY_SUCCEEDED";
export const UPDATE_COMPANY_KEY_FAILED = "UPDATE_COMPANY_KEY_FAILED";

const getAnalyticsCountAction = (payload) => ({
  type: GET_ANALYTICS_COUNT,
  payload,
});

const getAnalyticsCountSuccessAction = (payload) => {
  return {
    type: GET_ANALYTICS_COUNT_SUCCESS,
    payload,
  };
};

const getAnalyticsCountErrorAction = (payload) => ({
  type: GET_ANALYTICS_COUNT_ERROR,
  payload,
});

const getAnalyticsReviewCountAction = (payload) => ({
  type: GET_ANALYTICS_REVIEW_COUNT,
  payload,
});

const getAnalyticsReviewCountSuccessAction = (payload) => {
  return {
    type: GET_ANALYTICS_REVIEW_COUNT_SUCCESS,
    payload,
  };
};

const getAnalyticsReviewCountErrorAction = (payload) => ({
  type: GET_ANALYTICS_REVIEW_COUNT_ERROR,
  payload,
});

const requestCompanyFeedbacks = () =>({ type: GET_COMPANY_FEEDBACKS_REQUESTED , payload :null});
const receiveCompanyFeedbacks = (data) =>({ type: GET_COMPANY_FEEDBACKS_SUCCESS , payload : data});
const companyFeedbacksError = (err) =>({ type: GET_COMPANY_FEEDBACKS_FAILED , payload :err});

const requestClaims = () =>({ type: GET_CLAIM_LIST_REQUESTED , payload :null});
const receiveClaims = (data) =>({ type: GET_CLAIM_LIST_SUCCESS , payload : data});
const receiveClaimsErr = (err) =>({ type: GET_CLAIM_LIST_FAILED , payload :err});

const updateClaimStatusRequested = () =>({ type: UPDATE_CLAIM_STATUS_REQUESTED , payload :null});
const updateClaimStatusSucces = (data) =>({ type: UPDATE_CLAIM_STATUS_SUCEESS , payload : data});
const updateClaimStatusErr = (err) =>({ type: UPDATE_CLAIM_STATUS_FALILED , payload :err});

const getCustomerSuggestionsRequested = () => ({ type: GET_CUSTOMER_SUGGESTIONS_REQUESTED });
const getCustomerSuggestionsSucceeded = data => ({ type: GET_CUSTOMER_SUGGESTIONS_SUCCEEDED, payload: data });
const getCustomerSuggestionsFailed = error => ({ type: GET_CUSTOMER_SUGGESTIONS_FAILED, payload: error });


const getUnclaimedRepsRequested = () => ({ type: GET_UNCLAIMED_REPS_REQUESTED });
const getUnclaimedRepsSucceeded = data => ({ type: GET_UNCLAIMED_REPS_SUCCEEDED, payload: data });
const getUnclaimedRepsFailed = error => ({ type: GET_UNCLAIMED_REPS_FAILED, payload: error });

const getRepsAccountsRequested = () => ({ type: GET_REPS_ACCOUNTS_REQUESTED });
const getRepsAccountsSucceeded = data => ({ type: GET_REPS_ACCOUNTS_SUCCEEDED, payload: data });
const getRepsAccountsFailed = error => ({ type: GET_REPS_ACCOUNTS_FAILED, payload: error });

const getPartnerKeysRequested = () => ({ type: GET_PARTNER_KEYS_REQUESTED });
const getPartnerKeysSucceeded = data => ({ type: GET_PARTNER_KEYS_SUCCEEDED, payload: data });
const getPartnerKeysFailed = error => ({ type: GET_PARTNER_KEYS_FAILED, payload: error });

const getCompanyKeysRequested = () => ({ type: GET_COMPANY_KEYS_REQUESTED });
const getCompanyKeysSucceeded = data => ({ type: GET_COMPANY_KEYS_SUCCEEDED, payload: data });
const getCompanyKeysFailed = error => ({ type: GET_COMPANY_KEYS_FAILED, payload: error });

const addPartnerRequested = () => ({ type: ADD_PARTNER_REQUESTED });
const addPartnerSucceeded = data => ({ type: ADD_PARTNER_SUCCEEDED, payload: data });
const addPartnerFailed = error => ({ type: ADD_PARTNER_FAILED, payload: error });

const addCompanyKeyRequested = () => ({ type: ADD_COMPANY_KEY_REQUESTED });
const addCompanyKeySucceeded = data => ({ type: ADD_COMPANY_KEY_SUCCEEDED, payload: data });
const addCompanyKeyFailed = error => ({ type: ADD_COMPANY_KEY_FAILED, payload: error });

const deletePartnerKeyRequested = () => ({ type: DELETE_PARTNER_KEY_REQUESTED });
const deletePartnerKeySucceeded = data => ({ type: DELETE_PARTNER_KEY_SUCCEEDED, payload: data });
const deletePartnerKeyFailed = error => ({ type: DELETE_PARTNER_KEY_FAILED, payload: error });

const deleteCompanyKeyRequested = () => ({ type: DELETE_COMPANY_KEY_REQUESTED });
const deleteCompanyKeySucceeded = data => ({ type: DELETE_COMPANY_KEY_SUCCEEDED, payload: data });
const deleteCompanyKeyFailed = error => ({ type: DELETE_COMPANY_KEY_FAILED, payload: error });

const updatePartnerKeyRequested = () => ({ type: UPDATE_PARTNER_KEY_REQUESTED });
const updatePartnerKeySucceeded = data => ({ type: UPDATE_PARTNER_KEY_SUCCEEDED, payload: data });
const updatePartnerKeyFailed = error => ({ type: UPDATE_PARTNER_KEY_FAILED, payload: error });

const updateCompanyKeyRequested = () => ({ type: UPDATE_COMPANY_KEY_REQUESTED });
const updateCompanyKeySucceeded = data => ({ type: UPDATE_COMPANY_KEY_SUCCEEDED, payload: data });
const updateCompanyKeyFailed = error => ({ type: UPDATE_COMPANY_KEY_FAILED, payload: error });

export const requestAnalyticsCount = (payload) => {
  console.log("Check the requestAnalyticsCount", payload);
  return (dispatch) => {
    dispatch(getAnalyticsCountAction());
    adminService
      .getAnalyticsCount()
      .then((response) => {
        console.log("Check the response", response);
        dispatch(getAnalyticsCountSuccessAction(response));
      })
      .catch((error) => {
        console.log("Err : ", error);
        dispatch(getAnalyticsCountErrorAction(error));
      });
  };
};

export const requestAnalyticsReviewsCount = (payload) => {
  console.log("Check the requestAnalyticsCount", payload);
  return (dispatch) => {
    dispatch(getAnalyticsReviewCountAction());
    adminService
      .getAnalyticsReviewsCount()
      .then((response) => {
        console.log("Check the response", response);
        dispatch(getAnalyticsReviewCountSuccessAction(response));
      })
      .catch((error) => {
        console.log("Err : ", error);
        dispatch(getAnalyticsReviewCountErrorAction(error));
      });
  };
};

export const handleGetCompanyFeedbacks = (id) => {
  return (dispatch) => {
    dispatch(requestCompanyFeedbacks());
    adminService
      .getCompanyFeedbacks(id)
      .then((response) => {
        dispatch(receiveCompanyFeedbacks(response.payload));
      })
      .catch((error) => {
        dispatch(companyFeedbacksError(error));
      });
  };
};

export const getRepFeedbacks = (id) => {
  return (dispatch , getState ) => {
    const state = getState();
    const auth = state.auth;
    const userData = auth && auth.userData;
    dispatch(requestCompanyFeedbacks());
    RepService
      .improvedGetRepFeedback(id , userData.id)
      .then((response) => {
        dispatch(receiveCompanyFeedbacks(response.payload));
      })
      .catch((error) => {
        dispatch(companyFeedbacksError(error));
      });
  };
};


export const getClaimList = (status) => {
  return (dispatch) => {
    dispatch(requestClaims());
    adminService
      .getClaimList(status)
      .then((response) => {
        dispatch(receiveClaims(response.payload));
      })
      .catch((error) => {
        dispatch(receiveClaimsErr(error));
      });
  };
};

export const updateClaimStatus = (obj , status) => {
  return (dispatch) => {
    dispatch(updateClaimStatusRequested());
    adminService
      .updateClaimStatus(obj)
      .then((response) => {
        dispatch(updateClaimStatusSucces(response.payload));
        dispatch(getClaimList(status))
      })
      .catch((error) => {
        dispatch(updateClaimStatusErr(error));
      });
  };
};

export function getCustomerSuggestions(customerName, customerId) {
  return dispatch => {
    dispatch(getCustomerSuggestionsRequested());

    adminService.getCustomerSuggestions(customerName, customerId)
      .then(response => {
        dispatch(getCustomerSuggestionsSucceeded(response));
      })
      .catch(error => {
        dispatch(getCustomerSuggestionsFailed(error));
      });
  };
}

export function getUnclaimedReps() {
  return dispatch => {
    dispatch(getUnclaimedRepsRequested());
    adminService.getUnclaimedReps()
      .then(response => {
        dispatch(getUnclaimedRepsSucceeded(response));
      })
      .catch(error => {
        dispatch(getUnclaimedRepsFailed(error));
      });
  };
}

export function getRepsAccounts() {
  return dispatch => {
    dispatch(getRepsAccountsRequested());
    adminService.getRepsAccounts()
      .then(response => {
        dispatch(getRepsAccountsSucceeded(response));
      })
      .catch(error => {
        dispatch(getRepsAccountsFailed(error));
      });
  };
}

export function getPartnerKeys() {
  return dispatch => {
    dispatch(getPartnerKeysRequested());
    adminService.getPartnerKeys()
      .then(response => {
        dispatch(getPartnerKeysSucceeded(response));
      })
      .catch(error => {
        dispatch(getPartnerKeysFailed(error));
      });
  };
}

export function getCompanyKeys() {
  return dispatch => {
    dispatch(getCompanyKeysRequested());
    adminService.getCompanyKeys()
      .then(response => {
        dispatch(getCompanyKeysSucceeded(response));
      })
      .catch(error => {
        dispatch(getCompanyKeysFailed(error));
      });
  };
}

export function addNewPartner(obj) {
  return dispatch => {
    dispatch(addPartnerRequested());
    adminService.addNewPartner(obj)
      .then(response => {
        dispatch(addPartnerSucceeded(response));
        dispatch(getPartnerKeys());
      })
      .catch(error => {
        dispatch(addPartnerFailed(error));
      });
  };
}

export function addCompanyKey(obj) {
  return dispatch => {
    dispatch(addCompanyKeyRequested());
    adminService.addCompanyKey(obj)
      .then(response => {
        dispatch(addCompanyKeySucceeded(response));
        dispatch(getCompanyKeys());
      })
      .catch(error => {
        dispatch(addCompanyKeyFailed(error));
      });
  };
}

export function deletePartnerKey(obj) {
  return dispatch => {
    dispatch(deletePartnerKeyRequested());
    adminService.deletePartnerKey(obj)
      .then(response => {
        dispatch(deletePartnerKeySucceeded(response));
        dispatch(getPartnerKeys());
      })
      .catch(error => {
        dispatch(deletePartnerKeyFailed(error));
      });
  };
}

export function deleteCompanyKey(obj) {
  return dispatch => {
    dispatch(deleteCompanyKeyRequested());
    adminService.deleteCompanyKey(obj)
      .then(response => {
        dispatch(deleteCompanyKeySucceeded(response));
        dispatch(getCompanyKeys());
      })
      .catch(error => {
        dispatch(deleteCompanyKeyFailed(error));
      });
  };
}

export function updatePartnerKey(obj) {
  return dispatch => {
    dispatch(updatePartnerKeyRequested());
    adminService.updatePartnerKey(obj)
      .then(response => {
        dispatch(updatePartnerKeySucceeded(response));
        dispatch(getPartnerKeys());
      })
      .catch(error => {
        dispatch(updatePartnerKeyFailed(error));
      });
  };
}


export function updateCompanyKey(obj) {
  return dispatch => {
    dispatch(updateCompanyKeyRequested());
    adminService.updateCompanyKey(obj)
      .then(response => {
        dispatch(updateCompanyKeySucceeded(response));
        dispatch(getPartnerKeys());
      })
      .catch(error => {
        dispatch(updateCompanyKeyFailed(error));
      });
  };
}