import React from "react";
import styled from "styled-components";
import ReactPhoneInput from "react-phone-input-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { Header3, Header5, reporaColor, FormRow, FormGroup, reporaDevice } from "../../../components/global/commonStyles";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { Wrapper, CloseButton, BackButton, HeaderWrapper, FormWrapper, PhoneGroup, ButtonContainer } from "./CommonStyles";
import Button from "../../../components/Buttons/Button";

const CollapseRow = styled(FormRow)`
  @media ${reporaDevice.xs} {
    flex-direction: column;

    >div {
      margin: 0;
      width: 100%;
      margin-top: 14px;
    }
  }
`;

const Step2Company = props => {

  const isValid = (
    props.step2Data &&
    props.step2Data.companyName && props.step2Data.companyName.trim() &&
    props.step2Data.linkedinUrl && props.step2Data.linkedinUrl.trim() &&
    props.step2Data.city && props.step2Data.city.trim() &&
    props.step2Data.zipCode && props.step2Data.zipCode.trim()
  );

  return (
    <Wrapper>
      <HeaderWrapper>
        <BackButton onClick={props.handleBack}><FontAwesomeIcon icon={faArrowLeft} /></BackButton>
        <CloseButton onClick={props.handleClose}><FontAwesomeIcon icon={faTimes} /></CloseButton>

        <Header3 textAlign="center">Sign Up</Header3>
        <Header5 textAlign="center" textColor={`${reporaColor.brightSkyBlue}`}>Enter your company information</Header5>
      </HeaderWrapper>
      <FormWrapper>
        <FormGroup>
          <label>Company Name</label>
          <input onChange={props.handleInputChange} name="companyName" value={props.step2Data.companyName || ""} />
        </FormGroup>

        <FormGroup>
          <label>LinkedIn URL</label>
          <input required onChange={props.handleInputChange} name="linkedinUrl" value={props.step2Data.linkedinUrl || ""} />
        </FormGroup>

        <FormGroup>
          <label>Industry</label>
          <Dropdown customWidth="100%" options={props.industriesList} value={props.step2Data.industry} placeholder="Industry" onClick={props.handleIndustryChange} width="100%" left />
        </FormGroup>

        <FormGroup>
          <label>Country</label>
          <Dropdown customWidth="100%" options={props.countryList} value={props.step2Data.country} placeholder="Country" onClick={props.handleCountryChange} width="100%" left />
        </FormGroup>

        <FormGroup>
          <label>City</label>
          <input onChange={props.handleInputChange} name="city" value={props.step2Data.city || ""} />
        </FormGroup>

        <FormGroup>
          <label>State</label>
          <Dropdown
            options={props.cityList}
            value={props.step2Data.state}
            placeholder="State"
            onClick={props.handleCityChange}
            customWidth="100%"
            left />
        </FormGroup>

        <CollapseRow>
          <FormGroup width="40%">
            <label>Zip Code</label>
            <input onChange={props.handleInputChange} name="zipCode" value={props.step2Data.zipCode || ""} />
          </FormGroup>
          <PhoneGroup width="60%">
            <label>Phone Number</label>
            <ReactPhoneInput
              name="phoneNumber"
              inputStyle={{ width: "100%", height: "40px", }}
              buttonStyle={{ zIndex: 1 }}
              disableAreaCodes={false}
              defaultCountry={"us"}
              value={props.step2Data.phoneNumber || ""}
              onChange={value => {
                props.handleInputChange({ target: { name: "phoneNumber", value: value } });
              }} />
          </PhoneGroup>
        </CollapseRow>

        <ButtonContainer className="m-b-45">
          <Button
            disabled={!props.validStep || !isValid}
            onClick={props.handleStepSubmit}
            className="m-t-10"
            width="100%">
            Continue
          </Button>
        </ButtonContainer>
      </FormWrapper>
    </Wrapper>
  );
};

export default Step2Company;
