import { get } from "lodash";

import config from "../config";
import constants from "../constants";
import authdAxios from "../modules/Authentication/utils/authdAxios";

const SearchService = () => {
  function searchReps(
    repName,
    repId,
    companyName,
    companyId,
    unclaimed,
    sessionUserId
  ) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/reps/search-reps`,
          { repName, repId, companyName, companyId, unclaimed, sessionUserId },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function searchCompanyReps(repName, repId, companyId, sessionUserId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/reps/search-company-reps`,
          { repName, repId, companyId, sessionUserId },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function getCompanyReps(companyId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/reps/get-all-by-company-id`,
          {
            id: companyId,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(({ data }) => {
          if (data.statusCode !== 200) {
            reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
          }
          resolve(data.payload);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  return {
    searchReps,
    searchCompanyReps,
    getCompanyReps,
  };
};

export default SearchService();
