import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import OutsideClickHandler from "react-outside-click-handler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { Header5, ReporaButton, reporaColor, reporaDevice } from "../../../components/global/commonStyles";
import Loader from "../../../components/Loader/Loader";
import DefaultAvatarSvg from "../assets/default-avatar.svg";
import SearchIcon from "../assets/search2.svg";
import constants from "../../../constants";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  position: relative;
  max-width:${props => props.maxWidth ? props.maxWidth:``};

  input {
    padding: 0 10px;
    padding-left: ${props => props.searchIcon ? "40px" : ""};
    height: ${props => props.inputHeight ? props.inputHeight : "40px"};
    border-radius: 4px;
    border: solid 1px ${reporaColor.grey87};
    background-color: ${reporaColor.white};

    font-size: 14px;
    color: ${reporaColor.black};
    font-family: Nexa Bold;

    width: 100%;

    ::placeholder {
      color: ${reporaColor.warmGrey};
    }
     
    &:focus {
      outline: none !important;
      border:1px solid ${reporaColor.brightSkyBlue};
      box-shadow: 0 0 2px ${reporaColor.brightSkyBlue};
  }
  }
`;

const SuggestionsContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #E8E8E8;
  margin-top: 5px;

  position: absolute;
  z-index: 1;
  width: ${props => props.width ? props.width:`330px`};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);

  @media ${reporaDevice.xs} {
    width: ${props => props.fromAdmin ? props.width:`100%`};

  }
`;

const SuggestionList = styled.ul`
  max-height: ${props => props.maxSuggestions ? `${props.maxSuggestions * 50}px` : "auto"};
  overflow: auto;
  background: white;
`;

const SuggestionItem = styled.li`
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  background: ${props => props.selected && reporaColor.grey91};

  ${props => props.noRecords && `justify-content: space-between`}

  &:hover {
    background: ${reporaColor.grey91};
  }

  img {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    margin-right: 15px;
  }

  p {
    font-weight: bold;
    color: ${reporaColor.warmGrey};
  }

  p:first-of-type {
    font-size: 14px;
  }

  p:last-of-type {
    font-size: 10px;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 10px;
  cursor: pointer;
  color: ${reporaColor.warmGrey};

  &:hover {
    color: black;
  }
`;

const SearchIconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

let requestTimeout = null;

class InputSuggestion extends React.Component {
  state = {
    inputValue: "",
    suggestions: [],
    showSuggestions: false,
    selectedIndex: -1
  }

  constructor(props) {
    super(props);
    this.suggestionRefs = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedElement } = this.props;
    const { inputValue } = this.state;

    if (selectedElement && selectedElement !== prevProps.selectedElement) {
      this.setState({
        inputValue: selectedElement.title
      });
    }

    if (this.props.suggestions !== prevProps.suggestions) {
      this.setState({
        showSuggestions: true
      });
    }

    if (inputValue !== prevState.inputValue) {
      this.setState({
        showSuggestions: false
      });
    }
  }

  handleKeyDown = e => {
    const { selectedIndex } = this.state;
    const { suggestions } = this.props;

    if (suggestions && suggestions.length > 0) {
      if (e.keyCode === 40) { // key down
        this.setState({
          selectedIndex: (selectedIndex + 1) >= suggestions.length ? suggestions.length - 1 : (selectedIndex + 1)
        });
      }

      if (e.keyCode === 38) { // key up
        this.setState({
          selectedIndex: (selectedIndex - 1) < 0 ? 0 : (selectedIndex - 1)
        });
      }

      if (e.keyCode === 13 && selectedIndex !== -1) { // enter key
        e.preventDefault();
        this.handleSelectElement(suggestions[selectedIndex]);
      }
    }
  }

  handleInputChange = event => {
    const value = event.target.value;

    this.setState({
      inputValue: value
    });

    if (this.props.handleInputChange) {
      this.props.handleInputChange(value);
    }

    if (value.length >= 2) {
      clearTimeout(requestTimeout);
      requestTimeout = setTimeout(() => {
        this.props.handleGetSuggestions(value);
      }, this.props.inputDelay);
    }
  }

  handleOutsideClick = () => {
    const { suggestions } = this.props;

    if (suggestions) {
      this.handleCloseSuggestions();
    }
  }

  handleCloseSuggestions = () => {
   setTimeout(() => {
    this.setState({
      showSuggestions: false,
      selectedIndex: -1
    });
   }, 500);
  }

  handleShowSuggestions = () => {
    this.setState({
      showSuggestions: true
    });
  }

  setInputFocus = () => {
    const inputNode = this.inputRef.current;
    inputNode.focus();
  }

  handleClearInput = () => {
    this.setState({
      inputValue: "",
      addInputValue: ""
    });

    this.setInputFocus();

    if (this.props.handleSelectElement) {
      this.props.handleSelectElement(null);
    }

    if (this.props.companySearch) {
      this.props.handlesetRepSearch("")
    }
  }

  handleSelectElement = suggestion => {
    this.handleCloseSuggestions();

    if (this.props.handleSelectElement) {
      this.props.handleSelectElement(suggestion);
    }
  }

  render() {
    const { maxSuggestions, minChars } = this.props;
    const node = this.suggestionRefs.current;
    if (node && node.children) {
      const selectedEl = node.children[0].childNodes[this.state.selectedIndex];
      if (selectedEl) {
        node.children[0].scrollTop = selectedEl.offsetTop;
      }
    }
    let tempVar = this.state.inputValue;
    if (this.props.companySearch) {
      tempVar = this.props.repSearchVal
    }

    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <Wrapper className={this.props.className} searchIcon={this.props.searchIcon} maxWidth={this.props?.maxWidth}  inputHeight = {this.props?.inputHeight}>

          {this.props.searchIcon &&
            <SearchIconContainer>
              <img src={SearchIcon} alt="search" />
            </SearchIconContainer>
          }

          <input
            name={this.props.name}
            onFocus={this.handleShowSuggestions}
            onBlur={this.handleCloseSuggestions}
            value={tempVar}
            placeholder={this.props.placeholder}
            onChange={this.handleInputChange}
            onKeyDown={this.handleKeyDown}
            ref={this.inputRef}
            autoComplete="off"
            autoFocus={this.props.autoFocus}
            className="textFocus"
            readOnly={this.props.loading}
          />

          {this.props.loading && <Loader overlayed size="small" />}

          {this.state.inputValue.length > 0 &&
            <CloseButton onClick={this.handleClearInput}>
              <FontAwesomeIcon icon={faTimes} />
            </CloseButton>
          }

          {!this.props.loading &&
            this.state.showSuggestions &&
            this.state.inputValue.length >= minChars &&
            this.props.suggestions.length > 0 &&
            !this.props.selectedElement &&
            <SuggestionsContainer width={ this.props.suggestionWidth } fromAdmin={this.props?.fromAdmin}>
              <div ref={this.suggestionRefs}>
                <SuggestionList maxSuggestions={maxSuggestions}>
                  {this.props.suggestions.map((suggestion, index) =>
                    <SuggestionItem
                      selected={index === this.state.selectedIndex}
                      key={index}
                      onMouseDown={() => this.handleSelectElement(suggestion)}>
                      { this.props.name !== "new-company-suggestions" &&
                      <img
                        alt="profile"
                        src={suggestion.picture || this.props.defaultPicture || DefaultAvatarSvg}
                        onError={event => {
                          event.target.onerror = null;
                          event.target.src = DefaultAvatarSvg;
                        }} />}
                      <div>
                        <p>{suggestion.title}</p>
                        {suggestion.subtitle && <p>{suggestion.subtitle}</p>}
                      </div>
                    </SuggestionItem>
                  )}
                </SuggestionList>
              </div>
            </SuggestionsContainer>
          }
          {
            !this.props.loading &&
            this.state.showSuggestions &&
            this.state.inputValue.length >= minChars &&
            this.props.suggestions.length == 0 &&
            this.props?.name === "new-rep-suggestions" &&
            this.props.user?.userRole === constants.USER_ROLES.CUSTOMER &&
            <SuggestionsContainer width={ this.props.suggestionWidth }>
              <div ref={this.suggestionRefs}>
                <SuggestionList>
                    <SuggestionItem noRecords>
                      <div style={{display : "contents"}}>
                      <Header5>No records found</Header5>
                      <Link style={{ textDecoration : "none"}} to="/ratenewrep"><ReporaButton small>Add Rep</ReporaButton></Link>
                      </div>
                    </SuggestionItem>
                </SuggestionList>
              </div>
            </SuggestionsContainer>
          }

        </Wrapper>
      </OutsideClickHandler >
    );
  }
}

InputSuggestion.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,

  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      picture: PropTypes.string
    })
  ).isRequired,

  maxSuggestions: PropTypes.number,
  minChars: PropTypes.number,
  loading: PropTypes.bool,
  handleAddElement: PropTypes.func,
  selectedElement: PropTypes.object,
  defaultPicture: PropTypes.string,
  name: PropTypes.string
};

InputSuggestion.defaultProps = {
  maxSuggestions: 5,
  minChars: 2,
  placeholder: "",
  loading: false
}

export default InputSuggestion;