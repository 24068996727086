import _ from "lodash";

import constants from "../constants";

export const onThen = (resolve, reject, response) => {
    const statusCode = _.get(response, "data.statusCode", 500);
    if (statusCode !== 200) {
        reject(_.get(response, "data.message", constants.ERROR_MESSAGES.DEFAULT))
    }
    resolve(_.get(response, "data.payload", {}))
}

export const onCatch = (reject, error) => {
    let message = _.get(error, "data.payload")
    if (!message) {
        message = _.get(error, "data.message", constants.ERROR_MESSAGES.DEFAULT)
    }
    reject(message)
}