import styled from "styled-components";
import { reporaColor, reporaDevice, PrimaryFont } from "../../../components/global/commonStyles";

export const Wrapper = styled.div`
  max-width: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: ${props => props.bkgImage || "none"};
  align-items: center;
  justify-content: center;
  margin: 80px auto 20px;
  position: relative;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 #00000033;

  @media ${reporaDevice.tablet} {
    max-width: 502px;
  }
`;

export const BackButton = styled.span`
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 20px;
  cursor: pointer;
  color: ${reporaColor.warmGrey};
`;

export const CloseButton = styled.span`
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 20px;
  cursor: pointer;
  color: ${reporaColor.warmGrey};
`;

export const HeaderWrapper = styled.div`
  max-width: 450px;
  width: 100%;
  margin-top: 34px;
`;

export const SocialButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  a {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const SideLines = styled.div`
  font-size: 14px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
  color: ${reporaColor.warmGrey};

  &:before {
    content: "";
    border-bottom: 1px solid;
    height: 50%;
    flex: 1;
    margin-right: 10px;
  }

  &:after {
    content: "";
    border-bottom: 1px solid;
    height: 50%;
    flex: 1;
    margin-left: 10px;
  }
`;

export const FormWrapper = styled.form`
  max-width: 380px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props => props.small
    ? "width: 300px;"
    : null
  }

  @media ${reporaDevice.mobile} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const ButtonContainer = styled.div`
  margin-bottom: 45px;
  width: 100%;
`;

export const FormDisclaimer = styled.div`
  font-size: 14px;
  line-height: 28px;
  color: ${reporaColor.warmGrey};
  margin-top: 18px;
`;

export const Button = styled.div`
  height: 70px;
  border-radius: 6px;
  background-color: #fafafa;
  border: solid 1px #dedede;
  cursor: pointer;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 35px;
  margin-bottom: 20px;

  &:hover {
    background: ${reporaColor.brightSkyBlue};
    color: white;

    svg {
      color: white;
    }
  }

  &:last-child {
    margin-bottom: 81px;
  }

  svg {
    color: ${reporaColor.warmGrey};
  }
`;

export const HeaderText = styled.h5`
  ${PrimaryFont}
  font-size: 14px;
  margin:21px 0;
  margin-bottom: 44px;
`;

export const ForgotLink = styled.span`
  text-align: left;
  width: 100%;

  a {
    color: ${reporaColor.brightSkyBlue};
    font-size: 12px;
  }
`;

export const SignupLink = styled.span`
  text-align: left;

  a {
    color: ${reporaColor.brightSkyBlue};
    font-size: 12px;
  }
`;

export const CreateContainer = styled.div`
  text-align: center;
  margin: 20px 0;
  font-size: 12px;
  color: ${reporaColor.warmGrey};
`;

export const LinkedInButton = styled.a`
  display: flex;
  align-items: center;
  background-color: ${reporaColor.brightSkyBlue};
  color: ${reporaColor.white};
  width: auto;
  height: 40px;
  border-radius: 2px;
  margin-bottom: 45px;
  cursor: pointer;

  @media ${reporaDevice.tablet} {
    width: 287px;
  }
`;

export const LinkedInButtonImageContainer = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid;
  height: 100%;
`;

export const LinkedInButtonText = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
`;

export const FormError = styled.div`
  color: ${reporaColor.watermelon};
  width: 100%;
  margin-top: 15px;
`;
