import * as adminActions from "../actions/adminActions";

const initialState = {
  adminAnalyticsCount: {},
  adminAnalyticsReviewsCount: {},
  adminStatusLoading: false,

  companyFeedBacks:[],
  fetchingCompanyFeedbacks:false,
  companyFeedbackError:'',

  claimsList : [],
  fetchingClaims : false,
  claimsListErr : '',

  updateClaimStatusReq : false,
  claimStatusUpdated : [],
  updateClaimStatusErr : '',

  fetchingCustomerSuggestions: false,
  customerSuggestions:[],
  customerSuggestionsErr:'',

  fetchingUnclaimedReps : false,
  unclaimedReps : [],
  unclaimedRepsErr : '',

  fetchingRepsAccounts : false,
  repsAccounts : [],
  repsAccountsErr : '',

  fetchingPartnerKeys : false,
  partnerKeys : [],
  partnerKeysErr : "",

  fetchingCompanyKeys : false,
  companyKeys : [],
  companyKeysErr : "",

  isAddingPartnerkey : false,
  partnerKeyAdded : [],
  errAddingPartnerKey : "",

  isAddingCompanykey : false,
  companyKeyAdded : [],
  errAddingCompanyKey : "",

  deletingPartnerKey : false,
  partnerKeyDeleted : [],
  errDeletingPartnerKey : "",

  updatingPartnerkey : false,
  partnerKeyUpdated : [],
  errUpdatingPartnerKey : ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case adminActions.GET_ANALYTICS_COUNT: {
      return {
        ...state,
        adminStatusLoading: true,
      };
    }
    case adminActions.GET_ANALYTICS_COUNT_SUCCESS: {
      return {
        ...state,
        adminStatusLoading: false,
        adminAnalyticsCount: action.payload,
      };
    }
    case adminActions.GET_ANALYTICS_COUNT_ERROR: {
      return {
        ...state,
        adminStatusLoading: false,
        adminAnalyticsCountError: action.payload,
      };
    }
    case adminActions.GET_ANALYTICS_REVIEW_COUNT: {
      return {
        ...state,
        adminStatusLoading: true,
      };
    }
    case adminActions.GET_ANALYTICS_REVIEW_COUNT_SUCCESS: {
      return {
        ...state,
        adminStatusLoading: false,
        adminAnalyticsReviewsCount: action.payload,
      };
    }
    case adminActions.GET_ANALYTICS_REVIEW_COUNT_ERROR: {
      return {
        ...state,
        adminStatusLoading: false,
        adminAnalyticsReviewsCountError: action.payload,
      };
    }
    case adminActions.GET_COMPANY_FEEDBACKS_REQUESTED:{
      return {
        ...state,
        fetchingCompanyFeedbacks : true,
        companyFeedBacks : [],
        companyFeedbackError : ""
      }
    }
    case adminActions.GET_COMPANY_FEEDBACKS_SUCCESS:{
      return {
        ...state,
        fetchingCompanyFeedbacks : false,
        companyFeedBacks : action.payload,
        companyFeedbackError : ""
      }
    }
    case adminActions.GET_COMPANY_FEEDBACKS_REQUESTED:{
      return {
        ...state,
        fetchingCompanyFeedbacks : false,
        companyFeedBacks : [],
        companyFeedbackError : action.payload
      }
    }
    case adminActions.GET_CLAIM_LIST_REQUESTED:{
      return {
        ...state,
        fetchingClaims : true,
        claimsList : [],
      }
    }
    case adminActions.GET_CLAIM_LIST_SUCCESS:{
      return {
        ...state,
        fetchingClaims : false,
        claimsList : action.payload,
      }
    }
    case adminActions.GET_CLAIM_LIST_FAILED:{
      return {
        ...state,
        fetchingClaims : false,
        claimsList : [],
        claimsListErr : action.payload
      }
    }
    case adminActions.UPDATE_CLAIM_STATUS_REQUESTED:{
      return {
        ...state,
        updateClaimStatusReq : true,
        claimStatusUpdated : [],
        updateClaimStatusErr : ''
      }
    }
    case adminActions.UPDATE_CLAIM_STATUS_SUCEESS:{
      return {
        ...state,
        updateClaimStatusReq : false,
        claimStatusUpdated : action.payload,
        updateClaimStatusErr : ''
      }
    }
    case adminActions.UPDATE_CLAIM_STATUS_FALILED:{
      return {
        ...state,
        updateClaimStatusReq : false,
        claimStatusUpdated : [],
        updateClaimStatusErr : action.payload
      }
    }
    case adminActions.GET_CUSTOMER_SUGGESTIONS_REQUESTED:{
      return {
        ...state,
        fetchingCustomerSuggestions : true,
        customerSuggestions : [],
        customerSuggestionsErr : ''
      }
    }
    case adminActions.GET_CUSTOMER_SUGGESTIONS_SUCCEEDED:{
      return {
        ...state,
        fetchingCustomerSuggestions : false,
        customerSuggestions : action.payload,
        customerSuggestionsErr : ''
      }
    }
    case adminActions.GET_CUSTOMER_SUGGESTIONS_FAILED:{
      return {
        ...state,
        fetchingCustomerSuggestions : false,
        customerSuggestions : [],
        customerSuggestionsErr : action.payload
      }
    }
    case adminActions.GET_UNCLAIMED_REPS_REQUESTED : {
      return {
        ...state,
        fetchingUnclaimedReps : true,
        unclaimedReps : []
      }
    }
    case adminActions.GET_UNCLAIMED_REPS_SUCCEEDED : {
      return {
        ...state,
        fetchingUnclaimedReps : false,
        unclaimedReps : action.payload,
        unclaimedRepsErr : ""
      }
    }
    case adminActions.GET_UNCLAIMED_REPS_FAILED : {
      return {
        ...state,
        fetchingUnclaimedReps : false,
        unclaimedReps : [],
        unclaimedRepsErr : action.payload
      }
    }
    case adminActions.GET_REPS_ACCOUNTS_REQUESTED : {
      return {
        ...state,
        fetchingRepsAccounts : true,
        repsAccounts : []
      }
    }
    case adminActions.GET_REPS_ACCOUNTS_SUCCEEDED : {
      return {
        ...state,
        fetchingRepsAccounts : false,
        repsAccounts : action.payload,
        repsAccountsErr : ""
      }
    }
    case adminActions.GET_REPS_ACCOUNTS_FAILED : {
      return {
        ...state,
        fetchingRepsAccounts : false,
        repsAccounts : [],
        repsAccountsErr : action.payload
      }
    }
    case adminActions.GET_PARTNER_KEYS_REQUESTED : {
      return {
        ...state,
        fetchingPartnerKeys : true,
        partnerKeys : []
      }
    }
    case adminActions.GET_PARTNER_KEYS_SUCCEEDED: {
      return {
        ...state,
        fetchingPartnerKeys : false,
        partnerKeys : action.payload,
        partnerKeysErr : ""
      }
    }
    case adminActions.GET_PARTNER_KEYS_FAILED: {
      return {
        ...state,
        fetchingPartnerKeys : false,
        partnerKeys : [],
        partnerKeysErr : action.payload
      }
    }
    case adminActions.GET_COMPANY_KEYS_REQUESTED : {
      return {
        ...state,
        fetchingCompanyKeys : true,
        companyKeys : []
      }
    }
    case adminActions.GET_COMPANY_KEYS_SUCCEEDED: {
      return {
        ...state,
        fetchingCompanyKeys : false,
        companyKeys : action.payload,
        companyKeysErr : ""
      }
    }
    case adminActions.GET_COMPANY_KEYS_FAILED: {
      return {
        ...state,
        fetchingCompanyKeys : false,
        companyKeys : [],
        companyKeysErr : action.payload
      }
    }
    case adminActions.ADD_PARTNER_REQUESTED : {
      return {
        ...state,
        isAddingPartnerkey : true,
        partnerKeyAdded : []
      }
    }
    case adminActions.ADD_PARTNER_SUCCEEDED: {
      return {
        ...state,
        isAddingPartnerkey : false,
        partnerKeyAdded : action.payload,
        errAddingPartnerKey : ""
      }
    }
    case adminActions.ADD_PARTNER_FAILED: {
      return {
        ...state,
        isAddingPartnerkey : false,
        partnerKeyAdded : [],
        errAddingPartnerKey : action.payload
      }
    }
    case adminActions.ADD_COMPANY_KEY_REQUESTED : {
      return {
        ...state,
        isAddingCompanykey : true,
        companyKeyAdded : []
      }
    }
    case adminActions.ADD_COMPANY_KEY_SUCCEEDED: {
      return {
        ...state,
        isAddingCompanykey : false,
        companyKeyAdded : action.payload,
        errAddingCompanyKey : ""
      }
    }
    case adminActions.ADD_COMPANY_KEY_FAILED: {
      return {
        ...state,
        isAddingCompanykey : false,
        companyKeyAdded : [],
        errAddingCompanyKey : action.payload
      }
    }
    case adminActions.DELETE_PARTNER_KEY_REQUESTED : {
      return {
        ...state,
        deletingPartnerKey : true,
        partnerKeyDeleted : []
      }
    }
    case adminActions.DELETE_PARTNER_KEY_SUCCEEDED: {
      return {
        ...state,
        deletingPartnerKey : false,
        partnerKeyDeleted : action.payload,
        errDeletingPartnerKey : ""
      }
    }
    case adminActions.DELETE_PARTNER_KEY_FAILED: {
      return {
        ...state,
        deletingPartnerKey : false,
        partnerKeyDeleted : [],
        errDeletingPartnerKey : action.payload
      }
    }
    case adminActions.UPDATE_PARTNER_KEY_REQUESTED : {
      return {
        ...state,
        updatingPartnerkey : true,
        partnerKeyUpdated : []
      }
    }
    case adminActions.UPDATE_PARTNER_KEY_SUCCEEDED: {
      return {
        ...state,
        updatingPartnerkey : false,
        partnerKeyUpdated : action.payload,
        errUpdatingPartnerKey : ""
      }
    }
    case adminActions.UPDATE_PARTNER_KEY_FAILED: {
      return {
        ...state,
        updatingPartnerkey : false,
        partnerKeyUpdated : [],
        errUpdatingPartnerKey : action.payload
      }
    }
    case adminActions.UPDATE_COMPANY_KEY_REQUESTED : {
      return {
        ...state,
        updatingCompanykey : true,
        companyKeyUpdated : []
      }
    }
    case adminActions.UPDATE_COMPANY_KEY_SUCCEEDED: {
      return {
        ...state,
        updatingCompanykey : false,
        companyKeyUpdated : action.payload,
        errUpdatingCompanyKey : ""
      }
    }
    case adminActions.UPDATE_COMPANY_KEY_FAILED: {
      return {
        ...state,
        updatingCompanykey : false,
        companyKeyUpdated : [],
        errUpdatingCompanyKey : action.payload
      }
    }
    default:
      return state;
  }
};
