import {
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILURE,
  GET_REPLIST_REQUEST,
  GET_REPLIST_SUCCESS,
  GET_REPLIST_FAILURE,
  GET_REPATTRLIST_REQUEST,
  GET_REPATTRLIST_SUCCESS,
  GET_ROLELIST_SUCCESS,
  GET_REPATTRLIST_FAILURE,
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILURE,
  GET_INFO_REQUEST,
  GET_INFO_SUCCESS,
  GET_INFO_FAILURE,
  GET_COMPANY_REPLIST_REQUEST,
  GET_COMPANY_REPLIST_SUCCESS,
  GET_COMPANY_REPLIST_FAILURE,
  UPDATE_COMPANY_INFO_REQUEST,
  UPDATE_COMPANY_INFO_SUCCESS,
  UPDATE_COMPANY_INFO_FAILURE,
  GET_PREVIOUS_COMPANIES_REQUEST,
  GET_PREVIOUS_COMPANIES_SUCCESS,
  GET_PREVIOUS_COMPANIES_FAILURE,
  GLOBAL_VERIFIED_CUSTOMER_SELECTED,
  GLOBAL_PROGRAM_CHANGE,
  GLOBAL_TEAM_CHANGE,
  GLOBAL_ANONYMOUS_RATING_SELECTED,
  GET_DASHBOARD_COMPANY_INFO_REQUESTED,
  GET_DASHBOARD_COMPANY_INFO_SUCCEEDED,
  GET_DASHBOARD_COMPANY_INFO_FAILED,
  GET_HOTSPOT_REPORT_REQUESTED,
  GET_HOTSPOT_REPORT_SUCCEEDED,
  GET_HOTSPOT_REPORT_FAILED,
  REPORT_ABUSE_REQUESTED,
  REPORT_ABUSE_SUCCEEDED,
  REPORT_ABUSE_FAILED,
  UPDATE_COMPANY_LOGO_SUCCESS,
  UPDATE_COMPANY_LOGO_FAILURE,
  UPDATE_COMPANY_LOGO_REQUEST,
  UPDATE_COMPANY_INFO_RESET,
  UPDATE_COMPANY_LOGO_RESET,
  DELETE_COMPANY_LOGO_REQUESTED,
  DELETE_COMPANY_LOGO_SUCCEEDED,
  DELETE_COMPANY_LOGO_FAILED,
  DELETE_COMPANY_LOGO_RESET,
  MINIMUM_RATING_REQUEST,
  MINIMUM_RATING_SUCCESS,
  MINIMUM_RATING_FAILURE,
  GET_COMPANY_AVERAGE_RATINGS_REQUESTED,
  GET_COMPANY_AVERAGE_RATINGS_SUCCEEDED,
  GET_COMPANY_AVERAGE_RATINGS_FAILED,
  GET_COMPANY_REP_TRENDS_REQUESTED,
  GET_COMPANY_REP_TRENDS_SUCCEEDED,
  GET_COMPANY_REP_TRENDS_FAILED,
  GET_COMPANY_MAPS_REQUESTED,
  GET_COMPANY_MAPS_SUCCEEDED,
  GET_COMPANY_MAPS_FAILED,
  GET_COMPANY_PUBLIC_INFO_REQUESTED,
  GET_COMPANY_PUBLIC_INFO_SUCCEEDED,
  GET_COMPANY_PUBLIC_INFO_FAILED,
  ASSIGNED_ROLES_REQUEST,
  ASSIGNED_ROLES_SUCCESS,
  ASSIGNED_ROLES_FAILURE,
  GET_USER_COMPANIES_REQUESTED,
  GET_USER_COMPANIES_SUCCEEDED,
  GET_USER_COMPANIES_FAILED,
  ADD_USER_COMPANY_REQUESTED,
  ADD_USER_COMPANY_SUCCEEDED,
  ADD_USER_COMPANY_FAILED,
  UPDATE_USER_COMPANY_REQUESTED,
  UPDATE_USER_COMPANY_SUCCEEDED,
  UPDATE_USER_COMPANY_FAILED,
  UPDATE_USER_CURRENT_COMPANY_REQUESTED,
  UPDATE_USER_CURRENT_COMPANY_SUCCEEDED,
  UPDATE_USER_CURRENT_COMPANY_FAILED,
  DELETE_USER_COMPANY_REQUESTED,
  DELETE_USER_COMPANY_SUCCEEDED,
  DELETE_USER_COMPANY_FAILED,
  ADD_COMPANY_REQUESTED,
  ADD_COMPANY_SUCCEEDED,
  ADD_COMPANY_FAILED,
  GET_COMPANY_SUGGESTIONS_REQUESTED,
  GET_COMPANY_SUGGESTIONS_NEW_REP_REQUESTED,
  GET_COMPANY_SUGGESTIONS_SUCCEEDED,
  GET_COMPANY_SUGGESTIONS_FAILED,
  ADD_COMPANY_CUSTOME_ATTRIBUTE_REQUESTED,
  ADD_COMPANY_CUSTOME_ATTRIBUTE_SUCCEEDED,
  ADD_COMPANY_CUSTOME_ATTRIBUTE_FAILED,
  GET_COMPANY_CUSTOME_ATTRIBUTE_REQUEST,
  GET_COMPANY_CUSTOME_ATTRIBUTE_SUCCESS,
  GET_COMPANY_CUSTOME_ATTRIBUTE_FAILURE,
  UPDATE_COMPANY_CUSTOME_ATTRIBUTE_REQUESTED,
  UPDATE_COMPANY_CUSTOME_ATTRIBUTE_SUCCEEDED,
  UPDATE_COMPANY_CUSTOME_ATTRIBUTE_FAILED,
  DELETE_COMPANY_CUSTOME_ATTRIBUTE_REQUESTED,
  DELETE_COMPANY_CUSTOME_ATTRIBUTE_SUCCEEDED,
  DELETE_COMPANY_CUSTOME_ATTRIBUTE_FAILED,
  CLEAR_COMPANY_CUSTOME_ATTRIBUTE_MESSAGE,
  CLEAR_CUSTOME_ATTR_LIST,
  UPLOAD_CRM_REQUESTED,
  UPLOAD_CRM_SUCCEEDED,
  UPLOAD_CRM_FAILED,
  CLEAR_CRM_DATA,
  UPDATE_CRM_SPECIFIC_RECORD,
  ADD_CRM_REQUESTED,
  ADD_CRM_SUCCEEDED,
  ADD_CRM_FAILED,
  UPDATE_CRM_REQUESTED,
  UPDATE_CRM_SUCCEEDED,
  UPDATE_CRM_FAILED,
  RESET_CRM_DATA,
  VALIDATE_CRM_DATA_REQUESTED,
  VALIDATE_CRM_DATA_SUCCEEDED,
  VALIDATE_CRM_DATA_FAILED,
  DELETE_CRM_DATA_REQUESTED,
  DELETE_CRM_DATA_SUCCEEDED,
  DELETE_CRM_DATA_FAILED,
  GET_CRM_USERS_COMPANY_REQUESTED,
  GET_CRM_USERS_COMPANY_SUCCEEDED,
  GET_CRM_USERS_COMPANY_FAILED,
  GET_CRM_FILE_DATA_REQUESTED,
  GET_CRM_FILE_DATA_SUCCEEDED,
  GET_CRM_FILE_DATA_FAILED,
  COMPANY_WIDE_AVERAGE_REQUESTED,
  COMPANY_WIDE_AVERAGE_SUCCEEDED,
  COMPANY_WIDE_AVERAGE_FAILED,
  COMPANY_LOGO,
  RATINGPAGE_COMPANY_LOGO,
  ADD_CATOGERY_CUSTOME_ATTRIBUTE_REQUESTED,
  ADD_CATOGERY_CUSTOME_ATTRIBUTE_SUCCEEDED,
  ADD_CATOGERY_CUSTOME_ATTRIBUTE_FAILED,
  GET_COMPANY_USER_REQUESTED,
  GET_COMPANY_USER_SUCCEEDED,
  GET_COMPANY_USER_FAILED,

  // Dashboard2
  COMPANY_DASHBOARD_DETAILS_REQUESTED,
  COMPANY_DASHBOARD_DETAILS_SUCCEEDED,
  COMPANY_DASHBOARD_DETAILS_FAILED,
  COMPANY_DASHBOARD_PROGRAMS_REQUESTED,
  COMPANY_DASHBOARD_PROGRAMS_SUCCEEDED,
  COMPANY_DASHBOARD_PROGRAMS_FAILED,
  GET_CATOGERY_AVERAGE_RATINGS_REQUESTED,
  GET_CATOGERY_AVERAGE_RATINGS_SUCCEEDED,
  GET_CATOGERY_AVERAGE_RATINGS_FAILED,
  GET_CATOGERY_REP_TRENDS_REQUESTED,
  GET_CATOGERY_REP_TRENDS_SUCCEEDED,
  GET_CATOGERY_REP_TRENDS_FAILED,
  GET_CATOGERY_MAPS_REQUESTED,
  GET_CATOGERY_MAPS_SUCCEEDED,
  GET_CATOGERY_MAPS_FAILED,
  GET_CATOGERY_HOTSPOT_REPORT_REQUESTED,
  GET_CATOGERY_HOTSPOT_REPORT_SUCCEEDED,
  GET_CATOGERY_HOTSPOT_REPORT_FAILED,
  PROGRAM_DATA_REQUESTED,
  PROGRAM_DATA_SUCCEEDED,
  PROGRAM_DATA_FAILED,
  companyDashboardDetails,
  RATE_CATOGERY_REQUEST,
  RATE_CATOGERY_SUCCESS,
  RATE_CATOGERY_FAILURE,
  RATE_CATOGERY_RESET,
  GET_COMPANY_PROGRAM_LIST_REQUEST,
  GET_COMPANY_PROGRAM_LIST_SUCCESS,
  GET_COMPANY_TEAM_LIST_SUCCESS,
  GET_COMPANY_PROGRAM_LIST_FAILURE,
  VIEW_CATOGERY_DATA_REQUESTED,
  VIEW_CATOGERY_DATA_SUCCEEDED,
  VIEW_CATOGERY_DATA_FAILED,
  GLOBAL_PROGRAM_SELECTED,
  ADD_TAG_TO_FEEEDBACK,
  ADD_TAG_TO_FEEEDBACK_SUCCESS,
  ADD_TAG_TO_FEEEDBACK_ERROR,
  UPDATE_PREFERENCE_SUCCEEDED,
  UPDATE_PREFERENCE_ERROR,
  UPDATE_PREFERENCE_REQUESTED,
  RATE_TEAM_RESET,
  TEAM_DATA_REQUESTED,
  TEAM_DATA_SUCCEEDED,
  TEAM_DATA_FAILED,
  EMAIL_TEMPLATE_REQUEST,
  EMAIL_TEMPLATE_SUCCESS,
  EMAIL_TEMPLATE_FAILURE,
  UPDATE_EMAIL_TEMPLATE_REQUEST,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_FAILURE,
} from "../actions/companyActions";

import { ADD_COMPANY_SUCCESS } from "../actions/accountActions";
import { LOGOUT_SUCCEEDED } from "../actions/authActions";
import constants from "../constants";

import structure from "./DashboardStructure.json";
const initialState = {
  companies: [],
  previousCompanies: [],
  repList: [],
  info: {},
  errorMessage: {},
  updateMessage: "",
  updateError: false,
  isFetching: false,
  isFetchingPrevious: false,
  globalVerifiedCustomer: "",
  globalAnonymousRating:
    constants.COMPANY_GLOBAL_RATING_OPTIONS_MAP.ANONYMOUS_RATINGS_INCLUDED,
  globalProgram: "",

  dashboardCompanyInfo: {},
  fetchingdashboardCompanyInfo: false,
  dashboardCompanyInfoError: null,

  hotspotReport: [],
  fetchingHotspotReport: false,
  hotspotReportError: null,

  updateCompanyLoading: false,
  updateCompanySuccess: false,
  updateCompanyError: "",
  updateLogoLoading: false,
  updateLogoSuccess: false,
  updateLogoError: "",

  deleteLogoLoading: false,
  deleteLogoSuccess: false,
  deleteLogoError: "",

  minimumRatingLoading: false,
  minimumRatingSuccess: false,
  minimumRatingError: "",
  reportAbuseRequest: {},

  companyAverageRatings: [],
  fetchingCompanyAverageRatings: false,
  companyAverageRatingsError: null,

  companyRepTrends: [],
  fetchingCompanyRepTrends: false,
  companyRepTrendsError: null,

  companyMaps: [],
  fetchingCompanyMaps: false,
  companyMapsError: null,

  companyPublicInfo: null,
  companyPublicInfoFetching: false,
  companyPublicInfoError: null,

  assignedRoles: [],
  assignedRolesLoading: false,
  assignedRolesSuccess: false,
  assignedRolesError: "",

  userCompanies: [],
  userCompaniesFetching: false,
  userCompaniesError: null,

  addUserCompanyFetching: false,
  addUserCompanyError: null,

  updateUserCompanyFetching: false,
  updateUserCompanyError: null,

  addCompanyFetching: false,
  addCompanyError: null,

  companySuggestionsFetching: false,
  companySuggestionsFetchingNewRep: false,
  companySuggestions: [],
  companySuggestionsError: "",

  addCompanyCustomeAttributesFetching: false,
  addCompanyCustomeAttributesError: null,
  companyCustomeAttributes: [],

  getCompanyCustomeAttributesFetching: false,
  getCompanyCustomeAttributesError: null,
  getcompanyCustomeAttributes: [],

  updateCompanyCustomeAttributesFetching: false,
  updateCompanyCustomeAttributesError: null,
  updatecompanyCustomeAttributes: [],

  deleteCompanyCustomeAttributesFetching: false,
  deleteCompanyCustomeAttributesError: null,
  deletecompanyCustomeAttributes: [],

  crmDataRequested: false,
  crmDatasucceded: null,
  crmDataError: null,

  companyCRMDataRequested: false,
  companyCRMDatasucceded: null,
  companyCRMDataError: null,

  validateCRMDataRequested: false,
  validateCRMDatasucceded: null,
  validateCRMDataError: null,

  deleteCRMDataRequested: false,
  deleteCRMDatasucceded: null,
  deleteCRMDataError: null,

  getCRMuserCompanyRequested: false,
  getCRMuserCompanysucceded: null,
  getCRMuserCompanyError: null,

  getCRMfileDataRequested: false,
  getCRMfileDatasucceded: null,
  getCRMfileDataError: null,

  companyWideDataRequested: false,
  companyWideDatasucceded: null,
  companyWideDataError: null,
  companyLogo: null,

  recipients: [],
  emailAlertsConfig: {},
  getInfoFetching: false,
  getInfoError: null,

  companyUser: {},
  fetchingCompanyUser: false,
  companyUserError: {},
  // Dashboard2
  companyDashboardDetailsRequested: false,
  companyDashboardDetailsSucceded: false,
  companyDashboardDetailsFailed: false,

  ProgramAverageRatings: [],
  fetchingProgramAverageRatings: false,
  ProgramAverageRatingsError: null,

  ProgramRepTrends: [],
  fetchingProgramRepTrends: false,
  ProgramRepTrendsError: null,

  ProgramMaps: [],
  fetchingProgramMaps: false,
  ProgramMapsError: null,

  programData: null,
  fetchingProgramData: false,
  programDataError: null,

  ratingPageCompanyLogo: null,

  teamData: null,
  fetchingTeamData: false,
  teamDataError: null,

  fetchingTemplate: false,
  emailTemplate: [],
  emailTemplateError: null,

  isTemplateUpdating: false,
  templateUpdated: null,
  templateUpdateErr: null,

  loader: true,
};

function updateReportAbusepRequest(state, payload) {
  const { feedbackId, ...nextData } = payload;
  let request = { ...state.reportAbuseRequest[feedbackId], ...nextData };
  let newState = {};
  newState.reportAbuseRequest = { ...state.reportAbuseRequest };
  newState.reportAbuseRequest[feedbackId] = request;
  return { ...state, ...newState };
}

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_SUCCEEDED:
      return {
        ...initialState,
      };
    case GET_COMPANIES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        companies: [],
        errorMessage: false,
      });
    case GET_COMPANIES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        companies: action.payload,
        errorMessage: false,
      });
    case GET_COMPANIES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        companies: [],
        errorMessage: action.payload,
      });
    case GET_REPLIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        customRatingAttributes: undefined,
        errorMessage: false,
      });
    case GET_REPLIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        customRatingAttributes: action.payload,
        errorMessage: false,
      });
    case GET_REPLIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        customRatingAttributes: [],
        errorMessage: action.payload,
      });
    case GET_REPATTRLIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        customCompanyRatingAttributes: undefined,
        errorMessage: false,
      });
    case GET_REPATTRLIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        customCompanyRatingAttributes: action.payload,
        errorMessage: false,
      });
    case GET_ROLELIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        customCompanyRoleAttributes: action.payload,
        errorMessage: false,
      });
    case GET_REPATTRLIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        customCompanyRatingAttributes: [],
        errorMessage: action.payload,
      });
    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        companies: [...state.companies, action.payload.payload],
      };

    case GET_COMPANY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        info: {},
        errorMessage: false,
      });
    case GET_COMPANY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        info: action.payload,
        errorMessage: false,
      });
    case GET_COMPANY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        info: {},
        errorMessage: action.payload,
      });

    case GET_INFO_REQUEST:
      return {
        ...state,
        getInfoFetching: true,
      };
    case GET_INFO_SUCCESS:
      return {
        ...state,
        getInfoFetching: false,
        recipients: action.payload.recipients || [],
        emailAlertsConfig: {
          average: action.payload.averageRatingThreshold,
          individual: action.payload.individualRatingThreshold,
          checked: action?.payload?.selected,
        },
      };
    case GET_INFO_FAILURE:
      return {
        ...state,
        getInfoFetching: false,
        getInfoError: action.payload,
      };
    case GET_COMPANY_REPLIST_REQUEST:
      return {
        ...state,
        isFetchingRepList: true,
      };
    case GET_COMPANY_REPLIST_SUCCESS:
      return {
        ...state,
        isFetchingRepList: false,
        repList: action.payload,
        errorMessageRepList: false,
      };
    case GET_COMPANY_REPLIST_FAILURE:
      return {
        ...state,
        isFetchingRepList: false,
        errorMessageRepList: action.payload,
      };

    case UPDATE_COMPANY_INFO_REQUEST:
      return Object.assign({}, state, {
        updateCompanyLoading: true,
        updateCompanySuccess: false,
        updateCompanyError: "",
      });
    case UPDATE_COMPANY_INFO_SUCCESS:
      return Object.assign({}, state, {
        updateCompanyLoading: false,
        updateCompanySuccess: true,
        updateCompanyError: "",
        info: { ...state.info, ...action.payload },
      });
    case UPDATE_COMPANY_INFO_FAILURE:
      return Object.assign({}, state, {
        updateCompanyLoading: false,
        updateCompanySuccess: false,
        updateCompanyError: action.payload,
      });
    case UPDATE_COMPANY_INFO_RESET:
      return Object.assign({}, state, {
        updateCompanyLoading: false,
        updateCompanySuccess: false,
        updateCompanyError: "",
      });

    case GET_PREVIOUS_COMPANIES_REQUEST:
      return Object.assign({}, state, {
        isFetchingPrevious: true,
        previousCompanies: [],
        errorMessage: false,
      });
    case GET_PREVIOUS_COMPANIES_SUCCESS:
      return Object.assign({}, state, {
        isFetchingPrevious: false,
        previousCompanies: action.payload,
        errorMessage: false,
      });
    case GET_PREVIOUS_COMPANIES_FAILURE:
      return Object.assign({}, state, {
        isFetchingPrevious: false,
        previousCompanies: [],
        errorMessage: action.payload,
      });

    case GLOBAL_VERIFIED_CUSTOMER_SELECTED:
      return {
        ...state,
        globalVerifiedCustomer: action.payload,
      };
    case GLOBAL_PROGRAM_SELECTED:
      return {
        ...state,
        globalProgram: action.payload,
      };
    case GLOBAL_PROGRAM_CHANGE:
      return {
        ...state,
        UserSelectedProgram: action.payload,
      };
    case GLOBAL_TEAM_CHANGE:
      return {
        ...state,
        UserSelectedTeam: action.payload,
      };
    case GLOBAL_ANONYMOUS_RATING_SELECTED:
      return {
        ...state,
        globalAnonymousRating: action.payload,
      };

    case GET_DASHBOARD_COMPANY_INFO_REQUESTED:
      return {
        ...state,
        fetchingdashboardCompanyInfo: true,
      };
    case GET_DASHBOARD_COMPANY_INFO_SUCCEEDED:
      return {
        ...state,
        dashboardCompanyInfo: action.payload,
        dashboardCompanyInfoError: null,
        fetchingdashboardCompanyInfo: false,
      };
    case GET_DASHBOARD_COMPANY_INFO_FAILED:
      return {
        ...state,
        dashboardCompanyInfoError: action.payload,
        fetchingdashboardCompanyInfo: false,
      };

    case GET_HOTSPOT_REPORT_REQUESTED:
      return {
        ...state,
        fetchingHotspotReport: true,
      };
    case GET_HOTSPOT_REPORT_SUCCEEDED:
      return {
        ...state,
        hotspotReport: action.payload,
        hotspotReportError: null,
        fetchingHotspotReport: false,
      };
    case GET_HOTSPOT_REPORT_FAILED:
      return {
        ...state,
        hotspotReportError: action.payload,
        fetchingHotspotReport: false,
      };

    case REPORT_ABUSE_REQUESTED:
      return updateReportAbusepRequest(state, {
        ...action.payload,
        isFetchingDispute: true,
        disputeResponseReady: false,
      });
    case REPORT_ABUSE_SUCCEEDED:
      return updateReportAbusepRequest(state, {
        ...action.payload,
        isFetchingDispute: false,
        disputeResponseReady: true,
      });
    case REPORT_ABUSE_FAILED:
      return updateReportAbusepRequest(state, {
        ...action.payload,
        isFetchingDispute: false,
        disputeResponseReady: true,
        errorMessage: action.payload.error,
      });

    case UPDATE_COMPANY_LOGO_REQUEST:
      return Object.assign({}, state, {
        updateLogoLoading: true,
        updateLogoSuccess: false,
        updateLogoError: "",
      });
    case UPDATE_COMPANY_LOGO_SUCCESS:
      return Object.assign({}, state, {
        updateLogoLoading: false,
        updateLogoSuccess: true,
        updateLogoError: "",
        info: { ...state.info, companyLogo: action.payload.companyLogo },
      });
    case UPDATE_COMPANY_LOGO_FAILURE:
      return Object.assign({}, state, {
        updateLogoLoading: false,
        updateLogoSuccess: false,
        updateLogoError: action.payload,
      });
    case UPDATE_COMPANY_LOGO_RESET:
      return Object.assign({}, state, {
        updateLogoLoading: false,
        updateLogoSuccess: false,
        updateLogoError: "",
      });

    case DELETE_COMPANY_LOGO_REQUESTED:
      return Object.assign({}, state, {
        deleteLogoLoading: true,
        deleteLogoSuccess: false,
        deleteLogoError: "",
      });
    case DELETE_COMPANY_LOGO_SUCCEEDED:
      return Object.assign({}, state, {
        deleteLogoLoading: false,
        deleteLogoSuccess: true,
        deleteLogoError: "",
        info: { ...state.info, logo: action.payload.logo },
      });
    case DELETE_COMPANY_LOGO_FAILED:
      return Object.assign({}, state, {
        deleteLogoLoading: false,
        deleteLogoSuccess: false,
        deleteLogoError: action.payload,
      });
    case DELETE_COMPANY_LOGO_RESET:
      return Object.assign({}, state, {
        deleteLogoLoading: false,
        deleteLogoSuccess: false,
        deleteLogoError: "",
      });

    case MINIMUM_RATING_REQUEST:
      return Object.assign({}, state, {
        minimumRatingLoading: true,
        minimumRatingSuccess: false,
        minimumRatingError: "",
      });
    case MINIMUM_RATING_SUCCESS:
      return Object.assign({}, state, {
        minimumRatingLoading: false,
        minimumRatingSuccess: true,
        minimumRatingError: "",
        info: { ...state.info, ...action.payload },
      });
    case MINIMUM_RATING_FAILURE:
      return Object.assign({}, state, {
        minimumRatingLoading: false,
        minimumRatingSuccess: false,
        minimumRatingError: action.payload,
      });

    case GET_COMPANY_AVERAGE_RATINGS_REQUESTED:
      return {
        ...state,
        fetchingCompanyAverageRatings: true,
      };
    case GET_COMPANY_AVERAGE_RATINGS_SUCCEEDED:
      return {
        ...state,
        companyAverageRatings: action.payload,
        companyAverageRatingsError: null,
        fetchingCompanyAverageRatings: false,
      };
    case GET_COMPANY_AVERAGE_RATINGS_FAILED:
      return {
        ...state,
        companyAverageRatingsError: action.payload,
        fetchingCompanyAverageRatings: false,
      };

    case GET_COMPANY_REP_TRENDS_REQUESTED:
      return {
        ...state,
        fetchingCompanyRepTrends: true,
      };
    case GET_COMPANY_REP_TRENDS_SUCCEEDED:
      return {
        ...state,
        companyRepTrends: action.payload,
        companyRepTrendsError: null,
        fetchingCompanyRepTrends: false,
      };
    case GET_COMPANY_REP_TRENDS_FAILED:
      return {
        ...state,
        companyRepTrendsError: action.payload,
        fetchingCompanyRepTrends: false,
      };

    case GET_COMPANY_MAPS_REQUESTED:
      return {
        ...state,
        fetchingCompanyMaps: true,
      };
    case GET_COMPANY_MAPS_SUCCEEDED:
      return {
        ...state,
        companyMaps: action.payload,
        companyMapsError: null,
        fetchingCompanyMaps: false,
      };
    case GET_COMPANY_MAPS_FAILED:
      return {
        ...state,
        companyMapsError: action.payload,
        fetchingCompanyMaps: false,
      };

    case GET_COMPANY_PUBLIC_INFO_REQUESTED:
      return {
        ...state,
        companyPublicInfoFetching: true,
      };
    case GET_COMPANY_PUBLIC_INFO_SUCCEEDED:
      return {
        ...state,
        companyPublicInfoFetching: false,
        companyPublicInfoError: null,
        companyPublicInfo: action.payload,
      };
    case GET_COMPANY_PUBLIC_INFO_FAILED:
      return {
        ...state,
        companyPublicInfoFetching: false,
        companyPublicInfoError: action.payload,
      };

    case ASSIGNED_ROLES_REQUEST:
      return Object.assign({}, state, {
        assignedRoles: [],
        assignedRolesLoading: true,
        assignedRolesSuccess: false,
        assignedRolesError: "",
      });
    case ASSIGNED_ROLES_SUCCESS:
      return Object.assign({}, state, {
        assignedRoles: action.payload,
        assignedRolesLoading: false,
        assignedRolesSuccess: true,
        assignedRolesError: "",
      });
    case ASSIGNED_ROLES_FAILURE:
      return Object.assign({}, state, {
        assignedRoles: [],
        assignedRolesLoading: false,
        assignedRolesSuccess: false,
        assignedRolesError: action.payload,
      });

    case GET_USER_COMPANIES_REQUESTED: {
      return {
        ...state,
        userCompaniesFetching: true,
      };
    }
    case GET_USER_COMPANIES_SUCCEEDED: {
      return {
        ...state,
        userCompaniesFetching: false,
        userCompaniesError: null,
        userCompanies: action.payload,
      };
    }
    case GET_USER_COMPANIES_FAILED: {
      return {
        ...state,
        userCompaniesFetching: false,
        userCompaniesError: action.payload,
      };
    }

    case ADD_USER_COMPANY_REQUESTED: {
      return {
        ...state,
        addUserCompanyFetching: true,
      };
    }
    case ADD_USER_COMPANY_SUCCEEDED: {
      return {
        ...state,
        addUserCompanyFetching: false,
        addUserCompanyError: null,
        userCompanies: action.payload,
      };
    }
    case ADD_COMPANY_CUSTOME_ATTRIBUTE_REQUESTED: {
      return {
        ...state,
        addCompanyCustomeAttributesFetching: true,
      };
    }
    case ADD_COMPANY_CUSTOME_ATTRIBUTE_SUCCEEDED: {
      return {
        ...state,
        addCompanyCustomeAttributesFetching: false,
        addCompanyCustomeAttributesError: null,
        companyCustomeAttributes: action.payload,
      };
    }
    case ADD_COMPANY_CUSTOME_ATTRIBUTE_FAILED: {
      return {
        ...state,
        addCompanyCustomeAttributesFetching: false,
        addCompanyCustomeAttributesError: action.payload.payload,
      };
    }

    case GET_COMPANY_CUSTOME_ATTRIBUTE_REQUEST: {
      return {
        ...state,
        getCompanyCustomeAttributesFetching: true,
        updateCompanyCustomeAttributesError: null,
      };
    }
    case GET_COMPANY_CUSTOME_ATTRIBUTE_SUCCESS: {
      return {
        ...state,
        getCompanyCustomeAttributesFetching: false,
        getCompanyCustomeAttributesError: null,
        getcompanyCustomeAttributes: action.payload,
        updateCompanyCustomeAttributesError: null,
      };
    }
    case GET_COMPANY_CUSTOME_ATTRIBUTE_FAILURE: {
      return {
        ...state,
        getCompanyCustomeAttributesFetching: false,
        getCompanyCustomeAttributesError: action.payload.payload,
        updateCompanyCustomeAttributesError: null,
      };
    }

    case UPDATE_COMPANY_CUSTOME_ATTRIBUTE_REQUESTED: {
      return {
        ...state,
        updateCompanyCustomeAttributesFetching: true,
        updateCompanyCustomeAttributesError: null,
      };
    }
    case UPDATE_COMPANY_CUSTOME_ATTRIBUTE_SUCCEEDED: {
      return {
        ...state,
        updateCompanyCustomeAttributesFetching: false,
        updateCompanyCustomeAttributesError: null,
        updatecompanyCustomeAttributes: action.payload,
      };
    }
    case UPDATE_COMPANY_CUSTOME_ATTRIBUTE_FAILED: {
      return {
        ...state,
        updateCompanyCustomeAttributesFetching: false,
        updateCompanyCustomeAttributesError: action.payload.data.payload,
      };
    }

    case DELETE_COMPANY_CUSTOME_ATTRIBUTE_REQUESTED: {
      return {
        ...state,
        deleteCompanyCustomeAttributesFetching: true,
        updateCompanyCustomeAttributesError: null,
      };
    }
    case DELETE_COMPANY_CUSTOME_ATTRIBUTE_SUCCEEDED: {
      return {
        ...state,
        deleteCompanyCustomeAttributesFetching: false,
        updateCompanyCustomeAttributesError: null,
        deletecompanyCustomeAttributes: action.payload,
      };
    }
    case DELETE_COMPANY_CUSTOME_ATTRIBUTE_FAILED: {
      return {
        ...state,
        deleteCompanyCustomeAttributesFetching: false,
        updateCompanyCustomeAttributesError: action.payload.data.payload,
      };
    }
    case ADD_USER_COMPANY_FAILED: {
      return {
        ...state,
        addUserCompanyFetching: false,
        addUserCompanyError: action.payload,
      };
    }

    case UPDATE_USER_COMPANY_REQUESTED: {
      return {
        ...state,
        updateUserCompanyFetching: true,
      };
    }
    case UPDATE_USER_COMPANY_SUCCEEDED: {
      const updatedCompany = action.payload;

      return {
        ...state,
        updateUserCompanyFetching: false,
        updateUserCompanyError: null,
        userCompanies: state.userCompanies.map((company) => {
          if (company.companyId === updatedCompany.companyId) {
            return updatedCompany;
          }
          return company;
        }),
      };
    }
    case UPDATE_USER_COMPANY_FAILED: {
      return {
        ...state,
        updateUserCompanyFetching: false,
        updateUserCompanyError: action.payload,
      };
    }

    case UPDATE_USER_CURRENT_COMPANY_REQUESTED: {
      return {
        ...state,
        updateUserCurrentCompanyFetching: true,
      };
    }
    case UPDATE_USER_CURRENT_COMPANY_SUCCEEDED: {
      const updatedCompany = action.payload;

      return {
        ...state,
        updateUserCurrentCompanyFetching: false,
        updateUserCurrentCompanyError: null,
        userCompanies: state.userCompanies.map((company) => {
          if (company.companyId === updatedCompany.companyId) {
            return updatedCompany;
          }
          return company;
        }),
      };
    }
    case UPDATE_USER_CURRENT_COMPANY_FAILED: {
      return {
        ...state,
        updateUserCurrentCompanyFetching: false,
        updateUserCurrentCompanyError: action.payload,
      };
    }

    case DELETE_USER_COMPANY_REQUESTED: {
      return {
        ...state,
        deleteUserCompanyFetching: true,
      };
    }
    case DELETE_USER_COMPANY_SUCCEEDED: {
      // remove the deleted company from the user companies list
      return {
        ...state,
        deleteUserCompanyFetching: false,
        deleteUserCompanyError: null,
        userCompanies: state.userCompanies.filter(
          (company) => company.companyId !== action.payload
        ),
      };
    }
    case DELETE_USER_COMPANY_FAILED: {
      return {
        ...state,
        deleteUserCompanyFetching: false,
        deleteUserCompanyError: action.payload,
      };
    }

    case ADD_COMPANY_REQUESTED: {
      return {
        ...state,
        addCompanyFetching: true,
      };
    }
    case ADD_COMPANY_SUCCEEDED: {
      return {
        ...state,
        addCompanyFetching: false,
        addCompanyError: null,
        companies: [...state.companies, action.payload],
      };
    }
    case ADD_COMPANY_FAILED: {
      return {
        ...state,
        addCompanyFetching: false,
        addCompanyError: action.payload,
      };
    }

    case GET_COMPANY_SUGGESTIONS_REQUESTED:
      return {
        ...state,
        companySuggestionsFetching: true,
      };
    case GET_COMPANY_SUGGESTIONS_NEW_REP_REQUESTED:
      return {
        ...state,
        companySuggestionsFetchingNewRep: true,
      };
    case GET_COMPANY_SUGGESTIONS_SUCCEEDED:
      return {
        ...state,
        companySuggestionsFetching: false,
        companySuggestionsFetchingNewRep: false,
        companySuggestions: action.payload,
        companySuggestionsError: "",
      };
    case GET_COMPANY_SUGGESTIONS_FAILED:
      return {
        ...state,
        companySuggestionsFetching: false,
        companySuggestionsFetchingNewRep: false,
        companySuggestionsError: action.payload,
      };

    case CLEAR_COMPANY_CUSTOME_ATTRIBUTE_MESSAGE:
      return {
        ...state,
        updateCompanyCustomeAttributesError: "",
      };
    case UPLOAD_CRM_REQUESTED:
      return {
        ...state,
        crmDataRequested: true,
        crmDatasucceded: null,
        crmDataError: null,
      };
    case UPLOAD_CRM_SUCCEEDED:
      return {
        ...state,
        crmDataRequested: false,
        crmDatasucceded: action.payload.payload,
        crmDataError: null,
      };
    case UPLOAD_CRM_FAILED:
      return {
        ...state,
        crmDataRequested: false,
        crmDatasucceded: null,
        crmDataError: action.payload,
      };
    case CLEAR_CRM_DATA:
      return {
        ...state,
        crmDataRequested: false,
        crmDatasucceded: null,
        crmDataError: null,
      };
    case UPDATE_CRM_SPECIFIC_RECORD:
      if (action.payload.step === 3) {
        return {
          ...state,
          crmDatasucceded: {
            ...state.crmDatasucceded,
            newRepList: state.crmDatasucceded.newRepList.map((content, i) =>
              content.id === action.payload.id
                ? { ...content, active: action.payload.state }
                : content
            ),
          },
        };
      }
      if (action.payload.step === 5) {
        return {
          ...state,
          crmDatasucceded: {
            ...state.crmDatasucceded,
            missingRepList: state.crmDatasucceded.missingRepList.map(
              (content, i) =>
                content.id === action.payload.id
                  ? { ...content, active: action.payload.state }
                  : content
            ),
          },
        };
      }
      if (action.payload.step === 6) {
        return {
          ...state,
          crmDatasucceded: {
            ...state.crmDatasucceded,
            missingUserList: state.crmDatasucceded.missingUserList.map(
              (content, i) =>
                content.id === action.payload.id
                  ? { ...content, active: action.payload.state }
                  : content
            ),
          },
        };
      } else {
        return {
          ...state,
          crmDatasucceded: {
            ...state.crmDatasucceded,
            newUserList: state.crmDatasucceded.newUserList.map((content, i) =>
              content.id === action.payload.id
                ? { ...content, active: action.payload.state }
                : content
            ),
          },
        };
      }

    case ADD_CRM_REQUESTED:
      return {
        ...state,
        companyCRMDataRequested: true,
        companyCRMDatasucceded: null,
        companyCRMDataError: null,
      };
    case ADD_CRM_SUCCEEDED:
      return {
        ...state,
        companyCRMDataRequested: false,
        companyCRMDatasucceded: action.payload,
        companyCRMDataError: null,
      };
    case ADD_CRM_FAILED:
      return {
        ...state,
        companyCRMDataRequested: false,
        companyCRMDatasucceded: null,
        companyCRMDataError: action.payload,
      };
    case UPDATE_CRM_REQUESTED:
      return {
        ...state,
        companyCRMDataRequested: true,
        companyCRMDatasucceded: null,
        companyCRMDataError: null,
      };
    case UPDATE_CRM_SUCCEEDED:
      console.log("UPDATE_CRM_SUCCEEDED", action);
      return {
        ...state,
        companyCRMDataRequested: false,
        companyCRMDatasucceded: action.payload,
        companyCRMDataError: null,
      };
    case UPDATE_CRM_FAILED:
      return {
        ...state,
        companyCRMDataRequested: false,
        companyCRMDatasucceded: null,
        companyCRMDataError: action.payload,
      };

    case RESET_CRM_DATA:
      return {
        ...state,
        crmDataRequested: false,
        crmDatasucceded: null,
        crmDataError: null,
        companyCRMDataRequested: false,
        companyCRMDatasucceded: null,
        companyCRMDataError: null,
        validateCRMDataRequested: false,
        validateCRMDatasucceded: null,
        validateCRMDataError: null,
      };
    case VALIDATE_CRM_DATA_REQUESTED:
      return {
        ...state,
        validateCRMDataRequested: true,
        validateCRMDatasucceded: null,
        validateCRMDataError: null,
      };
    case VALIDATE_CRM_DATA_SUCCEEDED:
      return {
        ...state,
        validateCRMDataRequested: false,
        validateCRMDatasucceded: action.payload.payload,
        validateCRMDataError: null,
      };
    case VALIDATE_CRM_DATA_FAILED:
      return {
        ...state,
        validateCRMDataRequested: false,
        validateCRMDatasucceded: null,
        validateCRMDataError: action.payload,
      };
    case DELETE_CRM_DATA_REQUESTED:
      return {
        ...state,
        deleteCRMDataRequested: true,
        deleteCRMDatasucceded: null,
        deleteCRMDataError: null,
      };
    case DELETE_CRM_DATA_SUCCEEDED:
      return {
        ...state,
        deleteCRMDataRequested: false,
        deleteCRMDatasucceded: action.payload.payload,
        deleteCRMDataError: null,
      };
    case DELETE_CRM_DATA_FAILED:
      return {
        ...state,
        deleteCRMDataRequested: false,
        deleteCRMDatasucceded: null,
        deleteCRMDataError: action.payload,
      };
    case GET_CRM_USERS_COMPANY_REQUESTED:
      return {
        ...state,
        getCRMuserCompanyRequested: true,
        getCRMuserCompanysucceded: null,
        getCRMuserCompanyError: null,
      };
    case GET_CRM_USERS_COMPANY_SUCCEEDED:
      return {
        ...state,
        getCRMuserCompanyRequested: false,
        getCRMuserCompanysucceded: action.payload.payload,
        getCRMuserCompanyError: null,
      };
    case GET_CRM_USERS_COMPANY_FAILED:
      return {
        ...state,
        getCRMuserCompanyRequested: false,
        getCRMuserCompanysucceded: null,
        getCRMuserCompanyError: action.payload,
      };
    case GET_CRM_FILE_DATA_REQUESTED:
      return {
        ...state,
        getCRMfileDataRequested: true,
        getCRMfileDatasucceded: null,
        getCRMfileDataError: null,
      };
    case GET_CRM_FILE_DATA_SUCCEEDED:
      return {
        ...state,
        getCRMfileDataRequested: false,
        getCRMfileDatasucceded: action.payload.payload,
        getCRMfileDataError: null,
      };
    case GET_CRM_FILE_DATA_FAILED:
      return {
        ...state,
        getCRMfileDataRequested: false,
        getCRMfileDatasucceded: null,
        getCRMfileDataError: action.payload,
      };

    case COMPANY_WIDE_AVERAGE_REQUESTED:
      return {
        ...state,
        companyWideDataRequested: true,
        companyWideDatasucceded: null,
        companyWideDataError: null,
      };
    case COMPANY_WIDE_AVERAGE_SUCCEEDED:
      return {
        ...state,
        companyWideDataRequested: false,
        companyWideDatasucceded: action.payload,
        companyWideDataError: null,
      };
    case COMPANY_WIDE_AVERAGE_FAILED:
      return {
        ...state,
        companyWideDataRequested: false,
        companyWideDatasucceded: null,
        companyWideDataError: action.payload,
      };
    case COMPANY_LOGO:
      const blob = new Blob([action.payload]);
      const url = URL.createObjectURL(blob);
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        var base64data = reader.result;
        window.localStorage.setItem("CompanyLogo", base64data);
      };
      return {
        ...state,
        companyLogo: url,
      };
    case RATINGPAGE_COMPANY_LOGO:
      const blob1 = new Blob([action.payload]);
      const url1 = URL.createObjectURL(blob1);
      return {
        ...state,
        ratingPageCompanyLogo: url1,
      };
    case CLEAR_CUSTOME_ATTR_LIST:
      return {
        ...state,
        getcompanyCustomeAttributes: [],
      };
    case GET_COMPANY_USER_REQUESTED:
      return {
        ...state,
        companyUser: {},
        fetchingCompanyUser: true,
        companyUserError: {},
      };
    case GET_COMPANY_USER_SUCCEEDED:
      return {
        ...state,
        companyUser: action.payload,
        fetchingCompanyUser: false,
        companyUserError: {},
      };
    case GET_COMPANY_USER_FAILED:
      return {
        ...state,
        companyUser: {},
        fetchingCompanyUser: false,
        companyUserError: action.payload,
      };
    //Dashboard2

    case COMPANY_DASHBOARD_DETAILS_REQUESTED:
      return {
        ...state,
        companyDashboardDetailsRequested: true,
        // companyDashboardDetailsRequested: null,
        // companyDashboardDetailsRequested: null,
        dashboardCompanyInfo: null,
      };
    case COMPANY_DASHBOARD_DETAILS_SUCCEEDED:
      // prepareStructure(action.payload);
      // ";
      // const tempVal = prepareStructure(action.payload);
      // const tempAvgRep = prepareAvgStructure(action.payload)
      return {
        ...state,
        companyDashboardDetailsRequested: false,
        companyDashboardDetailsSucceded: true,
        dashboardCompanyInfo: prepareStructure(action.payload),
        companyAverageRatings: action.payload,
        companyRepTrends: prepareTrendsStructure(action.payload),
        companyDashboardDetailsRequested: null,
        companyMaps: action.payload,
      };
    case COMPANY_DASHBOARD_DETAILS_FAILED:
      return {
        ...state,
        companyDashboardDetailsRequested: false,
        companyDashboardDetailsRequested: null,
        dashboardCompanyInfo: null,
      };
    // Dashboard2
    case COMPANY_DASHBOARD_PROGRAMS_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        dashboardCompanyProgramFilters: null,
        errorMessage: false,
      });
    case COMPANY_DASHBOARD_PROGRAMS_SUCCEEDED:
      let data = action.payload?.sort((a, b) =>
        a.programName.localeCompare(b.programName)
      );
      return Object.assign({}, state, {
        isFetching: false,
        dashboardCompanyProgramFilters: data,
        errorMessage: false,
      });
    case COMPANY_DASHBOARD_PROGRAMS_FAILED:
      return Object.assign({}, state, {
        isFetching: false,
        dashboardCompanyProgramFilters: [],
        errorMessage: action.payload,
      });
    //for program and teams
    case GET_CATOGERY_AVERAGE_RATINGS_REQUESTED:
      return {
        ...state,
        fetchingProgramAverageRatings: true,
      };
    case GET_CATOGERY_AVERAGE_RATINGS_SUCCEEDED:
      return {
        ...state,
        ProgramAverageRatings: action.payload,
        ProgramAverageRatingsError: null,
        fetchingProgramAverageRatings: false,
      };
    case GET_CATOGERY_AVERAGE_RATINGS_FAILED:
      return {
        ...state,
        ProgramAverageRatingsError: action.payload,
        fetchingProgramAverageRatings: false,
      };
    //for program and teams
    case GET_CATOGERY_REP_TRENDS_REQUESTED:
      return {
        ...state,
        fetchingProgramRepTrends: true,
      };
    case GET_CATOGERY_REP_TRENDS_SUCCEEDED:
      return {
        ...state,
        ProgramRepTrends: action.payload,
        ProgramRepTrendsError: null,
        fetchingProgramRepTrends: false,
      };
    case GET_CATOGERY_REP_TRENDS_FAILED:
      return {
        ...state,
        ProgramRepTrendsError: action.payload,
        fetchingProgramRepTrends: false,
      };
    //for program and teams
    case GET_CATOGERY_MAPS_REQUESTED:
      return {
        ...state,
        fetchingProgramMaps: true,
      };
    case GET_CATOGERY_MAPS_SUCCEEDED:
      return {
        ...state,
        ProgramMaps: action.payload,
        ProgramMapsError: null,
        fetchingProgramMaps: false,
      };
    case GET_CATOGERY_MAPS_FAILED:
      return {
        ...state,
        ProgramMapsError: action.payload,
        fetchingProgramMaps: false,
      };
    //for program and teams
    case GET_CATOGERY_HOTSPOT_REPORT_REQUESTED:
      return {
        ...state,
        fetchingProgramHotspotReport: true,
      };
    case GET_CATOGERY_HOTSPOT_REPORT_SUCCEEDED:
      return {
        ...state,
        programHotspotReport: action.payload,
        programHotspotReportError: null,
        fetchingProgramHotspotReport: false,
      };
    case GET_CATOGERY_HOTSPOT_REPORT_FAILED:
      return {
        ...state,
        programHotspotReportError: action.payload,
        fetchingProgramHotspotReport: false,
      };
    case PROGRAM_DATA_REQUESTED:
      return {
        ...state,
        fetchingProgramData: true,
      };
    case PROGRAM_DATA_SUCCEEDED:
      return {
        ...state,
        programData: action.payload,
        programDataError: null,
        fetchingProgramData: false,
      };
    case PROGRAM_DATA_FAILED:
      return {
        ...state,
        programDataError: action.payload,
        fetchingProgramData: false,
      };
    //FOR REPS AND PROGRAMS
    case RATE_CATOGERY_REQUEST:
      return Object.assign({}, state, {
        isFetchingRateProgram: true,
        rateResponse: {},
        rateResponseReady: false,
        errorMessage: false,
      });
    case RATE_CATOGERY_SUCCESS:
      return Object.assign({}, state, {
        isFetchingRateProgram: false,
        rateResponse: action.payload,
        rateResponseReady: true,
        errorMessage: false,
      });
    case RATE_CATOGERY_FAILURE:
      return Object.assign({}, state, {
        isFetchingRateProgram: false,
        rateResponse: {},
        errorMessage: action.payload,
        rateResponseReady: true,
      });
    case RATE_CATOGERY_RESET:
      return Object.assign({}, state, {
        isFetchingRateProgram: false,
        rateResponse: {},
        rateResponseReady: false,
        errorMessage: false,
      });
    //for program and teams
    case GET_COMPANY_PROGRAM_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetchingListingProgram: true,
        companyProgramList: undefined,
        errorMessage: false,
      });
    case GET_COMPANY_PROGRAM_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetchingListingProgram: false,
        companyProgramList: action.payload.programViewList,
        errorMessage: false,
      });
    case GET_COMPANY_TEAM_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetchingListingProgram: false,
        companyTeamList: action.payload.teamViewList,
        errorMessage: false,
      });
    case GET_COMPANY_PROGRAM_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetchingListingProgram: false,
        companyProgramList: [],
        errorMessage: action.payload,
      });
    //for program and teams
    case VIEW_CATOGERY_DATA_REQUESTED:
      return {
        ...state,
        fetchingCatogeryViewData: true,
      };
    case VIEW_CATOGERY_DATA_SUCCEEDED:
      return {
        ...state,
        categoryViewData: action.payload,
        catogeryViewDataError: null,
        fetchingCatogeryViewData: false,
      };
    case VIEW_CATOGERY_DATA_FAILED:
      return {
        ...state,
        catogeryViewDataError: action.payload,
        fetchingCatogeryViewData: false,
      };
    case ADD_TAG_TO_FEEEDBACK:
      return {
        ...state,
        isTagAttached: false,
      };
    case ADD_TAG_TO_FEEEDBACK_SUCCESS:
      return {
        ...state,
        isTagAttached: true,
      };
    case ADD_TAG_TO_FEEEDBACK_ERROR:
      return {
        ...state,
        isTagAttached: false,
      };
    case UPDATE_PREFERENCE_REQUESTED:
      return {
        ...state,
        isPreferenceUpdated: false,
      };
    case UPDATE_PREFERENCE_SUCCEEDED:
      return {
        ...state,
        isPreferenceUpdated: true,
      };
    case UPDATE_PREFERENCE_ERROR:
      return {
        ...state,
        isPreferenceUpdated: false,
      };
    case TEAM_DATA_REQUESTED:
      return {
        ...state,
        fetchingTeamData: true,
      };
    case TEAM_DATA_SUCCEEDED:
      return {
        ...state,
        teamData: action.payload,
        teamDataError: null,
        fetchingTeamData: false,
      };
    case TEAM_DATA_FAILED:
      return {
        ...state,
        teamDataError: action.payload,
        fetchingTeamData: false,
      };
    case RATE_TEAM_RESET:
      return Object.assign({}, state, {
        isFetchingRateTeam: false,
        rateResponse: {},
        rateResponseReady: false,
        errorMessage: false,
      });
    case ADD_CATOGERY_CUSTOME_ATTRIBUTE_REQUESTED: {
      return {
        ...state,
        addCompanyCustomeAttributesFetching: true,
      };
    }
    case ADD_CATOGERY_CUSTOME_ATTRIBUTE_SUCCEEDED: {
      return {
        ...state,
        addCompanyCustomeAttributesFetching: false,
        addCompanyCustomeAttributesError: null,
        companyCustomeAttributes: action.payload,
      };
    }
    case ADD_CATOGERY_CUSTOME_ATTRIBUTE_FAILED: {
      return {
        ...state,
        addCompanyCustomeAttributesFetching: false,
        addCompanyCustomeAttributesError: action.payload.payload,
      };
    }
    case EMAIL_TEMPLATE_REQUEST: {
      return {
        ...state,
        fetchingTemplate: true,
      };
    }
    case EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        fetchingTemplate: false,
        emailTemplate: action.payload,
        emailTemplateError: null,
      };
    }
    case EMAIL_TEMPLATE_FAILURE: {
      return {
        ...state,
        fetchingTemplate: false,
        emailTemplateError: action.payload,
      };
    }
    case UPDATE_EMAIL_TEMPLATE_REQUEST: {
      return {
        ...state,
        isTemplateUpdating: true,
      };
    }
    case UPDATE_EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        isTemplateUpdating: false,
        templateUpdated: action.payload,
        templateUpdateErr: null,
      };
    }
    case UPDATE_EMAIL_TEMPLATE_FAILURE: {
      return {
        ...state,
        isTemplateUpdating: false,
        templateUpdateErr: action.payload,
      };
    }

    default:
      return state;
  }
};

function prepareStructure(res) {
  return {
    average: res.overallAverage,
    // "average": 2.3668602,
    repScore: res.repscore,
    // "repScore": 223
    company: {
      id: res.companyId,
      name: res.companyName,
      // "linkedinUrl": "viithiisys.com",
      industry: "",
      logo: null,
      physicalAddress: {
        country: "",
        city: "",
        state: "",
        zipCode: "",
        territory: null,
      },
      searchName: null,
      createdAt: null,
      updatedAt: null,
      enrolled: true,
      minimumRatings: 1,
      // "privateCompany": true
      countriesMaps: res.countriesMaps,
      maxTotalFeedbacks: res.maxTotalFeedbacks,
    },
    filter: [
      // "New York",
      // "aaa",
      // "Unassigned",
      // "central",
      // "east",
      // "Japan",
      // "East",
      // "Southwest",
      // "southwest",
      // "US",
      // "Rep",
      // "Europe"
    ],
  };
}

function prepareTrendsStructure(res) {
  let tempCustomRating = res.customRatingsMap;
  let tempRating = res.ratingsMap;
  let finalArr = {};
  let finalCustomArr = {};
  if (tempRating) {
    Object.keys(tempRating).forEach(function (ele) {
      Object.assign(finalArr, { [ele]: tempRating[ele]["graphItemList"] });
    });
  }
  if (tempCustomRating) {
    Object.keys(tempCustomRating).forEach(function (ele) {
      Object.assign(finalCustomArr, {
        [ele]: tempCustomRating[ele]["graphItemList"],
      });
    });
  }
  const obj = { ratingsMap: finalArr, customRatingsMap: finalCustomArr };
  return obj;
}
export default companyReducer;

// id: 'd798fa8b-c3a6-4d3f-b33f-ab94d764ac1f',
//   companyId: 'b6903090-438f-11ec-be3a-dfdc4cf7e9bb',
//     companyName: 'Tray.co',
//       repscore: 223,
//         overallAverage: 2.3668602,
//           calculatedAT: '2022-04-21T19:00:47.483+0000',
//             averagePerCategory: {
//   AVAILABILITY: 2.8994083,
//     COMMITTED_TO_MY_SUCCESS: 0.3964497,
//       COMMUNICATION_SKILLS: 3.3017752,
//         DOMAIN_EXPERTISE: 0.3846154,
//           FOLLOW_THROUGH: 3.3491125,
//             ORGANIZATIONAL_SKILLS: 2.9467456,
//               OVERALL_RATING: 2.3668635,
//                 POSITIVE_EXPERIENCE: 91.12426,
//                   PREPARATION: 0.3846154,
//                     PRODUCT_KNOWLEDGE: 3.112426,
//                       PROFESSIONALISM: 3.3491125,
//                         PUNCTUALITY: 2.9230769,
//                           TRUSTWORTHINESS: 2.9881656
// },
// averagePerCustomCategory: {
//   'Competitive Analysys': 2.4733727,
//     cs1: 0.2366864,
//       gg: 0.0591716
// },
// comparedAveragePerCategory: {
//   AVAILABILITY: -0.27916312,
//     COMMITTED_TO_MY_SUCCESS: -0.05593124,
//       COMMUNICATION_SKILLS: -0.26965332,
//         DOMAIN_EXPERTISE: -0.091575086,
//           FOLLOW_THROUGH: -0.22231603,
//             ORGANIZATIONAL_SKILLS: -0.1246829,
//               OVERALL_RATING: -0.17209697,
//                 POSITIVE_EXPERIENCE: 0.6480713,
//                   PREPARATION: -0.07967031,
//                     PRODUCT_KNOWLEDGE: -0.18519306,
//                       PROFESSIONALISM: -0.2461257,
//                         PUNCTUALITY: -0.14835167,
//                           TRUSTWORTHINESS: -0.19040585
// },
// comparedAveragePerCustomCategory: {
//   'Competitive Analysys': 2.3809524,
//     cs1: 0.1904762,
//       gg: 0.10714286
// },
// ratingsMap: {
//   AVAILABILITY: {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 2.62
//       },
//       {
//         date: '3/2022',
//         value: 3.3
//       },
//       {
//         date: '2/2022',
//         value: 3.67
//       },
//       {
//         date: '1/2022',
//         value: 2.5
//       }
//     ]
//   },
//   COMMITTED_TO_MY_SUCCESS: {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 0.34
//       },
//       {
//         date: '3/2022',
//         value: 0.37
//       },
//       {
//         date: '2/2022',
//         value: 0
//       },
//       {
//         date: '1/2022',
//         value: 0.93
//       }
//     ]
//   },
//   COMMUNICATION_SKILLS: {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 3.04
//       },
//       {
//         date: '3/2022',
//         value: 3.55
//       },
//       {
//         date: '2/2022',
//         value: 3.67
//       },
//       {
//         date: '1/2022',
//         value: 3.64
//       }
//     ]
//   },
//   DOMAIN_EXPERTISE: {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 0.29
//       },
//       {
//         date: '3/2022',
//         value: 0.39
//       },
//       {
//         date: '2/2022',
//         value: 0
//       },
//       {
//         date: '1/2022',
//         value: 1
//       }
//     ]
//   },
//   FOLLOW_THROUGH: {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 3.13
//       },
//       {
//         date: '3/2022',
//         value: 3.61
//       },
//       {
//         date: '2/2022',
//         value: 2.67
//       },
//       {
//         date: '1/2022',
//         value: 3.57
//       }
//     ]
//   },
//   ORGANIZATIONAL_SKILLS: {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 2.82
//       },
//       {
//         date: '3/2022',
//         value: 3.13
//       },
//       {
//         date: '2/2022',
//         value: 3
//       },
//       {
//         date: '1/2022',
//         value: 2.79
//       }
//     ]
//   },
//   OVERALL_RATING: {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 2.2
//       },
//       {
//         date: '3/2022',
//         value: 2.55
//       },
//       {
//         date: '2/2022',
//         value: 2.45
//       },
//       {
//         date: '1/2022',
//         value: 2.51
//       }
//     ]
//   },
//   POSITIVE_EXPERIENCE: {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 91.76
//       },
//       {
//         date: '3/2022',
//         value: 92.54
//       },
//       {
//         date: '2/2022',
//         value: 66.67
//       },
//       {
//         date: '1/2022',
//         value: 85.71
//       }
//     ]
//   },
//   PREPARATION: {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 0.31
//       },
//       {
//         date: '3/2022',
//         value: 0.39
//       },
//       {
//         date: '2/2022',
//         value: 0
//       },
//       {
//         date: '1/2022',
//         value: 0.93
//       }
//     ]
//   },
//   PRODUCT_KNOWLEDGE: {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 2.93
//       },
//       {
//         date: '3/2022',
//         value: 3.27
//       },
//       {
//         date: '2/2022',
//         value: 3.67
//       },
//       {
//         date: '1/2022',
//         value: 3.36
//       }
//     ]
//   },
//   PROFESSIONALISM: {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 3.11
//       },
//       {
//         date: '3/2022',
//         value: 3.61
//       },
//       {
//         date: '2/2022',
//         value: 3
//       },
//       {
//         date: '1/2022',
//         value: 3.64
//       }
//     ]
//   },
//   PUNCTUALITY: {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 2.78
//       },
//       {
//         date: '3/2022',
//         value: 3.16
//       },
//       {
//         date: '2/2022',
//         value: 3.67
//       },
//       {
//         date: '1/2022',
//         value: 2.5
//       }
//     ]
//   },
//   REPSCORE: {
//     graphItemList: [
//       {
//         date: '1/2022',
//         value: 219.79
//       },
//       {
//         date: '2/2022',
//         value: 218.19
//       },
//       {
//         date: '3/2022',
//         value: 231.05
//       },
//       {
//         date: '4/2022',
//         value: 223.09
//       }
//     ]
//   },
//   TRUSTWORTHINESS: {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 2.8
//       },
//       {
//         date: '3/2022',
//         value: 3.25
//       },
//       {
//         date: '2/2022',
//         value: 3.67
//       },
//       {
//         date: '1/2022',
//         value: 2.71
//       }
//     ]
//   }
// },
// customRatingsMap: {
//   'Competitive Analysys': {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 2.56
//       },
//       {
//         date: '3/2022',
//         value: 2.81
//       },
//       {
//         date: '2/2022',
//         value: 0
//       },
//       {
//         date: '1/2022',
//         value: 0.86
//       }
//     ]
//   },
//   cs1: {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 0.28
//       },
//       {
//         date: '3/2022',
//         value: 0.24
//       },
//       {
//         date: '2/2022',
//         value: 0
//       },
//       {
//         date: '1/2022',
//         value: 0
//       }
//     ]
//   },
//   dd: {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 0
//       },
//       {
//         date: '3/2022',
//         value: 0
//       },
//       {
//         date: '2/2022',
//         value: 0
//       },
//       {
//         date: '1/2022',
//         value: 0
//       }
//     ]
//   },
//   gg: {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 0.01
//       },
//       {
//         date: '3/2022',
//         value: 0
//       },
//       {
//         date: '2/2022',
//         value: 3
//       },
//       {
//         date: '1/2022',
//         value: 0
//       }
//     ]
//   },
//   sfdsfd: {
//     graphItemList: [
//       {
//         date: '4/2022',
//         value: 0
//       },
//       {
//         date: '3/2022',
//         value: 0
//       },
//       {
//         date: '2/2022',
//         value: 0
//       },
//       {
//         date: '1/2022',
//         value: 0
//       }
//     ]
//   }
// },
// maxTotalFeedbacks: 107,
//   countriesMaps: {
//   India: {
//     listStateMap: {
//       Telangana: {
//         numberOfRatings: 1,
//           valueOfRatings: 248
//       },
//       'Andhra Pradesh': {
//         numberOfRatings: 5,
//           valueOfRatings: 246.4
//       }
//     },
//     totalRatingsCountry: 6,
//       totalRepscoreCountry: 246.67
//   },
//   'United States': {
//     listStateMap: {
//       'District of Columbia': {
//         numberOfRatings: 1,
//           valueOfRatings: 256
//       },
//       'South Dakota': {
//         numberOfRatings: 0,
//           valueOfRatings: 0
//       },
//       Alaska: {
//         numberOfRatings: 21,
//           valueOfRatings: 241.39
//       },
//       California: {
//         numberOfRatings: 75,
//           valueOfRatings: 195.47
//       },
//       Georgia: {
//         numberOfRatings: 1,
//           valueOfRatings: 256
//       },
//       Washington: {
//         numberOfRatings: 9,
//           valueOfRatings: 282.22
//       }
//     },
//     totalRatingsCountry: 107,
//       totalRepscoreCountry: 220.38
//   }
// }
