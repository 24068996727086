import React from "react";
import queryString from "query-string";

import ForgotPassword from "./ForgotPassword";
import ResetForgotPassword from "./ResetForgotPassword";

class SendForgotEmail extends React.Component {
  render() {
    const queryParams = queryString.parse(this.props.location.search);
     if (queryParams && queryParams.code) {
      return (
        <ResetForgotPassword {...this.props} code={queryParams.code} email={queryParams.email} />
      );
    }

    return (
      <ForgotPassword {...this.props}/>
    );
  }
}

export default SendForgotEmail;