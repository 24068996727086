import {
  GET_ASSIGNED_ROLES_REQUESTED,
  GET_ASSIGNED_ROLES_SUCCEEDED,
  GET_ASSIGNED_ROLES_FAILED,
  GET_MANAGE_REPS_REQUESTED,
  GET_MANAGE_REPS_SUCCEEDED,
  GET_MANAGE_REPS_FAILED,
  ADD_MANAGE_REP_REQUESTED,
  ADD_MANAGE_REP_SUCCEEDED,
  ADD_MANAGE_REP_FAILED,
  EDIT_REP_MANAGE_REP_REQUESTED,
  EDIT_REP_MANAGE_REP_SUCCEEDED,
  EDIT_REP_MANAGE_REP_FAILED,
  DELETE_MANAGE_REP_REQUESTED,
  DELETE_MANAGE_REP_SUCCEEDED,
  DELETE_MANAGE_REP_FAILED,
  EXIST_REP_MANAGE_REP_REQUESTED,
  EXIST_REP_MANAGE_REP_SUCCEEDED,
  EXIST_REP_MANAGE_REP_FAILED,
  SHOW_REP_MANAGEMENT_MESSAGE,
  CLOSE_REP_MANAGEMENT_MESSAGE,
  DISCONNECT_MANAGE_REP_SUCCEEDED,
  DISCONNECT_MANAGE_REP_REQUESTED,
  GET_FEEDBACK_DISPUTE_LIST_REQUESTED,
  GET_FEEDBACK_DISPUTE_LIST_SUCCESS,
  GET_FEEDBACK_DISPUTE_LIST_FAILED,
  UPDATE_FEEDBACK_DISPUTE_LIST_REQUESTED,
  UPDATE_FEEDBACK_DISPUTE_LIST_SUCCESS,
  UPDATE_FEEDBACK_DISPUTE_LIST_FAILED,
} from "../actions/manageRepsActions";

const initialState = {
  assignedRoles: [],
  assignedRolesFetching: false,
  assignedRolesError: null,

  reps: [],
  repsFetching: false,
  repsError: null,

  addRepFetching: false,
  addRepError: null,

  deleteRepFetching: false,
  deleteRepError: null,

  editRepFetching: false,
  editRepError: null,

  existRep: false,
  existRepFetching: false,
  existRepError: null,

  showMessage: false,
  messageType: null,
  messageData: null,

  disconnectCompanySucceeded: false,
  disconnectCompanyRequested: false,

  feedbackDisputeRequested: false,
  feedbackDisputeSucceeded: null,
  feedbackDisputeFailed: null,

  updateFeedbackDisputeRequested: false,
  updateFeedbackDisputeSucceeded: null,
  updateFeedbackDisputeFailed: null,
};

const manageRepsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISCONNECT_MANAGE_REP_SUCCEEDED:
      return {
        ...state,
        disconnectCompanySucceeded: true,
        disconnectCompanyRequested: false,
      };
    case DISCONNECT_MANAGE_REP_REQUESTED:
      return {
        ...state,
        disconnectCompanyRequested: true,
      };

    case GET_ASSIGNED_ROLES_REQUESTED:
      return {
        ...state,
        assignedRolesFetching: true,
      };
    case GET_ASSIGNED_ROLES_SUCCEEDED:
      return {
        ...state,
        assignedRolesFetching: false,
        assignedRolesError: null,
        assignedRoles: action.payload,
      };
    case GET_ASSIGNED_ROLES_FAILED:
      return {
        ...state,
        assignedRolesFetching: false,
        assignedRolesError: action.payload,
      };

    case GET_MANAGE_REPS_REQUESTED:
      return {
        ...state,
        repsFetching: true,
      };
    case GET_MANAGE_REPS_SUCCEEDED:
      return {
        ...state,
        repsFetching: false,
        repsError: null,
        reps: action.payload,
      };
    case GET_MANAGE_REPS_FAILED:
      return {
        ...state,
        repsFetching: false,
        repsError: action.payload,
      };

    case ADD_MANAGE_REP_REQUESTED:
      return {
        ...state,
        addRepFetching: true,
      };
    case ADD_MANAGE_REP_SUCCEEDED:
      return {
        ...state,
        addRepFetching: false,
        addUserError: null,
      };
    case ADD_MANAGE_REP_FAILED:
      return {
        ...state,
        addRepFetching: false,
        addRepError: action.payload,
      };

    case EDIT_REP_MANAGE_REP_REQUESTED:
      return {
        ...state,
        editRepFetching: true,
      };
    case EDIT_REP_MANAGE_REP_SUCCEEDED:
      return {
        ...state,
        editRepFetching: false,
        editUserError: null,
      };
    case EDIT_REP_MANAGE_REP_FAILED:
      return {
        ...state,
        editRepFetching: false,
        editRepError: action.payload,
      };

    case DELETE_MANAGE_REP_REQUESTED:
      return {
        ...state,
        deleteRepFetching: true,
      };
    case DELETE_MANAGE_REP_SUCCEEDED:
      return {
        ...state,
        deleteRepFetching: false,
        deleteRepError: null,
      };
    case DELETE_MANAGE_REP_FAILED:
      return {
        ...state,
        deleteRepFetching: false,
        deleteRepError: action.payload,
      };

    case EXIST_REP_MANAGE_REP_REQUESTED:
      return {
        ...state,
        existRep: false,
        existRepFetching: true,
      };
    case EXIST_REP_MANAGE_REP_SUCCEEDED:
      return {
        ...state,
        existRep: action.payload,
        existRepFetching: false,
        existRepError: null,
      };
    case EXIST_REP_MANAGE_REP_FAILED:
      return {
        ...state,
        existRepFetching: false,
        existRepError: action.payload,
      };

    case SHOW_REP_MANAGEMENT_MESSAGE:
      return {
        ...state,
        showMessage: true,
        messageType: action.payload.type,
        messageData: action.payload.data,
      };
    case CLOSE_REP_MANAGEMENT_MESSAGE:
      return {
        ...state,
        showMessage: false,
        messageType: null,
        messageData: null,
      };

    case GET_FEEDBACK_DISPUTE_LIST_REQUESTED:
      return {
        ...state,
        feedbackDisputeRequested: true,
        disputeStatusState: action.payload.disputeStatus,
      };
    case GET_FEEDBACK_DISPUTE_LIST_SUCCESS:
      return {
        ...state,
        feedbackDisputeSucceeded: action.payload,
        feedbackDisputeRequested: false,
        feedbackDisputeFailed: null,
      };
    case GET_FEEDBACK_DISPUTE_LIST_FAILED:
      return {
        ...state,
        feedbackDisputeSucceeded: null,
        feedbackDisputeRequested: false,
        feedbackDisputeFailed: action.payload,
      };

    // UPDATE_FEEDBACK_DISPUTE_LIST_REQUESTED,
    //   UPDATE_FEEDBACK_DISPUTE_LIST_SUCCESS,
    //   UPDATE_FEEDBACK_DISPUTE_LIST_FAILED

    case UPDATE_FEEDBACK_DISPUTE_LIST_REQUESTED:
      console.log("Action.payload :", action.payload);
      return {
        ...state,
        updateFeedbackDisputeRequested: {
          loading: true,
          type: action.payload.disputeStatus,
        },
        updateFeedbackDisputeSucceeded: null,
        updateFeedbackDisputeFailed: null,
      };
    case UPDATE_FEEDBACK_DISPUTE_LIST_SUCCESS:
      return {
        ...state,
        updateFeedbackDisputeRequested: {
          loading: false,
          type: "",
        },
        updateFeedbackDisputeSucceeded: action.payload,
        updateFeedbackDisputeFailed: null,
      };
    case UPDATE_FEEDBACK_DISPUTE_LIST_FAILED:
      return {
        ...state,
        updateFeedbackDisputeRequested: {
          loading: false,
          type: "",
        },
        updateFeedbackDisputeSucceeded: null,
        updateFeedbackDisputeFailed: action.payload,
      };
    default:
      return state;
  }
};

export default manageRepsReducer;
