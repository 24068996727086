//@flow
import React from "react";
import styled from "styled-components";
import { reporaSize, reporaColor, Title, Header2, Body3 } from "../../components/global/commonStyles";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.contentPosition === "left" ? "row-reverse" : "row"};

  @media (max-width: ${reporaSize.small}) {
    flex-direction: column-reverse;
  }
`;

const SideImage = styled.div`
  width: ${props => props.imageWidthPercent && props.imageWidthPercent > 0 ? `${props.imageWidthPercent}%` : "50%"};
  display: flex;
  flex-direction: column;

  ${props => props.contentPosition === "left" ? "padding-left: 3%;" : "padding-right: 3%;"};

  img {
    max-width: 100%;
  }

  @media (max-width: ${reporaSize.small}) {
    width: 100%;
    padding: 2% 0;
  }
`;

const PanelContent = styled.div`
  width: ${props => props.imageWidthPercent && props.imageWidthPercent > 0 ? `${100 - props.imageWidthPercent}%` : "50%"};
  display: flex;
  flex-direction: column;
  color: ${props => props.textColor || reporaColor.black};
  justify-content: center;
  
  ${props => props.contentPosition === "left" ? "padding-right: 3%;" : "padding-left: 3%;"};

  @media (max-width: ${reporaSize.small}) {
    width: 100%;
    padding: 2% 0;
    text-align: center;
  }

  h2 {
    font-size: 35px;
    line-height: initial;
    margin-bottom: 1%;

    @media (max-width: ${reporaSize.small}) {
      font-size: 20px;
    }
  }

  p {
    line-height: initial;
    margin-top: 2%;

    @media (max-width: ${reporaSize.small}) {
      font-size: 12px;
    }
  }
`;

class PanelImage extends React.Component {
  render() {
    if (this.props.data) {
      return (
        <Wrapper contentPosition={this.props.contentPosition}>
          <SideImage contentPosition={this.props.contentPosition} imageWidthPercent={this.props.imageWidthPercent}>
            {this.props.data.imgSrc
              ? <img src={this.props.data.imgSrc} alt="" />
              : null
            }
          </SideImage>
          <PanelContent contentPosition={this.props.contentPosition} imageWidthPercent={this.props.imageWidthPercent}>
            <Title textColor={this.props.textColor} >
              {this.props.data.title}
            </Title>
            <Header2 textColor={this.props.textColor}>{this.props.data.header}</Header2>
            <Body3 textColor={this.props.textColor}>{this.props.data.description}</Body3>
          </PanelContent>
        </Wrapper>
      )
    } else {
      return null;
    }
  }
}



export const Unwrapped = PanelImage;
export default PanelImage;
