import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { Header3, Header5, reporaColor, ReporaButton, FormGroup, } from "../../../components/global/commonStyles";
import { Wrapper, CloseButton, HeaderWrapper, FormWrapper, ButtonContainer } from "./CommonStyles";

const MessageWrapper = styled(FormWrapper)`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  text-align: left;

  h5 {
    width: 100%;
    margin: 10px 0;
    text-align: justify;
  }
`;

const EmailPersonal = styled.span`
  color: ${reporaColor.brightSkyBlue};
`;

const Step3RepGenericEmail = props => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <CloseButton onClick={props.handleClose}><FontAwesomeIcon icon={faTimes} /></CloseButton>
        <Header3 textAlign="center">Sign Up</Header3>
      </HeaderWrapper>
      <MessageWrapper className="m-t-15">
        <Header5 textColor={`${reporaColor.warmGrey}`}>It looks like <EmailPersonal>{props.step3Data.email}</EmailPersonal> may be your personal email address.</Header5>
        <Header5 textColor={`${reporaColor.warmGrey}`}>This is okay if you are between jobs, but otherwise please include your work email address:</Header5>
        <FormWrapper>
          <FormGroup style={{margin:"10px"}}>
            <label>Work Email Address</label>
            <input onChange={props.handleInputChange} name="email" value={props.step3Data.email || ""} />
          </FormGroup>
          <ButtonContainer>
            <ReporaButton onClick={props.handleStepSubmit} full>
              Create Account
            </ReporaButton>
          </ButtonContainer>
        </FormWrapper>
      </MessageWrapper>
    </Wrapper>
  );
};

export default Step3RepGenericEmail;
