import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import queryString from "query-string";
import base64 from "base-64";
import { Link } from "react-router-dom";

import { reporaDevice, reporaColor } from "../../../components/global/commonStyles";
import InputSuggestions from "../../SearchRep/components/InputSuggestions";

import SearchSvg from "../../../assets/search_button_solid.svg";
import CloseIconSvg from "../../../assets/close_icon.svg";

const Wrapper = styled.div` 
  ${props => props.header ? `border-right: 1px solid ${reporaColor.grey87}` : ""};
  ${props => props.header ? `border-left: 1px solid ${reporaColor.grey87}` : ""};
  padding: 15px 30px;
  flex: 1;
  position: relative;
`;

const FormWrapper = styled.form`
  display: flex;
  justify-content: center;
  width: 100%;

  > div {
    max-width: 330px;
    width: 100%;
    margin-right: 15px;
  }

  @media ${reporaDevice.xs} {
    flex-direction: column;
    align-items: center;

    > div {
      width: 100%;
      margin: 0;
      margin-top: 15px;
    }

    button {
      order: -1;
    }
  }
`;

const SubmitButton = styled.button`
  background: ${reporaColor.brightSkyBlue};
  display: flex;
  border-radius: 4px;

  padding: 0;
  width: 40px;
  height: 40px;
  border: 0;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const Header = styled.div`
  font-size: 16px;
    ${props => !props.header ? `text-align:center` : ""};
  color: ${reporaColor.brightSkyBlue};
  margin-bottom: 10px;
`;

let CloseButton = styled.img`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;

class SearchForm extends React.Component {
  state = {
    selectedRep: null,
    selectedCompany: null,

    repInputValue: "",
    companyInputValue: "",

    repSuggestions: [],
    companySuggestions: []
  }

  componentDidUpdate(prevProps) {
    const { repSuggestions, companySuggestions } = this.props;

    if (repSuggestions !== prevProps.repSuggestions) {
      this.setState({
        repSuggestions: repSuggestions.map(suggestion => ({
          title: suggestion.name,
          subtitle: suggestion.companyName || "-",
          picture: suggestion.image,
          repId: suggestion.id
        }))
      });
    }

    if (companySuggestions !== prevProps.companySuggestions) {
      this.setState({
        companySuggestions: companySuggestions.map(suggestion => {
          const industry = suggestion.industry ? `${suggestion.industry} - ` : "";
          const address = (
            suggestion.physicalAddress &&
            suggestion.physicalAddress.state &&
            suggestion.physicalAddress.city
          ) ? `${suggestion.physicalAddress.state}, ${suggestion.physicalAddress.city}` : "-";

          return ({
            title: suggestion.name,
            subtitle: industry + address,
            picture: suggestion.logo,
            companyId: suggestion.id
          });
        })
      });
    }
  }

  handleSelectRep = selectedEl => {
    this.setState({
      selectedRep: selectedEl,
      repInputValue: selectedEl ? selectedEl.title : ""
    });
  }

  handleSelectCompany = selectedEl => {
    this.setState({
      selectedCompany: selectedEl,
      companyInputValue: selectedEl ? selectedEl.title : ""
    });
  }

  handleInputRepChange = value => {
    this.setState({
      selectedRep: null,
      repInputValue: value
    });
  }

  handleInputCompanyChange = value => {
    this.setState({
      selectedCompany: null,
      companyInputValue: value
    });
  }

  handleSearch = event => {
    event.preventDefault();
    const { selectedRep, selectedCompany, repInputValue, companyInputValue } = this.state;

    const url = queryString.stringify({
      repName: repInputValue || undefined,
      companyName: companyInputValue || undefined,
      repId: selectedRep ? selectedRep.repId : undefined,
      companyId: selectedCompany ? selectedCompany.companyId : undefined
    });

    const encodedUrl = base64.encode(url);
    this.props.history.push(`/searchunclaimedaccount/results/${encodedUrl}`);
  }

  handleGetRepSuggestions = inputValue => {
    const companyId = this.state.selectedCompany && this.state.selectedCompany.companyId;
    this.props.handleGetRepSuggestions(inputValue, companyId);
  }

  handleGetCompanySuggestions = inputValue => {
    const repId = this.state.selectedRep && this.state.selectedRep.repId;
    this.props.handleGetCompanySuggestions(inputValue, repId);
  }

  render() {
    const { repSuggestions, companySuggestions } = this.state;
    return (
      <Wrapper header={this.props.header}>
        <Link to="/viewreppersonal">
          {this.props.header && <CloseButton src={CloseIconSvg} alt="close" />}
        </Link>

        <Header header={this.props.header}>Search for unclaimed accounts that may be yours:</Header>
        <FormWrapper>
          <InputSuggestions
            name="new-rep-suggestions"
            suggestions={repSuggestions}
            placeholder="Enter the name of a rep"
            loading={this.props.repSuggestionsFetching}
            handleSelectElement={this.handleSelectRep}
            selectedElement={this.state.selectedRep}
            handleInputChange={this.handleInputRepChange}

            inputDelay={this.props.inputDelay}
            handleGetSuggestions={this.handleGetRepSuggestions}
          />

          <InputSuggestions
            name="new-company-suggestions"
            suggestions={companySuggestions}
            placeholder="Company"
            loading={this.props.companySuggestionsFetching}
            handleSelectElement={this.handleSelectCompany}
            selectedElement={this.state.selectedCompany}
            handleInputChange={this.handleInputCompanyChange}

            inputDelay={this.props.inputDelay}
            handleGetSuggestions={this.handleGetCompanySuggestions}
          />

          <SubmitButton
            type="submit"
            onClick={this.handleSearch}
            disabled={this.props.fetchingCompanies || this.props.fetchingReps || (!this.state.repInputValue && !this.state.companyInputValue)}>
            <img src={SearchSvg} alt="search" />
          </SubmitButton>
        </FormWrapper>
      </Wrapper>
    );
  }
}

SearchForm.propTypes = {
  repSuggestions: PropTypes.array,
  companySuggestions: PropTypes.array,

  repSuggestionsFetching: PropTypes.bool,
  fetchingCompanySuggestions: PropTypes.bool,

  repSuggestionsError: PropTypes.string
};

SearchForm.defaultProps = {
  repSuggestions: [],
  companySuggestions: []
}

export default withRouter(SearchForm);
