import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { reporaColor, ReporaButton, FormGroup, InputTooltip, Body5 } from "../../../components/global/commonStyles";
import Loader from "../../../components/Loader/Loader";

const Wrapper = styled.div`
  max-width: 502px;
  width: 100%;
  border-radius: 2px;
  background: white;
  box-shadow: 0 0 8px 0 ${reporaColor.black02};
  margin: 0 auto;

  padding: 35px 55px;
  position: relative;
  z-index: 1;
  margin-top:2%;
  margin-bottom: 50px;
  > div >h1 {
    text-align:center;
    margin-bottom: 5%;
  }
  >div >div{

    margin-bottom:5%
  }
`;

const Header = styled.h1`
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  margin-bottom: 50px;
`;

const ErrorMessage = styled.div`
  margin-top: 30px;
  color: ${reporaColor.watermelon};
`;

const RestrictionItem = styled.li`
  display: flex;
  align-items: center;
  img {
    opacity: ${props => props.isValid ? 1 : 0};
    margin-right: 5px;
    width: 10px;
  }
`;

const SubHeader = styled.p`
  color: ${reporaColor.brightSkyBlue};
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  margin-top: 25px;
  margin-bottom: 20px;
`;

class ConfirmRegularAccount extends React.Component {
  state = {
    password: "",
    repassword: "",
    passwordValid: false,
    repasswordValid: false,
    passwordTooltip: false,
    repasswordTooltip: false,
    passwordRules: [
      { description: "8 characters", valid: false, regex: /^(?=.*\d).{8,}$/ },
      { description: "1 capital letter", valid: false, regex: /[A-Z]/g },
      { description: "1 lowercase letter", valid: false, regex: /[a-z]/g },
      { description: "1 number", valid: false, regex: /[0-9]/g },
      { description: "1 special character", valid: false, regex: /[^\w\s]/ }
    ]
  }


  handleInputChange = event => {
    let value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    const name = event.target.name;

    if (name === "password" || name === "repassword") {
      value = event.target.value.trim()
    }

    this.setState({
      [name]: value
    });

    if (name === "password") {
      this.validatePassword(value);
    }

    if (name === "repassword") {
      this.validateRepassword(value);
    }
  }

  handleSubmitButton = () => {
    const { password, repassword } = this.state;
    const { confirmCode, username, role } = this.props;
    if (this.validatePassword(password) && this.validateRepassword(repassword)) {
      this.props.onSubmit({
        confirmCode: confirmCode,
        password: password,
        role: role,
        username: username
      });
    }
  }


  handleResendSubmit = (event) => {
    const { password, repassword } = this.state;
    const { confirmCode, username, role } = this.props;
    this.props.resendConfirmation({
      confirmCode: confirmCode,
      password: password,
      role: role,
      username: username
    });
    this.setState({ msg: true })
  }

  handleNewRequestButton = () => {
    this.props.handleForgotPasswordClear();
  }

  validatePassword = value => {
    let passwordRulesTemp = this.state.passwordRules.slice();
    let passwordValid = true;
    const repasswordValid = this.state.repassword === value;

    passwordRulesTemp.forEach(element => {
      element.valid = !!value.match(element.regex);
      passwordValid = passwordValid && !!value.match(element.regex);
    });
    this.setState({
      passwordValid: passwordValid,
      passwordRules: passwordRulesTemp,
      repasswordValid: repasswordValid
    });
    return passwordValid;
  }

  validateRepassword = repassword => {
    const repasswordValid = repassword === this.state.password;
    this.setState({
      repasswordValid: repasswordValid
    });
    return repasswordValid;
  }

  handleInputBlur = event => {
    const inputName = event.target.getAttribute("name");
    switch (inputName) {
      case "password":
        this.setState({ passwordTooltip: false });
        break;
      case "repassword":
        this.setState({ repasswordTooltip: false });
        break;
      default: break;
    }
  }

  handleInputFocus = event => {
    const inputName = event.target.getAttribute("name");
    switch (inputName) {
      case "password":
        this.setState({ passwordTooltip: true });
        break;
      case "repassword":
        this.setState({ repasswordTooltip: true });
        break;
      default: break;
    }
  }

  handleLogOut = () => {
    const { handleLogout, history } = this.props;
    handleLogout(history);
  }

  render() {
    const { password, repassword, passwordValid, repasswordValid } = this.state;
    const { resetPasswordError, fetchingResetPassword, resetPasswordSent, email, error, success } = this.props;

    let _err = error; let invalidCode = false;
    let formButton = <ReporaButton disabled={!passwordValid || !repasswordValid} className="m-t-30" full onClick={this.handleSubmitButton}> Confirm </ReporaButton>
    if (_err === 'There was a connection error in the system') {
      _err = "Your verification link has expired. Please click below to request a new one.";
      invalidCode = true;
      formButton = <ReporaButton className="m-t-30" full onClick={this.handleResendSubmit}>Resend Email</ReporaButton>
    }


    return (
      <Wrapper>
        {fetchingResetPassword && <Loader overlayed size="medium" />}
        <div>
          <Header>Repora Confirm Account</Header>
        </div>
        {success === "Verification Link sent to you email." ? <>
          <div className="success-animation">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
          </div>
          <h2>We have sent a verification link to your email please verify your mail</h2>
         </> : <div>
          <FormGroup marginTop="none">
            <label>Email</label>
            <input
              name="email"
              type="text"
              value={email}
              disabled={true}
            />
          </FormGroup>
          <FormGroup marginTop="none">
            <label>Create New Password</label>
            <input
              name="password"
              type="password"
              value={password}
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur}
              onChange={this.handleInputChange} />
            <InputTooltip
              isValid={this.state.passwordValid}
              isVisible={this.state.passwordTooltip}>
              <Body5 textAlign="left">Must contain at least</Body5>
              <ul >
                {this.state.passwordRules.map((item, index) => {
                  return (
                    <RestrictionItem key={"passwordRule" + index} isValid={item.valid}>
                      <img src="assets/global/check-white.svg" alt="" />
                      <Body5 textAlign="left">
                        {item.description}
                      </Body5>
                    </RestrictionItem>
                  );
                })}
              </ul>
            </InputTooltip>
          </FormGroup>
          <FormGroup>
            <label>Re-Enter New Password</label>
            <input
              name="repassword"
              type="password"
              value={repassword}
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur} />
            <InputTooltip
              isValid={this.state.repasswordValid}
              isVisible={this.state.repasswordTooltip}>
              <Body5 textAlign="left">
                {this.state.repasswordValid
                  ? "Password is Matching"
                  : "Password Doesn't Match"
                }
              </Body5>
            </InputTooltip>
          </FormGroup>
          {_err && <ErrorMessage>{_err}</ErrorMessage>}
          {resetPasswordError && <ErrorMessage>{resetPasswordError}</ErrorMessage>}
          

          {formButton}
        </div>}
      </Wrapper>
    );
  }
}

export default ConfirmRegularAccount;
