import constants from "../constants";
import ManageUserService from "../services/ManageUserService";
import { handleGetCompanyUser } from "./companyActions";

/* Action Constants */
export const GET_COMPANY_USERS_REQUESTED = "GET_COMPANY_USERS_REQUESTED";
export const GET_COMPANY_USERS_SUCCEEDED = "GET_COMPANY_USERS_SUCCEEDED";
export const GET_COMPANY_USERS_FAILED = "GET_COMPANY_USERS_FAILED";

export const ADD_COMPANY_USER_REQUESTED = "ADD_COMPANY_USER_REQUESTED";
export const ADD_COMPANY_USER_SUCCEEDED = "ADD_COMPANY_USER_SUCCEEDED";
export const ADD_COMPANY_USER_FAILED = "ADD_COMPANY_USER_FAILED";
export const CLEAN_COMPANY_USER = "CLEAN_COMPANY_USER";

export const RESET_PASSWORD_COMPANY_USER_REQUESTED = "RESET_PASSWORD_COMPANY_USER_REQUESTED";
export const RESET_PASSWORD_COMPANY_USER_SUCCEEDED = "RESET_PASSWORD_COMPANY_USER_SUCCEEDED";
export const RESET_PASSWORD_COMPANY_USER_FAILED = "RESET_PASSWORD_COMPANY_USER_FAILED";

export const DELETE_USER_REQUESTED = "DELETE_USER_REQUESTED";
export const DELETE_USER_SUCCEEDED = "DELETE_USER_SUCCEEDED";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

export const USER_REVIEWS_EXIST_REQUESTED = "USER_REVIEWS_EXIST_REQUESTED";
export const USER_REVIEWS_EXIST_SUCCEEDED = "USER_REVIEWS_EXIST_SUCCEEDED";
export const USER_REVIEWS_EXIST_FAILED = "USER_REVIEWS_EXIST_FAILED";

export const EDIT_USER_REQUESTED = "EDIT_USER_REQUESTED";
export const EDIT_USER_SUCCEEDED = "EDIT_USER_SUCCEEDED";
export const EDIT_USER_FAILED = "EDIT_USER_FAILED";

export const SHOW_USER_MANAGEMENT_MESSAGE = "SHOW_USER_MANAGEMENT_MESSAGE";
export const CLOSE_USER_MANAGEMENT_MESSAGE = "CLOSE_USER_MANAGEMENT_MESSAGE";

/* Clear Errors */
export const CLEAR_ADD_USER_ERROR_MESSAGES = "CLEAR_ADD_USER_ERROR_MESSAGES";
export const CLEAR_DELETE_USER_ERROR_MESSAGES = "CLEAR_DELETE_USER_ERROR_MESSAGES";
export const CLEAR_RESET_PASSWORD_USER_ERROR_MESSAGES = "CLEAR_RESET_PASSWORD_USER_ERROR_MESSAGES";


/* Action Creators */
const getCompanyUsersRequested = () => ({ type: GET_COMPANY_USERS_REQUESTED, payload: null });
const getCompanyUsersSucceeded = data => ({ type: GET_COMPANY_USERS_SUCCEEDED, payload: data });
const getCompanyUsersFailed = data => ({ type: GET_COMPANY_USERS_FAILED, payload: data });

const addCompanyUserRequested = () => ({ type: ADD_COMPANY_USER_REQUESTED, payload: null });
const addCompanyUserSucceeded = data => ({ type: ADD_COMPANY_USER_SUCCEEDED, payload: data });
const addCompanyUserFailed = data => ({ type: ADD_COMPANY_USER_FAILED, payload: data });

const resetPasswordCompanyUserRequested = () => ({ type: RESET_PASSWORD_COMPANY_USER_REQUESTED, payload: null });
const resetPasswordCompanyUserSucceeded = data => ({ type: RESET_PASSWORD_COMPANY_USER_SUCCEEDED, payload: data });
const resetPasswordCompanyUserFailed = (data, email) => ({ type: RESET_PASSWORD_COMPANY_USER_FAILED, payload: data, email });

const deleteUserRequested = () => ({ type: DELETE_USER_REQUESTED, payload: null });
const deleteUserSucceeded = data => ({ type: DELETE_USER_SUCCEEDED, payload: data });
const deleteUserFailed = data => ({ type: DELETE_USER_FAILED, payload: data });

const userReviewsExistRequested = () => ({ type: USER_REVIEWS_EXIST_REQUESTED, payload: null });
const userReviewsExistSucceeded = data => ({ type: USER_REVIEWS_EXIST_SUCCEEDED, payload: data });
const userReviewsExistFailed = data => ({ type: USER_REVIEWS_EXIST_FAILED, payload: data });

const editUserRequested = () => ({ type: EDIT_USER_REQUESTED, payload: null });
const editUserSucceeded = data => ({ type: EDIT_USER_SUCCEEDED, payload: data });
const editUserFailed = data => ({ type: EDIT_USER_FAILED, payload: data });
const cleanUser = data => ({ type: CLEAN_COMPANY_USER, payload: null });

const closeMessageRequested = () => ({ type: CLOSE_USER_MANAGEMENT_MESSAGE, payload: null });
const showMessageRequested = data => ({ type: SHOW_USER_MANAGEMENT_MESSAGE, payload: data });

const requestClearAddUserErrorMessages = () => ({ type: CLEAR_ADD_USER_ERROR_MESSAGES });
const requestClearDeleteUserErrorMessages = () => ({ type: CLEAR_DELETE_USER_ERROR_MESSAGES });
const requestClearResetPasswordUserErrorMessages = () => ({ type: CLEAR_RESET_PASSWORD_USER_ERROR_MESSAGES });

/* Async Actions */
export function getUsers(role, companyId) {
  return dispatch => {
    dispatch(getCompanyUsersRequested());
    dispatch(cleanUser());

    ManageUserService.getUsers(role, companyId)
      .then(res => {
        dispatch(getCompanyUsersSucceeded(res));
      })
      .catch(err => {
        dispatch(getCompanyUsersFailed(err));
      });
  };
}

export function addCompanyUser(user, role, companyId) {
  return dispatch => {
    dispatch(addCompanyUserRequested());

    ManageUserService.addCompanyUser(user, companyId)
      .then(res => {
        dispatch(addCompanyUserSucceeded(res));
        dispatch(showMessageRequested({ type: "add", data: { firstName: user.firstName, lastName: user.lastName } }));
        dispatch(getUsers(role, companyId));
      })
      .catch(err => {
        dispatch(addCompanyUserFailed(err));
      });
  };
}

export function resetPassword(email) {
  return dispatch => {
    dispatch(resetPasswordCompanyUserRequested());

    ManageUserService.resetPasswordCompanyUser(email)
      .then(res => {
        dispatch(resetPasswordCompanyUserSucceeded(res));
        dispatch(showMessageRequested({ type: "reset", data: { email } }));
      })
      .catch(err => {
        dispatch(resetPasswordCompanyUserFailed(err, email));
      });
  };
}

export function deleteUser(email, firstName, lastName, role, companyId) {
  return dispatch => {
    dispatch(deleteUserRequested());

    ManageUserService.deleteUser(email)
      .then(res => {
        dispatch(deleteUserSucceeded(res));
        dispatch(showMessageRequested({ type: "delete", data: { firstName, lastName } }));
        dispatch(getUsers(role, companyId));
      })
      .catch(err => {
        dispatch(deleteUserFailed(err));
      });
  };
}

 export function checkUserReviewsData(email, firstName, lastName, role, companyId, id) {
  return dispatch => {
    ManageUserService.checkUserReviews({ "id": id })
      .then(res => {
        if (res.payload) {
          dispatch(deleteUserPermanante(email, firstName, lastName, role, companyId))
        } else {
          dispatch(deleteUser(email, firstName, lastName, role, companyId));
        }
      }).catch(err => {
        dispatch(deleteUserFailed(err));
      });
  };
}


export function checkUserReviewsDataExist(id) {
  return dispatch => {
    dispatch(userReviewsExistRequested())
    ManageUserService.checkUserReviews({ "id": id })
      .then(res => {
        dispatch(userReviewsExistSucceeded(res))
      }).catch(err => {
        dispatch(userReviewsExistFailed(err));
      });
  };
}

export function deleteUserPermanante(email, firstName, lastName, role, companyId, id) {
  return dispatch => {
    dispatch(deleteUserRequested());
    ManageUserService.disconnectCompanyUser({ "id": id })
      .then(res => {
        dispatch(deleteUserSucceeded(res));
        dispatch(showMessageRequested({ type: "delete", data: { firstName, lastName } }));
        dispatch(getUsers(role, companyId));
      })
      .catch(err => {
        dispatch(deleteUserFailed(err));
      });
  };
}
 

export function closeMessage() {
  return dispatch => {
    dispatch(closeMessageRequested());
  }
}

export function clearAddUserErrorMessages() {
  return dispatch => {
    dispatch(requestClearAddUserErrorMessages());
  }
}

export function clearDeleteUserErrorMessages() {
  return dispatch => {
    dispatch(requestClearDeleteUserErrorMessages());
  }
}

export function clearResetPasswordUserErrorMessages() {
  return dispatch => {
    dispatch(requestClearResetPasswordUserErrorMessages());
  }
}

export function editCompanyUser(user, role, companyId) {
  return dispatch => {
    dispatch(editUserRequested());
    ManageUserService.editCompanyUser(user)
      .then(res => {
        if( role === constants.USER_ROLES.REGULAR){
          dispatch(handleGetCompanyUser({userId: user.id ,role:role}))
        }
        dispatch(showMessageRequested({ type: "edit", data: { firstName: user.firstName, lastName: user.lastName } }));
        dispatch(getUsers(role, companyId));
        dispatch(editUserSucceeded(res));
      })
      .catch(err => {
        dispatch(editUserFailed(err));
      });
  };
}
