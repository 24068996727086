import React from "react";

import { connect } from "react-redux";
import { Redirect } from "react-router";
import queryString from "query-string";

import {
  signUpUser,
  addCompany,
  socialSignUp,
  getSocialUrl,
  getSocialData,
  resetAccountReducer,
  signUpCompany,
} from "../../actions/accountActions";
import {
  getCompanyPublicInfo,
  getCompanyInfoByInvite,
  getRepAttrList,
} from "../../actions/companyActions";
import View from "./components/View";
import CountriesStates from "../../data/CountriesStates";
import industries from "../../data/linkedInIndustries";
import constants from "../../constants";
import { isEmpty, isEqual } from "lodash";

class SignUp extends React.Component {
  state = {
    step: 1,
    redirectLink: "",
    userRole: "",
    provider: "",
    code: "",
    stateErrorMessage: "",
    functions: constants.FUNCTIONS,
    step2Customer: {
      email: "",
      password: "",
    },

    step2Company: {
      companyName: "",
      linkedinUrl: "",
      industry: "",
      country: "",
      city: "",
      state: "",
      zipCode: "",
      phoneNumber: "",
      valid: false,
    },

    step2RepValid: false,
    step3Customer: {
      firstName: "",
      lastName: "",
      jobTitle: "",
      companyName: "",
      country: "",
      city: "",
      state: "",
      zipCode: "",
      phoneNumber: "",
      valid: false,
      token: "",
    },
    step3Rep: {
      token: "",
    },
    step3Company: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      valid: false,
    },
    step4CompanyExisted: false,
    countryList: [],
    cityList: [],
    industriesList: [],
    functionSelected: "",
    invitedLink: false,
  };

  componentDidMount() {
    this.props.resetAccountReducer();
    this.props.handleGetRepList();

    let countries = CountriesStates.map(function (element) {
      return element.countryName;
    });
    countries.splice(countries.indexOf("United States"), 1);
    countries.unshift("United States");

    const industriesList = industries.map(function (element) {
      return element.description;
    });

    this.setState({
      countryList: countries,
      industriesList: industriesList,
    });

    const urlString = queryString.parse(this.props.location.search);
    let queryCompanyId;
    if (urlString) {
      if (urlString.companyId) {
        queryCompanyId = urlString.companyId;
      } else if (urlString.inviteId) {
        queryCompanyId = urlString.inviteId;
        window.localStorage.setItem("inviteId", urlString.inviteId);
      } else {
        queryCompanyId = null;
      }
    }

    if (queryCompanyId) {
      if (urlString.inviteId) {
        this.setState({
          inviteId: queryCompanyId,
          userRole: "REP",
          step: 2,
          invitation: true,
        });
      } else {
        this.setState({
          invitationCompanyId: queryCompanyId,
          userRole: "REP",
          step: 2,
          invitation: true,
        });
      }
      if (urlString.inviteId) {
        this.props.handleGetCompanyInfoByInviteId(queryCompanyId);
      } else {
        this.props.handleGetCompanyPublicInfo(queryCompanyId);
      }
    }

    if (urlString.state) {
      let companyId = urlString.state.split("_")[0].split("-");
      companyId.shift();
      companyId = companyId.join("-");

      this.setState({
        invitationCompanyId: companyId,
      });

      const split = urlString.state.split("_") || [];
      const data = {};
      split.forEach((urlParam) => {
        const splited = urlParam.split("-");
        const key = splited.shift();
        const value = splited.join("-");

        data[key] = value;
      });
      try {
        if (data.Invited) {
          this.setState({
            invitedLink: true,
          });
        }
      } catch (e) {
        console.log(e);
      }

      if (data.role) {
        switch (data.role) {
          case "REP":
            this.setState({
              userRole: "REP",
              step: 1,
              invitation: false,
            });
            break;
          case "CUSTOMER":
            this.setState({
              userRole: "CUSTOMER",
              step: 1,
            });
            break;
          case "ADMIN":
            this.setState({
              userRole: "ADMIN",
              step: 1,
            });
            break;
          default:
            break;
        }
      }
    }

    this.parsingUrlQuery();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.companyPublicInfo &&
      !isEmpty(this.props.companyPublicInfo) &&
      !isEqual(this.props.companyPublicInfo, prevProps.companyPublicInfo)
    ) {
      window.localStorage.setItem(
        "companyId",
        this.props.companyPublicInfo.companyId
      );
    }
  }

  componentWillReceiveProps(newprops) {
    if (this.state.step < 3) {
      this.setState({ stateErrorMessage: "" });
      if (newprops.socialDataValid) {
        if (this.state.userRole === "CUSTOMER") {
          let step3CustomerData = newprops.socialData;

          step3CustomerData["valid"] =
            this.checkingStepComplete(step3CustomerData);
          this.setState({
            step: 3,
            step3Customer: step3CustomerData,
          });
          const country = CountriesStates.find(function (element) {
            return element.countryName === step3CustomerData.country;
          });

          if (country) {
            const cityList = country.regions.map((city) => city.name);
            this.setState({ cityList: cityList });
          }
        } else if (this.state.userRole === "REP") {
          const step3RepData = { ...this.state.step3Rep };
          const newStep3RepData = { ...newprops.socialData };
          newStep3RepData["token"] = step3RepData.token;
          this.setState({
            step: 3,
            step3Rep: newStep3RepData,
          });
        }
      } else if (
        this.state.userRole !== "" &&
        this.state.userRole !== "ADMIN"
      ) {
        this.setState({ stateErrorMessage: newprops.socialData.message });
        window.history.pushState({}, document.title, "/signup");
        this.setState({ step: 2 });
      }
    }
  }

  decodeCodeURI(codeEnc) {
    this.setState({
      code: decodeURIComponent(codeEnc),
    });
  }

  parsingUrlQuery = () => {
    if (window.location.search !== "") {
      let queries = window.location.search.substring(1).split("&");
      let queryJson = {};

      queries.forEach(function (element) {
        const elementSplit = element.split("=");
        if (elementSplit[0] === "state") {
          const stateQueries = elementSplit[1].split("_");
          stateQueries.forEach(function (element) {
            const subElementSplit = element.split("-");
            queryJson[subElementSplit[0]] = subElementSplit[1];
          });
        } else {
          queryJson[elementSplit[0]] = elementSplit[1];
        }
      });

      let codeToSend = queryJson["code"];
      if (queryJson["role"] && queryJson["provider"]) {
        this.setState({
          userRole: queryJson["role"] || "",
          provider: queryJson["provider"] || "",
          code: queryJson["code"] || "",
        });
        if (
          queryJson["code"] &&
          queryJson["code"].slice(0, 2) === "4%" &&
          queryJson["provider"] === "GOOGLE"
        ) {
          this.decodeCodeURI(queryJson["code"]);
          codeToSend = decodeURIComponent(queryJson["code"]);
        }
        this.props.getSocialData(
          codeToSend,
          queryJson["provider"],
          queryJson["role"]
        );
      }
    }
  };
  handleBackExit = () => {
    window.history.back();
  };

  handleClose = () => {
    this.props.resetAccountReducer();
    this.setState({
      step: 1,
      redirectLink: "/",
      userRole: "",
      provider: "",
      code: "",
      stateErrorMessage: "",
      step2Customer: {
        email: "",
        password: "",
      },
      step2Company: {
        companyName: "",
        linkedinUrl: "",
        industry: "",
        country: "",
        city: "",
        state: "",
        zipCode: "",
        phoneNumber: "",
        valid: false,
      },
      step2RepValid: false,
      step3Customer: {
        firstName: "",
        lastName: "",
        jobTitle: "",
        companyName: "",
        country: "",
        city: "",
        state: "",
        zipCode: "",
        phoneNumber: "",
        valid: false,
        token: "",
      },
      step3Company: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        valid: false,
      },
      step4CompanyExisted: false,
      cityList: [],
    });
  };

  handleBack = () => {
    if (this.state.step === 2) {
      this.setState({
        step: 1,
        userRole: "",
        redirectLink: "",
        provider: "",
        errorMessage: "",
        step2Customer: {
          email: "",
          password: "",
        },
        step2Company: {
          companyName: "",
          industry: "",
          country: "",
          city: "",
          state: "",
          zipCode: "",
          phoneNumber: "",
          valid: false,
        },
      });
    } else if (this.state.step === 3) {
      if (this.state.provider === "") {
        this.setState({
          step: 2,
          step3Customer: {
            firstName: "",
            lastName: "",
            jobTitle: "",
            companyName: "",
            country: "",
            city: "",
            state: "",
            zipCode: "",
            phoneNumber: "",
            valid: false,
            token: "",
          },
          step3Company: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            valid: false,
          },
        });
      } else {
        this.props.resetAccountReducer();
        this.setState({
          step: 2,
          step3Customer: {
            firstName: "",
            lastName: "",
            jobTitle: "",
            companyName: "",
            country: "",
            city: "",
            state: "",
            zipCode: "",
            phoneNumber: "",
            valid: false,
            token: "",
          },
        });
        window.history.pushState({}, document.title, "/signup");
      }
    }
  };

  handleRoleSelection = (value) => {
    this.setState({ userRole: value });
  };

  handleRecaptchaChange = (value) => {
    if (this.state.userRole === "CUSTOMER") {
      let step3CustomerData = this.state.step3Customer;
      step3CustomerData["token"] = value;
      step3CustomerData["valid"] = this.checkingStepComplete(step3CustomerData);
      this.setState({ step3Customer: step3CustomerData });
    } else if (this.state.userRole === "REP") {
      const step3RepData = this.state.step3Rep;
      step3RepData["token"] = value;
      this.setState({ step3Rep: step3RepData });
    }
  };

  handleInputChange = (event) => {
    let inputName = event.target.name;
    let inputValue = event.target.value;

    if (inputName === "phoneNumber") {
      // inputValue = inputValue.replace(/-| |\(|\)|/g, "");
      inputValue = inputValue.replace(/[^0-9+]/g, "");
    }
    if (inputName === "jobTitle") {
      inputValue = inputValue.replace(/[^\w,.&-\s]/g, "");
    }
    if (this.state.step === 2) {
      if (this.state.userRole === "CUSTOMER") {
        let step2CustomerData = this.state.step2Customer;
        step2CustomerData[inputName] = inputValue;
        this.setState({ step2Customer: step2CustomerData });
      } else if (this.state.userRole === "REP") {
        this.setState({ step2RepValid: event.target.checked });
      } else if (this.state.userRole === "ADMIN") {
        let step2CompanyData = this.state.step2Company;
        step2CompanyData[inputName] = inputValue;
        step2CompanyData["valid"] = this.checkingStepComplete(step2CompanyData);
        this.setState({ step2Company: step2CompanyData });
      }
    }
    if (this.state.step === 3) {
      if (this.state.userRole === "CUSTOMER") {
        let step3CustomerData = this.state.step3Customer;
        step3CustomerData[inputName] = inputValue;
        step3CustomerData["valid"] =
          this.checkingStepComplete(step3CustomerData);
        this.setState({ step3Customer: step3CustomerData });
      } else if (this.state.userRole === "REP") {
        let step3RepData = this.state.step3Rep;
        step3RepData[inputName] = inputValue;
        step3RepData["valid"] = this.checkingStepComplete(step3RepData);
        this.setState({ step3Rep: step3RepData });
      } else if (this.state.userRole === "ADMIN") {
        let step3CompanyData = this.state.step3Company;
        step3CompanyData[inputName] = inputValue;
        step3CompanyData["valid"] = this.checkingStepComplete(step3CompanyData);
        this.setState({ step3Company: step3CompanyData });
      }
    }
  };

  handleCountryChange = (value) => {
    if (this.state.userRole === "CUSTOMER") {
      let step3CustomerData = this.state.step3Customer;
      step3CustomerData["country"] = value;
      step3CustomerData["state"] = "";
      step3CustomerData["valid"] = this.checkingStepComplete(step3CustomerData);
      this.setState({ step3Customer: step3CustomerData });
    } else if (this.state.userRole === "ADMIN") {
      let step2CompanyData = this.state.step2Company;
      step2CompanyData["country"] = value;
      step2CompanyData["state"] = "";
      step2CompanyData["valid"] = this.checkingStepComplete(step2CompanyData);
      this.setState({ step2Company: step2CompanyData });
    }

    const country = CountriesStates.find(function (element) {
      return element.countryName === value;
    });

    const cityList = country.regions.map((city) => city.name);

    this.setState({ cityList: cityList });
  };

  handleCityChange = (value) => {
    if (this.state.userRole === "CUSTOMER") {
      let step3CustomerData = this.state.step3Customer;
      step3CustomerData["state"] = value;
      step3CustomerData["valid"] = this.checkingStepComplete(step3CustomerData);
      this.setState({ step3Customer: step3CustomerData });
    } else if (this.state.userRole === "ADMIN") {
      let step2CompanyData = this.state.step2Company;
      step2CompanyData["state"] = value;
      step2CompanyData["valid"] = this.checkingStepComplete(step2CompanyData);
      this.setState({ step2Company: step2CompanyData });
    }
  };

  handleIndustryChange = (value) => {
    let step2CompanyData = this.state.step2Company;
    step2CompanyData["industry"] = value;
    step2CompanyData["valid"] = this.checkingStepComplete(step2CompanyData);
    this.setState({ step2Company: step2CompanyData });
  };

  handleStepSubmit = () => {
    document.getElementById("contentLoader").scrollIntoView();
    window.scrollTo(0, 0);
    if (this.state.step === 1 && this.state.userRole !== "") {
      if (this.state.userRole === "ADMIN") {
        this.setState({ redirectLink: "/contactus?view=company" });
      } else {
        this.setState({ step: 2 });
      }
    }

    if (this.state.step === 2) {
      if (this.state.userRole === "CUSTOMER") {
        this.setState({ step: 3 });
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (this.state.userRole === "ADMIN") {
        if (this.state.step2Company.valid) {
          this.setState({ step: 3 });
        }
      }
    }

    if (this.state.step === 3) {
      if (this.props.socialDataValid) {
        if (
          this.state.userRole === "CUSTOMER" &&
          this.state.step3Customer.valid
        ) {
          window.history.pushState({}, document.title, "/signup");
          this.setState({ step: 4 });
          this.props.handleSocialSignUp({
            token: this.state.step3Customer.token,
            ...this.props.socialData,
            password: null,
            companyName: this.state.step3Customer.companyName,
            companyId: null,
            country: this.state.step3Customer.country,
            phoneNumber: this.state.step3Customer.phoneNumber,
            zipCode: this.state.step3Customer.zipCode,
            state: this.state.step3Customer.state,
            userRole: this.state.step3Customer.userRole,
            city: this.state.step3Customer.city,
            jobTitle: this.state.step3Customer.jobTitle,
            invitedCompanyId: this.state.invitationCompanyId,
          });
        } else if (this.state.userRole === "REP") {
          // if (this.isGenericEmail(this.props.socialData.email)) {
          //   this.setState({ step: 3.5 });
          // } else {
          let data = {
            ...this.props.socialData,
            ...this.props.functionSelected,
            companyId:
              window.localStorage.getItem("companyId") ||
              this.state.invitationCompanyId,
            invitedCompanyId:
              window.localStorage.getItem("companyId") ||
              this.state.invitationCompanyId,
            inviteId: window.localStorage.getItem("inviteId") || "",
          };
          if (this.state.functionSelected === "Other") {
            data.repType = "General";
          } else {
            data.repType = this.state.functionSelected;
          }
          if (data.companyName === null) {
            data.companyName = "";
          }
          if (!isEmpty(this.props.socialData)) {
            if (
              this.props.socialData.companyName === null ||
              this.props.socialData.companyName === ""
            ) {
              data.companyName = this.state.step3Rep.companyName || "";
            }
          }
          data["token"] = this.state.step3Rep.token;
          this.props.handleSocialSignUp(data);
          window.history.pushState({}, document.title, "/signup");
          this.setState({ step: 4 });
          // }
        }
      } else if (this.state.step3Customer.valid) {
        this.setState({ step: 4 });
        window.history.pushState({}, document.title, "/signup");
        this.props.handleSignUp(
          this.state.step3Customer.token,
          this.state.step2Customer.email,
          this.state.step3Customer.firstName,
          this.state.step3Customer.lastName,
          this.state.step2Customer.password,
          this.state.step3Customer.companyName,
          "",
          this.state.step3Customer.country,
          this.state.step3Customer.phoneNumber,
          this.state.step3Customer.zipCode,
          this.state.step3Customer.state,
          this.state.step3Customer.city,
          this.state.step3Customer.jobTitle,
          "",
          this.state.userRole
        );
      } else if (this.state.step3Company.valid) {
        this.setState({ step: 4 });

        this.props.handleSignUpCompany({
          administrator: {
            email: this.state.step3Company.email,
            firstName: this.state.step3Company.firstName,
            lastName: this.state.step3Company.lastName,
            password: this.state.step3Company.password,
            companyName: this.state.step2Company.companyName,
            companyId: "",
            country: this.state.step2Company.country,
            phoneNumber: this.state.step2Company.phoneNumber,
            zipCode: this.state.step2Company.zipCode,
            state: this.state.step2Company.state,
            city: this.state.step2Company.city,
            jobTitle: "",
            industry: this.state.step2Company.industry,
            userRole: constants.USER_ROLES.COMPANY,
          },
          company: {
            name: this.state.step2Company.companyName,
            industry: this.state.step2Company.industry,
            linkedinUrl: this.state.step2Company.linkedinUrl,
            domain: "",
            minimumRatings: 1,
            enrolled: true,
            physicalAddress: {
              country: this.state.step2Company.country,
              city: this.state.step2Company.city,
              state: this.state.step2Company.state,
              zipCode: this.state.step2Company.zipCode,
              phoneNumber: this.state.step2Company.phoneNumber,
              territory: null,
            },
          },
        });
      }
    }

    if (this.state.step === 3.5) {
      this.props.handleSocialSignUp({
        token: this.state.step3Rep.token,
        ...this.props.socialData,
        repType: this.state.functionSelected,
        companyName: this.state.step3Rep.companyName,
        companyId:
          window.localStorage.getItem("companyId") ||
          this.state.invitationCompanyId,
        invitedCompanyId:
          window.localStorage.getItem("companyId") ||
          this.state.invitationCompanyId,
        inviteId: window.localStorage.getItem("inviteId") || "",
      });
      window.history.pushState({}, document.title, "/signup");
      this.setState({ step: 4 });
    }
  };

  handleSignUpFinish = (responseCode) => {
    this.props.resetAccountReducer();
    if (responseCode === 200) {
      this.setState({ redirectLink: "/login" });
    } else {
      this.setState({
        step: 1,
        redirectLink: "",
        userRole: "",
        provider: "",
        errorMessage: "",
        step2Customer: {
          email: "",
          password: "",
        },
        step2Company: {
          companyName: "",
          linkedinUrl: "",
          industry: "",
          country: "",
          city: "",
          state: "",
          zipCode: "",
          phoneNumber: "",
          valid: false,
        },
        step3Customer: {
          firstName: "",
          lastName: "",
          jobTitle: "",
          companyName: "",
          country: "",
          city: "",
          state: "",
          zipCode: "",
          phoneNumber: "",
          valid: false,
          token: "",
        },
        step3Company: {
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          valid: false,
        },
        step4CompanyExisted: false,
        cityList: [],
      });
    }
  };

  handleGetSocialUrl = (providerName) => {
    // console.log("provider Name-->>>", providerName);
    this.props.getSocialUrl(
      providerName,
      this.state.userRole,
      this.state.invitationCompanyId
    );
  };

  handleGetSocialUrlInivited = (providerName) => {
    this.props.getSocialUrlInvited(
      providerName,
      this.state.userRole,
      window.localStorage.getItem("companyId") || this.state.invitationCompanyId
    );
  };

  isGenericEmail = (email) => {
    const emailList = [
      "aol",
      "att",
      "comcast",
      "facebook",
      "gmail",
      "googlemail",
      "hotmail",
      "mail",
      "msn",
      "live",
      "yahoo",
    ];

    const emailDomain =
      email && email.trim() && email.split("@")[1].split(".")[0];
    return emailList.indexOf(emailDomain) >= 0;
  };

  checkingStepComplete = (stepData) => {
    const validPhone =
      stepData && stepData.phoneNumber
        ? stepData.phoneNumber !== "+" && stepData.phoneNumber.length >= 5
        : true; // HACK

    const stepValues = Object.values(stepData);
    return stepValues.indexOf("") === -1 && validPhone;
  };

  render() {
    if (this.state.redirectLink !== "") {
      return <Redirect push={true} to={this.state.redirectLink} />;
    }

    return (
      <View
        {...this.props}
        {...this.state}
        handleBack={this.handleBack}
        handleClose={this.handleClose}
        handleGetSocialUrl={this.handleGetSocialUrl}
        handleGetSocialUrlInivited={this.handleGetSocialUrlInivited}
        handleStepSubmit={this.handleStepSubmit}
        handleRoleSelection={this.handleRoleSelection}
        handleCountryChange={this.handleCountryChange}
        handleInputChange={this.handleInputChange}
        handleRecaptchaChange={this.handleRecaptchaChange}
        handleIndustryChange={this.handleIndustryChange}
        handleCityChange={this.handleCityChange}
        handleSignUpFinish={this.handleSignUpFinish}
        handleBackExit={this.handleBackExit}
        handleFunctionsChange={this.handleFunctionsChange}
      />
    );
  }

  handleFunctionsChange = (filterAverage) => {
    this.setState({
      functionSelected: filterAverage,
    });
  };
}

const mapStateToProps = (state) => ({
  isFetching: state.account.isFetching,
  isFetchingResponse: state.account.isFetchingResponse,
  isSignUpFinish: state.account.isSignUpFinish,
  responseSignUp: state.account.responseSignUp,
  responseUrl: state.account.responseUrl,
  socialData:
    state.account.socialData.statusCode === 200
      ? state.account.socialData.payload
      : state.account.socialData,
  socialDataValid:
    state.account.socialData.statusCode === 200 &&
    Object.keys(state.account.socialData.payload).length > 0,
  companyData:
    state.account.responseAddCompany.statusCode === 200
      ? state.account.responseAddCompany.payload
      : state.account.responseAddCompany,
  companyDataValid: state.account.responseAddCompany.statusCode === 200,
  errorMessage: state.account.errorMessage,
  companyPublicInfo: state.company.companyPublicInfo,
  functions: state.functions,
  functionSelected: state.functionSelected,
  invitedLink: state.invitedLink,
});

const mapDispatchToProps = (dispatch) => ({
  handleSignUp(
    token,
    email,
    firstName,
    lastName,
    password,
    companyName,
    companyId,
    country,
    phoneNumber,
    zipCode,
    state,
    city,
    jobTitle,
    industry,
    userRole
  ) {
    dispatch(
      signUpUser(
        token,
        email,
        firstName,
        lastName,
        password,
        companyName,
        companyId,
        country,
        phoneNumber,
        zipCode,
        state,
        city,
        jobTitle,
        industry,
        userRole
      )
    );
  },
  handleSocialSignUp(socialData) {
    dispatch(socialSignUp(socialData));
  },
  handleAddCompany(
    companyName,
    enrolled,
    industry,
    country,
    city,
    state,
    zipCode,
    phoneNumber,
    linkedinUrl,
    minimumRatings,
    territory
  ) {
    dispatch(
      addCompany(
        companyName,
        enrolled,
        industry,
        country,
        city,
        state,
        zipCode,
        phoneNumber,
        linkedinUrl,
        minimumRatings,
        territory
      )
    );
  },
  getSocialData(socialCode, providerName, userRole) {
    dispatch(getSocialData(socialCode, providerName, userRole));
  },
  getSocialUrl(providerName, userRole, invitationCompanyId) {
    dispatch(getSocialUrl(providerName, userRole, invitationCompanyId, false));
  },
  getSocialUrlInvited(providerName, userRole, invitationCompanyId) {
    dispatch(getSocialUrl(providerName, userRole, invitationCompanyId, true));
  },
  resetAccountReducer() {
    dispatch(resetAccountReducer());
  },
  handleGetCompanyInfoByInviteId(inviteId) {
    dispatch(getCompanyInfoByInvite(inviteId));
  },
  handleGetCompanyPublicInfo(companyId) {
    dispatch(getCompanyPublicInfo(companyId));
  },
  handleSignUpCompany(companyData) {
    dispatch(signUpCompany(companyData));
  },
  handleGetRepList() {
    dispatch(getRepAttrList());
  },
});

export const Unwrapped = SignUp;
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
