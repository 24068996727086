import Axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../components/Buttons/Button";
import Loader from "../../../components/Loader/Loader";
import {
  Body2,
  FormRow,
  ReporaButton,
  reporaColor,
} from "../../../components/global/commonStyles";
import urls from "../../../config";

const Wrapper = styled.div`
  margin: 3%;
  p {
    margin-bottom: 1%;
    margin-right: 10px;
  }
`;
const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  > div {
  }
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
  position: relative;
  width: 300px;

  label {
    font-size: 12px;
    font-weight: bold;
    color: ${reporaColor.black};
    margin-bottom: 5px;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
  }

  &:last-child {
    margin-bottom: 0;
  }

  input {
    font-family: Nexa Bold;
    height: 40px;
    border-radius: 4px;
    border: 1px solid ${reporaColor.warmGrey};
    width: 100%;
    padding: 0 10px;
    font-size: 14px;

    ${(props) =>
      props.invalid
        ? `
      box-shadow: 0 0 5px 1px ${reporaColor.watermelon};
    `
        : null};

    &:focus,
    &:active {
      box-shadow: none;
    }
  }
`;

const AttributesContainer = styled.div`
  margin-bottom: 20px;
  margin-left: 60px;
  position: relative;
  width: 250px;
  div {
    display: flex;
    justifycontent: center;
    alignitems: center;

    label {
      font-size: 12px;
      font-weight: bold;
      color: ${reporaColor.black};
      display: flex;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 15px;
      margin-top: 5px;
      text-align: center;
    }
  }

  label {
    font-size: 12px;
    font-weight: bold;
    color: ${reporaColor.black};
    margin-bottom: 5px;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
  }

  &:last-child {
    margin-bottom: 0;
  }

  input {
    font-family: Nexa Bold;
    height: 40px;
    border-radius: 4px;
    border: 1px solid ${reporaColor.warmGrey};
    width: 100%;
    min-width: 200px;
    padding: 0 10px;
    font-size: 14px;

    ${(props) =>
      props.invalid
        ? `
      box-shadow: 0 0 5px 1px ${reporaColor.watermelon};
    `
        : null};

    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  textarea {
    width: 100%;
    height: 90px;
    border: 1px solid ${reporaColor.warmGrey};
  }
`;

class CreateRoles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      role: {
        name: "",
        attributes1: "",
        attributes2: "",
        attributes3: "",
        attributes4: "",
        attributes5: "",
        attributes6: "",
        attributes7: "",
        attributes8: "",
        attributes9: "",
        attributes10: "",
      },
      isValid: true,
      isLoading: false,
    };
  }

  handleSubmitForm = async () => {
    try {
      const { role } = this.state;
      let allKeys = Object.keys(this.state.role);
      let allValues = [];
      allKeys.map((item, index) => {
        if (item !== "name" && role[item] !== "") {
          allValues.push(role[item]);
        }
      });
      this.setState({ isLoading: true });
      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": window.location.origin,
      };

      let reqOptions = {
        url: `${urls.ROLE_API}/reps/create-role`,
        method: "POST",
        headers: headersList,
        data: {
          repType: this.state.role.name,
          attributes: allValues.join(),
        },
      };

      let response = await Axios.request(reqOptions);
      this.setState({ isLoading: false });
      this.handleCancelEdit();
    } catch (e) {
      this.setState({ isLoading: false });
      console.log(e);
    }
  };

  handleOnChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let roles = { ...this.state.role };

    roles[name] = value;

    this.setState({ role: roles });
  };

  handleCancelEdit = () => {
    this.setState({
      role: {
        name: "",
        attributes1: "",
        attributes2: "",
        attributes3: "",
        attributes4: "",
        attributes5: "",
        attributes6: "",
        attributes7: "",
        attributes8: "",
        attributes9: "",
        attributes10: "",
      },
      isValid: true,
      isLoading: false,
    });
  };

  render() {
    const { role } = this.state;
    return (
      <Wrapper>
        <div style={{ display: "flex", gap: 70 }}>
          <NavLink className="Navlink" to="/admin/dashboard">
            Back to Admin Panel
          </NavLink>
          <Body2>Create Role</Body2>
        </div>

        {this.state.isLoading ? (
          <Loader overlayed size="small" />
        ) : (
          <div
            style={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              marginInline: "auto",
              padding: "30px 20px 20px 20px",
              background: "#fff",
              borderRadius: "12px",
              alignItems: "center",
            }}
            className="content">
            <InputContainer>
              <label>Role Name</label>
              <input
                style={{ borderColor: "#E8E8E8" }}
                name="name"
                type="text"
                value={role.name}
                onChange={(e) => this.handleOnChange(e)}></input>
            </InputContainer>
            <AttributesContainer>
              <label>Standard Attributes :</label>
              <div>
                <label>1</label>
                <input
                  style={{ borderColor: "#E8E8E8" }}
                  name="attributes1"
                  type="text"
                  value={role.attributes1}
                  onChange={(e) => this.handleOnChange(e)}></input>
              </div>
            </AttributesContainer>
            <AttributesContainer>
              <div>
                <label>2</label>
                <input
                  style={{ borderColor: "#E8E8E8" }}
                  name="attributes2"
                  type="text"
                  value={role.attributes2}
                  onChange={(e) => this.handleOnChange(e)}></input>
              </div>
            </AttributesContainer>
            <AttributesContainer>
              <div>
                <label>3</label>
                <input
                  style={{ borderColor: "#E8E8E8" }}
                  name="attributes3"
                  type="text"
                  value={role.attributes3}
                  onChange={(e) => this.handleOnChange(e)}></input>
              </div>
            </AttributesContainer>
            <AttributesContainer>
              <div>
                <label>4</label>
                <input
                  style={{ borderColor: "#E8E8E8" }}
                  name="attributes4"
                  type="text"
                  value={role.attributes4}
                  onChange={(e) => this.handleOnChange(e)}></input>
              </div>
            </AttributesContainer>
            <AttributesContainer>
              <div>
                <label>5</label>
                <input
                  style={{ borderColor: "#E8E8E8" }}
                  name="attributes5"
                  type="text"
                  value={role.attributes5}
                  onChange={(e) => this.handleOnChange(e)}></input>
              </div>
            </AttributesContainer>
            <AttributesContainer>
              <div>
                <label>6</label>
                <input
                  style={{ borderColor: "#E8E8E8" }}
                  name="attributes6"
                  type="text"
                  value={role.attributes6}
                  onChange={(e) => this.handleOnChange(e)}></input>
              </div>
            </AttributesContainer>
            <AttributesContainer>
              <div>
                <label>7</label>
                <input
                  style={{ borderColor: "#E8E8E8" }}
                  name="attributes7"
                  type="text"
                  value={role.attributes7}
                  onChange={(e) => this.handleOnChange(e)}></input>
              </div>
            </AttributesContainer>
            <AttributesContainer>
              <div>
                <label>8</label>
                <input
                  style={{ borderColor: "#E8E8E8" }}
                  name="attributes8"
                  type="text"
                  value={role.attributes8}
                  onChange={(e) => this.handleOnChange(e)}></input>
              </div>
            </AttributesContainer>
            <AttributesContainer>
              <div>
                <label>9</label>
                <input
                  style={{ borderColor: "#E8E8E8" }}
                  name="attributes9"
                  type="text"
                  value={role.attributes9}
                  onChange={(e) => this.handleOnChange(e)}></input>
              </div>
            </AttributesContainer>
            <AttributesContainer>
              <div>
                <label>10</label>
                <input
                  style={{ borderColor: "#E8E8E8" }}
                  name="attributes10"
                  type="text"
                  value={role.attributes10}
                  onChange={(e) => this.handleOnChange(e)}></input>
              </div>
            </AttributesContainer>
            <FormRow
              style={{
                width: "35%",
                minWidth: 300,
                justifyContent: "center",
                gap: "1rem",
                alignItems: "center",
                marginTop: "20px",
              }}>
              <ReporaButton
                style={{ border: "1px solid #0BC9F6" }}
                onClick={this.handleCancelEdit}
                secondary
                small>
                Cancel
              </ReporaButton>
              <Button
                disabled={false}
                size="small"
                onClick={() => this.handleSubmitForm()}>
                Save
              </Button>
            </FormRow>
          </div>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export const Unwrapped = CreateRoles;
export default connect(mapStateToProps, mapDispatchToProps)(CreateRoles);
