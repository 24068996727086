import React from "react";

let componentsInstances = {}

export default function componentLoader(name, loader) {
  if (componentsInstances[name]) return componentsInstances[name];

  componentsInstances[name] = class LazyComponent extends React.Component {

    state = {
      component: null
    };

    componentDidMount() {
      loader()
        .then(component => {
          this.setState({
            component: component.default
          })
        })
    }

    render() {
      if (this.state.component) {
        const ComponentInstance = this.state.component;
        return <ComponentInstance {...this.props} />;
      } else {
        return null;
      }
    }
  }

  return componentsInstances[name];
}
