import React from "react";
import { connect } from "react-redux";
import View from "./components/View";
import { getJsonFromUrl } from "../../components/global/utils";
import { claimRep, viewRep, clearClaimRep } from "../../actions/repActions";
import { TimeFilter, Trends } from "../ViewRep/constants";

class AccountCustomer extends React.Component {
  state = {
    detail: "Example: The customer added me to Repora and gave me a review before I had a Repora account. I just created my own Repora account and want to inherit that customer's review.",
    commentCharLeft: 827,
    isFormValid: true,
    tempRepId: 0,
    claimRepId: 0,
  };

  componentDidMount() {
    if (this.props.isAuthenticated && this.props != null) {
      let queryParams = getJsonFromUrl(this.props.location.search);
      if (!!queryParams.id) {
        this.props.handleRepProfile(queryParams.id, Trends[0], TimeFilter[0]);
        this.setState({
          tempRepId: queryParams.id,
          claimRepId: this.props.authUser.id
        });

      }
    }

  }

  handleBack = () => window.history.back();

  handleFormSubmit = () => {
    if (this.state.isFormValid) {
      let claimReason = this.state.detail;
      let tempRepId = this.state.tempRepId;
      let claimRepId = this.state.claimRepId;
      this.props.handleClaimRep(tempRepId, claimRepId, claimReason);
    }
  }


  handleCommentChange = (event) => {
    let inputValue = event.target.value;
    let charactersLeft = 1000 - inputValue.length;

    if (charactersLeft === 1000) {
      this.setState({
        detail: inputValue,
        commentCharLeft: charactersLeft,
        isFormValid: false
      });
    } else if (charactersLeft >= 0) {
      this.setState({
        detail: inputValue,
        commentCharLeft: charactersLeft,
        isFormValid: true
      });
    }
  }


  render() {
    if (this.props.isAuthenticated) {

      return (
        <View
          {...this.props}
          {...this.state}
          handleCommentChange={this.handleCommentChange}
          handleFormSubmit={this.handleFormSubmit}
          handleBack={this.handleBack}
        />
      );
    } else {
      return ("You have to be logged In.")
    }

  }
}

const mapStateToProps = state => ({
  unclaimedRepProfile: state.rep.repProfile,
  isFetching: state.rep.isFetchingProfileRep,
  authUser: state.auth.userData,
  isAuthenticated: state.auth.isAuthenticated,
  isFetchingClaimRep: state.rep.isFetchingClaimRep,
  errorMessage: state.rep.errorMessage,
  claimResponse: state.rep.claimResponse,
  claimResponseReady: state.rep.claimResponseReady,
});

const mapDispatchToProps = dispatch => ({
  handleRepProfile(id, defaultMetric, defaultTimeFilter) {
    dispatch(viewRep(id, defaultMetric, defaultTimeFilter));
  },
  handleClaimRep(tempRepId, claimRepId, claimReason) {
    dispatch(claimRep(tempRepId, claimRepId, claimReason));
  },
  handleOnFinishClaimRep() {
    dispatch(clearClaimRep());
  }
});

export const Unwrapped = AccountCustomer;
export default connect(mapStateToProps, mapDispatchToProps)(AccountCustomer);
