import React from "react";
import styled from "styled-components";
import { Header3, Body4, reporaColor, ReporaButton } from "../../../components/global/commonStyles";
import { Wrapper, HeaderWrapper, FormWrapper } from "./CommonStyles";

const MessageWrapper = styled(FormWrapper)`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageWrapper = styled.div`
  max-width: 450px;
  width: 100%;
  margin-bottom:30px;
  display: flex;
  justify-content: center;
  margin: 50px 0;

  img:first-child {
    margin-right: 10px;
  }
`;

class StepCompanyExisted extends React.Component {
  render() {
    return (
      <Wrapper>
        <HeaderWrapper>
          <Header3 textAlign="center">
            Looks like your company <br></br> already has an account
          </Header3>
        </HeaderWrapper>
        <MessageWrapper>
          <ImageWrapper>
            <img src="/assets/global/company.svg" alt="company-logo" />
          </ImageWrapper>
          <Body4 textAlign="center" textColor={reporaColor.brightSkyBlue}>
            An email has been sent to your company’s account administrator where they can take further steps.
          </Body4>
        </MessageWrapper>
        <ReporaButton className="m-b-30" onClick={() => this.props.handleSignUpFinish(400)}>Okay</ReporaButton>
      </Wrapper>
    );
  }
};

export default StepCompanyExisted;
