import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import styled from "styled-components";
import { loginAdmin } from "../../actions/authActions";
import View from "./login/View";

const Wrapper = styled.div` 
`;

class Admin extends React.Component {
  state = {
    companiesList: [],
  };

  componentDidMount() { }

  componentWillReceiveProps(newprops) { }

  render() {
    return (
      <Wrapper>
        <View {...this.props} handleLogin={this.props.handleLogin} />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.auth.isFetching,
  loginErrorMessage: state.auth.loginErrorMessage,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
  handleLogin(email, password, role, history, redirectPath) {
    dispatch(loginAdmin(email, password, role, history, redirectPath));
  },
});

export const Unwrapped = Admin;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);
