import React from "react"
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash"
import OutsideClickHandler from "react-outside-click-handler";

import { reporaDevice, reporaColor } from "../../../components/global/commonStyles";
import EmailSvg from "../components/assets/mail.svg";
import constants from "../../../constants";


const DropdownOptions = styled.ul`
  position: absolute;
  top: 44px;
  left: -1px;
  box-sizing: border-box;
  border-radius: 3px;
  width: auto;
  margin: 0;
  padding: 10px 20px;
  list-style-type: none;
  background-color: ${reporaColor.grey91};
  border-top: none;
  z-index: 1;
  min-width: 237px;
  max-height: 200px;
  max-width: calc(100vw - 30px);
  overflow: auto;

  @media ${reporaDevice.desktop} {
    left: -172px;
  }

  li {
    width: 100%;
    height: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

const EmailButton = styled.div`
  height: 40px;
  width: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: solid 1px ${reporaColor.grey91};
  background-color: white;
  cursor: pointer;
`;
// const toopTipMessage = styled.div`
//   justify-content:middle;
// `;

class ContactOptions extends React.Component {
  state = {
    showOptions: false
  }

  render() {
    const { showOptions } = this.state;
    const { repName, options, handleOptionClick } = this.props
     let toopTipMessage="Private message to <br/>" + repName+ "<br />" ;
      if(options.length>1){
        toopTipMessage="Private message to <br />"+repName+ " or <br />" + repName +"'s company <br />";
      }
    return (
      <OutsideClickHandler onOutsideClick={showOptions ? this.handleShowContactOptions : _.noop}>
        <EmailButton 
          onClick={this.handleShowContactOptions}
          data-tip={toopTipMessage}
          data-multiline="true"
          data-place="left"
          data-position="fixed"
          data-tip-disable={constants.IS_MOBILE}>
          <img src={EmailSvg} alt="email" />
        </EmailButton>

        {
          showOptions &&
          <DropdownOptions>
            {options.map((opt, index) => {
              if (opt) {
                return (
                  <li key={opt.id} onClick={_.partialRight(handleOptionClick, opt)}>
                    <span>{opt.label}</span>
                  </li>
                );
              }
              return null;
            })}
          </DropdownOptions>
        }
      </OutsideClickHandler>
    )
  }

  handleShowContactOptions = () => {
    const { showOptions } = this.state;
    this.setState({ showOptions: !showOptions })
  }
}

ContactOptions.propTypes = {
  options: PropTypes.array.isRequired,
  handleOptionClick: PropTypes.func.isRequired
}

export default ContactOptions
