import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Body4, reporaColor, reporaDevice } from "../../../components/global/commonStyles";
import { HeaderWrapper, FormWrapper } from "../../SignUp/components/CommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Button from "../../../components/Buttons/Button";

export const Wrapper = styled.div`
  max-width: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 80px auto 20px;
  position: relative;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 #00000033;
  padding: 10px;

  @media ${reporaDevice.tablet} {
    max-width: 502px;
    padding: 35px;
  }
`;

const MessageWrapper = styled(FormWrapper)`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 10px;
  }
`;

const ImageWrapper = styled.div`
  max-width: 450px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  align-items: center;

  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  svg {
    color: ${reporaColor.brightSkyBlue};
    margin: 10px;
    font-size: 20px;
  }
`;

const Header = styled.h2`
  font-size: 30px;
  text-align: center;
`;

const RateConfirmation = props => {
  const [image , setImage] = useState("/assets/global/userIcon.svg")

  function checkIfImageExists(url, callback) {
    const img = new Image();
    img.src = url;
    
    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };
      
      img.onerror = () => {
        callback(false);
      };
    }
  }
  
  
  // USAGE
  useEffect(()=>{
    checkIfImageExists(props.authUserPicture, (exists) => {
      if (exists) {
        setImage(props.authUserPicture)
      } else {
        console.error('Image does not exists.')
      }
    });
  },[props.authUserPicture])

  return (
    <Wrapper>
      <HeaderWrapper>
        <Header>
          {props.responseMessage.statusCode === 200 ? "Your claim was submitted" : props.responseMessage.message}
        </Header>
      </HeaderWrapper>
      <MessageWrapper>
        {props.responseMessage.statusCode === 200
          && <ImageWrapper>
            <img src={ image } alt="" /> <FontAwesomeIcon icon={faArrowLeft} /> <img src={"/assets/global/userIcon.svg"} alt="" />
          </ImageWrapper>
        }
        <Body4 textAlign="center" textColor={`${reporaColor.brightSkyBlue}`}>
          {props.responseMessage.statusCode === 200 && "Our team will review your claim and notify you of the result."}
        </Body4>
      </MessageWrapper>
      <Link style={{ textDecoration: "none" }} to="/viewreppersonal" onClick={props.handleOnFinishClaimRep}><Button>Okay</Button></Link>
    </Wrapper>
  );
};

export default RateConfirmation;
