import { isEmpty, isEqual } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import {
  getProgramData,
  handleSubmitRating,
  resetRateCatogery,
} from "../../actions/companyActions";
import { requestAttrList } from "../../actions/reportingPreferencesAction";
import View from "./components/View";

class RateProgram extends React.Component {
  state = {
    redirectLink: "",
    programData: null,
    reviewRequestedBy: "",
    spinner: true,
  };

  componentDidMount() {
    const { handleResetRateProgram, location } = this.props;
    let data = location.search.split("/");

    const programId = data[0].split("=")[1];
    const reviewRequestedById = data[1].split("=")[1];
    this.setState({ reviewRequestedBy: reviewRequestedById });
    this.setState({ spinner: true });
    this.props.handlegetprogramdata(programId);
    handleResetRateProgram();
    this.setState({ spinner: false });
  }

  componentDidUpdate(prevProps) {
    // this.setState({ spinner: true });
    if (
      !isEqual(this.props.programData, prevProps.programData) &&
      !isEmpty(this.props.programData)
    ) {
      this.setState({ programData: this.props.programData });

      if (!isEmpty(this.props.programData)) {
        this.setState({ spinner: true });
        this.props.handleGetCustomeAttr({
          companyId: this.props.programData.company.id,
          companyFeedbackCategory: "PROGRAM",
        });
        this.setState({ spinner: false });
      }
    }
    // ;
  }

  handleRateDone = () => {
    const { handleResetRateProgram } = this.props;
    handleResetRateProgram();
    this.setState({ redirectLink: "/home" });
  };

  render() {
    if (this.state.redirectLink !== "") {
      return <Redirect push={true} to={this.state.redirectLink} />;
    }
    let userdataExist = false;
    if (this.props.userData) {
      userdataExist = true;
    }

    return (
      <View
        {...this.props}
        programData={this.state.programData}
        handleRateDone={this.handleRateDone}
        reviewRequestedBy={this.state.reviewRequestedBy}
        userdataExist={userdataExist}
        userData={this.props.userData}
        spinner={this.props.isAtributesLoading}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
  programRateResponse: state.company.rateResponse,
  programRateResponseReturned: state.company.rateResponseReady,
  programDataFetching: state.company.fetchingProgramData,
  isFetchingRateProgram: state.company.isFetchingRateProgram,
  programData: state.company.programData,
  customeAttributes: state.company.getcompanyCustomeAttributes,
  attributesList: state.reportingPreferences.attributesList,
  customeAttributesFetching: state.company.getCompanyCustomeAttributesFetching,
  companylogBlob: state.company.ratingPageCompanyLogo,
  isAtributesLoading: state.reportingPreferences.isAtributesLoading,
});

const mapDispatchToProps = (dispatch) => ({
  handleResetRateProgram() {
    dispatch(resetRateCatogery());
  },
  handlegetprogramdata(id) {
    dispatch(getProgramData(id));
  },
  handleSubmitRating(obj) {
    dispatch(handleSubmitRating(obj));
  },
  handleGetCustomeAttr(id) {
    dispatch(requestAttrList(id));
  },
});

export const Unwrapped = RateProgram;
export default connect(mapStateToProps, mapDispatchToProps)(RateProgram);
