import React, { useState, useEffect } from "react";
import UpvoteIcon from "./assets/upvotes-green.svg";
import UpvoteGreyIcon from "./assets/upvotes-grey.svg";
import styled from "styled-components";
import {
  reporaColor,
  Body4,
  Body5,
  ReporaButton,
} from "../../../components/global/commonStyles";
import { TooltipWrapper, LikesWrapper, UpvoteRepButton } from "./FeedbackCard";
import TextArea from "../../../components/Inputs/TextArea";
import Loader from "../../../components/Loader/Loader";
import { parseDate, formatDate } from "../../../components/global/utils.js";

export const RenderFeedbackResponse = (props) => {
  const [showEditForm, updateShowEditForm] = useState(false);
  const [showCancelConfirm, updateShowCancelConfirm] = useState(false);

  useEffect(
    () => {
      updateShowEditForm(props.editRespone);
    },
    [props.editRespone]
  );

  useEffect(() => {
    if (
      props.latestEditFeedback &&
      document.getElementById(`Wrapper-${props.latestEditFeedback}`)
    ) {
      document
        .getElementById(`Wrapper-${props.latestEditFeedback}`)
        .scrollIntoView();
      props.onEditResponseClick(null);
    }
  }, []);

  let _feedList = props.data.feedbackResponseList;
  let isvotedFlag = false;
  const showTooltip = props.showTooltip;
  const disableSave = !(props.replayComment.trim() !== "");
  let name = props.authUser
    ? props.authUser.firstName + "  " + props.authUser.lastName
    : _feedList?.reviewerName;
  if (_feedList && props.authUser) {
    _feedList = _feedList[0];
    if (_feedList.upVoted && _feedList.upVoted.length > 0) {
      _feedList.upVoted.forEach((ele) => {
        if (ele.customerId == props.authUser.id) {
          isvotedFlag = true;
        }
      });
    }
    name = _feedList.reviewerName;
  } else if (_feedList) {
    name = _feedList[0].reviewerName;
  }

  let _feedBackRepCustomerData = props.data.feedbackResponseList
    ? props.data.feedbackResponseList[0]
    : "";

  const feedBackResponseVotes = () => {
    return props.data.feedbackResponseList
      ? props.data.feedbackResponseList[0]["upVotes"]
      : 0;
  };

  const getFormattedDate = (date) => {
    const parsedDate = parseDate(date);
    if (parsedDate) {
      return formatDate(parsedDate);
    } else {
      return "Invalid Date";
    }
  }

  const renderEditButtonsBlock = () => {
    if (props.repFeedBack.length > 0 && !showEditForm) {
      return (
        <>
          <Section3 id="section3">
            {!(
              props.location && props.location.pathname == "/viewrepcustomer"
            ) && (
              <>
                {props.authUser && (
                  <>
                    <ReporaButton
                      className="m-r-10"
                      style={{ backgroundColor: "transparent" }}
                      width="76px"
                      white
                      small
                      height="32px"
                      onClick={() => {
                        updateShowEditForm(true);
                        props.handleEditReplay();
                      }}
                    >
                      {" "}
                      Edit
                    </ReporaButton>
                  </>
                )}
                <ReporaButton
                  small
                  width="76px"
                  height="32px"
                  white
                  style={{ backgroundColor: "transparent" }}
                  onClick={props.handleShowTooltip}
                >
                  Delete
                  {showTooltip && props.renderTooltip()}
                  {props.deleteCustomerRepFeedBackLoading && (
                    <Loader overlayed size={"small"} />
                  )}
                </ReporaButton>
              </>
            )}
          </Section3>
        </>
      );
    } else {
      return (
        <>
          <Section3 id="section3">
            {showCancelConfirm ? (
              <>
                <CancelDiv id="CancelDiv">
                  <div> Are you sure? </div>
                  <ReporaButton
                    className="m-r-10"
                    width="76px"
                    white
                    small
                    height="32px"
                    onClick={() => {
                      updateShowEditForm(false);
                      updateShowCancelConfirm(false);
                      props.handleReplay(false)
                    }}
                  >
                    Cancel
                  </ReporaButton>
                </CancelDiv>
              </>
            ) : (
              <ReporaButton
                className="m-r-10"
                width="76px"
                white
                small
                height="32px"
                onClick={() => updateShowCancelConfirm(true)}
              >
                {" "}
                Cancel
              </ReporaButton>
            )}
            {props.repFeedBack.length > 0 ? (
              <ReporaButton
                small
                width="140px"
                height="32px"
                disabled={disableSave}
                onClick={props.handleUpdateResponse}
              >
                Save
                {props.updateCustomerRepFeedBackLoading && (
                  <Loader overlayed size={"small"} />
                )}
              </ReporaButton>
            ) : (
              <ReporaButton
                small
                width="140px"
                height="32px"
                disabled={disableSave}
                onClick={props.handleSubmitResponse}
              >
                Submit response
                {props.addCustomerRepFeedBackLoading && (
                  <Loader overlayed size={"small"} />
                )}
              </ReporaButton>
            )}
          </Section3>
        </>
      );
    }
  };

  if (
    props.authUser &&
    props.authUser.userRole !== "CUSTOMER" &&
    props.data.disputeStatus === "DISPUTE_APPROVED"
  ) {
    return <></>;
  }
  return (
    (showEditForm || !!(props.repFeedBack && props.repFeedBack.length > 0)) && (
      <>
        <CustRepWrapper id="CustRepWrapper">
          <Main id="main">
            <Section1>
              <div>
                <span>
                  <Body5 textColor={reporaColor.warmGrey}>{name}</Body5>
                </span>
                <Body5 textColor={reporaColor.warmGrey}>
                  Responding to {props.data.name}
                </Body5>
              </div>
              <div>
                <Body5 textColor={reporaColor.warmGrey}>
                  {getFormattedDate(props.repFeedBack.length > 0
                      ? _feedBackRepCustomerData.createdAt
                      : new Date())}
                </Body5>
              </div>
            </Section1>
            {props.repFeedBack.length <= 0 && (
              <>
                <div
                  style={{
                    fontFamily: "Nexa Bold",
                    fontSize: "13px",
                    lineHeight: "15px",
                    color: "#0BC9F6",
                    userSelect: "none",
                    padding: "9px",
                  }}
                >
                  Your response will be visible externally so please consider a
                  professional & courteous response. The customer will not be
                  able to reply to your response to avoid a back/forth public
                  debate.
                </div>
              </>
            )}
            <Section2>
              <div id="leftcontainer">
                {props.authUser && _feedList && props.data.feedbackResponseList[0]?.upVotes !== 0 && (
                  <LikesWrapper>
                    <TooltipWrapper
                      data-tip="Number of upvotes"
                      data-effect="solid"
                    >
                      <img src={UpvoteIcon} alt="upvote" />
                      <Body4 textColor={reporaColor.greenBlue}>
                        {feedBackResponseVotes()}
                      </Body4>
                    </TooltipWrapper>
                  </LikesWrapper>
                )}
              </div>
              <div id="rightcontainer">
                {showEditForm ? (
                  <TextArea
                    style={{ backgroundColor: "FFFFFF" }}
                    maxLength="1000"
                    rows="6"
                    onChange={props.handlereplayChange}
                    value={props.replayComment}
                    name="comment"
                    counter
                  />
                ) : (
                  <Body5 textColor={reporaColor.warmGrey}>
                    {props.repFeedBack}
                  </Body5>
                )}
              </div>
            </Section2>
            {props.authUser && props.authUser.userRole !== "CUSTOMER" ? (
              renderEditButtonsBlock()
            ) : (
              <>
                <Section3 id="section3">
                  <UpvoteRepButton
                    onClick={() => props.onUpvoteRepClick()}
                    data-tip={
                      isvotedFlag
                        ? "Click to remove your upvote"
                        : "Upvote if you agree <br/>or found this useful"
                    }
                    data-multiline="true"
                    data-effect="solid"
                  >
                    <img
                      src={isvotedFlag ? UpvoteIcon : UpvoteGreyIcon}
                      alt="upvote"
                    />
                  </UpvoteRepButton>
                </Section3>
              </>
            )}
          </Main>
        </CustRepWrapper>
      </>
    )
  );
};

const CustRepWrapper = styled.div`
  background: white;
  padding: 25px 20px;
  flex-direction: column;
  flex-shrink: 0;
  flex-wrap: wrap;
`;

const Main = styled.div`
  width: 100%;
  background-color: rgb(250, 250, 250);
  margin-top: 10px;
  padding: 2%;
`;

const Section1 = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 10px >div >span >p {
    color: #9b9b9b;
    font-size: 14px;
  }
`;

const Section2 = styled.div`
  width: 100%;
  position: relative;
  word-wrap: break-word;
  overflow-x: hidden;
  #leftcontainer {
    float: left;
    width: 75%;
    background-color: transparent;
    margin-left: 1%;
    position: fixed;
    display: inline-block;
  }
  #rightcontainer {
    position: relative;
    float: right;
    width: 75%;
    display: inline-block;

    > p {
      background-color: rgb(250, 250, 250);
      min-height: 50px;
      max-height: 150px;
    }
  }

  > div {
    background-color: rgb(255, 255, 255);
  }
`;
const Section3 = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2%;
`;

const CancelDiv = styled.div`
  display: flex;
  > div {
    padding: 8px;
    color: #ff2156;
  }
  > a {
    background-color: #ff2156;
    color: #ffffff;
  }
`;

export default RenderFeedbackResponse;
