import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { getClaimList, updateClaimStatus } from "../../../actions/adminActions";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Loader from "../../../components/Loader/Loader";
import { Body2, Body4 } from "../../../components/global/commonStyles";
import View from "./components/View";

const Wrapper = styled.div`
  margin: 3%;
  p {
    margin-bottom: 1%;
    margin-right: 10px;
  }
`;
const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  > div {
  }
`;

class ManageClaims extends React.Component {
  state = {
    companiesList: [],
    claimStatus: "Pending",
    headers: ["Claim Date", "Claimed Rep ID", "Claimed Rep Name", "Claiming Rep"],
    fromClaims:true,
  };

  componentDidMount() {
    this.props.handleGetClaimList({claimStatus:"PENDING"});
  }

  handleStatusChange = (ele) => {
    if (ele === "Accepted") {
      this.props.handleGetClaimList({claimStatus: 'APPROVED'});
    } else if (ele === "Rejected") {
      this.props.handleGetClaimList({claimStatus: 'REJECT'});
    } else {
      this.props.handleGetClaimList({claimStatus: 'PENDING'});
    }

    this.setState({ claimStatus: ele });
  };
  render() {
    return (
      <>
        <div style={{margin:"3%"}}><NavLink className='Navlink' to = "/admin/dashboard">Back to Admin Panel</NavLink></div>
        <Wrapper>
          <Body2>Manage Claims</Body2>
          <WrapperHeader>
            <Body4> Claims being shown: </Body4>

            <Dropdown
              className="m-b-15"
              options={["Pending", "Accepted", "Rejected"]}
              value={this.state.claimStatus}
              onClick={this.handleStatusChange}
            />
          </WrapperHeader>
          {this.props.fetchingClaims ? (
            <Loader overlayed size="small" />
          ) : (
            <View 
            {...this.props}
            {...this.state}
            />
          )}
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  reviewerId: state.auth.userData,
  claimsList : state.admin.claimsList,
  fetchingClaims : state.admin.fetchingClaims,
  claimsListErr : state.admin.claimsListErr,

  updateClaimStatusReq : state.admin.updateClaimStatusReq
});
const mapDispatchToProps = (dispatch) => ({
  handleGetClaimList(status){
    dispatch(getClaimList(status))
  },
  handleUpdateClaimStatus(obj , status){
    dispatch(updateClaimStatus(obj , status))
  }
});

export const Unwrapped = ManageClaims;
export default connect(mapStateToProps, mapDispatchToProps)(ManageClaims);
