import React from "react";
import styled from "styled-components";
import { reporaColor, ReporaButton, reporaSize, Header2, Body4 } from "../../../components/global/commonStyles";
import RepHeroBgImage from "../assets/Repora_Rep_Hero_BGimage_new.jfif";
import RepModuleBgImage from "../assets/Repora_ForReps_Module3_BGimage.jpg";
import Hero from "../../../components/HeroUpdated/Hero";
import PanelColumns from "../../../components/PanelColumnsUpdated/PanelColumns";
import PanelImage from "../../../components/PanelImageUpdated/PanelImage";
import Footer from "../../../components/FooterUpdated/Footer";
import Data from "./Data";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PageSection = styled.section`
  width: 100%;
  max-width: 100%;
  height: auto !important;
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: ${props => props.bkgImage ? `url(${props.bkgImage})` : "none"};
  background-color: ${props => props.bkgColor || "none" };
  background-position: ${props => props.isFooter || props.arch ? "top" : "center center"};
	background-repeat: no-repeat;
	background-size: cover;
  padding: ${props => props.isHero ? "8% 30% 8% 10%" : "5% 15%"};
  color: ${props => props.txtColor || "inherit"};

  ${props => props.isFooter ? "margin-top: -6%;" : null}

  @media (max-width: ${reporaSize.medium}) {
		padding-left: ${props => props.isHero ? "15%" : "5%"};
    padding-right: ${props => props.isHero ? "15%" : "5%"};

    ${props => props.isFooter ? "margin-top: -8%;" : null}
	}

  @media (max-width: ${reporaSize.small}) {
    ${props => props.isFooter ? "margin-top: -15%;" : null}
	}
`;

const ProsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: ${reporaSize.small}) {
		flex-direction: column;
	}

  h2 {
    font-size: 35px;
    line-height: initial;
    margin-bottom: 5%;

    @media (max-width: ${reporaSize.small}) {
      font-size: 20px;
      text-align: center;
    }
  }

  p {
    font-size: 16px;
    line-height: initial;
    margin-bottom: 5%;

    @media (max-width: ${reporaSize.small}) {
      font-size: 12px;
      text-align: center;
    }
  }
`;

const ReputationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-right: 5%;

  @media (max-width: ${reporaSize.small}) {
    width: 100%;
    margin-bottom: 5%;
    align-items: center;
    padding-right: 0;
	}

  img {
    border-radius: 10px;
    max-width: 100%;
  }
`;

const OpportunitiesContianer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 5%;

  @media (max-width: ${reporaSize.small}) {
    width: 100%;
	}
`;

const KnowledgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const KnowledgeItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${reporaColor.white};
  padding: 5%;
  border: 1px solid ${reporaColor.grey87};
  border-radius: 10px;
  margin-bottom: ${props => props.isLastItem ? "0px" : "20px"};

  @media (max-width: ${reporaSize.medium}) {
		margin-bottom: ${props => props.isLastItem ? "0px" : "10px"};
	}

  p {
    margin: 0;
  }
`;

const KnowledgeTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 47%;
  margin-right: 3%;
  word-break: break-word;
`;

const KnowledgeImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  margin-right: 5%;
  
  img {
    height: 30px;
  }
`;

const KnowledgeRating = styled.div`
  display: flex;
  flex-direction: column;
  width: 10%;
  align-items: end;
`;

const ModuleContainer = styled.div`
  padding: 0 10%;

  h2 {
    color: ${reporaColor.aquaBlue};
    font-size: 18px;
  }

  h4 {
    font-size: 35px;
    margin: 40px 0;

    @media (max-width: ${reporaSize.medium}) {
      font-size: 20px;
    }
  }
`

const CompareContainer = styled.div`
  margin-bottom: 70px;
`

const CustomPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h2 {
    font-size: 35px;
    line-height: initial;
    margin-bottom: 3%;

    @media (max-width: ${reporaSize.small}) {
      font-size: 20px;
    }
  }
`;

const View = () => {

  return (
    <Wrapper>
      <PageSection bkgImage={RepHeroBgImage} isHero>
        <Hero textColor={reporaColor.white} data={Data.hero} alignLeft contentWidth="50%" subtitleWidth="80%" />
      </PageSection>

      <PageSection>
        <ProsContainer>
          <ReputationContainer>
            <Header2>{Data.pros.reputation.header}</Header2>
            <Body4>{Data.pros.reputation.description}</Body4>
            <img src={Data.pros.reputation.imgSrc} alt=""/>
          </ReputationContainer>
          <OpportunitiesContianer>
            <Header2>{Data.pros.opportunities.header}</Header2>
            <Body4>{Data.pros.opportunities.description}</Body4>
            <KnowledgeContainer>
              {Data.pros.opportunities.knowledge && Data.pros.opportunities.knowledge.length > 0 ? (
                Data.pros.opportunities.knowledge.map((item, index) => (
                  <KnowledgeItem isLastItem={index === Data.pros.opportunities.knowledge.length - 1}>
                    <KnowledgeTitle>
                      <Body4>{item.title}</Body4>
                    </KnowledgeTitle>
                    <KnowledgeImage>
                      <img src={item.imgSrc} alt=""/>
                    </KnowledgeImage>
                    <KnowledgeRating>
                      <Body4>{item.rating}</Body4>
                    </KnowledgeRating>
                  </KnowledgeItem>
                ))
              ) : null}
            </KnowledgeContainer>
          </OpportunitiesContianer>

        </ProsContainer>
      </PageSection>

      <PageSection bkgImage={RepModuleBgImage} txtColor={reporaColor.white}>
        <ModuleContainer>
          <PanelColumns data={Data.performance} noHeadingMargin />
        </ModuleContainer>
      </PageSection>

      <PageSection>
        <CompareContainer>
          <PanelImage data={Data.compare} contentPosition="left" imageWidthPercent={65}></PanelImage>
        </CompareContainer>
      </PageSection>

      <PageSection bkgImage="/assets/home/footer-bgm.png" isFooter>
        <CustomPanel>
          <Header2 textAlign="center" textColor={`${reporaColor.black}`}>Start Using Repora Today!</Header2>
          <ReporaButton href="/contactus" secondary>Contact Us</ReporaButton>
        </CustomPanel>
      </PageSection>

      <Footer />
    </Wrapper>
  );
};

export default View;
