import axios from "axios";
import { get } from "lodash";
import config from "../config";
import constants from "../constants";
import authdAxios from "../modules/Authentication/utils/authdAxios";

const AdminService = () => {
  const getAnalyticsCount = () => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.ADMIN_API}/api/admin/analytics/count`, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  };
  const getAnalyticsReviewsCount = () => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.ADMIN_API}/api/admin/analytics/reviews-count`, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  };

  const getCompanyFeedbacks = (id) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.ADMIN_API}/api/admin/get-customer-reviews`, id, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  };

  const getClaimList = (status) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.ADMIN_API}/api/admin/get-rep-claims-list`, status, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  };

  const updateClaimStatus = (obj) => {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.ADMIN_API}/api/admin/admin-claim-action`, obj, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  };

  function getCustomerSuggestions(customerName, Id) {
    let obj = { name : customerName }
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.ADMIN_API}/api/admin/get-customer-suggestion-list`,
          obj,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }
  
  function getUnclaimedReps() {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.ADMIN_API}/api/admin/get-unclaimed-reps`,
          [],
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function getRepsAccounts() {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.ADMIN_API}/api/admin/get-rep-email-id-data`,
          [],
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }


  function getPartnerKeys() {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/partner-keys-data`,
          [],
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function getCompanyKeys() {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/company-keys-data`,
          [],
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function addNewPartner(obj) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/add-partner-api-key`,
          obj,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }
  
  function addCompanyKey(obj) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/add-company-api-key`,
          obj,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function deletePartnerKey(obj) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/delete-partner-api-key`,
          obj,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function deleteCompanyKey(obj) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${config.COMPANY_API}/api/company/delete-company-api-key`,
          obj,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function updatePartnerKey(obj) {
    //added but not using
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/company/update-partner-key`,
          obj,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function updateCompanyKey(obj) {
    //added but not using
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/company/update-company-api-key`,
          obj,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }
  
  

  return {
    getAnalyticsCount,
    getAnalyticsReviewsCount,
    getCompanyFeedbacks,
    getClaimList,
    updateClaimStatus,
    getCustomerSuggestions,
    getUnclaimedReps,
    getRepsAccounts,
    getPartnerKeys,
    getCompanyKeys,
    addNewPartner,
    addCompanyKey,
    deletePartnerKey,
    deleteCompanyKey,
    updatePartnerKey,
    updateCompanyKey
  };
};

export default AdminService();
