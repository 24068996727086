import React from "react";
import styled from "styled-components";

import Loader from "../../../components/Loader/Loader";

import {
  reporaDevice,
  reporaColor,
  Body5,
} from "../../../components/global/commonStyles";
import RatingStars from "../../../components/RatingStars/RatingStars";
import DefaultAvatarSvg from "../../../assets/global/default-avatar.svg";

const Wrapper = styled.div`
  position: relative;
  background-color: ${reporaColor.white};
  ${(props) =>
    props.privateCompany
      ? `justify-content: center;
  display: grid; `
      : `display: flex;`};
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.08);
  padding: 20px;

  @media ${reporaDevice.xs} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const ImageWrapper = styled.div`
  img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    object-position: center;
  }
`;

const InformationWrapper = styled.div`
  ${(props) =>
    props.privateCompany ? `margin-left: 0px;` : `margin-left: 20px`};
`;

const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;

  p {
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
  }
`;

const RepName = styled.p`
  display: inline;
  font-size: 16px;
  font-weight: bold;
`;

const PersonCard = (props) => {
  // let companyAddress = "No address found";
  // let territory = "";
  // if (props.repData.companyAddress) {
  //   try {
  //     const city = props.repData.companyAddress.city || "";
  //     const state = props.repData.companyAddress.state || "";
  //     const zipCode = props.repData.companyAddress.zipCode || "";
  //     companyAddress = `${city}, ${state} ${zipCode} `;
  //     territory = props.repData.companyAddress.territory
  //       ? ` ${props.repData.companyAddress.territory}`
  //       : "";
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  const repFullName = `${props.repData.firstName || "..."} ${
    props.repData.lastName || ""
  }`.trim();
  const companyName = props.repData.currentCompany || "No company found";
  // const companySpeciality = props.repData.unclaimed
  //   ? ""
  //   : props.repData.productSpeciality || "No";
  let nohiderating = true;
  if (
    props.repData.currentCompanyConnected === true &&
    props.repData.currentCompanyPrivate === true
  ) {
    nohiderating = false;
  }
  let rating = null;
  if (props.userData) {
    (() => {
      if (nohiderating) {
        return (rating = (
          <RatingWrapper>
            <RatingStars static rating={props.repData.average || 0} />
            <p>
              {props.repData.average ? props.repData.average.toFixed(1) : 0}
            </p>
          </RatingWrapper>
        ));
      }
    })();
  }

  return (
    <Wrapper privateCompany={props.repData?.currentCompanyPrivate}>
      {props.isFetching && <Loader overlayed size="medium" />}

      {props.repData?.currentCompanyPrivate ? (
        <>
          <InformationWrapper
            privateCompany={props.repData?.currentCompanyPrivate}
          >
            <RepName>{repFullName}</RepName>
            {rating}
            {companyName && companyName !== "Not Available" && (
              <Body5 textColor={reporaColor.warmGrey}>{companyName}</Body5>
            )}
            {/* <Body5 textColor={reporaColor.warmGrey}>{companyAddress}</Body5>
        <Body5 textColor={reporaColor.warmGrey}>{territory}</Body5> */}

            {/* {companySpeciality === "No" ? "" : <Body5 textColor={reporaColor.warmGrey}>{companySpeciality}</Body5>} */}
          </InformationWrapper>
          <ImageWrapper>
            <img
              src={props.repData.photoLinkedin || "/assets/global/userIcon.svg"}
              alt="profile"
              onError={(event) => {
                event.target.onerror = null;
                event.target.src = DefaultAvatarSvg;
              }}
            />
          </ImageWrapper>
        </>
      ) : (
        <>
          <ImageWrapper>
            <img
              src={props.repData.photoLinkedin || "/assets/global/userIcon.svg"}
              alt="profile"
              onError={(event) => {
                event.target.onerror = null;
                event.target.src = DefaultAvatarSvg;
              }}
            />
          </ImageWrapper>

          <InformationWrapper
            privateCompany={props.repData?.currentCompanyPrivate}
          >
            <RepName>{repFullName}</RepName>
            {rating}
            {companyName && companyName !== "Not Available" && (
              <Body5 textColor={reporaColor.warmGrey}>{companyName}</Body5>
            )}
            {/* <Body5 textColor={reporaColor.warmGrey}>{companyAddress}</Body5>
        <Body5 textColor={reporaColor.warmGrey}>{territory}</Body5> */}

            {/* {companySpeciality === "No" ? "" : <Body5 textColor={reporaColor.warmGrey}>{companySpeciality}</Body5>} */}
          </InformationWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default PersonCard;
