import React from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import styled ,{ css } from "styled-components";
import {Body4,Header4, reporaColor,FormGroup } from "../../../components/global/commonStyles";
import { Wrapper } from "./CustomerReviews";
import Loader from "../../../components/Loader/Loader";
import { getUnclaimedReps, getRepsAccounts } from "../../../actions/adminActions";
import moment from "moment";

const WrapperSection = styled.div`
  border-bottom: 1px solid lightgrey;
  display: flex; 
  align-items: center;
  justify-content:space-between;
  margin-left: 15px;

  input{
    display: flex;
    align-items: center;
    justify-content:space-around;
    max-width: 350px;
    margin : 10px 0px;
  }
`;

const Row = styled.div`
  background: white;
  display: flex;
  height: 60px;
  max-width: 1010px;
  border-bottom: 1px solid ${reporaColor.grey91};
  background: ${reporaColor.grey98};

  > div {
    flex: 1;
    font-size: 15px;
    padding-left: 20px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    background: ${reporaColor.grey98};
    &:last-child {
      border-right: none;
      min-width: 100px;
    }
    min-width: 200px;
  }
  > span {
    right: 1%;
    position: relative;
    align-items: center;

    top: 31%;
  }
`;

const ListHeader = styled.div`
display: flex;
height: 60px;
max-width: 931px;;
margin-left:28px;
> div {
  font-size: 14px;
  color: ${reporaColor.warmGrey};
  flex: 1;
  display:flex;
  align-items: center;
  min-width: 190px;
  &:last-child {
    min-width: 70px;
  }
  &:first-child {
    position: relative;
    left: -10px;
  }
  span{
    cursor:pointer;
  }
}
`;

const List = styled.div`
  overflow:auto;
  box-shadow: 0 0 8px 0 #00000033;
  max-width:1010px;
`;

class UnclaimedReps extends React.Component {
    state = {
        selectedRep : null,
        repSuggestions : [],
        show :false,
        repEmails : [],
        repDetails : {},
        showingRealReps : false,
    }

    componentDidMount(){
      this.props.getUnclaimedReps()
      this.props.getRepsAccounts()
    }

    componentDidUpdate(prevProps){
        const { repSuggestions , repEmails } = this.props
        if (repSuggestions !== prevProps.repSuggestions) {
            this.setState({
              repSuggestions: repSuggestions.map((suggestion) => ({
                repName: suggestion.repName,
                createdAt: suggestion.createdAt || "-",
                image: suggestion.image,
                repId: suggestion.repId,
                email: suggestion.email
              })),
            });
        }
        if( repEmails !== prevProps.repEmails ){
          this.setState({ 
            repEmails: repEmails.map((suggestion) => ({ email: suggestion.email, repId: suggestion.id })),
          });
        }
    }

    handleSortUnclaimedReps = (e) => {
      let value = e.target.value
      let sortedData = this.props.repSuggestions.filter((i)=>{
          if(i.email.toLowerCase().includes(value.toLowerCase()) || i.repName.toLowerCase().includes(value.toLowerCase())){
              return i
          }
      })
      this.setState({ repSuggestions : sortedData })
    }

    handleCheckEmailExist = (e) => {
      this.setState ({ show : true , repDetails : e})
    }

    getRepsAccounts = () => {
      const {repDetails} = this.state;
      let emailExist = this.state.repEmails.find((i)=>i.email === repDetails.email )
      return (
        <div style={{ margin : "10px"}}>
          {emailExist ?
            <Body4>{`Temp Rep Account With ${ repDetails.email } Exist In Repora As A Real Rep`}
            <Link target="_blank" to={`/viewrepcustomer?id=${emailExist?.repId}`}> View Rep</Link></Body4> 
            :
            <Body4>{`Temp Rep Account With ${ repDetails.email } Doesn't Exist In Repora As A Real Rep Account`}</Body4>}
        </div>
      )
    }

    sortByHeader = (value) => {
      const { repSuggestions } = this.state;
      if(value === 'name'){
      let sortedData = repSuggestions.sort((a,b)=>{
        return a.repName.localeCompare(b.repName)
      })
      this.setState({ repSuggestions : sortedData  })
      }else if(value === "date"){
        let sortedData = repSuggestions.sort((a,b)=>{
        return moment(b.createdAt).diff(moment(a.createdAt));
      })
      this.setState({ repSuggestions : sortedData  })
      }else if(value === "email"){
        let sortedData = repSuggestions.sort((a,b)=>{
          return a.email.localeCompare(b.email)
        })
        this.setState({ repSuggestions : sortedData  })
      }else if(value === "exist"){
        let existedReps =[];
        let unexistedReps =[];
        repSuggestions.map((i)=>{
          if(this.state.repEmails.find((ii)=>ii.email === i.email)){
            existedReps = [...existedReps , i]
          }
          else{
           unexistedReps = [...unexistedReps , i]}
        })
        this.setState({ repSuggestions : [...existedReps , ...unexistedReps]  })
      }
    }

    checkRepExist = (rep) => {
      let emailExist = this.state.repEmails.find((i)=>i.email === rep.email )
      if(emailExist) { 
        return true 
      }
      return false 
    }

    render(){
        const {repSuggestions , repEmails , showingRealReps} = this.state;
        return( 
          <Wrapper>
            <NavLink className='Navlink' to = "/admin/dashboard">Back to Admin Panel</NavLink><br/>
              <div style={{display: "flex" , alignItems : "baseline"}}>
              <Header4>Unclaimed Reps {`(${repSuggestions.length}):`}</Header4>
              <WrapperSection>
                  <FormGroup className="searchInput">
                      <input type = "text" placeholder="Search By Name or Email" onChange={(e) => {
                      this.handleSortUnclaimedReps(e)
                      }}/>
                  </FormGroup>
              </WrapperSection>
              </div>
          {(this.props.repSuggestionsFetching || this.props.fetchingRepEmails ) && <Loader overlayed size="medium" />}
          <List>
          <ListHeader>
          <div>
              <span >S No</span>
            </div>
            <div style={{minWidth: "212px"}}>
              <span onClick={()=>this.sortByHeader("name")}>Temp Rep</span>
            </div>
            <div style={{minWidth: "199px"}}>
              <span onClick={()=>this.sortByHeader("date")}>Created </span>
            </div>
            <div style={{minWidth: "300px"}}>
              <span  onClick={()=>this.sortByHeader("email")}>Email</span>
            </div>
            <div>
              <span  onClick={()=>this.sortByHeader("exist")}>Rep Exist</span>
            </div>
          </ListHeader>
          {repSuggestions && repEmails.length !==0 &&  repSuggestions.map((rep)=>{
            return (
              <>
                <Row key={rep?.repId}>
                <div>{repSuggestions.indexOf(rep)+1}</div>
                <div style={{ minWidth: "210px"}}><Link target="_blank" to={`/viewrepcustomer?id=${rep?.repId}`} style={{ textDecoration : "none" , color: reporaColor.black}}>{rep?.repName}</Link></div>
                <div>{rep.createdAt &&  moment(rep.createdAt).format("MM/DD/YYYY")}</div>
                <div style={{ minWidth: "300px"}}>{rep.email}</div>
                <div>{this.checkRepExist(rep) ? "Yes" : "No"}</div>
              </Row>
              </>)
          })}
          </List>
        </Wrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    repSuggestions: state.admin.unclaimedReps,
    repSuggestionsFetching: state.admin.fetchingUnclaimedReps,
    repSuggestionsError: state.admin.unclaimedRepsErr,

    fetchingRepEmails : state.admin.fetchingRepsAccounts,
    repEmails : state.admin.repsAccounts,
})

const mapDispatchToProps = (dispatch) => ({
  getUnclaimedReps() {
    dispatch(getUnclaimedReps());
  },
  getRepsAccounts(){
    dispatch(getRepsAccounts())
  }
})

export const Unwrapped = UnclaimedReps;
export default connect(mapStateToProps, mapDispatchToProps)(UnclaimedReps);