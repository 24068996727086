const Data = {
  hero: {
    header: "Growth. Courtesy of Customer Feedback.",
    description: "Leverage customer feedback to improve performance and growth.",
    buttonTxt: "Contact Us",
    buttonLink: "/contactus"
  },
  pros: {
    reputation: {
      header: "Curate your Online Reputation",
      description: "A high Repora score enhances your value to your company, your customers, and your future endeavors.",
      imgSrc: "/assets/featuresReps/sample-card-new.png"
    },
    opportunities: {
      header: "Discover Improvement Opportunities",
      description: "Learn what specific areas need improvement and make adjustments before they become bigger issues.",
      knowledge: [
        {
          title: "Product Knowledge",
          imgSrc: "/assets/featuresReps/star-graphics-3star-rating.svg",
          rating: "3.25"
        },
        {
          title: "Trustworthiness",
          imgSrc: "/assets/featuresReps/star-graphics-5star-rating.svg",
          rating: "5.0"
        },
        {
          title: "Communication Skills",
          imgSrc: "/assets/featuresReps/star-graphics-4star-rating.svg",
          rating: "4.1"
        }
      ]
    }
  },
  performance: {
    header: "MAXIMIZE YOUR PERFORMANCE",
    title: "Whether it’s customer renewal rate or other objectives, the better connected you are to your customer, the better are your results."
  },
  compare: {
    imgSrc: "/assets/featuresReps/graph.png",
    header: "Compare Your Ranking",
    description: "See how you stack up within your company, industry, region, or nation.",
    buttonTxt: ""
  }
}

export default Data;
