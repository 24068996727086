import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import queryString from "query-string";
import View from "./components/View";
import { submitContactUs, closeContactUs } from "../../actions/notificationActions";

class ContactUs extends React.Component {
  state = {
    redirectLink: "",
    isCompanyContact: false
  };

  handleRedirectHome = link => {
    document.getElementById("contentWrapper").scrollTop = 0;
    this.setState({ redirectLink: link });
  }
  componentDidMount()
  {
    document.getElementById("contentWrapper").scrollTop = 0;
    const { view } = queryString.parse(this.props.location.search);
    this.setState({
      isCompanyContact: view === "company"
    });
  }

  render() {
    if (this.state.redirectLink !== "") {
      return <Redirect push={true} to={this.state.redirectLink} />
    }
     

    return (
      <View
        {...this.props}
        isCompanyContact={this.state.isCompanyContact}
        handleRedirectHome={this.handleRedirectHome}
      />
    );
  }
}

const mapStateToProps = state => ({
  contactUsFetching: state.notification.contactUsFetching,
  contactUsSucceeded: state.notification.contactUsSucceeded,
  contactUsError: state.notification.contactUsError
});

const mapDispatchToProps = dispatch => ({
  handleSubmitContactUs(firstName, lastName, email, phoneNumber, message, isCompany) {
    dispatch(submitContactUs(firstName, lastName, email, phoneNumber, message, isCompany));
  },
  handleCloseContactUs() {
    dispatch(closeContactUs());
  }
});

export const Unwrapped = ContactUs;
export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
