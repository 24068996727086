import React from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";

import Button from "../../../components/Buttons/Button";
import Loader from "../../../components/Loader/Loader";
import { Header3, FormGroup } from "../../../components/global/commonStyles";
import {
  Wrapper, BackButton, CloseButton,
  HeaderWrapper, FormWrapper, ButtonContainer,
  CreateContainer, ForgotLink, SignupLink, FormError
} from "./CommonStyles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

class Step2Company extends React.Component {
  state = {
    email: "",
    password: ""
  };

  componentDidMount() {
    this.props.clearErrorMessages();
  }

  handleFormValidation = (event) => {
    const inputName = event.target.getAttribute("name");
    const inputValue = event.target.value
    this.setState({ [inputName]: inputValue });
    this.props.handleInputChange(event);
  }

  handleStepSubmit = (e) => {
    e.preventDefault();
    this.props.handleStepSubmit();
  }

  render() {
    const { email, password } = this.state;
    const isValid = !isEmpty(email) && !isEmpty(password);

    return (
      <section>
        <Wrapper>
          {this.props.isFetching && <Loader overlayed size="medium" />}

          <BackButton onClick={this.props.handleBack}><FontAwesomeIcon icon={faArrowLeft} /></BackButton>
          <CloseButton onClick={this.props.handleClose}><FontAwesomeIcon icon={faTimes} /></CloseButton>

          <HeaderWrapper>
            <Header3 textAlign="center">Log In</Header3>
          </HeaderWrapper>

          <FormWrapper>
            <FormGroup>
              <label>Email Address</label>
              <input
                name="email"
                required
                value={this.state.email}
                autoFocus
                onChange={this.handleFormValidation} />
            </FormGroup>

            <FormGroup>
              <label>Password</label>
              <input
                name="password"
                required
                type="password"
                value={this.state.password}
                onChange={this.handleFormValidation} />
            </FormGroup>

            <ForgotLink><Link to="/reset-password">Forgot?</Link></ForgotLink>

             

            {this.props.loginErrorMessage && <FormError>{this.props.loginErrorMessage}</FormError>}

            <ButtonContainer>
              <Button width="100%" type="Submit" disabled={!isValid} onClick={this.handleStepSubmit}>Log In</Button>
            </ButtonContainer>

          </FormWrapper>
        </Wrapper>

        <CreateContainer>
          <span>Don't have an account? <SignupLink><Link to="/signup">Sign Up</Link></SignupLink></span>
        </CreateContainer>
      </section>
    );
  }

};

export default Step2Company;
