import React from "react";
import styled from "styled-components";
import { reporaSize, reporaColor, Header3 } from "../../../components/global/commonStyles";
import Form from "./Form";
import Confirmation from "./Confirmation";
import Footer from "../../../components/FooterUpdated/Footer";

const Wrapper = styled.div`
  background-image: linear-gradient( 309deg, ${reporaColor.greenBlue68}, rgba(11, 201, 244, 0.85) 98%, ${reporaColor.brightSkyBlue85} );
  width: 100%;
  max-width: 1440px;
  padding-bottom: 40px;
  position: relative;
  min-height: calc(100% - 113px);

  @media (max-width: ${reporaSize.medium}) {
    min-height: calc(100% - 89px);
  }

  @media (max-width: ${reporaSize.small}) {
    min-height: calc(100% - 161px);
  }

  h3 {
    width: 100%;
    max-width: 731px;
    padding: 45px;
    margin: 0 auto;
  }
`;

const View = props => {
    return (
      <>
        <Wrapper>
          <Header3 textColor={reporaColor.white}>Contact Us</Header3>
          {
            props.contactUsSucceeded ?
              <Confirmation
                handleRedirectHome={props.handleRedirectHome}
                handleClose={props.handleCloseContactUs} />
              :
              <Form
                handleSubmit={props.handleSubmitContactUs}
                error={props.contactUsError}
                isCompanyContact={props.isCompanyContact}
                fetching={props.contactUsFetching} />
          }
        </Wrapper>
        <Footer />
      </>
  );
};

export default View;
