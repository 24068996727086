import ManageEmailAlertsService from "../services/manageEmailAlertsService";

/* Action Constants */
export const GET_COMPANY_RECIPIENTS_REQUESTED =
  "GET_COMPANY_RECIPIENTS_REQUESTED";
export const GET_COMPANY_RECIPIENTS_SUCCEEDED =
  "GET_COMPANY_RECIPIENTS_SUCCEEDED";
export const GET_COMPANY_RECIPIENTS_FAILED = "GET_COMPANY_RECIPIENTS_FAILED";

export const ADD_COMPANY_RECIPIENT_REQUESTED =
  "ADD_COMPANY_RECIPIENT_REQUESTED";
export const ADD_COMPANY_RECIPIENT_SUCCEEDED =
  "ADD_COMPANY_RECIPIENT_SUCCEEDED";
export const ADD_COMPANY_RECIPIENT_FAILED = "ADD_COMPANY_RECIPIENT_FAILED";

export const DELETE_COMPANY_RECIPIENT_REQUESTED =
  "DELETE_COMPANY_RECIPIENT_REQUESTED";
export const DELETE_COMPANY_RECIPIENT_SUCCEEDED =
  "DELETE_COMPANY_RECIPIENT_SUCCEEDED";
export const DELETE_COMPANY_RECIPIENT_FAILED =
  "DELETE_COMPANY_RECIPIENT_FAILED";

export const UPDATE_COMPANY_EMAIL_ALERTS_REQUESTED =
  "UPDATE_COMPANY_EMAIL_ALERTS_REQUESTED";
export const UPDATE_COMPANY_EMAIL_ALERTS_SUCCEEDED =
  "UPDATE_COMPANY_EMAIL_ALERTS_SUCCEEDED";
export const UPDATE_COMPANY_EMAIL_ALERTS_FAILED =
  "UPDATE_COMPANY_EMAIL_ALERTS_FAILED";

export const GET_EMAIL_ALERTS_CONFIG_REQUESTED =
  "GET_EMAIL_ALERTS_CONFIG_REQUESTED";
export const GET_EMAIL_ALERTS_CONFIG_SUCCEEDED =
  "GET_EMAIL_ALERTS_CONFIG_SUCCEEDED";
export const GET_EMAIL_ALERTS_CONFIG_FAILED = "GET_EMAIL_ALERTS_CONFIG_FAILED";

/* Action Creators */
const getCompanyRecipientsRequested = () => ({
  type: GET_COMPANY_RECIPIENTS_REQUESTED,
  payload: null,
});
const getCompanyRecipientsSucceeded = (data) => ({
  type: GET_COMPANY_RECIPIENTS_SUCCEEDED,
  payload: data,
});
const getCompanyRecipientsFailed = (data) => ({
  type: GET_COMPANY_RECIPIENTS_FAILED,
  payload: data,
});

const addCompanyRecipientRequested = () => ({
  type: ADD_COMPANY_RECIPIENT_REQUESTED,
  payload: null,
});
const addCompanyRecipientSucceeded = (data) => ({
  type: ADD_COMPANY_RECIPIENT_SUCCEEDED,
  payload: data,
});
const addCompanyRecipientFailed = (data) => ({
  type: ADD_COMPANY_RECIPIENT_FAILED,
  payload: data,
});

const deleteCompanyRecipientRequested = () => ({
  type: DELETE_COMPANY_RECIPIENT_REQUESTED,
  payload: null,
});
const deleteCompanyRecipientSucceeded = (data) => ({
  type: DELETE_COMPANY_RECIPIENT_SUCCEEDED,
  payload: data,
});
const deleteCompanyRecipientFailed = (data) => ({
  type: DELETE_COMPANY_RECIPIENT_FAILED,
  payload: data,
});

const updateCompanyEmailAlertsRequested = () => ({
  type: UPDATE_COMPANY_EMAIL_ALERTS_REQUESTED,
  payload: null,
});
const updateCompanyEmailAlertsSucceeded = (data) => ({
  type: UPDATE_COMPANY_EMAIL_ALERTS_SUCCEEDED,
  payload: data,
});
const updateCompanyEmailAlertsFailed = (data) => ({
  type: UPDATE_COMPANY_EMAIL_ALERTS_FAILED,
  payload: data,
});

const getEmailAlertsConfigRequested = () => ({
  type: GET_EMAIL_ALERTS_CONFIG_REQUESTED,
  payload: null,
});
const getEmailAlertsConfigSucceeded = (data) => ({
  type: GET_EMAIL_ALERTS_CONFIG_SUCCEEDED,
  payload: data,
});
const getEmailAlertsConfigFailed = (data) => ({
  type: GET_EMAIL_ALERTS_CONFIG_FAILED,
  payload: data,
});

export function getRecipients(companyId) {
  return (dispatch) => {
    dispatch(getCompanyRecipientsRequested());

    ManageEmailAlertsService.getRecipients(companyId)
      .then((response) => {
        dispatch(getCompanyRecipientsSucceeded(response.payload));
      })
      .catch((error) => {
        dispatch(getCompanyRecipientsFailed(error));
      });
  };
}

export function addRecipient(companyId, email) {
  return (dispatch) => {
    dispatch(addCompanyRecipientRequested());

    ManageEmailAlertsService.addRecipient(companyId, email)
      .then(() => {
        dispatch(addCompanyRecipientSucceeded(email));
      })
      .catch((error) => {
        dispatch(addCompanyRecipientFailed(error));
      });
  };
}

export function deleteRecipient(companyId, email) {
  return (dispatch) => {
    dispatch(deleteCompanyRecipientRequested());

    ManageEmailAlertsService.deleteRecipient(companyId, email)
      .then(() => {
        dispatch(deleteCompanyRecipientSucceeded(email));
      })
      .catch((error) => {
        dispatch(deleteCompanyRecipientFailed(error));
      });
  };
}

export function getEmailAlertsConfig(comapnyId) {
  // console.log("getEmailAlertsConfig companyId-->>>", comapnyId);
  return (dispatch) => {
    dispatch(getEmailAlertsConfigRequested());

    ManageEmailAlertsService.getEmailAlertsConfig(comapnyId)
      .then((response) => {
        dispatch(getEmailAlertsConfigSucceeded(response));
      })
      .catch((error) => {
        dispatch(getEmailAlertsConfigFailed(error));
      });
  };
}

export function updateEmailAlerts(comapnyId, config, isSelected) {
  // console.log("comapnyId-->>>", comapnyId);
  // console.log("config-->>>", config);
  // console.log("isSelected-->>>", isSelected);

  return (dispatch) => {
    dispatch(updateCompanyEmailAlertsRequested());

    ManageEmailAlertsService.updateEmailAlerts(comapnyId, config, isSelected)
      .then((response) => {
        dispatch(updateCompanyEmailAlertsSucceeded(response));
      })
      .catch((error) => {
        dispatch(updateCompanyEmailAlertsFailed(error));
      });
  };
}
