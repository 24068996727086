import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faTimes, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { Header3, reporaColor, CheckComponent } from "../../../components/global/commonStyles";
import {
  Wrapper,
  BackButton,
  CloseButton,
  HeaderWrapper,
  LinkedInButton,
  LinkedInButtonImageContainer,
  LinkedInButtonText
} from "./CommonStyles";

export const ButtonContainer = styled.div`
  margin-bottom: 55px;
  width: 100%;

  a {
    margin: 0 auto;
  }
`;

const TermsLink = styled.a`
  color: ${reporaColor.warmGrey};
  padding-left: 3px;
`;

const InvitationHeader = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  margin-top: 45px;
  color: black;
  text-align: center;
`;

const Icon = styled.div`
  color: white;
  background: ${reporaColor.brightSkyBlue};
  font-size: 30px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 100px;
  margin-bottom: 20px;
`;

const CompanyLogo = styled.div`
  height: 100px;
  width: 100px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const SubHeader = styled.div`
  font-size: 14px;
  text-align: center;
  color: ${reporaColor.warmGrey};
  margin-top: 24px;
  margin-bottom: 45px;
`;

const Step2Rep = props => {
  return (
    <Wrapper>
      {!props.invitation && <BackButton onClick={props.handleBack}><FontAwesomeIcon icon={faArrowLeft} /></BackButton>}
      <CloseButton onClick={props.handleClose}><FontAwesomeIcon icon={faTimes} /></CloseButton>

      <HeaderWrapper className="p-l-15 p-r-15">
        <Header3 textAlign="center">Sign Up</Header3>

        {props.invitation ?
          <InvitationHeader
            textAlign="center"
            textColor={`${reporaColor.warmGrey}`}>
            {`${(props.company && props.company.name) || "A Company"} has invited you to join Repora!`}
          </InvitationHeader>
          :
          <SubHeader textAlign="center" textColor={`${reporaColor.warmGrey}`}>We ask that you sign up using your LinkedIn account.<br />This helps us verify your credentials.</SubHeader>
        }

        <div className="d-flex justify-content-center m-t-30">
          {
            props.invitation &&
            ((props.company && props.company.logo) ?
              <CompanyLogo>
                <img alt="company_logo" src={props.company && props.company.logo} />
              </CompanyLogo>
              :
              <Icon>
                <FontAwesomeIcon icon={faBuilding} />
              </Icon>
            )
          }
        </div>
      </HeaderWrapper>

      <div className="p-l-15 p-r-15">
       


        {props.invitation ?
          <LinkedInButton disabled={!props.validStep} onClick={() => props.validStep ? props.handleGetSocialUrlInivited("LINKEDIN", true) : null}>
            <LinkedInButtonImageContainer><img src="/assets/global/linked-in.svg" alt="" /></LinkedInButtonImageContainer>
            <LinkedInButtonText>Sign Up using LinkedIn</LinkedInButtonText>
          </LinkedInButton> :
          <LinkedInButton disabled={!props.validStep} onClick={() => props.validStep ? props.handleGetSocialUrl("LINKEDIN") : null}>
            <LinkedInButtonImageContainer><img src="/assets/global/linked-in.svg" alt="" /></LinkedInButtonImageContainer>
            <LinkedInButtonText>Sign Up using LinkedIn</LinkedInButtonText>
          </LinkedInButton>
        }

        <CheckComponent className="m-b-45" textSize="14px" textColor={`${reporaColor.warmGrey}`} >
          <input id="repTermsService" type="checkbox" checked={props.validStep} onChange={props.handleInputChange} />
          <label htmlFor="repTermsService">
            <p>
              <span>I agree to the Repora</span>
              <TermsLink href={"/terms"}>Terms of Service</TermsLink>
            </p>
          </label>
        </CheckComponent>
      </div>
    </Wrapper>
  );
};

export default Step2Rep;
