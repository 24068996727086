import React from "react";
import styled, { css } from "styled-components";

import {
  reporaDevice,
  reporaColor,
  Header5,
  Header4,
  Body2,
  Body5,
  Body4,
  Body6,
} from "../../../components/global/commonStyles";
import {
  FormRow,
  FormGroup,
  CheckComponent,
} from "../../../components/global/commonStyles";
import RatingStars from "../../../components/RatingStars/RatingStars";
import LikeDislikeOption from "../../../components/LikeDislikeOption/LikeDislikeOption";
import TextArea from "../../../components/Inputs/TextArea";
import Button from "../../../components/Buttons/Button";
import DynamicRating from "../../../components/Dynamic/DynamicRating";
import Loader from "../../../components/Loader/Loader";
import {
  Wrapper as wrp,
  FormWrapper,
} from "../../SignUp/components/CommonStyles";
import ReactTooltip from "react-tooltip";
import HelpTooltip from "../../../components/HelpTooltip/HelpTooltip";
import { ThumbUpSharp } from "@mui/icons-material";
import { isEmpty, isEqual } from "lodash";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  flex-shrink: 0;
  padding: 28px 10px;
  margin-bottom: 40px;
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.08);

  @media ${reporaDevice.tablet} {
    padding: 30px 15px;
  }

  @media ${reporaDevice.desktop} {
    padding: 30px 50px;
  }
`;
const RadioSize = styled.div`
  margin-top: 6%;
  white-space: nowrap;
  height: 100%;
`;

const RadioRate = styled.label`
  font-size: 16px;
  font-family: fangsong;

  label > input[type="radio"] {
    display: none;
  }
  label > input[type="radio"] + *::before {
    content: "";
    display: inline-block;
    /* vertical-align: bottom; */
    width: 13px;
    height: 13px;
    margin-right: 0.2rem;
    margin-bottom: 0%;
    border: 1.5px solid #9b9b9b;
    border-radius: 100%;
  }

  label > input[type="radio"]:checked + *::before {
    background: radial-gradient(
      ${reporaColor.brightSkyBlue} 0%,
      ${reporaColor.brightSkyBlue} 40%,
      transparent 50%,
      transparent
    );
  }

  label > input[type="radio"]:checked + *::after {
    content: "";
    width: 10px;
    height: 10px;
    background: #0bc9f6;
    position: absolute;
    top: calc(50% - 5px);
    left: -17px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
  }
`;

let AnonymousEle = styled.span`
font-family: Nexa Bold;
box-sizing: border-box;
font-size: 12px;
display: grid;
line-height: 22px;
margin-top: 10px;
margin-left:10px
margin-bottom: 10px;

@media only screen and (min-width:768px){
  input{
    width:100%;
   }
}
@media only screen and (max-width:598px){
  input{
    width:98%;
   }
}

`;

const InvalidSpan = styled.div`
  font-size: small;
  color: red;
  text-align: center;
  margin-top: -2%;
`;

const InformationSpan = styled.span`
  margin-left: 5px;
  width: auto;
  height: auto;
  ${(props) => (props.position ? "position: absolute" : "position: relative")};

  img {
    width: 22px;
    height: 22px;
  }

  &:hover {
    span {
      display: block;
    }
  }
`;

const CollapseRow = styled(FormRow)`
  margin-top: -2%;
  margin-bottom: -6%;
  @media ${reporaDevice.xs} {
    flex-direction: column;
    > div {
      margin: 0;
      width: 100%;
    }
  }
`;
const RateRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-top: 1px solid ${reporaColor.grey91};

  ${(props) =>
    props.last &&
    css`
      border-bottom: 1px solid ${reporaColor.grey91};
    `}

  @media ${reporaDevice.xs} {
    flex-direction: column;
    align-items: center;
  }
`;

const AnonymusWrapper = styled.div`
  margin: 30px 0 40px;
`;

const LegendBlue = styled.p`
  color: ${reporaColor.brightSkyBlue};
  font-size: 14px;
  font-weight: bold;
`;

const RatingTypes = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${reporaDevice.xs} {
    justify-content: center;
  }
`;

export const BodyDescripton = styled.p`
  font-family: Nexa Bold;
  font-size: 10px;
  line-height: 12px;
`;

class RatingsCard extends React.Component {
  state = {
    ratings: {},
    thumbsUp: null,
    comment: "",
    anonymous: false,
    name: "",
    email: "",
    grayOut: false,
    varifiedEmail: false,
    verifiedEmailmsg: "",
    errorName: "",
    customeAttrList: [],
    combinedAttributes: [],

    // isButtonDisabled: false,
  };

  componentDidMount() {
    const { programData } = this.props;
    if (programData.attributeList) {
      let tempVar = {};
      programData.attributeList.forEach((ele) => {
        if (ele != "POSITIVE_EXPERIENCE") {
          Object.assign(tempVar, { [ele]: null });
        }
      });
      this.setState({ ratings: tempVar });
      if (programData.attributeList.includes("POSITIVE_EXPERIENCE")) {
        this.setState({ thumbsUp: null });
      } else {
        this.setState({ thumbsUp: true });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isEmpty(this.props.customeAttributes) &&
      !isEqual(this.props.customeAttributes, prevProps.customeAttributes)
    ) {
      let data = this.props.customeAttributes.map((i) => {
        return i.attributeName.replaceAll("_", " ");
      });
      this.setState({ customeAttrList: data });
    }
  }

  handleRateChange = (name, value) => {
    this.setState((prevState) => ({
      ratings: {
        ...prevState.ratings,
        [name]: parseInt(value),
      },
    }));
  };

  handleExperienceChange = (experience) => {
    this.setState({
      thumbsUp: experience,
    });
  };

  handleCommentChange = (event) => {
    this.setState({
      comment: event.target.value,
    });
  };

  anonymousVal = (event) => {
    if (event.target.value == "anonymous") {
      this.setState({
        anonymous: true,
        grayOut: true,
        varifiedEmail: false,
        verifiedEmailmsg: "",
        errorName: "",
      });

      AnonymousEle = styled.span`
          font-family: Nexa Bold;
          box-sizing: border-box;
          pointer-events: none;
          opacity: 0.3;
          font-size: 12px;
          display: grid;
          line-height: 22px;
          margin-top: 10px;
          margin-left:10px
          margin-bottom: 10px;
          width:100%;
          @media only screen and (min-width:768px){
            input{
              width:100%;
            }
          }
          @media only screen and (max-width:598px){
            input{
              width:98%;
             }
          }

          `;
    } else {
      this.setState({
        anonymous: false,
        grayOut: false,
        name: "",
        email: "",
        varifiedEmail: true,
        verifiedEmailmsg: "Please enter valid email",
        errorName: "Please enter valid name",
      });
      AnonymousEle = styled.span`
      font-family: Nexa Bold;
      box-sizing: border-box;
      font-size: 12px;
      display: grid;
      line-height: 22px;
      margin-top: 10px;
      margin-left:10px
      margin-bottom: 10px;
      width:100%;
      @media only screen and (min-width:768px){
        input{
          width:100%;
         }
      }
      @media only screen and (max-width:598px){
        input{
          width:98%;
         }
      }
     `;
    }
  };

  handlenameChange = (event) => {
    const { name, value, checked } = event.target;
    if (name == "name") {
      //old validation
      const pattern = !!value.match(
        /(^[a-zA-Z]{3,40}\s[a-zA-Z]{2,40})$|(^[a-zA-Z]{3,40}\S)$|(^[a-zA-Z]{3,40}\s[a-zA-Z]{2,40}\s[a-zA-Z]{2,40})$/
      );
      //new validation
      var letters = /^[a-zA-Z .]*$/;
      if (
        value &&
        value.length > 0 &&
        value.match(/[a-zA-Z]/) &&
        value.match(letters)
      ) {
        this.setState({
          name: value.trim(),
          errorName: "",
        });
      } else {
        this.setState({
          name: value.trim(),
          errorName: "Please enter valid name",
        });
      }
    } else {
      const emailValid = !!value.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ); // eslint-disable-line no-useless-escape
      if (emailValid) {
        this.setState({
          email: value,
          varifiedEmail: true,
          verifiedEmailmsg: "",
        });
      } else {
        this.setState({
          email: value,
          varifiedEmail: false,
          verifiedEmailmsg: "Please enter valid email",
        });
      }
    }
  };

  handleAnonymousChange = (event) => {
    this.setState({
      anonymous: event.target.checked,
    });
  };

  isFormValid = () => {
    const { ratings, thumbsUp } = this.state;
    const ratingsValid = !Object.keys(ratings).some((rating) => {
      return ratings[rating] === null;
    });

    const experienceValid = thumbsUp !== null;
    let tempVar = false;
    if (this.state.anonymous || this.props.userdataExist) {
      tempVar = true;
    } else if (
      this.state.verifiedEmailmsg.length == 0 &&
      this.state.varifiedEmail &&
      this.state.name.length > 2 &&
      this.state.errorName.length == 0 &&
      this.state.anonymous == false
    ) {
      tempVar = true;
    } else {
      tempVar = false;
    }
    return ratingsValid && experienceValid && tempVar;
  };

  handleFormSubmit = () => {
    // this.setState({
    //   isButtonDisabled: true,
    // });
    if (this.isFormValid) {
      const { programData, reviewRequestedBy, userData } = this.props;
      const { ratings, thumbsUp, comment, anonymous, name, email } = this.state;
      const formattedRatings = Object.keys(ratings).map((key) => ({
        category: key,
        value: ratings[key],
      }));
      let userEmail = email;
      let userName = name;
      if (!isEmpty(this.props.userData)) {
        userEmail = this.props.userData.email;
        userName =
          this.props.userData?.firstName + " " + this.props.userData?.lastName;
      }
      let obj = {
        detail: comment,
        anonymous: anonymous,
        companyId: programData.company.id,
        companyName: programData.company.name || "",
        userRatings: formattedRatings,
        categoryId: programData.id,
        customerEmail: userEmail,
        customerName: userName,
        companyFeedbackCategory: "PROGRAM",
        reviewRequestedBy: reviewRequestedBy,
        reviewerId: userData ? userData.id : "",
      };
      if (programData.attributeList.includes("POSITIVE_EXPERIENCE")) {
        Object.assign(obj, { thumbsUp: thumbsUp });
      }
      this.props.handleSubmitRating(obj);
    }
  };

  ratingAttributes = () => {
    const { programData, attributesList } = this.props;

    try {
      if (
        programData &&
        programData.attributeList &&
        Object.keys(attributesList).length
      ) {
        let attrArry = programData.attributeList;
        // Filter out "Positive Experience" from the standard attributes
        let positiveExperience = attrArry.find(
          (rate) => rate === "POSITIVE_EXPERIENCE"
        );

        let customAttrArry = programData.attributeDetails;
        let finalCustomArr = customAttrArry
          .filter(
            (rate) =>
              rate?.attibuteName !== "POSITIVE_EXPERIENCE" &&
              !attributesList["standard"]?.includes(rate?.attibuteName)
          )
          .map((rate) => {
            const ratingAttr = rate?.attibuteName.replace("_", " ");
            const ratingDesc = rate?.description.replace("_", " ");
            let rateValue = this.state.ratings[ratingAttr] || null;
            return (
              <RateRow key={ratingAttr}>
                <div style={{ maxWidth: 285, flexWrap: "wrap" }}>
                  <Body2>{ratingAttr}</Body2>
                  <div>
                    <Body5>{ratingDesc}</Body5>
                  </div>
                </div>
                <RatingStars
                  name={ratingAttr}
                  rating={rateValue}
                  big={true}
                  onChange={this.handleRateChange}
                />
              </RateRow>
            );
          });

        let finalArr = attrArry
          .filter(
            (rate) =>
              rate !== "POSITIVE_EXPERIENCE" &&
              attributesList["standard"]?.includes(rate)
          )
          .map((rate) => {
            const ratingAttr = this.state.customeAttrList.includes(rate)
              ? { key: rate, value: rate }
              : DynamicRating(rate);
            let rateValue = this.state.ratings[ratingAttr.key] || null;
            return (
              <RateRow key={ratingAttr.key}>
                <Body2>{ratingAttr.value}</Body2>
                <RatingStars
                  name={ratingAttr.key}
                  rating={rateValue}
                  big={true}
                  onChange={this.handleRateChange}
                />
              </RateRow>
            );
          });
        let temp;
        if (positiveExperience) {
          temp = (
            <RateRow last key={this.props.programData.id}>
              <Body2>How was your overall experience?</Body2>
              <LikeDislikeOption
                name="experience"
                selected={this.state.thumbsUp}
                onChange={this.handleExperienceChange}
              />
            </RateRow>
          );
        }

        // Concatenate all rating components ensuring Positive Experience comes last
        const combinedArr = [...finalArr, ...finalCustomArr];
        combinedArr.sort((a, b) => a.key.localeCompare(b.key));

        if (temp) {
          combinedArr.push(temp);
        }

        return combinedArr;
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { programData, spinner } = this.props;
    const programName = `${programData.programName}`.trim().replace("null", "");

    return (
      <Wrapper>
        <Header5 style={{ margin: "0px 0px 5px 0px" }}>
          We would love your feedback on:
        </Header5>
        {this.props.isFetching && <Loader overlayed size="medium" />}
        {this.props.isFetchingRateProgram && <Loader overlayed size="medium" />}
        <Header4 style={{ marginBottom: "5px" }}>{programName}</Header4>
        <Body5 style={{ color: `${reporaColor.aquaBlue}` }}>
          {programData?.customerInstructions}
        </Body5>

        <RatingTypes>
          <Body5 textColor={reporaColor.warmGrey}>Poor</Body5>
          <Body5 className="m-l-20" textColor={reporaColor.warmGrey}>
            Average
          </Body5>
          <Body5 className="m-l-20" textColor={reporaColor.warmGrey}>
            Great
          </Body5>
        </RatingTypes>
        {/* {this.state.spinner && <Loader overlayed size="medium" />} */}

        {spinner ? <Loader overlayed size="medium" /> : this.ratingAttributes()}

        <LegendBlue className="m-t-20 m-b-20">
          Write a comment (optional):
        </LegendBlue>

        <TextArea
          className="m-b-20"
          maxLength="1000"
          rows="7"
          onChange={this.handleCommentChange}
          value={this.state.comment}
          name="comment"
          counter
        />
        <div className="form-control">
          {!this.props.userdataExist ? (
            <FormWrapper maxWidth="500px">
              <CollapseRow>
                <RadioSize>
                  <RadioRate>
                    <label>
                      <input
                        type="radio"
                        onChange={this.anonymousVal}
                        checked={!this.state.anonymous}
                        name="anonymous"
                        value="non-anonymous"
                      />
                      <span> Identify me</span>
                    </label>
                  </RadioRate>
                </RadioSize>
                <div>
                  <AnonymousEle disabled={this.state.grayOut}>
                    <label>Full Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      onChange={this.handlenameChange}
                    />
                  </AnonymousEle>
                  <InvalidSpan>
                    {this.state.errorName ? this.state.errorName : ""}
                  </InvalidSpan>
                </div>
                <div>
                  <AnonymousEle disabled={this.state.grayOut}>
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={this.handlenameChange}
                    />
                  </AnonymousEle>
                  <InvalidSpan>
                    {this.state.varifiedEmail
                      ? ""
                      : this.state.verifiedEmailmsg}
                  </InvalidSpan>
                </div>
              </CollapseRow>
              <RadioSize>
                <RadioRate>
                  <label>
                    <input
                      type="radio"
                      name="anonymous"
                      style={{ border: "1px solid #E8E8E8 !important" }}
                      onChange={this.anonymousVal}
                      value="anonymous"
                    />
                    <span>
                      {" "}
                      Make my rating anonymous
                      <InformationSpan
                        position="absolutePosition"
                        data-tip
                        data-for="disputeTip"
                      >
                        <img src="/assets/global/help.svg" alt="" />
                      </InformationSpan>
                      <div>
                        <ReactTooltip id="disputeTip" data-effect="solid">
                          {this.props.programData?.company.privateCompany ? (
                            <span>
                              An identifiable rating will show your full name to
                              the company.
                              <br />
                              Anonymous ratings will hide your name, and in
                              order to obfuscate the date,
                              <br />
                              it will not post immediately and when it posts the
                              rating will be timestamped
                              <br />
                              with the month and year not the exact date.
                              <br />
                              <br />
                              Note: identifiable ratings are given higher
                              weighting than anonymous ratings.
                            </span>
                          ) : (
                            <span>
                              An identifiable rating will show your first name
                              and last initial to fellow
                              <br /> customers, while the rep and his/her
                              company will see your full name.
                              <br /> Anonymous ratings will hide your name, will
                              not be posted immediately,
                              <br /> and the rating date will only show the
                              month not the day. <br />
                              <br />
                              Note: identifiable ratings are given higher
                              weighting than anonymous <br />
                              ratings.
                            </span>
                          )}
                        </ReactTooltip>
                      </div>
                    </span>
                  </label>
                </RadioRate>
              </RadioSize>{" "}
              <br />
            </FormWrapper>
          ) : (
            <AnonymusWrapper>
              <CheckComponent
                textSize="14px"
                textColor={`${reporaColor.warmGrey}`}
              >
                <input
                  id="anonymousRating"
                  type="checkbox"
                  value="value1"
                  checked={this.state.anonymous}
                  onChange={this.handleAnonymousChange}
                />
                <label htmlFor="anonymousRating">
                  Make my rating anonymous
                  <HelpTooltip
                    data={
                      "A public rating will show your first name and last initial to fellow customers," +
                      "while reps and companies will see your full name. Anonymous ratings will hide your name," +
                      "will not be posted immediately, and the rating date will only show the month."
                    }
                  />
                </label>
              </CheckComponent>
            </AnonymusWrapper>
          )}
        </div>
        <Button
          disabled={
            !this.isFormValid()
            // || this.state.isButtonDisabled
          }
          onClick={this.handleFormSubmit}
        >
          Submit Rating
        </Button>
      </Wrapper>
    );
  }
}

export default RatingsCard;
