import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const Wrap = styled.div`
  background: white;
  font-size: 20px;
  padding: 5px;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid rgba(0,0,0,.2);
`;

const MenuToggle = props => (
  <Wrap>
    {props.menuOpen ?
      <FontAwesomeIcon icon={faTimes} />
      :
      <FontAwesomeIcon icon={faBars} />
    }
  </Wrap>
);

export default MenuToggle;
