const Data = {
  hero: {
    header: "Customer Sourced Feedback is Everything",
    description: "Repora is a new SaaS platform with the goal of optimizing growth for B2B organizations by empowering customer-sourced feedback.",
    buttonTxt: "Contact Us",
    buttonLink: "/contactus"
  },
  welcome: {
    header: "Welcome to Repora",
    title: "Repora is a new open, real-time, always-on, communication channel with customers.",
    size: "small",
    columns: [
      { imgSrc: "/assets/home/speed.svg", description: "Performance improves when being evaluated"},
      { imgSrc: "/assets/home/brain-clock.svg", description: "Realtime knowledge and intervention reduces customer and employee churn"},
      { imgSrc: "/assets/home/review-clipboard.svg", description: "Make performance reviews/employee coaching more objective and helpful"},
      { imgSrc: "/assets/home/handshake.svg", description: "Become a more committed vendor/partner to your customer"}
    ],
    footer: {
      imgSrc: "/assets/home/community.svg",
      title: "With Repora, you can gain important community intelligence which allows you to benchmark against industry leaders and competitors"
    }
  },
  problem: {
    head1: {
      header: "The Problem",
      title: "Companies are not effectively capturing and analyzing customer feedback on their people, programs, and teams."
    },
    effect: {
      title: "THE EFFECT ON...",
      customers: {
        title: "Customers",
        effects: ["Poor customer experience", "Lower customer value creation", "Higher customer churn"]
      },
      employees: {
        title: "Employees",
        effects: ["Deficiencies in employee coaching", "Unaddressed employee issues", "Higher employee churn"]
      }
    },
    head2: {
      header: "Working With Outdated Solutions",
      title: "Current methods are subjective and lack precision, scalability, and benchmarking"
    },
    footer: ["Call Recordings", "Random Ad-Hoc Feedback", "Phone Calls/Ride-Alongs", "Surveys"]
  }
}

export default Data;
