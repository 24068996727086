import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import DynamicRating from "../../../../components/Dynamic/DynamicRating";
import { reporaColor } from "../../../../components/global/commonStyles";
import { formatDate, parseDate } from "../../../../components/global/utils";
import ClaimsList from "./ClaimsList";
import CompanyApilist from "./CompanyAPIlist";
import EditForm from "./EditForm";
import PartnerList from "./PartenerList";

export const Wrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 #00000033;
  font-family: emoji;
`;
export const Row = styled.div`
  background: white;
  display: flex;
  height: 60px;
  gap: 20px;
  border-bottom: 1px solid ${reporaColor.grey91};
  background: ${reporaColor.grey98};

  > div {
    flex: 1;
    font-size: 15px;
    padding-left: 6px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    background: ${reporaColor.grey98};
    user-select: none;
    &:last-child {
      border-right: none;
    }
  }
  > span {
    right: 1%;
    position: relative;
    align-items: center;

    top: 31%;
  }
  &:nth-child(even) {
  }
  .keyDiv {
    overflow: hidden;
    padding: 8px 0px;
    height: 40px;
    align-items: flex-start;
  }
`;

export default class ListItem extends React.Component {
  state = { isOpen: false };

  componentDidMount() {}
  handleToggleForm = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  modalPOPup = () => {
    let ratings = this.props.ele.customerFeedbackResponse;
    let arr = [];
    ratings.ratingCategory.map((data) => {
      const ratingAttr = DynamicRating(data.type)["value"];
      arr.push({ type: ratingAttr, value: data.value });
    });
    if (ratings.customRatingCategory) {
      arr = [...arr, ...ratings.customRatingCategory];
    }
    this.props.handleModalVal({ arr, feedbackResp: ratings });
    var modal = document.getElementById("myModal");
    modal.style.display = "block";
  };

  getCustomerNamelink = (details) => {
    if (details.anonymous) {
      return <div>Anonymous</div>;
    } else if (details.reviewerId) {
      return (
        <a
          style={{
            textAlign: "center",
            textDecoration: "underline",
            color: "#0bc9f6",
            fontWeight: "500",
          }}
          target="_blank"
          href={"/view-past-ratings?id=" + details.reviewerId}
        >
          {details.customerName}
        </a>
      );
    } else if (details.customerName) {
      return <div>{details.customerName}</div>;
    } else {
      return "";
    }
  };
  render() {
    const { ele } = this.props;
    const { isOpen } = this.state;
    return (
      <Wrapper>
        {this.props.fromDispute && (
          <>
            <Row>
              <div>{ele.id}</div>
              <div>{formatDate(parseDate(ele.createdAt))}</div>
              <div>
                {
                  <a
                    style={{
                      textAlign: "center",
                      textDecoration: "underline",
                      color: "#0bc9f6",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                    onClick={this.modalPOPup}
                  >
                    {ele.customerFeedbackResponse.feedbackId || ""}
                  </a>
                }
              </div>

              <div>
                {formatDate(
                  parseDate(ele.customerFeedbackResponse.createdDate)
                )}
              </div>

              <div>
                {this.getCustomerNamelink(ele.customerFeedbackResponse)}
              </div>
              <div>
                <a
                  style={{
                    textAlign: "center",
                    textDecoration: "underline",
                    color: "#0bc9f6",
                    fontWeight: "500",
                  }}
                  target="_blank"
                  href={
                    "/viewrepcustomer?id=" + ele.customerFeedbackResponse.repId
                  }
                >
                  {ele.customerFeedbackResponse.repName}
                </a>
              </div>
              <div>{ele.customerFeedbackResponse.companyName}</div>
              <div>
                <div>{ele.disputedBy}</div>
              </div>

              <span
                onClick={this.handleToggleForm}
                style={{ paddingLeft: "5px" }}
              >
                <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
              </span>
            </Row>
            {isOpen && (
              <EditForm
                {...this.props}
                labelComment="Disputer’s Comment"
                handleUpdateFeedbackDispute={
                  this.props.handleUpdateFeedbackDispute
                }
                fromDispute
              />
            )}
          </>
        )}
        {this.props.fromClaims &&
          ele.claims?.map((i) => {
            return (
              <ClaimsList
                {...this.props}
                ele={ele}
                i={i}
                handleUpdateClaimStatus={this.props.handleUpdateClaimStatus}
              />
            );
          })}
        {this.props.fromManagePKeys &&
          ele?.map((i) => {
            return (
              <PartnerList
                {...this.props}
                ele={i}
                handleRemovePartnerKey={this.props.handleRemovePartnerKey}
                handleUpdatePartnerKey={this.props.handleUpdatePartnerKey}
              />
            );
          })}
        {this.props.fromManageCKeys &&
          ele?.map((i) => {
            if (i.companyApiKey) {
              return (
                <CompanyApilist
                  {...this.props}
                  ele={i}
                  handleRemoveCompanyKey={this.props.handleRemoveCompanyKey}
                  handleUpdateCompanyKey={this.props.handleUpdateCompanyKey}
                />
              );
            }
          })}
      </Wrapper>
    );
  }
}
