import React from "react";
import styled from "styled-components";

import Spinner from "../../../components/Spinner/Spinner"

import Step1 from "./Step1";
import Step2Customer from "./Step2Customer";
import Step2Rep from "./Step2Rep";
import Step2Company from "./Step2Company";
import Step3CustomerDetails from "./Step3CustomerDetails";
import Step3RepDetails from "./Step3RepDetails";
import Step3RepGenericEmail from "./Step3RepGenericEmail";
import Step3Company from "./Step3Company";
import StepCompanyExisted from "./StepCompanyExisted";
import StepConfirmation from "./StepConfirmation";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin-top: 100px;
  img {
    width: 100px;
    height: 100px;
  }
`;

const View = props => {
  if (props.isFetching || props.isFetchingResponse) {
    return <SpinnerWrapper><Spinner /></SpinnerWrapper>
  }

  switch (props.step) {
    case 1:
      return (
        <Wrapper>
          <Step1
            selectedValue={props.userRole}
            handleChange={props.handleRoleSelection}
            nextStepSubmit={props.handleStepSubmit}
            handleBackExit={props.handleBackExit} />
        </Wrapper>
      );

    case 2:
      switch (props.userRole) {
        case "CUSTOMER":
          return (
            <Wrapper>
              <Step2Customer
                errorMessage={props.stateErrorMessage}
                handleBack={props.handleBack}
                handleClose={props.handleClose}
                handleGetSocialUrl={props.handleGetSocialUrl}
                handleInputChange={props.handleInputChange}
                handleStepSubmit={props.handleStepSubmit} />
            </Wrapper>
          );
        case "REP":
          return (
            <Wrapper>
              <Step2Rep
                invitation={props.invitation}
                company={props.companyPublicInfo}

                validStep={props.step2RepValid}
                handleBack={props.handleBack}
                handleClose={props.handleClose}
                handleInputChange={props.handleInputChange}
                handleGetSocialUrl={props.handleGetSocialUrl}
                handleGetSocialUrlInivited={props.handleGetSocialUrlInivited}
                invitedLink={props.invitedLink}
              />
            </Wrapper>
          );
        case "ADMIN":
          return (
            <Wrapper>
              <Step2Company
                validStep={props.step2Company.valid}
                selectedCountry={props.step2Company.country}
                selectedState={props.step2Company.state}
                industriesList={props.industriesList}
                countryList={props.countryList}
                cityList={props.cityList}
                step2Data={props.step2Company}
                handleBack={props.handleBack}
                handleClose={props.handleClose}
                handleInputChange={props.handleInputChange}
                handleIndustryChange={props.handleIndustryChange}
                handleCountryChange={props.handleCountryChange}
                handleCityChange={props.handleCityChange}
                handleStepSubmit={props.handleStepSubmit} />
            </Wrapper>
          );
        default:
          return null;
      }

    case 3:
      switch (props.userRole) {
        case "CUSTOMER":
          return (
            <Wrapper>
              <Step3CustomerDetails
                validStep={props.step3Customer.valid}
                validSocialData={props.socialDataValid}
                selectedCountry={props.step3Customer.country}
                selectedState={props.step3Customer.state}
                countryList={props.countryList}
                cityList={props.cityList}
                step3Data={props.step3Customer}
                handleBack={props.handleBack}
                handleClose={props.handleClose}
                handleInputChange={props.handleInputChange}
                handleRecaptchaChange={props.handleRecaptchaChange}
                handleCountryChange={props.handleCountryChange}
                handleCityChange={props.handleCityChange}
                handleStepSubmit={props.handleStepSubmit} />
            </Wrapper>
          );
        case "REP":
          return (
            <Wrapper>
              <Step3RepDetails
                validSocialData={props.socialDataValid}
                step3Data={props.step3Rep}
                handleBack={props.handleBack}
                handleClose={props.handleClose}
                handleInputChange={props.handleInputChange}
                handleRecaptchaChange={props.handleRecaptchaChange}
                handleStepSubmit={props.handleStepSubmit}
                functions={props.functions}
                functionSelected={props.functionSelected}
                handleFunctionsChange={props.handleFunctionsChange}
                invitedLink={props.invitedLink}
              />
            </Wrapper>
          );
        case "ADMIN":
          return (
            <Wrapper>
              <Step3Company
                step3Data={props.step3Company}
                handleBack={props.handleBack}
                handleClose={props.handleClose}
                handleGetSocialUrl={props.handleGetSocialUrl}
                handleInputChange={props.handleInputChange}
                handleStepSubmit={props.handleStepSubmit} />
            </Wrapper>
          );
        default:
          return (
            <Wrapper>
              {JSON.stringify(props)}
            </Wrapper>
          );
      }

    case 3.5:
      return (
        <Wrapper>
          <Step3RepGenericEmail
            step3Data={props.socialData}
            handleBack={props.handleBack}
            handleClose={props.handleClose}
            handleInputChange={props.handleInputChange}
            handleStepSubmit={props.handleStepSubmit} />
        </Wrapper>
      );
    case 4:
      switch (props.userRole) {
        case "CUSTOMER":
          return (
            <Wrapper>
              <StepConfirmation
                customMessage="Your Repora account has been created and we have just sent you an email."
               customMessage2="To login, please verify the link in the email in the next 24 hours."
                responseMessage={props.responseSignUp}
                handleSignUpFinish={props.handleSignUpFinish} />
            </Wrapper>
          );
        case "REP":
          return (<Wrapper>
            <StepConfirmation
              picture={props.socialData.picture}
              accountName={props.socialData.firstName || ""}
              customMessage={"Your Repora account has been created and we have sent you an email at " + props.socialData.email}
              customMessage2="To login, please verify the link in the email in the next 24 hours."
              responseMessage={props.responseSignUp}
              handleSignUpFinish={props.handleSignUpFinish} />
          </Wrapper>)
        case "ADMIN":
          if (props.responseSignUp && props.responseSignUp.payload === "COMPANY_EXIST") {
            return (
              <Wrapper>
                <StepCompanyExisted handleSignUpFinish={props.handleSignUpFinish} />
              </Wrapper>
            );
          } else {
            return (
              <Wrapper>
                <StepConfirmation
                  userRole={props.userRole}
                  accountName={props.step2Company.companyName || ""}
                  customMessage="Your Repora account has been created and we have just sent you an email."
                  customMessage2="To login, please verify the link in the email in the next 24 hours."
                  responseMessage={props.responseSignUp}
                  handleSignUpFinish={props.handleSignUpFinish} />
              </Wrapper>
            );
          }
        default:
          return null;
      }
    default:
      return null;
  }
};

export default View;
