import React from "react";
import styled from "styled-components";
import moment from "moment";
import ReactTooltip from "react-tooltip";

import {
  reporaDevice,
  reporaColor,
  ReporaButton,
  Header2,
  Body2,
  Body4,
  Body5,
} from "../../../components/global/commonStyles";
import Spinner from "../../../components/Spinner/Spinner";
import { parseDate, formatDate } from "../../../components/global/utils.js";
import constants from "../../../constants";

import PersonCard from "./PersonCard";
import RatingsCard from "./RatingsCard";
import ExperienceCard from "./ExperienceCard";
import CompanyCard from "./CompanyCard";
import FeedbackCard from "./FeedbackCard";
import ContactOptions from "./ContactOptions";

const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  padding: 0 15px 20px;
  margin: 0 auto;

  @media ${reporaDevice.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const HalfWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  flex-shrink: 0;

  @media ${reporaDevice.tablet} {
    width: calc(50% - 5px);
  }

  @media ${reporaDevice.desktop} {
    width: calc(50% - 25px);
  }
`;

const SectionHeader = styled.div`
  font-size: 18px;
  line-height: 26px;
  padding: 30px 0;
`;

const FeedbackHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-bottom: 10px;
  @media ${reporaDevice.desktop} {
    flex-direction: row;
  }
`;

const FeedbackTitle = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  @media ${reporaDevice.desktop} {
    width: 50%;
  }
`;

const FeedbackActions = styled.div`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;

  a:last-child {
    margin-right: 10px;
  }

  @media ${reporaDevice.desktop} {
    width: 50%;
    flex-direction: row;

    a:last-child {
      margin-right: 0px;
      margin-left: 10px;
    }
  }
  @media ${reporaDevice.sm} {
    margin: 3% 0% 0% 0%;
  }
  @media ${reporaDevice.xs} {
    margin: 3% 0% 0% 0%;
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin-top: 100px;

  img {
    width: 100px;
    height: 100px;
  }
`;

function formatLocation(address) {
  if (address) {
    const city = address.city ? `${address.city}, ` : "";
    const state = address.state ? `${address.state} ` : "";
    const zipCode = address.zipCode ? address.zipCode : "";
    return `${city}${state}${zipCode}`.trim();
  }
  return "";
}

const CustomerRepView = (props) => {
  let repData = props.repProfile;
  let previousContent = null;
  let companyCard = null;
  if (props.isFetching) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  } else if (
    (repData.payload === undefined || repData.payload === null) &&
    repData.statusCode !== 200
  ) {
    return (
      <ErrorWrapper>
        <Header2>Error!</Header2>
        <Body2>{repData.message || "No Info available for the given id"}</Body2>
      </ErrorWrapper>
    );
  } else {
    repData = repData.payload;
    const feedList = repData.customerFeedbackList
      ? repData.customerFeedbackList
      : [];
    feedList.sort((a, b) => {
      var reg = /-|:|T|\+/; //The regex on which matches the string should be split (any used delimiter) -> could also be written like /[.:T\+]/
      var parsed = [
        //an array which holds the date parts for a and b
        a.createdDate.split(reg), //Split the datestring by the regex to get an array like [Year,Month,Day,Hour,...]
        b.createdDate.split(reg),
      ];
      var dates = [
        //Create an array of dates for a and b
        new Date(
          parsed[0][0],
          parsed[0][1],
          parsed[0][2],
          parsed[0][3],
          parsed[0][4],
          parsed[0][5]
        ), //Constructs an date of the above parsed parts (Year,Month...
        new Date(
          parsed[1][0],
          parsed[1][1],
          parsed[1][2],
          parsed[1][3],
          parsed[1][4],
          parsed[1][5]
        ),
      ];
      return dates[1] - dates[0]; //Returns the difference between the date (if b > a then a - b < 0)
    });

    const previousCompanies = props.previousCompanies.filter(
      (c) => c.current === false
    );
    if (previousCompanies && previousCompanies.length > 0) {
      previousContent = <SectionHeader>Previous Companies</SectionHeader>;

      companyCard = previousCompanies
        .sort((a, b) =>
          moment.duration(moment(b.startDate).diff(moment(a.startDate)))
        )
        .map((company, index) => {
          const startDate = formatDate(parseDate(company.startDate), "");
          const endDate = formatDate(parseDate(company.endDate), "");
          const date = startDate + " - " + endDate;
          const location = formatLocation(company.physicalAddress);
          return (
            <CompanyCard
              key={"company" + index}
              companyName={company.name}
              date={date}
              location={location}
              experience={company.productSpeciality}
            />
          );
        });
    } else {
      if (!repData.unclaimed) {
        previousContent = (
          <SectionHeader>
            <Body2>Previous Companies</Body2>
            <Body5 textColor={reporaColor.brightSkyBlue}>
              {" "}
              Rep have no previous companies yet.
            </Body5>
          </SectionHeader>
        );
      }
    }

    let unclaimed = repData.unclaimed;
    let repName = repData.firstName + " " + repData.lastName;
    let ctaContent = "";
    let feedbackList = "";
    let userRole = "";
    let feedBackAction = "";
    const averageList = repData.averagePerCategory;
    let ratingCard = <RatingsCard categories={averageList} />;
    let customeRatingCard = "";
    if (repData.averagePerCustomCategory) {
      customeRatingCard = (
        <RatingsCard categories={repData.averagePerCustomCategory} />
      );
    }
    if (props.authUser) {
      userRole = props.authUser.userRole;
    }
    if (unclaimed && userRole === "REP") {
      ctaContent = (
        <ReporaButton
          data-tip
          data-for="claimTip"
          onClick={() => {
            props.history.push(`/claimaccount?id=${repData.id}`);
          }}
        >
          Claim this Account
          <ReactTooltip
            id="claimTip"
            data-effect="solid"
            place="bottom"
            disable={constants.IS_MOBILE}
          >
            <span>
              You can claim accounts
              <br />
              that you believe were
              <br />
              created for you
            </span>
          </ReactTooltip>
        </ReporaButton>
      );
    } else {
      if (userRole) {
        if (userRole !== "REP") {
          if (
            userRole !== "ADMIN" &&
            userRole !== "REGULAR" &&
            userRole !== "REPORA_ADMIN"
          ) {
            ctaContent = (
              <ReporaButton onClick={props.handleRateRepRedirect}>
                {repData.ratedByCustomer
                  ? "Rate Again"
                  : `Rate ${repData.firstName}`}
              </ReporaButton>
            );
          }
        }
      } else {
        ctaContent = (
          <ReporaButton onClick={props.handleRateRepRedirect}>
            Rate Again{" "}
          </ReporaButton>
        );
      }
    }

    if (props.authUser) {
      if (repData.customerFeedbackList) {
        feedbackList = repData.customerFeedbackList.filter(
          (f) => f.reviewerId === props.authUser.id
        );
      }
      repData.ratedByCustomer = feedbackList.length > 0;
      userRole = props.authUser.userRole;
      ratingCard = (
        <RatingsCard
          categories={averageList}
          userRole={props.authUser.userRole}
        />
      );
      feedBackAction = (
        <FeedbackActions>
          {props.authUser.userRole == "CUSTOMER" && (
            <ContactOptions
              repName={repData.firstName}
              options={props.contactOptions}
              handleOptionClick={props.handleContactOptionClick}
            />
          )}
          {ctaContent}
        </FeedbackActions>
      );
    } else {
      feedBackAction = <FeedbackActions> {ctaContent} </FeedbackActions>;
    }
    const publicRep = props.repProfile.payload.currentCompanyPrivate;
    let nohiderating = true;
    if (
      repData.currentCompanyConnected == true &&
      repData.currentCompanyPrivate == true
    ) {
      nohiderating = false;
    }
    return (
      <Wrapper>
        <HalfWrapper>
          <PersonCard
            name={repName}
            unclaimed={repData.unclaimed}
            rate={repData.average}
            phone={repData.phone}
            territory={repData.territory}
            companyAddress={repData.companyAddress}
            image={repData.photoLinkedin}
            work={repData.work}
            repCompanies={props.repCompanies}
            productSpeciality={repData.productSpeciality}
            companyStartDate={repData.companyStartDate}
            currentCompany={repData.currentCompany}
            previousCompany={repData.previousCompany}
            currentCompanyConnected={repData.currentCompanyConnected}
            currentCompanyPrivate={repData.currentCompanyPrivate}
          />
          {nohiderating && <SectionHeader>Average Ratings</SectionHeader>}
          {nohiderating && ratingCard}
          {nohiderating && (
            <ExperienceCard thumbsUpAverage={repData.thumbsUpAverage} />
          )}
          {nohiderating && customeRatingCard}
          {nohiderating && window.screen.availWidth <= "767"
            ? feedBackAction
            : ""}
          {nohiderating &&
            previousCompanies &&
            previousCompanies.length > 0 &&
            previousContent}
          {nohiderating && companyCard}
        </HalfWrapper>
        <HalfWrapper>
          <FeedbackHeader>
            <FeedbackTitle>
              <Body2>Customer Feedback</Body2>
              {(() => {
                if (nohiderating) {
                  return (
                    <Body4 className="m-l-5" textColor={reporaColor.warmGrey}>
                      ({repData.feedbackCount} Total)
                    </Body4>
                  );
                } else {
                  return (
                    <Body4 className="m-l-5" textColor={reporaColor.warmGrey}>
                      ({feedList.length} Total)
                    </Body4>
                  );
                }
              })()}
            </FeedbackTitle>
            {window.screen.availWidth <= "767" ? "" : feedBackAction}
          </FeedbackHeader>
          {repData.feedbackCount > 0 &&
            feedList.map((feed, index) => {
              const key = `feed${index}`;
              let repFeedCustomer = "";
              if (feed.feedbackResponseList) {
                repFeedCustomer = feed.feedbackResponseList[0]["reviewDetail"];
              }

              return (
                <FeedbackCard
                  {...props}
                  unclaim={unclaimed}
                  key={key}
                  data={feed}
                  upvoteRequest={props.upvoteRequest[feed.feedbackId] || {}}
                  handleUpvote={props.handleUpvote}
                  publicRep={publicRep}
                  userRole={props.authUser ? props.authUser.userRole : false}
                  repFeedBack={repFeedCustomer}
                  repFeedBackData={feed.feedbackResponseList}
                  handleupVoteRepFeedCustomer={
                    props.handleupVoteRepFeedCustomer
                  }
                  currentCompanyPrivate={repData.currentCompanyPrivate}
                  currentCompanyConnected={repData.currentCompanyConnected}
                  feedbackViewType={"customer"}
                />
              );
            })}
        </HalfWrapper>
      </Wrapper>
    );
  }
};

export default CustomerRepView;
