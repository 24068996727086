const Data = {
  hero: {
    header: "Optimize your sales force",
    descriptionLine1: "Harness the power of your customers with RepScore,",
    descriptionLine2: "to gather honest and unbiased feedback on the performance of your reps.",
    buttonTxt: "Get Started"
  },
  heroVideo: {
    videoId: "txQ6t4yPIM0",
    videoPoster: "/assets/home/hero-video-poster.png"
  },
  ratingSystem: {
    header: "RepScore is a standardized rating system for product, service, and clinical reps",
    title: "RepScore Allows Customers to",
    columns: [
      { imgSrc: "/assets/home/ratings-column1-2x.png", header: "Provide Ratings", description: "Customers rate the reps they interact with, based on the key components of what makes a great customer-rep relationship." },
      { imgSrc: "/assets/home/ratings-column2.png", header: "Write Comments", description: "Unfiltered, immediate feedback that lets a rep, their company, and other customers know how they are doing or how to improve." },
      { imgSrc: "/assets/home/ratings-column3.png", header: "Improve Quality", description: "By rating the reps you interact with, you help out other customers just like you. And when reps know how to improve, it makes your experience better." },
    ]
  },
  repscoreProvides: {
    bkgImgSrc: "/assets/home/repscore-provides.jpg",
    imgHeight: 552,
    imgWidth: 1439,
    title: "REPSCORE PROVIDES",
    header: "Qualitative feedback you’re missing out on",
    description: "Start learning from your most important asset - your customers. Uncover the underlying factors that are hindering the success of your sales team.",
    buttonTxt: ""
  },
  forCompanies: {
    imgSrc: "/assets/home/companies2x.png",
    title: "FOR COMPANIES",
    header: "The insight you need to take action",
    description: "Identify and target where your team needs the most help.  Know the moment a negative trend begins, and how you can improve it.  ",
    buttonTxt: "Learn More",
    href: "/featurescompanies"
  },
  forCustomers: {
    imgSrc: "/assets/home/customers2x.png",
    title: "FOR CUSTOMERS",
    header: "View ratings and comments",
    description: "Know what to expect from a rep, before meeting with them. Understand their strengths and weaknesses, based on their experiences with other customers.",
    buttonTxt: "Learn More",
    secondary: true,
    href: "/featurescustomers"

  },
  forReps: {
    imgSrc: "/assets/home/reps.jpg",
    title: "FOR REPS",
    header: "Sell smarter with smarter feedback",
    description: "Improve your skills and become more efficient by identifying your strengths and weaknesses.",
    buttonTxt: "Learn More",
    frgImgSrc: "/assets/home/reps-rating.png",
    href: "/featuresreps"
  }
}

export default Data;
