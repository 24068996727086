import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Footer from "../../components/FooterUpdated/Footer";
import { reporaColor } from "../../components/global/commonStyles";
import Card from "./components/Card";
import { reporaDevice } from "../../components/global/commonStyles";
import Button from "../../components/Buttons/Button";
import ThumbUpIcon from "./assets/thumb_up.svg";
import ThumbDownIcon from "./assets/thumb_down.svg";

import StartBackground from "./assets/start-background.svg";
import RepscoreImage from "./assets/repscore.png";
import WomanBackground from "./assets/woman-background.jpg";
import RateCardImage from "./assets/rate-card.png";

const Section1 = styled.div`
  background: white;
  height: 485px;
  display: flex;
  flex-direction: column;
  color: ${reporaColor.white};
  text-align: center;

  background-image: linear-gradient(
    297deg, 
    rgba(5, 206, 124, 0.68), 
    rgba(11, 201, 244, 0.85) 98%,
    rgba(11, 201, 246, 0.85)
  );

  h1 {
    font-size: 52px;
    font-weight: bold;
    line-height: 1.25;
    margin-top: 95px;
    margin-bottom: 35px;
  }

  @media ${reporaDevice.xs} {
    h1 {
      margin-top: 50px;
    }
  }
`;

const Section2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    width: 299px;
    margin-top: -70px;
  }
`;

const Section3 = styled.div`
  margin-top: 140px;
  text-align: center;

  h2 {
    font-size: 42px;
    font-weight: bold;
    line-height: 1.19;
  }

  >div {
    display: flex;
    flex-direction: column;
    margin: 130px 0;

    >div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &:first-of-type {
        margin-bottom: 40px;
      }
    }
  }

  @media ${reporaDevice.stmd} {
    margin-top: 70px;

    h2 {
      font-size: 30px;
    }

    >div {
      margin: 70px 0;
    }
  }
`;

const Section4 = styled.div`
  background: url(${WomanBackground});
  background-size: cover;
  height: 550px;

  h2 {
    font-size: 42px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    margin-bottom: 25px;
  }

  >div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    text-align: right;
  }

  @media ${reporaDevice.xs} {
    background-position: -300px;
  }
`;

const Section5 = styled.div`
  background: white;
  padding-top: 100px;
  padding-bottom: 100px;

  >section {
    display: flex;

    >div {
      flex: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    >div:last-of-type {
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      margin-left: 50px;
    }
  }

  img {
    width: 100%;
    max-width: 470px;
  }

  h2 {
    font-size: 42px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    margin-bottom: 30px;
  }

  @media ${reporaDevice.xs} {
    >section {
      flex-direction: column;

      >div:last-of-type {
        justify-content: center;
        margin: 0;
        margin-top: 50px;
      }
    }
  }
`;

const Section6 = styled.div`
  background: url(${StartBackground});
  color:white;
  background-color: white;
  background-position: top;
  padding: 100px 0;

  h2 {
    color: white;
    font-size: 42px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.55;
    margin-bottom: 30px;
    text-align: center;
  }

  >div {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  @media ${reporaDevice.xs} {
    padding-top: 100px;
  }
`;

class HowRepscore extends React.Component {
  render() {
    return (
      <section>
        <Section1>
          <div className="sectionWrapper">
            <h1>
              <span>How is RepScore</span>
              <br></br>
              <span>calculated?</span>
            </h1>
            <p>RepScore measures the expertise/skills of a representative along with the </p>
            <p>overall experience of a customer. Unlike the star rating model, the proprietary</p>
            <p>algorithm of RepScore is a more sophisticated and intelligent representation of</p>
            <p>the representative in some key ways described below.</p>
          </div>
        </Section1>

        <Section2>
          <img src={RepscoreImage} alt="repscore"/>
        </Section2>

        <Section3 className="sectionWrapper">
          <h2>
            <span>Some rating attributes that are most illustrative of a rep’s capabilities are weighted higher than others.</span>
          </h2>
          <div>
            <div>
              <Card title="Product Knowledge" stars={5} big></Card>
              <Card title="Communication Skills" stars={5} big></Card>
              <Card title="Trustworthiness" stars={5} big></Card>
            </div>
            <div>
              <Card title="Professionalism" stars={5}></Card>
              <Card title="Organization" stars={5}></Card>
              <Card title="Availability" stars={5}></Card>
              <Card title="Follow Through" stars={5}></Card>
              <Card title="Punctuality" stars={5}></Card>
            </div>
          </div>
        </Section3>

        <Section4>
          <div className="sectionWrapper">
            <h2>Overall<br/>Customer<br/>Satisfaction</h2>
            <p>A customer’s overall experience is highly weighted</p><br/>
            <p><img style={{width:"30px",height:"30px"}} src={ThumbUpIcon} alt="Thumbs Up" /> &nbsp;
            <img style={{width:"30px",height:"30px"}} src={ThumbDownIcon} alt="Thumbs Down" /></p>
          </div>
        </Section4>

        <Section5>
          <section className="sectionWrapper">
            <div>
              <h2>Customer Weighting</h2>
              <p>Let’s face it, some feedback is more valuable than others. For example, anonymous feedback is weighted lower than feedback from customers who identify themselves. Customers who create an account, especially via a social network rather than a custom email/password, are weighted higher. A customer with a long history of broad feedback is weighted higher than a newcomer who arrived yesterday and has only provided one feedback.</p>
            </div>

            <div>
              <img src={RateCardImage} alt="rate card" />
            </div>
          </section>
        </Section5>

        <Section6>
          <div className="sectionWrapper">
            <p>There is even more intelligence embedded in the RepScore calculation, but we hope the above gives you a fair idea.</p>
          </div>
        </Section6>
      </section>
    );
  }
}

export default HowRepscore;
