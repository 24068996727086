import CompanyService from "../services/CompanyService";
import authService from "../services/AuthService";

import { UPDATE_USER_INFO_REQUESTED } from "./authActions";
import { viewRepCompany } from "./repActions";
import { requestAttrList } from "./reportingPreferencesAction";
/* Action Constants */
export const GET_COMPANIES_REQUEST = "GET_COMPANIES_REQUEST";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAILURE = "GET_COMPANIES_FAILURE";

export const GET_REPLIST_REQUEST = "GET_REPLIST_REQUEST";
export const GET_REPLIST_SUCCESS = "GET_REPLIST_SUCCESS";
export const GET_REPLIST_FAILURE = "GET_REPLIST_FAILURE";

export const GET_REPATTRLIST_REQUEST = "GET_REPATTRLIST_REQUEST";
export const GET_REPATTRLIST_SUCCESS = "GET_REPATTRLIST_SUCCESS";
export const GET_ROLELIST_SUCCESS = "GET_ROLELIST_SUCCESS";
export const GET_REPATTRLIST_FAILURE = "GET_REPATTRLIST_FAILURE";

//end

export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAILURE = "GET_COMPANY_FAILURE";

export const GET_INFO_REQUEST = "GET_INFO_REQUEST";
export const GET_INFO_SUCCESS = "GET_INFO_SUCCESS";
export const GET_INFO_FAILURE = "GET_INFO_FAILURE";

export const UPDATE_COMPANY_INFO_REQUEST = "UPDATE_COMPANY_INFO_REQUEST";
export const UPDATE_COMPANY_INFO_SUCCESS = "UPDATE_COMPANY_INFO_SUCCESS";
export const UPDATE_COMPANY_INFO_FAILURE = "UPDATE_COMPANY_INFO_FAILURE";
export const UPDATE_COMPANY_INFO_RESET = "UPDATE_COMPANY_INFO_RESET";

export const GET_PREVIOUS_COMPANIES_REQUEST = "GET_PREVIOUS_COMPANIES_REQUEST";
export const GET_PREVIOUS_COMPANIES_SUCCESS = "GET_PREVIOUS_COMPANIES_SUCCESS";
export const GET_PREVIOUS_COMPANIES_FAILURE = "GET_PREVIOUS_COMPANIES_FAILURE";

export const GET_COMPANY_REPLIST_REQUEST = "GET_COMPANY_REPLIST_REQUEST";
export const GET_COMPANY_REPLIST_SUCCESS = "GET_COMPANY_REPLIST_SUCCESS";
export const GET_COMPANY_REPLIST_FAILURE = "GET_COMPANY_REPLIST_FAILURE";

export const UPDATE_COMPANY_LOGO_REQUEST = "UPDATE_COMPANY_LOGO_REQUEST";
export const UPDATE_COMPANY_LOGO_SUCCESS = "UPDATE_COMPANY_LOGO_SUCCESS";
export const UPDATE_COMPANY_LOGO_FAILURE = "UPDATE_COMPANY_LOGO_FAILURE";
export const UPDATE_COMPANY_LOGO_RESET = "UPDATE_COMPANY_LOGO_RESET";

export const DELETE_COMPANY_LOGO_REQUESTED = "DELETE_COMPANY_LOGO_REQUESTED";
export const DELETE_COMPANY_LOGO_SUCCEEDED = "DELETE_COMPANY_LOGO_SUCCEEDED";
export const DELETE_COMPANY_LOGO_FAILED = "DELETE_COMPANY_LOGO_FAILED";
export const DELETE_COMPANY_LOGO_RESET = "DELETE_COMPANY_LOGO_RESET";

export const MINIMUM_RATING_REQUEST = "MINIMUM_RATING_REQUEST";
export const MINIMUM_RATING_SUCCESS = "MINIMUM_RATING_SUCCESS";
export const MINIMUM_RATING_FAILURE = "MINIMUM_RATING_FAILURE";

export const GLOBAL_VERIFIED_CUSTOMER_SELECTED =
  "GLOBAL_VERIFIED_CUSTOMER_SELECTED";
export const GLOBAL_PROGRAM_SELECTED = "GLOBAL_PROGRAM_SELECTED";

export const GLOBAL_PROGRAM_CHANGE = "GLOBAL_PROGRAM_CHANGE";
export const GLOBAL_TEAM_CHANGE = "GLOBAL_TEAM_CHANGE";
export const GLOBAL_ANONYMOUS_RATING_SELECTED =
  "GLOBAL_ANONYMOUS_RATING_SELECTED";

export const GET_MAPS_DATA_REQUESTED = "GET_MAPS_DATA_REQUESTED";

export const GET_DASHBOARD_COMPANY_INFO_REQUESTED =
  "GET_DASHBOARD_COMPANY_INFO_REQUESTED";
export const GET_DASHBOARD_COMPANY_INFO_SUCCEEDED =
  "GET_DASHBOARD_COMPANY_INFO_SUCCEEDED";
export const GET_DASHBOARD_COMPANY_INFO_FAILED =
  "GET_DASHBOARD_COMPANY_INFO_FAILED";

export const GET_HOTSPOT_REPORT_REQUESTED = "GET_HOTSPOT_REPORT_REQUESTED";
export const GET_HOTSPOT_REPORT_SUCCEEDED = "GET_HOTSPOT_REPORT_SUCCEEDED";
export const GET_HOTSPOT_REPORT_FAILED = "GET_HOTSPOT_REPORT_FAILED";

export const GET_COMPANY_AVERAGE_RATINGS_REQUESTED =
  "GET_COMPANY_AVERAGE_RATINGS_REQUESTED";
export const GET_COMPANY_AVERAGE_RATINGS_SUCCEEDED =
  "GET_COMPANY_AVERAGE_RATINGS_SUCCEEDED";
export const GET_COMPANY_AVERAGE_RATINGS_FAILED =
  "GET_COMPANY_AVERAGE_RATINGS_FAILED";

export const GET_COMPANY_REP_TRENDS_REQUESTED =
  "GET_COMPANY_REP_TRENDS_REQUESTED";
export const GET_COMPANY_REP_TRENDS_SUCCEEDED =
  "GET_COMPANY_REP_TRENDS_SUCCEEDED";
export const GET_COMPANY_REP_TRENDS_FAILED = "GET_COMPANY_REP_TRENDS_FAILED";

export const GET_COMPANY_MAPS_REQUESTED = "GET_COMPANY_MAPS_REQUESTED";
export const GET_COMPANY_MAPS_SUCCEEDED = "GET_COMPANY_MAPS_SUCCEEDED";
export const GET_COMPANY_MAPS_FAILED = "GET_COMPANY_MAPS_FAILED";

export const REPORT_ABUSE_REQUESTED = "REPORT_ABUSE_REQUESTED";
export const REPORT_ABUSE_SUCCEEDED = "REPORT_ABUSE_SUCCEEDED";
export const REPORT_ABUSE_FAILED = "REPORT_ABUSE_FAILED";

export const GET_COMPANY_PUBLIC_INFO_REQUESTED =
  "GET_COMPANY_PUBLIC_INFO_REQUESTED";
export const GET_COMPANY_PUBLIC_INFO_SUCCEEDED =
  "GET_COMPANY_PUBLIC_INFO_SUCCEEDED";
export const GET_COMPANY_PUBLIC_INFO_FAILED = "GET_COMPANY_PUBLIC_INFO_FAILED";

export const ASSIGNED_ROLES_REQUEST = "ASSIGNED_ROLES_REQUEST";
export const ASSIGNED_ROLES_SUCCESS = "ASSIGNED_ROLES_SUCCESS";
export const ASSIGNED_ROLES_FAILURE = "ASSIGNED_ROLES_FAILURE";

export const GET_USER_COMPANIES_REQUESTED = "GET_USER_COMPANIES_REQUESTED";
export const GET_USER_COMPANIES_SUCCEEDED = "GET_USER_COMPANIES_SUCCEEDED";
export const GET_USER_COMPANIES_FAILED = "GET_USER_COMPANIES_FAILED";

export const DELETE_USER_COMPANY_REQUESTED = "DELETE_USER_COMPANY_REQUESTED";
export const DELETE_USER_COMPANY_SUCCEEDED = "DELETE_USER_COMPANY_SUCCEEDED";
export const DELETE_USER_COMPANY_FAILED = "DELETE_USER_COMPANY_FAILED";

export const ADD_USER_COMPANY_REQUESTED = "ADD_USER_COMPANY_REQUESTED";
export const ADD_USER_COMPANY_SUCCEEDED = "ADD_USER_COMPANY_SUCCEEDED";
export const ADD_USER_COMPANY_FAILED = "ADD_USER_COMPANY_FAILED";

export const UPDATE_USER_COMPANY_REQUESTED = "UPDATE_USER_COMPANY_REQUESTED";
export const UPDATE_USER_COMPANY_SUCCEEDED = "UPDATE_USER_COMPANY_SUCCEEDED";
export const UPDATE_USER_COMPANY_FAILED = "UPDATE_USER_COMPANY_FAILED";

export const UPDATE_USER_CURRENT_COMPANY_REQUESTED =
  "UPDATE_USER_CURRENT_COMPANY_REQUESTED";
export const UPDATE_USER_CURRENT_COMPANY_SUCCEEDED =
  "UPDATE_USER_CURRENT_COMPANY_SUCCEEDED";
export const UPDATE_USER_CURRENT_COMPANY_FAILED =
  "UPDATE_USER_CURRENT_COMPANY_FAILED";

export const ADD_COMPANY_REQUESTED = "ADD_COMPANY_REQUESTED";
export const ADD_COMPANY_SUCCEEDED = "ADD_COMPANY_SUCCEEDED";
export const ADD_COMPANY_FAILED = "ADD_COMPANY_FAILED";

export const GET_COMPANY_SUGGESTIONS_REQUESTED =
  "GET_COMPANY_SUGGESTIONS_REQUESTED";
export const GET_COMPANY_SUGGESTIONS_NEW_REP_REQUESTED =
  "GET_COMPANY_SUGGESTIONS_NEW_REP_REQUESTED";
export const GET_COMPANY_SUGGESTIONS_SUCCEEDED =
  "GET_COMPANY_SUGGESTIONS_SUCCEEDED";
export const GET_COMPANY_SUGGESTIONS_FAILED = "GET_COMPANY_SUGGESTIONS_FAILED";

export const ADD_COMPANY_CUSTOME_ATTRIBUTE_REQUESTED =
  "ADD_COMPANY_CUSTOME_ATTRIBUTE_REQUESTED";
export const ADD_COMPANY_CUSTOME_ATTRIBUTE_SUCCEEDED =
  "ADD_COMPANY_CUSTOME_ATTRIBUTE_SUCCEEDED";
export const ADD_COMPANY_CUSTOME_ATTRIBUTE_FAILED =
  "ADD_COMPANY_CUSTOME_ATTRIBUTE_FAILED";

export const GET_COMPANY_CUSTOME_ATTRIBUTE_REQUEST =
  "GET_COMPANY_CUSTOME_ATTRIBUTE_REQUEST";
export const GET_COMPANY_CUSTOME_ATTRIBUTE_SUCCESS =
  "GET_COMPANY_CUSTOME_ATTRIBUTE_SUCCESS";
export const GET_COMPANY_CUSTOME_ATTRIBUTE_FAILURE =
  "GET_COMPANY_CUSTOME_ATTRIBUTE_FAILURE";

export const UPDATE_COMPANY_CUSTOME_ATTRIBUTE_REQUESTED =
  "UPDATE_COMPANY_CUSTOME_ATTRIBUTE_REQUESTED";
export const UPDATE_COMPANY_CUSTOME_ATTRIBUTE_SUCCEEDED =
  "UPDATE_COMPANY_CUSTOME_ATTRIBUTE_SUCCEEDED";
export const UPDATE_COMPANY_CUSTOME_ATTRIBUTE_FAILED =
  "UPDATE_COMPANY_CUSTOME_ATTRIBUTE_FAILED";

export const DELETE_COMPANY_CUSTOME_ATTRIBUTE_REQUESTED =
  "DELETE_COMPANY_CUSTOME_ATTRIBUTE_REQUESTED";
export const DELETE_COMPANY_CUSTOME_ATTRIBUTE_SUCCEEDED =
  "DELETE_COMPANY_CUSTOME_ATTRIBUTE_SUCCEEDED";
export const DELETE_COMPANY_CUSTOME_ATTRIBUTE_FAILED =
  "DELETE_COMPANY_CUSTOME_ATTRIBUTE_FAILED";

export const CLEAR_COMPANY_CUSTOME_ATTRIBUTE_MESSAGE =
  "CLEAR_COMPANY_CUSTOME_ATTRIBUTE_MESSAGE";
export const CLEAR_CUSTOME_ATTR_LIST = "CLEAR_CUSTOME_ATTR_LIST";

export const UPLOAD_CRM_REQUESTED = "UPLOAD_CRM_REQUESTED";
export const UPLOAD_CRM_SUCCEEDED = "UPLOAD_CRM_SUCCEEDED";
export const UPLOAD_CRM_FAILED = "UPLOAD_CRM_FAILED";
export const CLEAR_CRM_DATA = "CLEAR_CRM_DATA";
export const RESET_CRM_DATA = "RESET_CRM_DATA";

export const UPDATE_CRM_SPECIFIC_RECORD = "UPDATE_CRM_SPECIFIC_RECORD";

export const ADD_CRM_REQUESTED = "ADD_CRM_REQUESTED";
export const ADD_CRM_SUCCEEDED = "ADD_CRM_SUCCEEDED";
export const ADD_CRM_FAILED = "ADD_CRM_FAILED";

export const UPDATE_CRM_REQUESTED = "UPDATE_CRM_REQUESTED";
export const UPDATE_CRM_SUCCEEDED = "UPDATE_CRM_SUCCEEDED";
export const UPDATE_CRM_FAILED = "UPDATE_CRM_FAILED";

export const VALIDATE_CRM_DATA_REQUESTED = "VALIDATE_CRM_DATA_REQUESTED";
export const VALIDATE_CRM_DATA_SUCCEEDED = "VALIDATE_CRM_DATA_SUCCEEDED";
export const VALIDATE_CRM_DATA_FAILED = "VALIDATE_CRM_DATA_FAILED";

export const DELETE_CRM_DATA_REQUESTED = "DELETE_CRM_DATA_REQUESTED";
export const DELETE_CRM_DATA_SUCCEEDED = "DELETE_CRM_DATA_SUCCEEDED";
export const DELETE_CRM_DATA_FAILED = "DELETE_CRM_DATA_FAILED";

export const GET_CRM_USERS_COMPANY_REQUESTED =
  "GET_CRM_USERS_COMPANY_REQUESTED";
export const GET_CRM_USERS_COMPANY_SUCCEEDED =
  "GET_CRM_USERS_COMPANY_SUCCEEDED";
export const GET_CRM_USERS_COMPANY_FAILED = "GET_CRM_USERS_COMPANY_FAILED";

export const GET_CRM_FILE_DATA_REQUESTED = "GET_CRM_FILE_DATA_REQUESTED";
export const GET_CRM_FILE_DATA_SUCCEEDED = "GET_CRM_FILE_DATA_SUCCEEDED";
export const GET_CRM_FILE_DATA_FAILED = "GET_CRM_FILE_DATA_FAILED";

export const COMPANY_WIDE_AVERAGE_REQUESTED = "COMPANY_WIDE_AVERAGE_REQUESTED";
export const COMPANY_WIDE_AVERAGE_SUCCEEDED = "COMPANY_WIDE_AVERAGE_SUCCEEDED";
export const COMPANY_WIDE_AVERAGE_FAILED = "COMPANY_WIDE_AVERAGE_FAILED";

export const COMPANY_DASHBOARD_DETAILS_REQUESTED =
  "COMPANY_DASHBOARD_DETAILS_REQUESTED";
export const COMPANY_DASHBOARD_DETAILS_SUCCEEDED =
  "COMPANY_DASHBOARD_DETAILS_SUCCEEDED";
export const COMPANY_DASHBOARD_DETAILS_FAILED =
  "COMPANY_DASHBOARD_DETAILS_FAILED";

export const COMPANY_DASHBOARD_PROGRAMS_REQUESTED =
  "COMPANY_DASHBOARD_PROGRAMS_REQUESTED";
export const COMPANY_DASHBOARD_PROGRAMS_SUCCEEDED =
  "COMPANY_DASHBOARD_PROGRAMS_SUCCEEDED";
export const COMPANY_DASHBOARD_PROGRAMS_FAILED =
  "COMPANY_DASHBOARD_PROGRAMS_FAILED";

export const GET_CATOGERY_AVERAGE_RATINGS_REQUESTED =
  "GET_CATOGERY_AVERAGE_RATINGS_REQUESTED";
export const GET_CATOGERY_AVERAGE_RATINGS_SUCCEEDED =
  "GET_CATOGERY_AVERAGE_RATINGS_SUCCEEDED";
export const GET_CATOGERY_AVERAGE_RATINGS_FAILED =
  "GET_CATOGERY_AVERAGE_RATINGS_FAILED";

export const GET_CATOGERY_REP_TRENDS_REQUESTED =
  "GET_CATOGERY_REP_TRENDS_REQUESTED";
export const GET_CATOGERY_REP_TRENDS_SUCCEEDED =
  "GET_CATOGERY_REP_TRENDS_SUCCEEDED";
export const GET_CATOGERY_REP_TRENDS_FAILED = "GET_CATOGERY_REP_TRENDS_FAILED";

export const GET_CATOGERY_MAPS_REQUESTED = "GET_CATOGERY_MAPS_REQUESTED";
export const GET_CATOGERY_MAPS_SUCCEEDED = "GET_CATOGERY_MAPS_SUCCEEDED";
export const GET_CATOGERY_MAPS_FAILED = "GET_CATOGERY_MAPS_FAILED";

export const PROGRAM_DATA_REQUESTED = "PROGRAM_DATA_REQUESTED";
export const PROGRAM_DATA_SUCCEEDED = "PROGRAM_DATA_SUCCEEDED";
export const PROGRAM_DATA_FAILED = "PROGRAM_DATA_FAILED ";

export const GET_CATOGERY_HOTSPOT_REPORT_REQUESTED =
  "GET_CATOGERY_HOTSPOT_REPORT_REQUESTED";
export const GET_CATOGERY_HOTSPOT_REPORT_SUCCEEDED =
  "GET_CATOGERY_HOTSPOT_REPORT_SUCCEEDED";
export const GET_CATOGERY_HOTSPOT_REPORT_FAILED =
  "GET_CATOGERY_HOTSPOT_REPORT_FAILED";

export const RATE_CATOGERY_REQUEST = "RATE_CATOGERY_REQUEST";
export const RATE_CATOGERY_SUCCESS = "RATE_CATOGERY_SUCCESS";
export const RATE_CATOGERY_FAILURE = "RATE_CATOGERY_FAILURE";
export const RATE_CATOGERY_RESET = "RATE_CATOGERY_RESET";

export const COMPANY_LOGO = "COMPANY_LOGO";
export const RATINGPAGE_COMPANY_LOGO = "RATINGPAGE_COMPANY_LOGO";

export const GET_COMPANY_PROGRAM_LIST_REQUEST =
  "GET_COMPANY_PROGRAM_LIST_REQUEST";
export const GET_COMPANY_PROGRAM_LIST_SUCCESS =
  "GET_COMPANY_PROGRAM_LIST_SUCCESS";
export const GET_COMPANY_PROGRAM_LIST_FAILURE =
  "GET_COMPANY_PROGRAM_LIST_FAILURE";

export const VIEW_CATOGERY_DATA_REQUESTED = "VIEW_CATOGERY_DATA_REQUESTED";
export const VIEW_CATOGERY_DATA_SUCCEEDED = "VIEW_CATOGERY_DATA_SUCCEEDED";
export const VIEW_CATOGERY_DATA_FAILED = "VIEW_CATOGERY_DATA_FAILED ";

export const ADD_TAG_TO_FEEEDBACK = "ADD_TAG_TO_FEEEDBACK";
export const ADD_TAG_TO_FEEEDBACK_SUCCESS = "ADD_TAG_TO_FEEEDBACK_SUCCESS";
export const ADD_TAG_TO_FEEEDBACK_ERROR = "ADD_TAG_TO_FEEEDBACK_ERROR";

export const UPDATE_PREFERENCE_REQUESTED = "UPDATE_PREFERENCE_REQUESTED";
export const UPDATE_PREFERENCE_SUCCEEDED = "UPDATE_PREFERENCE_SUCCEEDED";
export const UPDATE_PREFERENCE_ERROR = "UPDATE_PREFERENCE_ERROR";

export const TEAM_DATA_REQUESTED = "TEAM_DATA_REQUESTED";
export const TEAM_DATA_SUCCEEDED = "TEAM_DATA_SUCCEEDED";
export const TEAM_DATA_FAILED = "TEAM_DATA_FAILED ";

export const RATE_TEAM_RESET = "RATE_TEAM_RESET";

export const GET_COMPANY_TEAM_LIST_SUCCESS = "GET_COMPANY_TEAM_LIST_SUCCESS";

export const ADD_CATOGERY_CUSTOME_ATTRIBUTE_REQUESTED =
  "ADD_CATOGERY_CUSTOME_ATTRIBUTE_REQUESTED";
export const ADD_CATOGERY_CUSTOME_ATTRIBUTE_SUCCEEDED =
  "ADD_CATOGERY_CUSTOME_ATTRIBUTE_SUCCEEDED";
export const ADD_CATOGERY_CUSTOME_ATTRIBUTE_FAILED =
  "ADD_CATOGERY_CUSTOME_ATTRIBUTE_FAILED";

export const GET_COMPANY_USER_REQUESTED = "GET_COMPANY_USER_REQUESTED";
export const GET_COMPANY_USER_SUCCEEDED = "GET_COMPANY_USER_SUCCEEDED";
export const GET_COMPANY_USER_FAILED = "GET_COMPANY_USER_FAILED";

export const EMAIL_TEMPLATE_REQUEST = "EMAIL_TEMPLATE_REQUEST";
export const EMAIL_TEMPLATE_SUCCESS = "EMAIL_TEMPLATE_SUCCESS";
export const EMAIL_TEMPLATE_FAILURE = "EMAIL_TEMPLATE_FAILURE";

export const UPDATE_EMAIL_TEMPLATE_REQUEST = "UPDATE_EMAIL_TEMPLATE_REQUEST";
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = "UPDATE_EMAIL_TEMPLATE_SUCCESS";
export const UPDATE_EMAIL_TEMPLATE_FAILURE = "UPDATE_EMAIL_TEMPLATE_FAILURE";

/* Action Creators */
const requestCompanies = () => ({ type: GET_COMPANIES_REQUEST, payload: null });
const receiveCompanies = (data) => ({
  type: GET_COMPANIES_SUCCESS,
  payload: data,
});
const receiveCompaniesError = (error) => ({
  type: GET_COMPANIES_FAILURE,
  payload: error,
});

const requestRepList = () => ({ type: GET_REPLIST_REQUEST, payload: null });
const receiveRepList = (data) => ({ type: GET_REPLIST_SUCCESS, payload: data });
const receiveRepListError = (error) => ({
  type: GET_REPLIST_FAILURE,
  payload: error,
});

const requestRepAttrList = () => ({
  type: GET_REPATTRLIST_REQUEST,
  payload: null,
});
const receiveRepAttrList = (data) => ({
  type: GET_REPATTRLIST_SUCCESS,
  payload: data,
});
const receiveRoleAttrList = (data) => ({
  type: GET_ROLELIST_SUCCESS,
  payload: data,
});
const receiveRepAttrListError = (error) => ({
  type: GET_REPATTRLIST_FAILURE,
  payload: error,
});

const requestCompanyInfo = () => ({ type: GET_COMPANY_REQUEST, payload: null });
const receiveCompanyInfo = (data) => ({
  type: GET_COMPANY_SUCCESS,
  payload: data,
});
const receiveCompanyInfoError = (error) => ({
  type: GET_COMPANY_FAILURE,
  payload: error,
});

const requestUpdateCompanyInfo = () => ({ type: UPDATE_COMPANY_INFO_REQUEST });
const requestResetCompanyInfo = () => ({ type: UPDATE_COMPANY_INFO_RESET });
const receiveUpdateCompanyInfo = (data) => ({
  type: UPDATE_COMPANY_INFO_SUCCESS,
  payload: data,
});
const receiveUpdateCompanyInfoError = (error) => ({
  type: UPDATE_COMPANY_INFO_FAILURE,
  payload: error,
});

const requestPreviousCompanies = () => ({
  type: GET_PREVIOUS_COMPANIES_REQUEST,
  payload: null,
});
const receivePreviousCompanies = (data) => ({
  type: GET_PREVIOUS_COMPANIES_SUCCESS,
  payload: data,
});
const receivePreviousCompaniesError = (error) => ({
  type: GET_PREVIOUS_COMPANIES_FAILURE,
  payload: error,
});

const requestCompanyRepList = () => ({
  type: GET_COMPANY_REPLIST_REQUEST,
  payload: null,
});
const receiveCompanyRepList = (data) => ({
  type: GET_COMPANY_REPLIST_SUCCESS,
  payload: data,
});
const receiveCompanyRepListError = (error) => ({
  type: GET_COMPANY_REPLIST_FAILURE,
  payload: error,
});

const globalVerifiedCustomerSelected = (data) => ({
  type: GLOBAL_VERIFIED_CUSTOMER_SELECTED,
  payload: data,
});
const globalProgramSelected = (data) => ({
  type: GLOBAL_PROGRAM_SELECTED,
  payload: data,
});
const globalProgramchange = (data) => ({
  type: GLOBAL_PROGRAM_CHANGE,
  payload: data,
});
const globalTeamchange = (data) => ({
  type: GLOBAL_TEAM_CHANGE,
  payload: data,
});
const globalAnonymousRatingSelected = (data) => ({
  type: GLOBAL_ANONYMOUS_RATING_SELECTED,
  payload: data,
});

const getMapsDataRequested = (data) => ({
  type: GET_MAPS_DATA_REQUESTED,
  payload: data,
});

const getDashboardCompanyInfoRequested = () => ({
  type: GET_DASHBOARD_COMPANY_INFO_REQUESTED,
  payload: null,
});
const getDashboardCompanyInfoSucceeded = (data) => ({
  type: GET_DASHBOARD_COMPANY_INFO_SUCCEEDED,
  payload: data,
});
const getDashboardCompanyInfoFailed = (data) => ({
  type: GET_DASHBOARD_COMPANY_INFO_FAILED,
  payload: data,
});

const getHotspotReportRequested = () => ({
  type: GET_HOTSPOT_REPORT_REQUESTED,
  payload: null,
});
const getHotspotReportSucceeded = (data) => ({
  type: GET_HOTSPOT_REPORT_SUCCEEDED,
  payload: data,
});
const getHotspotReportFailed = (data) => ({
  type: GET_HOTSPOT_REPORT_FAILED,
  payload: data,
});

const requestUpdateLogo = () => ({ type: UPDATE_COMPANY_LOGO_REQUEST });
const requestResetUpdateLogo = () => ({ type: UPDATE_COMPANY_LOGO_RESET });
const receiveUpdateLogo = (data) => ({
  type: UPDATE_COMPANY_LOGO_SUCCESS,
  payload: data,
});
const receiveUpdateLogoError = (error) => ({
  type: UPDATE_COMPANY_LOGO_FAILURE,
  payload: error,
});

const deleteLogoRequested = () => ({
  type: DELETE_COMPANY_LOGO_REQUESTED,
  payload: null,
});
const requestResetDeleteLogo = () => ({ type: DELETE_COMPANY_LOGO_RESET });
const deleteLogoSucceeded = (data) => ({
  type: DELETE_COMPANY_LOGO_SUCCEEDED,
  payload: data,
});
const deleteLogoFailed = (error) => ({
  type: DELETE_COMPANY_LOGO_FAILED,
  payload: error,
});

const requestMinimumRating = () => ({ type: MINIMUM_RATING_REQUEST });
const receiveMinimumRating = (data) => ({
  type: MINIMUM_RATING_SUCCESS,
  payload: data,
});
const receiveMinimumRatingError = (error) => ({
  type: MINIMUM_RATING_REQUEST,
  payload: error,
});

const getCompanyAverageRatingsRequested = () => ({
  type: GET_COMPANY_AVERAGE_RATINGS_REQUESTED,
  payload: null,
});
const getCompanyAverageRatingsSucceeded = (data) => ({
  type: GET_COMPANY_AVERAGE_RATINGS_SUCCEEDED,
  payload: data,
});
const getCompanyAverageRatingsFailed = (data) => ({
  type: GET_COMPANY_AVERAGE_RATINGS_FAILED,
  payload: data,
});

const getCompanyRepTrendsRequested = () => ({
  type: GET_COMPANY_REP_TRENDS_REQUESTED,
  payload: null,
});
const getCompanyRepTrendsSucceeded = (data) => ({
  type: GET_COMPANY_REP_TRENDS_SUCCEEDED,
  payload: data,
});
const getCompanyRepTrendsFailed = (data) => ({
  type: GET_COMPANY_REP_TRENDS_FAILED,
  payload: data,
});

const getCompanyMapsRequested = () => ({
  type: GET_COMPANY_MAPS_REQUESTED,
  payload: null,
});
const getCompanyMapsSucceeded = (data) => ({
  type: GET_COMPANY_MAPS_SUCCEEDED,
  payload: data,
});
const getCompanyMapsFailed = (data) => ({
  type: GET_COMPANY_MAPS_FAILED,
  payload: data,
});

const requestReportAbuse = (feedbackId) => ({
  type: REPORT_ABUSE_REQUESTED,
  payload: { feedbackId },
});
const receiveReportAbuse = (feedbackId, data) => ({
  type: REPORT_ABUSE_SUCCEEDED,
  payload: { feedbackId, data },
});
const receiveReportAbuseError = (feedbackId, error) => ({
  type: REPORT_ABUSE_FAILED,
  payload: { feedbackId, error },
});

const getCompanyPublicInfoRequested = () => ({
  type: GET_COMPANY_PUBLIC_INFO_REQUESTED,
  payload: null,
});
const getCompanyPublicInfoSucceeded = (data) => ({
  type: GET_COMPANY_PUBLIC_INFO_SUCCEEDED,
  payload: data,
});
const getCompanyPublicInfoFailed = (data) => ({
  type: GET_COMPANY_PUBLIC_INFO_FAILED,
  payload: data,
});

const requestAssignedRoles = () => ({ type: ASSIGNED_ROLES_REQUEST });
const receiveAssignedRoles = (data) => ({
  type: ASSIGNED_ROLES_SUCCESS,
  payload: data,
});
const receiveAssignedRolesError = (error) => ({
  type: ASSIGNED_ROLES_REQUEST,
  payload: error,
});

const getUserCompaniesRequested = () => ({
  type: GET_USER_COMPANIES_REQUESTED,
});
const getUserCompaniesSucceeded = (data) => ({
  type: GET_USER_COMPANIES_SUCCEEDED,
  payload: data,
});
const getUserCompaniesFailed = (error) => ({
  type: GET_USER_COMPANIES_FAILED,
  payload: error,
});

const addUserCompanyRequested = () => ({ type: ADD_USER_COMPANY_REQUESTED });
const addUserCompanySucceeded = (data) => ({
  type: ADD_USER_COMPANY_SUCCEEDED,
  payload: data,
});
const addUserCompanyFailed = (error) => ({
  type: ADD_USER_COMPANY_FAILED,
  payload: error,
});

const updateUserCompanyRequested = () => ({
  type: UPDATE_USER_COMPANY_REQUESTED,
});
const updateUserCompanySucceeded = (data) => ({
  type: UPDATE_USER_COMPANY_SUCCEEDED,
  payload: data,
});
const updateUserCompanyFailed = (error) => ({
  type: UPDATE_USER_COMPANY_FAILED,
  payload: error,
});

const updateUserCurrentCompanyRequested = () => ({
  type: UPDATE_USER_CURRENT_COMPANY_REQUESTED,
});
const updateUserCurrentCompanySucceeded = (data) => ({
  type: UPDATE_USER_CURRENT_COMPANY_SUCCEEDED,
  payload: data,
});
const updateUserCurrentCompanyFailed = (error) => ({
  type: UPDATE_USER_CURRENT_COMPANY_FAILED,
  payload: error,
});

const deleteUserCompanyRequested = () => ({
  type: DELETE_USER_COMPANY_REQUESTED,
});
const deleteUserCompanySucceeded = (data) => ({
  type: DELETE_USER_COMPANY_SUCCEEDED,
  payload: data,
});
const deleteUserCompanyFailed = (error) => ({
  type: DELETE_USER_COMPANY_FAILED,
  payload: error,
});

const addCompanyRequested = () => ({ type: ADD_COMPANY_REQUESTED });
const addCompanySucceeded = (data) => ({
  type: ADD_COMPANY_SUCCEEDED,
  payload: data,
});
const addCompanyFailed = (error) => ({
  type: ADD_COMPANY_FAILED,
  payload: error,
});

const getCompanySuggestionsRequested = () => ({
  type: GET_COMPANY_SUGGESTIONS_REQUESTED,
});
const getCompanySuggestionsRequestedRateNewRep = () => ({
  type: GET_COMPANY_SUGGESTIONS_NEW_REP_REQUESTED,
});
const getCompanySuggestionsSucceeded = (data) => ({
  type: GET_COMPANY_SUGGESTIONS_SUCCEEDED,
  payload: data,
});
const getCompanySuggestionsFailed = (error) => ({
  type: GET_COMPANY_SUGGESTIONS_FAILED,
  payload: error,
});

const addCompanyCustomeAttributeRequested = () => ({
  type: ADD_COMPANY_CUSTOME_ATTRIBUTE_REQUESTED,
  payload: null,
});
const addCompanyCustomeAttributeSucceeded = (data) => ({
  type: ADD_COMPANY_CUSTOME_ATTRIBUTE_SUCCEEDED,
  payload: data,
});
const addCompanyCustomeAttributeFailed = (data) => ({
  type: ADD_COMPANY_CUSTOME_ATTRIBUTE_FAILED,
  payload: data,
});

const requestCompanyCustomeAttribute = () => ({
  type: GET_COMPANY_CUSTOME_ATTRIBUTE_REQUEST,
  payload: null,
});
const receiveCompanyCustomeAttribute = (data) => ({
  type: GET_COMPANY_CUSTOME_ATTRIBUTE_SUCCESS,
  payload: data,
});
const receiveCompanyCustomeAttributeError = (error) => ({
  type: GET_COMPANY_CUSTOME_ATTRIBUTE_FAILURE,
  payload: error,
});

const updateCompanyCustomeAttributeRequested = () => ({
  type: UPDATE_COMPANY_CUSTOME_ATTRIBUTE_REQUESTED,
  payload: null,
});
const updateCompanyCustomeAttributeSucceeded = (data) => ({
  type: UPDATE_COMPANY_CUSTOME_ATTRIBUTE_SUCCEEDED,
  payload: data,
});
const updateCompanyCustomeAttributeFailed = (data) => ({
  type: UPDATE_COMPANY_CUSTOME_ATTRIBUTE_FAILED,
  payload: data,
});

const deleteCompanyCustomeAttributeRequested = () => ({
  type: DELETE_COMPANY_CUSTOME_ATTRIBUTE_REQUESTED,
  payload: null,
});
const deleteCompanyCustomeAttributeSucceeded = (data) => ({
  type: DELETE_COMPANY_CUSTOME_ATTRIBUTE_SUCCEEDED,
  payload: data,
});
const deleteCompanyCustomeAttributeFailed = (data) => ({
  type: DELETE_COMPANY_CUSTOME_ATTRIBUTE_FAILED,
  payload: data,
});

const clearCompanyCustomeAttributeMessage = (data) => ({
  type: CLEAR_COMPANY_CUSTOME_ATTRIBUTE_MESSAGE,
  payload: null,
});

const clearCustomeAttrList = () => ({
  type: CLEAR_CUSTOME_ATTR_LIST,
  payload: null,
});

const uploadCrmRequested = () => ({
  type: UPLOAD_CRM_REQUESTED,
  payload: null,
});
const uploadCrmSucceeded = (data) => ({
  type: UPLOAD_CRM_SUCCEEDED,
  payload: data,
});
const uploadCrmFailed = (data) => ({ type: UPLOAD_CRM_FAILED, payload: data });
const clearCRM = () => ({ type: CLEAR_CRM_DATA, payload: null });
const resetCRM = () => ({ type: RESET_CRM_DATA, payload: null });

const setCompanyLogo = (data) => ({ type: COMPANY_LOGO, payload: data });
const setRatinPageCompanyLogo = (data) => ({
  type: RATINGPAGE_COMPANY_LOGO,
  payload: data,
});
const updateCRMspecificRecord = (data) => ({
  type: UPDATE_CRM_SPECIFIC_RECORD,
  payload: data,
});

const addCrmRequested = () => ({ type: ADD_CRM_REQUESTED, payload: null });
const addCrmSucceeded = (data) => ({ type: ADD_CRM_SUCCEEDED, payload: data });
const addCrmFailed = (data) => ({ type: ADD_CRM_FAILED, payload: data });

const updateCrmRequested = () => ({
  type: UPDATE_CRM_REQUESTED,
  payload: null,
});
const updateCrmSucceeded = (data) => ({
  type: UPDATE_CRM_SUCCEEDED,
  payload: data,
});
const updateCrmFailed = (data) => ({ type: UPDATE_CRM_FAILED, payload: data });

const validateCRMDataRequested = () => ({
  type: VALIDATE_CRM_DATA_REQUESTED,
  payload: null,
});
const validateCRMDataSucceeded = (data) => ({
  type: VALIDATE_CRM_DATA_SUCCEEDED,
  payload: data,
});
const validateCRMDataFailed = (data) => ({
  type: VALIDATE_CRM_DATA_FAILED,
  payload: data,
});

const deleteCRMDataRequested = () => ({
  type: DELETE_CRM_DATA_REQUESTED,
  payload: null,
});
const deleteCRMDataSucceeded = (data) => ({
  type: DELETE_CRM_DATA_SUCCEEDED,
  payload: data,
});
const deleteCRMDataFailed = (data) => ({
  type: DELETE_CRM_DATA_FAILED,
  payload: data,
});

const getCRMusersCompanyRequested = () => ({
  type: GET_CRM_USERS_COMPANY_REQUESTED,
  payload: null,
});
const getCRMusersCompanySucceeded = (data) => ({
  type: GET_CRM_USERS_COMPANY_SUCCEEDED,
  payload: data,
});
const getCRMusersCompanyFailed = (data) => ({
  type: GET_CRM_USERS_COMPANY_FAILED,
  payload: data,
});

const getCRMFileDataRequested = () => ({
  type: GET_CRM_FILE_DATA_REQUESTED,
  payload: null,
});
const getCRMFileDataSucceeded = (data) => ({
  type: GET_CRM_FILE_DATA_SUCCEEDED,
  payload: data,
});
const getCRMFileDataFailed = (data) => ({
  type: GET_CRM_FILE_DATA_FAILED,
  payload: data,
});

const requestcompanyWideAverage = () => ({
  type: COMPANY_WIDE_AVERAGE_REQUESTED,
  payload: null,
});
const receivecompanyWideAverage = (data) => ({
  type: COMPANY_WIDE_AVERAGE_SUCCEEDED,
  payload: data,
});
const receivecompanyWideAverageError = (error) => ({
  type: COMPANY_WIDE_AVERAGE_FAILED,
  payload: error,
});

const requestCompanyUser = () => ({
  type: GET_COMPANY_USER_REQUESTED,
  payload: null,
});
const receiveCompanyUser = (data) => ({
  type: GET_COMPANY_USER_SUCCEEDED,
  payload: data,
});
const receiveCompanyUserDataError = (error) => ({
  type: GET_COMPANY_USER_FAILED,
  payload: error,
});

const requestcompanyDashboardDetails = () => ({
  type: COMPANY_DASHBOARD_DETAILS_REQUESTED,
  payload: null,
});
const receivecompanyDashboardDetails = (data) => ({
  type: COMPANY_DASHBOARD_DETAILS_SUCCEEDED,
  payload: data,
});
const receivecompanyDashboardDetailsError = (error) => ({
  type: COMPANY_DASHBOARD_DETAILS_FAILED,
  payload: error,
});

const requestcompanyDashboardPrograms = () => ({
  type: COMPANY_DASHBOARD_PROGRAMS_REQUESTED,
  payload: null,
});
const receivecompanyDashboardPrograms = (data) => ({
  type: COMPANY_DASHBOARD_PROGRAMS_SUCCEEDED,
  payload: data,
});
const receivecompanyDashboardProgramsError = (error) => ({
  type: COMPANY_DASHBOARD_PROGRAMS_FAILED,
  payload: error,
});

const getCatogeryAverageRatingsRequested = () => ({
  type: GET_CATOGERY_AVERAGE_RATINGS_REQUESTED,
  payload: null,
});
const getCatogeryAverageRatingsSucceeded = (data) => ({
  type: GET_CATOGERY_AVERAGE_RATINGS_SUCCEEDED,
  payload: data,
});
const getCatogeryAverageRatingsFailed = (data) => ({
  type: GET_CATOGERY_AVERAGE_RATINGS_FAILED,
  payload: data,
});

const getCatogeryRepTrendsRequested = () => ({
  type: GET_CATOGERY_REP_TRENDS_REQUESTED,
  payload: null,
});
const getCatogeryRepTrendsSucceeded = (data) => ({
  type: GET_CATOGERY_REP_TRENDS_SUCCEEDED,
  payload: data,
});
const getCatogeryRepTrendsFailed = (data) => ({
  type: GET_CATOGERY_REP_TRENDS_FAILED,
  payload: data,
});

const getCatogeryMapsRequested = () => ({
  type: GET_CATOGERY_MAPS_REQUESTED,
  payload: null,
});
const getCatogeryMapsSucceeded = (data) => ({
  type: GET_CATOGERY_MAPS_SUCCEEDED,
  payload: data,
});
const getCatogeryMapsFailed = (data) => ({
  type: GET_CATOGERY_MAPS_FAILED,
  payload: data,
});

const getCatogeryHotspotReportRequested = () => ({
  type: GET_CATOGERY_HOTSPOT_REPORT_REQUESTED,
  payload: null,
});
const getCatogeryHotspotReportSucceeded = (data) => ({
  type: GET_CATOGERY_HOTSPOT_REPORT_SUCCEEDED,
  payload: data,
});
const getCatogeryHotspotReportFailed = (data) => ({
  type: GET_CATOGERY_HOTSPOT_REPORT_FAILED,
  payload: data,
});

const requestProgramdata = () => ({
  type: PROGRAM_DATA_REQUESTED,
  payload: null,
});
const receiveProgramdata = (data) => ({
  type: PROGRAM_DATA_SUCCEEDED,
  payload: data,
});
const receiveProgramdataError = (error) => ({
  type: PROGRAM_DATA_FAILED,
  payload: error,
});

const requestRateCatogery = () => ({
  type: RATE_CATOGERY_REQUEST,
  payload: null,
});
const receiveRateCatogery = (data) => ({
  type: RATE_CATOGERY_SUCCESS,
  payload: data,
});
const receiveRateCatogeryError = (error) => ({
  type: RATE_CATOGERY_FAILURE,
  payload: error,
});
export const resetRateCatogery = () => ({ type: RATE_CATOGERY_RESET });

const requestCompanyProgramList = () => ({
  type: GET_COMPANY_PROGRAM_LIST_REQUEST,
  payload: null,
});
const receiveCompanyProgramList = (data) => ({
  type: GET_COMPANY_PROGRAM_LIST_SUCCESS,
  payload: data,
});
const receiveCompanyTeamList = (data) => ({
  type: GET_COMPANY_TEAM_LIST_SUCCESS,
  payload: data,
});
const receiveCompanyProgramListError = (error) => ({
  type: GET_COMPANY_PROGRAM_LIST_FAILURE,
  payload: error,
});

const requestCatogeryViewdata = () => ({
  type: VIEW_CATOGERY_DATA_REQUESTED,
  payload: null,
});
const receiveCatogeryViewdata = (data) => ({
  type: VIEW_CATOGERY_DATA_SUCCEEDED,
  payload: data,
});
const receiveCatogeryViewdataError = (error) => ({
  type: VIEW_CATOGERY_DATA_FAILED,
  payload: error,
});

const addTagToFeedback = (payload) => {
  return { type: ADD_TAG_TO_FEEEDBACK, payload };
};
const addTagToFeedbackSuccess = (payload) => {
  return { type: ADD_TAG_TO_FEEEDBACK_SUCCESS, payload };
};
const addTagToFeedbackError = (payload) => {
  return { type: ADD_TAG_TO_FEEEDBACK_ERROR, payload };
};

const updateDefaultDashboardRequested = () => {
  return { type: UPDATE_PREFERENCE_REQUESTED, payload: null };
};
const updateDefaultDashboardSuccess = (data) => {
  return { type: UPDATE_PREFERENCE_SUCCEEDED, payload: data };
};
const updateDefaultDashboardError = (error) => {
  return { type: UPDATE_PREFERENCE_ERROR, payload: error };
};

const requestTeamData = () => ({ type: TEAM_DATA_REQUESTED, payload: null });
const receiveTeamdata = (data) => ({
  type: TEAM_DATA_SUCCEEDED,
  payload: data,
});
const receiveTeamdataError = (error) => ({
  type: TEAM_DATA_FAILED,
  payload: error,
});

const addCatogeryCustomAttrRequested = () => ({
  type: ADD_CATOGERY_CUSTOME_ATTRIBUTE_REQUESTED,
  payload: null,
});
const addCatogeryCustomAttrSucceeded = (data) => ({
  type: ADD_CATOGERY_CUSTOME_ATTRIBUTE_SUCCEEDED,
  payload: data,
});
const addCatogeryCustomAttrFailed = (data) => ({
  type: ADD_CATOGERY_CUSTOME_ATTRIBUTE_FAILED,
  payload: data,
});

const requestInfo = () => ({ type: GET_INFO_REQUEST, payload: null });
const receiveInfo = (data) => ({ type: GET_INFO_SUCCESS, payload: data });
const receiveInfoError = (error) => ({
  type: GET_INFO_FAILURE,
  payload: error,
});

const requestEmailTemplate = () => ({
  type: EMAIL_TEMPLATE_REQUEST,
  payload: null,
});
const receiveEmailTemplate = (data) => ({
  type: EMAIL_TEMPLATE_SUCCESS,
  payload: data,
});
const receiveEmailTemplateError = (error) => ({
  type: EMAIL_TEMPLATE_FAILURE,
  payload: error,
});

const requestUpdateEmailTemplate = () => ({
  type: UPDATE_EMAIL_TEMPLATE_REQUEST,
  payload: null,
});
const EmailTemplateUpdated = (data) => ({
  type: UPDATE_EMAIL_TEMPLATE_SUCCESS,
  payload: data,
});
const UpdateEmailTemplateError = (error) => ({
  type: UPDATE_EMAIL_TEMPLATE_FAILURE,
  payload: error,
});

export const resetRateTeam = () => ({ type: RATE_TEAM_RESET });

export function setGlobalVerifiedCustomer(value) {
  return (dispatch) => {
    dispatch(globalVerifiedCustomerSelected(value));
  };
}

export function setGlobalProgramChange(value) {
  return (dispatch) => {
    dispatch(globalProgramSelected(value));
  };
}

export function setprogramchange(obj) {
  return (dispatch) => {
    dispatch(globalProgramchange(obj));
  };
}

export function setTeamChange(obj) {
  return (dispatch) => {
    dispatch(globalTeamchange(obj));
  };
}

export function setGlobalAnonymousRating(value) {
  return (dispatch) => {
    dispatch(globalAnonymousRatingSelected(value));
  };
}

export function getMapsData(companyId) {
  return (dispatch) => {
    dispatch(getMapsDataRequested());
    CompanyService.getMapsData(companyId)
      .then((response) => {
        // dispatch(receiveCompanies(res.payload));
      })
      .catch((error) => {
        // dispatch(receiveCompaniesError(err));
      });
  };
}

export function getCompanies() {
  return (dispatch) => {
    dispatch(requestCompanies());
    CompanyService.getCompanies()
      .then((res) => {
        dispatch(receiveCompanies(res.payload));
      })
      .catch((err) => {
        dispatch(receiveCompaniesError(err));
      });
  };
}

export function getRepList() {
  return (dispatch) => {
    dispatch(requestRepList());
    CompanyService.getRepList()
      .then((res) => {
        dispatch(receiveRepList(res.payload));
      })
      .catch((err) => {
        dispatch(receiveRepListError(err));
      });
  };
}

export function getRepAttrList(companyId) {
  // console.log("fn companyId-->>", companyId);
  return (dispatch) => {
    dispatch(requestRepAttrList());
    CompanyService.getRepAttrList(companyId)
      .then((res) => {
        dispatch(receiveRepAttrList(res.payload));
        let AllRoles = [];
        let roleKeys = Object.keys(res.payload);
        roleKeys.map((item) => {
          AllRoles.push({ type: item, label: item });
        });
        dispatch(receiveRoleAttrList(AllRoles));
      })
      .catch((err) => {
        dispatch(receiveRepAttrListError(err));
      });
  };
}

export function getCompanyInfo(id) {
  return (dispatch) => {
    dispatch(requestCompanyInfo());
    CompanyService.getCompanyInfo(id)
      .then((res) => {
        dispatch(receiveCompanyInfo(res.payload));
        if (!window.localStorage.getItem("CompanyLogo")) {
          try {
            if (res.payload.companyLogo) {
              dispatch(companyLogo(res.payload.companyLogo));
            }
          } catch (e) {
            console.log("Company Logo not found");
          }
        }
      })
      .catch((err) => {
        dispatch(receiveCompanyInfoError(err));
      });
  };
}
export function companyLogo(url) {
  return (dispatch) => {
    CompanyService.getCompanyLogo(url)
      .then((response) => {
        dispatch(setCompanyLogo(response));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getCompanyRepList(
  companyId,
  beginDateInMillis,
  endDateInMillis,
  verifiedCustomerOnly,
  anonymousHidden,
  tagId,
  role
) {
  return (dispatch, getState) => {
    const {
      auth: {
        userData: { email: emailId, userRole: myRole, seeAllData },
      },
    } = getState();
    dispatch(requestCompanyRepList());
    CompanyService.getCompanyRepList(
      companyId,
      beginDateInMillis,
      endDateInMillis,
      verifiedCustomerOnly,
      anonymousHidden,
      tagId,
      role,
      emailId,
      myRole,
      seeAllData
    )
      .then((response) => {
        // debugger;
        dispatch(receiveCompanyRepList(response.payload?.repViewList));
      })
      .catch((error) => {
        dispatch(receiveCompanyRepListError(error));
      });
  };
}

export function getCompanyTopList(companyId) {
  return (dispatch) => {
    dispatch(requestCompanyRepList());
    CompanyService.getCompanyTopList(companyId)
      .then((response) => {
        dispatch(receiveCompanyRepList(response.payload.repViewList));
      })
      .catch((error) => {
        dispatch(receiveCompanyRepListError(error));
      });
  };
}

//Top and bottom company repList
export function getCompanyTopBottomList(companyId) {
  return (dispatch) => {
    dispatch(requestCompanyRepList());
    CompanyService.getCompanyTopBottomList(companyId)
      .then((response) => {
        dispatch(receiveCompanyRepList(response.payload.repViewList));
      })
      .catch((error) => {
        dispatch(receiveCompanyRepListError(error));
      });
  };
}

export function updateCompanyInfo(id, info) {
  return (dispatch) => {
    dispatch(requestUpdateCompanyInfo());
    CompanyService.updateCompanyInfo(id, info)
      .then((res) => {
        dispatch(receiveUpdateCompanyInfo(res.payload.value));

        authService.updateUserInfo({
          // companyName: res.payload.value.name
          companyName: res.payload.name,
        });
        dispatch({ type: UPDATE_USER_INFO_REQUESTED });
      })
      .catch((err) => {
        dispatch(receiveUpdateCompanyInfoError(err));
      });
  };
}

export function initRequestUpdateCompanyInfo() {
  return (dispatch) => {
    dispatch(requestUpdateCompanyInfo());
  };
}

export function resetCompanyInfo() {
  return (dispatch) => {
    dispatch(requestResetCompanyInfo());
  };
}

export function getUserCompanies(userId) {
  return (dispatch) => {
    dispatch(getUserCompaniesRequested());

    CompanyService.getUserCompanies(userId)
      .then((response) => {
        dispatch(getUserCompaniesSucceeded(response));
      })
      .catch((error) => {
        dispatch(getUserCompaniesFailed(error));
      });
  };
}

export function getUserCompaniesSample(userId) {
  return (dispatch) => {
    dispatch(getUserCompaniesRequested());

    CompanyService.getUserCompaniesSample(userId)
      .then((response) => {
        dispatch(getUserCompaniesSucceeded(response));
      })
      .catch((error) => {
        dispatch(getUserCompaniesFailed(error));
      });
  };
}

export function addUserCompany(company, userLogged) {
  return (dispatch) => {
    dispatch(addUserCompanyRequested());

    CompanyService.addUserCompany(company, userLogged)
      .then((response) => {
        dispatch(addUserCompanySucceeded(response.payload));
        if (response.statusCode === 200 && company.current === true) {
          dispatch(getUserCompanies(company.userId));
        }
      })
      .catch((error) => {
        dispatch(addUserCompanyFailed(error));
      });
  };
}

export function updateUserCompany(company) {
  return (dispatch) => {
    dispatch(updateUserCompanyRequested());

    CompanyService.updateUserCompany(company)
      .then((response) => {
        dispatch(updateUserCompanySucceeded(response.payload));
      })
      .catch((error) => {
        dispatch(updateUserCompanyFailed(error));
      });
  };
}

export function updateUserCurrentCompany(company) {
  return (dispatch) => {
    dispatch(updateUserCurrentCompanyRequested());

    CompanyService.updateUserCompany(company)
      .then((response) => {
        dispatch(updateUserCurrentCompanySucceeded(response.payload));
      })
      .catch((error) => {
        dispatch(updateUserCurrentCompanyFailed(error));
      });
  };
}

export function deleteUserCompany(companyId, userId) {
  return (dispatch) => {
    dispatch(deleteUserCompanyRequested());

    CompanyService.deleteUserCompany(companyId, userId)
      .then((response) => {
        dispatch(deleteUserCompanySucceeded(companyId));
      })
      .catch((error) => {
        dispatch(deleteUserCompanyFailed(error));
      });
  };
}

export function getPreviousCompanies(repId) {
  return (dispatch) => {
    dispatch(requestPreviousCompanies());
    CompanyService.getPreviousCompanies(repId)
      .then((res) => {
        dispatch(receivePreviousCompanies(res.payload));
      })
      .catch((err) => {
        dispatch(receivePreviousCompaniesError(err));
      });
  };
}

export function getDashboardCompanyInfo(
  companyId,
  globalVerifiedCustomer,
  globalAnonymousRating,
  tagId,
  role
) {
  return (dispatch, getState) => {
    const {
      auth: {
        userData: { email: emailId, userRole: myRole, seeAllData },
      },
    } = getState();

    dispatch(getDashboardCompanyInfoRequested());

    CompanyService.getDashboardCompanyInfo(
      companyId,
      globalVerifiedCustomer,
      globalAnonymousRating,
      tagId,
      role,
      emailId,
      myRole,
      seeAllData
    )
      .then((response) => {
        dispatch(getDashboardCompanyInfoSucceeded(response.payload));
        if (
          response.payload.company &&
          !window.localStorage.getItem("CompanyLogo")
        ) {
          dispatch(companyLogo(response.payload.company?.companyLogo));
        }
      })
      .catch((error) => {
        dispatch(getDashboardCompanyInfoFailed(error));
      });
  };
}

export function updateLogo(companyId, logo) {
  return (dispatch) => {
    dispatch(requestUpdateLogo());

    CompanyService.updateLogo(companyId, logo)
      .then((res) => {
        dispatch(receiveUpdateLogo(res.payload));
        authService.updateUserInfo({ picture: res.payload.companylogo });
        dispatch(companyLogo(res.payload.companyLogo));
        dispatch({ type: UPDATE_USER_INFO_REQUESTED });
      })
      .catch((err) => {
        dispatch(receiveUpdateLogoError(err));
      });
  };
}

export function deleteLogo(companyId) {
  return (dispatch) => {
    dispatch(deleteLogoRequested());

    CompanyService.deleteLogo(companyId)
      .then((res) => {
        dispatch(deleteLogoSucceeded({ logo: null }));
        authService.updateUserInfo({ picture: null });
        dispatch({ type: UPDATE_USER_INFO_REQUESTED });
      })
      .catch((err) => {
        dispatch(deleteLogoFailed(err));
      });
  };
}

export function getHotspotReport(
  companyId,
  beginDateInMillis,
  endDateInMillis,
  verifiedCustomerOnly,
  anonymousHidden,
  tagId,
  role
) {
  return (dispatch, getState) => {
    const {
      auth: {
        userData: { email: emailId, userRole: myRole, seeAllData },
      },
    } = getState();
    dispatch(getHotspotReportRequested());
    CompanyService.getHotspotReport(
      companyId,
      beginDateInMillis,
      endDateInMillis,
      verifiedCustomerOnly,
      anonymousHidden,
      tagId,
      role,
      emailId,
      myRole,
      seeAllData
    )
      .then((response) => {
        dispatch(getHotspotReportSucceeded(response.payload));
      })
      .catch((error) => {
        dispatch(getHotspotReportFailed(error));
      });
  };
}

export function resetUpdateLogo() {
  return (dispatch) => {
    dispatch(requestResetUpdateLogo());
  };
}

export function resetDeleteLogo() {
  return (dispatch) => {
    dispatch(requestResetDeleteLogo());
  };
}

export function setMinimumRating(id, rating) {
  return (dispatch) => {
    dispatch(requestMinimumRating());
    CompanyService.setMinimumRating(id, rating)
      .then((res) => {
        dispatch(receiveMinimumRating(res.payload));
      })
      .catch((err) => {
        dispatch(receiveMinimumRatingError(err));
      });
  };
}

export function getCompanyAverageRatings(
  companyId,
  verifiedCustomerOnly,
  anonymousHidden,
  compared,
  tagId,
  role
) {
  return (dispatch, getState) => {
    const {
      auth: {
        userData: { email: emailId, userRole: myRole, seeAllData },
      },
    } = getState();
    dispatch(getCompanyAverageRatingsRequested());
    CompanyService.getCompanyAverageRatings(
      companyId,
      verifiedCustomerOnly,
      anonymousHidden,
      compared,
      tagId,
      role,
      emailId,
      myRole,
      seeAllData
    )
      .then((response) => {
        dispatch(getCompanyAverageRatingsSucceeded(response.payload));
      })
      .catch((error) => {
        dispatch(getCompanyAverageRatingsFailed(error));
      });
  };
}

export function getCompanyRepTrends(
  companyId,
  beginDateInMillis,
  endDateInMillis,
  verifiedCustomerOnly,
  anonymousHidden,
  tagId,
  role
) {
  return (dispatch, getState) => {
    const {
      auth: {
        userData: { email: emailId, userRole: myRole, seeAllData },
      },
    } = getState();

    dispatch(getCompanyRepTrendsRequested());

    CompanyService.getCompanyRepTrends(
      companyId,
      beginDateInMillis,
      endDateInMillis,
      verifiedCustomerOnly,
      anonymousHidden,
      tagId,
      role,
      emailId,
      myRole,
      seeAllData
    )
      .then((response) => {
        dispatch(getCompanyRepTrendsSucceeded(response.payload));
      })
      .catch((error) => {
        dispatch(getCompanyRepTrendsFailed(error));
      });
  };
}

export function getCompanyMaps(
  companyId,
  beginDateInMillis,
  endDateInMillis,
  verifiedCustomerOnly,
  anonymousHidden,
  category,
  filter,
  value,
  threshold,
  repType,
  tagId
) {
  return (dispatch, getState) => {
    const {
      auth: {
        userData: { email: emailId, userRole: myRole, seeAllData },
      },
    } = getState();
    dispatch(getCompanyMapsRequested());
    CompanyService.getCompanyMaps(
      companyId,
      beginDateInMillis,
      endDateInMillis,
      verifiedCustomerOnly,
      anonymousHidden,
      category,
      filter,
      value,
      threshold,
      repType,
      tagId,
      emailId,
      myRole,
      seeAllData
    )
      .then((response) => {
        dispatch(getCompanyMapsSucceeded(response.payload));
      })
      .catch((error) => {
        dispatch(getCompanyMapsFailed(error));
      });
  };
}

export function reportAbuse(feedbackId, payload) {
  return (dispatch) => {
    dispatch(requestReportAbuse(feedbackId));
    CompanyService.reportAbuse(payload)
      .then((res) => {
        dispatch(receiveReportAbuse(feedbackId, res));
      })
      .catch((err) => {
        dispatch(receiveReportAbuseError(feedbackId, err));
      });
  };
}

export function getCompanyPublicInfo(companyId) {
  return (dispatch) => {
    dispatch(getCompanyPublicInfoRequested());

    CompanyService.getCompanyPublicInfo(companyId)
      .then((response) => {
        dispatch(getCompanyPublicInfoSucceeded(response));
      })
      .catch((error) => {
        dispatch(getCompanyPublicInfoFailed(error));
      });
  };
}

export function getCompanyInfoByInvite(inviteId) {
  return (dispatch) => {
    dispatch(getCompanyPublicInfoRequested());

    CompanyService.getCompanyPublicInfoByInvite(inviteId)
      .then((response) => {
        dispatch(getCompanyPublicInfoSucceeded(response));
      })
      .catch((error) => {
        dispatch(getCompanyPublicInfoFailed(error));
      });
  };
}

export function getAssignedRoles(companyId) {
  return (dispatch) => {
    dispatch(requestAssignedRoles());
    CompanyService.getAssignedRoles(companyId)
      .then((response) => {
        dispatch(receiveAssignedRoles(response));
      })
      .catch((error) => {
        dispatch(receiveAssignedRolesError(error));
      });
  };
}

export function addCompany(company) {
  return (dispatch) => {
    dispatch(addCompanyRequested());

    CompanyService.addCompany(company)
      .then((response) => {
        dispatch(addCompanySucceeded(response.payload));
      })
      .catch((error) => {
        dispatch(addCompanyFailed(error));
      });
  };
}

export function getCompanySuggestionsRateNewRep(companyName) {
  return (dispatch) => {
    dispatch(getCompanySuggestionsRequestedRateNewRep());

    CompanyService.getCompanySuggestions(companyName)
      .then((response) => {
        dispatch(getCompanySuggestionsSucceeded(response));
      })
      .catch((error) => {
        dispatch(getCompanySuggestionsFailed(error));
      });
  };
}

export function getCompanySuggestions(companyName, repId) {
  return (dispatch) => {
    dispatch(getCompanySuggestionsRequested());

    CompanyService.getCompanySuggestions(companyName, repId)
      .then((response) => {
        dispatch(getCompanySuggestionsSucceeded(response));
      })
      .catch((error) => {
        dispatch(getCompanySuggestionsFailed(error));
      });
  };
}

export function addCutomeAttributes(customeAttrdata) {
  return (dispatch) => {
    dispatch(addCompanyCustomeAttributeRequested());
    CompanyService.addCustomeAttributes(customeAttrdata)
      .then((response) => {
        dispatch(addCompanyCustomeAttributeSucceeded(response));
        dispatch(
          getCompanyCustomeAttribute({
            companyId: customeAttrdata.companyId,
            repType: customeAttrdata.repType,
          })
        );
      })
      .catch((error) => {
        dispatch(addCompanyCustomeAttributeFailed(error));
      });
  };
}

export function getCompanyCustomeAttribute(obj) {
  return (dispatch) => {
    dispatch(requestCompanyCustomeAttribute());
    CompanyService.getCompanyCustomeAttr(obj)
      .then((res) => {
        dispatch(receiveCompanyCustomeAttribute(res.payload));
      })
      .catch((err) => {
        dispatch(receiveCompanyCustomeAttributeError(err));
      });
  };
}

export function getCompanyCustomeAttributeSample(obj) {
  return (dispatch) => {
    dispatch(requestCompanyCustomeAttribute());
    CompanyService.getCompanyCustomeAttrSample(obj)
      .then((res) => {
        dispatch(receiveCompanyCustomeAttribute(res.payload));
      })
      .catch((err) => {
        dispatch(receiveCompanyCustomeAttributeError(err));
      });
  };
}

export function updateCutomeAttributes(customeAttrdata, companyData) {
  return (dispatch) => {
    dispatch(updateCompanyCustomeAttributeRequested());
    CompanyService.updateCustomeAttributes(customeAttrdata)
      .then((response) => {
        dispatch(updateCompanyCustomeAttributeSucceeded(response));
        dispatch(getCompanyCustomeAttribute(companyData));
      })
      .catch((error) => {
        dispatch(updateCompanyCustomeAttributeFailed(error));
      });
  };
}

export function deleteCustomeAttributes(obj, customeAttrdata) {
  return (dispatch) => {
    dispatch(deleteCompanyCustomeAttributeRequested());
    CompanyService.deleteCustomeAttributes(obj)
      .then((response) => {
        dispatch(deleteCompanyCustomeAttributeSucceeded(response));
        dispatch(getCompanyCustomeAttribute(customeAttrdata));
      })
      .catch((error) => {
        dispatch(deleteCompanyCustomeAttributeFailed(error));
      });
  };
}

export function uploadCRMFile(obj, companyId, activationDate, name) {
  return (dispatch) => {
    dispatch(uploadCrmRequested());
    CompanyService.uploadCRMExcel(obj, companyId, activationDate, name)
      .then((response) => {
        dispatch(uploadCrmSucceeded(response));
      })
      .catch((error) => {
        dispatch(uploadCrmFailed(error));
      });
  };
}

export function addCRMFile(obj) {
  return (dispatch, getState) => {
    const {
      auth: {
        userData: { companyId: id },
      },
    } = getState();
    dispatch(addCrmRequested());
    CompanyService.addCRMExcel(obj)
      .then((response) => {
        dispatch(addCrmSucceeded(response));
        dispatch(getCRMFileData({ id: id }));
      })
      .catch((error) => {
        dispatch(addCrmFailed(error));
      });
  };
}

export function updateCRMFile(obj) {
  return (dispatch, getState) => {
    const {
      auth: {
        userData: { companyId: id },
      },
    } = getState();
    dispatch(updateCrmRequested());
    CompanyService.updateCRMExcel(obj)
      .then((response) => {
        dispatch(updateCrmSucceeded(response));
        dispatch(getCRMFileData({ id: id }));
      })
      .catch((error) => {
        dispatch(updateCrmFailed(error));
      });
  };
}

export function crmValidateData(obj) {
  return (dispatch) => {
    dispatch(validateCRMDataRequested());
    CompanyService.validateCRMData(obj)
      .then((response) => {
        dispatch(validateCRMDataSucceeded(response));
      })
      .catch((error) => {
        dispatch(validateCRMDataFailed(error));
      });
  };
}

export function crmValidateUpdateData(obj) {
  return (dispatch) => {
    dispatch(validateCRMDataRequested());
    CompanyService.updatevalidateCRMData(obj)
      .then((response) => {
        dispatch(validateCRMDataSucceeded(response));
      })
      .catch((error) => {
        dispatch(validateCRMDataFailed(error));
      });
  };
}

export function deleteCRMData(obj) {
  return (dispatch) => {
    dispatch(deleteCRMDataRequested());
    CompanyService.deleteCRMData(obj)
      .then((response) => {
        dispatch(deleteCRMDataSucceeded(response));
      })
      .catch((error) => {
        dispatch(deleteCRMDataFailed(error));
      });
  };
}

export function getCRMusersCompanyData(obj) {
  return (dispatch) => {
    dispatch(getCRMusersCompanyRequested());
    CompanyService.getCRMusersCompany(obj)
      .then((response) => {
        dispatch(getCRMusersCompanySucceeded(response));
      })
      .catch((error) => {
        dispatch(getCRMusersCompanyFailed(error));
      });
  };
}

export function getCRMFileData(obj) {
  return (dispatch) => {
    dispatch(getCRMFileDataRequested());
    CompanyService.getCRMFileData(obj)
      .then((response) => {
        dispatch(getCRMFileDataSucceeded(response));
      })
      .catch((error) => {
        dispatch(getCRMFileDataFailed(error));
      });
  };
}

export function getcrmFilesDownload(obj, fileName) {
  return (dispatch) => {
    CompanyService.getCRMFileDownload(obj, fileName)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {});
  };
}

export function clearCRMdata() {
  return (dispatch) => {
    dispatch(clearCRM());
  };
}
export function resetCRMdata() {
  return (dispatch) => {
    dispatch(resetCRM());
  };
}

export function upDateSpecificCRMRecord(data) {
  return (dispatch) => {
    dispatch(updateCRMspecificRecord(data));
  };
}

export function clearCutomeAttributesMessage() {
  return (dispatch) => {
    dispatch(clearCompanyCustomeAttributeMessage());
  };
}

export function clearCustomeAttrTypeList() {
  return (dispatch) => {
    dispatch(clearCustomeAttrList());
  };
}

export function companyWideAverage(obj) {
  return (dispatch) => {
    dispatch(requestcompanyWideAverage());
    CompanyService.getCompanyWideAverage(obj)
      .then((res) => {
        dispatch(receivecompanyWideAverage(res.payload));
      })
      .catch((err) => {
        dispatch(receivecompanyWideAverageError(err));
      });
  };
}

export function handleGetCompanyUser(obj) {
  return (dispatch) => {
    dispatch(requestCompanyUser());
    CompanyService.handleGetCompanyUser(obj)
      .then((res) => {
        dispatch(receiveCompanyUser(res.payload));
      })
      .catch((err) => {
        dispatch(receiveCompanyUserDataError(err));
      });
  };
}

// Dashboard2
export function companyDashboardDetails(obj) {
  return (dispatch) => {
    dispatch(requestcompanyDashboardDetails());
    CompanyService.getCompanyDashboardDetails(obj)
      .then((res) => {
        dispatch(receivecompanyDashboardDetails(res.payload));
        if (!window.localStorage.getItem("CompanyLogo")) {
          try {
            if (res.payload.logo) {
              dispatch(companyLogo(res.payload.logo));
            }
          } catch (e) {
            console.log("Company Logo not found");
          }
        }
      })
      .catch((err) => {
        dispatch(receivecompanyDashboardDetailsError(err));
      });
  };
}

export function getallprograms(companyId) {
  return (dispatch) => {
    dispatch(requestcompanyDashboardPrograms());
    CompanyService.getAllPrograms(companyId)
      .then((response) => {
        dispatch(receivecompanyDashboardPrograms(response.payload));
      })
      .catch((error) => {
        dispatch(receivecompanyDashboardProgramsError(error));
      });
  };
}

export function getProgramData(Id) {
  return (dispatch) => {
    dispatch(requestProgramdata());
    CompanyService.getProgramData(Id)
      .then((response) => {
        // console.log("response-->>>>", response);
        // dispatch(
        //   requestAttrList({
        //     companyId: response.payload.company.id,
        //     companyFeedbackCategory: "PROGRAM",
        //   })
        // );
        dispatch(receiveProgramdata(response.payload));

        try {
          if (response.payload.company.companyLogo) {
            dispatch(
              ratingPagecompanyLogo(response.payload.company.companyLogo)
            );
          }
        } catch (e) {
          console.log("Company Logo not found");
        }
      })
      .catch((error) => {
        dispatch(receiveProgramdataError(error));
      });
  };
}

export function ratingPagecompanyLogo(url) {
  return (dispatch) => {
    CompanyService.ratingPagecompanyLogo(url)
      .then((response) => {
        dispatch(setRatinPageCompanyLogo(response));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getCatogeryAverageRatings(
  companyId,
  verifiedCustomerOnly,
  anonymousHidden,
  compared,
  categoryID,
  category,
  tagId,
  role
) {
  return (dispatch, getState) => {
    const {
      auth: {
        userData: { email: emailId, userRole: myRole },
      },
    } = getState();
    dispatch(getCatogeryAverageRatingsRequested());
    CompanyService.getProgram_TeamaverageRatings(
      companyId,
      verifiedCustomerOnly,
      anonymousHidden,
      compared,
      categoryID,
      category,
      tagId,
      role,
      emailId,
      myRole
    )
      .then((response) => {
        dispatch(getCatogeryAverageRatingsSucceeded(response.payload));
        if (!window.localStorage.getItem("CompanyLogo")) {
          try {
            if (response.payload.companyLogo) {
              dispatch(companyLogo(response.payload.companyLogo));
            }
          } catch (e) {
            console.log("Company Logo not found");
          }
        }
      })
      .catch((error) => {
        dispatch(getCatogeryAverageRatingsFailed(error));
      });
  };
}

export function getCatogeryRepTrends(
  companyId,
  beginDateInMillis,
  endDateInMillis,
  verifiedCustomerOnly,
  anonymousHidden,
  categoryID,
  category,
  tagId,
  role
) {
  return (dispatch, getState) => {
    const {
      auth: {
        userData: { email: emailId, userRole: myRole },
      },
    } = getState();

    dispatch(getCatogeryRepTrendsRequested());

    CompanyService.getCatogeryRepTrends(
      companyId,
      beginDateInMillis,
      endDateInMillis,
      verifiedCustomerOnly,
      anonymousHidden,
      categoryID,
      category,
      tagId,
      role,
      emailId,
      myRole
    )
      .then((response) => {
        dispatch(getCatogeryRepTrendsSucceeded(response.payload));
      })
      .catch((error) => {
        dispatch(getCatogeryRepTrendsFailed(error));
      });
  };
}

export function getProgramCompanyMaps(
  companyId,
  beginDateInMillis,
  endDateInMillis,
  verifiedCustomerOnly,
  anonymousHidden,
  category,
  globalcategory,
  tagId,
  filter,
  value,
  threshold,
  repType,
  categoryID
) {
  return (dispatch, getState) => {
    const {
      auth: {
        userData: { email: emailId, userRole: myRole },
      },
    } = getState();
    dispatch(getCatogeryMapsRequested());
    CompanyService.getProgramCompanyMaps(
      companyId,
      beginDateInMillis,
      endDateInMillis,
      verifiedCustomerOnly,
      anonymousHidden,
      category,
      globalcategory,
      tagId,
      filter,
      value,
      threshold,
      repType,
      categoryID,
      emailId,
      myRole
    )
      .then((response) => {
        dispatch(getCatogeryMapsSucceeded(response.payload));
      })
      .catch((error) => {
        dispatch(getCatogeryMapsFailed(error));
      });
  };
}

export function getCatogeryHotspotReport(
  companyId,
  beginDateInMillis,
  endDateInMillis,
  verifiedCustomerOnly,
  anonymousHidden,
  categoryID,
  category,
  tagId,
  role
) {
  return (dispatch, getState) => {
    const {
      auth: {
        userData: { email: emailId, userRole: myRole, seeAllData },
      },
    } = getState();
    dispatch(getCatogeryHotspotReportRequested());
    CompanyService.getCatogeryHotspotReport(
      companyId,
      beginDateInMillis,
      endDateInMillis,
      verifiedCustomerOnly,
      anonymousHidden,
      categoryID,
      category,
      tagId,
      role,
      emailId,
      myRole,
      seeAllData
    )
      .then((response) => {
        dispatch(getCatogeryHotspotReportSucceeded(response.payload));
      })
      .catch((error) => {
        dispatch(getCatogeryHotspotReportFailed(error));
      });
  };
}

export function handleSubmitRating(obj) {
  return (dispatch) => {
    dispatch(requestRateCatogery());
    CompanyService.submitRating(obj)
      .then((response) => {
        dispatch(receiveRateCatogery(response.payload));
      })
      .catch((error) => {
        dispatch(receiveRateCatogeryError(error));
      });
  };
}

export function getCompanyProgramList(
  companyId,
  beginDateInMillis,
  endDateInMillis,
  anonymousHidden,
  companyFeedbackCategory
) {
  return (dispatch) => {
    dispatch(requestCompanyProgramList());
    CompanyService.getCompanyProgramList(
      companyId,
      beginDateInMillis,
      endDateInMillis,
      anonymousHidden,
      companyFeedbackCategory
    )
      .then((res) => {
        if (companyFeedbackCategory === "PROGRAM") {
          dispatch(receiveCompanyProgramList(res.payload));
        } else if (companyFeedbackCategory === "TEAM") {
          dispatch(receiveCompanyTeamList(res.payload));
        }
      })
      .catch((err) => {
        dispatch(receiveCompanyProgramListError(err));
      });
  };
}

export function getProgarmViewData(programId) {
  return (dispatch) => {
    dispatch(requestCatogeryViewdata());
    CompanyService.getProgramViewdata(programId)
      .then((response) => {
        dispatch(receiveCatogeryViewdata(response.payload));
      })
      .catch((error) => {
        dispatch(receiveCatogeryViewdataError(error));
      });
  };
}

export const handleAddSelectedTag = (
  feedbackId,
  tags,
  feedbackCategory,
  companyId,
  repId,
  programId
) => {
  return (dispatch) => {
    dispatch(addTagToFeedback());
    CompanyService.attachTagFeedback(feedbackId, feedbackCategory, tags)
      .then((response) => {
        dispatch(addTagToFeedbackSuccess(response));
        if (response.statusCode == 200) {
          if (programId) {
            dispatch(getProgarmViewData(programId));
          } else {
            dispatch(viewRepCompany(repId, 0, 0, companyId));
          }
        }
      })
      .catch((error) => dispatch(addTagToFeedbackError(error)));
  };
};

export const updateDefaultDashboard = (obj) => {
  return (dispatch) => {
    dispatch(updateDefaultDashboardRequested());
    CompanyService.updateDefaultDashboard(obj)
      .then((response) => {
        dispatch(updateDefaultDashboardSuccess(response));
      })
      .catch((error) => dispatch(updateDefaultDashboardError(error)));
  };
};

export function getTeamData(Id) {
  return (dispatch) => {
    dispatch(requestTeamData());
    CompanyService.getTeamData(Id)
      .then((response) => {
        dispatch(receiveTeamdata(response.payload));
        try {
          if (response.payload.company.companyLogo) {
            dispatch(
              ratingPagecompanyLogo(response.payload.company.companyLogo)
            );
          }
        } catch (e) {
          console.log("Company Logo not found");
        }
      })
      .catch((error) => {
        dispatch(receiveTeamdataError(error));
      });
  };
}

export function getTeamViewData(teamId) {
  return (dispatch) => {
    dispatch(requestCatogeryViewdata());
    CompanyService.getTeamViewdata(teamId)
      .then((response) => {
        dispatch(receiveCatogeryViewdata(response.payload));
      })
      .catch((error) => {
        dispatch(receiveCatogeryViewdataError(error));
      });
  };
}

export function addCatogeryCustomAttr(customeAttrdata) {
  return (dispatch) => {
    dispatch(addCatogeryCustomAttrRequested());
    CompanyService.addCatogeryCustomeAttributes(customeAttrdata)
      .then((response) => {
        dispatch(addCatogeryCustomAttrSucceeded(response));
        dispatch(
          getCompanyCustomeAttribute({
            companyId: customeAttrdata.companyId,
            companyFeedbackCategory: customeAttrdata.companyFeedbackCategory,
          })
        );
      })
      .catch((error) => {
        dispatch(addCatogeryCustomAttrFailed(error));
      });
  };
}

export function getInfo(id) {
  return (dispatch) => {
    dispatch(requestInfo());
    CompanyService.getCompanyInfo(id)
      .then((res) => {
        dispatch(receiveInfo(res.payload));
      })
      .catch((err) => {
        dispatch(receiveInfoError(err));
      });
  };
}

export function getEmailTemplate(id) {
  return (dispatch) => {
    dispatch(requestEmailTemplate());
    CompanyService.getEmailTemplate(id)
      .then((res) => {
        dispatch(receiveEmailTemplate(res.payload));
      })
      .catch((err) => {
        dispatch(receiveEmailTemplateError(err));
      });
  };
}

export function updateEmailTemplate(obj) {
  return (dispatch) => {
    dispatch(requestUpdateEmailTemplate());
    CompanyService.updateEmailTemplate(obj)
      .then((res) => {
        dispatch(EmailTemplateUpdated(res.payload));
        dispatch(getEmailTemplate(obj.companyId));
      })
      .catch((err) => {
        dispatch(UpdateEmailTemplateError(err));
      });
  };
}
