import React from "react";
import styled from "styled-components";

import { reporaColor, reporaDevice } from "../../../components/global/commonStyles";
import RatingStars from "../../../components/RatingStars/RatingStars";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  flex-shrink: 0;
  padding: 0 20px;
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.08);
`;

const RateRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
  align-items: center;
  text-transform: ${(props) => props.custom ? '' : 'capitalize'};

  >div:nth-child(1) {
    flex: 1;
  }

  >div:nth-child(2) {
    flex: 0 0 120px;
  }

  >div:nth-child(3) {
    flex: 0 0 50px;
  }

  @media ${reporaDevice.xs} {
    flex-wrap: wrap;

    >div:nth-child(1) {
      flex: 100%;
    }
  }
`;

const Title = styled.p`
  font-size: 16px;
  color: ${reporaColor.warmGrey};
`;

const Value = styled.p`
  font-size: 12px;
  color: ${reporaColor.warmGrey};
`;

const RatingsCard = props => {
  let categories = props.categories || [];

  return (
    <Wrapper>
      {categories.map((category, index) => {
        let catFormatted;
        if(props.custom){
          catFormatted = category.type.replace("_"," ")
        } else {
        catFormatted = category.type.replace(/_/g, " ").toLowerCase();
        }
        let value = category.value || 0;
        if (value === "NaN") {
          value = 0;
        }
        return (
          <RateRow key={"text" + index} custom = {props.custom}>
            <div>
              <Title textColor={reporaColor.warmGrey}>{catFormatted}</Title>
            </div>

            <div>
              <RatingStars static rating={value} />
            </div>

           
            <div>
              <Value>{value ? value?.toFixed(2) : "-"}</Value>
            </div>
          </RateRow>
        );
      })}
    </Wrapper>
  );
};

export default RatingsCard;
