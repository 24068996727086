import { LOGOUT_SUCCEEDED } from "../actions/authActions";
import * as reportingActions from "../actions/reportingPreferencesAction";

const initialState = {
  isAtributesLoading: false,
  attributesList: {},
  isProgramsLoading: false,
  programsList: [],
  tagList: null,
};

const getAttributeList = ({ payload }) => {
  return {
    standard: [...(payload["Standard Attributes"] || [])],
    custom: [...(payload["Custom Attributes"] || [])],
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_SUCCEEDED:
      return {
        ...initialState,
      };
    case reportingActions.GET_PROGRAMS_LIST: {
      return {
        ...state,
        isProgramsLoading: true,
      };
    }
    case reportingActions.GET_PROGRAMS_LIST_SUCCESS: {
      return {
        ...state,
        isProgramsLoading: false,
        programsList: action.payload.payload,
      };
    }
    case reportingActions.GET_PROGRAMS_LIST_ERROR: {
      return {
        ...state,
        isProgramsLoading: false,
      };
    }
    case reportingActions.ADD_PROGRAM: {
      return {
        ...state,
        isProgramAdding: true,
      };
    }
    case reportingActions.ADD_PROGRAM_SUCCESS: {
      return {
        ...state,
        isProgramAdding: false,
        programAdded: action.payload.payload,
      };
    }
    case reportingActions.ADD_PROGRAM_ERROR: {
      return {
        ...state,
        isProgramAdding: false,
      };
    }
    case reportingActions.GET_PROGRAM: {
      return {
        ...state,
        isAtributesLoading: true,
      };
    }
    case reportingActions.GET_PROGRAM_SUCCESS: {
      return {
        ...state,
        isAtributesLoading: false,
        programDetails: action.payload.payload,
      };
    }
    case reportingActions.GET_PROGRAM_ERROR: {
      return {
        ...state,
        isAtributesLoading: false,
      };
    }
    case reportingActions.GET_ATTRIBUTES_LIST: {
      return {
        ...state,
        isAtributesLoading: true,
        attributesList: {},
      };
    }
    case reportingActions.GET_ATTRIBUTES_LIST_SUCCESS: {
      return {
        ...state,
        isAtributesLoading: false,
        attributesList: getAttributeList(action.payload),
      };
    }
    case reportingActions.LOADER_ACTIVATE: {
      return {
        ...state,
        loader: false,
      };
    }
    case reportingActions.GET_ATTRIBUTES_LIST_ERROR: {
      return {
        ...state,
        isAtributesLoading: false,
      };
    }
    case reportingActions.GET_TAG_LIST: {
      return {
        ...state,
        isTagsLoading: true,
      };
    }
    case reportingActions.GET_TAG_LIST_SUCCESS: {
      return {
        ...state,
        isTagsLoading: false,
        tagList: action.payload.payload,
      };
    }
    case reportingActions.GET_TAG_LIST_ERROR: {
      return {
        ...state,
        isTagsLoading: false,
      };
    }
    case reportingActions.ADD_TAG: {
      return {
        ...state,
        isLoadingAddTag: true,
      };
    }
    case reportingActions.ADD_TAG_SUCCESS: {
      return {
        ...state,
        isLoadingAddTag: false,
        addTag: action.payload.payload,
      };
    }
    case reportingActions.ADD_TAG_ERROR: {
      return {
        ...state,
        isLoadingAddTag: false,
      };
    }
    case reportingActions.DELETE_TAG: {
      return {
        ...state,
        tagDeleted: false,
        isLoadingDeleteTag: true,
      };
    }
    case reportingActions.DELETE_TAG_SUCCESS: {
      return {
        ...state,
        tagDeleted: true,
        isLoadingDeleteTag: false,
      };
    }
    case reportingActions.UPDATE_TAG: {
      return {
        ...state,
        isTagUpdating: true,
      };
    }
    case reportingActions.UPDATE_TAG_SUCCESS: {
      return {
        ...state,
        isTagUpdating: false,
        tagUpdated: action.payload.payload,
      };
    }
    case reportingActions.UPDATE_TAG_ERROR: {
      return {
        ...state,
        isTagUpdating: false,
      };
    }
    case reportingActions.GET_TEAM_LIST: {
      return {
        ...state,
        isTeamsLoading: true,
      };
    }
    case reportingActions.GET_TEAM_LIST_SUCCESS: {
      return {
        ...state,
        isTeamsLoading: false,
        teamsList: action.payload.payload,
      };
    }
    case reportingActions.GET_TEAM_LIST_ERROR: {
      return {
        ...state,
        isTeamsLoading: false,
      };
    }
    case reportingActions.ADD_TEAM: {
      return {
        ...state,
        isTeamAdding: true,
      };
    }
    case reportingActions.ADD_TEAM_SUCCESS: {
      return {
        ...state,
        isTeamAdding: false,
        teamAdded: action.payload.payload,
      };
    }
    case reportingActions.ADD_TEAM_ERROR: {
      return {
        ...state,
        isTeamAdding: false,
      };
    }
    case reportingActions.GET_TEAM: {
      return {
        ...state,
        isTeamDataLoading: true,
      };
    }
    case reportingActions.GET_TEAM_SUCCESS: {
      return {
        ...state,
        isTeamDataLoading: false,
        teamDetails: action.payload.payload,
      };
    }
    case reportingActions.GET_TEAM_ERROR: {
      return {
        ...state,
        isTeamDataLoading: false,
      };
    }
    case reportingActions.DELETE_TEAM_SUCCESS: {
      return {
        ...state,
        teamDeleted: true,
        isLoadingDeleteTeam: false,
      };
    }
    case reportingActions.UPDATE_TEAM_SUCCESS: {
      return {
        ...state,
        teamUpdated: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
