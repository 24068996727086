//@flow
import React from "react";
import styled from "styled-components";

import { reporaDevice, reporaColor, Title, Header2, Body3 } from "../../components/global/commonStyles";
import { ReporaButton } from "../global/commonStyles";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;

  @media ${reporaDevice.tablet} {
    ${props => props.contentPosition === "left"
    ? "flex-direction: row-reverse;"
    : "flex-direction: row;"
  }
    justify-content: space-between;
  }
`;

const PanelContent = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px 0;
  position: relative;
  color: ${props => props.textColor || reporaColor.black};

  @media ${reporaDevice.tablet} {
    max-width: 475px;
    padding: 0 25px;
    margin: 0 auto;
    text-align: ${props => props.contentPosition
    ? props.contentPosition
    : "left"}
    ${props => props.contentPosition === "left"
    ? "align-items: flex-start;"
    : "align-items: flex-end;"
  }
  }

  >* {
    max-width: 100%;
  }

  h4, h2 {
    margin-bottom: 15px;

    @media ${reporaDevice.tablet} {
      margin-bottom: 30px;
    }
  }
`;

const BkgImage = styled.img`
  width: 100%;

  @media ${reporaDevice.tablet} {
    position: absolute;
    top: 0;
    left: 0;

  }
`;

const FrgImage = styled.img`
  width: 180px;
  height: 140px;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 50%;

  @media ${reporaDevice.tablet} {
    margin-top: 10%;
  }

  @media ${reporaDevice.tablet} {
    width: 220px;
    height: 180px;
  }
`;

const SideImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  @media ${reporaDevice.tablet} {
    width: 55%;
    padding-bottom: ${props => props.imgRatio}%;

    img {

    }
  }
`;

class PanelImage extends React.Component {
  render() {
    if (this.props.data) {


      const ratio = this.props.data.imgHeight * 100 / this.props.data.imgWidth;
      return (
        <Wrapper contentPosition={this.props.contentPosition}>
          {this.props.data.bkgImgSrc
            ? <BkgImage src={this.props.data.bkgImgSrc} alt="" />
            : null
          }
          <SideImage imgRatio={ratio}>
            {this.props.data.imgSrc
              ? <img src={this.props.data.imgSrc} alt="" />
              : null
            }
          </SideImage>
          <PanelContent contentPosition={this.props.contentPosition}>
            <Title textColor={this.props.textColor === "black" || !this.props.textColor ?
              `${reporaColor.warmGrey}`
              : `${reporaColor.black}`} >
              {this.props.data.title}</Title>
            <Header2 textColor={this.props.textColor}>{this.props.data.header}</Header2>
            <Body3 textColor={this.props.textColor}>{this.props.data.description}</Body3>
            {this.props.data.buttonTxt &&
              <ReporaButton href={this.props.data.href} secondary={this.props.data.secondary} marginTop="44px" >{this.props.data.buttonTxt}</ReporaButton>
            }
          </PanelContent>
          {
            this.props.data.frgImgSrc
              ? <FrgImage src={this.props.data.frgImgSrc} alt="" />
              : null
          }
        </Wrapper>
      )
    } else {
      return null;
    }
  }
}



export const Unwrapped = PanelImage;
export default PanelImage;
