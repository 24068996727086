import moment from "moment";
import React from "react";
import EditForm from "./EditForm";
import { Row } from "./ListItem";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const PartnerList = (props) => {
    const [isOpen , setOpen] = useState(false);
    const {ele , i} = props;

    const handleSetOpen = () => {
      setOpen(false)
    }

    const handleRemovePartnerKey = () => {
      props.handleRemovePartnerKey(props?.ele)
    }

    return (
        <>
            <div  key= {Math.random().toString()}>
            <Row>
            <div>{ele?.partnerName}</div>
            <div>{ele?.reporaCompanyName || ""}</div>
            <div className="keyDiv">{ele.partnerApiKey}</div>
            <span onClick={()=>setOpen(!isOpen)} style={{ paddingLeft: "5px" }}>
              <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
            </span>
          </Row>
          {isOpen && (
            <EditForm
              {...props}
              oldKey = {ele.partnerApiKey}
              handleRemovePartnerKey = { handleRemovePartnerKey }
              handleUpdatePartnerKey = { props.handleUpdatePartnerKey}
              handleSetOpen = {handleSetOpen}
              fromManagePKeys
            />
          )}
          </div>
        </>
    )

}

export default PartnerList;