import React from "react";
import styled from "styled-components";

import { reporaDevice } from "../../components/global/commonStyles";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${props => props.bkgImage};
  margin: 2% 0 10%;
`;

const VideoFrame = styled.div`
  height: 0;
  padding-bottom: 60%;
  width: 95%;
  max-width: 850px;
  position: relative;

  @media ${reporaDevice.tablet} {
    padding-bottom: 36%;
  }

  iframe, img {
    position: absolute;
    top: 0;
    left: 50%;
    width: auto;
    height: 100%;
    transform: translateX(-50%);

  }
`;

class PanelVideo extends React.Component {
  render() {
    return (
      <Wrapper>
        <VideoFrame>
          <img src={this.props.data.videoPoster} alt="" />
         </VideoFrame>
      </Wrapper>
    )
  }
}

export const Unwrapped = PanelVideo;
export default PanelVideo;
