export const Trends = [
  {
    key: "overallRatingList",
    label: "Overall Rating",
    color: "#14E5E8"
  },
  {
    key: "repScoreList",
    label: "Rep Score",
    color: "#3B14E8"
  },
  {
    key: "productKnowledgeList",
    label: "Product Knowledge",
    color: "#E814CE"
  },
  {
    key: "communicationSkillsList",
    label: "Comunication Skills",
    color: "#44E814"
  },
  {
    key: "trustworthinessList",
    label: "Trustworthiness",
    color: "#1714E8"
  },
  {
    key: "professionalismList",
    label: "Professionalism",
    color: "#D8E814"
  },
  {
    key: "organizationSkillsList",
    label: "Organization Skills",
    color: "#14E8D5"
  },
  {
    key: "followThroughList",
    label: "Follow Though",
    color: "#E87114"
  },
  {
    key: "punctualityList",
    label: "Punctuality",
    color: "#8B14E8"
  },
  {
    key: "thumbsUpsList",
    label: "Positive Experience",
    color: "#E83B14"
  },
  {
    key: "availabilityList",
    label: "Availability",
    color: "#1484E8"
  }
];

export const TimeFilter = [
  {
    label: "3 Months",
    months: 3
  },
  {
    label: "6 Months",
    months: 6
  },
  {
    label: "1 Year",
    months: 12
  }
];
