import React from "react";
import styled, { keyframes } from "styled-components";
import SpinnerSvg from "./assets/spinner.svg";
import PropTypes from "prop-types";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100%{ transform: rotate(360deg); }
`;

const Image = styled.img`
  animation: ${spin} 2s infinite linear;
  height: ${props => 
    props.size === "small" ? "50px" : 
    props.size === "medium" ? "100px" : 
    props.size === "large" ? "200px" : props.size || "50px"
  };
  color: ${props => props.color || "black"};
`;

const LoaderContainer = styled.div`
  position: ${props => props.overlayed ? "absolute" : "relative"};

  ${props => props.overlayed ? `
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.2);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  ` : null};
`;

const Loader = props => (
  <LoaderContainer overlayed={props.overlayed}>
    <Image size={props.size} src={SpinnerSvg} alt="Loading" />
  </LoaderContainer>
);

Loader.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  color: PropTypes.string,
  overlayed: PropTypes.bool
};

export default Loader;
