import React from "react";
import styled from "styled-components";
import { reporaColor, ReporaButton, reporaDevice, Header2 } from "../../../components/global/commonStyles";
import Hero from "../../../components/Hero/Hero";
import PanelVideo from "../../../components/PanelVideo/PanelVideo";
import PanelColumns from "../../../components/PanelColumns/PanelColumns";
import PanelImage from "../../../components/PanelImage/PanelImage";

import HandshakeBackground from "../assets/handshake.jpg";

import Footer from "../../../components/Footer/Footer";

import Data from "./Data";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PageSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: ${props => props.bkgImage ? `url(${props.bkgImage})` : "none"};
  background-position: ${props => props.position || "initial"};
  background-size: ${props => props.bkgSize || "auto"};
  background-repeat: no-repeat;

  @media ${reporaDevice.tablet} {
    ${props => props.arch ? "margin-top: -4.3%;" : null}

    padding-top: ${props => props.spacer || "5%"};
    padding-bottom: ${props => props.spacer || "5%"};
  }
`;

const CustomPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h2 {
      padding: 50px 0 30px;
  }

  a {
      margin-bottom: 130px;
  }
`;

const View = () => {
  return (
    <Wrapper>
      <PageSection bkgImage={HandshakeBackground} bkgSize="cover">
        <Hero
          margin="169px"
          textColor={reporaColor.black}
          h1MarginBotton="31px"
          pMarginBotton="43px"
          h1Width="815px"
          pWidth="923px"
          data={Data.hero} />
      </PageSection>

      <PageSection bkgImage="/assets/home/hero.svg">
        <PanelVideo youtubeId={"txQ6t4yPIM0"} data={Data.heroVideo} />
      </PageSection>

      <PageSection>
        <PanelColumns data={Data.ratingSystem} />
      </PageSection>

      <PageSection>
        <PanelImage contentPosition="left" data={Data.repscoreProvides} />
      </PageSection>

      <PageSection>
        <PanelImage contentPosition="right" data={Data.forCompanies} />
      </PageSection>

      <PageSection bkgImage="/assets/home/customers-bkg.svg">
        <PanelImage textColor={`${reporaColor.white}`} contentPosition="left" data={Data.forCustomers} />
      </PageSection>

      <PageSection spacer="0px">
        <PanelImage contentPosition="right" data={Data.forReps} />
      </PageSection>

      <PageSection bkgImage="/assets/home/start-repscore.png" arch spacer="0px">
        <CustomPanel>
          <Header2 textAlign="center" textColor={`${reporaColor.white}`}>Start using RepScore today</Header2>
          <ReporaButton href="/signup" secondary>Get Started</ReporaButton>
        </CustomPanel>
      </PageSection>

      <Footer />

    </Wrapper>
  );
};

export default View;
