import React from "react";
import styled from "styled-components";

import { reporaColor } from "../global/commonStyles";

const Wrapper = styled.div`
  display: flex;
  margin-left: 5px;
  width: auto;
  height: auto;
  position: relative;

  img {
    width: 22px;
    height: 22px;
  }

  &:hover {
    span {
      display: block;
    }
  }
`;

const TooltipMessage = styled.span`
  position: absolute;
  background: ${reporaColor.black};
  color: ${reporaColor.white};
  font-size: 12px;
  padding: 10px;
  border-radius: 5px;
  width: 200px;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 10px);
  display: none;

  &:after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

`;

const HelpTooltip = props => {
  return (
    <Wrapper>
      <img src="/assets/global/help.svg" alt=""/>
      {props.data?
      <TooltipMessage>
        {props.data}
      </TooltipMessage>:""}
    </Wrapper>
    );
}

export default HelpTooltip;
