import React from "react";
import styled from "styled-components";

import { useEffect } from "react";
import DefaultAvatarSvg from "../../../assets/global/default-avatar.svg";
import Loader from "../../../components/Loader/Loader";
import {
  reporaColor,
  reporaDevice,
} from "../../../components/global/commonStyles";

const Wrapper = styled.div`
  position: relative;
  background-color: ${reporaColor.white};
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.08);
  padding: 20px;
  ${(props) =>
    props.privateCompany
      ? `justify-content: center;
  display: grid; `
      : `display: flex;`};

  @media ${reporaDevice.xs} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const ImageWrapper = styled.div`
  img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    object-position: center;
  }
`;

const InformationWrapper = styled.div`
  ${(props) =>
    props.privateCompany ? `margin-left: 0px;` : `margin-left: 20px`};
`;

const RepName = styled.p`
  display: inline;
  font-size: 16px;
  font-weight: bold;
`;

const PersonCard = (props) => {
  useEffect(() => {}, [props.teamData, props.companylogBlob]);
  let companyName = "No company found";
  if (props.teamData.company?.name !== "") {
    companyName = props.teamData.company.name;
  }
  return (
    <Wrapper privateCompany={props.teamData?.company?.privateCompany}>
      {props.isFetching && <Loader overlayed size="medium" />}
      {props.teamData?.company?.privateCompany ? (
        <>
          <InformationWrapper
            privateCompany={props.teamData?.company?.privateCompany}
          >
            <RepName>{companyName}</RepName>
          </InformationWrapper>

          <ImageWrapper>
            <img src={props.companylogBlob || DefaultAvatarSvg} alt="logo" />
          </ImageWrapper>
        </>
      ) : (
        <>
          <ImageWrapper>
            <img src={props.companylogBlob || DefaultAvatarSvg} alt="logo" />
          </ImageWrapper>

          <InformationWrapper
            privateCompany={props.teamData?.company?.privateCompany}
          >
            <RepName>{companyName}</RepName>
          </InformationWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default PersonCard;
