import React from "react";
import { connect } from "react-redux";

import {
  getRepSuggestions,
  getCompanyRepSuggestions,
} from "../../actions/repActions";
import { getCompanySuggestions } from "../../actions/companyActions";
import SearchForm from "./components/SearchForm";
import {
  requestClearRepSelectionData,
  searchCompanyReps,
  searchReps,
} from "../../actions/searchActions";

class HeaderSearchRepUnclaimed extends React.Component {
  render() {
    return (
      <SearchForm
        {...this.props}
        repSuggestions={this.props.repSuggestions}
        companySuggestions={this.props.companySuggestions}
        repSuggestionsFetching={this.props.repSuggestionsFetching}
        companySuggestionsFetching={this.props.companySuggestionsFetching}
        repSuggestionsError={this.props.repSuggestionsError}
        companySuggestionsError={this.props.companySuggestionsError}
        handleGetRepSuggestions={this.props.handleGetRepSuggestions}
        handleGetCompanySuggestions={this.props.handleGetCompanySuggestions}
        inputDelay={500}
        header={this.props.header}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.userData,

  repSuggestions: state.rep.repSuggestions,
  companySuggestions: state.company.companySuggestions,

  repSuggestionsFetching: state.rep.repSuggestionsFetching,
  companySuggestionsFetching: state.company.companySuggestionsFetching,

  repSuggestionsError: state.rep.repSuggestionsError,
  companySuggestionsError: state.company.companySuggestionsError,

  repResults: state.search.repResults,
  repResultsFetching: state.search.repResultsFetching,
  repResultsError: state.search.repResultsError,
});

const mapDispatchToProps = (dispatch) => ({
  handleGetRepSuggestions(repName, companyId) {
    // sending true because all the suggestions must be from unclaimed accounts
    dispatch(getRepSuggestions(repName, companyId, true));
  },
  handleGetCompanySuggestions(companyName, repId) {
    dispatch(getCompanySuggestions(companyName, repId));
  },
  handleGetCompanyRepSuggestions(repName, companyId) {
    dispatch(getCompanyRepSuggestions(repName, companyId));
  },
  handleSearchReps(repName, repId, companyName, companyId) {
    dispatch(
      searchReps(
        repName,
        repId,
        companyName,
        companyId,
        false,
        this.user ? this.user.id : null
      )
    );
  },
  handleSearchCompanyReps(repName, repId, companyId) {
    dispatch(
      searchCompanyReps(
        repName,
        repId,
        companyId,
        this.user ? this.user.id : null
      )
    );
  },
  clearRepSelectionData(data) {
    dispatch(requestClearRepSelectionData(data));
  },
});

export const Unwrapped = HeaderSearchRepUnclaimed;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderSearchRepUnclaimed);
