import React from "react";
import styled from "styled-components";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  reporaDevice,
  reporaColor,
  ReporaButton,
  Header3,
  Body3,
  Body4,
  NoBorderTextArea, TextAreaWrapper
} from "../../../components/global/commonStyles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  flex-shrink: 0;
  padding: 30px 10px;
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.08);
  
  ${Body3} {
    margin-top: 25px;
  }

  @media ${reporaDevice.tablet} {
    padding: 30px 15px;
  }

  @media ${reporaDevice.desktop} {
    padding: 30px 50px;
  }
`;
export const CloseButton = styled.span`
  position: inherit;
  font-size: 20px;
  cursor: pointer;
  color: ${reporaColor.warmGrey};
`;

const ButtonWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
`;

const CharsLeftLabel = styled.label`
  position: absolute;
  bottom:3%;
  left: 5%;
`;
const SubButt = styled.div`
margin-top:3%;

@media ${reporaDevice.mobile} {
    margin:3% 0% 0% 0%;
    a{
width:100%;
font-size: 15px;
  }
  }
`;

const FormCard = props => {
  const {
    handleBack
  } = props;

  return (
    <Wrapper>
      <ButtonWrapper>
        <Header3>Claim this Rep Account</Header3>
        <CloseButton onClick={handleBack}><FontAwesomeIcon icon={faTimes} /></CloseButton>
      </ButtonWrapper>
      <Body3 textColor={`${reporaColor.brightSkyBlue}`}>
        In order to claim this account and the ratings associated, please tell us why you think they’re yours. The text below is just an example. Feel free to delete it and write a custom message and please be as detailed as possible.
      </Body3>
      <TextAreaWrapper>
        <NoBorderTextArea maxLength="1000" cols="30" rows="5" onChange={props.handleCommentChange} value={props.detail} placeholder="Enter your written explanation here..." />
        <CharsLeftLabel><Body4 textColor={`${reporaColor.brightSkyBlue}`}>{props.charactersLeft} characters left</Body4></CharsLeftLabel>
      </TextAreaWrapper>
      <SubButt>
        <ReporaButton large onClick={props.handleFormSubmit} disabled={!props.isFormValid}>
          Submit Claim
        </ReporaButton>
      </SubButt>
    </Wrapper>
  );
};

export default FormCard;
