import { isEqual } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import ReactTooltip from "react-tooltip";
import Button from "../../../components/Buttons/Button";
import DropdownAddOption from "../../../components/Dropdown/DropdownAddOption";
import DynamicRating, {
  DynamicCapital,
} from "../../../components/Dynamic/DynamicRating";
import HelpTooltip from "../../../components/HelpTooltip/HelpTooltip";
import TextArea from "../../../components/Inputs/TextArea";
import LikeDislikeOption from "../../../components/LikeDislikeOption/LikeDislikeOption";
import Loader from "../../../components/Loader/Loader";
import RatingStars from "../../../components/RatingStars/RatingStars";
import {
  Body2,
  Body5,
  CheckComponent,
  FormRow,
  Header3,
  reporaColor,
  reporaDevice,
} from "../../../components/global/commonStyles";
import { FormWrapper } from "../../SignUp/components/CommonStyles";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  flex-shrink: 0;
  padding: 30px 10px;
  margin-bottom: 40px;
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.08);

  @media ${reporaDevice.tablet} {
    padding: 30px 15px;
  }

  @media ${reporaDevice.desktop} {
    padding: 30px 50px;
  }
`;
const RadioSize = styled.div`
  margin-top: 6%;
  white-space: nowrap;
  height: 100%;
`;
const RadioRate = styled.label`
  font-size: 16px;
  font-family: fangsong;

  label > input[type="radio"] {
    display: none;
  }
  label > input[type="radio"] + *::before {
    content: "";
    display: inline-block;
    /* vertical-align: bottom; */
    width: 13px;
    height: 13px;
    margin-right: 0.2rem;
    margin-bottom: 0%;
    border: 1.5px solid #9b9b9b;
    border-radius: 100%;
  }

  label > input[type="radio"]:checked + *::before {
    background: radial-gradient(
      ${reporaColor.brightSkyBlue} 0%,
      ${reporaColor.brightSkyBlue} 40%,
      transparent 50%,
      transparent
    );
  }

  label > input[type="radio"]:checked + *::after {
    content: "";
    width: 10px;
    height: 10px;
    background: #0bc9f6;
    position: absolute;
    top: calc(50% - 5px);
    left: -17px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
  }
`;

let AnonymousEle = styled.span`
font-family: Nexa Bold;
box-sizing: border-box;
font-size: 12px;
display: grid;
line-height: 22px;
margin-top: 10px;
margin-left:10px
margin-bottom: 10px;

@media only screen and (min-width:768px){
  input{
    width:100%;
   }
}
@media only screen and (max-width:598px){
  input{
    width:98%;
   }
}

`;

const InvalidSpan = styled.div`
  font-size: small;
  color: red;
  text-align: center;
  margin-top: -2%;
`;

const InformationSpan = styled.span`
  margin-left: 5px;
  width: auto;
  height: auto;
  ${(props) => (props.position ? "position: absolute" : "position: relative")};

  img {
    width: 22px;
    height: 22px;
  }

  &:hover {
    span {
      display: block;
    }
  }
`;

const CollapseRow = styled(FormRow)`
  margin-top: -2%;
  margin-bottom: -6%;
  @media ${reporaDevice.xs} {
    flex-direction: column;
    > div {
      margin: 0;
      width: 100%;
    }
  }
`;
const RateRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-top: 1px solid ${reporaColor.grey91};

  ${(props) =>
    props.last &&
    css`
      border-bottom: 1px solid ${reporaColor.grey91};
    `}

  @media ${reporaDevice.xs} {
    flex-direction: column;
    align-items: center;
  }
`;

const DropdownContainer = styled.div`
  margin: 20px 0;

  p {
    color: ${reporaColor.warmGrey};
    font-size: 12px;
    font-weight: bold;
  }
`;

const LegendBlue = styled.p`
  color: ${reporaColor.brightSkyBlue};
  font-size: 14px;
  font-weight: bold;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > button:last-of-type {
    margin-left: 15px;
    flex: 1;
  }

  @media ${reporaDevice.xs} {
    flex-direction: column;

    > button:last-of-type {
      margin-left: 0;
      margin-top: 15px;
      flex: auto;
    }
  }
`;

const RatingTypes = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${reporaDevice.xs} {
    justify-content: center;
  }
`;

export const BodyDescripton = styled.p`
  font-family: Nexa Bold;
  font-size: 10px;
  line-height: 12px;
`;

class RatingsCard extends React.Component {
  state = {
    selectedCompany: null,
    selectedCompanyName: "",

    ratings: {},
    customeRating: {},
    thumbsUp: null,
    comment: "",
    anonymous: false,
    name: "",
    email: "",
    grayOut: false,
    varifiedEmail: false,
    verifiedEmailmsg: "",
    errorName: "",
  };

  componentDidMount() {
    const { repCompanies, repData, rateId, userData } = this.props;

    if (repData.ratingAttributes) {
      let tempVar = {};
      repData.ratingAttributes.forEach((ele) => {
        Object.assign(tempVar, { [ele]: null });
      });
      this.setState({ ratings: tempVar });
    }
    if (repCompanies.length) {
      const currentCompany = repCompanies.find(
        (company) => company.companyId === repData.companyId
      );
      const company = repCompanies.find(
        (company) => company.name === this.state.selectedCompanyName
      );
      this.setState({
        selectedCompany: company || currentCompany || repCompanies[0],
        selectedCompanyName:
          (company && company.name) ||
          (currentCompany && currentCompany.name) ||
          repCompanies[0].name,
      });
    }
    if (rateId) {
      const feedbackData = repData.customerFeedbackList.find(
        (feedback) => feedback.feedbackId === rateId
      );
      const ratings = {};
      const _customRatingCategory = {};
      feedbackData.ratingCategory.forEach(
        (rate) => (ratings[rate.type] = rate.value)
      );
      if (feedbackData.customRatingCategory) {
        feedbackData.customRatingCategory.forEach(
          (rate) => (_customRatingCategory[rate.type] = rate.value)
        );
      }
      this.setState({
        initialState: {
          selectedCompany: feedbackData.companyName,
          selectedCompanyName: feedbackData.companyName,
          ratings: ratings,
          customRatings: _customRatingCategory,
          thumbsUp: feedbackData.experience,
          comment: feedbackData.comment,
          anonymous: feedbackData.anonymous,
        },
        selectedCompany: feedbackData.companyName,
        selectedCompanyName: feedbackData.companyName,
        ratings: ratings,
        customeRating: _customRatingCategory,
        thumbsUp: feedbackData.experience,
        comment: feedbackData.comment,
        anonymous: feedbackData.anonymous,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { repCompanies, repData, rateId, userData, customAttr } = this.props;
    if (!rateId) {
      // NEW FEEDBACK
      if (
        repCompanies &&
        repCompanies.length > 0 &&
        repCompanies !== prevProps.repCompanies
      ) {
        const currentCompany = repCompanies.find(
          (company) => company.companyId === repData.companyId
        );
        const company = repCompanies.find(
          (company) => company.name === this.state.selectedCompanyName
        );

        this.setState({
          selectedCompany: company || currentCompany || repCompanies[0],
          selectedCompanyName:
            (company && company.name) ||
            (currentCompany && currentCompany.name) ||
            repCompanies[0].name,
        });
      }
    } else {
      // EDIT FEEDBACk
      if (repData && prevProps.repData !== repData) {
        const feedbackData = repData.customerFeedbackList.find(
          (feedback) => feedback.feedbackId === rateId
        );
        const ratings = {};
        const _customRatingCategory = {};
        feedbackData.ratingCategory.forEach(
          (rate) => (ratings[rate.type] = rate.value)
        );
        const company = repCompanies.find(
          (company) => company.companyId === feedbackData.company
        );
        if (feedbackData.customRatingCategory) {
          feedbackData.customRatingCategory.forEach(
            (rate) => (_customRatingCategory[rate.type] = rate.value)
          );
        }
        this.setState({
          initialState: {
            selectedCompany: repData.currentCompany,
            selectedCompanyName: company.name,

            ratings: ratings,
            customRatings: _customRatingCategory,
            thumbsUp: feedbackData.experience,
            comment: feedbackData.comment,
            anonymous: feedbackData.anonymous,
          },

          selectedCompany: company,
          selectedCompanyName: company.name,
          customeRating: _customRatingCategory,
          ratings: ratings,
          thumbsUp: feedbackData.experience,
          comment: feedbackData.comment,
          anonymous: feedbackData.anonymous,
        });
      }
    }

    if (this.props.repData.currentCompanyConnected === true) {
      if (!rateId && customAttr && prevProps.customAttr !== customAttr) {
        let custTempVar = {};
        customAttr.forEach((rate) => {
          if (rate.status) {
            Object.assign(custTempVar, { [rate.attributeName]: null });
          }
        });

        this.setState({ customeRating: custTempVar });
      }
    }
  }

  handleRateChange = (name, value) => {
    this.setState((prevState) => ({
      ratings: {
        ...prevState.ratings,
        [name]: parseInt(value),
      },
    }));
  };

  handleCustomeRateChange = (name, value) => {
    this.setState((prevState) => ({
      customeRating: {
        ...prevState.customeRating,
        [name]: parseInt(value),
      },
    }));
  };
  handleExperienceChange = (experience) => {
    this.setState({
      thumbsUp: experience,
    });
  };

  handleCommentChange = (event) => {
    this.setState({
      comment: event.target.value,
    });
  };

  anonymousVal = (event) => {
    if (event.target.value === "anonymous") {
      this.setState({
        anonymous: true,
        grayOut: true,
        varifiedEmail: false,
        verifiedEmailmsg: "",
        errorName: "",
      });

      AnonymousEle = styled.span`
          font-family: Nexa Bold;
          box-sizing: border-box;
          pointer-events: none;
          opacity: 0.3;
          font-size: 12px;
          display: grid;
          line-height: 22px;
          margin-top: 10px;
          margin-left:10px
          margin-bottom: 10px;
          width:100%;
          @media only screen and (min-width:768px){
            input{
              width:100%;
            }
          }
          @media only screen and (max-width:598px){
            input{
              width:98%;
             }
          }

          `;
    } else {
      this.setState({
        anonymous: false,
        grayOut: false,
        name: "",
        email: "",
        varifiedEmail: true,
        verifiedEmailmsg: "Please enter valid email",
        errorName: "Please enter valid name",
      });
      AnonymousEle = styled.span`
      font-family: Nexa Bold;
      box-sizing: border-box;
      font-size: 12px;
      display: grid;
      line-height: 22px;
      margin-top: 10px;
      margin-left:10px
      margin-bottom: 10px;
      width:100%;
      @media only screen and (min-width:768px){
        input{
          width:100%;
         }
      }
      @media only screen and (max-width:598px){
        input{
          width:98%;
         }
      }
     `;
    }
  };

  handlenameChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "name") {
      //old validation
      const pattern = !!value.match(
        /(^[a-zA-Z]{3,40}\s[a-zA-Z]{2,40})$|(^[a-zA-Z]{3,40}\S)$|(^[a-zA-Z]{3,40}\s[a-zA-Z]{2,40}\s[a-zA-Z]{2,40})$/
      );
      //new validation
      var letters = /^[a-zA-Z .]*$/;
      if (
        value &&
        value.length > 0 &&
        value.match(/[a-zA-Z]/) &&
        value.match(letters)
      ) {
        this.setState({
          name: value.trim(),
          errorName: "",
        });
      } else {
        this.setState({
          name: value.trim(),
          errorName: "Please enter valid name",
        });
      }
    } else {
      const emailValid = !!value.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ); // eslint-disable-line no-useless-escape
      if (emailValid) {
        this.setState({
          email: value,
          varifiedEmail: true,
          verifiedEmailmsg: "",
        });
      } else {
        this.setState({
          email: value,
          varifiedEmail: false,
          verifiedEmailmsg: "Please enter valid email",
        });
      }
    }
  };

  handleAnonymousChange = (event) => {
    this.setState({
      anonymous: event.target.checked,
    });
  };

  isFormValid = () => {
    const {
      ratings,
      thumbsUp,
      selectedCompany,
      name,
      email,
      varifiedEmail,
      customeRating,
    } = this.state;
    const { userData, repData } = this.props;
    const ratingsValid = !Object.keys(ratings).some((rating) => {
      return ratings[rating] === null;
    });
    const customeRatingValid = !Object.keys(customeRating).some((rating) => {
      return customeRating[rating] === null;
    });
    const experienceValid = thumbsUp !== null;
    if (userData) {
      if (!repData.unclaimed) {
        return (
          ratingsValid &&
          experienceValid &&
          selectedCompany &&
          customeRatingValid
        );
      } else {
        return ratingsValid && experienceValid && selectedCompany && true;
      }
    } else {
      let tempVar = false;
      if (this.state.anonymous) {
        tempVar = true;
      } else if (
        this.state.verifiedEmailmsg.length === 0 &&
        this.state.varifiedEmail &&
        this.state.name.length > 0 &&
        this.state.errorName.length === 0 &&
        this.state.anonymous === false
      ) {
        tempVar = true;
      } else {
        tempVar = false;
      }

      if (!repData.unclaimed) {
        return (
          ratingsValid &&
          experienceValid &&
          selectedCompany &&
          tempVar &&
          customeRatingValid
        );
      } else {
        return (
          ratingsValid && experienceValid && selectedCompany && tempVar && true
        );
      }
    }
  };

  handleCompanyChange = (companyDetails) => {
    const { repCompanies, repData, userData } = this.props;
    let companyName;
    let company;
    if (typeof companyDetails === "object") {
      company = repCompanies.find(
        (company) => company.companyId === companyDetails.companyId
      );
      companyName = company.name;
    } else {
      company = repCompanies.find((company) => company.name === companyName);
      companyName = companyDetails;
    }

    if (userData) {
      if (!company) {
        this.props.handleAddUserCompany(
          {
            name: companyName,
            enrolled: false,
            current: false,
            userId: repData.id,
          },
          true
        );
      }
    } else {
      if (!company) {
        this.props.handleAddUserCompany(
          {
            name: companyName,
            enrolled: false,
            current: false,
            userId: repData.id,
          },
          false
        );
      }
    }

    this.setState({
      selectedCompany: company,
      selectedCompanyName: companyName,
    });
  };

  handleFormSubmit = () => {
    if (this.isFormValid) {
      const { userData, repData, rateId } = this.props;
      const {
        selectedCompany,
        ratings,
        thumbsUp,
        comment,
        anonymous,
        name,
        email,
        customeRating,
      } = this.state;
      const formattedRatings = Object.keys(ratings).map((key) => ({
        category: key,
        value: ratings[key],
      }));

      const formattedCustomeRatings = Object.keys(customeRating).map((key) => ({
        category: key,
        value: customeRating[key],
      }));

      if (userData) {
        let companyId = "";
        if (selectedCompany.companyId) {
          companyId = selectedCompany.companyId;
        } else {
          const febBackID = repData.customerFeedbackList.find(
            (feedback) => feedback.feedbackId === rateId
          );
          companyId = febBackID !== undefined ? febBackID["company"] : "";
        }
        const obj = {
          feedbackId: rateId || null,
          repId: repData.id,
          reviewerId: userData.id,
          detail: comment,
          anonymous: anonymous,
          companyId: companyId || "",
          companyName: this.state.selectedCompanyName || "",
          userRatings: formattedRatings,
          thumbsUp: thumbsUp,
          id: rateId || null,
          repType: repData.repType,
          customRatings: formattedCustomeRatings,
        };
        this.props.handleRateRep(obj, true);
      } else {
        this.props.handleRateRep(
          {
            repId: repData.id,
            detail: comment,
            anonymous: anonymous,
            companyId: selectedCompany.companyId || "",
            companyName: this.state.selectedCompanyName || "",
            userRatings: formattedRatings,
            thumbsUp: thumbsUp,
            id: rateId || null,
            customerEmail: email,
            customerName: name,
            repType: repData.repType,
            customRatings: formattedCustomeRatings,
          },
          false
        );
      }
    }
  };

  hasChanged() {
    const { rateId } = this.props;
    if (rateId) {
      if (
        !isEqual(
          this.state.initialState.selectedCompany,
          this.state.selectedCompany
        ) ||
        this.state.initialState.selectedCompanyName !==
          this.state.selectedCompanyName ||
        !isEqual(this.state.initialState.ratings, this.state.ratings) ||
        !isEqual(
          this.state.initialState.customRatings,
          this.state.customeRating
        ) ||
        this.state.initialState.thumbsUp !== this.state.thumbsUp ||
        this.state.initialState.comment !== this.state.comment ||
        this.state.initialState.anonymous !== this.state.anonymous
      ) {
        return true;
      }
    }

    return false;
  }
  customRatingAttributes = () => {
    const { customAttr } = this.props;
    try {
      if (customAttr) {
        {
          let finalArr = customAttr.map((rate) => {
            if (rate.status === true) {
              const ratingAttr = DynamicCapital(rate.attributeName);
              let rateValue = "";
              rateValue = this.state.customeRating[ratingAttr.key];
              return (
                <RateRow key={ratingAttr.underScoreUpperCase} transform={false}>
                  <Body2>
                    {ratingAttr.data}
                    <BodyDescripton>{rate.description}</BodyDescripton>
                  </Body2>
                  <RatingStars
                    name={rate.attributeName}
                    rating={rateValue}
                    big={true}
                    onChange={this.handleCustomeRateChange}
                  />
                </RateRow>
              );
            }
          });
          return finalArr;
        }
      }
    } catch (e) {}
  };

  ratingAttributes = () => {
    const { userData, repData, rateId, customAttr } = this.props;
    try {
      if (repData && repData.ratingAttributes && customAttr) {
        {
          let attrArry = repData.ratingAttributes;
          let finalArr = attrArry.map((rate) => {
            const ratingAttr = DynamicRating(rate);
            let rateValue = "";
            if (ratingAttr.key) {
              rateValue = this.state.ratings[ratingAttr.key];
            } else {
              rateValue = null;
            }
            return (
              <RateRow key={ratingAttr.key}>
                <Body2>{ratingAttr.value}</Body2>
                <RatingStars
                  name={ratingAttr.key}
                  rating={rateValue}
                  big={true}
                  onChange={this.handleRateChange}
                />
              </RateRow>
            );
          });

          let _customAttr = [];
          let tmpArr = [];
          if (!repData.unclaimed) {
            _customAttr = customAttr.map((rate) => {
              const ratingAttr = DynamicCapital(rate.attributeName);
              if (rate.status === true && !tmpArr.includes(ratingAttr.data)) {
                let rateValue = "";
                rateValue = this.state.customeRating[ratingAttr.key];
                tmpArr.push(ratingAttr.data);
                if (this.props.repData.currentCompanyConnected == true) {
                  return (
                    <RateRow
                      key={ratingAttr.underScoreUpperCase}
                      transform={false}
                    >
                      <Body2>
                        {ratingAttr.data}
                        <BodyDescripton>{rate.description}</BodyDescripton>
                      </Body2>
                      <RatingStars
                        name={rate.attributeName}
                        rating={rateValue}
                        big={true}
                        onChange={this.handleCustomeRateChange}
                      />
                    </RateRow>
                  );
                }
              }
            });
          }

          const temp = (
            <RateRow last key={this.props.repData.id}>
              <Body2>How was your overall experience?</Body2>
              <LikeDislikeOption
                name="experience"
                selected={this.state.thumbsUp}
                onChange={this.handleExperienceChange}
              />
            </RateRow>
          );
          return [...finalArr, temp, ..._customAttr];
        }
      }
    } catch (e) {}
  };

  render() {
    const { repData, rateId, userData } = this.props;
    const repFullName = `${repData.firstName} ${repData.lastName}`
      .trim()
      .replace("null", "");
    const isEditable = !!rateId;
    return (
      <Wrapper>
        {this.props.isFetching && <Loader overlayed size="medium" />}
        {this.props.isFetchingRateRep && <Loader overlayed size="medium" />}

        {isEditable ? (
          <Header3>Edit Your Rating of {repFullName}</Header3>
        ) : (
          <Header3>Rate {repFullName}</Header3>
        )}

        <DropdownContainer>
          <p>Company</p>
          <DropdownAddOption
            key="sort"
            isCompanyAdd
            addPlaceHolder="Add a Company"
            placeholder="No company found"
            options={this.props.repCompanies.slice(0)} // HACK BECAUSE THE COMPONENT CHANGE THE ORIGINAL ARRAY
            value={
              this.state.selectedCompanyName.toLocaleLowerCase() ==
              "not available"
                ? "Please select"
                : this.state.selectedCompanyName
            }
            onClick={this.handleCompanyChange}
            width="265px"
            left="0"
            repCompanyChangeNew={true}
          />
        </DropdownContainer>

        <LegendBlue className="m-b-20">
          Help improve the experience of you, and your fellow customers, by
          rating your rep in the most important skill areas:
        </LegendBlue>

        <RatingTypes>
          <Body5 textColor={reporaColor.warmGrey}>Poor</Body5>
          <Body5 className="m-l-20" textColor={reporaColor.warmGrey}>
            Average
          </Body5>
          <Body5 className="m-l-20" textColor={reporaColor.warmGrey}>
            Great
          </Body5>
        </RatingTypes>

        {this.ratingAttributes()}

        <LegendBlue className="m-t-20 m-b-20">
          Write a comment (optional):
        </LegendBlue>

        <TextArea
          className="m-b-20"
          maxLength="1000"
          rows="7"
          onChange={this.handleCommentChange}
          value={this.state.comment}
          name="comment"
          counter
        />

        {userData ? (
          <div className="d-flex align-items-center m-b-20">
            <CheckComponent
              textSize="14px"
              width="auto"
              textColor={reporaColor.warmGrey}
            >
              <input
                id="anonymousRating"
                type="checkbox"
                value="value1"
                checked={this.state.anonymous}
                onChange={this.handleAnonymousChange}
              />
              <label htmlFor="anonymousRating">Make my rating anonymous</label>
            </CheckComponent>
            {repData?.currentCompanyPrivate ? (
              <div>
                <InformationSpan data-tip data-for="disputeTip">
                  <img
                    style={{ marginTop: "5px" }}
                    src="/assets/global/help.svg"
                    alt=""
                  />
                </InformationSpan>
                <div>
                  <ReactTooltip id="disputeTip" data-effect="solid">
                    <span>
                      An identifiable rating will show your full name to the rep
                      and his/her company.
                      <br />
                      Anonymous ratings will hide your name, and in order to
                      obfuscate the date,
                      <br />
                      it will not post immediately and when it posts the rating
                      will be timestamped
                      <br /> with the month and year not the exact date.
                      <br />
                      <br />
                      Note: identifiable ratings are given higher weighting than
                      anonymous ratings.
                    </span>
                  </ReactTooltip>
                </div>
              </div>
            ) : (
              <HelpTooltip data="A public rating will show your first name and last initial to fellow customers, while reps and companies will see your full name. Anonymous ratings will hide your name, will not be posted immediately, and the rating date will only show the month." />
            )}
          </div>
        ) : (
          <div className="form-control">
            <FormWrapper maxWidth="500px">
              <CollapseRow>
                <RadioSize>
                  <RadioRate>
                    <label>
                      <input
                        type="radio"
                        onChange={this.anonymousVal}
                        checked={!this.state.anonymous}
                        name="anonymous"
                        value="non-anonymous"
                      />
                      <span> Identify me</span>
                    </label>
                  </RadioRate>
                </RadioSize>
                <div>
                  <AnonymousEle disabled={this.state.grayOut}>
                    <label>Full Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      onChange={this.handlenameChange}
                    />
                  </AnonymousEle>
                  <InvalidSpan>
                    {this.state.errorName ? this.state.errorName : ""}
                  </InvalidSpan>
                </div>
                <div>
                  <AnonymousEle disabled={this.state.grayOut}>
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={this.handlenameChange}
                    />
                  </AnonymousEle>
                  <InvalidSpan>
                    {this.state.varifiedEmail
                      ? ""
                      : this.state.verifiedEmailmsg}
                  </InvalidSpan>
                </div>
              </CollapseRow>
              <RadioSize>
                <RadioRate>
                  <label>
                    <input
                      type="radio"
                      name="anonymous"
                      style={{ border: "1px solid #E8E8E8 !important" }}
                      onChange={this.anonymousVal}
                      value="anonymous"
                    />
                    <span>
                      {" "}
                      Make my rating anonymous
                      <InformationSpan
                        position="absolutePosition"
                        data-tip
                        data-for="disputeTip"
                      >
                        <img src="/assets/global/help.svg" alt="" />
                      </InformationSpan>
                      <div>
                        <ReactTooltip id="disputeTip" data-effect="solid">
                          {repData?.currentCompanyPrivate ? (
                            <span>
                              An identifiable rating will show your full name to
                              the rep and his/her company.
                              <br />
                              Anonymous ratings will hide your name, and in
                              order to obfuscate the date,
                              <br />
                              it will not post immediately and when it posts the
                              rating will be timestamped
                              <br /> with the month and year not the exact date.
                              <br />
                              <br />
                              Note: identifiable ratings are given higher
                              weighting than anonymous ratings.
                            </span>
                          ) : (
                            <span>
                              An identifiable rating will show your first name
                              and last initial to fellow
                              <br /> customers, while the rep and his/her
                              company will see your full name.
                              <br /> Anonymous ratings will hide your name, will
                              not be posted immediately,
                              <br /> and the rating date will only show the
                              month not the day. <br />
                              <br />
                              Note: identifiable ratings are given higher
                              weighting than anonymous <br />
                              ratings.
                            </span>
                          )}
                        </ReactTooltip>
                      </div>
                    </span>
                  </label>
                </RadioRate>
              </RadioSize>{" "}
              <br />
            </FormWrapper>
          </div>
        )}

        {isEditable ? (
          <ButtonsContainer>
            <Link
              to={`/viewrepcustomer?id=${repData.id}`}
              className="text-decoration-none"
            >
              <Button color="white">Cancel</Button>
            </Link>
            <Button
              disabled={!this.isFormValid() || !this.hasChanged()}
              onClick={this.handleFormSubmit}
            >
              Submit and Replace Rating
            </Button>
          </ButtonsContainer>
        ) : (
          <Button
            disabled={
              !this.isFormValid() ||
              this.state.selectedCompanyName.toLocaleLowerCase() ==
                "not available"
            }
            onClick={this.handleFormSubmit}
          >
            Submit Rating
          </Button>
        )}
      </Wrapper>
    );
  }
}

export default RatingsCard;
