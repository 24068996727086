import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Header3, Body4, reporaColor, reporaDevice, ReporaButton } from "../../../components/global/commonStyles";
import { HeaderWrapper, FormWrapper } from "../../SignUp/components/CommonStyles";

export const Wrapper = styled.div`
  max-width: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 80px auto 20px;
  position: relative;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 #00000033;
  padding: 10px;

  @media ${reporaDevice.tablet} {
    max-width: 502px;
    padding: 35px;
  }
`;

const MessageWrapper = styled(FormWrapper)`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 10px;
  }
`;

const ImageWrapper = styled.div`
  max-width: 450px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;

  img:first-child {
    margin-right: 10px;
    width: 80px;
    height: 80px;
  }
`;

const Wrapper2 = styled.div`
  padding-top: 80px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: center;
  background: white;
`;

const Card = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 8px 0 ${reporaColor.black08};
  border-radius: 2px;
  padding: 35px;
  max-width: 502px;

  @media ${reporaDevice.xs} {
    width: 100%;
  }
`;

const RateConfirmation = props => {
  const payload = props.responseMessage.payload
  const success = props.responseMessage.statusCode === 200

  return (
    <Wrapper2>
      <Card>
        <HeaderWrapper>
          <Header3 textAlign="center">{success ? "Thank You!" : "Rating Up Error!"}</Header3>
        </HeaderWrapper>
        <MessageWrapper>
          {success ? (
            <ImageWrapper>
              <img src="assets/global/userIcon.svg" alt="" />
              {/* <img src="/assets/global/check-green.svg" alt="" /> */}
            </ImageWrapper>
          ) : null}
          <Body4 textAlign="center" textColor={`${reporaColor.brightSkyBlue}`}>
            {success ? `Your rating for ${payload.firstName} ${payload.lastName} was submitted.` : props.errorMessage}
          </Body4>
          {success ? (
          <Body4 textAlign="center" textColor={`${reporaColor.warmGrey}`}>
            By providing feedback to professional contacts you interact 
            with, you help improve them and your relationship with their 
            firm, you make the time you spend with them more productive,
            and you help the overall customer community. <br/><br/>
            <NavLink style={{color: `${reporaColor.brightSkyBlue}`}} target='_blank' to='/'>
            Learn
            </NavLink> how Repora can do the same for you. 
            </Body4>
          ) : null}
        </MessageWrapper>
        <ReporaButton onClick={props.handleRateDone}>{success ? "Done" : "Go Back"}</ReporaButton>
      </Card>
    </Wrapper2>
  )
}

export default RateConfirmation;
