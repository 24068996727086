import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { reporaDevice, ReporaButton, reporaColor } from "../../components/global/commonStyles";
import constants from "../../constants";
import { getCompanyPublicInfo } from "../../actions/companyActions";

const Wrapper = styled.div`
  max-width: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 80px auto 20px;
  position: relative;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 #00000033;
  padding: 40px;

  @media ${reporaDevice.tablet} {
    max-width: 502px;
  }
`;

const Header = styled.h1`
  font-size: 30px;
  margin-bottom: 30px;
`;

const SubHeader = styled.h2`
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  color: ${reporaColor.brightSkyBlue};
`;

const CompanyLogo = styled.img`
  height: 70px;
`;

class InvitationSucceeded extends React.Component {

  componentDidMount() {
    if (this.props.userData && this.props.userData.companyId) {
      this.props.handleGetCompanyPublicInfo(this.props.userData.companyId);
    }
  }

  handleOkButtonClick = () => {
    const role = this.props.userData && this.props.userData.userRole;

    if (role) {
      this.props.history.push(`/${constants.ROLES_REDIRECTION[role]}`);
    }
  }

  render() {
    const { userData, companyPublicInfo } = this.props;

    const userFirstname = (userData && userData.firstName) || "";
    const companyName = (companyPublicInfo && companyPublicInfo.name) || "";
    const companyLogo = (companyPublicInfo && companyPublicInfo.logo) || "";

    return (
      <Wrapper>
        <Header>Hi, {userFirstname}</Header>
        {companyLogo && <CompanyLogo src={companyLogo}/>}
        {companyName ?
          <SubHeader>Your RepScore account has been asociated with {companyName}</SubHeader>
          :
          <SubHeader>You"re now connected</SubHeader>
        }
        <ReporaButton className="m-t-30" onClick={this.handleOkButtonClick} full>Okay</ReporaButton>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.auth.userData,
  companyPublicInfo: state.company.companyPublicInfo
});

const mapDispatchToProps = dispatch => ({
  handleGetCompanyPublicInfo(companyId) {
    dispatch(getCompanyPublicInfo(companyId));
  }
});

export const Unwrapped = InvitationSucceeded;
export default connect(mapStateToProps, mapDispatchToProps)(InvitationSucceeded);