import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

import { reporaColor } from "../../../components/global/commonStyles";

const Wrapper = styled.div`
  background: lime;
  position: relative;
`;

const TriggerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 3px;
  background-color: ${reporaColor.grey91};
  cursor: pointer;
  position: relative;
`;

const DropDownOptions = styled.div`
  position: absolute;
  z-index: 999;
  min-width: 164px;
  border-radius: 3px;
  background-color: #e8e8e8;
`;

class UserDropDown extends React.Component {
  state = {
    opened: false,
    styles: {}
  }

  constructor(props) {
    super(props);
    this.triggerButton = React.createRef();
    this.dropdownOptions = React.createRef();

    window.addEventListener("resize", () => {
      if (this.state.opened) {
        this.setState({
          opened: false
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const triggerElement = this.triggerButton ? this.triggerButton.current : null;
    const dropdownElement = this.dropdownOptions ? this.dropdownOptions.current : null;

    if (dropdownElement && (this.state.opened !== prevState.opened)) {
      const triggerOffset = triggerElement.getBoundingClientRect();
      const dropdownOffset = dropdownElement.getBoundingClientRect();

      const triggerRight = triggerOffset.right;
      const dropdownWidth = dropdownOffset.width;

      const top = triggerOffset.height + 10;
      let right = 0;

      if (triggerRight - dropdownWidth < 0) {
        right = right + (triggerRight - dropdownWidth) - 10;
      }

      this.setState({
        styles: {
          top,
          right
        }
      });
    }
  }

  handleOpenMenu = () => {
    this.setState({
      opened: true
    });
  }

  handleCloseMenu = () => {
    this.setState({
      opened: false
    });
  }

  render() {
    return (
      <Wrapper>
        {!this.state.opened &&
          <TriggerButton onClick={this.handleOpenMenu} innerRef={this.triggerButton}>
            <FontAwesomeIcon icon={faGear} />
          </TriggerButton>
        }

        {this.state.opened &&
          <TriggerButton onClick={this.handleCloseMenu} innerRef={this.triggerButton}>
            <FontAwesomeIcon icon={faGear} />
          </TriggerButton>
        }

        {this.state.opened &&
          <OutsideClickHandler onOutsideClick={this.handleCloseMenu}>
            <DropDownOptions
              onClick={this.handleCloseMenu}
              style={this.state.styles}
              innerRef={this.dropdownOptions}>
              {this.props.children}
            </DropDownOptions>
          </OutsideClickHandler>
        }
      </Wrapper>
    );
  }
};

export default UserDropDown;