import authdAxios from "../modules/Authentication/utils/authdAxios";
import config from "../config";
import constants from "../constants";
import { get } from "lodash";

const ManageUserService = () => {
  function getUsers(role, companyId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/users/list-users`,
          {
            "role": role,
            "companyId": companyId
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          }
        )
        .then(response => {
          resolve(response.data.payload);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        })
    })
  }

  function addCompanyUser(user, companyId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/auth/sign-up`,
          {
            ...user,
            companyId
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data.payload);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function editCompanyUser(user) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/users/update-user-information `,
          user,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data.payload);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function resetPasswordCompanyUser(email) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/auth/forgot-password`,
          { email },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data.payload);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function deleteUser(email) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/users/disable`,
          { email },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    })
  }



  function checkUserReviews(companyUserId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/reps/check-company-user-reviews`,
          companyUserId,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    })
  }

  function disconnectCompanyUser(companyUserId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/manage-reps/disconnect-company-user`,
          companyUserId,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    })
  }

  return {
    addCompanyUser,
    editCompanyUser,
    resetPasswordCompanyUser,
    getUsers,
    deleteUser,
    checkUserReviews,
    disconnectCompanyUser
  };
}

export default ManageUserService();