import React from "react";
import routes from "./routes";
import PrivateRoute from "./components/PrivateRoute";

const PrivateRoutes = ({ isAuthenticated, isFetching, requestedLogout, userData }) => {
  return Object.keys(routes).map(routeKey => {
    const { path, loader, invalidRoles, exact, strict } = routes[routeKey];

    window.scrollTo(0, 0)
    return (
      <PrivateRoute
        exact={exact || true}
        strict={strict || false}
        key={routeKey}
        pathName={routeKey}
        path={`/${path}`}
        isAuthenticated={isAuthenticated}
        isFetching={isFetching}
        componentLoader={loader}
        isValidPathForUserRole={invalidRoles.indexOf(userData.userRole) < 0}
        requestedLogout={requestedLogout}
      />
    );
  });
};

export default PrivateRoutes;