import authdAxios from "../modules/Authentication/utils/authdAxios";
import config from "../config";
import constants from "../constants";
import { get } from "lodash";

const accountService = () => {

  function validateRecaptcha(token) {
    const reCaptchaSecretKey = config.RE_CAPTCHA_KEYS.secretKey || "";
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/auth/verify-site`,
          {
            "secret": reCaptchaSecretKey,
            "token": token
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.data);
       });
    });
  }

  function signUp(
    email, firstName, lastName,
    password, companyName, companyId,
    country, phoneNumber, zipCode,
    state, city, jobTitle, industry, userRole) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/auth/sign-up`,
          {
            "email": email,
            "firstName": firstName,
            "lastName": lastName,
            "password": password,
            "companyName": companyName,
            "companyId": companyId,
            "country": country,
            "phoneNumber": phoneNumber,
            "zipCode": zipCode,
            "state": state,
            "city": city,
            "jobTitle": jobTitle,
            "industry": industry,
            "userRole": userRole
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.data);
       });
    });
  }

  function addCompany(companyName, enrolled, industry, country, city, state, zipCode, phoneNumber, linkedinUrl, minimumRatings, territory) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company/add`,
          {
            "name": companyName,
            "industry": industry,
            "linkedinUrl": linkedinUrl || null,
            "minimumRatings": minimumRatings || 1,
            "enrolled": enrolled,
            "physicalAddress": {
              "country": country,
              "city": city,
              "state": state,
              "zipCode": zipCode,
              "phoneNumber": phoneNumber,
              "territory": territory || null
            }
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function socialSignUp(socialData) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/social-media/create-user`,
          socialData,
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function getSocialUrl(providerName, userRole, callback) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/social-media/get-url`,
          {
            "provider": providerName,
            "role": userRole,
            callback
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function verifyAccount(userId, code, role, provider, callback, accessToken, password) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/social-media/verify-account `,
          {
            "userId": userId,
            "code": code,
            "role": role,
            "provider": provider,
            "callback": callback,
            "accessToken": accessToken,
            "password": password
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function updateFromLinkedin(code, role, provider, callback) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/social-media/update-from-linkedin`,
          {
            "code": code,
            "role": role,
            "provider": provider,
            "callback": callback
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function getSocialData(socialCode, providerName, userRole, callback) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/social-media/get-user-information`,
          {
            "code": socialCode,
            "provider": providerName,
            "role": userRole,
            callback
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function forgotPassword(email) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/auth/forgot-password`,
          { email },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data.payload);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function resetPassword(email, code, password) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/auth/confirm-code`,
          {
            username: email,
            confirmCode: code,
            password
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data.payload);
        })
        .catch(error => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function signUpCompany(companyData) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/auth/company/sign-up`,
          {
            administrator: companyData.administrator,
            company: companyData.company
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json"
            }
          })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          resolve(error.data);
        });
    });
  }

  return {
    validateRecaptcha,
    signUp,
    addCompany,
    socialSignUp,
    getSocialUrl,
    getSocialData,
    forgotPassword,
    resetPassword,
    verifyAccount,
    updateFromLinkedin,
    signUpCompany
  };
};

export default accountService();
