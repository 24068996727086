import {
  LOG_IN_REQUESTED,
  LOG_IN_SUCCEEDED,
  LOG_IN_FAILED,
  CLEAR_LOG_IN_MESSAGE,


  LOG_IN_ADMIN_REQUESTED,
  LOG_IN_ADMIN_SUCCEEDED,
  LOG_IN_ADMIN_FAILED,
  CLEAR_LOG_IN_ADMIN_MESSAGE,

  LOGOUT_SUCCEEDED,
  SOCIAL_LOG_IN_REQUESTED,
  SOCIAL_LOG_IN_SUCCEEDED,
  SOCIAL_LOG_IN_FAILED,
  CHANGE_PASSWORD_SUCCEEDED,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_RESET,
  COMPANY_UPDATE_PASSWORD_VERIFICATION_SUCCEEDED,
  COMPANY_UPDATE_PASSWORD_VERIFICATION_FAILED,
  ADMIN_INFO_PASSWORD_VERIFICATION_SUCCEEDED,
  ADMIN_INFO_PASSWORD_VERIFICATION_FAILED,
  PASSWORD_VERIFICATION_RESET,
  CHANGE_PASSWORD_REQUEST,
  UPDATE_ADMIN_INFO_REQUEST,
  UPDATE_ADMIN_INFO_SUCCESS,
  UPDATE_ADMIN_INFO_FAILURE,
  UPDATE_ADMIN_INFO_RESET,
  CLEAR_LOGIN_ERROR_MESSAGES,
  CONFIRM_EMAIL_RESET,
  CONFIRM_EMAIL_SUCCEEDED,
  CONFIRM_EMAIL_FAILED,
  CONFIRM_EMAIL_REQUEST,
  SOCIAL_URL_SUCCEEDED,

  UPDATE_USER_INFO_REQUESTED,

  SIGNIN_AND_CONFIRM_ACCOUNT_FAILED,
  SIGNIN_AND_CONFIRM_ACCOUNT_REQUESTED,
  SIGNIN_AND_CONFIRM_ACCOUNT_SUCCEEDED,
  SIGNIN_AND_RECONFIRM_ACCOUNT_SUCCEEDED

} from "../actions/authActions";

import {
  UPDATE_REP_PRODUCT_SPECIALITY_SUCCEEDED,
  UPDATE_REP_EMAIL_SUCCEEDED,
  UPDATE_REP_FROM_LINKEDIN_SUCCEEDED,
  UPDATE_COMPANY_USER_INFO_SUCCESS,
} from "../actions/repActions";

import AuthService from "../services/AuthService";

const initialState = {
  userData: AuthService.getUserInfo(),
  isAuthenticated: AuthService.isLoggedIn(),
  loginErrorMessage: null,
  isFetching: false,
  isFetchingPassword: false,
  passwordChangeLoading: false,
  passwordChangeSuccess: false,
  passwordChangeError: "",
  passwordCompanyUpdateVerified: false,
  passwordCompanyUpdateError: "",
  passwordAdminInfoVerified: false,
  passwordAdminInfoError: "",
  updateAdminInfoLoading: false,
  updateAdminInfoSuccess: false,
  updateAdminInfoError: "",
  socialUrl: {},
  success: "",
  requestedLogout: false,

  signinAndConfirmAccountLoading: false,
  signinAndConfirmAccountSuccess: false,
  signinAndConfirmAccountError: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN_REQUESTED:
      return ({
        ...state,
        isFetching: true
      });
    case LOG_IN_SUCCEEDED:
      return ({
        ...state,
        isFetching: false,
        loginErrorMessage: null,
        isAuthenticated: true,
        userData: AuthService.getUserInfo(),
        requestedLogout: false
      });
    case LOG_IN_FAILED:
      return ({
        ...state,
        isFetching: false,
        loginErrorMessage: action.payload
      });
    case CLEAR_LOG_IN_MESSAGE:
      return ({
        ...state,
        loginErrorMessage: null
      });
    case LOGOUT_SUCCEEDED:
      return ({
        ...state,
        requestedLogout: true,
        isAuthenticated: false,
        userData: {}
      });
    case SOCIAL_LOG_IN_REQUESTED:
      return ({
        ...state,
        isFetching: true
      });
    case SOCIAL_LOG_IN_SUCCEEDED:
      return ({
        ...state,
        isFetching: false,
        loginErrorMessage: null,
        isAuthenticated: true,
        userData: AuthService.getUserInfo()
      });
    case SOCIAL_LOG_IN_FAILED:
      return ({
        ...state,
        isFetching: false,
        loginErrorMessage: action.payload
      });

    case COMPANY_UPDATE_PASSWORD_VERIFICATION_SUCCEEDED:
      return ({
        ...state,
        passwordCompanyUpdateVerified: true,
        passwordCompanyUpdateError: "",
        passwordAdminInfoVerified: false,
        passwordAdminInfoError: ""
      });
    case COMPANY_UPDATE_PASSWORD_VERIFICATION_FAILED:
      return ({
        ...state,
        passwordCompanyUpdateVerified: false,
        passwordCompanyUpdateError: action.payload,
        passwordAdminInfoVerified: false,
        passwordAdminInfoError: ""
      });
    case ADMIN_INFO_PASSWORD_VERIFICATION_SUCCEEDED:
      return ({
        ...state,
        passwordCompanyUpdateVerified: false,
        passwordCompanyUpdateError: "",
        passwordAdminInfoVerified: true,
        passwordAdminInfoError: ""
      });
    case ADMIN_INFO_PASSWORD_VERIFICATION_FAILED:
      return ({
        ...state,
        passwordCompanyUpdateVerified: false,
        passwordCompanyUpdateError: "",
        passwordAdminInfoVerified: false,
        passwordAdminInfoError: action.payload
      });
    case PASSWORD_VERIFICATION_RESET:
      return ({
        ...state,
        passwordCompanyUpdateVerified: false,
        passwordCompanyUpdateError: "",
        passwordAdminInfoVerified: false,
        passwordAdminInfoError: ""
      });

    case UPDATE_REP_PRODUCT_SPECIALITY_SUCCEEDED: {
      return ({
        ...state,
        userData: AuthService.getUserInfo()
      })
    }

    case UPDATE_COMPANY_USER_INFO_SUCCESS: {
      return ({
        ...state,
        userData: AuthService.getUserInfo()
      });
    }
    case SOCIAL_URL_SUCCEEDED: {
      return Object.assign({}, state, { socialUrl: action.payload })
    }

    case UPDATE_REP_FROM_LINKEDIN_SUCCEEDED: {
      return ({
        ...state,
        userData: AuthService.getUserInfo()
      })
    }

    case UPDATE_REP_EMAIL_SUCCEEDED: {
      return ({
        ...state,
        userData: AuthService.getUserInfo()
      })
    }


    case CHANGE_PASSWORD_RESET:
      return ({
        ...state,
        passwordChangeLoading: false,
        passwordChangeSuccess: false,
        passwordChangeError: "",
      });
    case CHANGE_PASSWORD_SUCCEEDED:
      return ({
        ...state,
        passwordChangeLoading: false,
        passwordChangeSuccess: true,
        passwordChangeError: "",
      });
    case CHANGE_PASSWORD_FAILED:
      return ({
        ...state,
        passwordChangeLoading: false,
        passwordChangeSuccess: false,
        passwordChangeError: action.payload,
      });
    case CHANGE_PASSWORD_REQUEST:
      return ({
        ...state,
        passwordChangeLoading: true,
        passwordChangeSuccess: false,
        passwordChangeError: "",
      });

    case UPDATE_ADMIN_INFO_REQUEST:
      return Object.assign({}, state, { updateAdminInfoLoading: true, updateAdminInfoSuccess: false, updateAdminInfoError: "" });
    case UPDATE_ADMIN_INFO_SUCCESS:
      return Object.assign({}, state, { updateAdminInfoLoading: false, updateAdminInfoSuccess: true, updateAdminInfoError: "", userData: { ...state.info, ...action.payload } }); //missing
    case UPDATE_ADMIN_INFO_FAILURE:
      return Object.assign({}, state, { updateAdminInfoLoading: false, updateAdminInfoSuccess: false, updateAdminInfoError: action?.payload });
    case UPDATE_ADMIN_INFO_RESET:
      return Object.assign({}, state, { updateAdminInfoLoading: false, updateAdminInfoSuccess: false, updateAdminInfoError: "" });

    case CLEAR_LOGIN_ERROR_MESSAGES:
      return ({
        ...state,
        loginErrorMessage: ""
      });

    case CONFIRM_EMAIL_RESET:
      return ({
        ...state,
        confirmEmailLoading: false,
        confirmEmailSuccess: false,
        confirmEmailError: "",
      });
    case CONFIRM_EMAIL_SUCCEEDED:
      return ({
        ...state,
        confirmEmailLoading: false,
        confirmEmailSuccess: true,
        confirmEmailError: "",
      });
    case CONFIRM_EMAIL_FAILED:
      return ({
        ...state,
        confirmEmailLoading: false,
        confirmEmailSuccess: false,
        confirmEmailError: action.payload,
      });
    case CONFIRM_EMAIL_REQUEST:
      return ({
        ...state,
        confirmEmailLoading: true,
        confirmEmailSuccess: false,
        confirmEmailError: "",
      });

    case UPDATE_USER_INFO_REQUESTED:
      return ({
        ...state,
        userData: AuthService.getUserInfo()
      });

    case SIGNIN_AND_CONFIRM_ACCOUNT_REQUESTED:
      return ({
        ...state,
        signinAndConfirmAccountLoading: true,
        signinAndConfirmAccountError: "",
        success: ""
      })
    case SIGNIN_AND_CONFIRM_ACCOUNT_SUCCEEDED:
      return ({
        ...state,
        signinAndConfirmAccountLoading: false,
        signinAndConfirmAccountError: "",
        success: ""
      })
    case SIGNIN_AND_RECONFIRM_ACCOUNT_SUCCEEDED:
      return ({
        ...state,
        signinAndConfirmAccountLoading: false,
        signinAndConfirmAccountError: "",
        success: "Verification Link sent to you email."
      })
    case SIGNIN_AND_CONFIRM_ACCOUNT_FAILED:
      return ({
        ...state,
        signinAndConfirmAccountLoading: false,
        signinAndConfirmAccountError: action.payload,
        success: ""
      })



    case LOG_IN_ADMIN_REQUESTED:
      return ({
        ...state,
        isFetching: true
      });
    case LOG_IN_ADMIN_SUCCEEDED:
      return ({
        ...state,
        isFetching: false,
        loginErrorMessage: null,
        isAuthenticated: true,
        userData: AuthService.getUserInfo(),
        requestedLogout: false
      });
    case LOG_IN_ADMIN_FAILED:
      return ({
        ...state,
        isFetching: false,
        loginErrorMessage: action.payload
      });
    case CLEAR_LOG_IN_ADMIN_MESSAGE:
      return ({
        ...state,
        loginErrorMessage: null
      });

    default:
      return ({ ...state, isAuthenticated: AuthService.isLoggedIn() });
  }
};

export default authReducer;
