import React from "react";
import { Redirect } from "react-router";

import View from "./components/View";

class FeaturesCompanies extends React.Component {
  state = {
    redirectLink: ""
  };

  componentDidMount() {
    document.getElementById("contentWrapper").scrollTop = 0;
  }

  handleFooterRedirect = (link) => {
    document.getElementById("contentWrapper").scrollTop = 0;
    this.setState({ redirectLink: link });
  }

  render() {
    if (this.state.redirectLink !== "") {
      return <Redirect push={true} to={this.state.redirectLink} />
    }

    return (
      <View {...this.props} {...this.state} handleFooterRedirect={this.handleFooterRedirect} />
    );
  }
}

export default FeaturesCompanies;
