import ManageRepService from "../services/ManageRepsService";
import { getCompanies } from "./companyActions";

/* Action Constants */
export const GET_ASSIGNED_ROLES_REQUESTED = "GET_ASSIGNED_ROLES_REQUESTED";
export const GET_ASSIGNED_ROLES_SUCCEEDED = "GET_ASSIGNED_ROLES_SUCCEEDED";
export const GET_ASSIGNED_ROLES_FAILED = "GET_ASSIGNED_ROLES_FAILED";

export const GET_MANAGE_REPS_REQUESTED = "GET_MANAGE_REPS_REQUESTED";
export const GET_MANAGE_REPS_SUCCEEDED = "GET_MANAGE_REPS_SUCCEEDED";
export const GET_MANAGE_REPS_FAILED = "GET_MANAGE_REPS_FAILED";

export const ADD_MANAGE_REP_REQUESTED = "ADD_MANAGE_REP_REQUESTED";
export const ADD_MANAGE_REP_SUCCEEDED = "ADD_MANAGE_REP_SUCCEEDED";
export const ADD_MANAGE_REP_FAILED = "ADD_MANAGE_REP_FAILED";

export const DISCONNECT_MANAGE_REP_REQUESTED =
  "DISCONNECT_MANAGE_REP_REQUESTED";
export const DISCONNECT_MANAGE_REP_SUCCEEDED =
  "DISCONNECT_MANAGE_REP_SUCCEEDED";
export const DISCONNECT_MANAGE_REP_FAILED = "DISCONNECT_MANAGE_REP_FAILED";

export const DELETE_MANAGE_REP_REQUESTED = "DELETE_MANAGE_REP_REQUESTED";
export const DELETE_MANAGE_REP_SUCCEEDED = "DELETE_MANAGE_REP_SUCCEEDED";
export const DELETE_MANAGE_REP_FAILED = "DELETE_MANAGE_REP_FAILED";

export const RESEND_INVITE_MANAGE_REP_REQUESTED =
  "RESEND_INVITE_MANAGE_REP_REQUESTED";
export const RESEND_INVITE_MANAGE_REP_SUCCEEDED =
  "RESEND_INVITE_MANAGE_REP_SUCCEEDED";
export const RESEND_INVITE_MANAGE_REP_FAILED =
  "RESEND_INVITE_MANAGE_REP_FAILED";

export const EDIT_REP_MANAGE_REP_REQUESTED = "EDIT_REP_MANAGE_REP_REQUESTED";
export const EDIT_REP_MANAGE_REP_SUCCEEDED = "EDIT_REP_MANAGE_REP_SUCCEEDED";
export const EDIT_REP_MANAGE_REP_FAILED = "EDIT_REP_MANAGE_REP_FAILED";

export const EXIST_REP_MANAGE_REP_REQUESTED = "EXIST_REP_MANAGE_REP_REQUESTED";
export const EXIST_REP_MANAGE_REP_SUCCEEDED = "EXIST_REP_MANAGE_REP_SUCCEEDED";
export const EXIST_REP_MANAGE_REP_FAILED = "EXIST_REP_MANAGE_REP_FAILED";

export const SHOW_REP_MANAGEMENT_MESSAGE = "SHOW_REP_MANAGEMENT_MESSAGE";
export const CLOSE_REP_MANAGEMENT_MESSAGE = "CLOSE_REP_MANAGEMENT_MESSAGE";

export const GET_FEEDBACK_DISPUTE_LIST_REQUESTED =
  "GET_FEEDBACK_DISPUTE_LIST_REQUESTED";
export const GET_FEEDBACK_DISPUTE_LIST_SUCCESS =
  "GET_FEEDBACK_DISPUTE_LIST_SUCCESS";
export const GET_FEEDBACK_DISPUTE_LIST_FAILED =
  "GET_FEEDBACK_DISPUTE_LIST_FAILED";

export const UPDATE_FEEDBACK_DISPUTE_LIST_REQUESTED =
  "UPDATE_FEEDBACK_DISPUTE_LIST_REQUESTED";
export const UPDATE_FEEDBACK_DISPUTE_LIST_SUCCESS =
  "UPDATE_FEEDBACK_DISPUTE_LIST_SUCCESS";
export const UPDATE_FEEDBACK_DISPUTE_LIST_FAILED =
  "UPDATE_FEEDBACK_DISPUTE_LIST_FAILED";

/* Action Creators */
const getAssignedRolesRequested = () => ({
  type: GET_ASSIGNED_ROLES_REQUESTED,
  payload: null,
});
const getAssignedRolesSucceeded = (data) => ({
  type: GET_ASSIGNED_ROLES_SUCCEEDED,
  payload: data,
});
const getAssignedRolesFailed = (data) => ({
  type: GET_ASSIGNED_ROLES_FAILED,
  payload: data,
});

const getRepsRequested = () => ({
  type: GET_MANAGE_REPS_REQUESTED,
  payload: null,
});
const getRepsSucceeded = (data) => ({
  type: GET_MANAGE_REPS_SUCCEEDED,
  payload: data,
});
const getRepsFailed = (data) => ({
  type: GET_MANAGE_REPS_FAILED,
  payload: data,
});

const addRepRequested = () => ({
  type: ADD_MANAGE_REP_REQUESTED,
  payload: null,
});
const addRepSucceeded = (data) => ({
  type: ADD_MANAGE_REP_SUCCEEDED,
  payload: data,
});
const addRepFailed = (data) => ({ type: ADD_MANAGE_REP_FAILED, payload: data });

const disconnectRepRequested = () => ({
  type: DISCONNECT_MANAGE_REP_REQUESTED,
  payload: null,
});
const disconnectRepSucceeded = (data) => ({
  type: DISCONNECT_MANAGE_REP_SUCCEEDED,
  payload: data,
});
const disconnectRepFailed = (data) => ({
  type: DISCONNECT_MANAGE_REP_FAILED,
  payload: data,
});

const deleteRepRequested = () => ({
  type: DELETE_MANAGE_REP_REQUESTED,
  payload: null,
});
const deleteRepSucceeded = (data) => ({
  type: DELETE_MANAGE_REP_SUCCEEDED,
  payload: data,
});
const deleteRepFailed = (data) => ({
  type: DELETE_MANAGE_REP_FAILED,
  payload: data,
});

const resendInviteRequested = () => ({
  type: RESEND_INVITE_MANAGE_REP_REQUESTED,
  payload: null,
});
const resendInviteSucceeded = (data) => ({
  type: RESEND_INVITE_MANAGE_REP_SUCCEEDED,
  payload: data,
});
const resendInviteFailed = (data) => ({
  type: RESEND_INVITE_MANAGE_REP_FAILED,
  payload: data,
});

const editRepRequested = () => ({
  type: EDIT_REP_MANAGE_REP_REQUESTED,
  payload: null,
});
const editRepSucceeded = (data) => ({
  type: EDIT_REP_MANAGE_REP_SUCCEEDED,
  payload: data,
});
const editRepFailed = (data) => ({
  type: EDIT_REP_MANAGE_REP_FAILED,
  payload: data,
});

const existRepRequested = () => ({
  type: EXIST_REP_MANAGE_REP_REQUESTED,
  payload: null,
});
const existRepSucceeded = (data) => ({
  type: EXIST_REP_MANAGE_REP_SUCCEEDED,
  payload: data,
});
const existRepFailed = (data) => ({
  type: EXIST_REP_MANAGE_REP_FAILED,
  payload: data,
});

const closeMessageRequested = () => ({
  type: CLOSE_REP_MANAGEMENT_MESSAGE,
  payload: null,
});
const showMessageRequested = (data) => ({
  type: SHOW_REP_MANAGEMENT_MESSAGE,
  payload: data,
});

const getFeedbackDisputeRequested = (data) => ({
  type: GET_FEEDBACK_DISPUTE_LIST_REQUESTED,
  payload: data,
});
const getFeedbackDisputeSucceeded = (data) => ({
  type: GET_FEEDBACK_DISPUTE_LIST_SUCCESS,
  payload: data,
});
const getFeedbackDisputeFailed = (data) => ({
  type: GET_FEEDBACK_DISPUTE_LIST_FAILED,
  payload: data,
});

const updateFeedbackDisputeRequested = (data) => ({
  type: UPDATE_FEEDBACK_DISPUTE_LIST_REQUESTED,
  payload: data,
});
const updateFeedbackDisputeSucceeded = (data) => ({
  type: UPDATE_FEEDBACK_DISPUTE_LIST_SUCCESS,
  payload: data,
});
const updateFeedbackDisputeFailed = (data) => ({
  type: UPDATE_FEEDBACK_DISPUTE_LIST_FAILED,
  payload: data,
});

/* Async Actions */
export function getAssignedRoles(companyId) {
  return (dispatch) => {
    dispatch(getAssignedRolesRequested());

    ManageRepService.getAssignedRoles(companyId)
      .then((response) => {
        dispatch(getAssignedRolesSucceeded(response.payload));
      })
      .catch((error) => {
        dispatch(getAssignedRolesFailed(error));
      });
  };
}

export function getReps(companyId) {
  return (dispatch) => {
    dispatch(getRepsRequested());

    ManageRepService.getReps(companyId)
      .then((response) => {
        dispatch(getRepsSucceeded(response));
      })
      .catch((error) => {
        dispatch(getRepsFailed(error));
      });
  };
}

export function getRepsRole(companyId, role) {
  return (dispatch) => {
    dispatch(getRepsRequested());

    ManageRepService.getRepsRole(companyId, role)
      .then((response) => {
        dispatch(getRepsSucceeded(response));
      })
      .catch((error) => {
        dispatch(getRepsFailed(error));
      });
  };
}

export function addRep(companyId, rep) {
  return (dispatch) => {
    dispatch(addRepRequested());

    ManageRepService.addRep(companyId, rep)
      .then((response) => {
        dispatch(addRepSucceeded(response));
        dispatch(
          showMessageRequested({
            type: "add",
            data: { firstName: rep.firstName, lastName: rep.lastName },
          })
        );
        dispatch(getReps(companyId));
      })
      .catch((error) => {
        dispatch(addRepFailed(error));
      });
  };
}

export function disconnectRep(companyId, rep) {
  return (dispatch) => {
    dispatch(disconnectRepRequested());

    ManageRepService.disconnectRep(rep.id)
      .then((response) => {
        dispatch(disconnectRepSucceeded(response));
        dispatch(getCompanies());
        dispatch(
          showMessageRequested({
            type: "disconnect",
            data: { firstName: rep.firstname, lastName: rep.lastname },
          })
        );

        dispatch(getReps(companyId));
      })
      .catch((error) => {
        dispatch(disconnectRepFailed(error));
      });
  };
}

export function deleteRep(companyId, rep) {
  return (dispatch) => {
    dispatch(deleteRepRequested());

    ManageRepService.deleteRep(rep.id)
      .then((response) => {
        dispatch(deleteRepSucceeded(response));
        dispatch(
          showMessageRequested({
            type: "delete",
            data: { firstName: rep.firstname, lastName: rep.lastname },
          })
        );
        dispatch(getReps(companyId));
      })
      .catch((error) => {
        dispatch(deleteRepFailed(error));
      });
  };
}

export function resendInvite(rep) {
  return (dispatch) => {
    dispatch(resendInviteRequested());

    ManageRepService.resendInvite(rep.id)
      .then((response) => {
        dispatch(resendInviteSucceeded(response));
        dispatch(
          showMessageRequested({
            type: "resend",
            data: {
              firstName: rep.firstname,
              lastName: rep.lastname,
              email: rep.email,
            },
          })
        );
      })
      .catch((error) => {
        dispatch(resendInviteFailed(error));
      });
  };
}

export function editRep(companyId, rep) {
  return (dispatch) => {
    dispatch(editRepRequested());

    ManageRepService.editRep(rep)
      .then((response) => {
        dispatch(editRepSucceeded(response));
        dispatch(
          showMessageRequested({
            type: "edit",
            data: {
              firstName: rep.firstname,
              lastName: rep.lastname,
              email: rep.email,
            },
          })
        );
        dispatch(getReps(companyId));
      })
      .catch((error) => {
        dispatch(editRepFailed(error));
      });
  };
}

export function existRep(email) {
  return (dispatch) => {
    dispatch(existRepRequested());

    ManageRepService.existRep(email)
      .then((response) => {
        dispatch(existRepSucceeded(response));
      })
      .catch((error) => {
        dispatch(existRepFailed(error));
      });
  };
}

export function getFeedbackDispute(data) {
  return (dispatch) => {
    dispatch(getFeedbackDisputeRequested(data));
    ManageRepService.getFeedbackDispute(data)
      .then((response) => {
        dispatch(getFeedbackDisputeSucceeded(response));
      })
      .catch((error) => {
        dispatch(getFeedbackDisputeFailed(error));
      });
  };
}

export function updateFeedbackDispute(data) {
  return (dispatch, getState) => {
    const {
      manageRep: { disputeStatusState },
    } = getState();
    dispatch(updateFeedbackDisputeRequested(data));
    ManageRepService.updateFeedbackDispute(data)
      .then((response) => {
        dispatch(updateFeedbackDisputeSucceeded(response));
        dispatch(getFeedbackDispute({ disputeStatus: disputeStatusState }));
      })
      .catch((error) => {
        dispatch(updateFeedbackDisputeFailed(error));
      });
  };
}

export function closeMessage() {
  return (dispatch) => {
    dispatch(closeMessageRequested());
  };
}
