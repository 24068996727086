import queryString from "query-string";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { withRouter } from "react-router-dom";
import constants from "../../constants";

import { resendConfirmAccount, signinAndConfirmAccount } from "../../actions/authActions";
import ConfirmationForm from "./components/ConfirmationForm";
import ConfirmRegularAccount from './components/ConfirmRegularAccount';
const DEFAULT_ROLE = "REP";

class ConfirmEmail extends React.Component {
  state = {
    code: "",
    role: DEFAULT_ROLE,
    username: "",
    isSocial: false, redirectLink: ""
  };

  componentDidMount() {
    const { code, role, username, sm } = queryString.parse(this.props.location.search);

    this.setState({
      code,
      role,
      username,
      isSocial: sm === "true" ? true : false
    });
  }

  handleSigninAndConfirmAccount = ({ confirmCode, password, role, username }) => {
    const redirectPath = constants.ROLES_REDIRECTION[role];
    const history = this.props.history;
    if (role === "REGULAR") {
      setTimeout(x => {
        this.setState({ redirectLink: "/dashboardcompany" });

      }, 2000)
    }
    this.props.handleSigninAndConfirmAccount({ confirmCode, password, role, username, history, redirectPath });
  }

  handleresendConfirmAccount = ({ confirmCode, password, role, username }) => {
    const redirectPath = constants.ROLES_REDIRECTION[role];
    const history = this.props.history;
    let social = false;
    let params = this.props.location.search.split("&");
    if (role === "REP" || role === "CUSTOMER") {
      social = false;
      for (let i = 0; i < params.length; i++) {
        if (params[i].startsWith("sm=")) {
          social = JSON.parse(params[i].split("=")[1])
        }
      }
    }

    this.props.handleresendConfirmAccount({ password, username, role, history, redirectPath, social });
  }

  render() {
    const { code, role, username, isSocial } = this.state;
    if (role === "REGULAR" && this.props.auth && this.state.redirectLink) {
      if (this.props.auth.isAuthenticated) {
        return <Redirect push={true} to={this.state.redirectLink} />
      }
    }
    if (role === "REGULAR") {
      return (
        <ConfirmRegularAccount
          {...this.props}
          code={code}
          confirmCode={code}
          email={username}
          role={role}
          username={username}
          error={this.props.error}
          onSubmit={this.handleSigninAndConfirmAccount}
          resendConfirmation={this.handleresendConfirmAccount}
        />
      )

    } else {
      return (
        <ConfirmationForm
          {...this.props}
          confirmCode={code}
          role={role}
          username={username}
          isSocial={isSocial}
          onSubmit={this.handleSigninAndConfirmAccount}
          error={this.props.error}
          loading={this.props.loading}
          resendConfirmation={this.handleresendConfirmAccount}
        />
      );
    }
  }
}

const mapStateToProps = state => ({
  loading: state.auth.signinAndConfirmAccountLoading,
  error: state.auth.signinAndConfirmAccountError,
  auth: state.auth,
  success: state.auth.success
});

const mapDispatchToProps = dispatch => ({
  handleSigninAndConfirmAccount({ confirmCode, password, role, username, history, redirectPath }) {
    dispatch(signinAndConfirmAccount({ confirmCode, password, role, username, history, redirectPath }));
  },
  handleresendConfirmAccount({ password, username, role, history, redirectPath, social }) {
    dispatch(resendConfirmAccount({ password, username, role, history, redirectPath, social }));
  }


});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail));