import moment from "moment";
import config from "../config";
import authService from "../services/AuthService";
import {
  default as RepService,
  default as repService,
} from "../services/RepService";
import { UPDATE_USER_INFO_REQUESTED } from "./authActions";
import { getUserCompanies } from "./companyActions";

/* Action Constants */
export const GET_REP_LIST_REQUEST = "GET_REP_LIST_REQUEST";
export const GET_REP_LIST_SUCCESS = "GET_REP_LIST_SUCCESS";
export const GET_REP_LIST_FAILURE = "GET_REP_LIST_FAILURE";

export const GET_REP_PROFILE_REQUEST = "GET_REP_PROFILE_REQUEST";
export const GET_REP_PROFILE_SUCCESS = "GET_REP_PROFILE_SUCCESS";
export const GET_REP_PROFILE_FAILURE = "GET_REP_PROFILE_FAILURE";

export const GET_OPTIMISED_REP_PROFILE_REQUEST =
  "GET_OPTIMISED_REP_PROFILE_REQUEST";
export const GET_OPTIMISED_REP_PROFILE_SUCCESS =
  "GET_OPTIMISED_REP_PROFILE_SUCCESS";
export const GET_OPTIMISED_REP_PROFILE_FAILURE =
  "GET_OPTIMISED_REP_PROFILE_FAILURE";
export const RATE_REP_PROFILE_REQUEST = "RATE_REP_PROFILE_REQUEST";
export const RATE_REP_PROFILE_SUCCESS = "RATE_REP_PROFILE_SUCCESS";
export const RATE_REP_PROFILE_FAILURE = "RATE_REP_PROFILE_FAILURE";
export const RATE_REP_PROFILE_RESET = "RATE_REP_PROFILE_RESET";

export const SET_REP_TRENDS_TIME_FILTER = "SET_REP_TRENDS_TIME_FILTER";
export const SET_REP_TRENDS_METRIC = "SET_REP_TRENDS_METRIC";
export const SET_REP_TRENDS_DATA = "SET_REP_TRENDS_DATA";

export const UPDATE_REP_PRODUCT_SPECIALITY_REQUESTED =
  "UPDATE_REP_PRODUCT_SPECIALITY_REQUESTED";
export const UPDATE_REP_PRODUCT_SPECIALITY_SUCCEEDED =
  "UPDATE_REP_PRODUCT_SPECIALITY_SUCCEEDED";
export const UPDATE_REP_PRODUCT_SPECIALITY_FAILED =
  "UPDATE_REP_PRODUCT_SPECIALITY_FAILED";

export const UPDATE_COMPANY_USER_INFO_REQUEST =
  "UPDATE_COMPANY_USER_INFO_REQUEST";
export const UPDATE_COMPANY_USER_INFO_SUCCESS =
  "UPDATE_COMPANY_USER_INFO_SUCCESS";
export const UPDATE_COMPANY_USER_INFO_FAILURE =
  "UPDATE_COMPANY_USER_INFO_FAILURE";

export const ADD_PREVIOUS_COMPANY_REQUESTED = "ADD_PREVIOUS_COMPANY_REQUESTED";
export const ADD_PREVIOUS_COMPANY_SUCCEEDED = "ADD_PREVIOUS_COMPANY_SUCCEEDED";
export const ADD_PREVIOUS_COMPANY_FAILED = "ADD_PREVIOUS_COMPANY_FAILED";

export const DELETE_COMPANY_USER_REQUEST = "DELETE_COMPANY_USER_INFO_REQUEST";
export const DELETE_COMPANY_USER_SUCCESS = "DELETE_COMPANY_USER_INFO_SUCCESS";
export const DELETE_COMPANY_USER_FAILURE = "DELETE_COMPANY_USER_INFO_FAILURE";

export const UPDATE_REP_FROM_LINKEDIN_REQUESTED =
  "UPDATE_REP_FROM_LINKEDIN_REQUESTED";
export const UPDATE_REP_FROM_LINKEDIN_SUCCEEDED =
  "UPDATE_REP_FROM_LINKEDIN_SUCCEEDED";
export const UPDATE_REP_FROM_LINKEDIN_FAILED =
  "UPDATE_REP_FROM_LINKEDIN_FAILED";

export const UPDATE_REP_EMAIL_REQUESTED = "UPDATE_REP_EMAIL_REQUESTED";
export const UPDATE_REP_EMAIL_SUCCEEDED = "UPDATE_REP_EMAIL_SUCCEEDED";
export const UPDATE_REP_EMAIL_FAILED = "UPDATE_REP_EMAIL_FAILED";

export const DELETE_REP_EMAIL_REQUESTED = "DELETE_REP_EMAIL_REQUESTED";
export const DELETE_REP_EMAIL_SUCCEEDED = "DELETE_REP_EMAIL_SUCCEEDED";
export const DELETE_REP_EMAIL_FAILED = "DELETE_REP_EMAIL_FAILED";

export const RATE_NEW_REP_PROFILE_REQUEST = "RATE_NEW_REP_PROFILE_REQUEST";
export const RATE_NEW_REP_PROFILE_SUCCESS = "RATE_NEW_REP_PROFILE_SUCCESS";
export const RATE_NEW_REP_PROFILE_FAILURE = "RATE_NEW_REP_PROFILE_FAILURE";
export const RATE_NEW_REP_PROFILE_GO_BACK = "RATE_NEW_REP_PROFILE_GO_BACK";

export const CLAIM_PROFILE_REQUEST = "CLAIM_PROFILE_REQUEST";
export const CLAIM_PROFILE_SUCCESS = "CLAIM_PROFILE_SUCCESS";
export const CLAIM_PROFILE_FAILURE = "CLAIM_PROFILE_FAILURE";
export const CLAIM_PROFILE_RESET = "CLAIM_PROFILE_RESET";

export const DISPUTE_REP_REQUEST = "DISPUTE_REP_REQUEST";
export const DISPUTE_REP_SUCCESS = "DISPUTE_REP_SUCCESS";
export const DISPUTE_REP_FAILURE = "DISPUTE_REP_FAILURE";

export const INVITATION_TEMPLATE_REQUEST = "INVITATION_TEMPLATE_REQUEST";
export const INVITATION_TEMPLATE_SUCCESS = "INVITATION_TEMPLATE_SUCCESS";
export const INVITATION_TEMPLATE_FAILURE = "INVITATION_TEMPLATE_FAILURE";
export const INVITATION_SET_TEMPLATE_SUCCESS =
  "INVITATION_SET_TEMPLATE_SUCCESS";

export const REP_PROFILE_VIEW_REQUEST = "REP_PROFILE_VIEW_REQUEST";
export const REP_PROFILE_VIEW_SUCCESS = "REP_PROFILE_VIEW_SUCCESS";
export const REP_PROFILE_VIEW_FAILURE = "REP_PROFILE_VIEW_FAILURE";

export const CONTACT_REP_EMAIL_RESET = "CONTACT_REP_EMAIL_RESET";
export const CONTACT_REP_EMAIL_REQUEST = "CONTACT_REP_EMAIL_REQUEST";
export const CONTACT_REP_EMAIL_SUCCESS = "CONTACT_REP_EMAIL_SUCCESS";
export const CONTACT_REP_EMAIL_FAILURE = "CONTACT_REP_EMAIL_FAILURE";

export const CONTACT_COMPANY_EMAIL_RESET = "CONTACT_COMPANY_EMAIL_RESET";
export const CONTACT_COMPANY_EMAIL_REQUEST = "CONTACT_COMPANY_EMAIL_REQUEST";
export const CONTACT_COMPANY_EMAIL_SUCCESS = "CONTACT_COMPANY_EMAIL_SUCCESS";
export const CONTACT_COMPANY_EMAIL_FAILURE = "CONTACT_COMPANY_EMAIL_FAILURE";

export const DISCONNECT_COMPANY_REQUESTED = "DISCONNECT_COMPANY_REQUESTED";
export const DISCONNECT_COMPANY_SUCCEEDED = "DISCONNECT_COMPANY_SUCCEEDED";
export const DISCONNECT_COMPANY_FAILED = "DISCONNECT_COMPANY_FAILED";

export const CLEAR_ERRORS_REQUESTED = "CLEAR_ERRORS_REQUESTED";

export const GET_REP_PROFILE_REQUESTED = "GET_REP_PROFILE_REQUESTED";
export const GET_REP_PROFILE_SUCCEEDED = "GET_REP_PROFILE_SUCCEEDED";
export const GET_REP_PROFILE_FAILED = "GET_REP_PROFILE_FAILED";

export const GET_REP_SUGGESTIONS_REQUESTED = "GET_REP_SUGGESTIONS_REQUESTED";
export const GET_REP_SUGGESTIONS_SUCCEEDED = "GET_REP_SUGGESTIONS_SUCCEEDED";
export const GET_REP_SUGGESTIONS_FAILED = "GET_REP_SUGGESTIONS_FAILED";

export const GET_RESET_REP_SEARCH_STATE = "GET_RESET_REP_SEARCH_STATE";
export const SET_REP_SEARCH_STATE = "SET_REP_SEARCH_STATE";

export const GET_COMPANY_REP_SUGGESTIONS_REQUESTED =
  "GET_COMPANY_REP_SUGGESTIONS_REQUESTED";
export const GET_COMPANY_REP_SUGGESTIONS_SUCCEEDED =
  "GET_COMPANY_REP_SUGGESTIONS_SUCCEEDED";
export const GET_COMPANY_REP_SUGGESTIONS_FAILED =
  "GET_COMPANY_REP_SUGGESTIONS_FAILED";

export const ADD_CUSTOMER_REP_FEEDBACK_REQUESTED =
  "ADD_CUSTOMER_REP_FEEDBACK_REQUESTED";
export const ADD_CUSTOMER_REP_FEEDBACK_SUCCEEDED =
  "ADD_CUSTOMER_REP_FEEDBACK_SUCCEEDED";
export const ADD_CUSTOMER_REP_FEEDBACK_FAILED =
  "ADD_CUSTOMER_REP_FEEDBACK_FAILED";

export const UPDATE_CUSTOMER_REP_FEEDBACK_REQUESTED =
  "UPDATE_CUSTOMER_REP_FEEDBACK_REQUESTED";
export const UPDATE_CUSTOMER_REP_FEEDBACK_SUCCEEDED =
  "UPDATE_CUSTOMER_REP_FEEDBACK_SUCCEEDED";
export const UPDATE_CUSTOMER_REP_FEEDBACK_FAILED =
  "UPDATE_CUSTOMER_REP_FEEDBACK_FAILED";

export const DELETE_CUSTOMER_REP_FEEDBACK_REQUESTED =
  "DELETE_CUSTOMER_REP_FEEDBACK_REQUESTED";
export const DELETE_CUSTOMER_REP_FEEDBACK_SUCCEEDED =
  "DELETE_CUSTOMER_REP_FEEDBACK_SUCCEEDED";
export const DELETE_CUSTOMER_REP_FEEDBACK_FAILED =
  "DELETE_CUSTOMER_REP_FEEDBACK_FAILED";

export const UPVOTE_CUSTOMER_REP_FEEDBACK_REQUESTED =
  "UPVOTE_CUSTOMER_REP_FEEDBACK_REQUESTED";
export const UPVOTE_CUSTOMER_REP_FEEDBACK_SUCCEEDED =
  "UPVOTE_CUSTOMER_REP_FEEDBACK_SUCCEEDED";
export const UPVOTE_CUSTOMER_REP_FEEDBACK_FAILED =
  "UPVOTE_CUSTOMER_REP_FEEDBACK_FAILED";
export const UPDATE_STATE_CUSTOMER_REP_FEEDBACK_SUCCEEDED =
  "UPDATE_STATE_CUSTOMER_REP_FEEDBACK_SUCCEEDED";
export const GET_REP_OPT_PROFILE_SUCCESS = "GET_REP_OPT_PROFILE_SUCCESS";
export const GET_REP_OPT_PROFILE_REQUEST = "GET_REP_OPT_PROFILE_REQUEST";
export const GET_REP_OPT_PROFILE_FAILURE = "GET_REP_OPT_PROFILE_FAILURE";

export const GET_IMPROVED_REP_FEEDBACK_REQUEST =
  "GET_IMPROVED_REP_FEEDBACK_REQUEST";
export const GET_IMPROVED_REP_FEEDBACK_REQUEST_SUCCESS =
  "GET_IMPROVED_REP_FEEDBACK_REQUEST_SUCCESS";
export const GET_IMPROVED_REP_FEEDBACK_REQUEST_FAILURE =
  "GET_IMPROVED_REP_FEEDBACK_REQUEST_FAILURE";

/* Action Creators */
const requestRepList = () => ({ type: GET_REP_LIST_REQUEST, payload: null });
const receiveRepList = (data) => ({
  type: GET_REP_LIST_SUCCESS,
  payload: data,
});
const receiveRepListError = (error) => ({
  type: GET_REP_LIST_FAILURE,
  payload: error,
});

const requestRepInfo = () => ({ type: GET_REP_PROFILE_REQUEST, payload: null });

const requestImprovedRepFeedbackRequest = () => ({
  type: GET_IMPROVED_REP_FEEDBACK_REQUEST,
  payload: null,
});
const receiveImprovedRepFeedbackSuccess = (data) => ({
  type: GET_IMPROVED_REP_FEEDBACK_REQUEST_SUCCESS,
  payload: data,
});
const requestImprovedRepFeedbackFailure = (data) => ({
  type: GET_IMPROVED_REP_FEEDBACK_REQUEST_FAILURE,
  payload: data,
});

const receiveRepInfo = (data) => ({
  type: GET_REP_PROFILE_SUCCESS,
  payload: data,
});
const receiveRepInfoError = (error) => ({
  type: GET_REP_PROFILE_FAILURE,
  payload: error,
});
const receiveOPTRepInfo = (data) => ({
  type: GET_REP_OPT_PROFILE_SUCCESS,
  payload: data,
});
const receiveOPTRepInfoError = () => ({
  type: GET_REP_OPT_PROFILE_FAILURE,
  payload: null,
});
const reqOPTRepInfo = () => ({
  type: GET_REP_OPT_PROFILE_REQUEST,
  payload: null,
});

const requestOptimisedRepInfo = () => ({
  type: GET_OPTIMISED_REP_PROFILE_REQUEST,
  payload: null,
});
const receiveOptimisedRepInfo = (data) => ({
  type: GET_OPTIMISED_REP_PROFILE_SUCCESS,
  payload: data,
});
const receiveOptimisedRepInfoError = (error) => ({
  type: GET_OPTIMISED_REP_PROFILE_FAILURE,
  payload: error,
});

const requestRateRep = () => ({
  type: RATE_REP_PROFILE_REQUEST,
  payload: null,
});
const receiveRateRep = (data) => ({
  type: RATE_REP_PROFILE_SUCCESS,
  payload: data,
});
const receiveRateRepError = (error) => ({
  type: RATE_REP_PROFILE_FAILURE,
  payload: error,
});
export const resetRateRep = () => ({ type: RATE_REP_PROFILE_RESET });

const setRepTrendsTimeFilter = (timeFilter) => ({
  type: SET_REP_TRENDS_TIME_FILTER,
  payload: timeFilter,
});
const setRepTrendsMetric = (metric) => ({
  type: SET_REP_TRENDS_METRIC,
  payload: metric,
});

const updateFromLinkedinRequested = () => ({
  type: UPDATE_REP_FROM_LINKEDIN_REQUESTED,
  payload: null,
});
const updateFromLinkedinSucceeded = (data) => ({
  type: UPDATE_REP_FROM_LINKEDIN_SUCCEEDED,
  payload: data,
});
const updateFromLinkedinFailed = (data) => ({
  type: UPDATE_REP_FROM_LINKEDIN_FAILED,
  payload: data,
});

const updateRepProductSpecialityRequested = () => ({
  type: UPDATE_REP_PRODUCT_SPECIALITY_REQUESTED,
  payload: null,
});
const updateRepProductSpecialitySucceeded = (data) => ({
  type: UPDATE_REP_PRODUCT_SPECIALITY_SUCCEEDED,
  payload: data,
});
const updateRepProductSpecialityFailed = (data) => ({
  type: UPDATE_REP_PRODUCT_SPECIALITY_FAILED,
  payload: data,
});

const addPreviousCompanyRequested = () => ({
  type: ADD_PREVIOUS_COMPANY_REQUESTED,
});
const addPreviousCompanySucceeded = (data) => ({
  type: ADD_PREVIOUS_COMPANY_SUCCEEDED,
  payload: data,
});
const addPreviousCompanyFailed = (data) => ({
  type: ADD_PREVIOUS_COMPANY_FAILED,
  payload: data,
});

const requestDeleteCompanyUser = () => ({ type: DELETE_COMPANY_USER_REQUEST });
const receiveDeleteCompanyUser = (data) => ({
  type: DELETE_COMPANY_USER_SUCCESS,
  payload: data,
});
const receiveDeleteCompanyUserError = (error) => ({
  type: DELETE_COMPANY_USER_FAILURE,
  payload: error,
});

const requestUpdateCompanyUserInfo = () => ({
  type: UPDATE_COMPANY_USER_INFO_REQUEST,
});
const receiveUpdateCompanyUserInfo = (data) => ({
  type: UPDATE_COMPANY_USER_INFO_SUCCESS,
  payload: data,
});
const receiveUpdateCompanyUserInfoError = (error) => ({
  type: UPDATE_COMPANY_USER_INFO_FAILURE,
  payload: error,
});

const updateRepEmailRequested = () => ({
  type: UPDATE_REP_EMAIL_REQUESTED,
  payload: null,
});
const updateRepEmailSucceeded = (data) => ({
  type: UPDATE_REP_EMAIL_SUCCEEDED,
  payload: data,
});
const updateRepEmailFailed = (data) => ({
  type: UPDATE_REP_EMAIL_FAILED,
  payload: data,
});

const requestRateNewRep = () => ({ type: RATE_NEW_REP_PROFILE_REQUEST });
const receiveRateNewRep = (data) => ({
  type: RATE_NEW_REP_PROFILE_SUCCESS,
  payload: data,
});
const receiveRateNewRepError = (error) => ({
  type: RATE_NEW_REP_PROFILE_FAILURE,
  payload: error,
});

export const rateNewRepGoBack = () => ({ type: RATE_NEW_REP_PROFILE_GO_BACK });

const deleteRepEmailRequested = () => ({
  type: DELETE_REP_EMAIL_REQUESTED,
  payload: null,
});
const deleteRepEmailSucceeded = (data) => ({
  type: DELETE_REP_EMAIL_SUCCEEDED,
  payload: data,
});
const deleteRepEmailFailed = (data) => ({
  type: DELETE_REP_EMAIL_FAILED,
  payload: data,
});

const requestClaimRep = () => ({ type: CLAIM_PROFILE_REQUEST, payload: null });
const receiveClaimRep = (data) => ({
  type: CLAIM_PROFILE_SUCCESS,
  payload: data,
});
const receiveClaimRepError = (error) => ({
  type: CLAIM_PROFILE_FAILURE,
  payload: error,
});
const resetClaimRep = () => ({ type: CLAIM_PROFILE_RESET, payload: null });

const requestDisputeRep = (feedbackId) => ({
  type: DISPUTE_REP_REQUEST,
  payload: { feedbackId },
});
const receiveDisputeRep = (feedbackId, disputeResponse) => ({
  type: DISPUTE_REP_SUCCESS,
  payload: { feedbackId, disputeResponse },
});
const receiveDisputeRepError = (feedbackId, disputeResponse) => ({
  type: DISPUTE_REP_FAILURE,
  payload: { feedbackId, disputeResponse },
});
const requestInvitationTemplate = (invitationTemplate) => ({
  type: INVITATION_TEMPLATE_REQUEST,
  payload: { invitationTemplate },
});
const receiveInvitationTemplate = (invitationTemplate) => ({
  type: INVITATION_TEMPLATE_SUCCESS,
  payload: { invitationTemplate },
});
const receiveInvitationSetTemplate = (invitationTemplate) => ({
  type: INVITATION_SET_TEMPLATE_SUCCESS,
  payload: { invitationTemplate },
});
const receiveInvitationTemplateError = (invitationTemplate) => ({
  type: INVITATION_TEMPLATE_FAILURE,
  payload: { invitationTemplate },
});

const resetRepSearchState = (data) => ({
  type: GET_RESET_REP_SEARCH_STATE,
  payload: data,
});
const setRepSearchState = (data) => ({
  type: SET_REP_SEARCH_STATE,
  repSearch: data,
});

const requestRepView = () => ({ type: REP_PROFILE_VIEW_REQUEST });
const receiveRepView = (data) => ({
  type: REP_PROFILE_VIEW_SUCCESS,
  payload: data,
});
const receiveRepViewError = (error) => ({
  type: REP_PROFILE_VIEW_FAILURE,
  payload: error,
});

const resetContactRepEmail = () => ({ type: CONTACT_REP_EMAIL_RESET });
const requestContactRepEmail = () => ({ type: CONTACT_REP_EMAIL_REQUEST });
const receiveContactRepEmail = (data) => ({
  type: CONTACT_REP_EMAIL_SUCCESS,
  payload: data,
});
const receiveContactRepEmailError = (error) => ({
  type: CONTACT_REP_EMAIL_FAILURE,
  payload: error,
});

const resetContactCompanyEmail = () => ({ type: CONTACT_COMPANY_EMAIL_RESET });
const requestContactCompanyEmail = () => ({
  type: CONTACT_COMPANY_EMAIL_REQUEST,
});
const receiveContactCompanyEmail = (data) => ({
  type: CONTACT_COMPANY_EMAIL_SUCCESS,
  payload: data,
});
const receiveContactCompanyEmailError = (error) => ({
  type: CONTACT_COMPANY_EMAIL_FAILURE,
  payload: error,
});

const disconnectCompanyRequested = () => ({
  type: DISCONNECT_COMPANY_REQUESTED,
});
const disconnectCompanySucceeded = (data) => ({
  type: DISCONNECT_COMPANY_SUCCEEDED,
  payload: data,
});
const disconnectCompanyFailed = (error) => ({
  type: DISCONNECT_COMPANY_FAILED,
  payload: error,
});

const clearErrorsRequested = () => ({ type: CLEAR_ERRORS_REQUESTED });

const getRepProfileRequested = () => ({ type: GET_REP_PROFILE_REQUESTED });
const getRepProfileSucceeded = (data) => ({
  type: GET_REP_PROFILE_SUCCEEDED,
  payload: data,
});
const getRepProfileFailed = (error) => ({
  type: GET_REP_PROFILE_FAILED,
  payload: error,
});

const getRepSuggestionsRequested = () => ({
  type: GET_REP_SUGGESTIONS_REQUESTED,
});
const getRepSuggestionsSucceeded = (data) => ({
  type: GET_REP_SUGGESTIONS_SUCCEEDED,
  payload: data,
});
const getRepSuggestionsFailed = (error) => ({
  type: GET_REP_SUGGESTIONS_FAILED,
  payload: error,
});

const getCompanyRepSuggestionsRequested = () => ({
  type: GET_COMPANY_REP_SUGGESTIONS_REQUESTED,
});
const getCompanyRepSuggestionsSucceeded = (data) => ({
  type: GET_COMPANY_REP_SUGGESTIONS_SUCCEEDED,
  payload: data,
});
const getCompanyRepSuggestionsFailed = (error) => ({
  type: GET_COMPANY_REP_SUGGESTIONS_FAILED,
  payload: error,
});

/***************************************/
const addCustomerRepFeedBackRequested = () => ({
  type: ADD_CUSTOMER_REP_FEEDBACK_REQUESTED,
});
const addCustomerRepFeedBackSucceeded = (data) => ({
  type: ADD_CUSTOMER_REP_FEEDBACK_SUCCEEDED,
  payload: data,
});
const addCustomerRepFeedBackFailed = (data) => ({
  type: ADD_CUSTOMER_REP_FEEDBACK_FAILED,
  payload: data,
});

const updateCustomerRepFeedBackRequested = () => ({
  type: UPDATE_CUSTOMER_REP_FEEDBACK_REQUESTED,
});
const updateCustomerRepFeedBackSucceeded = (data) => ({
  type: UPDATE_CUSTOMER_REP_FEEDBACK_SUCCEEDED,
  payload: data,
});
const updateCustomerRepFeedBackFailed = (data) => ({
  type: UPDATE_CUSTOMER_REP_FEEDBACK_FAILED,
  payload: data,
});

const deleteCustomerRepFeedBackRequested = () => ({
  type: DELETE_CUSTOMER_REP_FEEDBACK_REQUESTED,
});
const deleteCustomerRepFeedBackSucceeded = (data) => ({
  type: DELETE_CUSTOMER_REP_FEEDBACK_SUCCEEDED,
  payload: data,
});
const deleteCustomerRepFeedBackFailed = (data) => ({
  type: DELETE_CUSTOMER_REP_FEEDBACK_FAILED,
  payload: data,
});

const upVoteCustomerRepFeedBackRequested = () => ({
  type: UPVOTE_CUSTOMER_REP_FEEDBACK_REQUESTED,
});
const upVoteCustomerRepFeedBackSucceeded = (data) => ({
  type: UPVOTE_CUSTOMER_REP_FEEDBACK_SUCCEEDED,
  payload: data,
});
const upVoteCustomerRepFeedBackFailed = (data) => ({
  type: UPVOTE_CUSTOMER_REP_FEEDBACK_FAILED,
  payload: data,
});
const upVoteStateCustomerRepFeedBackSucceeded = (data) => ({
  type: UPDATE_STATE_CUSTOMER_REP_FEEDBACK_SUCCEEDED,
  payload: data,
});

/*********************************/

const filterMetrics = (data, months, metric, contactPage, repFlag) => {
  let arr = [];
  let tempIndex = 0;
  let tempData = metric;
  let metricData = data;
  let isCutAttr = false;
  if (metric === "repScoreList") {
    metricData =
      repFlag === "improvedRep"
        ? data["ratingsMap"]["REPSCORE"]["graphItemList"]
        : data["ratingsMap"]["REPSCORE"];
  } else if (tempData) {
    data["customRatingsMap"] &&
      Object.keys(data["customRatingsMap"]).forEach((ele) => {
        if (ele === metric) {
          isCutAttr = true;
        }
      });
    if (isCutAttr) {
      metricData =
        repFlag === "improvedRep"
          ? data["customRatingsMap"][metric]["graphItemList"]
          : data["customRatingsMap"][metric];
    } else {
      tempData.split("").forEach((ele, index) => {
        if (ele === ele.toUpperCase()) {
          let modeifiedVal = metric.substring(tempIndex, index);
          arr.push(modeifiedVal.toUpperCase());
          tempIndex = index;
        }
      });
      metricData =
        repFlag === "improvedRep"
          ? data["ratingsMap"][arr.join("_")]["graphItemList"]
          : data["ratingsMap"][arr.join("_")];
    }
  }

  let result = {
    labels: [],
    values: [],
  };

  let _contactPage = false;
  if (contactPage) {
    _contactPage = contactPage;
  }
  if (metricData && months && !_contactPage) {
    const from = moment().subtract(months, "months");
    result = metricData
      .filter((info) => moment(info.date, "MM-DD-YYYY").isAfter(from))
      .sort(
        (infoA, infoB) =>
          new Date(infoA.date).getTime() - new Date(infoB.date).getTime()
      )
      .reduce(
        (accum, info) => ({
          labels: [...accum.labels, info.date],
          values: [...accum.values, info.value],
        }),
        {
          labels: [],
          values: [],
        }
      );
  }

  return {
    type: SET_REP_TRENDS_DATA,
    payload: result,
  };
};

export function getReps(extraParams = {}) {
  return (dispatch) => {
    dispatch(requestRepList());
    repService
      .getRepList(extraParams)
      .then((res) => {
        setTimeout(() => {
          if (res) {
            dispatch(receiveRepList(res.payload)); // TODO: FIX THIS
          }
        }, 1000);
      })
      .catch((err) => {
        dispatch(receiveRepListError(err));
      });
  };
}

export function getRepProfileSample(repId, userLogged, userId) {
  return (dispatch) => {
    dispatch(getRepProfileRequested());

    repService
      .getRepProfileSample(repId, userLogged, userId)
      .then((response) => {
        dispatch(getRepProfileSucceeded(response));
      })
      .catch((error) => {
        dispatch(getRepProfileFailed(error));
      });
  };
}

export function getRepProfile(repId, userLogged, userId) {
  return (dispatch) => {
    dispatch(getRepProfileRequested());

    repService
      .getRepProfile(repId, userLogged, userId)
      .then((response) => {
        dispatch(getRepProfileSucceeded(response));
      })
      .catch((error) => {
        dispatch(getRepProfileFailed(error));
      });
  };
}

export function viewRep(repID, defaultMetric, defaultTimeFilter) {
  return (dispatch, getState) => {
    const state = getState();
    const auth = state.auth;
    const userData = auth && auth.userData;

    if (userData && userData.id) {
      dispatch(requestRepInfo());
      dispatch(setRepTrendsMetric(defaultMetric));
      dispatch(setRepTrendsTimeFilter(defaultTimeFilter));

      repService
        .repProfile(repID, userData.id)
        .then((response) => {
          dispatch(receiveRepInfo(response));
          dispatch(
            filterMetrics(
              response.payload,
              defaultTimeFilter.months,
              defaultMetric.key,
              false
            )
          );
          return;
        })
        .catch((error) => {
          dispatch(receiveRepInfoError(error));
        });
    }
  };
}

export function improvedGetRepProfile(repID, defaultMetric, defaultTimeFilter) {
  return (dispatch, getState) => {
    const state = getState();
    const auth = state.auth;
    const userData = auth && auth.userData;

    if (userData && userData.id) {
      dispatch(requestRepInfo());
      dispatch(setRepTrendsMetric(defaultMetric));
      dispatch(setRepTrendsTimeFilter(defaultTimeFilter));
      repService
        .improvedGetRepProfile(repID, userData.id)
        .then((profileData) => {
          dispatch(requestImprovedRepFeedbackRequest());
          dispatch(receiveRepInfo(profileData));
          repService
            .improvedGetRepFeedback(repID, userData.id)
            .then((feedbackData) => {
              dispatch(receiveImprovedRepFeedbackSuccess(feedbackData));
            })
            .catch((err) => {
              dispatch(requestImprovedRepFeedbackFailure(err));
            });

          dispatch(
            filterMetrics(
              profileData.payload,
              defaultTimeFilter.months,
              defaultMetric.key,
              false,
              "improvedRep"
            )
          );
        })
        .catch((error) => {
          dispatch(receiveRepInfoError(error));
        });
    }
  };
}

export function viewRepContact(repID, defaultMetric, defaultTimeFilter) {
  return (dispatch, getState) => {
    const state = getState();
    const auth = state.auth;
    const userData = auth && auth.userData;

    if (userData && userData.id) {
      dispatch(requestRepInfo());
      dispatch(setRepTrendsMetric(defaultMetric));
      dispatch(setRepTrendsTimeFilter(defaultTimeFilter));

      repService
        .repProfile(repID, userData.id)
        .then((response) => {
          dispatch(receiveRepInfo(response));
          dispatch(
            filterMetrics(
              response.payload,
              defaultTimeFilter.months,
              defaultMetric.key,
              true
            )
          );
        })
        .catch((error) => {
          dispatch(receiveRepInfoError(error));
        });
    }
  };
}

export function viewRepLink(repID, defaultMetric, defaultTimeFilter) {
  return (dispatch) => {
    // const state = getState();
    // const auth = state.auth;
    // const userData = auth && auth.userData;

    dispatch(requestRepInfo());
    dispatch(setRepTrendsMetric(defaultMetric));
    dispatch(setRepTrendsTimeFilter(defaultTimeFilter));

    repService
      .repProfileLink(repID, repID)
      .then((response) => {
        dispatch(receiveRepInfo(response));
        dispatch(
          filterMetrics(
            response.payload,
            defaultTimeFilter.months,
            defaultMetric.key,
            false
          )
        );
      })
      .catch((error) => {
        dispatch(receiveRepInfoError(error));
      });
  };
}

export function setTrendsDEfault(defaultMetric) {
  return (dispatch) => {
    dispatch(setRepTrendsMetric(defaultMetric));
  };
}

export function viewComapnyRep(
  repID,
  defaultMetric,
  defaultTimeFilter,
  companyId
) {
  return (dispatch, getState) => {
    const state = getState();
    const auth = state.auth;
    const userData = auth && auth.userData;
    // "

    if (userData && userData.id) {
      dispatch(requestRepInfo());
      dispatch(setRepTrendsMetric(defaultMetric));
      dispatch(setRepTrendsTimeFilter(defaultTimeFilter));

      repService
        .getCompanyRepProfile(repID, companyId)
        .then((response) => {
          dispatch(receiveRepInfo(response));
          dispatch(
            filterMetrics(
              response.payload,
              defaultTimeFilter.months,
              defaultMetric.key,
              false
            )
          );
        })
        .catch((error) => {
          dispatch(receiveRepInfoError(error));
        });
    }
  };
}
//Optimized API
export function viewRepCompany(
  repID,
  defaultMetric,
  defaultTimeFilter,
  companyId
) {
  return (dispatch, getState) => {
    dispatch(requestOptimisedRepInfo());
    dispatch(requestRepInfo());
    dispatch(reqOPTRepInfo());
    const state = getState();
    const auth = state.auth;
    const userData = auth && auth.userData;
    if (userData && userData.id) {
      // dispatch(requestRepInfo());
      // dispatch(requestOptimisedRepInfo());
      repService
        .getCompanyRep(repID, companyId)
        .then((response) => {
          dispatch(receiveOptimisedRepInfo(response));
          // dispatch(reqOPTRepInfo());
          // dispatch(setRepTrendsMetric(defaultMetric))
          // dispatch(setRepTrendsTimeFilter(defaultTimeFilter))
        })
        .catch((error) => {
          // dispatch(receiveRepInfoError(error))
          dispatch(receiveOptimisedRepInfoError(error));
        });

      repService
        .getCompanyRepProfile(repID, companyId)
        .then((response) => {
          // dispatch(upVoteStateCustomerRepFeedBackSucceeded(response))
          dispatch(receiveOPTRepInfo(response));
          // dispatch(receiveRepInfo(response));
          // dispatch(filterMetrics(response.payload, defaultTimeFilter.months, defaultMetric.key, false));
        })
        .catch((error) => {
          dispatch(receiveOPTRepInfoError());
          dispatch(receiveRepInfoError(error));
        });

      //  repService
      //         .getCompanyRepProfile(repID, companyId)
      //         .then(response => {
      //           dispatch(receiveRepInfo(response));
      //           dispatch(filterMetrics(response.payload, defaultTimeFilter.months, defaultMetric.key, false));
      //         })
      //         .catch(error => {
      //           dispatch(receiveRepInfoError(error))
      //         });
    } else {
    }
  };
}

export function rateRep(rateData, userLogged) {
  return (dispatch) => {
    dispatch(requestRateRep());

    repService
      .rateRep(rateData, userLogged)
      .then((response) => {
        dispatch(receiveRateRep(response));
      })
      .catch((error) => {
        dispatch(receiveRateRepError(error));
      });
  };
}

export function setTrendsTimeFilter(newValue, repFlag) {
  return (dispatch, getState) => {
    const { rep } = getState();
    dispatch(setRepTrendsTimeFilter(newValue));
    dispatch(
      filterMetrics(
        rep.repProfile.payload,
        newValue.months,
        rep.trendsMetric && rep.trendsMetric.key,
        false,
        repFlag
      )
    );
  };
}

export function setTrendsMetric(newValue, repFlag) {
  return (dispatch, getState) => {
    const { rep } = getState();
    dispatch(setRepTrendsMetric(newValue));
    dispatch(
      filterMetrics(
        rep.repProfile.payload,
        rep.trendsTimeFilter && rep.trendsTimeFilter.months,
        newValue.key,
        false,
        repFlag
      )
    );
  };
}

export function updateRepProductSpeciality(userId, productSpeciality) {
  return (dispatch) => {
    dispatch(updateRepProductSpecialityRequested());
    repService
      .updateRepProductSpeciality(userId, productSpeciality)
      .then((res) => {
        setTimeout(() => {
          if (res) {
            dispatch(updateRepProductSpecialitySucceeded(res.payload)); // TODO: FIX THIS
          }
        }, 1000);
      })
      .catch((error) => {
        dispatch(updateRepProductSpecialityFailed(error));
      });
  };
}

export function updateCompanyUserInfo(info) {
  return (dispatch) => {
    dispatch(requestUpdateCompanyUserInfo());
    repService
      .updateCompanyUserInfo(info)
      .then((res) => {
        setTimeout(() => {
          if (res) {
            dispatch(receiveUpdateCompanyUserInfo(res.payload.value)); // TODO: FIX THIS
          }
        }, 1000);
      })
      .catch((err) => {
        dispatch(receiveUpdateCompanyUserInfoError(err));
      });
  };
}

export function deleteCompanyUser(companyId, userId) {
  return (dispatch) => {
    dispatch(requestDeleteCompanyUser());
    repService
      .deleteCompanyUser(companyId, userId)
      .then((res) => {
        setTimeout(() => {
          if (res && res.payload) {
            dispatch(receiveDeleteCompanyUser(res.payload.value)); // TODO: FIX THIS
          }
        }, 1000);
      })
      .catch((err) => {
        dispatch(receiveDeleteCompanyUserError(err));
      });
  };
}

export function addPrevCompanyUser(company) {
  return (dispatch) => {
    dispatch(addPreviousCompanyRequested());

    repService
      .addPreviousCompany(company)
      .then((response) => {
        dispatch(addPreviousCompanySucceeded(response));
      })
      .catch((error) => {
        dispatch(addPreviousCompanyFailed(error));
      });
  };
}

export function updateFromLinkedin(providerName, userRole, code, history) {
  history.push("/repaccount");
  return (dispatch) => {
    dispatch(updateFromLinkedinRequested());
    const callback = `${config.LOCAL_ROOT_URL}/repaccount`;

    repService
      .updateFromLinkedin(providerName, userRole, callback, code)
      .then((response) => {
        setTimeout(() => {
          if (response) {
            dispatch(updateFromLinkedinSucceeded(response.payload)); // TODO: FIX THIS
          }
        }, 500);
      })
      .catch((error) => {
        dispatch(updateFromLinkedinFailed(error));
      });
  };
}

export function rateNewRep(
  reviewerId,
  companyId,
  userInfo,
  detail,
  anonymous,
  userRatings,
  thumbsUp,
  functionSelected
) {
  return (dispatch) => {
    dispatch(requestRateNewRep());
    repService
      .rateNewRep(
        reviewerId,
        companyId,
        userInfo,
        detail,
        anonymous,
        userRatings,
        thumbsUp,
        functionSelected
      )
      .then((res) =>
        setTimeout(() => {
          if (res) {
            dispatch(receiveRateNewRep(res)); // TODO: FIX THIS
          }
        }, 100)
      )
      .catch((err) => {
        dispatch(receiveRateNewRepError(err));
      });
  };
}

export function updateRepEmail(
  userId,
  email,
  rcvMsgAtWorkEmail,
  secondaryEmail,
  rcvMsgAtSecondaryEmail
) {
  return (dispatch) => {
    dispatch(updateRepEmailRequested());

    repService
      .updateRepEmail(
        userId,
        email,
        rcvMsgAtWorkEmail,
        secondaryEmail,
        rcvMsgAtSecondaryEmail
      )
      .then((response) => {
        authService.updateUserInfo({
          email,
          rcvMsgAtWorkEmail,
          secondaryEmail,
          rcvMsgAtSecondaryEmail,
        });
        dispatch({ type: UPDATE_USER_INFO_REQUESTED });
        dispatch(updateRepEmailSucceeded(response.payload));
      })
      .catch((error) => {
        dispatch(updateRepEmailFailed(error));
      });
  };
}

export function deleteRepEmail(userId) {
  return (dispatch) => {
    dispatch(deleteRepEmailRequested());

    repService
      .deleteRepEmail(userId)
      .then((response) => {
        authService.updateUserInfo({ secondaryEmail: null });
        dispatch({ type: UPDATE_USER_INFO_REQUESTED });
        dispatch(deleteRepEmailSucceeded(response.payload));
      })
      .catch((error) => {
        dispatch(deleteRepEmailFailed(error));
      });
  };
}

export function claimRep(tempRepId, claimRepId, claimReason) {
  return (dispatch) => {
    dispatch(requestClaimRep());
    repService
      .claimRep(tempRepId, claimRepId, claimReason)
      .then((res) => {
        setTimeout(() => {
          if (res) {
            dispatch(receiveClaimRep(res)); // TODO: FIX THIS
          }
        }, 1000);
      })
      .catch((err) => {
        dispatch(receiveClaimRepError(err));
      });
  };
}

export function clearClaimRep() {
  return (dispatch) => {
    dispatch(resetClaimRep());
  };
}

export function disputeRep(repid, feedbackId, comment, disputedBy) {
  // return (dispatch, getState) => {
  //   const state = getState();
  //   const auth = state.auth;
  //   const userData = auth && auth.userData;
  //   if (userData && userData.id) {
  return (dispatch, getState) => {
    const state = getState();
    const auth = state.auth;
    const userData = auth && auth.userData;
    let payload = {
      repId: repid,
      feedbackId: feedbackId,
      comment: comment,
      disputedBy: disputedBy,
    };
    if (disputedBy === "COMPANY") {
      Object.assign(payload, { disputingCompanyUserId: userData.id });
    }
    dispatch(requestDisputeRep(feedbackId));
    repService
      // .disputeRep(repid, feedbackId, comment, disputedBy)
      .disputeRep(payload)
      .then((res) => {
        setTimeout(() => {
          if (res) {
            dispatch(receiveDisputeRep(feedbackId, res)); // TODO: FIX THIS
          }
        }, 1000);
      })
      .catch((err) => {
        dispatch(receiveDisputeRepError(feedbackId, err));
      });
  };
}

//changes start
export function setRepTemplate(repid, template) {
  return (dispatch) => {
    dispatch(requestInvitationTemplate(repid));
    repService
      .setRepTemplate(repid, template)
      .then((res) => {
        setTimeout(() => {
          if (res) {
            dispatch(receiveInvitationSetTemplate(res)); // TODO: FIX THIS
          }
        }, 1000);
      })
      .catch((err) => {
        dispatch(receiveInvitationTemplateError(err));
      });
  };
}

export function getRepTemplate(repid) {
  return (dispatch) => {
    dispatch(requestInvitationTemplate(repid));
    repService
      .getRepTemplate(repid)
      .then((res) => {
        setTimeout(() => {
          if (res) {
            dispatch(receiveInvitationTemplate(res)); // TODO: FIX THIS
          }
        }, 1000);
      })
      .catch((err) => {
        dispatch(receiveInvitationTemplateError(err));
      });
  };
}
//end

export function getRepViewInfo(id) {
  return (dispatch) => {
    dispatch(resetContactRepEmail());
    dispatch(resetContactCompanyEmail());
    dispatch(requestRepView());
    repService
      .getRepViewInfo(id)
      .then((res) => {
        dispatch(receiveRepView(res));
      })
      .catch((error) => {
        dispatch(receiveRepViewError(error));
      });
  };
}

export function contactRepEmail(info) {
  return (dispatch) => {
    dispatch(requestContactRepEmail());
    repService
      .contactRepEmail(info)
      .then((res) => {
        dispatch(receiveContactRepEmail(res));
      })
      .catch((error) => {
        dispatch(receiveContactRepEmailError(error));
      });
  };
}

export function contactCompanyEmail(info) {
  return (dispatch) => {
    dispatch(requestContactCompanyEmail());
    repService
      .contactCompanyEmail(info)
      .then((res) => {
        dispatch(receiveContactCompanyEmail(res));
      })
      .catch((error) => {
        dispatch(receiveContactCompanyEmailError(error));
      });
  };
}

export function disconnectCompany(userId) {
  return (dispatch) => {
    dispatch(disconnectCompanyRequested());

    RepService.disconnectCompany(userId)
      .then((response) => {
        dispatch(disconnectCompanySucceeded(response));
        dispatch(getUserCompanies(userId));
      })
      .catch((error) => {
        dispatch(disconnectCompanyFailed(error));
      });
  };
}

export function clearErrors() {
  return (dispatch) => {
    dispatch(clearErrorsRequested());
  };
}

export function getRepSuggestions(repName, companyId, unclaimed) {
  return (dispatch) => {
    dispatch(getRepSuggestionsRequested());

    RepService.getRepSuggestions(repName, companyId, unclaimed)
      .then((response) => {
        dispatch(getRepSuggestionsSucceeded(response));
      })
      .catch((error) => {
        dispatch(getRepSuggestionsFailed(error));
      });
  };
}

export function resetState(data) {
  return (dispatch) => {
    dispatch(resetRepSearchState(data));
  };
}

export function setingRepSearchStates(data) {
  return (dispatch) => {
    dispatch(setRepSearchState(data));
  };
}

export function getCompanyRepSuggestions(repName, companyId) {
  return (dispatch) => {
    dispatch(getCompanyRepSuggestionsRequested());

    RepService.getCompanyRepSuggestions(repName, companyId)
      .then((response) => {
        dispatch(getCompanyRepSuggestionsSucceeded(response));
      })
      .catch((error) => {
        dispatch(getCompanyRepSuggestionsFailed(error));
      });
  };
}

/****************************/
// const addCustomerRepFeedBackRequested = () => ({ type: ADD_CUSTOMER_REP_FEEDBACK_REQUESTED });
// const addCustomerRepFeedBackSucceeded = data => ({ type: ADD_CUSTOMER_REP_FEEDBACK_SUCCEEDED, payload: data });
// const addCustomerRepFeedBackFailed = data => ({ type: ADD_CUSTOMER_REP_FEEDBACK_FAILED, payload: data });
export function addRepFeedCustomer({
  company,
  id,
  defaultMetric,
  defaultTimeFilter,
  loginType,
  improvedRep,
  companyId,
}) {
  return (dispatch) => {
    dispatch(addCustomerRepFeedBackRequested());
    repService
      .addFeedCustomerBack(company)
      .then((response) => {
        dispatch(addCustomerRepFeedBackSucceeded(response));
        if (improvedRep) {
          dispatch(improvedGetRepProfile(id, defaultMetric, defaultTimeFilter));
        } else {
          if (loginType === "repLogin") {
            dispatch(viewRep(id, defaultMetric, defaultTimeFilter));
          } else {
            dispatch(
              viewRepCompany(id, defaultMetric, defaultTimeFilter, companyId)
            );
          }
        }
        // dispatch(viewRepCustomerList(id));
      })
      .catch((error) => {
        dispatch(addCustomerRepFeedBackFailed(error));
      });
  };
}

export function updateRepFeedCustomer({
  company,
  id,
  defaultMetric,
  defaultTimeFilter,
  companyId,
  loginType,
  improvedRep,
}) {
  return (dispatch) => {
    dispatch(updateCustomerRepFeedBackRequested());
    repService
      .updateFeedCustomerBack(company)
      .then((response) => {
        dispatch(updateCustomerRepFeedBackSucceeded(response));
        if (improvedRep) {
          dispatch(improvedGetRepProfile(id, defaultMetric, defaultTimeFilter));
        } else {
          if (loginType === "repLogin") {
            dispatch(viewRep(id, defaultMetric, defaultTimeFilter));
          } else {
            dispatch(
              viewRepCompany(id, defaultMetric, defaultTimeFilter, companyId)
            );
          }
        }
      })
      .catch((error) => {
        dispatch(updateCustomerRepFeedBackFailed(error));
      });
  };
}

export function deleteRepFeedCustomer(
  company,
  id,
  defaultMetric,
  defaultTimeFilter,
  improvedRep
) {
  return (dispatch) => {
    dispatch(deleteCustomerRepFeedBackRequested());
    repService
      .deleteFeedCustomerBack(company)
      .then((response) => {
        dispatch(deleteCustomerRepFeedBackSucceeded(response));
        // dispatch(viewRep(id, defaultMetric, defaultTimeFilter))
        if (improvedRep) {
          dispatch(improvedGetRepProfile(id, defaultMetric, defaultTimeFilter));
        } else {
          dispatch(viewRepCustomerList(id));
        }
      })
      .catch((error) => {
        dispatch(deleteCustomerRepFeedBackFailed(error));
      });
  };
}

export function upVoteRepFeedCustomer(company, id) {
  return (dispatch) => {
    dispatch(upVoteCustomerRepFeedBackRequested());
    repService
      .upvoteFeedCustomerBack(company)
      .then((response) => {
        dispatch(upVoteCustomerRepFeedBackSucceeded(response));
        // dispatch(upVoteStateCustomerRepFeedBackSucceeded(id))
        dispatch(viewRepCustomerList(id));
      })
      .catch((error) => {
        dispatch(upVoteCustomerRepFeedBackFailed(error));
      });
  };
}

export function viewRepCustomerList(repID) {
  return (dispatch, getState) => {
    const state = getState();
    const auth = state.auth;
    const userData = auth && auth.userData;
    if (userData && userData.id) {
      repService
        .repProfile(repID, userData.id)
        .then((response) => {
          dispatch(
            upVoteStateCustomerRepFeedBackSucceeded(
              response.payload.customerFeedbackList
            )
          );
        })
        .catch((error) => {
          dispatch(receiveRepInfoError(error));
        });
    }
  };
}

// const upVoteState = (obj) => {
//   return (dispatch) => {
//     dispatch(upVoteStateCustomerRepFeedBackSucceeded(obj));
//   };
// };
// export function getCompanyRepSuggestions(repName, companyId) {
//   return dispatch => {
//     dispatch(getCompanyRepSuggestionsRequested());

//     RepService.getCompanyRepSuggestions(repName, companyId)
//       .then(response => {
//         dispatch(getCompanyRepSuggestionsSucceeded(response));
//       })
//       .catch(error => {
//         dispatch(getCompanyRepSuggestionsFailed(error));
//       });
//   };
// }

/*****************************/
