import React from "react";
import styled from "styled-components";
import { reporaDevice } from "../../../components/global/commonStyles";
import PersonCard from "./PersonCard";
import RateConfirmation from "./RateConfirmation";
import RatingsCard from "./RatingsCard";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;

  @media ${reporaDevice.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const SideWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  flex-shrink: 0;

  @media ${reporaDevice.tablet} {
    ${(props) =>
      props.left
        ? "width: 40%; min-width: 305px; padding-right: 20px;"
        : "width: 60%"}
  }

  @media ${reporaDevice.desktop} {
    ${(props) => (props.left ? "max-width: 305px;" : "max-width: 600px")}
  }
`;

const View = (props) => {
  if (props.rateResponseReturned) {
    return (
      <RateConfirmation
        rateId={props.rateId}
        repName={props.repData.firstName + " " + props.repData.lastName}
        repPhoto={
          props.repData && props.repData.photoLinkedin
            ? props.repData.photoLinkedin
            : "assets/global/userIcon.svg"
        }
        responseMessage={props.rateResponse}
        handleRateDone={props.handleRateDone}
        userData={props.userData}
      />
    );
  }

  return (
    <Wrapper>
      <SideWrapper left>
        <PersonCard
          repData={props.repData}
          userData={props.userData}
          repCompanies={getCompany(props)}
          isFetching={props.repDataFetching}
        />
      </SideWrapper>

      <SideWrapper right>
        {props.repData.ratingAttributes && props.customAttr ? (
          <RatingsCard
            key={props.repData.id}
            userData={props.userData}
            repData={props.repData}
            rateId={props.rateId}
            repCompanies={getCompany(props)}
            companies={props.companies}
            auth={props.auth}
            isFetchingRateRep={props.isFetchingRateRep}
            isFetching={
              props.repDataFetching ||
              props.repCompaniesFetching ||
              props.addUserCompanyFetching
            }
            handleAddUserCompany={props.handleAddUserCompany}
            ratingAttributes={props.repData.ratingAttributes}
            handleRateRep={props.handleRateRep}
            customAttr={props.customAttr}
          />
        ) : null}
      </SideWrapper>
    </Wrapper>
  );
};
const getCompany = (props) => {
  return props.repCompanies;
};

export default View;
