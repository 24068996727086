import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  requestAnalyticsCount,
  requestAnalyticsReviewsCount,
} from "../../../actions/adminActions";
import Loader from "../../../components/Loader/Loader";
import { Row, Wrapper as HeaderWrapper } from "./View";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reporaColor } from "../../../components/global/commonStyles";
import { NavLink } from "react-router-dom";

export const HeaderRow = styled.div`
  background: ${reporaColor.grey98};
  display: flex;
  height: 60px;
  border-bottom: 1px solid ${reporaColor.grey91};
  > div {
    font-size: 15px;
    padding-left: 20px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    background: ${reporaColor.grey98};
    user-select: none;
    &:last-child {
      border-right: none;
    }
  }

  .labelColumns-0 {
  }
  .labelColumns-1 {
    padding: 0 0 0 40px;
  }
  .labelColumns-2 {
    padding: 0 0 0 80px;
  }

  > span {
    flex: 1;
    padding-left: 15px;
    padding-right: 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    user-select: none;
    &:last-child {
      border-right: none;
    }
  }

  &:last-child {
    border-bottom: none;
  }
  &:nth-child(even) {
  }
`;

const QuestionIcon = styled.div`
  border: 1px solid;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  padding: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: ${reporaColor.warmGrey};
  margin-left: 10px;
`;

export const TooltipWrapper = styled.div`
  .manage-emails-tooltip {
    color: ${reporaColor.red};
    background: ${reporaColor.gray};
    opacity: 1;
    z-index: 1200;
    width: 220px;
    padding: 10px;
    left: 800px !important;
    top: 20px !important;
  }
`;

const HeaderHelp = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Admin_UserStats = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestAnalyticsCount("test"));
    dispatch(requestAnalyticsReviewsCount());
  }, []);

  return (
    <>
      {props.adminDataLoading ? (
        <Loader overlayed size="small" />
      ) : (
        <>
          <div style={{ width: "85%", margin: "2%", marginTop: "5%" }}>
          <NavLink className='Navlink' to = "/admin/dashboard">Back to Admin Panel</NavLink><br/><br/>
            <HeaderHelp>
              <div>
                <strong>Reviews</strong>
              </div>
              <QuestionIcon data-for="review-count-help" data-tip>
                <FontAwesomeIcon icon={faQuestion} />
              </QuestionIcon>
            </HeaderHelp>
            <TooltipWrapper>
              <ReactTooltip
                id="review-count-help"
                className="manage-emails-tooltip"
                style={{ left: "400px", top: "10px" }}
              >
                <strong>Definitions:</strong>
                <ul>
                  <li>Today: Since midnight last night</li>
                  <br />
                  <li>
                    Last Week: The last 7 full days of data. Eg. If viewed in
                    middle of a Tuesday, it will include data for 00:00 Tuesday
                    of the prior week untill 23:59 Monday this week.
                  </li>
                  <br />
                  <li>
                    Last Month: Similar to last 7days. It should include the
                    last 30 or 31 full days of data
                  </li>
                  <br />
                  <li>All time: All reviews ever collected </li>
                </ul>
              </ReactTooltip>
            </TooltipWrapper>
            <HeaderWrapper id="headWrapper">
              <HeaderRow>
                <div style={{ width: "28%" }}>{""}</div>
                <div style={{ width: "18%" }}>Today</div>
                <div style={{ width: "18%" }}>Last Week</div>
                <div style={{ width: "18%" }}>Last Month</div>
                <div style={{ width: "18%" }}>All Time</div>
              </HeaderRow>
              {props.adminReviewCount && props.adminReviewCount.payload && (
                <>
                  {reviewCountMappings.map((item, index) => (
                    <>
                      <HeaderRow>
                        <div
                          style={{ width: "28%" }}
                          key={`${item.label}-${index}`}
                          className={`labelColumns-${item.level}`}
                        >
                          {item.label}
                        </div>
                        {item.mappinigs.map((cat, index) => {
                          return (
                            <div
                              style={{ width: "18%" }}
                              key={`${cat}-${index}`}
                            >
                              {props.adminReviewCount.payload[cat]}
                            </div>
                          );
                        })}
                      </HeaderRow>
                    </>
                  ))}
                </>
              )}
            </HeaderWrapper>
          </div>

          <div style={{ width: "85%", margin: "2%" }}>
            <HeaderHelp>
              <div>
                <strong>User/Company Counts</strong>
              </div>
              <QuestionIcon data-for="analytics-help" data-tip>
                <FontAwesomeIcon icon={faQuestion} />
              </QuestionIcon>
            </HeaderHelp>
            <ReactTooltip id="analytics-help">
              <span>Counts: total number of users/companies by type</span>
            </ReactTooltip>
            <HeaderWrapper id="headWrapper">
              <Row>
                <div>Type</div>
                <div>Count</div>
              </Row>
              {props.adminAnalyticsCount && props.adminAnalyticsCount.payload && (
                <>
                  <>
                    {analyticsMappings.map((item, index) => (
                      <Row>
                        <div key={`${item.label}-${index}`}><span
                        style={{marginLeft: item.label.trim().toLocaleLowerCase() === "private" || item.label.trim().toLocaleLowerCase() === "public" ? "60px" : "0px" }
                        }>{item.label}</span></div>
                        <div key={`${item.key}-${index}`}>
                          {props.adminAnalyticsCount.payload[item.key]}
                        </div>
                      </Row>
                    ))}
                  </>
                </>
              )}
            </HeaderWrapper>
          </div>
        </>
      )}
    </>
  );
};

const analyticsMappings = [
  { key: "companyCount", label: "Companies" },
  { key: "privateCompanyCount", label: "Private" },
  { key: "publicCompanyCount", label: "Public" },
  { key: "companyAdminCount", label: "Company Admins" },
  { key: "companyUsersCount", label: "Company Users" },
  { key: "connectedRepsCount", label: "Connected Reps" },
  { key: "unconnectedRepsCount", label: "Unconnected Reps" },
  { key: "customersCount", label: "Customers" },
  { key: "repsCount", label: "Reps" },
  { key: "tempRepsCount", label: "Temps Reps" },
];

const reviewCountMappings = [
  {
    key: "totalReviews",
    label: "Total Reviews",
    level: 0,
    mappinigs: [
      "dailyReviewsCount",
      "weeklyReviewsCount",
      "monthlyReviewsCount",
      "totalReviewsCount",
    ],
  },
  {
    key: "dailyReviewsCount",
    label: "1. Logged In Customers",
    level: 1,
    mappinigs: [
      "dailyLoggedInReviewsCount",
      "weeklyLoggedInReviewsCount",
      "monthlyLoggedInReviewsCount",
      "totalLoggedInReviewsCount",
    ],
  },
  {
    label: "a. Anonymous",
    level: 2,
    mappinigs: [
      "dailyLoggedInAnonymousReviewsCount",
      "weeklyLoggedInAnonymousReviewsCount",
      "monthlyLoggedInAnonymousReviewsCount",
      "totalLoggedInAnonymousReviewsCount",
    ],
  },
  {
    label: "b. Identified",
    level: 2,
    mappinigs: [
      "dailyLoggedInIdentityReviewsCount",
      "weeklyLoggedInIdentityReviewsCount",
      "monthlyLoggedInIdentityReviewsCount",
      "totalLoggedInIdentityReviewsCount",
    ],
  },
  {
    key: "totalReviewsCount",
    label: "2. Via Invite Link",
    level: 1,
    mappinigs: [
      "dailyLinkReviewsCount",
      "weeklyLinkReviewsCount",
      "monthlyLinkReviewsCount",
      "totalLinkReviewsCount",
    ],
  },
  {
    key: "weeklyReviewsCount",
    label: "a. Anonymous",
    level: 2,
    mappinigs: [
      "dailyLinkAnonymousReviewsCount",
      "weeklyLinkAnonymousReviewsCount",
      "monthlyLinkAnonymousReviewsCount",
      "totalLinkAnonymousReviewsCount",
    ],
  },
  {
    key: "monthlyReviewsCount",
    label: "b. Identified",
    level: 2,
    mappinigs: [
      "dailyLinkIdentityReviewsCount",
      "weeklyLinkIdentityReviewsCount",
      "monthlyLinkIdentityReviewsCount",
      "totalLinkIdentityReviewsCount",
    ],
  },
];

const mapStateToProps = (state) => ({
  adminDataLoading: state.admin.adminStatusLoading,
  adminAnalyticsCount: state.admin.adminAnalyticsCount,
  adminReviewCount: state.admin.adminAnalyticsReviewsCount,
});
export default connect(mapStateToProps)(Admin_UserStats);
