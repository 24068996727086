import { isEqual } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import {
  addCompany,
  getCompanySuggestionsRateNewRep,
  getRepList,
} from "../../actions/companyActions";
import { checkEmailExist } from "../../actions/customerActions";
import { rateNewRep, rateNewRepGoBack } from "../../actions/repActions";
import View from "./components/View";

class RateRep extends React.Component {
  state = {
    redirectLink: "",
    currentCompany: "",
    detail: "",
    anonymous: false,
    companyId: "",
    userRatings: [],
    userRatingsValid: false,
    upVoted: [
      { customerId: "dd12fec8-1f99-4354-abcc-d18be0569330" },
      { customerId: "1c19e27e-4d81-48f8-9e07-ee0a8c31f999" },
    ],
    thumbsUp: null,
    commentCharLeft: 1000,
    isFormValid: false,
    companiesList: [],
    userInfo: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validEmail: false,
    errorMessage: "",
    userInfoValid: false,
    addCompanyLoading: false,
    functionSelected: "",
    showEmailExist: false,
  };

  componentDidMount() {
    // this.props.handleGetCompanies();
    this.props.handleGetRepList();
    window["Rate"] = this;
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(this.props.companies, prevProps.companies) &&
      this.state.currentCompany
    ) {
      const company = this.props.companies.find(
        (company) => company.name === this.state.currentCompany
      );
      this.setState({
        companyId: company.id,
        addCompanyLoading: false,
      });
    }

    // if (!this.state.isFormValid && !this.state.userRatings.some(ur => ur.value == null)) {
    //   const { userInfo, userRatingsValid, thumbsUp } = this.state;

    //   const isFormValid =
    //     userRatingsValid &&
    //     thumbsUp !== null &&
    //     userInfo.firstName.trim() !== "" &&
    //     userInfo.lastName.trim() !== "" &&
    //     userInfo.email.trim() !== "";
    //   // this.setState({ isFormValid: true && thumbsUp !== null });
    //   this.setState({ isFormValid: true});

    // } else if (this.state.isFormValid && this.state.userRatings.some(ur => ur.value == null)) {
    //   this.setState({ isFormValid: false });
    // }
  }

  componentWillReceiveProps(nextProps) {
    const { checkingEmailError } = nextProps;
    if (
      this.state.userInfo.email !== "" &&
      checkingEmailError &&
      !isEqual(checkingEmailError && this.props.checkingEmailError)
    ) {
      this.setState({ errorMessage: checkingEmailError.message });
    } else {
      this.setState({ errorMessage: "" });
    }
  }

  handleRateChange = (name, value) => {
    let userRatingsValid = true;
    let userRatings = this.state.userRatings.map(function (element) {
      if (element.category === name) {
        userRatingsValid = userRatingsValid && true;
        return { category: name, value: value };
      } else {
        userRatingsValid = userRatingsValid && element.value !== null;
        return element;
      }
    });

    const { userInfo, currentCompany, thumbsUp } = this.state;
    const isFormValid =
      userRatingsValid &&
      thumbsUp !== null &&
      userInfo.firstName.trim() !== "" &&
      userInfo.lastName.trim() !== "" &&
      userInfo.email.trim() !== "";
    this.setState({
      userRatings: userRatings,
      userRatingsValid: userRatingsValid,
      isFormValid: isFormValid,
    });
  };

  handleExperienceSelection = (thumbsUp) => {
    const { userInfo, currentCompany, userRatingsValid } = this.state;
    const isFormValid =
      userRatingsValid &&
      thumbsUp !== null &&
      userInfo.firstName.trim() !== "" &&
      userInfo.lastName.trim() !== "" &&
      userInfo.email.trim() !== "";
    this.setState({
      thumbsUp,
      isFormValid,
    });
  };

  handleAddNewCompany = (companyName) => {
    const { companies } = this.props;
    const { userRatingsValid, thumbsUp, userInfo } = this.state;
    const isFormValid =
      userRatingsValid &&
      thumbsUp !== null &&
      userInfo.firstName.trim() !== "" &&
      userInfo.lastName.trim() !== "" &&
      userInfo.email.trim() !== "";
    const company = companies.find(
      (company) => company.name.toLowerCase() === companyName.toLowerCase()
    );

    if (company) {
      this.setState({
        currentCompany: company.name,
        companyId: company.id || "",
        isFormValid,
      });
    } else {
      this.setState({
        addCompanyLoading: true,
        isFormValid,
        currentCompany: companyName,
      });

      const newCompany = {
        name: companyName,
        minimumRatings: 1,
        enrolled: false,
        physicalAddress: null,
      };

      this.props.handleAddCompany(newCompany);
    }
  };

  handleSelectCompany1 = (companyDetails) => {
    const { companySuggestions } = this.props;
    const { userRatingsValid, thumbsUp, userInfo } = this.state;
    const isFormValid =
      userRatingsValid &&
      thumbsUp !== null &&
      userInfo.firstName.trim() !== "" &&
      userInfo.lastName.trim() !== "" &&
      userInfo.email.trim() !== "";
    if (typeof companyDetails === "object") {
      const company = companySuggestions.find(
        (company) => company.id === companyDetails.companyId
      );

      this.setState({
        currentCompany: company ? company.name : "",
        companyId: company ? company.id : "",
        isFormValid,
      });
    } else {
      this.setState({
        currentCompany: companyDetails,
        companyId: "",
        isFormValid,
      });
    }
  };

  handleSelectCompany = (companyName) => {
    const { companies } = this.props;
    const { userRatingsValid, thumbsUp, userInfo } = this.state;
    const isFormValid =
      userRatingsValid &&
      thumbsUp !== null &&
      userInfo.firstName.trim() !== "" &&
      userInfo.lastName.trim() !== "" &&
      userInfo.email.trim() !== "";
    const company = companies.find(
      (company) => company.name.toLowerCase() === companyName.toLowerCase()
    );

    this.setState({
      currentCompany: company ? company.name : "",
      companyId: company ? company.id : "",
      isFormValid,
    });
  };

  handleCompanyChange = (currentCompany) => {
    const { userRatingsValid, thumbsUp, userInfo } = this.state;
    const isFormValid =
      userRatingsValid &&
      thumbsUp !== null &&
      userInfo.firstName.trim() !== "" &&
      userInfo.lastName.trim() !== "" &&
      userInfo.email.trim() !== "";
    const company = this.props.companies.find(
      (company) => company.name === currentCompany
    );

    if (company) {
      this.setState({
        currentCompany,
        companyId: company.id || "",
        isFormValid,
      });
    } else {
      const newCompany = {
        name: currentCompany,
        minimumRatings: 1,
        enrolled: false,
        physicalAddress: null,
      };

      this.props.handleAddCompany(newCompany);

      this.setState({
        currentCompany,
        isFormValid,
      });
    }
  };

  handleCommentChange = (event) => {
    const detail = event.target.value;
    const commentCharLeft = 1000 - detail.length;
    const { userInfo, userRatingsValid, thumbsUp, currentCompany } = this.state;
    const isFormValid =
      userRatingsValid &&
      thumbsUp !== null &&
      userInfo.firstName.trim() !== "" &&
      userInfo.lastName.trim() !== "" &&
      userInfo.email.trim() !== "";
    if (commentCharLeft >= 0) {
      this.setState({
        detail,
        isFormValid,
        commentCharLeft,
      });
    }
  };

  handleAnonymousCheck = (event) => {
    this.setState({ anonymous: event.target.checked });
  };

  handleFirstNameChange = (event) => {
    let { userInfo } = this.state;
    const firstName = event.target.value;
    userInfo.firstName = firstName;
    const { userRatingsValid, thumbsUp, currentCompany } = this.state;
    const isFormValid =
      userRatingsValid &&
      thumbsUp !== null &&
      userInfo.firstName.trim() !== "" &&
      userInfo.lastName.trim() !== "" &&
      userInfo.email.trim() !== "";
    this.setState({ userInfo, isFormValid });
  };

  handleLastNameChange = (event) => {
    let { userInfo } = this.state;
    const lastName = event.target.value;
    userInfo.lastName = lastName;
    const { userRatingsValid, thumbsUp, currentCompany } = this.state;
    const isFormValid =
      userRatingsValid &&
      thumbsUp !== null &&
      userInfo.firstName.trim() !== "" &&
      userInfo.lastName.trim() !== "" &&
      userInfo.email.trim() !== "";
    this.setState({ userInfo, isFormValid });
  };

  handleEmailChange = (event) => {
    let { userInfo } = this.state;
    const email = event.target.value;
    userInfo.email = email;
    const emailValid = !!email.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ); // eslint-disable-line no-useless-escape
    const { userRatingsValid, thumbsUp, currentCompany } = this.state;
    this.setState({ validEmail: emailValid });
    if (emailValid) {
      this.props.handleCheckEmail(userInfo.email);
    } else {
      this.setState({ errorMessage: "Please enter valid Email address" });
    }
    const isFormValid =
      userRatingsValid &&
      thumbsUp !== null &&
      userInfo.firstName.trim() !== "" &&
      userInfo.lastName.trim() !== "" &&
      userInfo.email.trim() !== "" &&
      emailValid;
    this.setState({ userInfo, isFormValid });
  };

  handleFormSubmit = (event) => {
    event.preventDefault();
    const {
      companyId,
      userInfo,
      detail,
      anonymous,
      userRatings,
      thumbsUp,
      functionSelected,
      validEmail,
    } = this.state;
    if (this.state.isFormValid && functionSelected !== "" && validEmail) {
      const { reviewerId } = this.props;
      let tempVar = functionSelected;
      if (functionSelected == "Other" || functionSelected == "General") {
        tempVar = "General";
      }
      this.props.handleRateNewRep(
        reviewerId,
        companyId,
        userInfo,
        detail,
        anonymous,
        userRatings,
        thumbsUp,
        tempVar
      );
    }
  };
  handleFunctionsChange = (filterAverage) => {
    let tmpData = filterAverage;
    if (this.props.ratingAttribute) {
      let modifyStructure = "";
      if (filterAverage == "Other" || filterAverage == "General") {
        tmpData = "Other";
        modifyStructure = this.props.ratingAttribute["General"];
      } else {
        modifyStructure = this.props.ratingAttribute[filterAverage];
      }
      let tempVar = [];
      modifyStructure.forEach((ele) => {
        tempVar.push({ category: ele, value: null });
      });
      this.setState({
        functionSelected: tmpData,
        userRatings: tempVar,
        isFormValid: false,
      });
    }
  };
  handleRateDone = () => {
    const { statusCode } = this.props.rateNewRepResponse;
    if (statusCode === 200) {
      this.setState({
        redirectLink: `/viewrepcustomer?id=${this.props.rateNewRepResponse.payload.repId}`,
      });
    }
    this.props.handleRateConfirmationGoBack();
  };

  render() {
    if (this.state.redirectLink !== "") {
      return <Redirect push={true} to={this.state.redirectLink} />;
    }

    // if (this.state.userRatings.forEach(r=>r.value!=null)){
    //   this.setState({isFormValid:true})
    // }else{
    //   this.setState({isFormValid:false})
    // }

    return (
      <View
        {...this.props}
        {...this.state}
        setFormRef={this.setFormRef}
        handleFirstNameChange={this.handleFirstNameChange}
        handleLastNameChange={this.handleLastNameChange}
        handleEmailChange={this.handleEmailChange}
        handleRateChange={this.handleRateChange}
        handleCompanyChange={this.handleCompanyChange}
        handleExperienceSelection={this.handleExperienceSelection}
        handleCommentChange={this.handleCommentChange}
        handleAnonymousCheck={this.handleAnonymousCheck}
        handleFormSubmit={this.handleFormSubmit}
        handleRateDone={this.handleRateDone}
        handleAddNewCompany={this.handleAddNewCompany}
        handleSelectCompany={this.handleSelectCompany}
        handleSelectCompany1={this.handleSelectCompany1}
        handleFunctionsChange={this.handleFunctionsChange}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companies: state.company.companies,
    companiesFetching: state.company.isFetching,
    companySuggestions: state.company.companySuggestions,
    companySuggestionsFetching: state.company.companySuggestionsFetchingNewRep,
    companySuggestionsError: state.company.companySuggestionsError,

    rateNewRepResponse: state.rep.rateNewRepResponse,
    rateNewRepResponseReturned: state.rep.rateNewRepResponseReady,
    isFetching: state.rep.isFetchingRateNewRep,
    errorMessage: state.rep.errorMessage,
    reviewerId: state.auth.userData ? state.auth.userData.id : "",
    functionSelected: state.functionSelected,
    ratingAttribute: state.company.customRatingAttributes,
    isCheckingEmail: state.customer.isCheckingEmail,
    isUniqueEmail: state.customer.isUniqueEmail,
    checkingEmailError: state.customer.checkingEmailError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleRateNewRep(
    reviewerId,
    companyId,
    userInfo,
    detail,
    anonymous,
    userRatings,
    thumbsUp,
    functionSelected
  ) {
    dispatch(
      rateNewRep(
        reviewerId,
        companyId,
        userInfo,
        detail,
        anonymous,
        userRatings,
        thumbsUp,
        functionSelected
      )
    );
  },

  // handleGetCompanies() {
  //   dispatch(getCompanies())
  // },

  handleGetCompanySuggestions(companyName) {
    dispatch(getCompanySuggestionsRateNewRep(companyName));
  },

  handleGetRepList() {
    dispatch(getRepList());
  },

  handleRateConfirmationGoBack() {
    dispatch(rateNewRepGoBack());
  },

  handleAddCompany(company) {
    dispatch(addCompany(company));
  },

  handleCheckEmail(email) {
    dispatch(checkEmailExist(email));
  },
});

export const Unwrapped = RateRep;
export default connect(mapStateToProps, mapDispatchToProps)(RateRep);
