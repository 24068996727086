const Data = {
  hero: {
    header: "Your Time is Valuable",
    description: "Feedback will improve your vendor and their representatives, improving the effectiveness of your time and your relationship with the company.",
    buttonTxt: "Contact Us",
    buttonLink: "/contactus"
  },
}

export default Data;
