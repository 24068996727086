export default {
  USER_ROLES: {
    CUSTOMER: "CUSTOMER",
    REP: "REP",
    COMPANY: "ADMIN",
    REGULAR: "REGULAR",
    ADMIN: "REPORA_ADMIN",
  },

  PROVIDERS: {
    LINKEDIN: "LINKEDIN",
    GOOGLE: "GOOGLE",
    FACEBOOK: "FACEBOOK",
  },

  USER_FLAG: [
    { type: "All_Users", label: "All Users" },
    { type: "Users_Visible_To_Customers", label: "Users Visible To Customers" },
  ],

  ROLES_REDIRECTION: {
    CUSTOMER: "searchrep",
    REP: "viewreppersonal",
    ADMIN: "dashboardcompany",
  },

  MAP_TYPES: {
    USA_STATES: "USA_STATES",
    WORLD: "WORLD",
  },

  ERROR_MESSAGES: {
    DEFAULT:
      "This action could not be completed at this moment. Check with your System Administrator",
  },

  PERFORMANCE_CATEGORY: [
    { type: "REPSCORE", label: "Repscore" },
    { type: "ANY_RATING", label: "Any Rating" },
    { type: "POSITIVE_EXPERIENCE", label: "Positive Experience" },
    { type: "AVAILABILITY", label: "Availability" },
    { type: "FOLLOW_THROUGH", label: "Follow Through" },
    { type: "TRUSTWORTHINESS", label: "Trustworthiness" },
    { type: "PROFESSIONALISM", label: "Professionalism" },
    { type: "PUNCTUALITY", label: "Punctuality" },
    { type: "COMMUNICATION_SKILLS", label: "Communication Skills" },
    { type: "PRODUCT_KNOWLEDGE", label: "Product Knowledge" },
    { type: "ORGANIZATION_SKILLS", label: "Organization Skills" },
  ],
  PERFORMANCE_CATEGORY_MAP: {
    REPSCORE: "REPSCORE",
    ANY_RATING: "ANY_RATING",
    POSITIVE_EXPERIENCE: "POSITIVE_EXPERIENCE",
    AVAILABILITY: "AVAILABILITY",
    FOLLOW_THROUGH: "FOLLOW_THROUGH",
    TRUSTWORTHINESS: "TRUSTWORTHINESS",
    PROFESSIONALISM: "PROFESSIONALISM",
    PUNCTUALITY: "PUNCTUALITY",
    COMMUNICATION_SKILLS: "COMMUNICATION_SKILLS",
    PRODUCT_KNOWLEDGE: "PRODUCT_KNOWLEDGE",
    ORGANIZATION_SKILLS: "ORGANIZATION_SKILLS",
  },
  PERFORMANCE_CATEGORY_LABELS: {
    REPSCORE: "Repscore",
    ANY_RATING: "Any Rating",
    POSITIVE_EXPERIENCE: "Positive Experience",
    AVAILABILITY: "Availability",
    FOLLOW_THROUGH: "Follow Through",
    TRUSTWORTHINESS: "Trustworthiness",
    PROFESSIONALISM: "Professionalism",
    PUNCTUALITY: "Punctuality",
    COMMUNICATION_SKILLS: "Communication Skills",
    PRODUCT_KNOWLEDGE: "Product Knowledge",
    ORGANIZATION_SKILLS: "Organization Skills",
  },

  TOPBOTTOM_PERFORMANCE_CATEGORY: [
    { type: "REPSCORE", label: "Repscore" },
    { type: "OVERALL_RATING", label: "Overall Rating" },
    { type: "PRODUCT_KNOWLEDGE", label: "Product Knowledge" },
    { type: "COMMUNICATION_SKILLS", label: "Communication Skills" },
    { type: "TRUSTWORTHINESS", label: "Trustworthiness" },
    { type: "PROFESSIONALISM", label: "Professionalism" },
    { type: "ORGANIZATION_SKILLS", label: "Organization Skills" },
    { type: "AVAILABILITY", label: "Availability" },
    { type: "FOLLOW_THROUGH", label: "Follow Through" },
    { type: "PUNCTUALITY", label: "Punctuality" },
    { type: "POSITIVE_EXPERIENCE", label: "Positive Experience" },
  ],
  TOPBOTTOM_PERFORMANCE_CATEGORY_MAP: {
    REPSCORE: "REPSCORE",
    OVERALL_RATING: "OVERALL_RATING",
    PRODUCT_KNOWLEDGE: "PRODUCT_KNOWLEDGE",
    COMMUNICATION_SKILLS: "COMMUNICATION_SKILLS",
    TRUSTWORTHINESS: "TRUSTWORTHINESS",
    PROFESSIONALISM: "PROFESSIONALISM",
    ORGANIZATION_SKILLS: "ORGANIZATION_SKILLS",
    AVAILABILITY: "AVAILABILITY",
    FOLLOW_THROUGH: "FOLLOW_THROUGH",
    PUNCTUALITY: "PUNCTUALITY",
    POSITIVE_EXPERIENCE: "POSITIVE_EXPERIENCE",
  },

  HOTSPOT_PERFORMANCE_CATEGORY: [
    { type: "ANY_RATING", label: "Any Rating" },
    { type: "PRODUCT_KNOWLEDGE", label: "Product Knowledge" },
    { type: "COMMUNICATION_SKILLS", label: "Communication Skills" },
    { type: "TRUSTWORTHINESS", label: "Trustworthiness" },
    { type: "PROFESSIONALISM", label: "Professionalism" },
    { type: "ORGANIZATION_SKILLS", label: "Organization Skills" },
    { type: "AVAILABILITY", label: "Availability" },
    { type: "FOLLOW_THROUGH", label: "Follow Through" },
    { type: "PUNCTUALITY", label: "Punctuality" },
    { type: "POSITIVE_EXPERIENCE", label: "Positive Experience" },
  ],
  HOTSPOT_PERFORMANCE_CATEGORY_MAP: {
    ANY_RATING: "ANY_RATING",
    PRODUCT_KNOWLEDGE: "PRODUCT_KNOWLEDGE",
    COMMUNICATION_SKILLS: "COMMUNICATION_SKILLS",
    TRUSTWORTHINESS: "TRUSTWORTHINESS",
    PROFESSIONALISM: "PROFESSIONALISM",
    ORGANIZATION_SKILLS: "ORGANIZATION_SKILLS",
    AVAILABILITY: "AVAILABILITY",
    FOLLOW_THROUGH: "FOLLOW_THROUGH",
    PUNCTUALITY: "PUNCTUALITY",
    POSITIVE_EXPERIENCE: "POSITIVE_EXPERIENCE",
  },
  HOTSPOT_PERFORMANCE_CATEGORY_LABEL_MAP: {
    ANY_RATING: "Any Rating",
    PRODUCT_KNOWLEDGE: "Product Knowledge",
    COMMUNICATION_SKILLS: "Communication Skills",
    TRUSTWORTHINESS: "Trustworthiness",
    PROFESSIONALISM: "Professionalism",
    ORGANIZATION_SKILLS: "Organization Skills",
    AVAILABILITY: "Availability",
    FOLLOW_THROUGH: "Follow Through",
    PUNCTUALITY: "Punctuality",
    POSITIVE_EXPERIENCE: "Positive Experience",
    DOMAIN_EXPERTISE: "Domain Expertise",
  },
  HOTSPOT_CALENDAR_OPTIONS: [
    { type: "3_MONTHS", label: "3 Months" },
    { type: "1_YEAR", label: "1 Year" },
    { type: "MAX", label: "Max" },
  ],
  HOTSPOT_LIMIT: [
    { type: "ABOVE", label: "Above" },
    { type: "BELOW", label: "Below" },
  ],
  HOTSPOT_LIMIT_MAP: {
    ABOVE: "ABOVE",
    BELOW: "BELOW",
  },
  HOTSPOT_THUMBS_OPTIONS: [
    // { type: "THUMBS_UP", label: "Thumbs Up" },
    { type: "THUMBS_DOWN", label: "Thumbs Down" },
  ],
  HOTSPOT_THUMBS_OPTIONS_MAP: {
    THUMBS_UP: "THUMBS_UP",
    THUMBS_DOWN: "THUMBS_DOWN",
  },

  CALENDAR_OPTIONS: [
    { type: "THREE_MONTHS", label: "3 Months" },
    { type: "ONE_YEAR", label: "1 Year" },
    { type: "MAX", label: "Max" },
    { type: "CUSTOM", label: "Custom" },
  ],
  CALENDAR_OPTIONS_MAP: {
    THREE_MONTHS: "THREE_MONTHS",
    ONE_YEAR: "ONE_YEAR",
    MAX: "MAX",
    CUSTOM: "CUSTOM",
  },

  AVERAGE_RATINGS_PERFORMANCE_CATEGORY: [
    { type: "OVERALL_RATING", label: "Overall Rating" },
    { type: "PRODUCT_KNOWLEDGE", label: "Product Knowledge" },
    { type: "COMMUNICATION_SKILLS", label: "Communication Skills" },
    { type: "TRUSTWORTHINESS", label: "Trustworthiness" },
    { type: "PROFESSIONALISM", label: "Professionalism" },
    { type: "ORGANIZATION_SKILLS", label: "Organization Skills" },
    { type: "AVAILABILITY", label: "Availability" },
    { type: "FOLLOW_THROUGH", label: "Follow Through" },
    { type: "PUNCTUALITY", label: "Punctuality" },
  ],
  AVERAGE_RATINGS_PERFORMANCE_CATEGORY_MAP: {
    OVERALL_RATING: "OVERALL_RATING",
    PRODUCT_KNOWLEDGE: "PRODUCT_KNOWLEDGE",
    COMMUNICATION_SKILLS: "COMMUNICATION_SKILLS",
    TRUSTWORTHINESS: "TRUSTWORTHINESS",
    PROFESSIONALISM: "PROFESSIONALISM",
    ORGANIZATION_SKILLS: "ORGANIZATION_SKILLS",
    AVAILABILITY: "AVAILABILITY",
    FOLLOW_THROUGH: "FOLLOW_THROUGH",
    PUNCTUALITY: "PUNCTUALITY",
    POSITIVE_EXPERIENCE: "POSITIVE_EXPERIENCE",
  },
  AVERAGE_RATINGS_TIME_OPTIONS: [
    { type: "LAST_QUARTER", label: "Last Quarter" },
    { type: "LAST_YEAR", label: "Last Year" },
  ],

  REP_TRENDS_PERFORMANCE_CATEGORY: [
    { type: "repScoreList", label: "RepScore" },
    { type: "overallRatingList", label: "Overall Rating" },
    { type: "productKnowledgeList", label: "Product Knowledge" },
    { type: "communicationSkillsList", label: "Communication Skills" },
    { type: "trustworthinessList", label: "Trustworthiness" },
    { type: "professionalismList", label: "Professionalism" },
    { type: "organizationSkillsList", label: "Organization Skills" },
    { type: "availabilityList", label: "Availability" },
    { type: "followThroughList", label: "Follow Through" },
    { type: "punctualityList", label: "Punctuality" },
    { type: "thumbsUpsList", label: "Positive Experience" },
  ],

  REP_TRENDS_PROGRAM_PERFORMANCE_CATEGORY: [
    { type: "commitedtomysuccess", label: "Commited To My Success" },
    { type: "communicationskills", label: "Communication Skills" },
    { type: "domainexpertise", label: "Domain Expertise" },
    { type: "followthrough", label: "Follow Through" },
    { type: "organizationalskills", label: "Organizational Skills" },
    { type: "overallrating", label: "Overallrating" },
    { type: "positiveexperience", label: "Positive Experience" },
    { type: "preparation", label: "Preparation" },
    { type: "productknowledge", label: "Productknowledge" },
    { type: "professionalism", label: "Professionalism" },
    { type: "trustworthiness", label: "Trustworthiness" },
  ],

  REP_TRENDS_PERFORMANCE_CATEGORY_MAP: {
    REP_SCORE: "repScoreList",
    OVERALL_RATING: "overallRatingList",
    PRODUCT_KNOWLEDGE: "productKnowledgeList",
    COMMUNICATION_SKILLS: "communicationSkillsList",
    TRUSTWORTHINESS: "trustworthinessList",
    PROFESSIONALISM: "professionalismList",
    ORGANIZATION_SKILLS: "organizationSkillsList",
    AVAILABILITY: "availabilityList",
    FOLLOW_THROUGH: "followThroughList",
    PUNCTUALITY: "punctualityList",
    POSITIVE_EXPERIENCE: "thumbsUpsList",
  },

  MAPS_PERFORMANCE_CATEGORY: [
    { type: "REPSCORE", label: "Repscore" },
    { type: "ANY_ATTRIBUTE", label: "Any Attribute" },
    { type: "OVERALL_RATING", label: "Overall Rating" },
    { type: "PRODUCT_KNOWLEDGE", label: "Product Knowledge" },
    { type: "COMMUNICATION_SKILLS", label: "Communication Skills" },
    { type: "TRUSTWORTHINESS", label: "Trustworthiness" },
    { type: "PROFESSIONALISM", label: "Professionalism" },
    { type: "ORGANIZATION_SKILLS", label: "Organization Skills" },
    { type: "AVAILABILITY", label: "Availability" },
    { type: "FOLLOW_THROUGH", label: "Follow Through" },
    { type: "PUNCTUALITY", label: "Punctuality" },
    { type: "POSITIVE_EXPERIENCE", label: "Positive Experience" },
  ],
  MAPS_PERFORMANCE_CATEGORY_MAP: {
    REPSCORE: "REPSCORE",
    ANY_ATTRIBUTE: "ANY_ATTRIBUTE",
    OVERALL_RATING: "OVERALL_RATING",
    PRODUCT_KNOWLEDGE: "PRODUCT_KNOWLEDGE",
    COMMUNICATION_SKILLS: "COMMUNICATION_SKILLS",
    TRUSTWORTHINESS: "TRUSTWORTHINESS",
    PROFESSIONALISM: "PROFESSIONALISM",
    ORGANIZATION_SKILLS: "ORGANIZATION_SKILLS",
    AVAILABILITY: "AVAILABILITY",
    FOLLOW_THROUGH: "FOLLOW_THROUGH",
    PUNCTUALITY: "PUNCTUALITY",
    POSITIVE_EXPERIENCE: "POSITIVE_EXPERIENCE",
  },
  MAPS_PERFORMANCE_CATEGORY_FILTER: [
    { type: "AVERAGE", label: "Average" },
    { type: "THRESHOLD", label: "Threshold" },
  ],
  MAPS_PERFORMANCE_CATEGORY_FILTER_MAP: {
    AVERAGE: "AVERAGE",
    THRESHOLD: "THRESHOLD",
  },
  MAPS_PERFORMANCE_CATEGORY_FILTER_AVERAGE: [
    { type: "ABOVE", label: "Above" },
    { type: "BELOW", label: "Below" },
  ],
  MAPS_PERFORMANCE_CATEGORY_FILTER_AVERAGE_MAP: {
    ABOVE: "ABOVE",
    BELOW: "BELOW",
  },
  MAPS_PERFORMANCE_CATEGORY_FILTER_THRESHOLD: [
    { type: "THUMBS_UP", label: "Thumbs Up" },
    { type: "THUMBS_DOWN", label: "Thumbs Down" },
  ],
  MAPS_PERFORMANCE_CATEGORY_FILTER_THRESHOLD_MAP: {
    THUMBS_UP: "THUMBS_UP",
    THUMBS_DOWN: "THUMBS_DOWN",
  },
  MAPS_NUMBER_VALUE_FILTER_MAP: {
    NUMBER: "NUMBER",
    VALUE: "VALUE",
  },

  RATE_REP_RATINGS: [
    { type: "PRODUCT_KNOWLEDGE", label: "Product Knowledge" },
    { type: "COMMUNICATION_SKILLS", label: "Communication Skills" },
    { type: "TRUSTWORTHINESS", label: "Trustworthiness" },
    { type: "PROFESSIONALISM", label: "Professionalism" },
    { type: "ORGANIZATION_SKILLS", label: "Organization Skills" },
    { type: "AVAILABILITY", label: "Availability" },
    { type: "FOLLOW_THROUGH", label: "Follow Through" },
    { type: "PUNCTUALITY", label: "Punctuality" },
  ],
  RATE_REP_RATINGS_MAP: {
    PRODUCT_KNOWLEDGE: "PRODUCT_KNOWLEDGE",
    COMMUNICATION_SKILLS: "COMMUNICATION_SKILLS",
    TRUSTWORTHINESS: "TRUSTWORTHINESS",
    PROFESSIONALISM: "PROFESSIONALISM",
    ORGANIZATION_SKILLS: "ORGANIZATION_SKILLS",
    AVAILABILITY: "AVAILABILITY",
    FOLLOW_THROUGH: "FOLLOW_THROUGH",
    PUNCTUALITY: "PUNCTUALITY",
  },

  COMPANY_GLOBAL_CUSTOMER_OPTIONS_REGULAR: [{ type: "REPS", label: "Reps" }],

  COMPANY_GLOBAL_CUSTOMER_OPTIONS: [
    // { type: "VERIFIED_CUSTOMERS", label: "Verified Customers" },
    // { type: "ALL_CUSTOMERS", label: "All Customers" },
    { type: "REPS", label: "Reps" },
    { type: "PROGRAMS", label: "Programs" },
    { type: "TEAMS", label: "Teams" },
  ],

  COMPANY_PROGRAMS_OPTIONS_MAP: {
    SELECT_PROGRAM: "Select Program",
  },

  COMPANY_GLOBAL_CUSTOMER_OPTIONS_MAP: {
    // VERIFIED_CUSTOMERS: "VERIFIED_CUSTOMERS",
    // ALL_CUSTOMERS: "ALL_CUSTOMERS",
    REPS: "REPS",
    PROGRAMS: "PROGRAMS",
    TEAMS: "TEAMS",
  },

  COMPANY_GLOBAL_CUSTOMER_OPTIONS_LABEL_MAP: {
    // VERIFIED_CUSTOMERS: "Verified Customers",
    // ALL_CUSTOMERS: "All Customers",
    REPS: "Reps",
    PROGRAMS: "Programs",
    TEAMS: "Teams",
  },

  COMPANY_GLOBAL_ROLES: [
    { type: "All_Roles", label: "All Roles" },
    { type: "Customer_Success", label: "Customer Success" },
    { type: "Design/Creative", label: "Design/Creative" },
    { type: "Developer/Engineer", label: "Developer/Engineer" },
    { type: "General", label: "General" },
    { type: "Sales", label: "Sales" },
  ],
  COMPANY_GLOBAL_ROLES_LABEL: {
    All_Roles: "All Roles",
  },

  COMPANY_GLOBAL_RATING_OPTIONS: [
    { type: "ANONYMOUS_RATINGS_HIDDEN", label: "Anonymous Ratings Hidden" },
    { type: "ANONYMOUS_RATINGS_INCLUDED", label: "Anonymous Ratings Included" },
  ],

  COMPANY_GLOBAL_RATING_OPTIONS_MAP: {
    ANONYMOUS_RATINGS_HIDDEN: "ANONYMOUS_RATINGS_HIDDEN",
    ANONYMOUS_RATINGS_INCLUDED: "ANONYMOUS_RATINGS_INCLUDED",
  },
  COMPANY_GLOBAL_RATING_OPTIONS_LABEL_MAP: {
    ANONYMOUS_RATINGS_HIDDEN: "Anonymous Ratings Hidden",
    ANONYMOUS_RATINGS_INCLUDED: "Anonymous Ratings Included",
  },
  FUNCTIONS: [
    "Customer Success",
    "Design/Creative",
    "Developer/Engineer",
    "General",
    "Sales",
  ],

  IS_MOBILE: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),

  DISPUTE_REJECTED_HELP: {
    PRIVATE_COMPANY: `${"The dispute of this rating was not accepted. The rating will remain visible to you, your company and the original customer who left the rating."} \n${"This rating will be used in RepScore and star calculations."}\n
      ${"You may dispute this rating again if you like."}`,
    PUBLIC_COMPANY: `${"The dispute of this rating was not accepted. The rating will remain visible to all relevant parties: to you, your company (if you are connected to one), the original customer who left the rating and to other customers."}\n ${"This rating will be used in RepScore and star calculations. You may dispute this rating again if you like."}`,
  },
  IN_DISPUTE:
    "This rating is currently under dispute. Repora will respond to your dispute shortly. In the meantime, the rating is only visible to you, your company (if you are connected to one), and the original customer who left the rating. This rating is not being used in RepScore or star calculations",
  REPORA_SA_PATH: {
    REPLISTING: "/repora-myreps-sa",
    VIEWREP: "/repora-viewrep-sa",
    COMPANYVIEW: "/repora-viewcompany-sa",
  },
  REPORA_IFRAME_PATHS: [
    "/repora-viewcompany-sa",
    "/repora-myreps-sa",
    "/repora-viewrep-sa",
    "/unVerified",
  ],
};
