import React from "react";
import styled from "styled-components";
import { reporaColor, ReporaButton, reporaSize, Header2, Body3 } from "../../../components/global/commonStyles";
import Hero from "../../../components/HeroUpdated/Hero";
import PanelColumns from "../../../components/PanelColumnsUpdated/PanelColumns";
import PanelImage from "../../../components/PanelImageUpdated/PanelImage";
import CompanyHeroBgImage from "../assets/Repora_Company_Hero_BGimage.jpg";
import Footer from "../../../components/FooterUpdated/Footer";
import Data from "./Data";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PageSection = styled.section`
  width: 100%;
  max-width: 100%;
  height: auto !important;
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: ${props => props.bkgImage ? `url(${props.bkgImage})` : "none"};
  background-color: ${props => props.bkgColor || "none" };
  background-position: ${props => props.isFooter || props.arch || props.isHero ? "top" : "center center"};
	background-repeat: no-repeat;
	background-size: cover;
  padding: ${props => props.isHero ? "8% 30%" : "5% 15%"};
  color: ${props => props.txtColor || "inherit"};

  ${props => props.isFooter ? "margin-top: -6%;" : null}

  @media (max-width: ${reporaSize.medium}) {
		padding-left: ${props => props.isHero ? "15%" : "5%"};
    padding-right: ${props => props.isHero ? "15%" : "5%"};

    ${props => props.isFooter ? "margin-top: -8%;" : null}
	}

  @media (max-width: ${reporaSize.small}) {
    ${props => props.isFooter ? "margin-top: -15%;" : null}
	}
`;

const PrioritiesText = styled.div`
  display: flex;
  padding: 0 2% 2%;
  justify-content: center;

  h2 {
    font-size: 18px;
    color: ${reporaColor.aquaBlue};
  }
`;

const PrioritiesTextFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 9px;
  }

  a {
    font-size: 9px;
    color: ${reporaColor.aquaBlue};
  }
`;

const CeoContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 70px;
  
  @media (max-width: ${reporaSize.small}) {
    flex-direction: column;
  }
`

const CeoText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  padding-right: 5%;
  padding-left: 5%;
  
  @media (max-width: ${reporaSize.small}) {
    width: 100%;
    align-items: center;
  }

  h2 {
    color: ${reporaColor.white}
    font-size: 28px;
    line-height: initial;
    position: relative;
  
    @media (max-width: ${reporaSize.small}) {
      font-size: 22px;
      text-align: center;
    }

    span {
      color: ${reporaColor.aquaBlue};

      &.quote-start {

        @media (max-width: ${reporaSize.small}) {
          padding-right: 15px;
        }

        &::after {
          content: "“";
          font-size: 120px;
          color: #155264;
          position: absolute;
          top: -25px;
          left: -55px;
  
          @media (max-width: ${reporaSize.small}) {
            left: -35px;
          }
        }
      }
    
      &.quote-end {
        
        @media (max-width: ${reporaSize.small}) {
          padding-left: 15px;
        }

        &::after {
          content: "”";
          font-size: 120px;
          color: #155264;
          position: absolute;
          bottom: -95px;
          right: 50px;
  
          @media (max-width: ${reporaSize.medium}) {
            right: 110px;
          }
  
          @media (max-width: ${reporaSize.small}) {
            right: 20px;
          }
        }
      }
    }
  }
`

const CeoImg = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  
  @media (max-width: ${reporaSize.small}) {
    width: 100%;
    align-items: center;
    padding-top: 10%;
  }
`

const CeoImgContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;

  img {
    max-width: 150px;
  }

  p {
    color: ${reporaColor.aquaBlue}
  }
`

const CustomPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h2 {
    font-size: 35px;
    line-height: initial;
    margin-bottom: 3%;

    @media (max-width: ${reporaSize.small}) {
      font-size: 20px;
    }
  }
`;

const Padder = styled.div`
  display: flex;
  padding: 3% 0;
`

const View = () => {

  return (
    <Wrapper>
      <PageSection bkgImage={CompanyHeroBgImage} isHero>
        <Hero textColor={reporaColor.white} data={Data.hero} />
      </PageSection>
      
      <PageSection>
        <PrioritiesText>
          <Header2>{Data.prioritiesHeader}</Header2>
        </PrioritiesText>
        <PanelImage data={Data.efficientGrowth} contentPosition="left" imageWidthPercent={55}></PanelImage>
        <Padder />
        <PanelImage data={Data.topInitiatives} contentPosition="right" imageWidthPercent={66}></PanelImage>
        <Padder />
        <PrioritiesText>
          <PrioritiesTextFooter>
            <div>
              <span>{Data.prioritiesFooter.text}</span>
            </div>
            <div>
              <a href={Data.prioritiesFooter.linkUrl} target="_blank" rel="noopener noreferrer">{Data.prioritiesFooter.linkText}</a>
            </div>
          </PrioritiesTextFooter>
        </PrioritiesText>
      </PageSection>

      <PageSection bkgImage="/assets/featuresCompanies/customer-type-image.png" txtColor={reporaColor.white} arch>
        <Padder />
        <PanelColumns data={Data.customers.head} noHeadingMargin />
      </PageSection>

      <PageSection>
        <PanelColumns data={Data.useCases} boxcolor={reporaColor.white} columnHeaderSize="30px" />
      </PageSection>

      <PageSection bkgColor={reporaColor.sectionBackGround}>
        <CeoContainer>
          <CeoText>
            <Header2>
              <span className="quote-start" />
              It’s my responsibility as CEO to know <span>what customers think of us</span> and to create the environment to <span>keep top employees</span>. I’m not afraid of being evaluated.
              <span className="quote-end" />
            </Header2>
          </CeoText>
          <CeoImg>
            <CeoImgContent>
              <img src="/assets/featuresCompanies/company-quote-headshot.png" alt="" />
              <Body3>Nick Mehta</Body3>
              <Body3>CEO. Gainsight</Body3>
            </CeoImgContent>
          </CeoImg>
        </CeoContainer>
      </PageSection>

      <PageSection bkgImage="/assets/home/footer-bgm.png" isFooter>
        <CustomPanel>
          <Header2 textAlign="center" textColor={`${reporaColor.black}`}>Start Using Repora Today!</Header2>
          <ReporaButton href="/contactus?view=company" secondary>Contact Us</ReporaButton>
        </CustomPanel>
      </PageSection>

      <Footer />
    </Wrapper>
  );
};

export default View;
