import authdAxios from "../modules/Authentication/utils/authdAxios";
import config from "../config";
import constants from "../constants";
import { get } from "lodash";
import authService from "./AuthService";
import axios from "axios";

const RepService = () => {
  function getRepList(extraParams) {
    if (extraParams) {
      extraParams = { onlyUnclaimReps: true };
    }
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.REP_API}/api/reps/get-all`, extraParams, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function _getRepProfile(repId, userId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/reps/view-profile`,
          { id: repId, reviewerId: userId },
          { headers: { "content-type": "application/json" } }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function getRepProfile(repId, logged, userId) {
    let url = `${config.REP_API}/reps/view-rep-profile-sample`;
    url = url.replace("4033", "3033");
    let payload = { id: repId };
    if (logged) {
      return _getRepProfile(repId, userId);
    } else {
      return new Promise((resolve, reject) => {
        axios
          .post(url, payload)
          .then((response) => {
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(
              get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT)
            );
          });
      });
    }
  }

  function getRepProfileSample(repId, logged, userId) {
    let url = `${config.REP_API}/reps/view-rep-profile-sample`;
    url = url.replace("4033", "3033");
    let payload = { id: repId };
    return new Promise((resolve, reject) => {
      axios
        .post(url, payload)
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function getCompanyRepProfile(repId, companyId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.REP_API}/api/reps/view-company-rep-profile`, {
          repId: repId,
          companyId: companyId,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  //Optimized API
  function getCompanyRep(repId, companyId) {
    return new Promise((resolve, reject) => {
      // https://dev.repora.co:4033/api/company-dashboard/get-company-rep-details
      authdAxios
        .post(
          `${config.REP_API}/api/company-dashboard/get-company-rep-details`,
          {
            repId: repId,
            companyId: companyId,
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function repProfile(repId, userId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/reps/view-profile-sample`,
          { id: repId, reviewerId: userId },
          { headers: { "content-type": "application/json" } }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function improvedGetRepProfile(repId, userId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/reps/get-rep-profile-details`,
          { id: repId, reviewerId: userId },
          { headers: { "content-type": "application/json" } }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function improvedGetRepFeedback(repId, userId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/reps/get-rep-feedback-list`,
          { id: repId, reviewerId: userId },
          { headers: { "content-type": "application/json" } }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function repProfileLink(repId, userId) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${config.REP_API}/api/link/view-profile`,
          { id: repId, reviewerId: userId },
          { headers: { "content-type": "application/json" } }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function _rateRep(rateData) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/reps/rate-rep`,
          {
            repId: rateData.repId,
            reviewerId: rateData.reviewerId,
            detail: rateData.detail,
            anonymous: rateData.anonymous,
            companyId: rateData.companyId,
            userRatings: rateData.userRatings,
            thumbsUp: rateData.thumbsUp,
            id: rateData.feedbackId,
            repType: rateData.repType,
            customRatings: rateData.customRatings,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(get(error, "data", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function rateRep(rateData, logged) {
    let url = `${config.REP_API}/reps/rate-rep-profile`;
    url = url.replace("4033", "3033");
    if (logged) {
      url = `${config.REP_API}/api/reps/rate-rep`;
      return _rateRep(rateData);
    } else {
      return new Promise((resolve, reject) => {
        axios
          .post(url, rateData, {
            timeout: config.API_TIMEOUT_DURATION,
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(
              get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT)
            );
          });
      });
    }
  }

  function updateRepProductSpeciality(userId, productSpeciality) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/users/rep/product-speciality`,
          {
            userId,
            productSpeciality,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
          authService.updateUserInfo({ productSpeciality });
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function updateCompanyUserInfo(info) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company-users/update-company-user`,
          {
            companyId: info.companyId,
            userId: info.userId,
            name: info.name,
            industry: info.industry,
            logo: null,
            linkedinUrl: null,
            physicalAddress: {
              country: info.country,
              city: info.city,
              state: info.state,
              zipCode: info.zipCode,
              phoneNumber: null,
              territory: info.territory,
            },
            current: info.current,
            startDate: info.startDate,
            endDate: info.endDate,
            enrolled: false,
            editableByLinkedin: false,
            productSpeciality: info.productSpeciality,
            connected: info.connected,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(({ data }) => {
          if (data.statusCode !== 200) {
            reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
          }
          resolve(data);
        })
        .catch((error) => {
          const { data } = error.response || { data: {} };
          reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
        });
    });
  }

  function addPreviousCompany(company) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company-users/add-company-user`,
          [
            {
              userId: company.userId,
              name: company.name,
              industry: company.industry,
              companyLinkedinId: null,
              isCurrent: false,
              startDate: company.startDate,
              endDate: company.endDate,
              productSpeciality: company.productSpeciality,
              enrolled: false,
              physicalAddress: {
                country: company.country,
                city: company.city,
                state: company.state,
                zipCode: company.zipCode,
                phoneNumber: null,
                territory: company.territory,
              },
            },
          ],
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function deleteCompanyUser(companyId, userId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.COMPANY_API}/api/company-users/delete-company-user`,

          {
            companyId: companyId,
            userId: userId,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(({ data }) => {
          if (data.statusCode !== 200) {
            reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
          }
          resolve(data);
        })
        .catch((error) => {
          const { data } = error.response || { data: {} };
          reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
        });
    });
  }

  function updateFromLinkedin(providerName, userRole, callback, code) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/social-media/update-from-linkedin`,
          {
            provider: providerName,
            role: userRole,
            callback: callback,
            code: code,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
          authService.updateUserInfo(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function rateNewRep(
    reviewerId,
    companyId,
    userInfo,
    detail,
    anonymous,
    userRatings,
    thumbsUp,
    repType
  ) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.CUSTOMER_API}/api/reps/add-temp-rep`,
          {
            tempRep: {
              reviewerId,
              companyId,
              firstName: userInfo.firstName,
              lastName: userInfo.lastName,
              email: userInfo.email,
              repType: repType,
            },
            customerFeedback: {
              reviewerId,
              detail,
              anonymous,
              companyId,
              userRatings,
              thumbsUp,
              repType: repType,
            },
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(({ data }) => {
          if (data.statusCode !== 200) {
            reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
          }
          resolve(data);
        })
        .catch((error) => {
          const { data } = error.response || { data: {} };
          reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
        });
    });
  }

  function updateRepEmail(
    userId,
    email,
    rcvMsgAtWorkEmail,
    secondaryEmail,
    rcvMsgAtSecondaryEmail
  ) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/users/rep/email`,
          {
            userId,
            email,
            rcvMsgAtWorkEmail,
            secondaryEmail,
            rcvMsgAtSecondaryEmail,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function deleteRepEmail(userId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.PASSPORT_API}/api/users/rep/del-secondary-email`,
          {
            userId,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function claimRep(tempRepId, claimRepId, claimReason) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/reps/claim-rep`,
          {
            tempRepId: tempRepId,
            claimRepId: claimRepId,
            claimReason: claimReason,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  // function disputeRep(repid, feedbackId, comment) {
  function disputeRep(payload) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/reps/dispute`,
          // {
          //   repId: repid,
          //   feedbackId: feedbackId,
          //   comment: comment,
          // },
          payload,
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function setRepTemplate(repid, template) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/reps/add-template`,
          {
            repId: repid,
            repTemplate: template,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  function getRepTemplate(repid) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/reps/rep-template`,
          {
            repId: repid,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(
            get(
              error,
              "response.data.payload",
              constants.ERROR_MESSAGES.DEFAULT
            )
          );
        });
    });
  }

  // function getRepattributes() {
  //   return new Promise((resolve, reject) => {
  //     authdAxios
  //       .post(
  //         `${config.REP_API}/api/reps/rep-type-list`,
  //         {},
  //         {
  //           headers: {
  //             "content-type": "application/json",
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         resolve(response.data);
  //       })
  //       .catch((error) => {
  //         reject(
  //           get(
  //             error,
  //             "response.data.payload",
  //             constants.ERROR_MESSAGES.DEFAULT
  //           )
  //         );
  //       });
  //   });
  // }

  function getRepViewInfo(id) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/reps/view `,
          {
            id,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(({ data }) => {
          if (data.statusCode !== 200) {
            reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
          }
          resolve(data.payload);
        })
        .catch((error) => {
          const { data } = error.response || { data: {} };
          reject(data.error || constants.ERROR_MESSAGES.DEFAULT);
        });
    });
  }

  function contactRepEmail(data) {
    // const { fullNameFrom, emailFrom, fullNameTo, emailTo, message, companyId } =
    //   data;
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.CUSTOMER_API}/api/manage-reps/contact-rep`, data, {
          timeout: config.API_TIMEOUT_DURATION,
          headers: {
            "content-type": "application/json",
          },
        })
        .then(({ data }) => {
          if (data.statusCode !== 200) {
            reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
          }
          resolve(data.payload);
        })
        .catch((error) => {
          const { data } = error.response || { data: {} };
          reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
        });
    });
  }

  function contactCompanyEmail(data) {
    const {
      companyId,
      emailFrom,
      nameFrom,
      message,
      contactMe,
      repName,
      customerCompany,
      repLink,
    } = data;
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.NOTIFICATION_API}/api/notification/rep/contact-rep-company`,
          {
            companyId,
            emailFrom,
            nameFrom,
            message,
            contactMe,
            repName,
            customerCompany,
            repLink,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(({ data }) => {
          if (data.statusCode !== 200) {
            reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
          }
          resolve(data.payload);
        })
        .catch((error) => {
          const { data } = error.response || { data: {} };
          reject(data.payload || constants.ERROR_MESSAGES.DEFAULT);
        });
    });
  }

  function disconnectCompany(repId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/manage-reps/disconnect`,
          {
            id: repId,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function getRepSuggestions(repName, companyId, unclaimed) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/reps/get-reps-suggestions`,
          {
            repName: repName,
            companyId: companyId,
            unclaimed: unclaimed,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function getCompanyRepSuggestions(repName, companyId) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(
          `${config.REP_API}/api/reps/search-company-reps`,
          {
            repName: repName,
            companyId: companyId,
          },
          {
            timeout: config.API_TIMEOUT_DURATION,
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  /*****************/

  function addFeedCustomerBack(obj) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.REP_API}/api/reps/add-feedback-response`, obj, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function updateFeedCustomerBack(obj) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.REP_API}/api/reps/update-feedback-response`, obj, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function upvoteFeedCustomerBack(obj) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.REP_API}/api/reps/up-vote-feedback-response`, obj, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  function deleteFeedCustomerBack(obj) {
    return new Promise((resolve, reject) => {
      authdAxios
        .post(`${config.REP_API}/api/reps/delete-feedback-response`, obj, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data.payload);
        })
        .catch((error) => {
          reject(get(error, "data.payload", constants.ERROR_MESSAGES.DEFAULT));
        });
    });
  }

  /*************************/

  return {
    getRepList,
    getCompanyRep,
    repProfile,
    improvedGetRepProfile,
    improvedGetRepFeedback,
    rateRep,
    updateFromLinkedin,
    updateRepProductSpeciality,
    updateRepEmail,
    deleteRepEmail,
    updateCompanyUserInfo,
    addPreviousCompany,
    deleteCompanyUser,
    rateNewRep,
    claimRep,
    disputeRep,
    setRepTemplate,
    getRepTemplate,
    getRepViewInfo,
    contactRepEmail,
    contactCompanyEmail,
    disconnectCompany,
    getRepProfile,
    getRepProfileSample,
    repProfileLink,
    getRepSuggestions,
    getCompanyRepSuggestions,
    getCompanyRepProfile,

    addFeedCustomerBack,
    upvoteFeedCustomerBack,
    updateFeedCustomerBack,
    deleteFeedCustomerBack,
  };
};

export default RepService();
